import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Appbar, TextInput, Button, Snackbar, Modal, Portal, Title, Colors, RadioButton } from 'react-native-paper';
import { Alert, Dimensions, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableWithoutFeedback, View, Image, TouchableOpacity } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import UtilsManager from '../../managers/UtilsManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import styles from '../../css/myCss';
import AdBanner from '../generic/AdBanner';
import GUIManager from '../../managers/GUIManager';
import _ from 'lodash';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//maps
  //react native
  import MapView from 'react-native-maps';
  //web
  import { MapContainer as WebMap, TileLayer as WebTileLayer, Marker as WebMarker, Popup as WebPopup, Tooltip as WebTooltip, useMapEvents } from 'react-leaflet';
  import L from 'leaflet';
  import axios from 'axios';
  //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
  import 'leaflet/dist/leaflet.css'; //important - if it's not loaded, the map tiles are not shown correctly
import Generic_RestManager from '../../managers/Generic_RestManager';
import GenericConstManager from '../../managers/Generic_ConstManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RightColumnWeb from '../generic/RightColumnWeb';
import LeftColumnWeb from '../generic/LeftColumnWeb';

let MyMapViewForReactNative;
let MyMapViewForWeb;

  const zoomLevel = 18;


if (Platform.OS === 'web') {  //WEB
  MyMapViewForWeb = true;
  MyMapViewForReactNative = null;
} else {                      //REACT NATIVE
  MyMapViewForWeb = false;
  MyMapViewForReactNative = MapView;
}
//-maps


const myMarkerImg =  require("../../assets/marker.png");

const longitudeOffset = 0;//0.0344;  //oso to ayksanw, o xarths kineitai pros ta aristera (pineza pros ta deksia)
const latitudeOffset = 0;//-0.0476;  //oso to meiwnw (ayksanw thn arnhtikh timh), o xarths kineitai pros ta panw (pineza pros ta katw)
const deltaOffset = 0.004;       //oso to ayksanw, meiwnei zoom

const screenDimensions = Dimensions.get('window');

const OwnerMyProfileView = () => {
  const navigation = useNavigation();

  const webMapRef = useRef(null);

  const [initialPositionBasedOnIP, setInitialPositionBasedOnIP] = useState<{ latitude: number; longitude: number }>({ latitude: 40.60289, longitude: 22.96243 }); // Default: Thessaloniki
  useEffect(() => {
    console.log("initialPositionBasedOnIP - useEffect: ", initialPositionBasedOnIP);
  }, [initialPositionBasedOnIP]);

  const [costForRehersalNeeded_forWebAlert, setCostForRehersalNeeded_forWebAlert] = useState(false);
  useEffect(() => {
    if(costForRehersalNeeded_forWebAlert==true && Platform.OS==='web') {
      setAlertForWebText(i18n.t('youNeedToSetCostForRehersal'));
      setAlertForWebVisible(true);
    }
  }, [costForRehersalNeeded_forWebAlert]);
  
  const [costForRecordingNeeded_forWebAlert, setCostForRecordingNeeded_forWebAlert] = useState(false);
  useEffect(() => {
    if(costForRecordingNeeded_forWebAlert==true && Platform.OS==='web') {
      setAlertForWebText(i18n.t('youNeedToSetCostForRecording'));
      setAlertForWebVisible(true);
    }
  }, [costForRecordingNeeded_forWebAlert]);
  
  const [membershipPlansNeeded_forWebAlert, setMembershipPlansNeeded_forWebAlert] = useState(false);
  useEffect(() => {
    if(membershipPlansNeeded_forWebAlert==true && Platform.OS==='web') {
      setAlertForWebText(i18n.t('youNeedToDefineAtLeastOneMembershipPlan'));
      setAlertForWebVisible(true);
    }
  }, [membershipPlansNeeded_forWebAlert]);

  const [courtEditingNeeded_forWebAlert, setCourtEditingNeeded_forWebAlert] = useState(false);
  useEffect(() => {
    if(courtEditingNeeded_forWebAlert==true && Platform.OS==='web') {
      setAlertForWebText(i18n.t('youNeedToDefineTheCostPerHourForEachCourtForNonMembers'));
      setAlertForWebVisible(true);
    }
  }, [courtEditingNeeded_forWebAlert]);
  
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerMyProfileView: I'm ready!");
    (async () => {
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
      if(handleWebPageReloadActuallyRun == true) {
        setCurOwner(LoginManager.getInstance().loggedInOwner);
        setCurMD5EncodedPassword(LoginManager.getInstance().md5EncodedPassword);
        setCurNonEncryptedPasswordAgain(LoginManager.getInstance().password);
      }
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
          const tmpOwnerExtraInfo_Studio : Studio_OwnerExtraInfo = await Studio_RestManager.getInstance().getOwnerExtraInfo(LoginManager.getInstance().ID);
          if(tmpOwnerExtraInfo_Studio!=null && tmpOwnerExtraInfo_Studio!=undefined) {
            setInitialOwnerExtraInfo_Studio(tmpOwnerExtraInfo_Studio);
            setOwnerExtraInfo_Studio(tmpOwnerExtraInfo_Studio);
          }
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        const tmpOwnerExtraInfo_Tennis : Tennis_OwnerExtraInfo = await Tennis_RestManager.getInstance().getOwnerExtraInfo(LoginManager.getInstance().ID);
        if(tmpOwnerExtraInfo_Tennis!=null && tmpOwnerExtraInfo_Tennis!=undefined) {
          setInitialOwnerExtraInfo_Tennis(tmpOwnerExtraInfo_Tennis);
          setOwnerExtraInfo_Tennis(tmpOwnerExtraInfo_Tennis);
        }
      }
    })();
  }, [alreadyRunOnceOnViewLoad]);

////////////////////
// STUDIO - START //
////////////////////
  const [initialOwnerExtraInfo_Studio, setInitialOwnerExtraInfo_Studio] = useState<Studio_OwnerExtraInfo>({
    id: -1,
    owner_id: -1,
    allows_rehearsal: false,
    allows_recording: false
  });
  const [ownerExtraInfo_Studio, setOwnerExtraInfo_Studio] = useState<Studio_OwnerExtraInfo>({
    id: -1,
    owner_id: -1,
    allows_rehearsal: false,
    allows_recording: false
  });
  useEffect(() => {
    console.log("useEffect - ownerExtraInfo_Studio - START - ownerExtraInfo_Studio", ownerExtraInfo_Studio);
    if(ownerExtraInfo_Studio.allows_rehearsal==true && ownerExtraInfo_Studio.allows_recording==false)
      setStudioRehearsalsRecordings(Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY);
    else if(ownerExtraInfo_Studio.allows_rehearsal==false && ownerExtraInfo_Studio.allows_recording==true)
      setStudioRehearsalsRecordings(Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY);
    else if(ownerExtraInfo_Studio.allows_rehearsal==true && ownerExtraInfo_Studio.allows_recording==true)
      setStudioRehearsalsRecordings(Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS);
    console.log("useEffect - ownerExtraInfo_Studio - STOP");
  }, [JSON.stringify(ownerExtraInfo_Studio)]);

  const [studioRehearsalsRecordings, setStudioRehearsalsRecordings] = useState("");
  useEffect(() => {
    if(studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY) {
      console.log("Rehearsals only will be allowed through Handybooker");
    }
    else if(studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY) {
      console.log("Recordings only will be allowed through Handybooker");
    }
    else if(studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS) {
      console.log("Both rehearsals and recordings will be allowed through Handybooker");
    }
  }, [studioRehearsalsRecordings]);
//////////////////
// STUDIO - END //
//////////////////

////////////////////
// TENNIS - START //
////////////////////
  const [initialOwnerExtraInfo_Tennis, setInitialOwnerExtraInfo_Tennis] = useState<Tennis_OwnerExtraInfo>({
    id: -1,
    owner_id: -1,
    allows_reservations_to_members: false,
    allows_reservations_to_non_members: false
  });
  const [ownerExtraInfo_Tennis, setOwnerExtraInfo_Tennis] = useState<Tennis_OwnerExtraInfo>({
    id: -1,
    owner_id: -1,
    allows_reservations_to_members: false,
    allows_reservations_to_non_members: false
  });
  useEffect(() => {
    console.log("useEffect - ownerExtraInfo_Tennis - START - ownerExtraInfo_Tennis", ownerExtraInfo_Tennis);
    if(ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false)
      setTennisPrivateClubMode(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
    else if(ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true)
      setTennisPrivateClubMode(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS);
    else if(ownerExtraInfo_Tennis.allows_reservations_to_members==false && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true)
      setTennisPrivateClubMode(Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE);    
    console.log("useEffect - ownerExtraInfo_Tennis - STOP");
  }, [JSON.stringify(ownerExtraInfo_Tennis)]);

  const [tennisPrivateClubMode, setTennisPrivateClubMode] = useState("");
  useEffect(() => {
    if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY) {
      console.log(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
    }
    else if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS) {
      console.log(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS);
    }
    else if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE) {
      console.log(Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE);
    }
  }, [tennisPrivateClubMode]);
//////////////////
// TENNIS - END //
//////////////////  

  const [visibleModal, setVisibleModal] = useState(false);
  const showModal = () => setVisibleModal(true);
  const hideModal = () => setVisibleModal(false);

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const onDismissSnackBar = () => { setSnackBarVisible(false); setSnackBarText(""); }
  const [snackBarText, setSnackBarText] = useState('');  
  useEffect(() => {
    if(snackBarText != '')
      setSnackBarVisible(true);
  }, [snackBarText]);

  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow.length > 0)
    {
      if(MyAlertReactNative != null) {
        // Works on both Android and iOS
        MyAlertReactNative.alert(
          messageToShow,
          '',
          [
            {
              text: 'OK',
              onPress: () => {
                setMessageToShow('');
                setVisibleModal(false);
              }
            }
          ],
          { cancelable: false }
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebText(messageToShow);
        setAlertForWebVisible(true);
      }
    }
  }, [isLoading]);

  const [region, setRegion] = useState({
    latitude: 0.0,
    longitude: 0.0,
    latitudeDelta: 0.009,
    longitudeDelta: 0.009
  });
    useEffect(() => {
    console.log("New coordinates -> lat: " + region.latitude + ", lon: " + region.longitude);
  }, [region]);
  
  const [curNonEncryptedPassword, setCurNonEncryptedPassword] = useState(LoginManager.getInstance().password);
  useEffect(() => {
    (async () => {
      if(Platform.OS == "web") {
        const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(curNonEncryptedPassword);
        //console.log('Digest: ', digest);
        setCurMD5EncodedPassword(digest);
        setCurOwner({...curOwner, 
          password: digest
        });
      }
      else {
        const digest = await UtilsManager.getInstance().encodeWithMD5(curNonEncryptedPassword);
        //console.log('Digest: ', digest);
        setCurMD5EncodedPassword(digest);
        setCurOwner({...curOwner, 
          password: digest
        });
      }      
    })();
  }, [curNonEncryptedPassword]);
  const [curMD5EncodedPassword, setCurMD5EncodedPassword] = useState(LoginManager.getInstance().md5EncodedPassword);
  const [curNonEncryptedPasswordAgain, setCurNonEncryptedPasswordAgain] = useState(LoginManager.getInstance().password);
  
  const [curOwner, setCurOwner] = useState<Owner>(LoginManager.getInstance().loggedInOwner);
  useEffect(() => {
    //for react native map
    setRegion({...region, 
      latitude: curOwner.latitude,// + latitudeOffset,
      longitude: curOwner.longitude,// + longitudeOffset,
      latitudeDelta: deltaOffset,
      longitudeDelta: deltaOffset
    });
    //and for web map
    setInitialPositionBasedOnIP({ latitude: curOwner.latitude-GenericConstManager.getInstance().WEB_MAP_LAT_OFFSET, longitude: curOwner.longitude-GenericConstManager.getInstance().WEB_MAP_LON_OFFSET });
  }, [curOwner]);
  
  const saveProfileAsync = useCallback(async () => { 
    if(curOwner.name === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompleteName'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompleteName'));
    }
    else if(curOwner.surname === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompleteSurname'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompleteSurname'));
    }
    else if(curOwner.telephone === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompletePhone'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompletePhone'));
    }
    else if(curNonEncryptedPassword === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompletePassword'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompletePassword'));
    }
    else if(curNonEncryptedPasswordAgain === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompletePasswordAgain'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompletePasswordAgain'));
    }
    else if(curNonEncryptedPassword != curNonEncryptedPasswordAgain) {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('passwordsDoNotMatch'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('passwordsDoNotMatch'));
    }
    else {
      setIsLoading(true);       
      var updatedOwner : Owner = LoginManager.getInstance().loggedInOwner;
      var extraInfoHasChangedAndRoomsNeedUpdate_Studio : boolean = false;
      var extraInfoHasChangedAndCourtsNeedUpdate_Tennis : boolean = false;
      var tmpNewOwnerExtraInfo_Studio : Studio_OwnerExtraInfo;
      var tmpNewOwnerExtraInfo_Tennis : Tennis_OwnerExtraInfo;
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        updatedOwner = await Studio_RestManager.getInstance().editOwner(curOwner);

        //edit extra info (if changed)
        if( (studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY
              && (initialOwnerExtraInfo_Studio.allows_rehearsal==false || initialOwnerExtraInfo_Studio.allows_recording==true) )
            ||
            (studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY
              && (initialOwnerExtraInfo_Studio.allows_rehearsal==true || initialOwnerExtraInfo_Studio.allows_recording==false) )
            ||
            (studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS
              && (initialOwnerExtraInfo_Studio.allows_rehearsal==false || initialOwnerExtraInfo_Studio.allows_recording==false) )  ) 
        {
          var tmpOwnerExtraInfo_Studio : Studio_OwnerExtraInfo = {
            id: -1,
            owner_id: -1,
            allows_rehearsal: false,
            allows_recording: false
          };
          if(studioRehearsalsRecordings == Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY) {
            tmpOwnerExtraInfo_Studio.allows_rehearsal = true;
            tmpOwnerExtraInfo_Studio.allows_recording = false;

            LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = true;
            LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = false;
          }
          else if(studioRehearsalsRecordings == Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY) {
            tmpOwnerExtraInfo_Studio.allows_rehearsal = false;
            tmpOwnerExtraInfo_Studio.allows_recording = true;

            LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = false;
            LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = true;
          }
          else if(studioRehearsalsRecordings == Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS) {
            tmpOwnerExtraInfo_Studio.allows_rehearsal = true;
            tmpOwnerExtraInfo_Studio.allows_recording = true;

            LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = true;
            LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = true;
          }
          console.log("paw na kalesw editOwnerExtraInfo me input tmpOwnerExtraInfo_Studio: ", tmpOwnerExtraInfo_Studio);
          await Studio_RestManager.getInstance().editOwnerExtraInfo(tmpOwnerExtraInfo_Studio);
          extraInfoHasChangedAndRoomsNeedUpdate_Studio = true;
        }
        //-edit extra info
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        updatedOwner = await Tennis_RestManager.getInstance().editOwner(curOwner);

        //edit extra info (if changed)
        if( (tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY
              && (initialOwnerExtraInfo_Tennis.allows_reservations_to_members==false || initialOwnerExtraInfo_Tennis.allows_reservations_to_non_members==true) )
            ||
            (tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS
              && (initialOwnerExtraInfo_Tennis.allows_reservations_to_members==false || initialOwnerExtraInfo_Tennis.allows_reservations_to_non_members==false) )
            ||
            (tennisPrivateClubMode==Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE
              && (initialOwnerExtraInfo_Tennis.allows_reservations_to_members==true || initialOwnerExtraInfo_Tennis.allows_reservations_to_non_members==false) )  ) 
        {
          var tmpOwnerExtraInfo_Tennis : Tennis_OwnerExtraInfo = {
            id: -1,
            owner_id: -1,
            allows_reservations_to_members: false,
            allows_reservations_to_non_members: false
          };
          if(tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY) {
            tmpOwnerExtraInfo_Tennis.allows_reservations_to_members = true;
            tmpOwnerExtraInfo_Tennis.allows_reservations_to_non_members = false;

            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = true;
            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = false;
          }
          else if(tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS) {
            tmpOwnerExtraInfo_Tennis.allows_reservations_to_members = true;
            tmpOwnerExtraInfo_Tennis.allows_reservations_to_non_members = true;

            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = true;
            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = true;
          }
          else if(tennisPrivateClubMode==Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE) {
            tmpOwnerExtraInfo_Tennis.allows_reservations_to_members = false;
            tmpOwnerExtraInfo_Tennis.allows_reservations_to_non_members = true;

            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = false;
            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = true;
          }
          console.log("paw na kalesw editOwnerExtraInfo me input tmpOwnerExtraInfo_Tennis: ", tmpOwnerExtraInfo_Tennis);
          await Tennis_RestManager.getInstance().editOwnerExtraInfo(tmpOwnerExtraInfo_Tennis);
          extraInfoHasChangedAndCourtsNeedUpdate_Tennis = true;
        }
        //-edit extra info
      }
      //alert("Role: " + responseJson.role + ", ID: " + responseJson.ID + ", verified: " + responseJson.verified);

      //IMPORTANT: this has to be performed AFTER editUser - otherwise editUser will fail as the new password is not yet stored in DB!
      LoginManager.getInstance().password = curNonEncryptedPassword;
      LoginManager.getInstance().md5EncodedPassword = curMD5EncodedPassword;

      //update stored object
      LoginManager.getInstance().loggedInOwner = updatedOwner;

      //store user email/password locally on the device
      if(Platform.OS != 'web')
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(curNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
      else //web
        sessionStorage.setItem('storedNonEncryptedPassword', curNonEncryptedPassword);

      setCurOwner(updatedOwner);

      //setMessageToShow(i18n.t('profileUpdated'));
      setIsLoading(false);
      setSnackBarText(i18n.t('profileUpdated'));
      
      if(extraInfoHasChangedAndRoomsNeedUpdate_Studio == true) {
        //init
        tmpNewOwnerExtraInfo_Studio = await Studio_RestManager.getInstance().getOwnerExtraInfo(LoginManager.getInstance().ID);
        if(tmpNewOwnerExtraInfo_Studio!=null && tmpNewOwnerExtraInfo_Studio!=undefined) {
          setInitialOwnerExtraInfo_Studio(tmpNewOwnerExtraInfo_Studio);
          setOwnerExtraInfo_Studio(tmpNewOwnerExtraInfo_Studio);
        }
        //-init
        
        var tmpWhatHasBeenEnabled_Studio = "nothing";
        if(initialOwnerExtraInfo_Studio.allows_rehearsal==false 
            && (studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY
                || studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS)) 
        {
          tmpWhatHasBeenEnabled_Studio = "rehearsal";
        }
        else if(initialOwnerExtraInfo_Studio.allows_recording==false 
            && (studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY
                || studioRehearsalsRecordings==Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS)) 
        {
          tmpWhatHasBeenEnabled_Studio = "recording";
        }

        if(tmpWhatHasBeenEnabled_Studio != "nothing") {
          var tmpMsg : string = "";
          var tmpTitle : string = "";
          if(tmpWhatHasBeenEnabled_Studio == "rehearsal") {
            tmpMsg = i18n.t('youNeedToSetCostForRehersal');
            tmpTitle = i18n.t('defineRehearsalCost');

            if(MyAlertForWeb != null)
              setCostForRehersalNeeded_forWebAlert(true);
          }
          else if(tmpWhatHasBeenEnabled_Studio == "recording") {
            tmpMsg = i18n.t('youNeedToSetCostForRecording');
            tmpTitle = i18n.t('defineRecordingCost');

            if(MyAlertForWeb != null)
              setCostForRecordingNeeded_forWebAlert(true);
          }

          if(MyAlertReactNative != null) {
            MyAlertReactNative.alert(
              i18n.t('roomEditingNeeded'),
              tmpMsg,
              [
                {
                  text: 'OK',
                  onPress: () => 
                  {
                    navigation.navigate('OwnerManageRooms', { 
                      title: i18n.t('manageRooms'), 
                      backLabel: i18n.t('back'),
                      params: { ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile: true }
                    });
                  }
                }
              ],
              { cancelable: false }
            );
          }
        }
      }
      else if(extraInfoHasChangedAndCourtsNeedUpdate_Tennis == true) {
        //init
        tmpNewOwnerExtraInfo_Tennis = await Tennis_RestManager.getInstance().getOwnerExtraInfo(LoginManager.getInstance().ID);
        if(tmpNewOwnerExtraInfo_Tennis!=null && tmpNewOwnerExtraInfo_Tennis!=undefined) {
          setInitialOwnerExtraInfo_Tennis(tmpNewOwnerExtraInfo_Tennis);
          setOwnerExtraInfo_Tennis(tmpNewOwnerExtraInfo_Tennis);
        }
        //-init

        var tmpWhatHasBeenEnabled_Tennis = "nothing";
        var tmpMembershipsNeedToBeDeleted_Tennis = false;
        if(initialOwnerExtraInfo_Tennis.allows_reservations_to_members==false 
            && (tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY
                || tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS)) 
        {
          tmpWhatHasBeenEnabled_Tennis = "club members";
        }
        else if(initialOwnerExtraInfo_Tennis.allows_reservations_to_non_members==false 
            && (tennisPrivateClubMode==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS
                || tennisPrivateClubMode==Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE)) 
        {
          tmpWhatHasBeenEnabled_Tennis = "anyone";
        }
        if(initialOwnerExtraInfo_Tennis.allows_reservations_to_members==true 
          && tennisPrivateClubMode==Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE)
        {
          tmpMembershipsNeedToBeDeleted_Tennis = true;
        }
        
        if(tmpMembershipsNeedToBeDeleted_Tennis == true) {
          await Tennis_RestManager.getInstance().deleteAllOwnerClubMemberships();
          LoginManager.getInstance().loggedInTennisOwner_memberships = [];
        }
        
        if(tmpWhatHasBeenEnabled_Tennis != "nothing") {
          var tmpMsg : string = "";
          var tmpTitle : string = "";
          if(tmpWhatHasBeenEnabled_Tennis == "club members") {
            tmpMsg = i18n.t('youNeedToDefineAtLeastOneMembershipPlan');
            tmpTitle = i18n.t('defineMembershipPlans');
            if(MyAlertReactNative != null) {
              MyAlertReactNative.alert(
                i18n.t('membershipPlansNeeded'),
                tmpMsg,
                [
                  {
                    text: 'OK',
                    onPress: () => 
                    {
                      navigation.navigate('OwnerManageTennisClubMemberships', { 
                        title: tmpTitle, 
                        backLabel: i18n.t('back')
                      });
                    }
                  }
                ],
                { cancelable: false }
              );
            }
            else
              setMembershipPlansNeeded_forWebAlert(true);
          }
          else if(tmpWhatHasBeenEnabled_Tennis == "anyone") {
            tmpMsg = i18n.t('youNeedToDefineTheCostPerHourForEachCourtForNonMembers');
            tmpTitle = i18n.t('defineTheCostPerHourForEachCourt');

            if(MyAlertReactNative != null) {
              MyAlertReactNative.alert(
                i18n.t('courtEditingNeeded'),
                tmpMsg,
                [
                  {
                    text: 'OK',
                    onPress: () => 
                    {
                      navigation.navigate('OwnerManageRooms', { 
                        title: tmpTitle, 
                        backLabel: i18n.t('back'),
                        params: { ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile: true }
                      });
                    }
                  }
                ],
                { cancelable: false }
              );
            }
            else
              setCourtEditingNeeded_forWebAlert(true);
          }
        }
      }
    }
  }, [JSON.stringify(curOwner), studioRehearsalsRecordings, tennisPrivateClubMode]);

  async function saveCoordinatesAsync() {
    setIsLoading(true);       
    const tmpUpdatedOwner : Owner = {
      id: curOwner.id,
      name: curOwner.name,
      surname: curOwner.surname,
      telephone: curOwner.telephone,
      email: curOwner.email,
      password: curOwner.password,
      company_name: curOwner.company_name,
      postal_address: curOwner.postal_address,
      city: curOwner.city,
      country: curOwner.country,
      longitude: region.longitude,
      latitude: region.latitude,
      website: curOwner.website,
      last_login: curOwner.last_login,
      language: curOwner.language,
      email_notifications: curOwner.email_notifications,
      registration_date: curOwner.registration_date,
      verified: curOwner.verified,    //verified is when he succesfully adds the verification code sent by HandyBooker
      active: curOwner.active,        //when active==false, this means that I manually created the user
      info_source: curOwner.info_source
    }
    var updatedOwner : Owner = LoginManager.getInstance().loggedInOwner;
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      updatedOwner = await Studio_RestManager.getInstance().editOwner(tmpUpdatedOwner);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      updatedOwner = await Tennis_RestManager.getInstance().editOwner(tmpUpdatedOwner);
    
    //update stored object
    LoginManager.getInstance().loggedInOwner = updatedOwner;
    setCurOwner(tmpUpdatedOwner);
    //update owners stored array
    const index = GUIManager.getInstance().allOwners.findIndex(owner => owner.id === tmpUpdatedOwner.id);
    GUIManager.getInstance().allOwners.splice(index, 1, tmpUpdatedOwner);

    //setMessageToShow(i18n.t('profileUpdated'));
    setIsLoading(false);
    setVisibleModal(false);
    if(Platform.OS === 'web')
      setSnackBarText(i18n.t('coordinatesUpdatedWeb'));
    else
      setSnackBarText(i18n.t('coordinatesUpdated'));
  }

  const debouncedOnRegionChangeComplete = _.debounce((region) => setRegion(region), 3000); //update it once in 3 sec
  const onWebMapMove = (event) => {
    // Log the center coordinates when the map is moved
    const newCenter = event.target.getCenter();
    const newLat = parseFloat(newCenter.lat.toFixed(6));
    const newLon = parseFloat(newCenter.lng.toFixed(6));
    console.log('Web Map - Center Coordinates:', newLat, newLon);
    setRegion({...region, 
      latitude: newLat + GenericConstManager.getInstance().WEB_MAP_LAT_OFFSET,
      longitude: newLon + GenericConstManager.getInstance().WEB_MAP_LON_OFFSET
    });
  };

  const WebMapEvents = () => {
    const map = useMapEvents({
      move: onWebMapMove,
    });

    return null;
  };

  // Create a ref for the TextInput components
  const nameInputRef = useRef(null);
  const surnameInputRef = useRef(null);
  const telephoneInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const passwordAgainInputRef = useRef(null);
  const companyNameInputRef = useRef(null);
  const postalAddressInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const countryInputRef = useRef(null);
  const websiteInputRef = useRef(null);

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
  >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
              <Portal>
                <Modal visible={visibleModal} onDismiss={hideModal} contentContainerStyle={styles.modalContainerOwnerProfile}>
                  <View style={{flex: 1}}>          
                    {MyMapViewForReactNative!=null && 
                      <MyMapViewForReactNative style={styles.mapStyleOwnerPinOnMap} 
                        region={region}
                        showsUserLocation={true}
                        userLocationAnnotationTitle={i18n.t('myLocation')}
                        onRegionChangeComplete={debouncedOnRegionChangeComplete}
                      >
                      </MyMapViewForReactNative>
                    }
                    {MyMapViewForWeb==true && 
                      <View>
                        <WebMap ref={webMapRef} key={JSON.stringify(initialPositionBasedOnIP)} 
                                center={[initialPositionBasedOnIP.latitude, initialPositionBasedOnIP.longitude]} 
                                zoom={zoomLevel} 
                                style={styles.mapStyleOwnerPinOnMap}
                        >
                          <WebTileLayer
                            attribution='&copy; <a href="https://tile.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <WebMapEvents />
                        </WebMap>
                      </View>
                    }
                    <View style={styles.markerContainer}>
                      <View style={styles.markerContainerChild} pointerEvents="none" /*Set pointerEvents to 'none' to make it non-interactive*/>
                        <Image
                          source={myMarkerImg}                      
                          style={styles.markerTargetImage} 
                        />
                      </View>
                    </View>
                    <Button icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => saveCoordinatesAsync()}>{i18n.t('verifyAddress')}</Button>
                  </View>
                </Modal>
              </Portal>

                <ScrollView style={styles.scrollView}>
                  <Appbar.Header>
                    <Appbar.Content title={i18n.t('myProfile')} />
                    <Appbar.Action icon={({ color, size }) => (<MaterialCommunityIcons name="map-marker-radius" color={color} size={size} />)} onPress={showModal} />
                  </Appbar.Header>
                  <Spinner
                    visible={isLoading}
                    textContent={i18n.t('loading')}
                    textStyle={styles.spinnerTextStyle}
                  />
                  <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{
                      LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('studioOwnerInfo')
                      : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourtOwnerInfo') 
                      : "UNKNOWN"}
                  </Title>
                  <TouchableOpacity onPress={() => nameInputRef.current.focus()}>
                    <TextInput
                      ref={nameInputRef}
                      label={i18n.t('name')}
                      value={curOwner.name}
                      onChangeText={tmpName => setCurOwner({...curOwner,
                        name: tmpName
                      })}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => surnameInputRef.current.focus()}>
                    <TextInput
                      ref={surnameInputRef}
                      label={i18n.t('surname')}
                      value={curOwner.surname}
                      onChangeText={tmpSurname => setCurOwner({...curOwner, 
                        surname: tmpSurname
                      })}
                    />
                  </TouchableOpacity>
                  <TextInput
                    label="Email"
                    value={curOwner.email}
                    style={{color: 'red', backgroundColor: 'lightgray'}}
                    disabled={true}
                  />
                  <TouchableOpacity onPress={() => telephoneInputRef.current.focus()}>
                    <TextInput
                      ref={telephoneInputRef}
                      label={i18n.t('telephone')}
                      value={curOwner.telephone}
                      onChangeText={tmpTelephone => setCurOwner({...curOwner, 
                        telephone: tmpTelephone
                      })}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => passwordInputRef.current.focus()}>
                    <TextInput
                      ref={passwordInputRef}
                      label={i18n.t('password')}
                      value={curNonEncryptedPassword}
                      onChangeText={tmpPassword => setCurNonEncryptedPassword(tmpPassword)}
                      secureTextEntry
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => passwordAgainInputRef.current.focus()}>
                    <TextInput
                      ref={passwordAgainInputRef}
                      label={i18n.t('passwordAgain')}
                      value={curNonEncryptedPasswordAgain}
                      onChangeText={tmpPasswordAgain => setCurNonEncryptedPasswordAgain(tmpPasswordAgain)}
                      secureTextEntry
                    />
                  </TouchableOpacity>
                  <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('companyInfo')}</Title>

                  {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <RadioButton.Group onValueChange={newValue => setStudioRehearsalsRecordings(newValue)} value={studioRehearsalsRecordings}>
                    <RadioButton.Item
                      key={Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY}
                      label={i18n.t('reservationsOnlyForRehearsal')}
                      value={Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY}
                      status={ studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY ? 'checked' : 'unchecked' }
                    />
                    <RadioButton.Item
                      key={Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY}
                      label={i18n.t('reservationsOnlyForRecording')}
                      value={Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY}
                      status={ studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY ? 'checked' : 'unchecked' }
                    />
                    <RadioButton.Item
                      key={Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS}
                      label={i18n.t('reservationsForRehearsalAndRecording')}
                      value={Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS}
                      status={ studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS ? 'checked' : 'unchecked' }
                    />
                  </RadioButton.Group>}

                  {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <RadioButton.Group onValueChange={newValue => setTennisPrivateClubMode(newValue)} value={tennisPrivateClubMode}>
                    <RadioButton.Item
                      key={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY}
                      label={i18n.t('onlyClubMembersCanReserve')}
                      value={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY}
                      status={ tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY ? 'checked' : 'unchecked' }
                    />
                    <RadioButton.Item
                      key={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS}
                      label={i18n.t('clubMembersAndNonClubMembersCanReserve')}
                      value={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS}
                      status={ tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS ? 'checked' : 'unchecked' }
                    />
                    <RadioButton.Item
                      key={Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE}
                      label={i18n.t('onlyNonClubMembersCanReserve')}
                      value={Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE}
                      status={ tennisPrivateClubMode === Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE ? 'checked' : 'unchecked' }
                    />
                  </RadioButton.Group>}

                  <TouchableOpacity onPress={() => companyNameInputRef.current.focus()}>
                    <TextInput
                      ref={companyNameInputRef}
                      label={i18n.t('companyName')}
                      value={curOwner.company_name}
                      onChangeText={tmpCompanyName => setCurOwner({...curOwner, 
                        company_name: tmpCompanyName
                      })}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => postalAddressInputRef.current.focus()}>
                    <TextInput
                      ref={postalAddressInputRef}
                      label={i18n.t('postalAddress')}
                      value={curOwner.postal_address}
                      onChangeText={tmpPostalAddress => setCurOwner({...curOwner, 
                        postal_address: tmpPostalAddress
                      })}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => cityInputRef.current.focus()}>
                    <TextInput
                      ref={cityInputRef}
                      label={i18n.t('city')}
                      value={curOwner.city}
                      onChangeText={tmpCity => setCurOwner({...curOwner, 
                        city: tmpCity
                      })}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => countryInputRef.current.focus()}>
                    <TextInput
                      ref={countryInputRef}
                      label={i18n.t('country')}
                      value={curOwner.country}
                      onChangeText={tmpCountry => setCurOwner({...curOwner, 
                        country: tmpCountry
                      })}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => websiteInputRef.current.focus()}>
                    <TextInput
                      ref={websiteInputRef}
                      label={i18n.t('website')}
                      value={curOwner.website}
                      onChangeText={tmpWebsite => setCurOwner({...curOwner, 
                        website: tmpWebsite
                      })}
                    />
                  </TouchableOpacity>
                  <Button icon={({ color, size }) => (<MaterialCommunityIcons name="content-save" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={saveProfileAsync}>{i18n.t('save')}</Button>
                </ScrollView>
                <Snackbar
                  style={styles.snackbarUpper}
                  visible={snackBarVisible}
                  onDismiss={onDismissSnackBar}>
                  {snackBarText}
                </Snackbar> 
                {MyAlertForWeb!=null && 
                  <MyAlertForWeb
                    show={alertForWebVisible}
                    showProgress={false}
                    title={i18n.t('caution')}
                    message={alertForWebText}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    confirmText="OK"
                    confirmButtonColor="#DD6B55"
                    onCancelPressed={() => {
                      setAlertForWebVisible(false);
                    }}
                    onConfirmPressed={() => {
                      setAlertForWebVisible(false);

                      if(costForRehersalNeeded_forWebAlert == true) {
                        setCostForRehersalNeeded_forWebAlert(false);

                        navigation.navigate('OwnerManageRooms', { 
                          title: i18n.t('manageRooms'), 
                          backLabel: i18n.t('back'),
                          params: { ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile: true }
                        });
                      }
                      else if(costForRecordingNeeded_forWebAlert == true) {
                        setCostForRecordingNeeded_forWebAlert(false);

                        navigation.navigate('OwnerManageRooms', { 
                          title: i18n.t('manageRooms'), 
                          backLabel: i18n.t('back'),
                          params: { ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile: true }
                        });
                      }
                      else if(membershipPlansNeeded_forWebAlert == true) {
                        setMembershipPlansNeeded_forWebAlert(false);

                        navigation.navigate('OwnerManageTennisClubMemberships', { 
                          title: i18n.t('defineMembershipPlans'), 
                          backLabel: i18n.t('back')
                        });
                      }
                      else if(courtEditingNeeded_forWebAlert == true) {
                        setCourtEditingNeeded_forWebAlert(false);

                        navigation.navigate('OwnerManageRooms', { 
                          title: i18n.t('manageRooms'), 
                          backLabel: i18n.t('back'),
                          params: { ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile: true }
                        });
                      }
                    }}  
                  />
                }
              </View>
              <RightColumnWeb/>
            </View>
          </TouchableWithoutFeedback>
          <AdBanner/>
        </KeyboardAvoidingView>
    );
  };

export default OwnerMyProfileView;
