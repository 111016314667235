import React, { useState, useEffect } from 'react';
import { Appbar, Button, Switch, Text, Snackbar, Portal, Dialog, Paragraph, TextInput } from 'react-native-paper';
import { Alert, Dimensions, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View, Image } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import styles from '../../css/myCss';
import AdBanner from '../generic/AdBanner';
import DropDown from 'react-native-paper-dropdown';
import UtilsManager from '../../managers/UtilsManager';
import GUIManager from '../../managers/GUIManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import Generic_ConstManager from '../../managers/Generic_ConstManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RightColumnWeb from '../generic/RightColumnWeb';
import LeftColumnWeb from '../generic/LeftColumnWeb';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const enFlag =  require("../../assets/EN_flag.png");
const enFlagSelected =  require("../../assets/EN_flag_selected.png");
const grFlag =  require("../../assets/GR_flag.png");
const grFlagSelected =  require("../../assets/GR_flag_selected.png");

const OwnerSettingsView = () => {

//////////////////////////////////
// Generic, All domains - START //
//////////////////////////////////
  const navigation = useNavigation();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerSettingsView: I'm ready!");
    (async () => {
      setIsLoading(true);
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
      if(handleWebPageReloadActuallyRun) {
        setCurOwner(LoginManager.getInstance().loggedInOwner);
      }
      //initialize currency - get the currency of the first room
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        var tmpRoom : Studio_Room | undefined = undefined;
        if(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.length > 0) { //first examine if there are stored rooms
          tmpRoom = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
          console.log("...initializing currency using GUIManager.getInstance().allRoomsOfLoggedInOwner[0].currency -> " + tmpRoom.currency);
        }
        else { //call the web service
          const tmpAllRoomsOfOwner : Array<Studio_Room> = await Studio_RestManager.getInstance().getAllRoomsOfOwner(LoginManager.getInstance().loggedInOwner.id);
          if(tmpAllRoomsOfOwner.length > 0) {
            tmpRoom = tmpAllRoomsOfOwner[0]; //just get the first one
            console.log("...initializing currency using Studio_RestManager.getInstance().getAllRoomsOfOwner(LoginManager.getInstance().loggedInOwner.id)[0].currency -> " + tmpRoom.currency);
          }
        }
        if(tmpRoom != undefined) {
          setInitCurrency(tmpRoom.currency);
          setSelectedCurrency(tmpRoom.currency);
          setRoomJustToGetOwnerIdAndNewCurrency_Studio(tmpRoom);
        }
        else {
          console.log("ERROR: tmpRoom is null and, thus, I cannot initialize currency!")
        }
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        var tmpCourt : Tennis_Court | undefined = undefined;
        if(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.length > 0) { //first examine if there are stored rooms
          tmpCourt = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
          console.log("...initializing currency using GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0].currency -> " + tmpCourt.currency);
        }
        else { //call the web service
          const tmpAllCourtsOfOwner : Array<Tennis_Court> = await Tennis_RestManager.getInstance().getAllCourtsOfOwner(LoginManager.getInstance().loggedInOwner.id);
          if(tmpAllCourtsOfOwner.length > 0) {
            tmpCourt = tmpAllCourtsOfOwner[0]; //just get the first one
            console.log("...initializing currency using Tennis_RestManager.getInstance().getAllCourtsOfOwner(LoginManager.getInstance().loggedInOwner.id)[0].currency -> " + tmpCourt.currency);
          }
        }
        if(tmpCourt != undefined) {
          setInitCurrency(tmpCourt.currency);
          setSelectedCurrency(tmpCourt.currency);
          setCourtJustToGetOwnerIdAndNewCurrency_Tennis(tmpCourt);
        }
        else {
          console.log("ERROR: tmpCourt is null and, thus, I cannot initialize currency!")
        }
      }
      setIsLoading(false);
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const onDismissSnackBar = () => {
    setSnackBarVisible(false);
    setMessageToShow('');
  }

  const [initCurrency, setInitCurrency] = useState("");
  const [currencyVisible, setCurrencyVisible] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState("");
  useEffect(() => {
    if(selectedCurrency != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO 
          && roomJustToGetOwnerIdAndNewCurrency_Studio.id != -1 
          && roomJustToGetOwnerIdAndNewCurrency_Studio.currency != "" 
          && selectedCurrency != roomJustToGetOwnerIdAndNewCurrency_Studio.currency) 
      {
        console.log("new currency: ", selectedCurrency);
        setIsLoading(true);
        setRoomJustToGetOwnerIdAndNewCurrency_Studio({...roomJustToGetOwnerIdAndNewCurrency_Studio, currency: selectedCurrency});
        //then go to useEffect [roomJustToGetOwnerIdAndNewCurrency_Studio]
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS 
          && courtJustToGetOwnerIdAndNewCurrency_Tennis.id != -1 
          && courtJustToGetOwnerIdAndNewCurrency_Tennis.currency != "" 
          && selectedCurrency != courtJustToGetOwnerIdAndNewCurrency_Tennis.currency) 
      {
        console.log("new currency: ", selectedCurrency);
        setIsLoading(true);
        setCourtJustToGetOwnerIdAndNewCurrency_Tennis({...courtJustToGetOwnerIdAndNewCurrency_Tennis, currency: selectedCurrency});
        //then go to useEffect [courtJustToGetOwnerIdAndNewCurrency_Tennis]
      }
    }
  }, [selectedCurrency]);

  const [deleteAccountDialogVisible, setDeleteAccountDialogVisible] = useState(false);
  const hideDeleteAccountDialog = () => setDeleteAccountDialogVisible(false);

  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow.length > 0)
    {
      var translatedMessageToShow: string | void = messageToShow;
      if(messageToShow === "Owner deleted!") {
        translatedMessageToShow = i18n.t('accountDeleted');
        setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
          // Works on both Android and iOS
          if(MyAlertReactNative != null) {
            MyAlertReactNative.alert(
              translatedMessageToShow,
              '',
              [
                {
                  text: 'OK',
                  onPress: () => {
                    logout();
                  }
                }
              ],
              { cancelable: false }
            );
          }
          else if(MyAlertForWeb != null) {
            setAlertForWebText(translatedMessageToShow);
            setAlertForWebVisible(true);
          }
        }, 500);
      }
      else {
        setSnackBarVisible(true);
      }      
    }
  }, [isLoading]);
  
  const [curOwner, setCurOwner] = useState<Owner>(LoginManager.getInstance().loggedInOwner);

  const [updateLanguageNow, setUpdateLanguageNow] = useState(false);
  useEffect(() => {
    if(updateLanguageNow === true) {
      setUpdateLanguageNow(false);
      editOwnerAsync();
    }
  }, [curOwner.language]);

  const [updateEmailNotificationsNow, setUpdateEmailNotificationsNow] = useState(false);
  useEffect(() => {
    if(updateEmailNotificationsNow === true) {
      setUpdateEmailNotificationsNow(false);
      editOwnerAsync();
      if(curOwner.email_notifications === true)
        setMessageToShow(i18n.t('yesEmailNotifications'));
      else
        setMessageToShow(i18n.t('noEmailNotifications'));
    }
  }, [curOwner.email_notifications]);

  const screenDimensions = Dimensions.get('window');

  const [curEnFlag, setCurEnFlag] = useState(enFlagSelected);
  const [curGrFlag, setCurGrFlag] = useState(grFlag);
  useEffect(() => {    
    if(curOwner.language === 'EN')
    {
      i18n.locale = 'en';
      setCurEnFlag(enFlagSelected);
      setCurGrFlag(grFlag);
      LoginManager.getInstance().language = "EN";
      if(Platform.OS != 'web')
        SecureStore.setItemAsync('storedLanguage', JSON.stringify('en')).catch((error) => console.log('Error during storedEmail storage!', error));
      else //web
        sessionStorage.setItem('storedLanguage', 'en');
    }
    else if(curOwner.language === 'GR')
    {
      i18n.locale = 'gr';
      setCurEnFlag(enFlag);
      setCurGrFlag(grFlagSelected);
      LoginManager.getInstance().language = "GR";
      if(Platform.OS != 'web')
        SecureStore.setItemAsync('storedLanguage', JSON.stringify('gr')).catch((error) => console.log('Error during storedEmail storage!', error));
      else //web
        sessionStorage.setItem('storedLanguage', 'gr');
    }
  }, [curOwner.language, curEnFlag, curGrFlag]);

  
  async function editOwnerAsync() {        
    setIsLoading(true);       
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
      const editOwnerResponse = await Studio_RestManager.getInstance().editOwner(curOwner);
    }
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
      const editOwnerResponse = await Tennis_RestManager.getInstance().editOwner(curOwner);
    }
    setIsLoading(false);
  }

  async function deleteUserAsync() {     
    setDeleteAccountDialogVisible(false);   
    setIsLoading(true);       
    var webServiceResponseStr : string | void = "";
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      webServiceResponseStr = await Studio_RestManager.getInstance().deleteOwner(curOwner.email);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      webServiceResponseStr = await Tennis_RestManager.getInstance().deleteOwner(curOwner.email);
    setMessageToShow(webServiceResponseStr);
    setIsLoading(false); 
  }

  function logout() {
    LoginManager.getInstance().logout();
    navigation.reset({
      index: 1,
      routes: [{ name: 'HandyBooker_Home' }]
    });
  }
////////////////////////////////
// Generic, All domains - END //
////////////////////////////////

///////////////////////////
// Studio domain - START //
///////////////////////////
    const [roomJustToGetOwnerIdAndNewCurrency_Studio, setRoomJustToGetOwnerIdAndNewCurrency_Studio] = useState<Studio_Room>({
      id: -1,
      owned_by: LoginManager.getInstance().loggedInOwner.id,
      name: '',
      currency: '',
      monday_cost_per_hour_reh: -1,
      monday_cost_per_hour_rec: -1,
      tuesday_cost_per_hour_reh: -1,
      tuesday_cost_per_hour_rec: -1,
      wednesday_cost_per_hour_reh: -1,
      wednesday_cost_per_hour_rec: -1,
      thursday_cost_per_hour_reh: -1,
      thursday_cost_per_hour_rec: -1,
      friday_cost_per_hour_reh: -1,
      friday_cost_per_hour_rec: -1,
      saturday_cost_per_hour_reh: -1,
      saturday_cost_per_hour_rec: -1,
      sunday_cost_per_hour_reh: -1,
      sunday_cost_per_hour_rec: -1,
      monday_working_hours_start: '',
      tuesday_working_hours_start: '',
      wednesday_working_hours_start: '',
      thursday_working_hours_start: '',
      friday_working_hours_start: '',
      saturday_working_hours_start: '',
      sunday_working_hours_start: '',
      monday_working_hours_stop: '',
      tuesday_working_hours_stop: '',
      wednesday_working_hours_stop: '',
      thursday_working_hours_stop: '',
      friday_working_hours_stop: '',
      saturday_working_hours_stop: '',
      sunday_working_hours_stop: '',    
      space: '',
      speakers: '',
      console: '',
      microphones: '',
      drums: '',
      guitars: '',
      basses: '',
      pianos_keyboards: '',
      guitar_amplifiers: '',
      bass_amplifiers: '',
      other_equipment: ''
    });
    useEffect(() => {
      (async () => {
        if(selectedCurrency != "" 
            && roomJustToGetOwnerIdAndNewCurrency_Studio.id != -1 
            && roomJustToGetOwnerIdAndNewCurrency_Studio.currency != ""
            && roomJustToGetOwnerIdAndNewCurrency_Studio.currency != initCurrency) 
        {
          GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio = await Studio_RestManager.getInstance().editCurrencyOfAllRoomsOfCurOwner(roomJustToGetOwnerIdAndNewCurrency_Studio);
          console.log("   editCurrencyOfAllRoomsOfCurOwner -> done!");
          setIsLoading(false);
        }
      })();
    }, [roomJustToGetOwnerIdAndNewCurrency_Studio]);
/////////////////////////
// Studio domain - END //
/////////////////////////

///////////////////////////
// Tennis domain - START //
///////////////////////////
  const [courtJustToGetOwnerIdAndNewCurrency_Tennis, setCourtJustToGetOwnerIdAndNewCurrency_Tennis] = useState<Tennis_Court>({
    id: -1,
    owned_by: LoginManager.getInstance().loggedInOwner.id,
    name: '',
    type: -1,
    currency: '',
    monday_cost_per_hour: -1,
    tuesday_cost_per_hour: -1,
    wednesday_cost_per_hour: -1,
    thursday_cost_per_hour: -1,
    friday_cost_per_hour: -1,
    saturday_cost_per_hour: -1,
    sunday_cost_per_hour: -1,
    monday_working_hours_start: '',
    tuesday_working_hours_start: '',
    wednesday_working_hours_start: '',
    thursday_working_hours_start: '',
    friday_working_hours_start: '',
    saturday_working_hours_start: '',
    sunday_working_hours_start: '',
    monday_working_hours_stop: '',
    tuesday_working_hours_stop: '',
    wednesday_working_hours_stop: '',
    thursday_working_hours_stop: '',
    friday_working_hours_stop: '',
    saturday_working_hours_stop: '',
    sunday_working_hours_stop: ''
  });
  useEffect(() => {
    (async () => {
      if(selectedCurrency != "" 
          && courtJustToGetOwnerIdAndNewCurrency_Tennis.id != -1 
          && courtJustToGetOwnerIdAndNewCurrency_Tennis.currency != ""
          && courtJustToGetOwnerIdAndNewCurrency_Tennis.currency != initCurrency) 
      {
        GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis = await Tennis_RestManager.getInstance().editCurrencyOfAllCourtsOfCurOwner(courtJustToGetOwnerIdAndNewCurrency_Tennis);
        console.log("   editCurrencyOfAllCourtsOfCurOwner -> done!");
        setIsLoading(false);
      }
    })();
  }, [courtJustToGetOwnerIdAndNewCurrency_Tennis]);
/////////////////////////
// Tennis domain - END //
/////////////////////////

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
          

              <ScrollView style={styles.scrollView}>
                <Appbar.Header>
                  <Appbar.Content title={i18n.t('settings')} />

                <Portal>
                  <Dialog visible={deleteAccountDialogVisible} onDismiss={hideDeleteAccountDialog} style={styles.dialog}>
                    <Dialog.Title>{i18n.t('deleteAccount')}</Dialog.Title>
                    <Dialog.Content>
                      <Paragraph>{i18n.t('areYouSure')}</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                      <Button onPress={() => setDeleteAccountDialogVisible(false)}>{i18n.t('cancel')}</Button>
                      <Button onPress={() => deleteUserAsync()}>Ok</Button>
                    </Dialog.Actions>
                  </Dialog>
                </Portal>
                </Appbar.Header>

                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />

                <View style={{flexDirection:"row", marginTop: 20}}>

                  <View style={{flex:1, alignItems:'flex-end'}}>
                    <TouchableOpacity
                      onPress={() => { 
                          setCurOwner({...curOwner, 
                            language: 'EN'
                          });
                          LoginManager.getInstance().language = "EN";
                          setUpdateLanguageNow(true);
                        }
                      }            
                    >
                      <Image
                        source={curEnFlag}
                        style={styles.flag}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={{flex:1, alignItems:'flex-start'}}>
                    <TouchableOpacity style={{justifyContent:"center"}}
                      onPress={() => { 
                          setCurOwner({...curOwner, 
                            language: 'GR'
                          });
                          LoginManager.getInstance().language = "GR";
                          setUpdateLanguageNow(true);
                        }
                      }
                    >
                      <Image
                        source={curGrFlag}
                        style={styles.flag}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
    
                <View style={{flexDirection:"row", marginTop: 20}}>
                  <Text style={styles.notificationSettingsText}>{i18n.t('receiveEmailNotifications')}</Text> 
                  <Switch style={styles.notificationSettingsSwitch} value={curOwner.email_notifications} onValueChange={() => {
                        setCurOwner({...curOwner, 
                          email_notifications: !curOwner.email_notifications
                        });
                        setUpdateEmailNotificationsNow(true);
                      }} />    
                </View> 

                <DropDown
                  label={i18n.t('currency')}
                  mode={'flat'}
                  visible={currencyVisible}
                  showDropDown={() => setCurrencyVisible(true)}
                  onDismiss={() => setCurrencyVisible(false)}
                  value={selectedCurrency}
                  setValue={tmpCurrency => setSelectedCurrency(tmpCurrency)}
                  list={UtilsManager.getInstance().currencyList}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={currencyVisible==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                />
              </ScrollView>
              <View style={{flexDirection:"column"}}>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-remove" color={color} size={size} />)} mode="contained" style={{alignSelf: 'flex-end', width: Platform.OS!="web" ? screenDimensions.width : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH}} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContentRed} onPress={() => setDeleteAccountDialogVisible(true)}>{i18n.t('deleteAccount')}</Button>
              </View>
              <Snackbar
                style={{borderColor: 'white', borderWidth: 3, backgroundColor: '#6200EE', bottom: 100}}
                visible={snackBarVisible}
                onDismiss={onDismissSnackBar}>
                {messageToShow}
              </Snackbar>   
              {MyAlertForWeb!=null && 
                <MyAlertForWeb
                  show={alertForWebVisible}
                  showProgress={false}
                  title={i18n.t('caution')}
                  message={alertForWebText}
                  closeOnTouchOutside={false}
                  closeOnHardwareBackPress={false}
                  showConfirmButton={true}
                  confirmText="OK"
                  confirmButtonColor="#DD6B55"
                  onCancelPressed={() => {
                    setAlertForWebVisible(false);
                  }}
                  onConfirmPressed={() => {
                    setAlertForWebVisible(false);
                    logout();
                  }}  
                />
              }
            </View>
            <RightColumnWeb/>
          </View>
        </TouchableWithoutFeedback>
        <AdBanner/>
      </KeyboardAvoidingView>
    );
  };

export default OwnerSettingsView;