import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { TextInput } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OwnerDropdownTuesdayFrom = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeTuesdayStartHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeTuesdayStartHour_Tennis,
                                
                                setTuesdayStartHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Studio.showTuesdayStartHour}
        showDropDown={() => setMyData_Studio({...myData_Studio, showTuesdayStartHour: true})}
        onDismiss={() => setMyData_Studio({...myData_Studio, showTuesdayStartHour: false})}
        value={myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_working_hours_start=="closed" ? proposeTuesdayStartHour_Studio : selectedRoom_Studio.tuesday_working_hours_start}
        setValue={setTuesdayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showTuesdayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Tennis.showTuesdayStartHour}
        showDropDown={() => setMyData_Tennis({...myData_Tennis, showTuesdayStartHour: true})}
        onDismiss={() => setMyData_Tennis({...myData_Tennis, showTuesdayStartHour: false})}
        value={myData_Tennis.tuesdayOpen=="true" && selectedCourt_Tennis.tuesday_working_hours_start=="closed" ? proposeTuesdayStartHour_Tennis : selectedCourt_Tennis.tuesday_working_hours_start}
        setValue={setTuesdayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showTuesdayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownTuesdayFrom/*, areEqual*/);