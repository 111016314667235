import { Dimensions, Platform, StyleSheet } from 'react-native';
import { Colors } from 'react-native-paper';
import { RFPercentage } from "react-native-responsive-fontsize";
import Generic_ConstManager from '../managers/Generic_ConstManager';
import { greaterThan } from 'react-native-reanimated';

const screenDimensions = Dimensions.get('window');

const styles = StyleSheet.create({
    about: {
        flex: 1,
        marginTop: -12,
        alignItems: "center",
        justifyContent: "center",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    adBanner: {
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'red',
        height: 50,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    adBannerGuest: {
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'red',
        height: 50,
        position: 'absolute',
        bottom: -2,
        zIndex: 1,
        elevation: 1,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },
    alert: {
        ...Platform.select({
            web: {
                height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT * 0.8
            },
            default: {
                height: screenDimensions.height * 0.8                
            },
        })
    },
    btn: {
        backgroundColor: 'white',
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/2
            },
            default: {
                width: screenDimensions.width/2                
            },
        })
    },
    btnFullWidth: {
        backgroundColor: 'white',
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },
    bottomNavigation: {
        ...Platform.select({
            web: {
                //width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,  
                //paddingLeft: Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH,
                //paddingRight: Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH             
            },
            default: {
                width: screenDimensions.width
            },
        })
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },
    card: {
        margin: 2
    },
    closedLabel : {
        color: 'white',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: 'red',
        fontSize: RFPercentage(3),
        borderColor: 'white',
        borderWidth: 2,
        paddingTop: 18,
        paddingBottom: 20,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*0.78
            },
            default: {
                
            },
        })
    },
    clubMemberTitle: {
        textAlign: 'center',
        textAlignVertical: 'center',
        color: 'green',
        fontWeight: 'bold',
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 0.5,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-10
            },
            default: {
                width: screenDimensions.width-10
            },
        })
    },
    columnBottom: {
        //backgroundColor: "yellow",
        //marginLeft: 10,
        //marginRight: 10,
        paddingTop: 10,
        alignSelf: "center",
        alignItems: "center",
        ...Platform.select({
            web: {
                display: (screenDimensions.width-10)<Generic_ConstManager.getInstance().WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS ? "flex" : "none",
                width: screenDimensions.width
            },
            default: {

            },
        })
    },
    columnContainer_threeColumnsAndBottomNavigation: {
        //backgroundColor: '#ecf0f1',
        //backgroundColor: 'brown',
        flex: 1,
        flexDirection:"row", 
        ...Platform.select({
            web: {
                //height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-123,
            },
            default: {

            },
        })
    },
    columnLeft: {
        //backgroundColor: "yellow",
        //marginLeft: 10,
        //marginRight: 10,
        alignSelf: "center",
        alignItems: "center",
        ...Platform.select({
            web: {
                display: screenDimensions.width>Generic_ConstManager.getInstance().WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS ? "flex" : "none",
                width: Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH
            },
            default: {

            },
        })
    },
    columnLeftIconButton: {
        ...Platform.select({
            web: {
                fontSize: 14,
                width: 110
            },
            default: {
                fontSize: RFPercentage(1.5)
            },
        })
    },
    columnMainApp: {
        flex: 1,
        //backgroundColor: '#ecf0f1',
        //backgroundColor: 'green',
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH > (screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH)) ? (screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH)) : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                maxwidth: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                //height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT
            },
            default: {
                width: screenDimensions.width,
                height: screenDimensions.height-60
            },
        })
    },
    columnRight: {
        //backgroundColor: "blue",
        //marginLeft: 10,
        //marginRight: 10,
        justifyContent: 'center',
        //alignItems: 'center',
        alignSelf: "center",
        ...Platform.select({
            web: {
                display: screenDimensions.width>Generic_ConstManager.getInstance().WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS ? "flex" : "none",
                width: Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH
            },
            default: {
                
            },
        })
    },
    container: {
        flex: 1,
        //backgroundColor: '#ecf0f1',
        alignContent: 'center',
        alignSelf: 'center',
        ...Platform.select({
            web: {
                width: screenDimensions.width,
                maxWidth: Generic_ConstManager.getInstance().WEB_MAX_WIDTH                
            },
            default: {
                width: screenDimensions.width,
                height: screenDimensions.height
            },
        })
    },
    containerStyle: {
        flex: 1,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    copyright: {
        color: 'gray',
        alignSelf: 'center'        
    },
    dayContainer: {
        flexDirection:"row", 
        justifyContent: "space-evenly",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    dayOpenClosed: {
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    daySwitch: {
        flex:1, 
        alignItems:'flex-end',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    dayText: { 
        padding: 8, 
        fontSize: 16, 
        flex:1, 
        alignItems:'flex-start',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    dayTitle: {
        marginTop: 30,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    dialog: {
        backgroundColor: '#ecf0f1',
        alignContent: 'center',
        alignSelf: 'center',
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/1.8,
                maxWidth: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/1.8
            },
            default: {
                width: screenDimensions.width*0.8
            },
        })
    },
    domainDropdown: {
        backgroundColor: 'white',
        paddingBottom: 5,
        ...Platform.select({
            web: {
                width: screenDimensions.width>Generic_ConstManager.getInstance().WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS ? screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH) : screenDimensions.width,
                maxWidth: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },
    emptyDate: {
        height: 15,
        flex: 1,
        paddingTop: 30,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    equipmentCard: {
        borderWidth: 0.3,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/3
            },
            default: {
                width: screenDimensions.width/3
            },
        })
    },
    equipmentImg: {
        alignSelf:'center',
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/10, 
                height: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/10
            },
            default: {
                width: screenDimensions.width/5,
                height: screenDimensions.width/5
            },
        })
    },
    equipmentCardTitle: {
        color: 'darkslategrey',
        fontWeight: 'bold',
        textAlign: 'center',
        ...Platform.select({
            web: {
                fontSize: 14
            },
            default: {
                fontSize: RFPercentage(1.7)
            },
        })
    },
    equipmentCardText: {
        textAlign: 'center',
        paddingTop: 10,
        ...Platform.select({
            web: {
                fontSize: 13
            },
            default: {
                fontSize: RFPercentage(1.7)
            },
        })
    },
    fab: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 0,
        zIndex: 1,
        elevation: 1,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    fabGuest: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 50,
        zIndex: 1,
        elevation: 1,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    fabOwnerPinOnMap: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 200,
        zIndex: 1,
        elevation: 1,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    flag: {
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/10,
                maxWidth: 50,
                height: (Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/10)*(44/44),   //height/width
                maxHeight: 50*(44/44),
            },
            default: {
                width: screenDimensions.width/10,
                height: (screenDimensions.width/10)*(44/44)
            },
        })
    },
    fullWidthInputText: {
        flex: 1,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-21
            },
            default: {
                width: screenDimensions.width-5
            },
        })
    },
    head: { 
        backgroundColor: Colors.blueGrey100, 
        justifyContent: 'center',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    iconButton: {
        ...Platform.select({
            web: {
                fontSize: 15
            },
            default: {
                fontSize: RFPercentage(2.2)
            },
        })
    },
    iconButtonSmall: {
        ...Platform.select({
            web: {
                fontSize: 15
            },
            default: {
                fontSize: RFPercentage(1.7)
            },
        })
    },
    iconButtonContent: {
        height: 50,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    iconButtonContentRed: {
        height: 50,
        backgroundColor: 'red',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    iconButtonContentWhite: {
        height: 50,
        backgroundColor: 'white',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    iconButtonContentSmall: {
        height: 52,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    inner: {
        flex: 1,
        justifyContent: "space-around",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    item: {
        backgroundColor: 'white',
        flex: 1,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 0.5,
        padding: 10,
        marginRight: 10,
        marginTop: 17,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    itemStyle: {
        padding: 10,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    lineSeparator: {
        height: 1, 
        backgroundColor: '#e0e0e0', 
        marginVertical: 10
    },
    logoHomePage: {
        ...Platform.select({
            web: {
                width: (screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH))<=Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH ? (screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH)) : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                height: (screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH))<=Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH ? (screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH))*(256/640) : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*(256/640),
                maxwidth: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH, 
                maxheight: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*(256/640)
            },
            default: {
                width: screenDimensions.width, 
                height: screenDimensions.width*(256/640)
            }
        })
    },
    mainTitle: {
        textAlign: 'center',
        color: 'blue'
    },
    mapStyle: {
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT*0.7
            },
            default: {
                width: screenDimensions.width,
                height: screenDimensions.height*0.7
            }
        })
    },
    mapStyleOwnerPinOnMapSignUp: {
        //flex: 1,
        //width: screenDimensions.width,
        //height: screenDimensions.height-(screenDimensions.height/2.6),
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-250
            },
            default: {
                width: screenDimensions.width,
                height: screenDimensions.height
            },
        })
    },
    mapStyleOwnerPinOnMap: {
        //flex: 1,
        //width: screenDimensions.width,
        //height: screenDimensions.height-(screenDimensions.height/2.6),
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/6),
                height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4)
            },
            default: {
                width: screenDimensions.width-(screenDimensions.width/6),
                height: screenDimensions.height-(screenDimensions.height/4)
            },
        })
    },
    markerContainer: {
        alignItems: 'center',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    markerContainerChild: {
        zIndex: 1, // works on ios
        elevation: 1, // works on android
        position: 'absolute', 
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })   
    },
    markerTargetImage: {
        ...Platform.select({
            web: {
                //gia na einai kentrarismenos o stoxos panw sto xarth, tha prepei 
                //to width toy na einai container_width/2 - target_width/2
                //to height toy na einai container_height/2 - target_height/2
                //---------------------------------------------------------------------------------------------------
                    //container 1 (modalContainerOwnerProfile) width: 
                        //width:
                            //web -> Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/6),
                            //mobile -> screenDimensions.width-(screenDimensions.width/6),
                        //height:
                            //web -> Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4)
                            //mobile -> screenDimensions.height-(screenDimensions.height/4)
                            //---------------------------------------------------------------------------------------
                            //container 2 (mapStyleOwnerPinOnMap) width: 
                                //width:
                                    //web -> Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/6),
                                    //mobile -> screenDimensions.width-(screenDimensions.width/6),
                                //height:
                                    //web -> Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4)
                                    //mobile -> screenDimensions.height-(screenDimensions.height/4)
                                    //-------------------------------------------------------------------------------
                                    // Oi 2 containers exoun idio width & height, opote xrhsimpoiw toy enos ek twn 2!
                                    //-------------------------------------------------------------------------------
                
                width: ((Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/6))/2) - ((Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/5)/2), 
                height: ((Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4))/2) - ((Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/5)/2),
                //kai prepei na to anevasw (to top einai meion)
                //-(container_height/2 + target_image_height/2)
                top: -(((Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4))/2) + ((Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/5)/2))
            },
            default: {
                width: ((screenDimensions.width-(screenDimensions.width/6))/2) - ((screenDimensions.width/5)/2), 
                height: ((screenDimensions.height-(screenDimensions.height/4))/2) - ((screenDimensions.width/5)/2),
                top: -(((screenDimensions.height-(screenDimensions.height/4))/2) + ((screenDimensions.width/5)/2))          
            },
        }) 
    },
    markerContainerChildOwnerPinOnMap: {
        zIndex: 1, // works on ios
        elevation: 1, // works on android
        position: 'absolute', 
        //top: -((screenDimensions.height-screenDimensions.height/3.8)/1.6),
        ...Platform.select({
            web: {
                top: -(((Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-250)/2) + ((Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/5)*(640/630))/2 ) //half height of map + half height of marker
            },
            default: {
                top: -((screenDimensions.height/2) + ((screenDimensions.width/5)*(640/630))/2 ) //half height of map + half height of marker
            },
        })
    },
    markerTitle: {
        fontSize: 15, 
        fontWeight: 'bold',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    menu: { 
        position: 'absolute',
        top: 120,
        overflow: 'visible',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    modalContainer: {
        flex: 0.8,
        margin: 0,
        backgroundColor: Colors.blueGrey100,
        borderWidth: 4,
        borderColor: 'white',
        borderRadius: 5,
        alignSelf: "center",
        ...Platform.select({
            web: {
                width: screenDimensions.width<=Generic_ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.8 : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*0.8,
                height: screenDimensions.height<=Generic_ConstManager.getInstance().WEB_MAX_HEIGHT ? screenDimensions.height*0.8 :  Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4)
            },
            default: {
                height: screenDimensions.height-(screenDimensions.height/4)
            },
        })
    },
    modalContainerOwnerProfile: {
        alignSelf: 'center',
        backgroundColor: Colors.blueGrey100,
        borderWidth: 4,
        borderColor: 'white',
        borderRadius: 5,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH-(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/6),
                height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT-(Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/4)
            },
            default: {
                width: screenDimensions.width-(screenDimensions.width/6),
                height: screenDimensions.height-(screenDimensions.height/4)
            },
        })
      },
    modalPhotoDesc: {
        flex: 1,
        margin: 20,
        borderRadius: 20,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    name: {
        textAlign: "center",
        fontSize: 24,
        fontWeight: "200",
        color: "#FFFFFFEE",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    notificationBadge: {
        position: 'absolute',
        top: -8,
        right: -8,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    notificationIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    notificationSettingsSwitch: {
        alignItems:'flex-end',
        ...Platform.select({
            web: {
                flex: 1,
                marginRight: 70,
                marginBottom: 20
            },
            default: {
                flex:1,
            },
        })
    },
    notificationSettingsText: {
        fontSize: 16,  
        alignItems:'flex-start',
        ...Platform.select({
            web: {
                marginLeft: 70,
                flex:10
            },
            default: {
                padding: 8,
                flex:5
            },
        })
    },
    pagerView: {
        height: 1500,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    picDesc: {
        ...Platform.select({
            web: {
                alignSelf: "center", 
                textAlign: "center", 
                fontStyle: "italic", 
                backgroundColor: "black",
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH, 
                color: "white",
                //backgroundColor: "rgb(207, 216, 220)",
                //borderStyle: "dashed", 
                //borderColor: "black", 
                //borderWidth: 1, 
                //paddingLeft: 30, 
                //paddingRight: 30, 
                marginBottom: 15, 
                //paddingTop: 15, 
                paddingBottom: 15
            },
            default: {
                
            },
        })
    },
    roomTable: {
        borderWidth: 2, 
        borderColor: "#6200EE",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableMainHeader: {
        backgroundColor: "#6200EE",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableSecondaryHeader: {
        backgroundColor: "gainsboro",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableDayName: {
        color: "white", 
        fontWeight: "bold", 
        alignSelf:"center", 
        textAlign: "center",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableWorkingHours: {
        color: "black", 
        fontWeight: "bold", 
        alignSelf:"center", 
        textAlign: "center",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableCost: {
        color: "black", 
        fontWeight: "bold", 
        alignSelf:"center", 
        textAlign: "center",
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableFrom: {
        flex: 1, 
        marginRight: 2,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableTo: {
        flex: 1, 
        marginLeft: 2,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableRehearsalCost: {
        flex: 1, 
        marginRight: 2,
        borderWidth: 1,
        borderColor: "grey",
        borderRadius: 5,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    roomTableRecordingCost: {
        flex: 1, 
        marginLeft: 2,
        borderWidth: 1,
        borderColor: "grey",
        borderRadius: 5,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    courtTableCost: {
        flex: 1, 
        marginRight: 2,
        borderWidth: 1,
        borderColor: "grey",
        borderRadius: 5,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    root: {
        flex: 1,
        backgroundColor: "white",
        //...Platform.select({
        //  android: { paddingTop: StatusBar.currentHeight },
        //  default: null,
        //}),
        //paddingTop: 10,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    rootPhotos: {
        flex: 1,
        backgroundColor: "#000",
        //...Platform.select({
        //  android: { paddingTop: StatusBar.currentHeight },
        //  default: null,
        //}),
        paddingTop: 30,
        paddingBottom: 30,
        alignItems: 'flex-end',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    safeContainerStyle: {
        flex: 1,
        margin: 20,
        justifyContent: 'center',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    screen: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    screenTitle: {
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    scrollView: {
        backgroundColor: 'whitesmoke',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    searchBar: {
        borderBottomColor: 'black',
        borderTopColor: 'black',
        ...Platform.select({
            web: {
                width: screenDimensions.width - (Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH + Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH),
                maxWidth: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },
    searchResults: {
        position: 'absolute',
        top: 100,
        zIndex: 2,
        elevation: 2,
        backgroundColor: 'whitesmoke',
        opacity: 0.7,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    snackbar: {
        borderColor: 'white', 
        borderWidth: 3, 
        backgroundColor: '#6200EE',
        marginBottom: 10,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    snackbarUpper: {
        borderColor: 'white', 
        borderWidth: 3, 
        backgroundColor: '#6200EE',
        marginBottom: 100,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    spinnerTextStyle: {
        color: '#FFF',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    statusLabel: {
        paddingTop: 15, 
        textAlign: 'center', 
        color: 'white',
        ...Platform.select({
            web: {
                fontSize: RFPercentage(1.4),
                width:(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*0.8)/2
            },
            default: {
                fontSize: RFPercentage(1.7),
                width:screenDimensions.width/2
            },
        })
    },
    subheading: {
        backgroundColor: Colors.blueGrey100,
        color: 'black',
        textAlign: 'center',
        fontWeight: 'bold',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    tableRow: {
        paddingLeft: 0, 
        paddingTop: 0, 
        paddingRight: 0,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    tableTitle: {
        flex: 3.5, 
        backgroundColor: Colors.blueGrey100, 
        justifyContent: 'space-evenly',
        paddingTop: 20,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        }) 
    },
    tableValueColumn: {
        flex: 5, 
        justifyContent: 'space-evenly',
        paddingLeft: 5,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    tableIcon: {
        flex: 3, 
        justifyContent:'space-evenly',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    text: { 
        margin: 6,
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        }) 
    },
    tipContainer: {
        position: 'absolute',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.8,
        top: -250,
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT
            },
            default: {
                width: screenDimensions.width,
                height: screenDimensions.height
            },
        })
    },
    tipFab: {
        position: 'absolute',
        width: 56,
        height: 56,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 28,
        backgroundColor: '#6200ee',
        zIndex: 1,
        elevation: 1,
        ...Platform.select({
            web: {
                right: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/2 - 28,
                bottom: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT/2 - 80
            },
            default: {
                right: screenDimensions.width/2 - 28,
                bottom: screenDimensions.height/2 - 80
            },
        })
    },
    tipText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'red',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },
    calendarSelectedDayTitle: {
        margin: 50, 
        textAlign: 'center', 
        //color: Colors.blueA700,
        fontWeight: 'bold',
        ...Platform.select({
            web: {
                
            },
            default: {
                
            },
        })
    },



    modalMainContainer: {
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width,
            },
        })
    },
    modalSubContainer: {
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width,
            },
        })
      },
    flatlistItemContainer : {
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },

    roomEquipmentTableRow: {
        flexDirection:"row",
        ...Platform.select({
            web: {
                width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH
            },
            default: {
                width: screenDimensions.width
            },
        })
    },

    webCalendar: {
        ...Platform.select({
            web: {
                alignSelf: "center",
                width: (screenDimensions.width<Generic_ConstManager.getInstance().WEB_MAX_WIDTH) ? screenDimensions.width-Generic_ConstManager.getInstance().WEB_LEFT_COLUMN_WIDTH-Generic_ConstManager.getInstance().WEB_RIGHT_COLUMN_WIDTH : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                maxwidth: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH,
                height: screenDimensions.height<=Generic_ConstManager.getInstance().WEB_MAX_HEIGHT ? screenDimensions.height-191 : Generic_ConstManager.getInstance().WEB_MAX_HEIGHT
            },
            default: {
                
            },
        })
    },
    webCalendarItemContainer: {
        ...Platform.select({
            web: {
                borderRadius: 5,
                borderColor: 'black',
                borderWidth: 0.5,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
            default: {
                
            },
        })
    },
    webCalendarItemText: {
        ...Platform.select({
            web: {
                color: 'black',
                paddingLeft: 5,
                paddingRight: 5,
            },
            default: {
                
            },
        })
    },
  });

  export default styles; 