import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextInput, Button, Divider, IconButton, Colors, DataTable, Text, Checkbox } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import Studio_RestManager from '../../managers/Studio_RestManager';
import { useNavigation } from '@react-navigation/native';
import styles from '../../css/myCss';
import GUIManager from '../../managers/GUIManager';
import LoginManager from '../../managers/LoginManager';
import OwnerRoomsDropdown from './dropdowns/OwnerRoomsDropdown';
import OwnerDropdownMondayFrom from './dropdowns/OwnerDropdownMondayFrom';
import OwnerDropdownMondayTo from './dropdowns/OwnerDropdownMondayTo';
import OwnerDropdownTuesdayFrom from './dropdowns/OwnerDropdownTuesdayFrom';
import OwnerDropdownTuesdayTo from './dropdowns/OwnerDropdownTuesdayTo';
import OwnerDropdownWednesdayFrom from './dropdowns/OwnerDropdownWednesdayFrom';
import OwnerDropdownWednesdayTo from './dropdowns/OwnerDropdownWednesdayTo';
import OwnerDropdownThursdayFrom from './dropdowns/OwnerDropdownThursdayFrom';
import OwnerDropdownThursdayTo from './dropdowns/OwnerDropdownThursdayTo';
import OwnerDropdownFridayFrom from './dropdowns/OwnerDropdownFridayFrom';
import OwnerDropdownFridayTo from './dropdowns/OwnerDropdownFridayTo';
import OwnerDropdownSaturdayFrom from './dropdowns/OwnerDropdownSaturdayFrom';
import OwnerDropdownSaturdayTo from './dropdowns/OwnerDropdownSaturdayTo';
import OwnerDropdownSundayFrom from './dropdowns/OwnerDropdownSundayFrom';
import OwnerDropdownSundayTo from './dropdowns/OwnerDropdownSundayTo';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import UtilsManager from '../../managers/UtilsManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';

//Alert
  //react native
    //already imported above
  //web
    import AwesomeAlert from 'react-native-awesome-alerts';
import LeftColumnWeb from '../generic/LeftColumnWeb';
import RightColumnWeb from '../generic/RightColumnWeb';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const OwnerManageRoomsView = ({route}) => {
  const [ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile, setOwnerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile] = useState(route?.params?.params?.ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile ?? false);
  /*useEffect(() => {
    console.log("useEffect - ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile: " + ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile);
  }, [ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile]);*/

  const navigation = useNavigation();
  //const aref = useAnimatedRef();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebTitle, setAlertForWebTitle] = useState('');
  const [alertForWebText, setAlertForWebText] = useState('');

///////////////////////////
// Studio domain - START //
///////////////////////////
  const [myRoomsArray_Studio, setMyRoomsArray_Studio] = useState<Array<Studio_Room>>(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio);
  useEffect(() => {
    console.log("useEffect - myRoomsArray_Studio - START");
    console.log("myRoomsArray_Studio: " + JSON.stringify(myRoomsArray_Studio,null, 2));
    if(addNewRoomMode_Studio == true) {
      console.log("useEffect - myRoomsArray_Studio (addNewRoomMode_Studio=", addNewRoomMode_Studio, ") kalei -> setSelectedRoomDropdownIndex_Studio(myRoomsArray_Studio.length-1) -> setSelectedRoomDropdownIndex_Studio(", myRoomsArray_Studio.length-1, ")");
      setSelectedRoomDropdownIndex_Studio(myRoomsArray_Studio.length-1);
    }
    else {
      console.log("useEffect - myRoomsArray (addNewRoomMode_Studio=", addNewRoomMode_Studio, ") kalei -> setSelectedRoomDropdownIndex_Studio(0);");
      setSelectedRoomDropdownIndex_Studio(0);
    }
    console.log("useEffect - myRoomsArray - STOP");
  }, [myRoomsArray_Studio.length]);

  const [myRoomsDropdownList_Studio, setMyRoomsDropdownList_Studio] = useState<Array<DropdownItem>>(
    myRoomsArray_Studio.map((tmpRoom, tmpIndex) => ({
        label: tmpRoom.name,
        value: tmpIndex.toString()
    }))
  );
  useEffect(() => {
    console.log("useEffect - myRoomsDropdownList_Studio - START -> myRoomsDropdownList_Studio: ", JSON.stringify(myRoomsDropdownList_Studio, null, 2));
    console.log("useEffect - myRoomsDropdownList_Studio - STOP");
  }, [JSON.stringify(myRoomsDropdownList_Studio)]);

  const [showRoomsDropdownList_Studio, setShowRoomsDropdownList_Studio] = useState(false);
  const [selectedRoomDropdownIndex_Studio, setSelectedRoomDropdownIndex_Studio] = useState(0);
  useEffect(() => {
    console.log("useEffect - selectedRoomDropdownIndex_Studio - START -> selectedRoomDropdownIndex_Studio: ", selectedRoomDropdownIndex_Studio);
    if(addNewRoomMode_Studio == false)
      setSelectedRoom_Studio(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[selectedRoomDropdownIndex_Studio]);
    console.log("useEffect - selectedRoomDropdownIndex_Studio - STOP");
  }, [selectedRoomDropdownIndex_Studio]);

  const [cancelNewRoomMode_Studio, setCancelNewRoomMode_Studio] = useState(false);
  useEffect(() => {
    console.log("useEffect - cancelNewRoomMode_Studio - START -> cancelNewRoomMode_Studio: ", cancelNewRoomMode_Studio);
    if(cancelNewRoomMode_Studio == true) {
      setIsLoading(true);
      setTimeout(() => { //just to ensure that loading will show
        setAddNewRoomMode_Studio(false);
      }, 100);
    }
    console.log("useEffect - cancelNewRoomMode_Studio - STOP");
  }, [cancelNewRoomMode_Studio]);

  const [addNewRoomMode_Studio, setAddNewRoomMode_Studio] = useState(false);
  useEffect(() => {
    console.log("useEffect - addNewRoomMode_Studio - START -> addNewRoomMode_Studio: ", addNewRoomMode_Studio);
    if(addNewRoomMode_Studio == true) {
      setIsLoading(true);
      setTimeout(() => { //just to ensure that loading will show
        setCancelNewRoomMode_Studio(false);
        setSelectedRoom_Studio({...selectedRoom_Studio, 
          id: -1,
          owned_by: LoginManager.getInstance().ID,
          name: 'Room ' + (GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.length + 1).toString(),
          currency: GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0].currency,
          monday_cost_per_hour_reh: -1, monday_cost_per_hour_rec: -1,
          tuesday_cost_per_hour_reh: -1, tuesday_cost_per_hour_rec: -1,
          wednesday_cost_per_hour_reh: -1, wednesday_cost_per_hour_rec: -1,
          thursday_cost_per_hour_reh: -1, thursday_cost_per_hour_rec: -1,
          friday_cost_per_hour_reh: -1, friday_cost_per_hour_rec: -1,
          saturday_cost_per_hour_reh: -1, saturday_cost_per_hour_rec: -1,
          sunday_cost_per_hour_reh: -1, sunday_cost_per_hour_rec: -1,
          monday_working_hours_start: 'closed', tuesday_working_hours_start: 'closed', wednesday_working_hours_start: 'closed',
          thursday_working_hours_start: 'closed', friday_working_hours_start: 'closed', saturday_working_hours_start: 'closed', sunday_working_hours_start: 'closed',
          monday_working_hours_stop: 'closed', tuesday_working_hours_stop: 'closed', wednesday_working_hours_stop: 'closed',
          thursday_working_hours_stop: 'closed', friday_working_hours_stop: 'closed', saturday_working_hours_stop: 'closed', sunday_working_hours_stop: 'closed',
          space: '', speakers: '', console: '', microphones: '', drums: '', guitars: '', basses: '', pianos_keyboards: '', guitar_amplifiers: '', bass_amplifiers: '', other_equipment: ''
        });
      }, 100);
    }
    else if(addNewRoomMode_Studio == false) {
      if(cancelNewRoomMode_Studio == true) {
        console.log("  eimai sto useEffect - addNewRoomMode_Studio kai svhnw last item myRoomsArray kai myRoomsDropdownList_Studio epeidh addNewRoomMode_Studio==false && cancelNewRoomMode_Studio==true");
        setMyRoomsArray_Studio([...myRoomsArray_Studio].slice(0, myRoomsArray_Studio.length-1));  //delete last item
        setMyRoomsDropdownList_Studio([...myRoomsDropdownList_Studio].slice(0, myRoomsDropdownList_Studio.length-1));  //delete last item
      }
    }
    console.log("useEffect - addNewRoomMode_Studio - STOP");
  }, [addNewRoomMode_Studio]);

  const [selectedRoom_Studio, setSelectedRoom_Studio] = useState<Studio_Room>(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0]);
  useEffect(() => {
    console.log("useEffect - selectedRoom_Studio.id - START");
    console.log("selectedRoom: " + JSON.stringify(selectedRoom_Studio,null, 2));
    if(addNewRoomMode_Studio==true && selectedRoom_Studio.id==-1) {
      console.log("paw na prosthesw sto myRoomsArray to selectedRoom: " + JSON.stringify(selectedRoom_Studio, null, 2));
      setMyRoomsArray_Studio(myRoomsArray_Studio => [...myRoomsArray_Studio, selectedRoom_Studio]);
      setMyRoomsDropdownList_Studio([...myRoomsDropdownList_Studio, { label: selectedRoom_Studio.name, value: myRoomsDropdownList_Studio.length.toString() }]);
      setMyData_Studio({...myData_Studio, 
        mondayOpen: 'false', tuesdayOpen: 'false', wednesdayOpen: 'false', 
        thursdayOpen: 'false', fridayOpen: 'false', saturdayOpen: 'false', sundayOpen: 'false',
        showMondayStartHour: false, showMondayStopHour: false,
        showTuesdayStartHour: false, showTuesdayStopHour: false,
        showWednesdayStartHour: false, showWednesdayStopHour: false,
        showThursdayStartHour: false, showThursdayStopHour: false,
        showFridayStartHour: false, showFridayStopHour: false,
        showSaturdayStartHour: false, showSaturdayStopHour: false,
        showSundayStartHour: false, showSundayStopHour: false,
        mondayFreeRehIsConfirmed: false, mondayFreeRecIsConfirmed: false,
        tuesdayFreeRehIsConfirmed: false, tuesdayFreeRecIsConfirmed: false,
        wednesdayFreeRehIsConfirmed: false, wednesdayFreeRecIsConfirmed: false,
        thursdayFreeRehIsConfirmed: false, thursdayFreeRecIsConfirmed: false,
        fridayFreeRehIsConfirmed: false, fridayFreeRecIsConfirmed: false,
        saturdayFreeRehIsConfirmed: false, saturdayFreeRecIsConfirmed: false,
        sundayFreeRehIsConfirmed: false, sundayFreeRecIsConfirmed: false,
        confirmationDialogVisible: false, confirmationMessage: '',
        mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
        tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
        wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
        thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
        fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
        saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
        sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false
      });
    }
    else {
      console.log("   eimai sto useEffect - selectedRoom_Studio.id kai kalw setSelectedRoomChangedAndTriggersMyDataUpdate_Studio(true);");
      setSelectedRoomChangedAndTriggersMyDataUpdate_Studio(true);
    }
    console.log("useEffect - selectedRoom_Studio.id - STOP");
  }, [selectedRoom_Studio?.id]);

  useEffect(() => {
    console.log("useEffect - selectedRoom_Studio - START");
    console.log("selectedRoom: " + JSON.stringify(selectedRoom_Studio,null, 2));
    if(myData_Studio.mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    else if(myData_Studio.tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    else if(myData_Studio.wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    else if(myData_Studio.thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    else if(myData_Studio.fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    else if(myData_Studio.saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    else if(myData_Studio.sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)
      setMyData_Studio({...myData_Studio, sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false});
    console.log("useEffect - selectedRoom_Studio - STOP");
  }, [JSON.stringify(selectedRoom_Studio)]);

  const [selectedRoomChangedAndTriggersMyDataUpdate_Studio, setSelectedRoomChangedAndTriggersMyDataUpdate_Studio] = useState(false);
  useEffect(() => {
    console.log("useEffect - selectedRoomChangedAndTriggersMyDataUpdate_Studio - START -> selectedRoomChangedAndTriggersMyDataUpdate_Studio: ", selectedRoomChangedAndTriggersMyDataUpdate_Studio);
    if(selectedRoomChangedAndTriggersMyDataUpdate_Studio == true) {
      var tmpMondayOpen = 'false';
      var tmpTuesdayOpen = 'false';
      var tmpWednesdayOpen = 'false';
      var tmpThursdayOpen = 'false';
      var tmpFridayOpen = 'false';
      var tmpSaturdayOpen = 'false';
      var tmpSundayOpen = 'false';

      if(selectedRoom_Studio.monday_working_hours_start=='closed' && selectedRoom_Studio.monday_working_hours_stop=='closed') tmpMondayOpen = 'false'; else tmpMondayOpen = 'true';
      if(selectedRoom_Studio.tuesday_working_hours_start=='closed' && selectedRoom_Studio.tuesday_working_hours_stop=='closed') tmpTuesdayOpen = 'false'; else tmpTuesdayOpen = 'true';
      if(selectedRoom_Studio.wednesday_working_hours_start=='closed' && selectedRoom_Studio.wednesday_working_hours_stop=='closed') tmpWednesdayOpen = 'false'; else tmpWednesdayOpen = 'true';
      if(selectedRoom_Studio.thursday_working_hours_start=='closed' && selectedRoom_Studio.thursday_working_hours_stop=='closed') tmpThursdayOpen = 'false'; else tmpThursdayOpen = 'true';
      if(selectedRoom_Studio.friday_working_hours_start=='closed' && selectedRoom_Studio.friday_working_hours_stop=='closed') tmpFridayOpen = 'false'; else tmpFridayOpen = 'true';
      if(selectedRoom_Studio.saturday_working_hours_start=='closed' && selectedRoom_Studio.saturday_working_hours_stop=='closed') tmpSaturdayOpen = 'false'; else tmpSaturdayOpen = 'true';
      if(selectedRoom_Studio.sunday_working_hours_start=='closed' && selectedRoom_Studio.sunday_working_hours_stop=='closed') tmpSundayOpen = 'false'; else tmpSundayOpen = 'true';

      if(myData_Studio.mondayOpen!=tmpMondayOpen || myData_Studio.tuesdayOpen!=tmpTuesdayOpen || myData_Studio.wednesdayOpen!=tmpWednesdayOpen 
          || myData_Studio.thursdayOpen!=tmpThursdayOpen || myData_Studio.fridayOpen!=tmpFridayOpen || myData_Studio.saturdayOpen!=tmpSaturdayOpen || myData_Studio.sundayOpen!=tmpSundayOpen) 
      {
        setMyData_Studio({...myData_Studio, 
          mondayOpen: tmpMondayOpen,
          tuesdayOpen: tmpTuesdayOpen,
          wednesdayOpen: tmpWednesdayOpen,
          thursdayOpen: tmpThursdayOpen,
          fridayOpen: tmpFridayOpen,
          saturdayOpen: tmpSaturdayOpen,
          sundayOpen: tmpSundayOpen
        });
      }
      setSelectedRoomChangedAndTriggersMyDataUpdate_Studio(false);
    }
    console.log("useEffect - selectedRoomChangedAndTriggersMyDataUpdate_Studio - STOP");
  }, [selectedRoomChangedAndTriggersMyDataUpdate_Studio]);

  const [myData_Studio, setMyData_Studio] = useState({
    mondayOpen: 'false', tuesdayOpen: 'false', wednesdayOpen: 'false', thursdayOpen: 'false', fridayOpen: 'false', saturdayOpen: 'false', sundayOpen: 'false',
    showMondayStartHour: false, showMondayStopHour: false,
    showTuesdayStartHour: false, showTuesdayStopHour: false,
    showWednesdayStartHour: false, showWednesdayStopHour: false,
    showThursdayStartHour: false, showThursdayStopHour: false,
    showFridayStartHour: false, showFridayStopHour: false,
    showSaturdayStartHour: false, showSaturdayStopHour: false,
    showSundayStartHour: false, showSundayStopHour: false,
    mondayFreeRehIsConfirmed: false, mondayFreeRecIsConfirmed: false,
    tuesdayFreeRehIsConfirmed: false, tuesdayFreeRecIsConfirmed: false,
    wednesdayFreeRehIsConfirmed: false, wednesdayFreeRecIsConfirmed: false,
    thursdayFreeRehIsConfirmed: false, thursdayFreeRecIsConfirmed: false,
    fridayFreeRehIsConfirmed: false, fridayFreeRecIsConfirmed: false,
    saturdayFreeRehIsConfirmed: false, saturdayFreeRecIsConfirmed: false,
    sundayFreeRehIsConfirmed: false, sundayFreeRecIsConfirmed: false,
    confirmationDialogVisible: false, confirmationMessage: '',    
    mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
    tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
    wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
    thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
    fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
    saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false,
    sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate: false
  });
  useEffect(() => {
    console.log("useEffect - myData_Studio - START");
    console.log("myData_Studio: " + JSON.stringify(myData_Studio,null, 2));    
    
    if(myData_Studio.mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpMondayWorkingHoursStartUpdated : string = selectedRoom_Studio.monday_working_hours_start;
      var tmpMondayWorkingHoursStopUpdated : string = selectedRoom_Studio.monday_working_hours_stop;
      var tmpMondayRehearsalCost : number = selectedRoom_Studio.monday_cost_per_hour_reh;
      var tmpMondayRecordingCost : number = selectedRoom_Studio.monday_cost_per_hour_rec;
      if(myData_Studio.mondayOpen == 'true') {
        if(tmpMondayWorkingHoursStartUpdated == "closed") tmpMondayWorkingHoursStartUpdated = proposeMondayStartHour_Studio();
        if(tmpMondayWorkingHoursStopUpdated == "closed") tmpMondayWorkingHoursStopUpdated = proposeMondayStopHour_Studio();
        if(tmpMondayRehearsalCost == -1) tmpMondayRehearsalCost = proposeMondayRehearsalCost_Studio();
        if(tmpMondayRecordingCost == -1) tmpMondayRecordingCost = proposeMondayRecordingCost_Studio();
      }
      else if(myData_Studio.mondayOpen == 'false') {
        if(tmpMondayWorkingHoursStartUpdated != "closed") tmpMondayWorkingHoursStartUpdated = "closed";
        if(tmpMondayWorkingHoursStopUpdated != "closed") tmpMondayWorkingHoursStopUpdated = "closed";
        if(tmpMondayRehearsalCost != -1) tmpMondayRehearsalCost = -1;
        if(tmpMondayRecordingCost != -1) tmpMondayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        monday_working_hours_start: tmpMondayWorkingHoursStartUpdated, 
        monday_working_hours_stop: tmpMondayWorkingHoursStopUpdated, 
        monday_cost_per_hour_reh: tmpMondayRehearsalCost, 
        monday_cost_per_hour_rec: tmpMondayRecordingCost
      });
    }
    else if(myData_Studio.tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpTuesdayWorkingHoursStartUpdated : string = selectedRoom_Studio.tuesday_working_hours_start;
      var tmpTuesdayWorkingHoursStopUpdated : string = selectedRoom_Studio.tuesday_working_hours_stop;
      var tmpTuesdayRehearsalCost : number = selectedRoom_Studio.tuesday_cost_per_hour_reh;
      var tmpTuesdayRecordingCost : number = selectedRoom_Studio.tuesday_cost_per_hour_rec;
      if(myData_Studio.tuesdayOpen == 'true') {
        if(tmpTuesdayWorkingHoursStartUpdated == "closed") tmpTuesdayWorkingHoursStartUpdated = proposeTuesdayStartHour_Studio();
        if(tmpTuesdayWorkingHoursStopUpdated == "closed") tmpTuesdayWorkingHoursStopUpdated = proposeTuesdayStopHour_Studio();
        if(tmpTuesdayRehearsalCost == -1) tmpTuesdayRehearsalCost = proposeTuesdayRehearsalCost_Studio();
        if(tmpTuesdayRecordingCost == -1) tmpTuesdayRecordingCost = proposeTuesdayRecordingCost_Studio();
      }
      else if(myData_Studio.tuesdayOpen == 'false') {
        if(tmpTuesdayWorkingHoursStartUpdated != "closed") tmpTuesdayWorkingHoursStartUpdated = "closed";
        if(tmpTuesdayWorkingHoursStopUpdated != "closed") tmpTuesdayWorkingHoursStopUpdated = "closed";
        if(tmpTuesdayRehearsalCost != -1) tmpTuesdayRehearsalCost = -1;
        if(tmpTuesdayRecordingCost != -1) tmpTuesdayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        tuesday_working_hours_start: tmpTuesdayWorkingHoursStartUpdated, 
        tuesday_working_hours_stop: tmpTuesdayWorkingHoursStopUpdated, 
        tuesday_cost_per_hour_reh: tmpTuesdayRehearsalCost, 
        tuesday_cost_per_hour_rec: tmpTuesdayRecordingCost
      });
    }
    else if(myData_Studio.wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpWednesdayWorkingHoursStartUpdated : string = selectedRoom_Studio.wednesday_working_hours_start;
      var tmpWednesdayWorkingHoursStopUpdated : string = selectedRoom_Studio.wednesday_working_hours_stop;
      var tmpWednesdayRehearsalCost : number = selectedRoom_Studio.wednesday_cost_per_hour_reh;
      var tmpWednesdayRecordingCost : number = selectedRoom_Studio.wednesday_cost_per_hour_rec;
      if(myData_Studio.wednesdayOpen == 'true') {
        if(tmpWednesdayWorkingHoursStartUpdated == "closed") tmpWednesdayWorkingHoursStartUpdated = proposeWednesdayStartHour_Studio();
        if(tmpWednesdayWorkingHoursStopUpdated == "closed") tmpWednesdayWorkingHoursStopUpdated = proposeWednesdayStopHour_Studio();
        if(tmpWednesdayRehearsalCost == -1) tmpWednesdayRehearsalCost = proposeWednesdayRehearsalCost_Studio();
        if(tmpWednesdayRecordingCost == -1) tmpWednesdayRecordingCost = proposeWednesdayRecordingCost_Studio();
      }
      else if(myData_Studio.wednesdayOpen == 'false') {
        if(tmpWednesdayWorkingHoursStartUpdated != "closed") tmpWednesdayWorkingHoursStartUpdated = "closed";
        if(tmpWednesdayWorkingHoursStopUpdated != "closed") tmpWednesdayWorkingHoursStopUpdated = "closed";
        if(tmpWednesdayRehearsalCost != -1) tmpWednesdayRehearsalCost = -1;
        if(tmpWednesdayRecordingCost != -1) tmpWednesdayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        wednesday_working_hours_start: tmpWednesdayWorkingHoursStartUpdated,
        wednesday_working_hours_stop: tmpWednesdayWorkingHoursStopUpdated,
        wednesday_cost_per_hour_reh: tmpWednesdayRehearsalCost, 
        wednesday_cost_per_hour_rec: tmpWednesdayRecordingCost
      });
    }
    else if(myData_Studio.thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpThursdayWorkingHoursStartUpdated : string = selectedRoom_Studio.thursday_working_hours_start;
      var tmpThursdayWorkingHoursStopUpdated : string = selectedRoom_Studio.thursday_working_hours_stop;
      var tmpThursdayRehearsalCost : number = selectedRoom_Studio.thursday_cost_per_hour_reh;
      var tmpThursdayRecordingCost : number = selectedRoom_Studio.thursday_cost_per_hour_rec;
      if(myData_Studio.thursdayOpen == 'true') {
        if(tmpThursdayWorkingHoursStartUpdated == "closed") tmpThursdayWorkingHoursStartUpdated = proposeThursdayStartHour_Studio();
        if(tmpThursdayWorkingHoursStopUpdated == "closed") tmpThursdayWorkingHoursStopUpdated = proposeThursdayStopHour_Studio();
        if(tmpThursdayRehearsalCost == -1) tmpThursdayRehearsalCost = proposeThursdayRehearsalCost_Studio();
        if(tmpThursdayRecordingCost == -1) tmpThursdayRecordingCost = proposeThursdayRecordingCost_Studio();
      }
      else if(myData_Studio.thursdayOpen == 'false') {
        if(tmpThursdayWorkingHoursStartUpdated != "closed") tmpThursdayWorkingHoursStartUpdated = "closed";
        if(tmpThursdayWorkingHoursStopUpdated != "closed") tmpThursdayWorkingHoursStopUpdated = "closed";
        if(tmpThursdayRehearsalCost != -1) tmpThursdayRehearsalCost = -1;
        if(tmpThursdayRecordingCost != -1) tmpThursdayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        thursday_working_hours_start: tmpThursdayWorkingHoursStartUpdated, 
        thursday_working_hours_stop: tmpThursdayWorkingHoursStopUpdated, 
        thursday_cost_per_hour_reh: tmpThursdayRehearsalCost, 
        thursday_cost_per_hour_rec: tmpThursdayRecordingCost
      });
    }
    else if(myData_Studio.fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpFridayWorkingHoursStartUpdated : string = selectedRoom_Studio.friday_working_hours_start;
      var tmpFridayWorkingHoursStopUpdated : string = selectedRoom_Studio.friday_working_hours_stop;
      var tmpFridayRehearsalCost : number = selectedRoom_Studio.friday_cost_per_hour_reh;
      var tmpFridayRecordingCost : number = selectedRoom_Studio.friday_cost_per_hour_rec;
      if(myData_Studio.fridayOpen == 'true') {
        if(tmpFridayWorkingHoursStartUpdated == "closed") tmpFridayWorkingHoursStartUpdated = proposeFridayStartHour_Studio();
        if(tmpFridayWorkingHoursStopUpdated == "closed") tmpFridayWorkingHoursStopUpdated = proposeFridayStopHour_Studio();
        if(tmpFridayRehearsalCost == -1) tmpFridayRehearsalCost = proposeFridayRehearsalCost_Studio();
        if(tmpFridayRecordingCost == -1) tmpFridayRecordingCost = proposeFridayRecordingCost_Studio();
      }
      else if(myData_Studio.fridayOpen == 'false') {
        if(tmpFridayWorkingHoursStartUpdated != "closed") tmpFridayWorkingHoursStartUpdated = "closed";
        if(tmpFridayWorkingHoursStopUpdated != "closed") tmpFridayWorkingHoursStopUpdated = "closed";
        if(tmpFridayRehearsalCost != -1) tmpFridayRehearsalCost = -1;
        if(tmpFridayRecordingCost != -1) tmpFridayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        friday_working_hours_start: tmpFridayWorkingHoursStartUpdated,
        friday_working_hours_stop: tmpFridayWorkingHoursStopUpdated,  
        friday_cost_per_hour_reh: tmpFridayRehearsalCost, 
        friday_cost_per_hour_rec: tmpFridayRecordingCost
      });
    }
    else if(myData_Studio.saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpSaturdayWorkingHoursStartUpdated : string = selectedRoom_Studio.friday_working_hours_start;
      var tmpSaturdayWorkingHoursStopUpdated : string = selectedRoom_Studio.friday_working_hours_stop;
      var tmpSaturdayRehearsalCost : number = selectedRoom_Studio.friday_cost_per_hour_reh;
      var tmpSaturdayRecordingCost : number = selectedRoom_Studio.friday_cost_per_hour_rec;
      if(myData_Studio.saturdayOpen == 'true') {
        if(tmpSaturdayWorkingHoursStartUpdated == "closed") tmpSaturdayWorkingHoursStartUpdated = proposeSaturdayStartHour_Studio();
        if(tmpSaturdayWorkingHoursStopUpdated == "closed") tmpSaturdayWorkingHoursStopUpdated = proposeSaturdayStopHour_Studio();
        if(tmpSaturdayRehearsalCost == -1) tmpSaturdayRehearsalCost = proposeSaturdayRehearsalCost_Studio();
        if(tmpSaturdayRecordingCost == -1) tmpSaturdayRecordingCost = proposeSaturdayRecordingCost_Studio();
      }
      else if(myData_Studio.saturdayOpen == 'false') {
        if(tmpSaturdayWorkingHoursStartUpdated != "closed") tmpSaturdayWorkingHoursStartUpdated = "closed";
        if(tmpSaturdayWorkingHoursStopUpdated != "closed") tmpSaturdayWorkingHoursStopUpdated = "closed";
        if(tmpSaturdayRehearsalCost != -1) tmpSaturdayRehearsalCost = -1;
        if(tmpSaturdayRecordingCost != -1) tmpSaturdayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        saturday_working_hours_start: tmpSaturdayWorkingHoursStartUpdated, 
        saturday_working_hours_stop: tmpSaturdayWorkingHoursStopUpdated, 
        saturday_cost_per_hour_reh: tmpSaturdayRehearsalCost, 
        saturday_cost_per_hour_rec: tmpSaturdayRecordingCost
      });
    }
    else if(myData_Studio.sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true) {
      console.log("   mphke sto if(myData_Studio.sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate == true)");
      var tmpSundayWorkingHoursStartUpdated : string = selectedRoom_Studio.sunday_working_hours_start;
      var tmpSundayWorkingHoursStopUpdated : string = selectedRoom_Studio.sunday_working_hours_stop;
      var tmpSundayRehearsalCost : number = selectedRoom_Studio.sunday_cost_per_hour_reh;
      var tmpSundayRecordingCost : number = selectedRoom_Studio.sunday_cost_per_hour_rec;
      if(myData_Studio.sundayOpen == 'true') {
        if(tmpSundayWorkingHoursStartUpdated == "closed") tmpSundayWorkingHoursStartUpdated = proposeSundayStartHour_Studio();
        if(tmpSundayWorkingHoursStopUpdated == "closed") tmpSundayWorkingHoursStopUpdated = proposeSundayStopHour_Studio();
        if(tmpSundayRehearsalCost == -1) tmpSundayRehearsalCost = proposeSundayRehearsalCost_Studio();
        if(tmpSundayRecordingCost == -1) tmpSundayRecordingCost = proposeSundayRecordingCost_Studio();
      }
      else if(myData_Studio.sundayOpen == 'false') {
        if(tmpSundayWorkingHoursStartUpdated != "closed") tmpSundayWorkingHoursStartUpdated = "closed";
        if(tmpSundayWorkingHoursStopUpdated != "closed") tmpSundayWorkingHoursStopUpdated = "closed";
        if(tmpSundayRehearsalCost != -1) tmpSundayRehearsalCost = -1;
        if(tmpSundayRecordingCost != -1) tmpSundayRecordingCost = -1;
      }
      setSelectedRoom_Studio({...selectedRoom_Studio, 
        sunday_working_hours_start: tmpSundayWorkingHoursStartUpdated,      
        sunday_working_hours_stop: tmpSundayWorkingHoursStopUpdated,
        sunday_cost_per_hour_reh: tmpSundayRehearsalCost, 
        sunday_cost_per_hour_rec: tmpSundayRecordingCost
      });
    }
      
    if(myData_Studio.confirmationDialogVisible == true) {
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(i18n.t('areYouSure'),myData_Studio.confirmationMessage,
        [{ text: i18n.t('cancel'), onPress: () => 
                          setMyData_Studio({...myData_Studio, 
                            confirmationMessage: '',
                            confirmationDialogVisible: false
                          })
        },
        { text: 'OK', onPress: () => giveConfirmation()}
        ]);
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('areYouSure'));
        setAlertForWebText(myData_Studio.confirmationMessage);
        setAlertForWebVisible(true);
      }
    }
    setIsLoading(false);
    console.log("useEffect - myData_Studio - STOP");
  }, [JSON.stringify(myData_Studio)]);

  async function addRoomAsync() {
    //check the currency of the first room
    //if it's different, show alert
    if(myRoomsArray_Studio[0]!=undefined && myRoomsArray_Studio[0].currency!=selectedRoom_Studio.currency) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('differentCurrencies') + "(" + selectedRoom_Studio.currency + ")",[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('differentCurrencies') + "(" + selectedRoom_Studio.currency + ")");
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=='true' && selectedRoom_Studio.monday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=='true' && selectedRoom_Studio.monday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=='true' && selectedRoom_Studio.tuesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=='true' && selectedRoom_Studio.tuesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=='true' && selectedRoom_Studio.wednesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=='true' && selectedRoom_Studio.wednesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=='true' && selectedRoom_Studio.thursday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=='true' && selectedRoom_Studio.thursday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=='true' && selectedRoom_Studio.friday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=='true' && selectedRoom_Studio.friday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.saturdayOpen=='true' && selectedRoom_Studio.saturday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.saturdayOpen=='true' && selectedRoom_Studio.saturday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=='true' && selectedRoom_Studio.sunday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=='true' && selectedRoom_Studio.sunday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForMondayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForMondayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForMondayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForMondayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForTuesdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForTuesdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForTuesdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForTuesdayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForWednesdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForWednesdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForWednesdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForWednesdayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForThursdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForThursdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForThursdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForThursdayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForFridayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForFridayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForFridayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForFridayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSaturdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSaturdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSaturdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSaturdayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSundayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSundayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSundayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSundayRec'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=="false" && myData_Studio.tuesdayOpen=="false" && myData_Studio.wednesdayOpen=="false" && myData_Studio.thursdayOpen=="false" && myData_Studio.fridayOpen=="false" && myData_Studio.saturdayOpen=="false" && myData_Studio.sundayOpen=="false") {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('theRoomShouldBeAtLeastOneDayOpen'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('theRoomShouldBeAtLeastOneDayOpen'));
        setAlertForWebVisible(true);
      }
    }
      
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_reh==0 &&  myData_Studio.mondayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnMondayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_rec==0 &&  myData_Studio.mondayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnMondayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_reh==0 &&  myData_Studio.tuesdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnTuesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_rec==0 &&  myData_Studio.tuesdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnTuesdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_reh==0 &&  myData_Studio.wednesdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnWednesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_rec==0 &&  myData_Studio.wednesdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnWednesdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_reh==0 &&  myData_Studio.thursdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnThursdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_rec==0 &&  myData_Studio.thursdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnThursdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_reh==0 &&  myData_Studio.fridayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnFridayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_rec==0 &&  myData_Studio.fridayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnFridayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_reh==0 &&  myData_Studio.saturdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnSaturdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_rec==0 &&  myData_Studio.saturdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnSaturdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_reh==0 &&  myData_Studio.sundayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnSundayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_rec==0 &&  myData_Studio.sundayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnSundayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else {
      setIsLoading(true);       

      var final_mondayFrom: string = 'closed';
      var final_mondayTo: string = 'closed';
      var final_mondayRehCost: number = -1;
      var final_mondayRecCost: number = -1;

      var final_tuesdayFrom: string = 'closed';
      var final_tuesdayTo: string = 'closed';
      var final_tuesdayRehCost: number = -1;
      var final_tuesdayRecCost: number = -1;

      var final_wednesdayFrom: string = 'closed';
      var final_wednesdayTo: string = 'closed';
      var final_wednesdayRehCost: number = -1;
      var final_wednesdayRecCost: number = -1;

      var final_thursdayFrom: string = 'closed';
      var final_thursdayTo: string = 'closed';
      var final_thursdayRehCost: number = -1;
      var final_thursdayRecCost: number = -1;

      var final_fridayFrom: string = 'closed';
      var final_fridayTo: string = 'closed';
      var final_fridayRehCost: number = -1;
      var final_fridayRecCost: number = -1;

      var final_saturdayFrom: string = 'closed';
      var final_saturdayTo: string = 'closed';
      var final_saturdayRehCost: number = -1;
      var final_saturdayRecCost: number = -1;

      var final_sundayFrom: string = 'closed';
      var final_sundayTo: string = 'closed';
      var final_sundayRehCost: number = -1;
      var final_sundayRecCost: number = -1;

      if(myData_Studio.mondayOpen == "true") {
        final_mondayFrom = selectedRoom_Studio.monday_working_hours_start;
        final_mondayTo = selectedRoom_Studio.monday_working_hours_stop;
        final_mondayRehCost = selectedRoom_Studio.monday_cost_per_hour_reh;
        final_mondayRecCost = selectedRoom_Studio.monday_cost_per_hour_rec;
      }
      if(myData_Studio.tuesdayOpen == "true") {
        final_tuesdayFrom = selectedRoom_Studio.tuesday_working_hours_start;
        final_tuesdayTo = selectedRoom_Studio.tuesday_working_hours_stop;
        final_tuesdayRehCost = selectedRoom_Studio.tuesday_cost_per_hour_reh;
        final_tuesdayRecCost = selectedRoom_Studio.tuesday_cost_per_hour_rec;
      }
      if(myData_Studio.wednesdayOpen == "true") {
        final_wednesdayFrom = selectedRoom_Studio.wednesday_working_hours_start;
        final_wednesdayTo = selectedRoom_Studio.wednesday_working_hours_stop;
        final_wednesdayRehCost = selectedRoom_Studio.wednesday_cost_per_hour_reh;
        final_wednesdayRecCost = selectedRoom_Studio.wednesday_cost_per_hour_rec;
      }
      if(myData_Studio.thursdayOpen == "true") {
        final_thursdayFrom = selectedRoom_Studio.thursday_working_hours_start;
        final_thursdayTo = selectedRoom_Studio.thursday_working_hours_stop;
        final_thursdayRehCost = selectedRoom_Studio.thursday_cost_per_hour_reh;
        final_thursdayRecCost = selectedRoom_Studio.thursday_cost_per_hour_rec;
      }
      if(myData_Studio.fridayOpen == "true") {
        final_fridayFrom = selectedRoom_Studio.friday_working_hours_start;
        final_fridayTo = selectedRoom_Studio.friday_working_hours_stop;
        final_fridayRehCost = selectedRoom_Studio.friday_cost_per_hour_reh;
        final_fridayRecCost = selectedRoom_Studio.friday_cost_per_hour_rec;
      }
      if(myData_Studio.saturdayOpen == "true") {
        final_saturdayFrom = selectedRoom_Studio.saturday_working_hours_start;
        final_saturdayTo = selectedRoom_Studio.saturday_working_hours_stop;
        final_saturdayRehCost = selectedRoom_Studio.saturday_cost_per_hour_reh;
        final_saturdayRecCost = selectedRoom_Studio.saturday_cost_per_hour_rec;
      }
      if(myData_Studio.sundayOpen == "true") {
        final_sundayFrom = selectedRoom_Studio.sunday_working_hours_start;
        final_sundayTo = selectedRoom_Studio.sunday_working_hours_stop;
        final_sundayRehCost = selectedRoom_Studio.sunday_cost_per_hour_reh;
        final_sundayRecCost = selectedRoom_Studio.sunday_cost_per_hour_rec;
      }

      const tmpRoomToBeAdded : Studio_Room = {
        id: selectedRoom_Studio.id,
        owned_by: selectedRoom_Studio.owned_by,
        name: selectedRoom_Studio.name,
        currency: selectedRoom_Studio.currency,
        monday_cost_per_hour_reh: final_mondayRehCost,
        monday_cost_per_hour_rec: final_mondayRecCost,
        tuesday_cost_per_hour_reh: final_tuesdayRehCost,
        tuesday_cost_per_hour_rec: final_tuesdayRecCost,
        wednesday_cost_per_hour_reh: final_wednesdayRehCost,
        wednesday_cost_per_hour_rec: final_wednesdayRecCost,
        thursday_cost_per_hour_reh: final_thursdayRehCost,
        thursday_cost_per_hour_rec: final_thursdayRecCost,
        friday_cost_per_hour_reh: final_fridayRehCost,
        friday_cost_per_hour_rec: final_fridayRecCost,
        saturday_cost_per_hour_reh: final_saturdayRehCost,
        saturday_cost_per_hour_rec: final_saturdayRecCost,
        sunday_cost_per_hour_reh: final_sundayRehCost,
        sunday_cost_per_hour_rec: final_sundayRecCost,
        monday_working_hours_start: final_mondayFrom,
        tuesday_working_hours_start: final_tuesdayFrom,
        wednesday_working_hours_start: final_wednesdayFrom,
        thursday_working_hours_start: final_thursdayFrom,
        friday_working_hours_start: final_fridayFrom,
        saturday_working_hours_start: final_saturdayFrom,
        sunday_working_hours_start: final_sundayFrom,
        monday_working_hours_stop: final_mondayTo,
        tuesday_working_hours_stop: final_tuesdayTo,
        wednesday_working_hours_stop: final_wednesdayTo,
        thursday_working_hours_stop: final_thursdayTo,
        friday_working_hours_stop: final_fridayTo,
        saturday_working_hours_stop: final_saturdayTo,
        sunday_working_hours_stop: final_sundayTo,    
        space: selectedRoom_Studio.space,
        speakers: selectedRoom_Studio.speakers,
        console: selectedRoom_Studio.console,
        microphones: selectedRoom_Studio.microphones,
        drums: selectedRoom_Studio.drums,
        guitars: selectedRoom_Studio.guitars,
        basses: selectedRoom_Studio.basses,
        pianos_keyboards: selectedRoom_Studio.pianos_keyboards,
        guitar_amplifiers: selectedRoom_Studio.guitar_amplifiers,
        bass_amplifiers: selectedRoom_Studio.bass_amplifiers,
        other_equipment: selectedRoom_Studio.other_equipment
      }
      
      const newlyAddedRoom : Studio_Room = await Studio_RestManager.getInstance().addRoom(tmpRoomToBeAdded);

      //update selectedRoom id (it was -1 before actually added)
      setSelectedRoom_Studio({...selectedRoom_Studio, id: newlyAddedRoom.id});
      //update rooms stored array
      GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.push(newlyAddedRoom);
      console.log("meta to addRoom -> GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio: " + JSON.stringify(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio, null, 2));
      //setMyRoomsArray_Studio(prevRooms => [...prevRooms.slice(0, prevRooms.length - 1), newlyAddedRoom]);
      //setmyRoomsDropdownList_Studio(prevDropdown => [...prevDropdown.slice(0, prevDropdown.length - 1), { label: newlyAddedRoom.name, value: (prevDropdown.length).toString() }]);

      setIsLoading(false);
      if(MyAlertReactNative != null) {
        Alert.alert(
          i18n.t('congratulations'),
          i18n.t('roomAdded'),
          [
            { 
              text: "OK", onPress: () => { 
                setAddNewRoomMode_Studio(false);
              } 
            }
          ]
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('congratulations'));
        setAlertForWebText(i18n.t('roomAdded'));
        setAlertForWebVisible(true);
      }
    }  
  }

  async function editRoomAsync() {
    if(myData_Studio.mondayOpen=='true' && selectedRoom_Studio.monday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=='true' && selectedRoom_Studio.monday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=='true' && selectedRoom_Studio.tuesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=='true' && selectedRoom_Studio.tuesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=='true' && selectedRoom_Studio.wednesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=='true' && selectedRoom_Studio.wednesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=='true' && selectedRoom_Studio.thursday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=='true' && selectedRoom_Studio.thursday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=='true' && selectedRoom_Studio.friday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=='true' && selectedRoom_Studio.friday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStopHour'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.saturdayOpen=='true' && selectedRoom_Studio.saturday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.saturdayOpen=='true' && selectedRoom_Studio.saturday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=='true' && selectedRoom_Studio.sunday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=='true' && selectedRoom_Studio.sunday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStopHour'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForMondayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForMondayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForMondayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForMondayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForTuesdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForTuesdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForTuesdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForTuesdayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForWednesdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForWednesdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForWednesdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForWednesdayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForThursdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForThursdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForThursdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForThursdayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForFridayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForFridayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForFridayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForFridayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSaturdayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSaturdayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSaturdayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSaturdayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_reh<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSundayReh'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSundayReh'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_rec<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSundayRec'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSundayRec'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Studio.mondayOpen=="false" && myData_Studio.tuesdayOpen=="false" && myData_Studio.wednesdayOpen=="false" && myData_Studio.thursdayOpen=="false" && myData_Studio.fridayOpen=="false" && myData_Studio.saturdayOpen=="false" && myData_Studio.sundayOpen=="false") {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('theRoomShouldBeAtLeastOneDayOpen'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('theRoomShouldBeAtLeastOneDayOpen'));
        setAlertForWebVisible(true);
      }
    }
      
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_reh==0 &&  myData_Studio.mondayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnMondayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_rec==0 &&  myData_Studio.mondayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnMondayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_reh==0 &&  myData_Studio.tuesdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnTuesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_rec==0 &&  myData_Studio.tuesdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnTuesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_reh==0 &&  myData_Studio.wednesdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnWednesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_rec==0 &&  myData_Studio.wednesdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnWednesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_reh==0 &&  myData_Studio.thursdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnThursdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_rec==0 &&  myData_Studio.thursdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnThursdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_reh==0 &&  myData_Studio.fridayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnFridayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_rec==0 &&  myData_Studio.fridayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnFridayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_reh==0 &&  myData_Studio.saturdayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnSaturdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_rec==0 &&  myData_Studio.saturdayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnSaturdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_reh==0 &&  myData_Studio.sundayFreeRehIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRehearsalOnSundayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_rec==0 &&  myData_Studio.sundayFreeRecIsConfirmed==false) {
      setMyData_Studio({...myData_Studio, 
        confirmationMessage: i18n.t('areYouSureThatRecordingOnSundayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else {
      setIsLoading(true);       

      var final_mondayFrom: string = 'closed';
      var final_mondayTo: string = 'closed';
      var final_mondayRehCost: number = -1;
      var final_mondayRecCost: number = -1;

      var final_tuesdayFrom: string = 'closed';
      var final_tuesdayTo: string = 'closed';
      var final_tuesdayRehCost: number = -1;
      var final_tuesdayRecCost: number = -1;

      var final_wednesdayFrom: string = 'closed';
      var final_wednesdayTo: string = 'closed';
      var final_wednesdayRehCost: number = -1;
      var final_wednesdayRecCost: number = -1;

      var final_thursdayFrom: string = 'closed';
      var final_thursdayTo: string = 'closed';
      var final_thursdayRehCost: number = -1;
      var final_thursdayRecCost: number = -1;

      var final_fridayFrom: string = 'closed';
      var final_fridayTo: string = 'closed';
      var final_fridayRehCost: number = -1;
      var final_fridayRecCost: number = -1;

      var final_saturdayFrom: string = 'closed';
      var final_saturdayTo: string = 'closed';
      var final_saturdayRehCost: number = -1;
      var final_saturdayRecCost: number = -1;

      var final_sundayFrom: string = 'closed';
      var final_sundayTo: string = 'closed';
      var final_sundayRehCost: number = -1;
      var final_sundayRecCost: number = -1;

      if(myData_Studio.mondayOpen == "true") {
        final_mondayFrom = selectedRoom_Studio.monday_working_hours_start;
        final_mondayTo = selectedRoom_Studio.monday_working_hours_stop;
        final_mondayRehCost = selectedRoom_Studio.monday_cost_per_hour_reh;
        final_mondayRecCost = selectedRoom_Studio.monday_cost_per_hour_rec;
      }
      if(myData_Studio.tuesdayOpen == "true") {
        final_tuesdayFrom = selectedRoom_Studio.tuesday_working_hours_start;
        final_tuesdayTo = selectedRoom_Studio.tuesday_working_hours_stop;
        final_tuesdayRehCost = selectedRoom_Studio.tuesday_cost_per_hour_reh;
        final_tuesdayRecCost = selectedRoom_Studio.tuesday_cost_per_hour_rec;
      }
      if(myData_Studio.wednesdayOpen == "true") {
        final_wednesdayFrom = selectedRoom_Studio.wednesday_working_hours_start;
        final_wednesdayTo = selectedRoom_Studio.wednesday_working_hours_stop;
        final_wednesdayRehCost = selectedRoom_Studio.wednesday_cost_per_hour_reh;
        final_wednesdayRecCost = selectedRoom_Studio.wednesday_cost_per_hour_rec;
      }
      if(myData_Studio.thursdayOpen == "true") {
        final_thursdayFrom = selectedRoom_Studio.thursday_working_hours_start;
        final_thursdayTo = selectedRoom_Studio.thursday_working_hours_stop;
        final_thursdayRehCost = selectedRoom_Studio.thursday_cost_per_hour_reh;
        final_thursdayRecCost = selectedRoom_Studio.thursday_cost_per_hour_rec;
      }
      if(myData_Studio.fridayOpen == "true") {
        final_fridayFrom = selectedRoom_Studio.friday_working_hours_start;
        final_fridayTo = selectedRoom_Studio.friday_working_hours_stop;
        final_fridayRehCost = selectedRoom_Studio.friday_cost_per_hour_reh;
        final_fridayRecCost = selectedRoom_Studio.friday_cost_per_hour_rec;
      }
      if(myData_Studio.saturdayOpen == "true") {
        final_saturdayFrom = selectedRoom_Studio.saturday_working_hours_start;
        final_saturdayTo = selectedRoom_Studio.saturday_working_hours_stop;
        final_saturdayRehCost = selectedRoom_Studio.saturday_cost_per_hour_reh;
        final_saturdayRecCost = selectedRoom_Studio.saturday_cost_per_hour_rec;
      }
      if(myData_Studio.sundayOpen == "true") {
        final_sundayFrom = selectedRoom_Studio.sunday_working_hours_start;
        final_sundayTo = selectedRoom_Studio.sunday_working_hours_stop;
        final_sundayRehCost = selectedRoom_Studio.sunday_cost_per_hour_reh;
        final_sundayRecCost = selectedRoom_Studio.sunday_cost_per_hour_rec;
      }

      const tmpRoomToBeEdited : Studio_Room = {
        id: selectedRoom_Studio.id,
        owned_by: selectedRoom_Studio.owned_by,
        name: selectedRoom_Studio.name,
        currency: selectedRoom_Studio.currency,
        monday_cost_per_hour_reh: final_mondayRehCost,
        monday_cost_per_hour_rec: final_mondayRecCost,
        tuesday_cost_per_hour_reh: final_tuesdayRehCost,
        tuesday_cost_per_hour_rec: final_tuesdayRecCost,
        wednesday_cost_per_hour_reh: final_wednesdayRehCost,
        wednesday_cost_per_hour_rec: final_wednesdayRecCost,
        thursday_cost_per_hour_reh: final_thursdayRehCost,
        thursday_cost_per_hour_rec: final_thursdayRecCost,
        friday_cost_per_hour_reh: final_fridayRehCost,
        friday_cost_per_hour_rec: final_fridayRecCost,
        saturday_cost_per_hour_reh: final_saturdayRehCost,
        saturday_cost_per_hour_rec: final_saturdayRecCost,
        sunday_cost_per_hour_reh: final_sundayRehCost,
        sunday_cost_per_hour_rec: final_sundayRecCost,
        monday_working_hours_start: final_mondayFrom,
        tuesday_working_hours_start: final_tuesdayFrom,
        wednesday_working_hours_start: final_wednesdayFrom,
        thursday_working_hours_start: final_thursdayFrom,
        friday_working_hours_start: final_fridayFrom,
        saturday_working_hours_start: final_saturdayFrom,
        sunday_working_hours_start: final_sundayFrom,
        monday_working_hours_stop: final_mondayTo,
        tuesday_working_hours_stop: final_tuesdayTo,
        wednesday_working_hours_stop: final_wednesdayTo,
        thursday_working_hours_stop: final_thursdayTo,
        friday_working_hours_stop: final_fridayTo,
        saturday_working_hours_stop: final_saturdayTo,
        sunday_working_hours_stop: final_sundayTo,    
        space: selectedRoom_Studio.space,
        speakers: selectedRoom_Studio.speakers,
        console: selectedRoom_Studio.console,
        microphones: selectedRoom_Studio.microphones,
        drums: selectedRoom_Studio.drums,
        guitars: selectedRoom_Studio.guitars,
        basses: selectedRoom_Studio.basses,
        pianos_keyboards: selectedRoom_Studio.pianos_keyboards,
        guitar_amplifiers: selectedRoom_Studio.guitar_amplifiers,
        bass_amplifiers: selectedRoom_Studio.bass_amplifiers,
        other_equipment: selectedRoom_Studio.other_equipment
      }
      const webServiceResponseStr : string | void = await Studio_RestManager.getInstance().editRoom(tmpRoomToBeEdited);
      
      //update rooms stored array
      const indexOfRoomToBeUpdated = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.findIndex(room => room.id === tmpRoomToBeEdited.id);
      GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[indexOfRoomToBeUpdated] = tmpRoomToBeEdited;
      //to myRoomsArray ginetai update aytomata logw "const [myRoomsArray, setMyRoomsArray] = useState<Array<Room>>(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio);"
      
      setIsLoading(false);
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(
          i18n.t('congratulations'),
          i18n.t('roomUpdated'),
          [
            { 
              text: "OK", onPress: () => {}            
            }
          ]
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('congratulations'));
        setAlertForWebText(i18n.t('roomUpdated'));
        setAlertForWebVisible(true);
      }
    }
  }

  function deleteRoom() {
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(
        i18n.t('deleteRoom'),
        i18n.t('deleteRoomAreYouSure'),
        [
          { text: i18n.t('cancel'), onPress: () => null },
          { text: "OK", onPress: () => { deleteRoomAsync(); }
          }
        ]
      );
    }
    else if(MyAlertForWeb != null) {
      setAlertForWebTitle(i18n.t('deleteRoom'));
      setAlertForWebText(i18n.t('deleteRoomAreYouSure'));
      setAlertForWebVisible(true);
    }
  }
  
  async function deleteRoomAsync() {
    setIsLoading(true);
    const webServiceResponseStr : string | void = await Studio_RestManager.getInstance().deleteRoom(selectedRoom_Studio.id);
    console.log("response deleteRoom: " + webServiceResponseStr);
  
    const index = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.findIndex(item => item.id === selectedRoom_Studio.id);
    //update stored arrays  
    GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.splice(index, 1);
    console.log("meta to deleteRoom -> GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio: " + JSON.stringify(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio, null, 2));
    myRoomsArray_Studio.splice(index, 1);
    console.log("TO META (myRoomsArray_Studio): ", JSON.stringify(myRoomsArray_Studio, null, 2));
    //  To "useEffect - myRoomsArray_Studio" tha prokalesei: 
    //    setSelectedRoomDropdownIndex_Studio(0);
    //  kai thewrhtika tha mpei sto "useEffect - selectedRoomDropdownIndex_Studio" poy tha prokalesei:
    //    setSelectedRoom_Studio(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[selectedRoomDropdownIndex_Studio]);
    //  alla epeidh sthn periptwsh pou esvhsa to 1o Room (index = 0), den tha mpei sto "useEffect - selectedRoomDropdownIndex_Studio"
    //  kalw edw to setSelectedRoom_Studio
    setSelectedRoom_Studio(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0]);
    console.log("afairw index: ", index, " apo myRoomsDropdownList_Studio");
    const updatedDropDownListWithPossibleWrongValues = myRoomsDropdownList_Studio.filter((_, i) => i !== index);
    // prosoxh: prepei na kanw tropopoihsh sta values toy DropDownList gia na mhn exw undefined
    // p.x. an svhsw to index 0, to aray tha einai:
    //    [{
    //       "label": "Room A",
    //       "value": "1"
    //     },
    //     {
    //       "label": "Room B",
    //       "value": "2"
    //    }]
    // kai prepei na ginei:
    //       "label": "Room A",
    //       "value": "0"
    //     },
    //     {
    //       "label": "Room B",
    //       "value": "1"
    //    }]
    const updatedDropDownListWithCorrectValues = updatedDropDownListWithPossibleWrongValues.map((item, index) => {
      return {
        label: item.label,
        value: index.toString()
      }
    });  
  
    console.log("aytoToDropdownListThaSetarw: ", JSON.stringify(updatedDropDownListWithCorrectValues, null, 2));
    setMyRoomsDropdownList_Studio(updatedDropDownListWithCorrectValues);   
  
    setIsLoading(false);
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(
        '',
        i18n.t('roomHasBeenDeleted'),
        [
          { 
            text: "OK", onPress: () => {}        
          }
        ]
      );
    }
    else if(MyAlertForWeb != null) {
      setAlertForWebTitle(i18n.t(''));
      setAlertForWebText(i18n.t('roomHasBeenDeleted'));
      setAlertForWebVisible(true);
    }
  }

  const proposeMondayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    else if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.tuesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_start;
    else if(selectedRoom_Studio.wednesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_start;
    else if(selectedRoom_Studio.thursday_working_hours_start != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_start;
    else if(selectedRoom_Studio.friday_working_hours_start != "closed") resultStr = selectedRoom_Studio.friday_working_hours_start;
    else if(selectedRoom_Studio.saturday_working_hours_start != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_start;
    else if(selectedRoom_Studio.sunday_working_hours_start != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, monday_working_hours_start: resultStr});
    console.log("proposeMondayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.tuesday_working_hours_start,
    selectedRoom_Studio.wednesday_working_hours_start,
    selectedRoom_Studio.thursday_working_hours_start,
    selectedRoom_Studio.friday_working_hours_start,
    selectedRoom_Studio.saturday_working_hours_start,
    selectedRoom_Studio.sunday_working_hours_start]);

  const proposeTuesdayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    else if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_start != "closed") resultStr = selectedRoom_Studio.monday_working_hours_start;
    else if(selectedRoom_Studio.wednesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_start;
    else if(selectedRoom_Studio.thursday_working_hours_start != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_start;
    else if(selectedRoom_Studio.friday_working_hours_start != "closed") resultStr = selectedRoom_Studio.friday_working_hours_start;
    else if(selectedRoom_Studio.saturday_working_hours_start != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_start;
    else if(selectedRoom_Studio.sunday_working_hours_start != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, tuesday_working_hours_start: resultStr});
    console.log("proposeTuesdayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_start, 
    selectedRoom_Studio.wednesday_working_hours_start,
    selectedRoom_Studio.thursday_working_hours_start,
    selectedRoom_Studio.friday_working_hours_start,
    selectedRoom_Studio.saturday_working_hours_start,
    selectedRoom_Studio.sunday_working_hours_start]);

  const proposeWednesdayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    else if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_start != "closed") resultStr = selectedRoom_Studio.monday_working_hours_start;
    else if(selectedRoom_Studio.tuesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_start;
    else if(selectedRoom_Studio.thursday_working_hours_start != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_start;
    else if(selectedRoom_Studio.friday_working_hours_start != "closed") resultStr = selectedRoom_Studio.friday_working_hours_start;
    else if(selectedRoom_Studio.saturday_working_hours_start != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_start;
    else if(selectedRoom_Studio.sunday_working_hours_start != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, wednesday_working_hours_start: resultStr});
    console.log("proposeWednesdayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_start, 
    selectedRoom_Studio.tuesday_working_hours_start,
    selectedRoom_Studio.thursday_working_hours_start,
    selectedRoom_Studio.friday_working_hours_start,
    selectedRoom_Studio.saturday_working_hours_start,
    selectedRoom_Studio.sunday_working_hours_start]);

  const proposeThursdayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    else if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_start != "closed") resultStr = selectedRoom_Studio.monday_working_hours_start;
    else if(selectedRoom_Studio.tuesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_start;
    else if(selectedRoom_Studio.wednesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_start;
    else if(selectedRoom_Studio.friday_working_hours_start != "closed") resultStr = selectedRoom_Studio.friday_working_hours_start;
    else if(selectedRoom_Studio.saturday_working_hours_start != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_start;
    else if(selectedRoom_Studio.sunday_working_hours_start != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, thursday_working_hours_start: resultStr});
    console.log("proposeThursdayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_start, 
    selectedRoom_Studio.tuesday_working_hours_start,
    selectedRoom_Studio.wednesday_working_hours_start,
    selectedRoom_Studio.friday_working_hours_start,
    selectedRoom_Studio.saturday_working_hours_start,
    selectedRoom_Studio.sunday_working_hours_start]);

  const proposeFridayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    else if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_start != "closed") resultStr = selectedRoom_Studio.monday_working_hours_start;
    else if(selectedRoom_Studio.tuesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_start;
    else if(selectedRoom_Studio.wednesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_start;
    else if(selectedRoom_Studio.thursday_working_hours_start != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_start;
    else if(selectedRoom_Studio.saturday_working_hours_start != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_start;
    else if(selectedRoom_Studio.sunday_working_hours_start != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, friday_working_hours_start: resultStr});
    console.log("proposeFridayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_start, 
      selectedRoom_Studio.tuesday_working_hours_start,
      selectedRoom_Studio.wednesday_working_hours_start,
      selectedRoom_Studio.thursday_working_hours_start,
      selectedRoom_Studio.saturday_working_hours_start,
      selectedRoom_Studio.sunday_working_hours_start]);

  const proposeSaturdayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    else if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_start != "closed") resultStr = selectedRoom_Studio.monday_working_hours_start;
    else if(selectedRoom_Studio.tuesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_start;
    else if(selectedRoom_Studio.wednesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_start;
    else if(selectedRoom_Studio.thursday_working_hours_start != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_start;
    else if(selectedRoom_Studio.friday_working_hours_start != "closed") resultStr = selectedRoom_Studio.friday_working_hours_start;
    else if(selectedRoom_Studio.sunday_working_hours_start != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, saturday_working_hours_start: resultStr});
    console.log("proposeSaturdayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_start, 
    selectedRoom_Studio.tuesday_working_hours_start,
    selectedRoom_Studio.wednesday_working_hours_start,
    selectedRoom_Studio.thursday_working_hours_start,
    selectedRoom_Studio.friday_working_hours_start,
    selectedRoom_Studio.sunday_working_hours_start]);

  const proposeSundayStartHour_Studio = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.sunday_working_hours_start != "closed") resultStr = tmpFirstRoom.sunday_working_hours_start;
    else if(tmpFirstRoom.monday_working_hours_start != "closed") resultStr = tmpFirstRoom.monday_working_hours_start;
    else if(tmpFirstRoom.tuesday_working_hours_start != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_start;
    else if(tmpFirstRoom.wednesday_working_hours_start != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_start;
    else if(tmpFirstRoom.thursday_working_hours_start != "closed") resultStr = tmpFirstRoom.thursday_working_hours_start;
    else if(tmpFirstRoom.friday_working_hours_start != "closed") resultStr = tmpFirstRoom.friday_working_hours_start;
    else if(tmpFirstRoom.saturday_working_hours_start != "closed") resultStr = tmpFirstRoom.saturday_working_hours_start;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_start != "closed") resultStr = selectedRoom_Studio.monday_working_hours_start;
    else if(selectedRoom_Studio.tuesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_start;
    else if(selectedRoom_Studio.wednesday_working_hours_start != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_start;
    else if(selectedRoom_Studio.thursday_working_hours_start != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_start;
    else if(selectedRoom_Studio.friday_working_hours_start != "closed") resultStr = selectedRoom_Studio.friday_working_hours_start;
    else if(selectedRoom_Studio.saturday_working_hours_start != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_start;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, sunday_working_hours_start: resultStr});
    console.log("proposeSundayStartHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_start, 
    selectedRoom_Studio.tuesday_working_hours_start,
    selectedRoom_Studio.wednesday_working_hours_start,
    selectedRoom_Studio.thursday_working_hours_start,
    selectedRoom_Studio.friday_working_hours_start,
    selectedRoom_Studio.saturday_working_hours_start]);

  const proposeMondayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    else if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.tuesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_stop;
    else if(selectedRoom_Studio.wednesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_stop;
    else if(selectedRoom_Studio.thursday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_stop;
    else if(selectedRoom_Studio.friday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.friday_working_hours_stop;
    else if(selectedRoom_Studio.saturday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_stop;
    else if(selectedRoom_Studio.sunday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, monday_working_hours_stop: resultStr});
    console.log("proposeMondayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.tuesday_working_hours_stop,
    selectedRoom_Studio.wednesday_working_hours_stop,
    selectedRoom_Studio.thursday_working_hours_stop,
    selectedRoom_Studio.friday_working_hours_stop,
    selectedRoom_Studio.saturday_working_hours_stop,
    selectedRoom_Studio.sunday_working_hours_stop]);

  const proposeTuesdayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    else if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.monday_working_hours_stop;
    else if(selectedRoom_Studio.wednesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_stop;
    else if(selectedRoom_Studio.thursday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_stop;
    else if(selectedRoom_Studio.friday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.friday_working_hours_stop;
    else if(selectedRoom_Studio.saturday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_stop;
    else if(selectedRoom_Studio.sunday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, tuesday_working_hours_stop: resultStr});
    console.log("proposeTuesdayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_stop, 
    selectedRoom_Studio.wednesday_working_hours_stop,
    selectedRoom_Studio.thursday_working_hours_stop,
    selectedRoom_Studio.friday_working_hours_stop,
    selectedRoom_Studio.saturday_working_hours_stop,
    selectedRoom_Studio.sunday_working_hours_stop]);

  const proposeWednesdayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    else if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.monday_working_hours_stop;
    else if(selectedRoom_Studio.tuesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_stop;
    else if(selectedRoom_Studio.thursday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_stop;
    else if(selectedRoom_Studio.friday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.friday_working_hours_stop;
    else if(selectedRoom_Studio.saturday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_stop;
    else if(selectedRoom_Studio.sunday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, wednesday_working_hours_stop: resultStr});
    console.log("proposeWednesdayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_stop, 
    selectedRoom_Studio.tuesday_working_hours_stop,
    selectedRoom_Studio.thursday_working_hours_stop,
    selectedRoom_Studio.friday_working_hours_stop,
    selectedRoom_Studio.saturday_working_hours_stop,
    selectedRoom_Studio.sunday_working_hours_stop]);

  const proposeThursdayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    else if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.monday_working_hours_stop;
    else if(selectedRoom_Studio.tuesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_stop;
    else if(selectedRoom_Studio.wednesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_stop;
    else if(selectedRoom_Studio.friday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.friday_working_hours_stop;
    else if(selectedRoom_Studio.saturday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_stop;
    else if(selectedRoom_Studio.sunday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, thursday_working_hours_stop: resultStr});
    console.log("proposeThursdayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_stop, 
    selectedRoom_Studio.tuesday_working_hours_stop,
    selectedRoom_Studio.wednesday_working_hours_stop,
    selectedRoom_Studio.friday_working_hours_stop,
    selectedRoom_Studio.saturday_working_hours_stop,
    selectedRoom_Studio.sunday_working_hours_stop]);

  const proposeFridayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    else if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.monday_working_hours_stop;
    else if(selectedRoom_Studio.tuesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_stop;
    else if(selectedRoom_Studio.wednesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_stop;
    else if(selectedRoom_Studio.thursday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_stop;
    else if(selectedRoom_Studio.saturday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_stop;
    else if(selectedRoom_Studio.sunday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, friday_working_hours_stop: resultStr});
    console.log("proposeFridayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_stop, 
    selectedRoom_Studio.tuesday_working_hours_stop,
    selectedRoom_Studio.wednesday_working_hours_stop,
    selectedRoom_Studio.thursday_working_hours_stop,
    selectedRoom_Studio.saturday_working_hours_stop,
    selectedRoom_Studio.sunday_working_hours_stop]);

  const proposeSaturdayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    else if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.monday_working_hours_stop;
    else if(selectedRoom_Studio.tuesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_stop;
    else if(selectedRoom_Studio.wednesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_stop;
    else if(selectedRoom_Studio.thursday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_stop;
    else if(selectedRoom_Studio.friday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.friday_working_hours_stop;
    else if(selectedRoom_Studio.sunday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.sunday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, saturday_working_hours_stop: resultStr});
    console.log("proposeSaturdayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_stop, 
    selectedRoom_Studio.tuesday_working_hours_stop,
    selectedRoom_Studio.wednesday_working_hours_stop,
    selectedRoom_Studio.thursday_working_hours_stop,
    selectedRoom_Studio.friday_working_hours_stop,
    selectedRoom_Studio.sunday_working_hours_stop]);

  const proposeSundayStopHour_Studio = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.sunday_working_hours_stop != "closed") resultStr = tmpFirstRoom.sunday_working_hours_stop;
    else if(tmpFirstRoom.monday_working_hours_stop != "closed") resultStr = tmpFirstRoom.monday_working_hours_stop;
    else if(tmpFirstRoom.tuesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.tuesday_working_hours_stop;
    else if(tmpFirstRoom.wednesday_working_hours_stop != "closed") resultStr = tmpFirstRoom.wednesday_working_hours_stop;
    else if(tmpFirstRoom.thursday_working_hours_stop != "closed") resultStr = tmpFirstRoom.thursday_working_hours_stop;
    else if(tmpFirstRoom.friday_working_hours_stop != "closed") resultStr = tmpFirstRoom.friday_working_hours_stop;
    else if(tmpFirstRoom.saturday_working_hours_stop != "closed") resultStr = tmpFirstRoom.saturday_working_hours_stop;
    //then override with the current room
    if(selectedRoom_Studio.monday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.monday_working_hours_stop;
    else if(selectedRoom_Studio.tuesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.tuesday_working_hours_stop;
    else if(selectedRoom_Studio.wednesday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.wednesday_working_hours_stop;
    else if(selectedRoom_Studio.thursday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.thursday_working_hours_stop;
    else if(selectedRoom_Studio.friday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.friday_working_hours_stop;
    else if(selectedRoom_Studio.saturday_working_hours_stop != "closed") resultStr = selectedRoom_Studio.saturday_working_hours_stop;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, sunday_working_hours_stop: resultStr});
    console.log("proposeSundayStopHour_Studio: ", resultStr);
    return resultStr;    
  }, [selectedRoom_Studio.monday_working_hours_stop, 
    selectedRoom_Studio.tuesday_working_hours_stop,
    selectedRoom_Studio.wednesday_working_hours_stop,
    selectedRoom_Studio.thursday_working_hours_stop,
    selectedRoom_Studio.friday_working_hours_stop,
    selectedRoom_Studio.saturday_working_hours_stop]);

  const proposeMondayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    else if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.tuesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.thursday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_reh;
    else if(selectedRoom_Studio.friday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_reh;
    else if(selectedRoom_Studio.saturday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_reh;
    else if(selectedRoom_Studio.sunday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, monday_cost_per_hour_reh: resultNum});
    console.log("proposeMondayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.tuesday_cost_per_hour_reh,
    selectedRoom_Studio.wednesday_cost_per_hour_reh,
    selectedRoom_Studio.thursday_cost_per_hour_reh,
    selectedRoom_Studio.friday_cost_per_hour_reh,
    selectedRoom_Studio.saturday_cost_per_hour_reh,
    selectedRoom_Studio.sunday_cost_per_hour_reh]);

  const proposeTuesdayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    else if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_reh;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.thursday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_reh;
    else if(selectedRoom_Studio.friday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_reh;
    else if(selectedRoom_Studio.saturday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_reh;
    else if(selectedRoom_Studio.sunday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, tuesday_cost_per_hour_reh: resultNum});
    console.log("proposeTuesdayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_reh, 
    selectedRoom_Studio.wednesday_cost_per_hour_reh,
    selectedRoom_Studio.thursday_cost_per_hour_reh,
    selectedRoom_Studio.friday_cost_per_hour_reh,
    selectedRoom_Studio.saturday_cost_per_hour_reh,
    selectedRoom_Studio.sunday_cost_per_hour_reh]);

  const proposeWednesdayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    else if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_reh;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.thursday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_reh;
    else if(selectedRoom_Studio.friday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_reh;
    else if(selectedRoom_Studio.saturday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_reh;
    else if(selectedRoom_Studio.sunday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, wednesday_cost_per_hour_reh: resultNum});
    console.log("proposeWednesdayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_reh, 
    selectedRoom_Studio.tuesday_cost_per_hour_reh,
    selectedRoom_Studio.thursday_cost_per_hour_reh,
    selectedRoom_Studio.friday_cost_per_hour_reh,
    selectedRoom_Studio.saturday_cost_per_hour_reh,
    selectedRoom_Studio.sunday_cost_per_hour_reh]);

  const proposeThursdayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    else if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_reh;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.friday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_reh;
    else if(selectedRoom_Studio.saturday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_reh;
    else if(selectedRoom_Studio.sunday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, thursday_cost_per_hour_reh: resultNum});
    console.log("proposeThursdayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_reh, 
    selectedRoom_Studio.tuesday_cost_per_hour_reh,
    selectedRoom_Studio.wednesday_cost_per_hour_reh,
    selectedRoom_Studio.friday_cost_per_hour_reh,
    selectedRoom_Studio.saturday_cost_per_hour_reh,
    selectedRoom_Studio.sunday_cost_per_hour_reh]);

  const proposeFridayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    else if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_reh;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.thursday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_reh;
    else if(selectedRoom_Studio.saturday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_reh;
    else if(selectedRoom_Studio.sunday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, friday_cost_per_hour_reh: resultNum});
    console.log("proposeFridayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_reh, 
    selectedRoom_Studio.tuesday_cost_per_hour_reh,
    selectedRoom_Studio.wednesday_cost_per_hour_reh,
    selectedRoom_Studio.thursday_cost_per_hour_reh,
    selectedRoom_Studio.saturday_cost_per_hour_reh,
    selectedRoom_Studio.sunday_cost_per_hour_reh]);

  const proposeSaturdayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    else if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_reh;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.thursday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_reh;
    else if(selectedRoom_Studio.friday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_reh;
    else if(selectedRoom_Studio.sunday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, saturday_cost_per_hour_reh: resultNum});
    console.log("proposeSaturdayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_reh, 
    selectedRoom_Studio.tuesday_cost_per_hour_reh,
    selectedRoom_Studio.wednesday_cost_per_hour_reh,
    selectedRoom_Studio.thursday_cost_per_hour_reh,
    selectedRoom_Studio.friday_cost_per_hour_reh,
    selectedRoom_Studio.sunday_cost_per_hour_reh]);

  const proposeSundayRehearsalCost_Studio = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.sunday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_reh;
    else if(tmpFirstRoom.monday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_reh;
    else if(tmpFirstRoom.tuesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_reh;
    else if(tmpFirstRoom.wednesday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_reh;
    else if(tmpFirstRoom.thursday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_reh;
    else if(tmpFirstRoom.friday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_reh;
    else if(tmpFirstRoom.saturday_cost_per_hour_reh != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_reh;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_reh;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_reh;
    else if(selectedRoom_Studio.thursday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_reh;
    else if(selectedRoom_Studio.friday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_reh;
    else if(selectedRoom_Studio.saturday_cost_per_hour_reh != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_reh;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, sunday_cost_per_hour_reh: resultNum});
    console.log("proposeSundayRehearsalCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_reh, 
    selectedRoom_Studio.tuesday_cost_per_hour_reh,
    selectedRoom_Studio.wednesday_cost_per_hour_reh,
    selectedRoom_Studio.thursday_cost_per_hour_reh,
    selectedRoom_Studio.friday_cost_per_hour_reh,
    selectedRoom_Studio.saturday_cost_per_hour_reh]);

  const proposeMondayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    else if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.tuesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.thursday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_rec;
    else if(selectedRoom_Studio.friday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_rec;
    else if(selectedRoom_Studio.saturday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_rec;
    else if(selectedRoom_Studio.sunday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, monday_cost_per_hour_rec: resultNum});
    console.log("proposeMondayRecordingCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.tuesday_cost_per_hour_rec,
    selectedRoom_Studio.wednesday_cost_per_hour_rec,
    selectedRoom_Studio.thursday_cost_per_hour_rec,
    selectedRoom_Studio.friday_cost_per_hour_rec,
    selectedRoom_Studio.saturday_cost_per_hour_rec,
    selectedRoom_Studio.sunday_cost_per_hour_rec]);

  const proposeTuesdayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    else if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_rec;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.thursday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_rec;
    else if(selectedRoom_Studio.friday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_rec;
    else if(selectedRoom_Studio.saturday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_rec;
    else if(selectedRoom_Studio.sunday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, tuesday_cost_per_hour_rec: resultNum});
    console.log("proposeTuesdayRecordingCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_rec, 
    selectedRoom_Studio.wednesday_cost_per_hour_rec,
    selectedRoom_Studio.thursday_cost_per_hour_rec,
    selectedRoom_Studio.friday_cost_per_hour_rec,
    selectedRoom_Studio.saturday_cost_per_hour_rec,
    selectedRoom_Studio.sunday_cost_per_hour_rec]);

  const proposeWednesdayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    else if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_rec;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.thursday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_rec;
    else if(selectedRoom_Studio.friday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_rec;
    else if(selectedRoom_Studio.saturday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_rec;
    else if(selectedRoom_Studio.sunday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, wednesday_cost_per_hour_rec: resultNum});
    console.log("proposeWednesdayRecordingost: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_rec, 
    selectedRoom_Studio.tuesday_cost_per_hour_rec,
    selectedRoom_Studio.thursday_cost_per_hour_rec,
    selectedRoom_Studio.friday_cost_per_hour_rec,
    selectedRoom_Studio.saturday_cost_per_hour_rec,
    selectedRoom_Studio.sunday_cost_per_hour_rec]);

  const proposeThursdayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    else if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_rec;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.friday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_rec;
    else if(selectedRoom_Studio.saturday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_rec;
    else if(selectedRoom_Studio.sunday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, thursday_cost_per_hour_rec: resultNum});
    console.log("proposeThursdayRecordingCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_rec, 
    selectedRoom_Studio.tuesday_cost_per_hour_rec,
    selectedRoom_Studio.wednesday_cost_per_hour_rec,
    selectedRoom_Studio.friday_cost_per_hour_rec,
    selectedRoom_Studio.saturday_cost_per_hour_rec,
    selectedRoom_Studio.sunday_cost_per_hour_rec]);

  const proposeFridayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    else if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_rec;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.thursday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_rec;
    else if(selectedRoom_Studio.saturday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_rec;
    else if(selectedRoom_Studio.sunday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, friday_cost_per_hour_rec: resultNum});
    console.log("proposeFridayRecordingCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_rec, 
    selectedRoom_Studio.tuesday_cost_per_hour_rec,
    selectedRoom_Studio.wednesday_cost_per_hour_rec,
    selectedRoom_Studio.thursday_cost_per_hour_rec,
    selectedRoom_Studio.saturday_cost_per_hour_rec,
    selectedRoom_Studio.sunday_cost_per_hour_rec]);

  const proposeSaturdayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    else if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_rec;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.thursday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_rec;
    else if(selectedRoom_Studio.friday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_rec;
    else if(selectedRoom_Studio.sunday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.sunday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, saturday_cost_per_hour_rec: resultNum});
    console.log("proposeSaturdayRecordingCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_rec, 
    selectedRoom_Studio.tuesday_cost_per_hour_rec,
    selectedRoom_Studio.wednesday_cost_per_hour_rec,
    selectedRoom_Studio.thursday_cost_per_hour_rec,
    selectedRoom_Studio.friday_cost_per_hour_rec,
    selectedRoom_Studio.sunday_cost_per_hour_rec]);

  const proposeSundayRecordingCost_Studio = useCallback(() : number => {
    var resultNum : number = 30;
    //first consider the first room
    const tmpFirstRoom : Studio_Room = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[0];
    if(tmpFirstRoom.sunday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.sunday_cost_per_hour_rec;
    else if(tmpFirstRoom.monday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.monday_cost_per_hour_rec;
    else if(tmpFirstRoom.tuesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.tuesday_cost_per_hour_rec;
    else if(tmpFirstRoom.wednesday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.wednesday_cost_per_hour_rec;
    else if(tmpFirstRoom.thursday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.thursday_cost_per_hour_rec;
    else if(tmpFirstRoom.friday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.friday_cost_per_hour_rec;
    else if(tmpFirstRoom.saturday_cost_per_hour_rec != -1) resultNum = tmpFirstRoom.saturday_cost_per_hour_rec;
    //then override with the current room
    if(selectedRoom_Studio.monday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.monday_cost_per_hour_rec;
    else if(selectedRoom_Studio.tuesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.tuesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.wednesday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.wednesday_cost_per_hour_rec;
    else if(selectedRoom_Studio.thursday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.thursday_cost_per_hour_rec;
    else if(selectedRoom_Studio.friday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.friday_cost_per_hour_rec;
    else if(selectedRoom_Studio.saturday_cost_per_hour_rec != -1) resultNum = selectedRoom_Studio.saturday_cost_per_hour_rec;
    //finally...set it!
    setSelectedRoom_Studio({...selectedRoom_Studio, sunday_cost_per_hour_rec: resultNum});
    console.log("proposeSundayRecordingCost_Studio: ", resultNum);
    return resultNum;  
  }, [selectedRoom_Studio.monday_cost_per_hour_rec, 
    selectedRoom_Studio.tuesday_cost_per_hour_rec,
    selectedRoom_Studio.wednesday_cost_per_hour_rec,
    selectedRoom_Studio.thursday_cost_per_hour_rec,
    selectedRoom_Studio.friday_cost_per_hour_rec,
    selectedRoom_Studio.saturday_cost_per_hour_rec]);

  const [ownerExtraInfo_Studio, setOwnerExtraInfo_Studio] = useState<Studio_OwnerExtraInfo>({
    id: -1,
    owner_id: -1,
    allows_rehearsal: false,
    allows_recording: false
  });
  useEffect(() => {
    console.log("useEffect - ownerExtraInfo_Studio - START - ownerExtraInfo_Studio", ownerExtraInfo_Studio);
    
    console.log("useEffect - ownerExtraInfo_Studio - STOP");
  }, [JSON.stringify(ownerExtraInfo_Studio)]);
/////////////////////////
// Studio domain - END //
/////////////////////////

///////////////////////////
// Tennis domain - START //
///////////////////////////
  const [myCourtsArray_Tennis, setMyCourtsArray_Tennis] = useState<Array<Tennis_Court>>(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis);
  useEffect(() => {
    console.log("useEffect - myCourtsArray_Tennis - START");
    console.log("myCourtsArray_Tennis: " + JSON.stringify(myCourtsArray_Tennis,null, 2));
    if(addNewCourtMode_Tennis == true) {
      console.log("useEffect - myCourtsArray_Tennis (addNewCourtMode_Tennis=", addNewCourtMode_Tennis, ") kalei -> setSelectedCourtDropdownIndex_Tennis(myCourtsArray_Tennis.length-1) -> setSelectedCourtDropdownIndex_Tennis(", myCourtsArray_Tennis.length-1, ")");
      setSelectedCourtDropdownIndex_Tennis(myCourtsArray_Tennis.length-1);
    }
    else {
      console.log("useEffect - myCourtsArray (addNewCourtMode_Tennis=", addNewCourtMode_Tennis, ") kalei -> setSelectedCourtDropdownIndex_Tennis(0);");
      setSelectedCourtDropdownIndex_Tennis(0);
    }
    console.log("useEffect - myCourtsArray - STOP");
  }, [myCourtsArray_Tennis.length]);

  const [myCourtsDropdownList_Tennis, setMyCourtsDropdownList_Tennis] = useState<Array<DropdownItem>>(
    myCourtsArray_Tennis.map((tmpCourt, tmpIndex) => ({
        label: tmpCourt.name,
        value: tmpIndex.toString()
    }))
  );
  useEffect(() => {
    console.log("useEffect - myCourtsDropdownList_Tennis - START -> myCourtsDropdownList_Tennis: ", JSON.stringify(myCourtsDropdownList_Tennis, null, 2));
    console.log("useEffect - myCourtsDropdownList_Tennis - STOP");
  }, [JSON.stringify(myCourtsDropdownList_Tennis)]);

  const [showCourtsDropdownList_Tennis, setShowCourtsDropdownList_Tennis] = useState(false);
  const [selectedCourtDropdownIndex_Tennis, setSelectedCourtDropdownIndex_Tennis] = useState(0);
  useEffect(() => {
    console.log("useEffect - selectedCourtDropdownIndex_Tennis - START -> selectedCourtDropdownIndex_Tennis: ", selectedCourtDropdownIndex_Tennis);
    if(addNewCourtMode_Tennis == false)
      setSelectedCourt_Tennis(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[selectedCourtDropdownIndex_Tennis]);
    console.log("useEffect - selectedCourtDropdownIndex_Tennis - STOP");
  }, [selectedCourtDropdownIndex_Tennis]);

  const [cancelNewCourtMode_Tennis, setCancelNewCourtMode_Tennis] = useState(false);
  useEffect(() => {
    console.log("useEffect - cancelNewCourtMode_Tennis - START -> cancelNewCourtMode_Tennis: ", cancelNewCourtMode_Tennis);
    if(cancelNewCourtMode_Tennis == true) {
      setIsLoading(true);
      setTimeout(() => { //just to ensure that loading will show
        setAddNewCourtMode_Tennis(false);
      }, 100);
    }
    console.log("useEffect - cancelNewCourtMode_Tennis - STOP");
  }, [cancelNewCourtMode_Tennis]);

  const [addNewCourtMode_Tennis, setAddNewCourtMode_Tennis] = useState(false);
  useEffect(() => {
    console.log("useEffect - addNewCourtMode_Tennis - START -> addNewCourtMode_Tennis: ", addNewCourtMode_Tennis);
    if(addNewCourtMode_Tennis == true) {
      setIsLoading(true);
      setTimeout(() => { //just to ensure that loading will show
        setCancelNewCourtMode_Tennis(false);
        setSelectedCourt_Tennis({...selectedCourt_Tennis, 
          id: -1,
          owned_by: LoginManager.getInstance().ID,
          name: 'Court ' + (GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.length + 1).toString(),
          type: 5, //clay
          currency: GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0].currency,
          monday_cost_per_hour: -1,
          tuesday_cost_per_hour: -1,
          wednesday_cost_per_hour: -1,
          thursday_cost_per_hour: -1,
          friday_cost_per_hour: -1,
          saturday_cost_per_hour: -1,
          sunday_cost_per_hour: -1,
          monday_working_hours_start: 'closed',
          tuesday_working_hours_start: 'closed',
          wednesday_working_hours_start: 'closed',
          thursday_working_hours_start: 'closed',
          friday_working_hours_start: 'closed',
          saturday_working_hours_start: 'closed',
          sunday_working_hours_start: 'closed',
          monday_working_hours_stop: 'closed',
          tuesday_working_hours_stop: 'closed',
          wednesday_working_hours_stop: 'closed',
          thursday_working_hours_stop: 'closed',
          friday_working_hours_stop: 'closed',
          saturday_working_hours_stop: 'closed',
          sunday_working_hours_stop: 'closed'
        });
      }, 100);
    }
    else if(addNewCourtMode_Tennis == false) {
      if(cancelNewCourtMode_Tennis == true) {
        console.log("  eimai sto useEffect - addNewCourtMode_Tennis kai svhnw last item myCourtsArray kai myCourtsDropdownList_Tennis epeidh addNewCourtMode_Tennis==false && cancelNewCourtMode_Tennis==true");
        setMyCourtsArray_Tennis([...myCourtsArray_Tennis].slice(0, myCourtsArray_Tennis.length-1));  //delete last item
        setMyCourtsDropdownList_Tennis([...myCourtsDropdownList_Tennis].slice(0, myCourtsDropdownList_Tennis.length-1));  //delete last item
      }
    }
    console.log("useEffect - addNewCourtMode_Tennis - STOP");
  }, [addNewCourtMode_Tennis]);

  const [selectedCourt_Tennis, setSelectedCourt_Tennis] = useState<Tennis_Court>(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0]);
  useEffect(() => {
    console.log("useEffect - selectedCourt_Tennis.id - START");
    console.log("selectedCourt_Tennis: " + JSON.stringify(selectedCourt_Tennis,null, 2));
    if(addNewCourtMode_Tennis==true && selectedCourt_Tennis.id==-1) {
      console.log("paw na prosthesw sto myCourtsArray to selectedCourt_Tennis: " + JSON.stringify(selectedCourt_Tennis, null, 2));
      setMyCourtsArray_Tennis(myCourtsArray_Tennis => [...myCourtsArray_Tennis, selectedCourt_Tennis]);
      setMyCourtsDropdownList_Tennis([...myCourtsDropdownList_Tennis, { label: selectedCourt_Tennis.name, value: myCourtsDropdownList_Tennis.length.toString() }]); 
      setMyData_Tennis({...myData_Tennis, 
        mondayOpen: 'false', tuesdayOpen: 'false', wednesdayOpen: 'false', 
        thursdayOpen: 'false', fridayOpen: 'false', saturdayOpen: 'false', sundayOpen: 'false',
        showMondayStartHour: false, showMondayStopHour: false,
        showTuesdayStartHour: false, showTuesdayStopHour: false,
        showWednesdayStartHour: false, showWednesdayStopHour: false,
        showThursdayStartHour: false, showThursdayStopHour: false,
        showFridayStartHour: false, showFridayStopHour: false,
        showSaturdayStartHour: false, showSaturdayStopHour: false,
        showSundayStartHour: false, showSundayStopHour: false,
        mondayFreeIsConfirmed: false,
        tuesdayFreeIsConfirmed: false,
        wednesdayFreeIsConfirmed: false,
        thursdayFreeIsConfirmed: false,
        fridayFreeIsConfirmed: false,
        saturdayFreeIsConfirmed: false,
        sundayFreeIsConfirmed: false,
        confirmationDialogVisible: false, confirmationMessage: '',
        mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
        tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
        wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
        thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
        fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
        saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
        sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false
      });
    }
    else {
      console.log("   eimai sto useEffect - selectedCourt_Tennis.id kai kalw setSelectedCourtChangedAndTriggersMyDataUpdate_Tennis(true);");
      setSelectedCourtChangedAndTriggersMyDataUpdate_Tennis(true);
    }
    console.log("useEffect - selectedCourt_Tennis.id - STOP");
  }, [selectedCourt_Tennis?.id]);

  useEffect(() => {
    console.log("useEffect - selectedCourt_Tennis - START");
    console.log("selectedCourt_Tennis: " + JSON.stringify(selectedCourt_Tennis,null, 2));
    if(myData_Tennis.mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    else if(myData_Tennis.tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    else if(myData_Tennis.wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    else if(myData_Tennis.thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    else if(myData_Tennis.fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    else if(myData_Tennis.saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    else if(myData_Tennis.sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)
      setMyData_Tennis({...myData_Tennis, sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false});
    console.log("useEffect - selectedCourt_Tennis - STOP");
  }, [JSON.stringify(selectedCourt_Tennis)]);

  const [selectedCourtChangedAndTriggersMyDataUpdate_Tennis, setSelectedCourtChangedAndTriggersMyDataUpdate_Tennis] = useState(false);
  useEffect(() => {
    console.log("useEffect - selectedCourtChangedAndTriggersMyDataUpdate_Tennis - START -> selectedCourtChangedAndTriggersMyDataUpdate_Tennis: ", selectedCourtChangedAndTriggersMyDataUpdate_Tennis);
    if(selectedCourtChangedAndTriggersMyDataUpdate_Tennis == true) {
      var tmpMondayOpen = 'false';
      var tmpTuesdayOpen = 'false';
      var tmpWednesdayOpen = 'false';
      var tmpThursdayOpen = 'false';
      var tmpFridayOpen = 'false';
      var tmpSaturdayOpen = 'false';
      var tmpSundayOpen = 'false';

      if(selectedCourt_Tennis.monday_working_hours_start=='closed' && selectedCourt_Tennis.monday_working_hours_stop=='closed') tmpMondayOpen = 'false'; else tmpMondayOpen = 'true';
      if(selectedCourt_Tennis.tuesday_working_hours_start=='closed' && selectedCourt_Tennis.tuesday_working_hours_stop=='closed') tmpTuesdayOpen = 'false'; else tmpTuesdayOpen = 'true';
      if(selectedCourt_Tennis.wednesday_working_hours_start=='closed' && selectedCourt_Tennis.wednesday_working_hours_stop=='closed') tmpWednesdayOpen = 'false'; else tmpWednesdayOpen = 'true';
      if(selectedCourt_Tennis.thursday_working_hours_start=='closed' && selectedCourt_Tennis.thursday_working_hours_stop=='closed') tmpThursdayOpen = 'false'; else tmpThursdayOpen = 'true';
      if(selectedCourt_Tennis.friday_working_hours_start=='closed' && selectedCourt_Tennis.friday_working_hours_stop=='closed') tmpFridayOpen = 'false'; else tmpFridayOpen = 'true';
      if(selectedCourt_Tennis.saturday_working_hours_start=='closed' && selectedCourt_Tennis.saturday_working_hours_stop=='closed') tmpSaturdayOpen = 'false'; else tmpSaturdayOpen = 'true';
      if(selectedCourt_Tennis.sunday_working_hours_start=='closed' && selectedCourt_Tennis.sunday_working_hours_stop=='closed') tmpSundayOpen = 'false'; else tmpSundayOpen = 'true';

      if(myData_Tennis.mondayOpen!=tmpMondayOpen || myData_Tennis.tuesdayOpen!=tmpTuesdayOpen || myData_Tennis.wednesdayOpen!=tmpWednesdayOpen 
          || myData_Tennis.thursdayOpen!=tmpThursdayOpen || myData_Tennis.fridayOpen!=tmpFridayOpen || myData_Tennis.saturdayOpen!=tmpSaturdayOpen || myData_Tennis.sundayOpen!=tmpSundayOpen) 
      {
        setMyData_Tennis({...myData_Tennis, 
          mondayOpen: tmpMondayOpen,
          tuesdayOpen: tmpTuesdayOpen,
          wednesdayOpen: tmpWednesdayOpen,
          thursdayOpen: tmpThursdayOpen,
          fridayOpen: tmpFridayOpen,
          saturdayOpen: tmpSaturdayOpen,
          sundayOpen: tmpSundayOpen
        });
      }
      setSelectedCourtChangedAndTriggersMyDataUpdate_Tennis(false);
    }
    console.log("useEffect - selectedCourtChangedAndTriggersMyDataUpdate_Tennis - STOP");
  }, [selectedCourtChangedAndTriggersMyDataUpdate_Tennis]);

  const [myData_Tennis, setMyData_Tennis] = useState({
    mondayOpen: 'false', tuesdayOpen: 'false', wednesdayOpen: 'false', thursdayOpen: 'false', fridayOpen: 'false', saturdayOpen: 'false', sundayOpen: 'false',
    showMondayStartHour: false, showMondayStopHour: false,
    showTuesdayStartHour: false, showTuesdayStopHour: false,
    showWednesdayStartHour: false, showWednesdayStopHour: false,
    showThursdayStartHour: false, showThursdayStopHour: false,
    showFridayStartHour: false, showFridayStopHour: false,
    showSaturdayStartHour: false, showSaturdayStopHour: false,
    showSundayStartHour: false, showSundayStopHour: false,
    mondayFreeIsConfirmed: false,
    tuesdayFreeIsConfirmed: false,
    wednesdayFreeIsConfirmed: false,
    thursdayFreeIsConfirmed: false,
    fridayFreeIsConfirmed: false,
    saturdayFreeIsConfirmed: false,
    sundayFreeIsConfirmed: false,
    confirmationDialogVisible: false, confirmationMessage: '',    
    mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
    tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
    wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
    thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
    fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
    saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false,
    sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate: false
  });
  useEffect(() => {
    console.log("useEffect - myData_Tennis - START");
    console.log("myData_Tennis: " + JSON.stringify(myData_Tennis,null, 2));    
    
    if(myData_Tennis.mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpMondayWorkingHoursStartUpdated : string = selectedCourt_Tennis.monday_working_hours_start;
      var tmpMondayWorkingHoursStopUpdated : string = selectedCourt_Tennis.monday_working_hours_stop;
      var tmpMondayCost : number = selectedCourt_Tennis.monday_cost_per_hour;
      if(myData_Tennis.mondayOpen == 'true') {
        if(tmpMondayWorkingHoursStartUpdated == "closed") tmpMondayWorkingHoursStartUpdated = proposeMondayStartHour_Tennis();
        if(tmpMondayWorkingHoursStopUpdated == "closed") tmpMondayWorkingHoursStopUpdated = proposeMondayStopHour_Tennis();
        if(tmpMondayCost == -1) tmpMondayCost = proposeMondayCost_Tennis();
      }
      else if(myData_Tennis.mondayOpen == 'false') {
        if(tmpMondayWorkingHoursStartUpdated != "closed") tmpMondayWorkingHoursStartUpdated = "closed";
        if(tmpMondayWorkingHoursStopUpdated != "closed") tmpMondayWorkingHoursStopUpdated = "closed";
        if(tmpMondayCost != -1) tmpMondayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        monday_working_hours_start: tmpMondayWorkingHoursStartUpdated, 
        monday_working_hours_stop: tmpMondayWorkingHoursStopUpdated, 
        monday_cost_per_hour: tmpMondayCost
      });
    }
    else if(myData_Tennis.tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpTuesdayWorkingHoursStartUpdated : string = selectedCourt_Tennis.tuesday_working_hours_start;
      var tmpTuesdayWorkingHoursStopUpdated : string = selectedCourt_Tennis.tuesday_working_hours_stop;
      var tmpTuesdayCost : number = selectedCourt_Tennis.tuesday_cost_per_hour;
      if(myData_Tennis.tuesdayOpen == 'true') {
        if(tmpTuesdayWorkingHoursStartUpdated == "closed") tmpTuesdayWorkingHoursStartUpdated = proposeTuesdayStartHour_Tennis();
        if(tmpTuesdayWorkingHoursStopUpdated == "closed") tmpTuesdayWorkingHoursStopUpdated = proposeTuesdayStopHour_Tennis();
        if(tmpTuesdayCost == -1) tmpTuesdayCost = proposeTuesdayCost_Tennis();
      }
      else if(myData_Tennis.tuesdayOpen == 'false') {
        if(tmpTuesdayWorkingHoursStartUpdated != "closed") tmpTuesdayWorkingHoursStartUpdated = "closed";
        if(tmpTuesdayWorkingHoursStopUpdated != "closed") tmpTuesdayWorkingHoursStopUpdated = "closed";
        if(tmpTuesdayCost != -1) tmpTuesdayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        tuesday_working_hours_start: tmpTuesdayWorkingHoursStartUpdated, 
        tuesday_working_hours_stop: tmpTuesdayWorkingHoursStopUpdated, 
        tuesday_cost_per_hour: tmpTuesdayCost
      });
    }
    else if(myData_Tennis.wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpWednesdayWorkingHoursStartUpdated : string = selectedCourt_Tennis.wednesday_working_hours_start;
      var tmpWednesdayWorkingHoursStopUpdated : string = selectedCourt_Tennis.wednesday_working_hours_stop;
      var tmpWednesdayCost : number = selectedCourt_Tennis.wednesday_cost_per_hour;
      if(myData_Tennis.wednesdayOpen == 'true') {
        if(tmpWednesdayWorkingHoursStartUpdated == "closed") tmpWednesdayWorkingHoursStartUpdated = proposeWednesdayStartHour_Tennis();
        if(tmpWednesdayWorkingHoursStopUpdated == "closed") tmpWednesdayWorkingHoursStopUpdated = proposeWednesdayStopHour_Tennis();
        if(tmpWednesdayCost == -1) tmpWednesdayCost = proposeWednesdayCost_Tennis();
      }
      else if(myData_Tennis.wednesdayOpen == 'false') {
        if(tmpWednesdayWorkingHoursStartUpdated != "closed") tmpWednesdayWorkingHoursStartUpdated = "closed";
        if(tmpWednesdayWorkingHoursStopUpdated != "closed") tmpWednesdayWorkingHoursStopUpdated = "closed";
        if(tmpWednesdayCost != -1) tmpWednesdayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        wednesday_working_hours_start: tmpWednesdayWorkingHoursStartUpdated,
        wednesday_working_hours_stop: tmpWednesdayWorkingHoursStopUpdated,
        wednesday_cost_per_hour: tmpWednesdayCost
      });
    }
    else if(myData_Tennis.thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpThursdayWorkingHoursStartUpdated : string = selectedCourt_Tennis.thursday_working_hours_start;
      var tmpThursdayWorkingHoursStopUpdated : string = selectedCourt_Tennis.thursday_working_hours_stop;
      var tmpThursdayCost : number = selectedCourt_Tennis.thursday_cost_per_hour;
      if(myData_Tennis.thursdayOpen == 'true') {
        if(tmpThursdayWorkingHoursStartUpdated == "closed") tmpThursdayWorkingHoursStartUpdated = proposeThursdayStartHour_Tennis();
        if(tmpThursdayWorkingHoursStopUpdated == "closed") tmpThursdayWorkingHoursStopUpdated = proposeThursdayStopHour_Tennis();
        if(tmpThursdayCost == -1) tmpThursdayCost = proposeThursdayCost_Tennis();
      }
      else if(myData_Tennis.thursdayOpen == 'false') {
        if(tmpThursdayWorkingHoursStartUpdated != "closed") tmpThursdayWorkingHoursStartUpdated = "closed";
        if(tmpThursdayWorkingHoursStopUpdated != "closed") tmpThursdayWorkingHoursStopUpdated = "closed";
        if(tmpThursdayCost != -1) tmpThursdayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        thursday_working_hours_start: tmpThursdayWorkingHoursStartUpdated, 
        thursday_working_hours_stop: tmpThursdayWorkingHoursStopUpdated, 
        thursday_cost_per_hour: tmpThursdayCost
      });
    }
    else if(myData_Tennis.fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpFridayWorkingHoursStartUpdated : string = selectedCourt_Tennis.friday_working_hours_start;
      var tmpFridayWorkingHoursStopUpdated : string = selectedCourt_Tennis.friday_working_hours_stop;
      var tmpFridayCost : number = selectedCourt_Tennis.friday_cost_per_hour;
      if(myData_Tennis.fridayOpen == 'true') {
        if(tmpFridayWorkingHoursStartUpdated == "closed") tmpFridayWorkingHoursStartUpdated = proposeFridayStartHour_Tennis();
        if(tmpFridayWorkingHoursStopUpdated == "closed") tmpFridayWorkingHoursStopUpdated = proposeFridayStopHour_Tennis();
        if(tmpFridayCost == -1) tmpFridayCost = proposeFridayCost_Tennis();
      }
      else if(myData_Tennis.fridayOpen == 'false') {
        if(tmpFridayWorkingHoursStartUpdated != "closed") tmpFridayWorkingHoursStartUpdated = "closed";
        if(tmpFridayWorkingHoursStopUpdated != "closed") tmpFridayWorkingHoursStopUpdated = "closed";
        if(tmpFridayCost != -1) tmpFridayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        friday_working_hours_start: tmpFridayWorkingHoursStartUpdated,
        friday_working_hours_stop: tmpFridayWorkingHoursStopUpdated,  
        friday_cost_per_hour: tmpFridayCost
      });
    }
    else if(myData_Tennis.saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpSaturdayWorkingHoursStartUpdated : string = selectedCourt_Tennis.friday_working_hours_start;
      var tmpSaturdayWorkingHoursStopUpdated : string = selectedCourt_Tennis.friday_working_hours_stop;
      var tmpSaturdayCost : number = selectedCourt_Tennis.friday_cost_per_hour;
      if(myData_Tennis.saturdayOpen == 'true') {
        if(tmpSaturdayWorkingHoursStartUpdated == "closed") tmpSaturdayWorkingHoursStartUpdated = proposeSaturdayStartHour_Tennis();
        if(tmpSaturdayWorkingHoursStopUpdated == "closed") tmpSaturdayWorkingHoursStopUpdated = proposeSaturdayStopHour_Tennis();
        if(tmpSaturdayCost == -1) tmpSaturdayCost = proposeSaturdayCost_Tennis();
      }
      else if(myData_Tennis.saturdayOpen == 'false') {
        if(tmpSaturdayWorkingHoursStartUpdated != "closed") tmpSaturdayWorkingHoursStartUpdated = "closed";
        if(tmpSaturdayWorkingHoursStopUpdated != "closed") tmpSaturdayWorkingHoursStopUpdated = "closed";
        if(tmpSaturdayCost != -1) tmpSaturdayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        saturday_working_hours_start: tmpSaturdayWorkingHoursStartUpdated, 
        saturday_working_hours_stop: tmpSaturdayWorkingHoursStopUpdated, 
        saturday_cost_per_hour: tmpSaturdayCost
      });
    }
    else if(myData_Tennis.sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true) {
      console.log("   mphke sto if(myData_Tennis.sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate == true)");
      var tmpSundayWorkingHoursStartUpdated : string = selectedCourt_Tennis.sunday_working_hours_start;
      var tmpSundayWorkingHoursStopUpdated : string = selectedCourt_Tennis.sunday_working_hours_stop;
      var tmpSundayCost : number = selectedCourt_Tennis.sunday_cost_per_hour;
      if(myData_Tennis.sundayOpen == 'true') {
        if(tmpSundayWorkingHoursStartUpdated == "closed") tmpSundayWorkingHoursStartUpdated = proposeSundayStartHour_Tennis();
        if(tmpSundayWorkingHoursStopUpdated == "closed") tmpSundayWorkingHoursStopUpdated = proposeSundayStopHour_Tennis();
        if(tmpSundayCost == -1) tmpSundayCost = proposeSundayCost_Tennis();
      }
      else if(myData_Tennis.sundayOpen == 'false') {
        if(tmpSundayWorkingHoursStartUpdated != "closed") tmpSundayWorkingHoursStartUpdated = "closed";
        if(tmpSundayWorkingHoursStopUpdated != "closed") tmpSundayWorkingHoursStopUpdated = "closed";
        if(tmpSundayCost != -1) tmpSundayCost = -1;
      }
      setSelectedCourt_Tennis({...selectedCourt_Tennis, 
        sunday_working_hours_start: tmpSundayWorkingHoursStartUpdated,      
        sunday_working_hours_stop: tmpSundayWorkingHoursStopUpdated,
        sunday_cost_per_hour: tmpSundayCost
      });
    }
      
    if(myData_Tennis.confirmationDialogVisible == true) {
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(i18n.t('areYouSure'),myData_Tennis.confirmationMessage,
        [{ text: i18n.t('cancel'), onPress: () => 
                          setMyData_Tennis({...myData_Tennis, 
                            confirmationMessage: '',
                            confirmationDialogVisible: false
                          })
        },
        { text: 'OK', onPress: () => giveConfirmation()}
        ]);
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('areYouSure'));
        setAlertForWebText(myData_Tennis.confirmationMessage);
        setAlertForWebVisible(true);
      }
    }
    setIsLoading(false);
    console.log("useEffect - myData_Tennis - STOP");
  }, [JSON.stringify(myData_Tennis)]);

  async function addCourtAsync() {
    //check the currency of the first court
    //if it's different, show alert
    if(myCourtsArray_Tennis[0]!=undefined && myCourtsArray_Tennis[0].currency!=selectedCourt_Tennis.currency) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('differentCurrencies') + "(" + selectedCourt_Tennis.currency + ")",[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('differentCurrencies'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.mondayOpen=='true' && selectedCourt_Tennis.monday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.mondayOpen=='true' && selectedCourt_Tennis.monday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.tuesdayOpen=='true' && selectedCourt_Tennis.tuesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.tuesdayOpen=='true' && selectedCourt_Tennis.tuesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.wednesdayOpen=='true' && selectedCourt_Tennis.wednesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.wednesdayOpen=='true' && selectedCourt_Tennis.wednesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.thursdayOpen=='true' && selectedCourt_Tennis.thursday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.thursdayOpen=='true' && selectedCourt_Tennis.thursday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.fridayOpen=='true' && selectedCourt_Tennis.friday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.fridayOpen=='true' && selectedCourt_Tennis.friday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.saturdayOpen=='true' && selectedCourt_Tennis.saturday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.saturdayOpen=='true' && selectedCourt_Tennis.saturday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.sundayOpen=='true' && selectedCourt_Tennis.sunday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.sundayOpen=='true' && selectedCourt_Tennis.sunday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.mondayOpen=="true" && selectedCourt_Tennis.monday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForMonday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForMonday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.tuesdayOpen=="true" && selectedCourt_Tennis.tuesday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForTuesday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForTuesday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.wednesdayOpen=="true" && selectedCourt_Tennis.wednesday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForWednesday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForWednesday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.thursdayOpen=="true" && selectedCourt_Tennis.thursday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForThursday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForThursday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForFriday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForFriday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSaturday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSaturday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.sundayOpen=="true" && selectedCourt_Tennis.sunday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSunday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSunday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.mondayOpen=="false" && myData_Tennis.tuesdayOpen=="false" && myData_Tennis.wednesdayOpen=="false" && myData_Tennis.thursdayOpen=="false" && myData_Tennis.fridayOpen=="false" && myData_Tennis.saturdayOpen=="false" && myData_Tennis.sundayOpen=="false") {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('theCourtShouldBeAtLeastOneDayOpen'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('theCourtShouldBeAtLeastOneDayOpen'));
        setAlertForWebVisible(true);
      }
    }
      
    else if(myData_Tennis.mondayOpen=="true" && selectedCourt_Tennis.monday_cost_per_hour==0 &&  myData_Tennis.mondayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnMondayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Tennis.tuesdayOpen=="true" && selectedCourt_Tennis.tuesday_cost_per_hour==0 &&  myData_Tennis.tuesdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnTuesdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Tennis.wednesdayOpen=="true" && selectedCourt_Tennis.wednesday_cost_per_hour==0 &&  myData_Tennis.wednesdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnWednesdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Tennis.thursdayOpen=="true" && selectedCourt_Tennis.thursday_cost_per_hour==0 &&  myData_Tennis.thursdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnThursdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_cost_per_hour==0 &&  myData_Tennis.fridayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnFridayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_cost_per_hour==0 &&  myData_Tennis.saturdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnSaturdayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else if(myData_Tennis.sundayOpen=="true" && selectedCourt_Tennis.sunday_cost_per_hour==0 &&  myData_Tennis.sundayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnSundayIsFree'),
        confirmationDialogVisible: true
      });
    }

    else {
      setIsLoading(true);       

      var final_mondayFrom: string = 'closed';
      var final_mondayTo: string = 'closed';
      var final_mondayCost: number = -1;

      var final_tuesdayFrom: string = 'closed';
      var final_tuesdayTo: string = 'closed';
      var final_tuesdayCost: number = -1;

      var final_wednesdayFrom: string = 'closed';
      var final_wednesdayTo: string = 'closed';
      var final_wednesdayCost: number = -1;

      var final_thursdayFrom: string = 'closed';
      var final_thursdayTo: string = 'closed';
      var final_thursdayCost: number = -1;

      var final_fridayFrom: string = 'closed';
      var final_fridayTo: string = 'closed';
      var final_fridayCost: number = -1;

      var final_saturdayFrom: string = 'closed';
      var final_saturdayTo: string = 'closed';
      var final_saturdayCost: number = -1;

      var final_sundayFrom: string = 'closed';
      var final_sundayTo: string = 'closed';
      var final_sundayCost: number = -1;

      if(myData_Tennis.mondayOpen == "true") {
        final_mondayFrom = selectedCourt_Tennis.monday_working_hours_start;
        final_mondayTo = selectedCourt_Tennis.monday_working_hours_stop;
        final_mondayCost = selectedCourt_Tennis.monday_cost_per_hour;
      }
      if(myData_Tennis.tuesdayOpen == "true") {
        final_tuesdayFrom = selectedCourt_Tennis.tuesday_working_hours_start;
        final_tuesdayTo = selectedCourt_Tennis.tuesday_working_hours_stop;
        final_tuesdayCost = selectedCourt_Tennis.tuesday_cost_per_hour;
      }
      if(myData_Tennis.wednesdayOpen == "true") {
        final_wednesdayFrom = selectedCourt_Tennis.wednesday_working_hours_start;
        final_wednesdayTo = selectedCourt_Tennis.wednesday_working_hours_stop;
        final_wednesdayCost = selectedCourt_Tennis.wednesday_cost_per_hour;
      }
      if(myData_Tennis.thursdayOpen == "true") {
        final_thursdayFrom = selectedCourt_Tennis.thursday_working_hours_start;
        final_thursdayTo = selectedCourt_Tennis.thursday_working_hours_stop;
        final_thursdayCost = selectedCourt_Tennis.thursday_cost_per_hour;
      }
      if(myData_Tennis.fridayOpen == "true") {
        final_fridayFrom = selectedCourt_Tennis.friday_working_hours_start;
        final_fridayTo = selectedCourt_Tennis.friday_working_hours_stop;
        final_fridayCost = selectedCourt_Tennis.friday_cost_per_hour;
      }
      if(myData_Tennis.saturdayOpen == "true") {
        final_saturdayFrom = selectedCourt_Tennis.saturday_working_hours_start;
        final_saturdayTo = selectedCourt_Tennis.saturday_working_hours_stop;
        final_saturdayCost = selectedCourt_Tennis.saturday_cost_per_hour;
      }
      if(myData_Tennis.sundayOpen == "true") {
        final_sundayFrom = selectedCourt_Tennis.sunday_working_hours_start;
        final_sundayTo = selectedCourt_Tennis.sunday_working_hours_stop;
        final_sundayCost = selectedCourt_Tennis.sunday_cost_per_hour;
      }

      const tmpCourtToBeAdded : Tennis_Court = {
        id: selectedCourt_Tennis.id,
        owned_by: selectedCourt_Tennis.owned_by,
        name: selectedCourt_Tennis.name,
        type: selectedCourt_Tennis.type,
        currency: selectedCourt_Tennis.currency,
        monday_cost_per_hour: final_mondayCost,
        tuesday_cost_per_hour: final_tuesdayCost,
        wednesday_cost_per_hour: final_wednesdayCost,
        thursday_cost_per_hour: final_thursdayCost,
        friday_cost_per_hour: final_fridayCost,
        saturday_cost_per_hour: final_saturdayCost,
        sunday_cost_per_hour: final_sundayCost,
        monday_working_hours_start: final_mondayFrom,
        tuesday_working_hours_start: final_tuesdayFrom,
        wednesday_working_hours_start: final_wednesdayFrom,
        thursday_working_hours_start: final_thursdayFrom,
        friday_working_hours_start: final_fridayFrom,
        saturday_working_hours_start: final_saturdayFrom,
        sunday_working_hours_start: final_sundayFrom,
        monday_working_hours_stop: final_mondayTo,
        tuesday_working_hours_stop: final_tuesdayTo,
        wednesday_working_hours_stop: final_wednesdayTo,
        thursday_working_hours_stop: final_thursdayTo,
        friday_working_hours_stop: final_fridayTo,
        saturday_working_hours_stop: final_saturdayTo,
        sunday_working_hours_stop: final_sundayTo
      }
      
      const newlyAddedCourt : Tennis_Court = await Tennis_RestManager.getInstance().addCourt(tmpCourtToBeAdded);

      //update selectedCourt id (it was -1 before actually added)
      setSelectedCourt_Tennis({...selectedCourt_Tennis, id: newlyAddedCourt.id});
      //update courts stored array
      GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.push(newlyAddedCourt);
      console.log("meta to addCourt -> GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis: " + JSON.stringify(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis, null, 2));
      //setMyCourtsArray_Tennis(prevCourts => [...prevCourts.slice(0, prevCourts.length - 1), newlyAddedCourt]);
      //setmyCourtsDropdownList_Tennis(prevDropdown => [...prevDropdown.slice(0, prevDropdown.length - 1), { label: newlyAddedCourt.name, value: (prevDropdown.length).toString() }]);

      setIsLoading(false);
      if(MyAlertReactNative != null) {
        Alert.alert(
          i18n.t('congratulations'),
          i18n.t('courtAdded'),
          [
            { 
              text: "OK", onPress: () => { 
                setAddNewCourtMode_Tennis(false);
              } 
            }
          ]
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('congratulations'));
        setAlertForWebText(i18n.t('courtAdded'));
        setAlertForWebVisible(true);
      }
    }  
  }

  async function editCourtAsync() {
    if(myData_Tennis.mondayOpen=='true' && selectedCourt_Tennis.monday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.mondayOpen=='true' && selectedCourt_Tennis.monday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteMondayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteMondayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.tuesdayOpen=='true' && selectedCourt_Tennis.tuesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.tuesdayOpen=='true' && selectedCourt_Tennis.tuesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteTuesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteTuesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.wednesdayOpen=='true' && selectedCourt_Tennis.wednesday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.wednesdayOpen=='true' && selectedCourt_Tennis.wednesday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteWednesdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteWednesdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.thursdayOpen=='true' && selectedCourt_Tennis.thursday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.thursdayOpen=='true' && selectedCourt_Tennis.thursday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteThursdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteThursdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.fridayOpen=='true' && selectedCourt_Tennis.friday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.fridayOpen=='true' && selectedCourt_Tennis.friday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteFridayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteFridayStopHour'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Tennis.saturdayOpen=='true' && selectedCourt_Tennis.saturday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.saturdayOpen=='true' && selectedCourt_Tennis.saturday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSaturdayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSaturdayStopHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.sundayOpen=='true' && selectedCourt_Tennis.sunday_working_hours_start=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStartHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStartHour'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.sundayOpen=='true' && selectedCourt_Tennis.sunday_working_hours_stop=='closed') {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseCompleteSundayStopHour'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteSundayStopHour'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Tennis.mondayOpen=="true" && selectedCourt_Tennis.monday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForMonday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForMonday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.tuesdayOpen=="true" && selectedCourt_Tennis.tuesday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForTuesday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForTuesday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.wednesdayOpen=="true" && selectedCourt_Tennis.wednesday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForWednesday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForWednesday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.thursdayOpen=="true" && selectedCourt_Tennis.thursday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForThursday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForThursday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForFriday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForFriday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSaturday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSaturday'));
        setAlertForWebVisible(true);
      }
    }
    else if(myData_Tennis.sundayOpen=="true" && selectedCourt_Tennis.sunday_cost_per_hour<0) {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('pleaseEnterValidCostForSunday'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseEnterValidCostForSunday'));
        setAlertForWebVisible(true);
      }
    }

    else if(myData_Tennis.mondayOpen=="false" && myData_Tennis.tuesdayOpen=="false" && myData_Tennis.wednesdayOpen=="false" && myData_Tennis.thursdayOpen=="false" && myData_Tennis.fridayOpen=="false" && myData_Tennis.saturdayOpen=="false" && myData_Tennis.sundayOpen=="false") {
      if(MyAlertReactNative != null)
        MyAlertReactNative.alert(i18n.t('caution'),i18n.t('theCourtShouldBeAtLeastOneDayOpen'),[{ text: 'ΟΚ', onPress: () => null}]);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('theCourtShouldBeAtLeastOneDayOpen'));
        setAlertForWebVisible(true);
      }
    }
      
    else if(myData_Tennis.mondayOpen=="true" && selectedCourt_Tennis.monday_cost_per_hour==0 && myData_Tennis.mondayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnMondayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Tennis.tuesdayOpen=="true" && selectedCourt_Tennis.tuesday_cost_per_hour==0 && myData_Tennis.tuesdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnTuesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Tennis.wednesdayOpen=="true" && selectedCourt_Tennis.wednesday_cost_per_hour==0 && myData_Tennis.wednesdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnWednesdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Tennis.thursdayOpen=="true" && selectedCourt_Tennis.thursday_cost_per_hour==0 &&  myData_Tennis.thursdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnThursdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_cost_per_hour==0 &&  myData_Tennis.fridayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnFridayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_cost_per_hour==0 &&  myData_Tennis.saturdayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnSaturdayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else if(myData_Tennis.sundayOpen=="true" && selectedCourt_Tennis.sunday_cost_per_hour==0 &&  myData_Tennis.sundayFreeIsConfirmed==false) {
      setMyData_Tennis({...myData_Tennis, 
        confirmationMessage: i18n.t('areYouSureThatCostOnSundayIsFree'),
        confirmationDialogVisible: true
      });
    }
    else {
      setIsLoading(true);       

      var final_mondayFrom: string = 'closed';
      var final_mondayTo: string = 'closed';
      var final_mondayCost: number = -1;

      var final_tuesdayFrom: string = 'closed';
      var final_tuesdayTo: string = 'closed';
      var final_tuesdayCost: number = -1;

      var final_wednesdayFrom: string = 'closed';
      var final_wednesdayTo: string = 'closed';
      var final_wednesdayCost: number = -1;

      var final_thursdayFrom: string = 'closed';
      var final_thursdayTo: string = 'closed';
      var final_thursdayCost: number = -1;

      var final_fridayFrom: string = 'closed';
      var final_fridayTo: string = 'closed';
      var final_fridayCost: number = -1;

      var final_saturdayFrom: string = 'closed';
      var final_saturdayTo: string = 'closed';
      var final_saturdayCost: number = -1;

      var final_sundayFrom: string = 'closed';
      var final_sundayTo: string = 'closed';
      var final_sundayCost: number = -1;

      if(myData_Tennis.mondayOpen == "true") {
        final_mondayFrom = selectedCourt_Tennis.monday_working_hours_start;
        final_mondayTo = selectedCourt_Tennis.monday_working_hours_stop;
        final_mondayCost = selectedCourt_Tennis.monday_cost_per_hour;
      }
      if(myData_Tennis.tuesdayOpen == "true") {
        final_tuesdayFrom = selectedCourt_Tennis.tuesday_working_hours_start;
        final_tuesdayTo = selectedCourt_Tennis.tuesday_working_hours_stop;
        final_tuesdayCost = selectedCourt_Tennis.tuesday_cost_per_hour;
      }
      if(myData_Tennis.wednesdayOpen == "true") {
        final_wednesdayFrom = selectedCourt_Tennis.wednesday_working_hours_start;
        final_wednesdayTo = selectedCourt_Tennis.wednesday_working_hours_stop;
        final_wednesdayCost = selectedCourt_Tennis.wednesday_cost_per_hour;
      }
      if(myData_Tennis.thursdayOpen == "true") {
        final_thursdayFrom = selectedCourt_Tennis.thursday_working_hours_start;
        final_thursdayTo = selectedCourt_Tennis.thursday_working_hours_stop;
        final_thursdayCost = selectedCourt_Tennis.thursday_cost_per_hour;
      }
      if(myData_Tennis.fridayOpen == "true") {
        final_fridayFrom = selectedCourt_Tennis.friday_working_hours_start;
        final_fridayTo = selectedCourt_Tennis.friday_working_hours_stop;
        final_fridayCost = selectedCourt_Tennis.friday_cost_per_hour;
      }
      if(myData_Tennis.saturdayOpen == "true") {
        final_saturdayFrom = selectedCourt_Tennis.saturday_working_hours_start;
        final_saturdayTo = selectedCourt_Tennis.saturday_working_hours_stop;
        final_saturdayCost = selectedCourt_Tennis.saturday_cost_per_hour;
      }
      if(myData_Tennis.sundayOpen == "true") {
        final_sundayFrom = selectedCourt_Tennis.sunday_working_hours_start;
        final_sundayTo = selectedCourt_Tennis.sunday_working_hours_stop;
        final_sundayCost = selectedCourt_Tennis.sunday_cost_per_hour;
      }

      const tmpCourtToBeEdited : Tennis_Court = {
        id: selectedCourt_Tennis.id,
        owned_by: selectedCourt_Tennis.owned_by,
        name: selectedCourt_Tennis.name,
        type: selectedCourt_Tennis.type,
        currency: selectedCourt_Tennis.currency,
        monday_cost_per_hour: final_mondayCost,
        tuesday_cost_per_hour: final_tuesdayCost,
        wednesday_cost_per_hour: final_wednesdayCost,
        thursday_cost_per_hour: final_thursdayCost,
        friday_cost_per_hour: final_fridayCost,
        saturday_cost_per_hour: final_saturdayCost,
        sunday_cost_per_hour: final_sundayCost,
        monday_working_hours_start: final_mondayFrom,
        tuesday_working_hours_start: final_tuesdayFrom,
        wednesday_working_hours_start: final_wednesdayFrom,
        thursday_working_hours_start: final_thursdayFrom,
        friday_working_hours_start: final_fridayFrom,
        saturday_working_hours_start: final_saturdayFrom,
        sunday_working_hours_start: final_sundayFrom,
        monday_working_hours_stop: final_mondayTo,
        tuesday_working_hours_stop: final_tuesdayTo,
        wednesday_working_hours_stop: final_wednesdayTo,
        thursday_working_hours_stop: final_thursdayTo,
        friday_working_hours_stop: final_fridayTo,
        saturday_working_hours_stop: final_saturdayTo,
        sunday_working_hours_stop: final_sundayTo
      }
      const webServiceResponseStr : string | void = await Tennis_RestManager.getInstance().editCourt(tmpCourtToBeEdited);
      
      //update courts stored array
      const indexOfCourtToBeUpdated = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.findIndex(court => court.id === tmpCourtToBeEdited.id);
      GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[indexOfCourtToBeUpdated] = tmpCourtToBeEdited;
      //to myCourtsArray ginetai update aytomata logw "const [myCourtsArray, setMyCourtsArray] = useState<Array<Court>>(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis);"
      
      setIsLoading(false);
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(
          i18n.t('congratulations'),
          i18n.t('courtUpdated'),
          [
            { 
              text: "OK", onPress: () => {}            
            }
          ]
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('congratulations'));
        setAlertForWebText(i18n.t('courtUpdated'));
        setAlertForWebVisible(true);
      }
    }
  }

  function deleteCourt() {
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(
        i18n.t('deleteCourt'),
        i18n.t('deleteCourtAreYouSure'),
        [
          { text: i18n.t('cancel'), onPress: () => null },
          { text: "OK", onPress: () => { deleteCourtAsync(); }
          }
        ]
      );
    }
    else if(MyAlertForWeb != null) {
      setAlertForWebTitle(i18n.t('deleteCourt'));
      setAlertForWebText(i18n.t('deleteCourtAreYouSure'));
      setAlertForWebVisible(true);
    }
  }

  async function deleteCourtAsync() {
    setIsLoading(true);
    const webServiceResponseStr : string | void = await Tennis_RestManager.getInstance().deleteCourt(selectedCourt_Tennis.id);
    console.log("response deleteCourt: " + webServiceResponseStr);

    const index = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.findIndex(item => item.id === selectedCourt_Tennis.id);
    //update stored arrays  
    GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.splice(index, 1);
    console.log("meta to deleteCourt -> GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis: " + JSON.stringify(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis, null, 2));
    myCourtsArray_Tennis.splice(index, 1);
    console.log("TO META (myCourtsArray_Tennis): ", JSON.stringify(myCourtsArray_Tennis, null, 2));
    //  To "useEffect - myCourtsArray_Tennis" tha prokalesei: 
    //    setSelectedCourtDropdownIndex_Tennis(0);
    //  kai thewrhtika tha mpei sto "useEffect - selectedCourtDropdownIndex_Tennis" poy tha prokalesei:
    //    setSelectedCourt_Tennis(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[selectedCourtDropdownIndex_Tennis]);
    //  alla epeidh sthn periptwsh pou esvhsa to 1o Court (index = 0), den tha mpei sto "useEffect - selectedCourtDropdownIndex_Tennis"
    //  kalw edw to setSelectedCourt_Tennis
    setSelectedCourt_Tennis(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0]);
    console.log("afairw index: ", index, " apo myCourtsDropdownList_Tennis");
    const updatedDropDownListWithPossibleWrongValues = myCourtsDropdownList_Tennis.filter((_, i) => i !== index);
    // prosoxh: prepei na kanw tropopoihsh sta values toy DropDownList gia na mhn exw undefined
    // p.x. an svhsw to index 0, to aray tha einai:
    //    [{
    //       "label": "Court A",
    //       "value": "1"
    //     },
    //     {
    //       "label": "Court B",
    //       "value": "2"
    //    }]
    // kai prepei na ginei:
    //       "label": "Court A",
    //       "value": "0"
    //     },
    //     {
    //       "label": "Court B",
    //       "value": "1"
    //    }]
    const updatedDropDownListWithCorrectValues = updatedDropDownListWithPossibleWrongValues.map((item, index) => {
      return {
        label: item.label,
        value: index.toString()
      }
    });  

    console.log("aytoToDropdownListThaSetarw: ", JSON.stringify(updatedDropDownListWithCorrectValues, null, 2));
    setMyCourtsDropdownList_Tennis(updatedDropDownListWithCorrectValues);   

    setIsLoading(false);
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(
        '',
        i18n.t('courtHasBeenDeleted'),
        [
          { 
            text: "OK", onPress: () => {}        
          }
        ]
      );
    }
    else if(MyAlertForWeb != null) {
      setAlertForWebTitle(i18n.t(''));
      setAlertForWebText(i18n.t('courtHasBeenDeleted'));
      setAlertForWebVisible(true);
    }
  }

  const proposeMondayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    else if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.tuesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_start;
    else if(selectedCourt_Tennis.wednesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_start;
    else if(selectedCourt_Tennis.thursday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_start;
    else if(selectedCourt_Tennis.friday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_start;
    else if(selectedCourt_Tennis.saturday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_start;
    else if(selectedCourt_Tennis.sunday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, monday_working_hours_start: resultStr});
    console.log("proposeMondayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.tuesday_working_hours_start,
    selectedCourt_Tennis.wednesday_working_hours_start,
    selectedCourt_Tennis.thursday_working_hours_start,
    selectedCourt_Tennis.friday_working_hours_start,
    selectedCourt_Tennis.saturday_working_hours_start,
    selectedCourt_Tennis.sunday_working_hours_start]);

  const proposeTuesdayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    else if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_start;
    else if(selectedCourt_Tennis.wednesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_start;
    else if(selectedCourt_Tennis.thursday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_start;
    else if(selectedCourt_Tennis.friday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_start;
    else if(selectedCourt_Tennis.saturday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_start;
    else if(selectedCourt_Tennis.sunday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, tuesday_working_hours_start: resultStr});
    console.log("proposeTuesdayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_start, 
    selectedCourt_Tennis.wednesday_working_hours_start,
    selectedCourt_Tennis.thursday_working_hours_start,
    selectedCourt_Tennis.friday_working_hours_start,
    selectedCourt_Tennis.saturday_working_hours_start,
    selectedCourt_Tennis.sunday_working_hours_start]);

  const proposeWednesdayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    else if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_start;
    else if(selectedCourt_Tennis.tuesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_start;
    else if(selectedCourt_Tennis.thursday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_start;
    else if(selectedCourt_Tennis.friday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_start;
    else if(selectedCourt_Tennis.saturday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_start;
    else if(selectedCourt_Tennis.sunday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, wednesday_working_hours_start: resultStr});
    console.log("proposeWednesdayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_start, 
    selectedCourt_Tennis.tuesday_working_hours_start,
    selectedCourt_Tennis.thursday_working_hours_start,
    selectedCourt_Tennis.friday_working_hours_start,
    selectedCourt_Tennis.saturday_working_hours_start,
    selectedCourt_Tennis.sunday_working_hours_start]);

  const proposeThursdayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    else if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_start;
    else if(selectedCourt_Tennis.tuesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_start;
    else if(selectedCourt_Tennis.wednesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_start;
    else if(selectedCourt_Tennis.friday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_start;
    else if(selectedCourt_Tennis.saturday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_start;
    else if(selectedCourt_Tennis.sunday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, thursday_working_hours_start: resultStr});
    console.log("proposeThursdayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_start, 
    selectedCourt_Tennis.tuesday_working_hours_start,
    selectedCourt_Tennis.wednesday_working_hours_start,
    selectedCourt_Tennis.friday_working_hours_start,
    selectedCourt_Tennis.saturday_working_hours_start,
    selectedCourt_Tennis.sunday_working_hours_start]);

  const proposeFridayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    else if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_start;
    else if(selectedCourt_Tennis.tuesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_start;
    else if(selectedCourt_Tennis.wednesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_start;
    else if(selectedCourt_Tennis.thursday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_start;
    else if(selectedCourt_Tennis.saturday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_start;
    else if(selectedCourt_Tennis.sunday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, friday_working_hours_start: resultStr});
    console.log("proposeFridayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_start, 
      selectedCourt_Tennis.tuesday_working_hours_start,
      selectedCourt_Tennis.wednesday_working_hours_start,
      selectedCourt_Tennis.thursday_working_hours_start,
      selectedCourt_Tennis.saturday_working_hours_start,
      selectedCourt_Tennis.sunday_working_hours_start]);

  const proposeSaturdayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    else if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_start;
    else if(selectedCourt_Tennis.tuesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_start;
    else if(selectedCourt_Tennis.wednesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_start;
    else if(selectedCourt_Tennis.thursday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_start;
    else if(selectedCourt_Tennis.friday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_start;
    else if(selectedCourt_Tennis.sunday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, saturday_working_hours_start: resultStr});
    console.log("proposeSaturdayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_start, 
    selectedCourt_Tennis.tuesday_working_hours_start,
    selectedCourt_Tennis.wednesday_working_hours_start,
    selectedCourt_Tennis.thursday_working_hours_start,
    selectedCourt_Tennis.friday_working_hours_start,
    selectedCourt_Tennis.sunday_working_hours_start]);

  const proposeSundayStartHour_Tennis = useCallback(() : string => {
    var resultStr : string = "10:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.sunday_working_hours_start != "closed") resultStr = tmpFirstCourt.sunday_working_hours_start;
    else if(tmpFirstCourt.monday_working_hours_start != "closed") resultStr = tmpFirstCourt.monday_working_hours_start;
    else if(tmpFirstCourt.tuesday_working_hours_start != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_start;
    else if(tmpFirstCourt.wednesday_working_hours_start != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_start;
    else if(tmpFirstCourt.thursday_working_hours_start != "closed") resultStr = tmpFirstCourt.thursday_working_hours_start;
    else if(tmpFirstCourt.friday_working_hours_start != "closed") resultStr = tmpFirstCourt.friday_working_hours_start;
    else if(tmpFirstCourt.saturday_working_hours_start != "closed") resultStr = tmpFirstCourt.saturday_working_hours_start;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_start;
    else if(selectedCourt_Tennis.tuesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_start;
    else if(selectedCourt_Tennis.wednesday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_start;
    else if(selectedCourt_Tennis.thursday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_start;
    else if(selectedCourt_Tennis.friday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_start;
    else if(selectedCourt_Tennis.saturday_working_hours_start != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_start;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, sunday_working_hours_start: resultStr});
    console.log("proposeSundayStartHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_start, 
    selectedCourt_Tennis.tuesday_working_hours_start,
    selectedCourt_Tennis.wednesday_working_hours_start,
    selectedCourt_Tennis.thursday_working_hours_start,
    selectedCourt_Tennis.friday_working_hours_start,
    selectedCourt_Tennis.saturday_working_hours_start]);

  const proposeMondayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    else if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.tuesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_stop;
    else if(selectedCourt_Tennis.wednesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_stop;
    else if(selectedCourt_Tennis.thursday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_stop;
    else if(selectedCourt_Tennis.friday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_stop;
    else if(selectedCourt_Tennis.saturday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_stop;
    else if(selectedCourt_Tennis.sunday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, monday_working_hours_stop: resultStr});
    console.log("proposeMondayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.tuesday_working_hours_stop,
    selectedCourt_Tennis.wednesday_working_hours_stop,
    selectedCourt_Tennis.thursday_working_hours_stop,
    selectedCourt_Tennis.friday_working_hours_stop,
    selectedCourt_Tennis.saturday_working_hours_stop,
    selectedCourt_Tennis.sunday_working_hours_stop]);

  const proposeTuesdayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    else if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_stop;
    else if(selectedCourt_Tennis.wednesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_stop;
    else if(selectedCourt_Tennis.thursday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_stop;
    else if(selectedCourt_Tennis.friday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_stop;
    else if(selectedCourt_Tennis.saturday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_stop;
    else if(selectedCourt_Tennis.sunday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, tuesday_working_hours_stop: resultStr});
    console.log("proposeTuesdayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_stop, 
    selectedCourt_Tennis.wednesday_working_hours_stop,
    selectedCourt_Tennis.thursday_working_hours_stop,
    selectedCourt_Tennis.friday_working_hours_stop,
    selectedCourt_Tennis.saturday_working_hours_stop,
    selectedCourt_Tennis.sunday_working_hours_stop]);

  const proposeWednesdayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    else if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_stop;
    else if(selectedCourt_Tennis.tuesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_stop;
    else if(selectedCourt_Tennis.thursday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_stop;
    else if(selectedCourt_Tennis.friday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_stop;
    else if(selectedCourt_Tennis.saturday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_stop;
    else if(selectedCourt_Tennis.sunday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, wednesday_working_hours_stop: resultStr});
    console.log("proposeWednesdayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_stop, 
    selectedCourt_Tennis.tuesday_working_hours_stop,
    selectedCourt_Tennis.thursday_working_hours_stop,
    selectedCourt_Tennis.friday_working_hours_stop,
    selectedCourt_Tennis.saturday_working_hours_stop,
    selectedCourt_Tennis.sunday_working_hours_stop]);

  const proposeThursdayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    else if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_stop;
    else if(selectedCourt_Tennis.tuesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_stop;
    else if(selectedCourt_Tennis.wednesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_stop;
    else if(selectedCourt_Tennis.friday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_stop;
    else if(selectedCourt_Tennis.saturday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_stop;
    else if(selectedCourt_Tennis.sunday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, thursday_working_hours_stop: resultStr});
    console.log("proposeThursdayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_stop, 
    selectedCourt_Tennis.tuesday_working_hours_stop,
    selectedCourt_Tennis.wednesday_working_hours_stop,
    selectedCourt_Tennis.friday_working_hours_stop,
    selectedCourt_Tennis.saturday_working_hours_stop,
    selectedCourt_Tennis.sunday_working_hours_stop]);

  const proposeFridayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    else if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_stop;
    else if(selectedCourt_Tennis.tuesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_stop;
    else if(selectedCourt_Tennis.wednesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_stop;
    else if(selectedCourt_Tennis.thursday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_stop;
    else if(selectedCourt_Tennis.saturday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_stop;
    else if(selectedCourt_Tennis.sunday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, friday_working_hours_stop: resultStr});
    console.log("proposeFridayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_stop, 
    selectedCourt_Tennis.tuesday_working_hours_stop,
    selectedCourt_Tennis.wednesday_working_hours_stop,
    selectedCourt_Tennis.thursday_working_hours_stop,
    selectedCourt_Tennis.saturday_working_hours_stop,
    selectedCourt_Tennis.sunday_working_hours_stop]);

  const proposeSaturdayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    else if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_stop;
    else if(selectedCourt_Tennis.tuesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_stop;
    else if(selectedCourt_Tennis.wednesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_stop;
    else if(selectedCourt_Tennis.thursday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_stop;
    else if(selectedCourt_Tennis.friday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_stop;
    else if(selectedCourt_Tennis.sunday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.sunday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, saturday_working_hours_stop: resultStr});
    console.log("proposeSaturdayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_stop, 
    selectedCourt_Tennis.tuesday_working_hours_stop,
    selectedCourt_Tennis.wednesday_working_hours_stop,
    selectedCourt_Tennis.thursday_working_hours_stop,
    selectedCourt_Tennis.friday_working_hours_stop,
    selectedCourt_Tennis.sunday_working_hours_stop]);

  const proposeSundayStopHour_Tennis = useCallback(() : string => {
    var resultStr : string = "22:00";
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.sunday_working_hours_stop != "closed") resultStr = tmpFirstCourt.sunday_working_hours_stop;
    else if(tmpFirstCourt.monday_working_hours_stop != "closed") resultStr = tmpFirstCourt.monday_working_hours_stop;
    else if(tmpFirstCourt.tuesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.tuesday_working_hours_stop;
    else if(tmpFirstCourt.wednesday_working_hours_stop != "closed") resultStr = tmpFirstCourt.wednesday_working_hours_stop;
    else if(tmpFirstCourt.thursday_working_hours_stop != "closed") resultStr = tmpFirstCourt.thursday_working_hours_stop;
    else if(tmpFirstCourt.friday_working_hours_stop != "closed") resultStr = tmpFirstCourt.friday_working_hours_stop;
    else if(tmpFirstCourt.saturday_working_hours_stop != "closed") resultStr = tmpFirstCourt.saturday_working_hours_stop;
    //then override with the current court
    if(selectedCourt_Tennis.monday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.monday_working_hours_stop;
    else if(selectedCourt_Tennis.tuesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.tuesday_working_hours_stop;
    else if(selectedCourt_Tennis.wednesday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.wednesday_working_hours_stop;
    else if(selectedCourt_Tennis.thursday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.thursday_working_hours_stop;
    else if(selectedCourt_Tennis.friday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.friday_working_hours_stop;
    else if(selectedCourt_Tennis.saturday_working_hours_stop != "closed") resultStr = selectedCourt_Tennis.saturday_working_hours_stop;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, sunday_working_hours_stop: resultStr});
    console.log("proposeSundayStopHour_Tennis: ", resultStr);
    return resultStr;    
  }, [selectedCourt_Tennis.monday_working_hours_stop, 
    selectedCourt_Tennis.tuesday_working_hours_stop,
    selectedCourt_Tennis.wednesday_working_hours_stop,
    selectedCourt_Tennis.thursday_working_hours_stop,
    selectedCourt_Tennis.friday_working_hours_stop,
    selectedCourt_Tennis.saturday_working_hours_stop]);

  const proposeMondayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    else if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.tuesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.tuesday_cost_per_hour;
    else if(selectedCourt_Tennis.wednesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.wednesday_cost_per_hour;
    else if(selectedCourt_Tennis.thursday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.thursday_cost_per_hour;
    else if(selectedCourt_Tennis.friday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.friday_cost_per_hour;
    else if(selectedCourt_Tennis.saturday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.saturday_cost_per_hour;
    else if(selectedCourt_Tennis.sunday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.sunday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, monday_cost_per_hour: resultNum});
    console.log("proposeMondayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.tuesday_cost_per_hour,
    selectedCourt_Tennis.wednesday_cost_per_hour,
    selectedCourt_Tennis.thursday_cost_per_hour,
    selectedCourt_Tennis.friday_cost_per_hour,
    selectedCourt_Tennis.saturday_cost_per_hour,
    selectedCourt_Tennis.sunday_cost_per_hour]);

  const proposeTuesdayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    else if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.monday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.monday_cost_per_hour;
    else if(selectedCourt_Tennis.wednesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.wednesday_cost_per_hour;
    else if(selectedCourt_Tennis.thursday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.thursday_cost_per_hour;
    else if(selectedCourt_Tennis.friday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.friday_cost_per_hour;
    else if(selectedCourt_Tennis.saturday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.saturday_cost_per_hour;
    else if(selectedCourt_Tennis.sunday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.sunday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, tuesday_cost_per_hour: resultNum});
    console.log("proposeTuesdayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.monday_cost_per_hour, 
    selectedCourt_Tennis.wednesday_cost_per_hour,
    selectedCourt_Tennis.thursday_cost_per_hour,
    selectedCourt_Tennis.friday_cost_per_hour,
    selectedCourt_Tennis.saturday_cost_per_hour,
    selectedCourt_Tennis.sunday_cost_per_hour]);

  const proposeWednesdayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    else if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.monday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.monday_cost_per_hour;
    else if(selectedCourt_Tennis.tuesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.tuesday_cost_per_hour;
    else if(selectedCourt_Tennis.thursday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.thursday_cost_per_hour;
    else if(selectedCourt_Tennis.friday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.friday_cost_per_hour;
    else if(selectedCourt_Tennis.saturday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.saturday_cost_per_hour;
    else if(selectedCourt_Tennis.sunday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.sunday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, wednesday_cost_per_hour: resultNum});
    console.log("proposeWednesdayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.monday_cost_per_hour, 
    selectedCourt_Tennis.tuesday_cost_per_hour,
    selectedCourt_Tennis.thursday_cost_per_hour,
    selectedCourt_Tennis.friday_cost_per_hour,
    selectedCourt_Tennis.saturday_cost_per_hour,
    selectedCourt_Tennis.sunday_cost_per_hour]);

  const proposeThursdayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    else if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.monday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.monday_cost_per_hour;
    else if(selectedCourt_Tennis.tuesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.tuesday_cost_per_hour;
    else if(selectedCourt_Tennis.wednesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.wednesday_cost_per_hour;
    else if(selectedCourt_Tennis.friday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.friday_cost_per_hour;
    else if(selectedCourt_Tennis.saturday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.saturday_cost_per_hour;
    else if(selectedCourt_Tennis.sunday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.sunday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, thursday_cost_per_hour: resultNum});
    console.log("proposeThursdayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.monday_cost_per_hour, 
    selectedCourt_Tennis.tuesday_cost_per_hour,
    selectedCourt_Tennis.wednesday_cost_per_hour,
    selectedCourt_Tennis.friday_cost_per_hour,
    selectedCourt_Tennis.saturday_cost_per_hour,
    selectedCourt_Tennis.sunday_cost_per_hour]);

  const proposeFridayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    else if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.monday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.monday_cost_per_hour;
    else if(selectedCourt_Tennis.tuesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.tuesday_cost_per_hour;
    else if(selectedCourt_Tennis.wednesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.wednesday_cost_per_hour;
    else if(selectedCourt_Tennis.thursday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.thursday_cost_per_hour;
    else if(selectedCourt_Tennis.saturday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.saturday_cost_per_hour;
    else if(selectedCourt_Tennis.sunday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.sunday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, friday_cost_per_hour: resultNum});
    console.log("proposeFridayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.monday_cost_per_hour, 
    selectedCourt_Tennis.tuesday_cost_per_hour,
    selectedCourt_Tennis.wednesday_cost_per_hour,
    selectedCourt_Tennis.thursday_cost_per_hour,
    selectedCourt_Tennis.saturday_cost_per_hour,
    selectedCourt_Tennis.sunday_cost_per_hour]);

  const proposeSaturdayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    else if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.monday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.monday_cost_per_hour;
    else if(selectedCourt_Tennis.tuesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.tuesday_cost_per_hour;
    else if(selectedCourt_Tennis.wednesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.wednesday_cost_per_hour;
    else if(selectedCourt_Tennis.thursday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.thursday_cost_per_hour;
    else if(selectedCourt_Tennis.friday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.friday_cost_per_hour;
    else if(selectedCourt_Tennis.sunday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.sunday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, saturday_cost_per_hour: resultNum});
    console.log("proposeSaturdayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.monday_cost_per_hour, 
    selectedCourt_Tennis.tuesday_cost_per_hour,
    selectedCourt_Tennis.wednesday_cost_per_hour,
    selectedCourt_Tennis.thursday_cost_per_hour,
    selectedCourt_Tennis.friday_cost_per_hour,
    selectedCourt_Tennis.sunday_cost_per_hour]);

  const proposeSundayCost_Tennis = useCallback(() : number => {
    var resultNum : number = 15;
    //first consider the first court
    const tmpFirstCourt : Tennis_Court = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[0];
    if(tmpFirstCourt.sunday_cost_per_hour != -1) resultNum = tmpFirstCourt.sunday_cost_per_hour;
    else if(tmpFirstCourt.monday_cost_per_hour != -1) resultNum = tmpFirstCourt.monday_cost_per_hour;
    else if(tmpFirstCourt.tuesday_cost_per_hour != -1) resultNum = tmpFirstCourt.tuesday_cost_per_hour;
    else if(tmpFirstCourt.wednesday_cost_per_hour != -1) resultNum = tmpFirstCourt.wednesday_cost_per_hour;
    else if(tmpFirstCourt.thursday_cost_per_hour != -1) resultNum = tmpFirstCourt.thursday_cost_per_hour;
    else if(tmpFirstCourt.friday_cost_per_hour != -1) resultNum = tmpFirstCourt.friday_cost_per_hour;
    else if(tmpFirstCourt.saturday_cost_per_hour != -1) resultNum = tmpFirstCourt.saturday_cost_per_hour;
    //then override with the current court
    if(selectedCourt_Tennis.monday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.monday_cost_per_hour;
    else if(selectedCourt_Tennis.tuesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.tuesday_cost_per_hour;
    else if(selectedCourt_Tennis.wednesday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.wednesday_cost_per_hour;
    else if(selectedCourt_Tennis.thursday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.thursday_cost_per_hour;
    else if(selectedCourt_Tennis.friday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.friday_cost_per_hour;
    else if(selectedCourt_Tennis.saturday_cost_per_hour != -1) resultNum = selectedCourt_Tennis.saturday_cost_per_hour;
    //finally...set it!
    setSelectedCourt_Tennis({...selectedCourt_Tennis, sunday_cost_per_hour: resultNum});
    console.log("proposeSundayCost_Tennis: ", resultNum);
    return resultNum;  
  }, [selectedCourt_Tennis.monday_cost_per_hour, 
    selectedCourt_Tennis.tuesday_cost_per_hour,
    selectedCourt_Tennis.wednesday_cost_per_hour,
    selectedCourt_Tennis.thursday_cost_per_hour,
    selectedCourt_Tennis.friday_cost_per_hour,
    selectedCourt_Tennis.saturday_cost_per_hour]);

  const [tennisCourtTypeDropdownList, setTennisCourtTypeDropdownList] = useState(UtilsManager.getInstance().tennisCourtTypeList_EN);
  const [showTennisCourtTypeDropdownList, setShowTennisCourtTypeDropdownList] = useState(false);
///////////////////////////
// Tennis domain - END //
///////////////////////////

//////////////////////////////////
// Generic, All domains - START //
//////////////////////////////////
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerManageRoomsView: I'm ready!");

    if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
      if(LoginManager.getInstance().language == LoginManager.getInstance().EN)
        setTennisCourtTypeDropdownList(UtilsManager.getInstance().tennisCourtTypeList_EN);
      else if(LoginManager.getInstance().language == LoginManager.getInstance().GR)
        setTennisCourtTypeDropdownList(UtilsManager.getInstance().tennisCourtTypeList_GR);
    }
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
      (async () => {
        const tmpOwnerExtraInfo_Studio : Studio_OwnerExtraInfo = await Studio_RestManager.getInstance().getOwnerExtraInfo(LoginManager.getInstance().ID);
        if(tmpOwnerExtraInfo_Studio!=null && tmpOwnerExtraInfo_Studio!=undefined)
          setOwnerExtraInfo_Studio(tmpOwnerExtraInfo_Studio);
      })();
    }
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);

  const [mondayStartHour, setMondayStartHour] = useState("");
  useEffect(() => {
    if(mondayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, monday_working_hours_start: mondayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, monday_working_hours_start: mondayStartHour});
    }
  }, [mondayStartHour]);
  const [mondayStopHour, setMondayStopHour] = useState("");
  useEffect(() => {
    if(mondayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, monday_working_hours_stop: mondayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, monday_working_hours_stop: mondayStopHour});
    }
  }, [mondayStopHour]);

  const [tuesdayStartHour, setTuesdayStartHour] = useState("");
  useEffect(() => {
    if(tuesdayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, tuesday_working_hours_start: tuesdayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, tuesday_working_hours_start: tuesdayStartHour});
    }
  }, [tuesdayStartHour]);
  const [tuesdayStopHour, setTuesdayStopHour] = useState("");
  useEffect(() => {
    if(tuesdayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, tuesday_working_hours_stop: tuesdayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, tuesday_working_hours_stop: tuesdayStopHour});
    }
  }, [tuesdayStopHour]);

  const [wednesdayStartHour, setWednesdayStartHour] = useState("");
  useEffect(() => {
    if(wednesdayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, wednesday_working_hours_start: wednesdayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, wednesday_working_hours_start: wednesdayStartHour});
    }
  }, [wednesdayStartHour]);
  const [wednesdayStopHour, setWednesdayStopHour] = useState("");
  useEffect(() => {
    if(wednesdayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, wednesday_working_hours_stop: wednesdayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, wednesday_working_hours_stop: wednesdayStopHour});
    }
  }, [wednesdayStopHour]);

  const [thursdayStartHour, setThursdayStartHour] = useState("");
  useEffect(() => {
    if(thursdayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, thursday_working_hours_start: thursdayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, thursday_working_hours_start: thursdayStartHour});
    }
  }, [thursdayStartHour]);
  const [thursdayStopHour, setThursdayStopHour] = useState("");
  useEffect(() => {
    if(thursdayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, thursday_working_hours_stop: thursdayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, thursday_working_hours_stop: thursdayStopHour});
    }
  }, [thursdayStopHour]);

  const [fridayStartHour, setFridayStartHour] = useState("");
  useEffect(() => {
    if(fridayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, friday_working_hours_start: fridayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, friday_working_hours_start: fridayStartHour});
    }
  }, [fridayStartHour]);
  const [fridayStopHour, setFridayStopHour] = useState("");
  useEffect(() => {
    if(fridayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, friday_working_hours_stop: fridayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, friday_working_hours_stop: fridayStopHour});
    }
  }, [fridayStopHour]);

  const [saturdayStartHour, setSaturdayStartHour] = useState("");
  useEffect(() => {
    if(saturdayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, saturday_working_hours_start: saturdayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, saturday_working_hours_start: saturdayStartHour});
    }
  }, [saturdayStartHour]);
  const [saturdayStopHour, setSaturdayStopHour] = useState("");
  useEffect(() => {
    if(saturdayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, saturday_working_hours_stop: saturdayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, saturday_working_hours_stop: saturdayStopHour});
    }
  }, [saturdayStopHour]);

  const [sundayStartHour, setSundayStartHour] = useState("");
  useEffect(() => {
    if(sundayStartHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, sunday_working_hours_start: sundayStartHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, sunday_working_hours_start: sundayStartHour});
    }
  }, [sundayStartHour]);
  const [sundayStopHour, setSundayStopHour] = useState("");
  useEffect(() => {
    if(sundayStopHour != "") {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        setSelectedRoom_Studio({...selectedRoom_Studio, sunday_working_hours_stop: sundayStopHour});
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        setSelectedCourt_Tennis({...selectedCourt_Tennis, sunday_working_hours_stop: sundayStopHour});
    }
  }, [sundayStopHour]);

  function giveConfirmation() {
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
      if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnMondayIsFree')) { setMyData_Studio({...myData_Studio, mondayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnTuesdayIsFree')) { setMyData_Studio({...myData_Studio, tuesdayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnWednesdayIsFree')) { setMyData_Studio({...myData_Studio, wednesdayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnThursdayIsFree')) { setMyData_Studio({...myData_Studio, thursdayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnFridayIsFree')) { setMyData_Studio({...myData_Studio, fridayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnSaturdayIsFree')) { setMyData_Studio({...myData_Studio, saturdayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRehearsalOnSundayIsFree')) { setMyData_Studio({...myData_Studio, sundayFreeRehIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }

      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnMondayIsFree')) { setMyData_Studio({...myData_Studio, mondayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnTuesdayIsFree')) { setMyData_Studio({...myData_Studio, tuesdayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnWednesdayIsFree')) { setMyData_Studio({...myData_Studio, wednesdayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnThursdayIsFree')) { setMyData_Studio({...myData_Studio, thursdayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnFridayIsFree')) { setMyData_Studio({...myData_Studio, fridayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnSaturdayIsFree')) { setMyData_Studio({...myData_Studio, saturdayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Studio.confirmationMessage == i18n.t('areYouSureThatRecordingOnSundayIsFree')) { setMyData_Studio({...myData_Studio, sundayFreeRecIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
    }
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
      if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnMondayIsFree')) { setMyData_Tennis({...myData_Tennis, mondayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnTuesdayIsFree')) { setMyData_Tennis({...myData_Tennis, tuesdayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnWednesdayIsFree')) { setMyData_Tennis({...myData_Tennis, wednesdayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnThursdayIsFree')) { setMyData_Tennis({...myData_Tennis, thursdayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnFridayIsFree')) { setMyData_Tennis({...myData_Tennis, fridayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnSaturdayIsFree')) { setMyData_Tennis({...myData_Tennis, saturdayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
      else if(myData_Tennis.confirmationMessage == i18n.t('areYouSureThatCostOnSundayIsFree')) { setMyData_Tennis({...myData_Tennis, sundayFreeIsConfirmed: true, confirmationDialogVisible: false, confirmationMessage: ''}); }
    }
  }
////////////////////////////////
// Generic, All domains - END //
////////////////////////////////  

  // Create a ref for the TextInput components
  const roomNameInputRef = useRef(null);
  const courtNameInputRef = useRef(null);

  const mondayRehCostInputRef = useRef(null);
  const mondayRecCostInputRef = useRef(null);
  const tuesdayRehCostInputRef = useRef(null);
  const tuesdayRecCostInputRef = useRef(null);
  const wednesdayRehCostInputRef = useRef(null);
  const wednesdayRecCostInputRef = useRef(null);
  const thursdayRehCostInputRef = useRef(null);
  const thursdayRecCostInputRef = useRef(null);
  const fridayRehCostInputRef = useRef(null);
  const fridayRecCostInputRef = useRef(null);
  const saturdayRehCostInputRef = useRef(null);
  const saturdayRecCostInputRef = useRef(null);
  const sundayRehCostInputRef = useRef(null);
  const sundayRecCostInputRef = useRef(null);

  const dimensionsInputRef = useRef(null);
  const speakersInputRef = useRef(null);
  const consoleInputRef = useRef(null);
  const microphonesInputRef = useRef(null);
  const drumsInputRef = useRef(null);
  const guitarsInputRef = useRef(null);
  const bassesInputRef = useRef(null);
  const pianoKeyboardsInputRef = useRef(null);
  const guitarAmplifiersInputRef = useRef(null);
  const bassAmplifiersInputRef = useRef(null);
  const otherInputRef = useRef(null);

  const mondayCostInputRef = useRef(null);
  const tuesdayCostInputRef = useRef(null);
  const wednesdayCostInputRef = useRef(null);
  const thursdayCostInputRef = useRef(null);
  const fridayCostInputRef = useRef(null);
  const saturdayCostInputRef = useRef(null);
  const sundayCostInputRef = useRef(null);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile==true ? styles.container : styles.columnMainApp}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View style={ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile==true ? styles.columnContainer_threeColumnsAndBottomNavigation : styles.inner}>
          {ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile==true && <LeftColumnWeb/>}
          <View style={ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile==true ? styles.columnMainApp : styles.inner}>
            <ScrollView>
              <Spinner
                visible={isLoading}
                textContent={i18n.t('loading')}
                textStyle={styles.spinnerTextStyle}
              />
              <View style={{flex: 1, display: (isLoading==false && addNewRoomMode_Studio==false) ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center'}}>
                <View style={{ flex: 5 }}>
                  <OwnerRoomsDropdown 
                      myRoomsDropdownList_Studio = {myRoomsDropdownList_Studio}
                      showRoomsDropdownList_Studio = {showRoomsDropdownList_Studio}
                      setShowRoomsDropdownList_Studio = {setShowRoomsDropdownList_Studio}
                      selectedRoomDropdownIndex_Studio = {selectedRoomDropdownIndex_Studio}
                      setSelectedRoomDropdownIndex_Studio = {setSelectedRoomDropdownIndex_Studio}

                      myCourtsDropdownList_Tennis = {myCourtsDropdownList_Tennis}
                      showCourtsDropdownList_Tennis = {showCourtsDropdownList_Tennis}
                      setShowCourtsDropdownList_Tennis = {setShowCourtsDropdownList_Tennis}
                      selectedCourtDropdownIndex_Tennis = {selectedCourtDropdownIndex_Tennis}
                      setSelectedCourtDropdownIndex_Tennis = {setSelectedCourtDropdownIndex_Tennis}
                  />
                </View>
                <View style={{flex: 1, alignItems: 'flex-end', paddingTop: 5 }}>
                  <IconButton icon={({ color, size }) => (<MaterialCommunityIcons name="plus-circle-outline" color={color} size={size} />)} size={30} color={Colors.green500} onPress={() => { 
                    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
                      setAddNewRoomMode_Studio(true); 
                    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
                      setAddNewCourtMode_Tennis(true); 
                  }} />
                </View>
              </View>
              <Divider style={{ height: 2 }} />                    

              <DataTable style={{ marginTop: 5, borderWidth: 2, borderColor: "#6200EE" }}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", alignSelf:"center", textAlign: "center" }}>{LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('editRoomInfo') 
                                                                                                                  : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('editCourtInfo')
                                                                                                                  : "ERROR!"}</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={styles.tableRow}>
                  {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && 
                    <TouchableOpacity onPress={() => roomNameInputRef.current.focus()}>
                      <TextInput
                        ref={roomNameInputRef}
                        label={i18n.t('roomName')}
                        value={selectedRoom_Studio.name}
                        style={[styles.fullWidthInputText, {display: isLoading==false ? 'flex' : 'none'}]}
                        onChangeText={tmpRoomName => { 
                            setMyRoomsDropdownList_Studio(prevRoomsDropdownList => 
                              prevRoomsDropdownList.map((item, index) => 
                                index !== selectedRoomDropdownIndex_Studio ? item : {...item, label: tmpRoomName}));
                            setSelectedRoom_Studio({...selectedRoom_Studio, name: tmpRoomName});
                          } 
                        }
                      />
                    </TouchableOpacity>
                  }
                  {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && 
                    <TouchableOpacity onPress={() => courtNameInputRef.current.focus()}>
                      <TextInput
                        ref={courtNameInputRef}
                        label={i18n.t('courtName')}
                        value={selectedCourt_Tennis.name}
                        style={[styles.fullWidthInputText, {display: isLoading==false ? 'flex' : 'none'}]}
                        onChangeText={tmpCourtName => { 
                            setMyCourtsDropdownList_Tennis(prevCourtsDropdownList => 
                              prevCourtsDropdownList.map((item, index) => 
                                index !== selectedCourtDropdownIndex_Tennis ? item : {...item, label: tmpCourtName}));
                            setSelectedCourt_Tennis({...selectedCourt_Tennis, name: tmpCourtName});
                          } 
                        }
                      />
                    </TouchableOpacity>
                  }
                </DataTable.Row>     
              </DataTable>
              
              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('monday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.mondayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Studio({...myData_Studio, mondayOpen: myData_Studio.mondayOpen=='true' ? 'false' : 'true', mondayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('monday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownMondayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeMondayStartHour_Studio={proposeMondayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeMondayStartHour_Tennis={proposeMondayStartHour_Tennis}
                                  
                                  setMondayStartHour={setMondayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownMondayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeMondayStopHour_Studio={proposeMondayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeMondayStopHour_Tennis={proposeMondayStopHour_Tennis}
                                  
                                  setMondayStopHour={setMondayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('monday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => mondayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={mondayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_reh==-1 ? proposeMondayRehearsalCost_Studio.toString() : selectedRoom_Studio.monday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpMondayRehearsalCost => setSelectedRoom_Studio({
                          ...selectedRoom_Studio,
                          monday_cost_per_hour_reh: parseFloat(tmpMondayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => mondayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={mondayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_cost_per_hour_rec==-1 ? proposeMondayRecordingCost_Studio.toString() : selectedRoom_Studio.monday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpMondayRecordingCost => setSelectedRoom_Studio({
                          ...selectedRoom_Studio,
                          monday_cost_per_hour_rec: parseFloat(tmpMondayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('tuesday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.tuesdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setMyData_Studio({...myData_Studio, tuesdayOpen: myData_Studio.tuesdayOpen=='true' ? 'false' : 'true', tuesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green" uncheckedColor="red" labelStyle={{ color: "white" }} style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('tuesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownTuesdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeTuesdayStartHour_Studio={proposeTuesdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeTuesdayStartHour_Tennis={proposeTuesdayStartHour_Tennis}
                                  
                                  setTuesdayStartHour={setTuesdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownTuesdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeTuesdayStopHour_Studio={proposeTuesdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeTuesdayStopHour_Tennis={proposeTuesdayStopHour_Tennis}
                                  
                                  setTuesdayStopHour={setTuesdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('tuesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => tuesdayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={tuesdayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_reh==-1 ? proposeTuesdayRehearsalCost_Studio.toString() : selectedRoom_Studio.tuesday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpTuesdayRehearsalCost => setSelectedRoom_Studio({
                          ...selectedRoom_Studio,
                          tuesday_cost_per_hour_reh: parseFloat(tmpTuesdayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => tuesdayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={tuesdayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.tuesdayOpen=="true" && selectedRoom_Studio.tuesday_cost_per_hour_rec==-1 ? proposeTuesdayRecordingCost_Studio.toString() : selectedRoom_Studio.tuesday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpTuesdayRecordingCost => setSelectedRoom_Studio({
                          ...selectedRoom_Studio,
                          tuesday_cost_per_hour_rec: parseFloat(tmpTuesdayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('wednesday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.wednesdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setMyData_Studio({...myData_Studio, wednesdayOpen: myData_Studio.wednesdayOpen=='true' ? 'false' : 'true', wednesdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green" uncheckedColor="red" labelStyle={{ color: "white" }} style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('wednesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownWednesdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeWednesdayStartHour_Studio={proposeWednesdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeWednesdayStartHour_Tennis={proposeWednesdayStartHour_Tennis}
                                  
                                  setWednesdayStartHour={setWednesdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownWednesdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeWednesdayStopHour_Studio={proposeWednesdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeWednesdayStopHour_Tennis={proposeWednesdayStopHour_Tennis}
                                  
                                  setWednesdayStopHour={setWednesdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('wednesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => wednesdayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={wednesdayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_reh==-1 ? proposeWednesdayRehearsalCost_Studio.toString() : selectedRoom_Studio.wednesday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpWednesdayRehearsalCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          wednesday_cost_per_hour_reh: parseFloat(tmpWednesdayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => wednesdayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={wednesdayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_cost_per_hour_rec==-1 ? proposeWednesdayRecordingCost_Studio.toString() : selectedRoom_Studio.wednesday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpWednesdayRecordingCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          wednesday_cost_per_hour_rec: parseFloat(tmpWednesdayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('thursday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.thursdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setMyData_Studio({...myData_Studio, thursdayOpen: myData_Studio.thursdayOpen=='true' ? 'false' : 'true', thursdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green" uncheckedColor="red" labelStyle={{ color: "white" }} style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('thursday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownThursdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeThursdayStartHour_Studio={proposeThursdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeThursdayStartHour_Tennis={proposeThursdayStartHour_Tennis}
                                  
                                  setThursdayStartHour={setThursdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownThursdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeThursdayStopHour_Studio={proposeThursdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeThursdayStopHour_Tennis={proposeThursdayStopHour_Tennis}
                                  
                                  setThursdayStopHour={setThursdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('thursday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => thursdayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={thursdayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_reh==-1 ? proposeThursdayRehearsalCost_Studio.toString() : selectedRoom_Studio.thursday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpThursdayRehearsalCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          thursday_cost_per_hour_reh: parseFloat(tmpThursdayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => thursdayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={thursdayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.thursdayOpen=="true" && selectedRoom_Studio.thursday_cost_per_hour_rec==-1 ? proposeThursdayRecordingCost_Studio.toString() : selectedRoom_Studio.thursday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpThursdayRecordingCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          thursday_cost_per_hour_rec: parseFloat(tmpThursdayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('friday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.fridayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setMyData_Studio({...myData_Studio, fridayOpen: myData_Studio.fridayOpen=='true' ? 'false' : 'true', fridayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green" uncheckedColor="red" labelStyle={{ color: "white" }} style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('friday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownFridayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeFridayStartHour_Studio={proposeFridayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeFridayStartHour_Tennis={proposeFridayStartHour_Tennis}
                                  
                                  setFridayStartHour={setFridayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownFridayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeFridayStopHour_Studio={proposeFridayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeFridayStopHour_Tennis={proposeFridayStopHour_Tennis}
                                  
                                  setFridayStopHour={setFridayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('friday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => fridayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={fridayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_reh==-1 ? proposeFridayRehearsalCost_Studio.toString() : selectedRoom_Studio.friday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpFridayRehearsalCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          friday_cost_per_hour_reh: parseFloat(tmpFridayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => fridayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={fridayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_cost_per_hour_rec==-1 ? proposeFridayRecordingCost_Studio.toString() : selectedRoom_Studio.friday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpFridayRecordingCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          friday_cost_per_hour_rec: parseFloat(tmpFridayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('saturday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.saturdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setMyData_Studio({...myData_Studio, saturdayOpen: myData_Studio.saturdayOpen=='true' ? 'false' : 'true', saturdayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green" uncheckedColor="red" labelStyle={{ color: "white" }} style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('saturday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownSaturdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSaturdayStartHour_Studio={proposeSaturdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSaturdayStartHour_Tennis={proposeSaturdayStartHour_Tennis}
                                  
                                  setSaturdayStartHour={setSaturdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownSaturdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSaturdayStopHour_Studio={proposeSaturdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSaturdayStopHour_Tennis={proposeSaturdayStopHour_Tennis}
                                  
                                  setSaturdayStopHour={setSaturdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('saturday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => saturdayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={saturdayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_reh==-1 ? proposeSaturdayRehearsalCost_Studio.toString() : selectedRoom_Studio.saturday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpSaturdayRehearsalCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          saturday_cost_per_hour_reh: parseFloat(tmpSaturdayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => saturdayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={saturdayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_cost_per_hour_rec==-1 ? proposeSaturdayRecordingCost_Studio.toString() : selectedRoom_Studio.saturday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpSaturdayRecordingCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          saturday_cost_per_hour_rec: parseFloat(tmpSaturdayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={[styles.roomTable, { display: isLoading==false ? "flex" : "none"}]}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('sunday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Studio.sundayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setMyData_Studio({...myData_Studio, sundayOpen: myData_Studio.sundayOpen=='true' ? 'false' : 'true', sundayCheckboxJustChangedAndTriggersSelectedRoomUpdate: true});
                    }}
                    color="green" uncheckedColor="red" labelStyle={{ color: "white" }} style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('sunday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownSundayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSundayStartHour_Studio={proposeSundayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSundayStartHour_Tennis={proposeSundayStartHour_Tennis}
                                  
                                  setSundayStartHour={setSundayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownSundayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSundayStopHour_Studio={proposeSundayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSundayStopHour_Tennis={proposeSundayStopHour_Tennis}
                                  
                                  setSundayStopHour={setSundayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Studio.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('sunday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Studio.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  {ownerExtraInfo_Studio.allows_rehearsal==true && <View style={styles.roomTableRehearsalCost}>
                    <TouchableOpacity onPress={() => sundayRehCostInputRef.current.focus()}>
                      <TextInput
                        ref={sundayRehCostInputRef}
                        label={i18n.t('rehearsal')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_reh==-1 ? proposeSundayRehearsalCost_Studio.toString() : selectedRoom_Studio.sunday_cost_per_hour_reh.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpSundayRehearsalCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          sunday_cost_per_hour_reh: parseFloat(tmpSundayRehearsalCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                  {ownerExtraInfo_Studio.allows_recording==true && <View style={styles.roomTableRecordingCost}>
                    <TouchableOpacity onPress={() => sundayRecCostInputRef.current.focus()}>
                      <TextInput
                        ref={sundayRecCostInputRef}
                        label={i18n.t('recording')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={isLoading==false && myData_Studio.sundayOpen=="true" && selectedRoom_Studio.sunday_cost_per_hour_rec==-1 ? proposeSundayRecordingCost_Studio.toString() : selectedRoom_Studio.sunday_cost_per_hour_rec.toString()}
                        right={<TextInput.Affix text={selectedRoom_Studio.currency}/>}
                        onChangeText={tmpSundayRecordingCost => setSelectedRoom_Studio({...selectedRoom_Studio,
                          sunday_cost_per_hour_rec: parseFloat(tmpSundayRecordingCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>}
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable style={{ borderWidth: 2, borderColor: "#6200EE", display: isLoading==false ? 'flex' : 'none'}}>
                <DataTable.Header style={{ backgroundColor: "#6200EE"}}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", alignSelf:"center", textAlign: "center" }}>{i18n.t('equipment')}</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => dimensionsInputRef.current.focus()}>
                    <TextInput
                      ref={dimensionsInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('space')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.space : ''}
                      onChangeText={tmpSpace => setSelectedRoom_Studio({...selectedRoom_Studio, space: tmpSpace})}                 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => speakersInputRef.current.focus()}>
                    <TextInput
                      ref={speakersInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('speakers')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.speakers : ''}
                      onChangeText={tmpSpeakers => setSelectedRoom_Studio({...selectedRoom_Studio, speakers: tmpSpeakers})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => consoleInputRef.current.focus()}>
                    <TextInput
                      ref={consoleInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('console')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.console : ''}
                      onChangeText={tmpConsole => setSelectedRoom_Studio({...selectedRoom_Studio, console: tmpConsole})} 
                    />     
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => microphonesInputRef.current.focus()}>
                    <TextInput
                      ref={microphonesInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('microphones')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.microphones : ''}
                      onChangeText={tmpMicrophones => setSelectedRoom_Studio({...selectedRoom_Studio, microphones: tmpMicrophones})} 
                    />
                  </TouchableOpacity>     
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => drumsInputRef.current.focus()}>
                    <TextInput
                      ref={drumsInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('drums')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.drums : ''}
                      onChangeText={tmpDrums => setSelectedRoom_Studio({...selectedRoom_Studio, drums: tmpDrums})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => guitarsInputRef.current.focus()}>
                    <TextInput
                      ref={guitarsInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('guitars')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.guitars : ''}
                      onChangeText={tmpGuitars => setSelectedRoom_Studio({...selectedRoom_Studio, guitars: tmpGuitars})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => bassesInputRef.current.focus()}>
                    <TextInput
                      ref={bassesInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('basses')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.basses : ''}
                      onChangeText={tmpBasses => setSelectedRoom_Studio({...selectedRoom_Studio, basses: tmpBasses})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => pianoKeyboardsInputRef.current.focus()}>
                    <TextInput
                      ref={pianoKeyboardsInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('pianosKeyboards')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.pianos_keyboards : ''}
                      onChangeText={tmpPianosKeyboards => setSelectedRoom_Studio({...selectedRoom_Studio, pianos_keyboards: tmpPianosKeyboards})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => guitarAmplifiersInputRef.current.focus()}>
                    <TextInput
                      ref={guitarAmplifiersInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('guitarAmplifiers')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.guitar_amplifiers : ''}
                      onChangeText={tmpGuitarAmplifiers => setSelectedRoom_Studio({...selectedRoom_Studio, guitar_amplifiers: tmpGuitarAmplifiers})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => bassAmplifiersInputRef.current.focus()}>
                    <TextInput
                      ref={bassAmplifiersInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('bassAmplifiers')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.bass_amplifiers : ''}
                      onChangeText={tmpBassAmplifiers => setSelectedRoom_Studio({...selectedRoom_Studio, bass_amplifiers: tmpBassAmplifiers})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
                <DataTable.Row style={styles.tableRow}>
                  <TouchableOpacity onPress={() => otherInputRef.current.focus()}>
                    <TextInput
                      ref={otherInputRef}
                      style={styles.fullWidthInputText}
                      label={i18n.t('otherEquipment')}
                      value={selectedRoom_Studio!=undefined && selectedRoomDropdownIndex_Studio>-1 ? selectedRoom_Studio.other_equipment : ''}
                      onChangeText={tmpOtherEquipment => setSelectedRoom_Studio({...selectedRoom_Studio, other_equipment: tmpOtherEquipment})} 
                    />
                  </TouchableOpacity>
                </DataTable.Row>
              </DataTable>}
                        
              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="content-save" color={color} size={size} />)} mode="contained" style={{ display: selectedRoom_Studio!=undefined && myRoomsArray_Studio.length>0 && isLoading==false && addNewRoomMode_Studio==false ? 'flex' : 'none', flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => editRoomAsync()}>{i18n.t('save')}</Button>}
              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} mode="contained" style={{ backgroundColor: 'red', display: selectedRoom_Studio!=undefined && myRoomsArray_Studio.length>1 && isLoading==false && addNewRoomMode_Studio==false ? 'flex' : 'none', flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => deleteRoom()}>{i18n.t('delete')}</Button>}
              {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <View style={{flexDirection:"row"}}>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} mode="contained" style={{ backgroundColor: 'red', display: selectedRoom_Studio!=undefined && myRoomsArray_Studio.length>1 && isLoading==false && addNewRoomMode_Studio==true ? 'flex' : 'none', flex:1, alignItems:'center' }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => { setCancelNewRoomMode_Studio(true); }}>{i18n.t('cancel')}</Button>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="plus-circle-outline" color={color} size={size} />)} mode="contained" style={{ backgroundColor: 'green', display: selectedRoom_Studio!=undefined && myRoomsArray_Studio.length>1 && isLoading==false && addNewRoomMode_Studio==true ? 'flex' : 'none', flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => addRoomAsync()}>{i18n.t('add')}</Button>
              </View>}


              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <View style={styles.domainDropdown}>
                <DropDown
                    label={i18n.t('type')}
                    mode={'outlined'}
                    visible={showTennisCourtTypeDropdownList}
                    showDropDown={() => setShowTennisCourtTypeDropdownList(true)}
                    onDismiss={() => setShowTennisCourtTypeDropdownList(false)}
                    value={selectedCourt_Tennis.type.toString()}
                    setValue={tmpCurValue => setSelectedCourt_Tennis({...selectedCourt_Tennis, type: parseInt(tmpCurValue, 10)})}
                    list={tennisCourtTypeDropdownList}
                    inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showTennisCourtTypeDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                />
                <Divider style={{ height: 5, backgroundColor: "white" }}/>
              </View>}
              
              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('monday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.mondayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, mondayOpen: myData_Tennis.mondayOpen=='true' ? 'false' : 'true', mondayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('monday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownMondayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeMondayStartHour_Studio={proposeMondayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeMondayStartHour_Tennis={proposeMondayStartHour_Tennis}
                                  
                                  setMondayStartHour={setMondayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownMondayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeMondayStopHour_Studio={proposeMondayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeMondayStopHour_Tennis={proposeMondayStopHour_Tennis}
                                  
                                  setMondayStopHour={setMondayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('monday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.mondayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => mondayCostInputRef.current.focus()}>
                      <TextInput
                        ref={mondayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.mondayOpen=="true" && selectedCourt_Tennis.monday_cost_per_hour==-1 ? proposeMondayCost_Tennis.toString() : selectedCourt_Tennis.monday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpMondayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          monday_cost_per_hour: parseFloat(tmpMondayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('tuesday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.tuesdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, tuesdayOpen: myData_Tennis.tuesdayOpen=='true' ? 'false' : 'true', tuesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('tuesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownTuesdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeTuesdayStartHour_Studio={proposeTuesdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeTuesdayStartHour_Tennis={proposeTuesdayStartHour_Tennis}
                                  
                                  setTuesdayStartHour={setTuesdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownTuesdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeTuesdayStopHour_Studio={proposeTuesdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeTuesdayStopHour_Tennis={proposeTuesdayStopHour_Tennis}
                                  
                                  setTuesdayStopHour={setTuesdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('tuesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.tuesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => tuesdayCostInputRef.current.focus()}>
                      <TextInput
                        ref={tuesdayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.tuesdayOpen=="true" && selectedCourt_Tennis.tuesday_cost_per_hour==-1 ? proposeTuesdayCost_Tennis.toString() : selectedCourt_Tennis.tuesday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpTuesdayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          tuesday_cost_per_hour: parseFloat(tmpTuesdayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('wednesday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.wednesdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, wednesdayOpen: myData_Tennis.wednesdayOpen=='true' ? 'false' : 'true', wednesdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('wednesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownWednesdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeWednesdayStartHour_Studio={proposeWednesdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeWednesdayStartHour_Tennis={proposeWednesdayStartHour_Tennis}
                                  
                                  setWednesdayStartHour={setWednesdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownWednesdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeWednesdayStopHour_Studio={proposeWednesdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeWednesdayStopHour_Tennis={proposeWednesdayStopHour_Tennis}
                                  
                                  setWednesdayStopHour={setWednesdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('wednesday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.wednesdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => wednesdayCostInputRef.current.focus()}>
                      <TextInput
                        ref={wednesdayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.wednesdayOpen=="true" && selectedCourt_Tennis.wednesday_cost_per_hour==-1 ? proposeWednesdayCost_Tennis.toString() : selectedCourt_Tennis.wednesday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpWednesdayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          wednesday_cost_per_hour: parseFloat(tmpWednesdayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('thursday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.thursdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, thursdayOpen: myData_Tennis.thursdayOpen=='true' ? 'false' : 'true', thursdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('thursday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownThursdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeThursdayStartHour_Studio={proposeThursdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeThursdayStartHour_Tennis={proposeThursdayStartHour_Tennis}
                                  
                                  setThursdayStartHour={setThursdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownThursdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeThursdayStopHour_Studio={proposeThursdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeThursdayStopHour_Tennis={proposeThursdayStopHour_Tennis}
                                  
                                  setThursdayStopHour={setThursdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('thursday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.thursdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => thursdayCostInputRef.current.focus()}>
                      <TextInput
                        ref={thursdayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.thursdayOpen=="true" && selectedCourt_Tennis.thursday_cost_per_hour==-1 ? proposeThursdayCost_Tennis.toString() : selectedCourt_Tennis.thursday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpThursdayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          thursday_cost_per_hour: parseFloat(tmpThursdayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('friday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.fridayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, fridayOpen: myData_Tennis.fridayOpen=='true' ? 'false' : 'true', fridayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('friday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownFridayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeFridayStartHour_Studio={proposeFridayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeFridayStartHour_Tennis={proposeFridayStartHour_Tennis}
                                  
                                  setFridayStartHour={setFridayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownFridayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeFridayStopHour_Studio={proposeFridayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeFridayStopHour_Tennis={proposeFridayStopHour_Tennis}
                                  
                                  setFridayStopHour={setFridayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('friday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.fridayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => fridayCostInputRef.current.focus()}>
                      <TextInput
                        ref={fridayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_cost_per_hour==-1 ? proposeFridayCost_Tennis.toString() : selectedCourt_Tennis.friday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpFridayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          friday_cost_per_hour: parseFloat(tmpFridayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('saturday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.saturdayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, saturdayOpen: myData_Tennis.saturdayOpen=='true' ? 'false' : 'true', saturdayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('saturday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownSaturdayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSaturdayStartHour_Studio={proposeSaturdayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSaturdayStartHour_Tennis={proposeSaturdayStartHour_Tennis}
                                  
                                  setSaturdayStartHour={setSaturdayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownSaturdayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSaturdayStopHour_Studio={proposeSaturdayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSaturdayStopHour_Tennis={proposeSaturdayStopHour_Tennis}
                                  
                                  setSaturdayStopHour={setSaturdayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('saturday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.saturdayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => saturdayCostInputRef.current.focus()}>
                      <TextInput
                        ref={saturdayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_cost_per_hour==-1 ? proposeSaturdayCost_Tennis.toString() : selectedCourt_Tennis.saturday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpSaturdayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          saturday_cost_per_hour: parseFloat(tmpSaturdayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable style={styles.roomTable}>
                <DataTable.Header style={styles.roomTableMainHeader}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableDayName}>{i18n.t('sunday')}</Text>
                  </TouchableOpacity>
                  <Checkbox.Item
                    label={i18n.t('open')}  
                    status={myData_Tennis.sundayOpen=='true' ? 'checked' : 'unchecked'}
                    onPress={() => { 
                      setMyData_Tennis({...myData_Tennis, sundayOpen: myData_Tennis.sundayOpen=='true' ? 'false' : 'true', sundayCheckboxJustChangedAndTriggersSelectedCourtUpdate: true});
                    }}
                    color="green"
                    uncheckedColor="red"
                    labelStyle={{ color: "white" }}
                    style={{ flex: 1 }}
                  />
                </DataTable.Header>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableWorkingHours}>{i18n.t('workingHours')} ({i18n.t('sunday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.roomTableFrom}>
                    <OwnerDropdownSundayFrom   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSundayStartHour_Studio={proposeSundayStartHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSundayStartHour_Tennis={proposeSundayStartHour_Tennis}
                                  
                                  setSundayStartHour={setSundayStartHour}
                    />
                  </View>
                  <View style={styles.roomTableTo}>
                    <OwnerDropdownSundayTo   
                                  myData_Studio={myData_Studio}
                                  setMyData_Studio={setMyData_Studio}
                                  selectedRoom_Studio={selectedRoom_Studio}
                                  proposeSundayStopHour_Studio={proposeSundayStopHour_Studio}
                                  
                                  myData_Tennis={myData_Tennis}
                                  setMyData_Tennis={setMyData_Tennis}
                                  selectedCourt_Tennis={selectedCourt_Tennis}
                                  proposeSundayStopHour_Tennis={proposeSundayStopHour_Tennis}
                                  
                                  setSundayStopHour={setSundayStopHour}
                    />
                  </View>
                </DataTable.Row>
                <DataTable.Header style={[styles.roomTableSecondaryHeader, { display: myData_Tennis.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <TouchableOpacity style={{ flex: 1, alignSelf:"center", justifyContent: "center" }}>
                    <Text style={styles.roomTableCost}>{i18n.t('cost')} ({i18n.t('sunday')})</Text>
                  </TouchableOpacity>
                </DataTable.Header>
                <DataTable.Row style={[styles.tableRow, { display: myData_Tennis.sundayOpen=='true' ? 'flex' : 'none' }]}>
                  <View style={styles.courtTableCost}>
                    <TouchableOpacity onPress={() => sundayCostInputRef.current.focus()}>
                      <TextInput
                        ref={sundayCostInputRef}
                        style={styles.fullWidthInputText}
                        label={i18n.t('cost')}
                        keyboardType={'decimal-pad'} mode={'flat'}
                        value={myData_Tennis.sundayOpen=="true" && selectedCourt_Tennis.sunday_cost_per_hour==-1 ? proposeSundayCost_Tennis.toString() : selectedCourt_Tennis.sunday_cost_per_hour.toString()}
                        right={<TextInput.Affix text={selectedCourt_Tennis.currency}/>}
                        onChangeText={tmpSundayCost => setSelectedCourt_Tennis({
                          ...selectedCourt_Tennis,
                          sunday_cost_per_hour: parseFloat(tmpSundayCost.replace(',', '.'))
                        })} 
                      />
                    </TouchableOpacity>
                  </View>
                </DataTable.Row>
              </DataTable>}

              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="content-save" color={color} size={size} />)} mode="contained" style={{ display: selectedCourt_Tennis!=undefined && myCourtsArray_Tennis.length>0 && isLoading==false && addNewCourtMode_Tennis==false ? 'flex' : 'none', flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => editCourtAsync()}>{i18n.t('save')}</Button>}
              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} mode="contained" style={{ backgroundColor: 'red', display: selectedCourt_Tennis!=undefined && myCourtsArray_Tennis.length>1 && isLoading==false && addNewCourtMode_Tennis==false ? 'flex' : 'none', flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => deleteCourt()}>{i18n.t('delete')}</Button>}
              {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <View style={{flexDirection:"row"}}>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} mode="contained" style={{ backgroundColor: 'red', display: selectedCourt_Tennis!=undefined && myCourtsArray_Tennis.length>1 && isLoading==false && addNewCourtMode_Tennis==true ? 'flex' : 'none', flex:1, alignItems:'center' }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => { setCancelNewCourtMode_Tennis(true); }}>{i18n.t('cancel')}</Button>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="plus-circle-outline" color={color} size={size} />)} mode="contained" style={{ backgroundColor: 'green', display: selectedCourt_Tennis!=undefined && myCourtsArray_Tennis.length>1 && isLoading==false && addNewCourtMode_Tennis==true ? 'flex' : 'none', flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => addCourtAsync()}>{i18n.t('add')}</Button>
              </View>}
            </ScrollView>
            {MyAlertForWeb!=null && 
              <MyAlertForWeb
                show={alertForWebVisible}
                showProgress={false}
                title={(alertForWebTitle==i18n.t('areYouSure') || alertForWebTitle==i18n.t('congratulations') || alertForWebTitle==i18n.t('deleteRoom') || alertForWebTitle==i18n.t('deleteCourt')) ? alertForWebTitle : i18n.t('caution')}
                message={alertForWebText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                showCancelButton={(alertForWebTitle==i18n.t('areYouSure') || alertForWebTitle==i18n.t('deleteRoom') || alertForWebTitle==i18n.t('deleteCourt')) ? true : false}
                onCancelPressed={() => {
                  if(alertForWebTitle == i18n.t('areYouSure')) {
                    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
                      setMyData_Studio({...myData_Studio, 
                        confirmationMessage: '',
                        confirmationDialogVisible: false
                      });
                    }
                    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
                      setMyData_Tennis({...myData_Tennis, 
                        confirmationMessage: '',
                        confirmationDialogVisible: false
                      });
                    }
                  }
                  setAlertForWebVisible(false);
                  setAlertForWebTitle("");
                }}
                onConfirmPressed={() => {
                  if(alertForWebTitle == i18n.t('areYouSure')) {
                    giveConfirmation();
                  }
                  else if(alertForWebTitle == i18n.t('congratulations')) {
                    if(alertForWebText == i18n.t('roomAdded'))
                      setAddNewRoomMode_Studio(false);
                    else if(alertForWebText == i18n.t('courtAdded'))
                      setAddNewCourtMode_Tennis(false);
                  }
                  else if(alertForWebTitle == i18n.t('deleteRoom')) {
                    deleteRoomAsync();
                  }
                  else if(alertForWebTitle == i18n.t('deleteCourt')) {
                    deleteCourtAsync();
                  }
                  setAlertForWebVisible(false);
                  setAlertForWebTitle("");
                }}  
              />
            }
          </View>
          {ownerCameHereAutomaticallyBecauseHeMadeChangesInHisProfile==true && <RightColumnWeb/>}
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

export default OwnerManageRoomsView;