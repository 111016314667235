import React, { memo, useEffect, useRef, useState } from "react";
import { FlatList, Platform, View } from "react-native";
import { Button, Dialog, Paragraph, Appbar, Portal, Menu, Divider, Badge, Text } from "react-native-paper";
import { useNavigation } from '@react-navigation/native';
import i18n from '../../utils/i18n';
import LoginManager from "../../managers/LoginManager";
import styles from "../../css/myCss";
import * as Notifications from 'expo-notifications';
import Generic_RestManager from "../../managers/Generic_RestManager";
import GenericConstManager from "../../managers/Generic_ConstManager";
import { MaterialCommunityIcons } from "@expo/vector-icons";
   
type Props = {
  screenTitle: string;
};  
  
const OwnerMenuOptions = ({ 
                            screenTitle
                            /*setSelectedDate,
                            setNewReservation_date,
                            setForceOwnerReservationsUpdate */
                          }: Props) => {
  const navigation = useNavigation();

  const [updateNotificationsNow, setUpdateNotificationsNow] = useState(true);
  useEffect(() => {
    if (updateNotificationsNow) {
      // update notifications
      (async () => {
        const tmpAllNotificationsFromWS : Array<Notification> = await Generic_RestManager.getInstance().getAllNotificationsByUserEmail();
        //console.log("paw na setarw notifications: ", JSON.stringify(tmpAllNotificationsFromWS, null, 2));
        setAllNotifications(tmpAllNotificationsFromWS);
      })();
      setUpdateNotificationsNow(false);      
    }
    else {
      // Wait XXX seconds before update notifications again
      const timeout = setTimeout(() => {
        setUpdateNotificationsNow(true);
      }, GenericConstManager.getInstance().UPDATE_NOTIFICATIONS_EVERY_MILLISECONDS);

      // Clear the timeout when the component unmounts or when updateNotificationsNow changes to true
      return () => clearTimeout(timeout);
    }
  }, [updateNotificationsNow]);

  const [menuVisible, setMenuVisible] = useState(false);
  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);
  const hideLogoutDialog = () => setLogoutDialogVisible(false);

  const MORE_ICON = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';
  function goToMyProfile() {
    closeMenu();
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      navigation.navigate('OwnerMyProfile', { title: i18n.t('studioOwnerProfile'), backLabel: i18n.t('back') });
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      navigation.navigate('OwnerMyProfile', { title: i18n.t('tennisCourtOwnerProfile'), backLabel: i18n.t('back') });
  }


  function goToManageTennisMemberships() {
    closeMenu();
    navigation.navigate('OwnerManageTennisClubMemberships', { title: i18n.t('membershipPackages'), backLabel: i18n.t('back') });
  }

  function goToSettings() {
    closeMenu();
    navigation.navigate('OwnerSettings', { title: i18n.t('accountSettings'), backLabel: i18n.t('back') });
  }

  function logout() {
    setLogoutDialogVisible(false);
    /*const webServiceResponseBoolean : boolean = */Generic_RestManager.getInstance().deleteToken();
    LoginManager.getInstance().logout();
    navigation.reset({
      index: 1,
      routes: [{ name: 'HandyBooker_Home' }]
      //key: null
    });
  }

  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const openNotifications = () => setNotificationsVisible(true);
  const closeNotifications = () => setNotificationsVisible(false);

  const notificationListener = useRef();
  const responseListener = useRef();

  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      //setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log("notification response: " + response);
    });

    Notifications.setNotificationHandler({
      handleNotification: async (notification) => {
        console.log(notification);
    
        // Add the notification to the notifications array
        //setAllNotifications(prevNotifications => [...prevNotifications, notification]);
    
    
        // Handle onPress logic
        // For example, navigate to a specific screen based on the notification data
        // The data can be accessed through notification.data
        
        // Access the data property of the notification
        const data = notification.request.content.data;
    
        // Do something with the data
        console.log("notification data: ", data);
    
        return {
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: true,
        };
      },
    });
  }, []);
  


   return (
    <Appbar.Header>
        <Appbar.Content title={screenTitle} />
        <Portal>
          <Dialog visible={logoutDialogVisible} onDismiss={hideLogoutDialog} style={styles.dialog}>
            <Dialog.Title>{i18n.t('logout')}</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setLogoutDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => logout()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        
        <View style={{ flexDirection: 'row' }}>
          <Menu
            visible={notificationsVisible}
            onDismiss={closeNotifications}
            anchor={<Appbar.Action icon='bell' color="white" onPress={() => { 
              if(allNotifications!=undefined && allNotifications.length>0) 
                openNotifications();
            }} />}
            style={styles.menu}
            >
            <FlatList
                  data={allNotifications}
                  renderItem={({ item }) => (
                    <Menu.Item
                      onPress={() => {  
                        //console.log("notification: ", JSON.stringify(item,null, 2));
                        //console.log("thelw na diagrapsw ID: " + item.id);
                        setAllNotifications(prevNotifications => {
                          return prevNotifications.filter(tmpNotification => tmpNotification !== item);
                        });
                        Generic_RestManager.getInstance().deleteNotification(item.id);
                        //console.log.
                        //first close notifications menu
                        //closeNotifications();
                        //then force reservations update
                        //setForceOwnerReservationsUpdate(true);
                        //and set the selected date
                        /*const dateString = '20/03/2023';
                        const [day, month, year] = dateString.split('/').map(str => parseInt(str, 10));
                        const dateObject = new Date(year, month - 1, day);
                        const timestamp = dateObject.getTime();                        
                        setSelectedDate(UtilsManager.getInstance().getSelectedDayTitle(day, month, year, timestamp));
                        setNewReservation_date(dateString);*/
                        //init
                  //      setAllNotifications([]);
                        //LoginManager.getInstance().allNotifications = [];
                      }}
                      title={
                        <View>
                          <Text>{item.my_title}</Text>
                          <Text style={{ color: '#999' }}>{item.my_body}</Text>
                        </View>
                      }
                      icon={({ color, size }) => (<MaterialCommunityIcons name="close-box-outline" color={color} size={size} />)}
                    />
                  )}
                  keyExtractor={(item, index) => index.toString()}
              />
          </Menu>
          <View style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <Badge
              visible={allNotifications!=undefined && allNotifications.length>0}
              style={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
            >
              {allNotifications!=undefined ? allNotifications.length : "0"}
            </Badge>
          </View>
        </View>

        <Menu
          visible={menuVisible}
          onDismiss={closeMenu}
          anchor={<Appbar.Action icon={({ color, size }) => <MaterialCommunityIcons name={MORE_ICON} size={size} color="white" />} onPress={openMenu} />}
          style={styles.menu}
          >
            <Menu.Item onPress={() => {goToMyProfile()}} title={i18n.t('myProfile')} icon={({ color, size }) => (<MaterialCommunityIcons name="account" color={color} size={size} />)} />
            <Divider />
            {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS 
              && LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members==true
              && <Menu.Item onPress={() => {goToManageTennisMemberships()}} title={i18n.t('membershipPackages')} icon={({ color, size }) => (<MaterialCommunityIcons name="package" color={color} size={size} />)} />}
            {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS 
              && LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members==true
              && <Divider />}
            <Menu.Item onPress={() => {goToSettings()}} title={i18n.t('settings')} icon={({ color, size }) => (<MaterialCommunityIcons name="cog-outline" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={() => {closeMenu(); setLogoutDialogVisible(true);}} title={i18n.t('logout')} icon={({ color, size }) => (<MaterialCommunityIcons name="logout" color={color} size={size} />)} />
        </Menu>            
    </Appbar.Header>
   );
  };
 
  export default memo(OwnerMenuOptions);