class TennisConstManager {
    public readonly RESERVATION_ADDED : number = 100;
    public readonly COURT_ALREADY_RESERVED : number = -100;
    public readonly INVALID_DATE : number = -108;
    public readonly PLAYER_HAS_ANOTHER_RESERVATION_AT_THIS_TIME : number = -200;
    public readonly NOT_ENOUGH_PERMISSIONS : number = -300;
    public readonly RESERVATION_MADE_BY_THE_OWNER_OF_THE_COURT : number = -1000;
    public readonly CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED : number = -1005;

    public readonly INTRUDER_MSG : string = "You do not have permissions for this!";
    public readonly RESERVATION_ADDED_MSG : string = "Reservation added successfully!";
    public readonly COURT_ALREADY_RESERVED_MSG : string = "The court is already reserved at this time slot!";
    public readonly PLAYER_HAS_ANOTHER_RESERVATION_AT_THIS_TIME_MSG : string = "This player has already another reservation at this time slot!";
    public readonly SIGN_IN_NEEDED_MSG : string = "Please sign in to get access to this information!";
    public readonly ALREADY_SENT : string = "Already sent!";
    public readonly INVALID_USERNAME_OR_KEY : string = "The email or key you provided is invalid!";
    public readonly INVALID_KEY : string = "The key you provided is invalid!";
    public readonly ACCOUNT_VERIFIED : string = "The account has been verified successfully!";
    public readonly VERIFICATION_KEY_HAS_BEEN_RESENT : string = "The verification key has been resent!";
    public readonly EMAILS_SENT_TO_INACTIVE_OWNERS : string = "Emails have been sent to all inactive owners!";
    public readonly PLAYER_BLOCKED : string = "Player blocked successfully!";
    public readonly PLAYER_UNBLOCKED : string = "Player unblocked successfully!";
    public readonly CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG : string = "The reservation cannot be completed because the player is blocked by this owner!";
    public readonly DB_BACKUP_WAS_SAVED_SUCCESSFULLY_INTO_JSON : string = "BD was saved successfully into JSON!";
    public readonly AN_ERROR_OCCURED_DURING_DB_BACKUP : string = "An error occured during DB backup!";
    public readonly DB_WAS_RESTORED_SUCCESSFULLY_FROM_JSON : string = "DB was restored successfully from JSON!";
    public readonly AN_ERROR_OCCURED_DURING_DB_RESTORATION : string = "An error occured during DB restoration!";
    public readonly ANOTHER_USER_EXISTS_WITH_THIS_EMAIL : string = "Another user exists with this email!";
    public readonly PLAYER_ADDED_SUCCESSFULLY : string = "Player added successfully!";
    public readonly YES : string = "YES";
    public readonly NO : string = "NO";
    public readonly INVALID_DATE_MSG : string = "Invalid date!";
    public readonly YOU_HAVE_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_SUCCESSFULLY_MSG : string = "You have promoted HandyBooker to this owner successfully!";
    public readonly YOU_HAVE_ALREADY_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_MSG : string = "You have already promoted HandyBooker to this owner!";
    public readonly CURRENCY_OF_ALL_COURTS_UPDATED_MSG : string = "The currency of all courts has been updated!";

    public readonly PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY: string = "Private Club - Reservations only for members";
    public readonly PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS: string = "Private club - Reservations for both members and non-members";
    public readonly NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE: string = "Non private club - Reservations for anyone";    

    public readonly JOIN_THE_CLUB: string = "Join the club";
    public readonly I_AM_ALREADY_MEMBER: string = "I am already member";
    public readonly MAKE_RESERVATION_AS_GUEST: string = "Make a reservation as guest";

    public readonly CLUB_REQUEST_ADDED_SUCCESSFULLY: string = "Club request added successfully!";
    public readonly CLUB_REQUEST_ALREADY_SENT = "Club request already sent!";

    public readonly CLUB_MEMBER_ADDED_SUCCESSFULLY = "Club member added successfully!";
    public readonly THERE_IS_ANOTHER_CLUB_MEMBER_WITH_THIS_EMAIL = "There is another club member with this email!";

    public readonly THE_USER_IS_NOT_MEMBER_OF_THIS_CLUB = "The user is not member of this club";
    public readonly THE_USER_IS_MEMBER_OF_THIS_CLUB = "The user is member of this club";

    public readonly PLAYER = "player";
    public readonly MEMBER = "member";
    public readonly GUEST = "guest";

    //public readonly CLUB_REQUEST_ACCEPTED = "Club request accepted!";
    //public readonly CLUB_REQUEST_REJECTED = "Club request rejected!";

    private static instance: TennisConstManager;

    private constructor() { 
    }

    public static getInstance(): TennisConstManager {
        if (!TennisConstManager.instance) {
            TennisConstManager.instance = new TennisConstManager();
        }

        return TennisConstManager.instance;
    }
}

export default TennisConstManager;