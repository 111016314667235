import React, { useCallback, useState } from "react";
import { Keyboard, Linking, ScrollView, TouchableWithoutFeedback, View } from "react-native";
import { Button, Colors, DataTable, Dialog, Divider, IconButton, Paragraph, Portal, Title } from "react-native-paper";
import * as MailComposer from 'expo-mail-composer';
import styles from "../../css/myCss";
import i18n from "../../utils/i18n";
import Studio_RestManager from "../../managers/Studio_RestManager";
import UtilsManager from "../../managers/UtilsManager";
import moment from "moment";
import Spinner from "react-native-loading-spinner-overlay";
import LoginManager from "../../managers/LoginManager";
import Tennis_RestManager from "../../managers/Tennis_RestManager";
import Tennis_ConstManager from "../../managers/Tennis_ConstManager";
import { MaterialCommunityIcons } from "@expo/vector-icons";


const OwnerSelectedReservationModal = ({  
                                setMessageToShowForReservationCancellation,
                                curReservationDetails_Studio,
                                curReservationDetails_Tennis
                            }) => {

    const [isLoading, setIsLoading] = useState(false);                                

    const makePhoneCall_Studio = useCallback(() => {
        Linking.openURL("tel://" + curReservationDetails_Studio.musician_tel);
    }, [curReservationDetails_Studio.musician_tel]);

    const makePhoneCall_Tennis = useCallback(() => {
        Linking.openURL("tel://" + curReservationDetails_Tennis.player_tel);
    }, [curReservationDetails_Tennis.player_tel]);

    const sendEmail_Studio = useCallback(async () => {
        let result = await MailComposer.composeAsync({
            recipients: [curReservationDetails_Studio.musician_email],
            subject: '',
            body: '',
        });
        //alert(result.status);
    }, [curReservationDetails_Studio.musician_email]);

    const sendEmail_Tennis = useCallback(async () => {
        let result = await MailComposer.composeAsync({
            recipients: [curReservationDetails_Tennis.player_email],
            subject: '',
            body: '',
        });
        //alert(result.status);
    }, [curReservationDetails_Tennis.player_email]);

    const [cancelReservationDialogVisible, setCancelReservationDialogVisible] = useState(false);
    const showCancelReservationDialog = () => setCancelReservationDialogVisible(true);
    const hideCancelReservationDialog = () => setCancelReservationDialogVisible(false);

    const cancelReservation = useCallback(async () => {
        hideCancelReservationDialog();
        setIsLoading(true);        
        var webServiceResponseStr;
        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
            webServiceResponseStr = await Studio_RestManager.getInstance().cancelReservation(curReservationDetails_Studio.reservation_id);
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
            webServiceResponseStr = await Tennis_RestManager.getInstance().cancelReservation(curReservationDetails_Tennis.reservation_id);
        if(webServiceResponseStr == "Reservation cancelled!")
            setMessageToShowForReservationCancellation(i18n.t('reservationCancelled'));
        else
            setMessageToShowForReservationCancellation(webServiceResponseStr);
        setIsLoading(false);
    }, [curReservationDetails_Studio.reservation_id, 
        curReservationDetails_Tennis.reservation_id]);
    
    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
            <ScrollView>
                <Spinner
                    visible={isLoading}
                    textContent={i18n.t('loading')}
                    textStyle={styles.spinnerTextStyle}
                />
                <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('reservationDetails')}</Title>
                <Divider/>
                {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <View>
                    <DataTable>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('rehearsalRecording')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.rehearsal_recording_str=="yes" ? i18n.t('rehearsal') : i18n.t('recording')}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('room')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.room_name}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.reservation_date}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('hours')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.reservation_start_time} - {curReservationDetails_Studio.reservation_stop_time}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('totalCost')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.reservation_cost}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={[styles.tableRow, {display: curReservationDetails_Studio.reservation_comments!='' ? 'flex' : 'none' }]}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('comments')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.reservation_comments}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                    
                    { curReservationDetails_Studio.musician_email != "" &&<Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('musicianDetails')}</Title>}
                    { curReservationDetails_Studio.musician_email != "" && 
                        <DataTable>
                            <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>{i18n.t('musician')}</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.musician_name}</DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>{i18n.t('telephone')}</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.musician_tel}</DataTable.Cell>
                                <DataTable.Cell style={styles.tableIcon}>
                                    <IconButton
                                        icon={({ color, size }) => (<MaterialCommunityIcons name="phone" color={color} size={size} />)}
                                        color={Colors.blue900}
                                        size={35}
                                        onPress={makePhoneCall_Studio} />
                                </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>Email</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.musician_email}</DataTable.Cell>
                                <DataTable.Cell style={styles.tableIcon}>
                                    <IconButton
                                        icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)}
                                        color={Colors.blue900}
                                        size={35}
                                        onPress={sendEmail_Studio} />
                                </DataTable.Cell>
                            </DataTable.Row>
                            {curReservationDetails_Studio.musician_band!="" && <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>{i18n.t('bandCapital')}</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Studio.musician_band}</DataTable.Cell>
                            </DataTable.Row>}
                        </DataTable>
                    }
                    <Button disabled={UtilsManager.getInstance().compareDates(moment(new Date()).format('DD/MM/YYYY'), curReservationDetails_Studio.reservation_date)==-1 ? true : false} icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} mode="contained" onPress={showCancelReservationDialog} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContentRed}>{i18n.t('cancelReservation')}</Button>
                </View>}

                {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <View>
                    <DataTable>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('tennisCourt')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.court_name}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.reservation_date}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('hours')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.reservation_start_time} - {curReservationDetails_Tennis.reservation_stop_time}</DataTable.Cell>
                        </DataTable.Row>
                        {curReservationDetails_Tennis.player_tag!=Tennis_ConstManager.getInstance().MEMBER && <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('cost')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.reservation_cost}</DataTable.Cell>
                        </DataTable.Row>}
                        <DataTable.Row style={[styles.tableRow, {display: curReservationDetails_Tennis.reservation_comments!='' ? 'flex' : 'none' }]}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('comments')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.reservation_comments}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                    
                    { curReservationDetails_Tennis.player_email != "" &&<Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('playerDetails')}</Title>}
                    { curReservationDetails_Tennis.player_email != "" && 
                        <DataTable>
                            <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>{i18n.t('player')}</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.player_name}</DataTable.Cell>
                                <DataTable.Cell style={styles.tableIcon}>{curReservationDetails_Tennis.player_tag==Tennis_ConstManager.getInstance().MEMBER ? i18n.t('clubMember') : curReservationDetails_Tennis.player_tag==Tennis_ConstManager.getInstance().GUEST ? i18n.t('guest') : ""}</DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>{i18n.t('telephone')}</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.player_tel}</DataTable.Cell>
                                <DataTable.Cell style={styles.tableIcon}>
                                    <IconButton
                                        icon={({ color, size }) => (<MaterialCommunityIcons name="phone" color={color} size={size} />)}
                                        color={Colors.blue900}
                                        size={35}
                                        onPress={makePhoneCall_Tennis} />
                                </DataTable.Cell>
                            </DataTable.Row>
                            <DataTable.Row style={styles.tableRow}>
                                <DataTable.Title style={styles.tableTitle}>Email</DataTable.Title>
                                <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails_Tennis.player_email}</DataTable.Cell>
                                <DataTable.Cell style={styles.tableIcon}>
                                    <IconButton
                                        icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)}
                                        color={Colors.blue900}
                                        size={35}
                                        onPress={sendEmail_Tennis} />
                                </DataTable.Cell>
                            </DataTable.Row>
                        </DataTable>
                    }
                    <Button disabled={UtilsManager.getInstance().compareDates(moment(new Date()).format('DD/MM/YYYY'), curReservationDetails_Tennis.reservation_date)==-1 ? true : false} icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} mode="contained" onPress={showCancelReservationDialog} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContentRed}>{i18n.t('cancelReservation')}</Button>
                </View>}

                <Portal>
                    <Dialog visible={cancelReservationDialogVisible} onDismiss={hideCancelReservationDialog} style={styles.dialog}>
                    <Dialog.Title>{i18n.t('cancelReservation')}</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph>{i18n.t('areYouSure')}</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={hideCancelReservationDialog}>{i18n.t('cancel')}</Button>
                        <Button onPress={cancelReservation}>Ok</Button>
                    </Dialog.Actions>
                    </Dialog>
                </Portal>
            </ScrollView>
        </TouchableWithoutFeedback>
    );
  };

  export default React.memo(OwnerSelectedReservationModal);