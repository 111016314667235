import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { TextInput } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OwnerDropdownSaturdayFrom = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeSaturdayStartHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeSaturdayStartHour_Tennis,
                                
                                setSaturdayStartHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Studio.showSaturdayStartHour}
        showDropDown={() => setMyData_Studio({...myData_Studio, showSaturdayStartHour: true})}
        onDismiss={() => setMyData_Studio({...myData_Studio, showSaturdayStartHour: false})}
        value={myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_working_hours_start=="closed" ? proposeSaturdayStartHour_Studio : selectedRoom_Studio.saturday_working_hours_start}
        setValue={setSaturdayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showSaturdayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Tennis.showSaturdayStartHour}
        showDropDown={() => setMyData_Tennis({...myData_Tennis, showSaturdayStartHour: true})}
        onDismiss={() => setMyData_Tennis({...myData_Tennis, showSaturdayStartHour: false})}
        value={myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_working_hours_start=="closed" ? proposeSaturdayStartHour_Tennis : selectedCourt_Tennis.saturday_working_hours_start}
        setValue={setSaturdayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showSaturdayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownSaturdayFrom/*, areEqual*/);