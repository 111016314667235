import React, { useState, useEffect } from 'react';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, TouchableOpacity, TouchableWithoutFeedback, View, Text, ScrollView, RefreshControl } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import {LocaleConfig, Agenda} from 'react-native-calendars';
import Generic_RestManager from '../../managers/Generic_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import UtilsManager from '../../managers/UtilsManager';
import PlayerMenuOptions from './PlayerMenuOptions';
import styles from '../../css/myCss';
import moment from 'moment';
import Tip from '../generic/Tip';

//Alert
  //react native
    //already imported above
  //web
  //...toDo...

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = null;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const calendarAgendaConfig = require('../calendarAgendaConfig');

//Calendar
  //react native
    //already imported above
  //web
  import { Calendar, momentLocalizer } from "react-big-calendar";
  //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
  import "react-big-calendar/lib/css/react-big-calendar.css";
import Studio_RestManager from '../../managers/Studio_RestManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import { Modal, Portal } from 'react-native-paper';
import PlayerSelectedReservationModal from './PlayerSelectedReservationModal';
  
  import "moment/locale/el"; // Import the Greek locale data
  
  const localizer = momentLocalizer(moment);  

  let MyCalendarReactNative;
  let MyCalendarForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyCalendarReactNative = null;
    MyCalendarForWeb = Calendar;
  } else {                      //REACT NATIVE
    MyCalendarReactNative = Agenda;
    MyCalendarForWeb = null;
  }
//-Calendar

const PlayerMyReservationsView = () => {
  const navigation = useNavigation();

  const [curReservationDetails, setCurReservationDetails] = useState({
    itemDomain: '',
    ownerID: -1,
    companyName: '',
    roomName: '',
    courtName: '',
    reservationDate: '',
    reservationStartTime: '',
    reservationStopTime: '',
    isRehearsal: true,
    reservationCost: '',
    reservationComments: ''
  });
  useEffect(() => {
    if(curReservationDetails.itemDomain != "")
      setVisibleSelectedReservationDetailsModal(true);
  }, [JSON.stringify(curReservationDetails)]);

  const [visibleSelectedReservationDetailsModal, setVisibleSelectedReservationDetailsModal] = useState(false);
  useEffect(() => {
    console.log("useEffect - START - visibleSelectedReservationDetailsModal");
    if(visibleSelectedReservationDetailsModal == true) {
      //setMessageToShowForReservationCancellation(""); //init
    }
    else {
      //init
      const tmpCurReservationDetails = {
        itemDomain: '',
        ownerID: -1,
        companyName: '',
        roomName: '',
        courtName: '',
        reservationDate: '',
        reservationStartTime: '',
        reservationStopTime: '',
        isRehearsal: true,
        reservationCost: '',
        reservationComments: ''
      }
      setCurReservationDetails(tmpCurReservationDetails);
    }
    console.log("useEffect - STOP - visibleSelectedReservationDetailsModal");
  }, [visibleSelectedReservationDetailsModal]);
  const hideSelectedReservationDetailsModal = () => setVisibleSelectedReservationDetailsModal(false);

  // Customized text for the navigation buttons
  const webCalendarMessages = LoginManager.getInstance().language == LoginManager.getInstance().EN ? {
    today: 'Today',
    previous: 'Previous',
    next: 'Next',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Time',
    event: 'Reservation'
  } : {
    today: 'Σήμερα',
    previous: 'Προηγούμενο',
    next: 'Επόμενο',
    month: 'Μήνας',
    week: 'Εβδομάδα',
    day: 'Ημέρα',
    agenda: 'Ατζέντα',
    date: 'Ημερομηνία',
    time: 'Ώρα',
    event: 'Κράτηση'
  };
  const [calendarEventsForWeb, setCalendarEventsForWeb] = useState([]); 
  const [calendarEventsForWebAllInfo, setCalendarEventsForWebAllInfo] = useState<Array<ReservationDetailsRetrievedFromAgendaItem>>([]); 

  const handleEventClick = (event) => {
    // Display an alert when an agenda item is clicked
    //alert(`Clicked on Agenda Item: ${event.title}`);
    //alert(`Clicked on Agenda Item: ${JSON.stringify(event, null, 2)}`);

    var tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem;
    //find the corresponding object from the list
    for (const tempItem of calendarEventsForWebAllInfo) {
      const tempJsonObject = JSON.parse(tempItem.webAgendaItem_JSONstringified);

      if(tempJsonObject.start==event.start.toISOString() && tempJsonObject.end==event.end.toISOString() && tempJsonObject.title==event.title)
        tmpReservationDetailsRetrievedFromAgendaItem = tempItem;
    }
    if(tmpReservationDetailsRetrievedFromAgendaItem == undefined) {
      alert(`Clicked on Agenda Item: ${JSON.stringify(event, null, 2)}`);
    }
    else {
      //agendaItemOnClicked(tmpReservationDetailsRetrievedFromAgendaItem.ownerID, tmpReservationDetailsRetrievedFromAgendaItem.itemDomain);
      const tmpCurReservationDetails = {
        itemDomain: tmpReservationDetailsRetrievedFromAgendaItem.itemDomain,
        ownerID: tmpReservationDetailsRetrievedFromAgendaItem.ownerID,
        companyName: tmpReservationDetailsRetrievedFromAgendaItem.companyName,
        roomName: tmpReservationDetailsRetrievedFromAgendaItem.roomName,
        courtName: tmpReservationDetailsRetrievedFromAgendaItem.courtName,
        reservationDate: tmpReservationDetailsRetrievedFromAgendaItem.reservationDate,
        reservationStartTime: tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime,
        reservationStopTime: tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime,
        isRehearsal: tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal,
        reservationCost: tmpReservationDetailsRetrievedFromAgendaItem.reservationCost,
        reservationComments: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments
      }
      setCurReservationDetails(tmpCurReservationDetails);
      //alert(`Clicked on Agenda Item: ${JSON.stringify(tmpReservationDetailsRetrievedFromAgendaItem, null, 2)}`);
    }
  };

  //set calendar locale
  LocaleConfig.locales[LoginManager.getInstance().language.toLowerCase()] = {
    monthNames: [i18n.t('january'),i18n.t('february'),i18n.t('march'),i18n.t('april'),i18n.t('may'),i18n.t('june'),i18n.t('july'),i18n.t('august'),i18n.t('september'),i18n.t('october'),i18n.t('november'),i18n.t('december')],
    monthNamesShort: [i18n.t('januaryShort'),i18n.t('februaryShort'),i18n.t('marchShort'),i18n.t('aprilShort'),i18n.t('mayShort'),i18n.t('juneShort'),i18n.t('julyShort'),i18n.t('augustShort'),i18n.t('septemberShort'),i18n.t('octoberShort'),i18n.t('novemberShort'),i18n.t('decemberShort')],
    dayNames: [i18n.t('sunday'),i18n.t('monday'),i18n.t('tuesday'),i18n.t('wednesday'),i18n.t('thursday'),i18n.t('friday'),i18n.t('saturday')],
    dayNamesShort: [i18n.t('sundayShort'),i18n.t('mondayShort'),i18n.t('tuesdayShort'),i18n.t('wednesdayShort'),i18n.t('thursdayShort'),i18n.t('fridayShort'),i18n.t('saturdayShort')],
    today: i18n.t('today')
  };
  LocaleConfig.defaultLocale = LoginManager.getInstance().language.toLowerCase(); //it could be gr/fr/whatever, as the values are already translated to the current language
  //-set calendar locale

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("PlayerMyReservationsView: I'm ready!");
    if(LoginManager.getInstance().language == LoginManager.getInstance().GR)
      moment.locale("el");
    else
      moment.locale("en");
    //console.log("curDate: " + curDate);
    console.log("moment curDate: " + moment().format('YYYY-MM-DD').toString());
    (async () => {
      setIsLoading(true);
      const tmpAllAgendaItemsToSet = await Generic_RestManager.getInstance().getAllReservationsOfAllDomainsByUserEmail_forReactNativeCalendarsAgenda(LoginManager.getInstance().username);
      setAgendaItems(tmpAllAgendaItemsToSet);
      setIsLoading(false);
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const todayDate = new Date();
  //console.log("todayDate: " + JSON.stringify(todayDate, null, 2));
  const [selectedDate, setSelectedDate] = useState(UtilsManager.getInstance().getSelectedDayTitle(todayDate.getDate(), todayDate.getMonth()+1, todayDate.getFullYear(), todayDate.getMilliseconds()));

  const [agendaItems, setAgendaItems] = useState({
    //'2022-06-12': [{name: 'item 1 - any js object', height: 100}]//,
    //'2021-04-18': [{name: 'item 2 - any js object', height: 100}],
    //'2021-04-19': [{name: 'item 3 - any js object', height: 100}, {name: 'any js object', height: 100}]
  });  
  useEffect(() => {
    if (Platform.OS === 'web') {  //WEB
      var tmpNewCalendarEventsForWeb = [];

      /*const calendarEventsForWeb = [
        {
          start: moment("2023-10-28T18:00:00").toDate(),
          end: moment("2023-10-28T19:00:00").toDate(),
          title: "Πρόβα\nEmerald sun",
        }
      ];*/
      
      // Iterate over the properties of the input object
      for (const date in agendaItems) {
        if (agendaItems.hasOwnProperty(date)) {
          const dateItems = agendaItems[date];
          for (const item of dateItems) {
            const tmpReservationData = item.name;
            console.log("tmpReservationData -> ", tmpReservationData);
            
            const tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem = UtilsManager.getInstance().parseReservationDetailsFromAgendaItem(tmpReservationData);
            
            var tempCalItem = {
              start: moment(UtilsManager.getInstance().tranformDateToISOFormat(tmpReservationDetailsRetrievedFromAgendaItem.reservationDate) + "T" + tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime + ":00").toDate(),
              end: moment(UtilsManager.getInstance().tranformDateToISOFormat(tmpReservationDetailsRetrievedFromAgendaItem.reservationDate) + "T" + tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime + ":00").toDate(),
              title: tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime + "-" + tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime + "\n"
                     + tmpReservationDetailsRetrievedFromAgendaItem.companyName + "\n"
                     + (tmpReservationDetailsRetrievedFromAgendaItem.itemDomain==LoginManager.getInstance().STUDIO ? tmpReservationDetailsRetrievedFromAgendaItem.roomName : tmpReservationDetailsRetrievedFromAgendaItem.itemDomain==LoginManager.getInstance().TENNIS ? tmpReservationDetailsRetrievedFromAgendaItem.courtName : "unknown")  + "\n"
            };
            tmpNewCalendarEventsForWeb.push(tempCalItem); //this is to show the events in the agenda

            tmpReservationDetailsRetrievedFromAgendaItem.webAgendaItem_JSONstringified = JSON.stringify(tempCalItem); //this is to have all the info for each reservation stored to show it when the agenda event is clicked
            setCalendarEventsForWebAllInfo(calendarEventsForWebAllInfo => [...calendarEventsForWebAllInfo, tmpReservationDetailsRetrievedFromAgendaItem]); //add this to the list
          }
        }
      }
      setCalendarEventsForWeb(tmpNewCalendarEventsForWeb);
    }
  }, [agendaItems]);
  
  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow.length > 0)
    {
      if(MyAlertReactNative != null) {
        // Works on both Android and iOS
        MyAlertReactNative.alert(
          messageToShow,
          '',
          [
            {
              text: 'OK',
              onPress: () => {
                setMessageToShow('');
              }
            }
          ],
          { cancelable: false }
        );
      }
    }
  }, [isLoading]);

    
  async function agendaItemOnClicked(tmpSelectedOwnerID: number, selectedDomainValue: string) {
     //find owner 
     var currentSelectedOwner : Owner;
     if(selectedDomainValue == LoginManager.getInstance().STUDIO)
      currentSelectedOwner = await Studio_RestManager.getInstance().getOwnerById(tmpSelectedOwnerID);
     else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
      currentSelectedOwner = await Tennis_RestManager.getInstance().getOwnerById(tmpSelectedOwnerID);
     //-find owner
  
    navigation.navigate('SelectedOwnerSummary', { 
      title: selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('studioInfo') 
              : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourtInfo') 
              : "ERROR", 
      backLabel: i18n.t('back'),
      params: {selectedDomainValue, currentSelectedOwner}
    });
  }

  function renderItem(item) {
    const tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem = UtilsManager.getInstance().parseReservationDetailsFromAgendaItem(item.name);
    
    return (
      <ScrollView style={styles.scrollView}>
        <TouchableOpacity
          testID={calendarAgendaConfig.agenda.ITEM}
          style={[styles.item, {height: item.height}]}
          onPress={() => agendaItemOnClicked(tmpReservationDetailsRetrievedFromAgendaItem.ownerID, tmpReservationDetailsRetrievedFromAgendaItem.itemDomain)}
        >
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('category')}: <Text style={{fontWeight: "normal", color: "brown"}}>{i18n.t('studioCategory')}</Text></Text>}
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('category')}: <Text style={{fontWeight: "normal", color: "green"}}>{i18n.t('tennisCategory')}</Text></Text>}
          <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('date')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationDate}</Text></Text>
          <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('hours')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime} - {tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime}</Text></Text>
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('studio')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.companyName}</Text></Text>}
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('company')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.companyName}</Text></Text>}
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('room')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.roomName}</Text></Text>}
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('tennisCourt')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.courtName}</Text></Text>}
          {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('type')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? i18n.t('rehearsal') : i18n.t('recording')}</Text></Text>}
          <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('totalCost')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationCost}</Text></Text>        
          <Text style={{flexDirection:"row", fontWeight: "bold", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{i18n.t('comments')}: <Text style={{fontWeight: "normal", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationComments}</Text></Text>        
        </TouchableOpacity>
      </ScrollView>
    );
  }

  function renderEmptyDate() {
    return (
      <View style={styles.emptyDate}>
        <Text>This is empty date!</Text>
      </View>
    );
  }

  function rowHasChanged(r1, r2) {
    return r1.name !== r2.name;
  }

  function timeToString(time) {
    const date = new Date(time);
    return date.toISOString().split('T')[0];
  }

  const [agendaIsRefresing, setAgendaIsRefresing] = useState(false);
  const [retrieveNewAgendaItemsNow, setRetrieveNewAgendaItemsNow] = useState(false);
  useEffect(() => {
    console.log("useEffect - START - retrieveNewAgendaItemsNow -> retrieveNewAgendaItemsNow: ", retrieveNewAgendaItemsNow);
    if(retrieveNewAgendaItemsNow==true && Object.entries(agendaItems).length==0) { //this is true when the user pulls down to refresh and "handleRefresh" function is called
      (async () => {
        const tmpAllAgendaItemsToSet = await Generic_RestManager.getInstance().getAllReservationsOfAllDomainsByUserEmail_forReactNativeCalendarsAgenda(LoginManager.getInstance().username);
        setAgendaItems(tmpAllAgendaItemsToSet);
        setRetrieveNewAgendaItemsNow(false);
        if(isLoading == true)
          setIsLoading(false);
        if(agendaIsRefresing == true) 
          setAgendaIsRefresing(false);
      })();
    }
    console.log("useEffect - STOP - retrieveNewAgendaItemsNow");
  }, [retrieveNewAgendaItemsNow]);

  const handleRefresh = (() => {
    setAgendaIsRefresing(true);
    setIsLoading(true);
    setAgendaItems({}); //I need this 2-step process (first make agenda empty and then re-fill it, because otherwise update has problems)
    setRetrieveNewAgendaItemsNow(true);
  });

  const [showTip, setShowTip] = useState(true);
  useEffect(() => {
    console.log("useEffect - START - showTip -> showTip: ", showTip);
    console.log("step5: useEffect [showTip]: ", showTip);
    let timeoutId: number;
    if (showTip == true) {
      timeoutId = setTimeout(() => {
        setShowTip(false);
      }, 3000); // hide tip after 3 seconds
    }
  
    // This cleanup function will be called when the useEffect hook is called again
    // (e.g. when showTip changes)
    console.log("useEffect - STOP - showTip");
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showTip]);

  
  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={[styles.columnMainApp, { alignSelf: 'center' }]}>
            
            <PlayerMenuOptions screenTitle={i18n.t('myReservations')} />
              
              <Spinner
                visible={isLoading}
                textContent={i18n.t('loading')}
                textStyle={styles.spinnerTextStyle}
              />
              {MyCalendarReactNative!=null && <Agenda
                testID={calendarAgendaConfig.agenda.CONTAINER}
                items={agendaItems}
                // Callback that gets called when items for a certain month should be loaded (month became visible)
                loadItemsForMonth={month => {
                  //console.log('trigger items loading - month: ' + JSON.stringify(month, null, 2));
                }}
                // Callback that fires when the calendar is opened or closed
                onCalendarToggled={calendarOpened => {
                  //console.log("calendarOpened: " + calendarOpened);
                }}
                // Callback that gets called on day press
                onDayPress={day => {
                  //console.log('day pressed: ' + JSON.stringify(day, null, 2));
                  setSelectedDate(UtilsManager.getInstance().getSelectedDayTitle(day.day, day.month, day.year, day.timestamp));
                }}
                // Callback that gets called when day changes while scrolling agenda list
                onDayChange={day => {
                  console.log('day changed: ' + JSON.stringify(day, null, 2));
                }}
                //loadItemsForMonth={loadItems.bind(this)}
                selected={moment().format('YYYY-MM-DD').toString()}
                // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
                minDate={'2022-12-01'}
                // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
                maxDate={'2023-01-30'}
                // Max amount of months allowed to scroll to the past. Default = 50
                pastScrollRange={1}
                // Max amount of months allowed to scroll to the future. Default = 50
                futureScrollRange={1}
                hideKnob={false}
                // When `true` and `hideKnob` prop is `false`, the knob will always be visible and the user will be able to drag the knob up and close the calendar. Default = false
                showClosingKnob={true}
                renderItem={renderItem.bind(this)}
                renderEmptyDate={renderEmptyDate.bind(this)}
                rowHasChanged={rowHasChanged.bind(this)}
                renderEmptyData = {() => {return (
                    <View>
                      <Text style={styles.calendarSelectedDayTitle}>{selectedDate}</Text>
                      {isLoading==false && <Text style={{ textAlign: 'center' }}>{i18n.t('youHaveNoReservationForThisDate')}</Text>}
                    </View>
                  );
                }}
                // If provided, a standard RefreshControl will be added for "Pull to Refresh" functionality. Make sure to also set the refreshing prop correctly
                onRefresh={() => console.log('refreshing...')}
                // Set this true while waiting for new data from a refresh
                refreshControl={
                  <RefreshControl
                    refreshing={agendaIsRefresing}
                    onRefresh={handleRefresh}
                    progressViewOffset={40}
                    title={i18n.t('pullDownToUpdate')}
                    titleColor="blue"
                    tintColor="green"
                  />
                }
                // Add a custom RefreshControl component, used to provide pull-to-refresh functionality for the ScrollView
                //refreshControl={null}
                // markingType={'period'}
                // markedDates={{
                //    '2017-05-08': {textColor: '#43515c'},
                //    '2017-05-09': {textColor: '#43515c'},
                //    '2017-05-14': {startingDay: true, endingDay: true, color: 'blue'},
                //    '2017-05-21': {startingDay: true, color: 'blue'},
                //    '2017-05-22': {endingDay: true, color: 'gray'},
                //    '2017-05-24': {startingDay: true, color: 'gray'},
                //    '2017-05-25': {color: 'gray'},
                //    '2017-05-26': {endingDay: true, color: 'gray'}}}
                // monthFormat={'yyyy'}
                 theme={{
                  //...calendarTheme,
                  //agendaDayTextColor: 'yellow',
                  //agendaDayNumColor: 'green',
                  //agendaTodayColor: 'black',
                  agendaKnobColor: 'black'
                }}
                //renderDay={(day, item) => (<Text>{day ? day.day: 'item'}</Text>)}
                // hideExtraDays={false}
              />}
              {MyCalendarForWeb!=null && 
                <View style={styles.webCalendar}>
                  <MyCalendarForWeb
                    localizer={localizer}
                    messages={webCalendarMessages}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={calendarEventsForWeb}
                    onSelectEvent={handleEventClick}
                    components={{
                      event: CustomEvent, // You can style the event component
                    }}
                  />
                </View>
              }

              {Platform.OS!='web' && showTip==true && <Tip />}

              <Portal>
                <Modal style={{ display: isLoading==false ? "flex" : "none" }} visible={visibleSelectedReservationDetailsModal} onDismiss={hideSelectedReservationDetailsModal} contentContainerStyle={styles.modalContainer}>
                  <PlayerSelectedReservationModal navigation={navigation} curReservationDetails={curReservationDetails} hideSelectedReservationDetailsModal={hideSelectedReservationDetailsModal} />
                </Modal>
              </Portal>

          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  };

  // Define a custom event component to style the event title
  const CustomEvent = ({ event }) => (
    <View style={styles.webCalendarItemContainer}>
      {event.title.split('\n').map((line, index) => (
        <View key={index}><Text style={styles.webCalendarItemText}>{line}</Text></View>
      ))}
    </View>
  );

export default PlayerMyReservationsView;
