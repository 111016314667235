import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TextInput } from 'react-native-paper';

const OwnerDropdownFridayFrom = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeFridayStartHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeFridayStartHour_Tennis,
                                
                                setFridayStartHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
          label={i18n.t('from')}
          mode={'outlined'}
          visible={myData_Studio.showFridayStartHour}
          showDropDown={() => setMyData_Studio({...myData_Studio, showFridayStartHour: true})}
          onDismiss={() => setMyData_Studio({...myData_Studio, showFridayStartHour: false})}
          value={myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_working_hours_start=="closed" ? proposeFridayStartHour_Studio : selectedRoom_Studio.friday_working_hours_start}
          setValue={setFridayStartHour}
          list={hoursList}
          inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showFridayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
          label={i18n.t('from')}
          mode={'outlined'}
          visible={myData_Tennis.showFridayStartHour}
          showDropDown={() => setMyData_Tennis({...myData_Tennis, showFridayStartHour: true})}
          onDismiss={() => setMyData_Tennis({...myData_Tennis, showFridayStartHour: false})}
          value={myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_working_hours_start=="closed" ? proposeFridayStartHour_Tennis : selectedCourt_Tennis.friday_working_hours_start}
          setValue={setFridayStartHour}
          list={hoursList}
          inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showFridayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownFridayFrom/*, areEqual*/);