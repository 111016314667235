import React, { useState } from 'react';
import { BottomNavigation } from 'react-native-paper';
import i18n from '../../utils/i18n';
import OwnerBrowseStudiosSubview from './OwnerBrowseStudiosSubview';
import OwnerManageRoomsView from './OwnerManageRoomsView';
import OwnerManagePhotosView from './OwnerManagePhotosView';
import OwnerMyReservationsView from './OwnerMyReservationsView';
import OwnerManagePlayersView from './OwnerManagePlayersView';
import OwnerMenuOptions from './OwnerMenuOptions';
import LoginManager from '../../managers/LoginManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OwnerControlPanelFooter = ({selectedDomainValue, setSelectedDomainValue}) => {
  const [index, setIndex] = useState(0/*GUIManager.getInstance().goToMyProfileSubpageIndex*/);
  const [routes] = useState([
    { key: 'OwnerMyReservations', title: i18n.t('reservations'), icon: ({ color, size }) => (<MaterialCommunityIcons name='calendar' color={color} size={size} />), screenTitle: i18n.t('myReservations') },
    { key: 'OwnerManagePhotos', title: i18n.t('photos'), icon: ({ color, size }) => (<MaterialCommunityIcons name='camera' color={color} size={size} />), screenTitle: i18n.t('managePhotos') },
    { key: 'OwnerManageRooms', title: i18n.t('infrastructure'), icon: ({ color, size }) => (<MaterialCommunityIcons name='home-account' color={color} size={size} />), screenTitle: selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('manageRooms') : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('manageCourts') : "ERROR!" },
    { key: 'OwnerManagePlayers', title: i18n.t('customers'), icon: ({ color, size }) => (<MaterialCommunityIcons name='account-group' color={color} size={size} />), screenTitle: i18n.t('customers') },
    { key: 'OwnerBrowseStudios', title: i18n.t('browse'), icon: ({ color, size }) => (<MaterialCommunityIcons name='earth' color={color} size={size} />), screenTitle: selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('findStudio') : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('findTennisCourt') : "ERROR!" }
  ]);

  const renderScene = BottomNavigation.SceneMap({
    OwnerMyReservations: OwnerMyReservationsView,
    OwnerManagePhotos: OwnerManagePhotosView,
    OwnerManageRooms: OwnerManageRoomsView,
    OwnerManagePlayers: OwnerManagePlayersView,
    OwnerBrowseStudios: () => <OwnerBrowseStudiosSubview selectedDomainValue={selectedDomainValue} setSelectedDomainValue={setSelectedDomainValue} />,
  });

  const customRenderScene = (props) => {
    const { route } = props;

    // Define the title for the screen
    const screenTitle = route.screenTitle;

    return (
      <>
        <OwnerMenuOptions
          screenTitle={screenTitle}
        />
        {renderScene(props)}
      </>
    );
  };

  return (
    <BottomNavigation 
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={customRenderScene}
    />
  );
};

export default OwnerControlPanelFooter;
