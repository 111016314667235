import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextInput, Snackbar, Modal, Button, Text, Portal, Dialog, Paragraph, IconButton, Colors, FAB } from 'react-native-paper';
import { Alert, Dimensions, View, SafeAreaView, Image, Platform, TouchableOpacity, useWindowDimensions } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import Animated, { useAnimatedRef } from 'react-native-reanimated';
import styles from '../../css/myCss';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import OwnerManagePhotosFullScreenModal from './OwnerManagePhotosFullScreenModal';
import UtilsManager from '../../managers/UtilsManager';
import Generic_ConstManager from '../../managers/Generic_ConstManager';

//Image Picker
    //react native
      import * as ImagePicker from 'expo-image-picker';
      import { UIImagePickerPresentationStyle } from 'expo-image-picker/build/ImagePicker.types';
    //web
      import { launchImageLibrary } from 'react-native-image-picker';
//-Image Picker

//Alert
  //react native
    //already imported above
  //web
  //...toDo...

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = null;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//image gallery
  //react native
    import Carousel from 'react-native-snap-carousel';
    import ImageViewer from 'react-native-image-zoom-viewer';
  //web
    import Swiper from 'react-native-web-swiper';
    import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
    import { MaterialCommunityIcons } from '@expo/vector-icons';
    import Lightbox from "yet-another-react-lightbox";
    import "yet-another-react-lightbox/styles.css"; //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
    import Counter from "yet-another-react-lightbox/plugins/counter";
    import "yet-another-react-lightbox/plugins/counter.css"; //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
    import Captions from "yet-another-react-lightbox/plugins/captions";
    import "yet-another-react-lightbox/plugins/captions.css"; //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
    import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
    import "yet-another-react-lightbox/plugins/thumbnails.css"; //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
    import Inline from "yet-another-react-lightbox/plugins/inline";




let MyImageGalleryForReactNative;
let MyImageGalleryForWeb;
if (Platform.OS === 'web') {  //WEB
  MyImageGalleryForReactNative = null;
  MyImageGalleryForWeb = Swiper;
} else {                      //REACT NATIVE
  MyImageGalleryForReactNative = Carousel;
  MyImageGalleryForWeb = null;
}
//-image gallery


const screenDimensions = Dimensions.get('window');

const OwnerManagePhotosView = () => {
  const navigation = useNavigation();
  const aref = useAnimatedRef();
  const windowDimensions = useWindowDimensions();

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const onDismissSnackBar = () => setSnackBarVisible(false);

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerManagePhotosView: I'm ready!");
    setCurrentImageId(-1);
    setSwiperIndex(0);
    setUploadPhotoDescDialogVisible(false);
    setEditPhotoDescDialogVisible(false);
    (async () => {
      setIsLoading(true);
      var tmpAllOwnerPics : Array<PicBase64> = [];
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        tmpAllOwnerPics = await Studio_RestManager.getInstance().getOwnerPics(LoginManager.getInstance().ID);
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        tmpAllOwnerPics = await Tennis_RestManager.getInstance().getOwnerPics(LoginManager.getInstance().ID);
      console.log("studio_getOwnerPics response: " + JSON.stringify(tmpAllOwnerPics, null, 2));
      setMyPhotosArray(tmpAllOwnerPics);

      const tmpPhotosURLsArray = tmpAllOwnerPics.map(tmpPhoto => {
        return tmpPhoto.pic_url
      })
      console.log("tmpPhotosURLsArray: ", tmpPhotosURLsArray);
      setAllPhotosURLs(tmpPhotosURLsArray);

      setIsLoading(false);
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const [myPhotosArray, setMyPhotosArray] = useState<Array<PicBase64>>([]);
  useEffect(() => {
    let tmpImagesList = []/* as Array<PhotoItem>*/;
    let tmpImagesForLightbox = [];
    let tmpCounter: number = 0;
    myPhotosArray.forEach((tmpPhoto, tmpIndex) => {
      let tmpPhotoItem = {} /*as PhotoItem*/;
      if(MyImageGalleryForReactNative!=null && MyImageGalleryForWeb==null) {
        tmpPhotoItem.id = tmpPhoto.id;
        tmpPhotoItem.url = tmpPhoto.pic_url;
        tmpPhotoItem.description = tmpPhoto.pic_description;
      }
      else if(MyImageGalleryForWeb!=null && MyImageGalleryForReactNative==null) {
        tmpPhotoItem.id = tmpPhoto.id;
        tmpPhotoItem.url = tmpPhoto.pic_url;
        tmpPhotoItem.description = tmpPhoto.pic_description;
        tmpPhotoItem.thumbnail = tmpPhoto.pic_url;
        tmpPhotoItem.original = tmpPhoto.pic_url;

        let tmpPhotoItemForLightbox = {};
        tmpPhotoItemForLightbox.src = tmpPhoto.pic_url;
        tmpPhotoItemForLightbox.alt = tmpPhoto.pic_description;
        tmpPhotoItemForLightbox.title = "";
        tmpPhotoItemForLightbox.description = tmpPhoto.pic_description;
        tmpImagesForLightbox.push(tmpPhotoItemForLightbox);
      }
      tmpImagesList.push(tmpPhotoItem);
      tmpCounter++;
    });
    setImages(tmpImagesList);
    setImagesForLightbox(tmpImagesForLightbox);
  }, [myPhotosArray]);

  const [allPhotosURLs, setAllPhotosURLs] = useState([] as any);
  useEffect(() => {
    (async () => {
      if(allPhotosURLs!=undefined) {
        if(allPhotosURLs.length > 0) {
          //if(MyImageGalleryForWeb!=null && MyImageGalleryForReactNative==null) {
            var tmpPhotosDimensions = [];
            for(var i=0; i<allPhotosURLs.length; i++) {
              var tmpDimensions = await UtilsManager.getInstance().getImageDimensions(allPhotosURLs[i]);
              tmpPhotosDimensions[i] = {
                width: tmpDimensions.width,
                height: tmpDimensions.height
              };
            }   
            setAllPhotosDimensions(tmpPhotosDimensions);  
          //}
        }
      }
    })();
  }, [allPhotosURLs]);

  const [allPhotosDimensions, setAllPhotosDimensions] = useState<{ width: number; height: number; }[]>([]);
  useEffect(() => {
    //complete photos array
    var tmpPhotosArray = [];
    if(allPhotosURLs!=undefined && allPhotosDimensions!=undefined) {
      if(allPhotosURLs.length>0 && allPhotosDimensions.length>0) {
        for(var i=0; i<allPhotosURLs.length; i++) {
          if(MyImageGalleryForReactNative!=null && MyImageGalleryForWeb==null)
            tmpPhotosArray[i] = {
              url: allPhotosURLs[i],
              description: myPhotosArray[i].pic_description!=undefined ? myPhotosArray[i].pic_description : ""
            };
          else if(MyImageGalleryForWeb!=null && MyImageGalleryForReactNative==null) {
            const tmpImageScale = UtilsManager.getInstance().calculateImageScale(Generic_ConstManager.getInstance().WEB_MAX_WIDTH, 700, allPhotosDimensions[i].width, allPhotosDimensions[i].height, 5);
            tmpPhotosArray[i] = {
              description: myPhotosArray[i].pic_description!=undefined ? myPhotosArray[i].pic_description : "",
              thumbnail: allPhotosURLs[i],
              original: allPhotosURLs[i],
              width: allPhotosDimensions[i].width,
              height: allPhotosDimensions[i].height,
              initialScale: tmpImageScale.initialScale,
              maxScale: tmpImageScale.maxScale
            };
          }
        }
      }
    }
    setOwnerPics(tmpPhotosArray);
    console.log("tmpPhotosArray / ownerPics: ", tmpPhotosArray);
  }, [allPhotosDimensions]);

  const [ownerPics, setOwnerPics] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  // The path of the picked image
  const [pickedImagePath, setPickedImagePath] = useState('');
  const [pickedImageBase64, setPickedImageBase64] = useState('');
  useEffect(() => {
    if(pickedImagePath!=undefined && pickedImageBase64!='') {
      setUploadPhotoDescDialogVisible(true);
    }
  }, [pickedImageBase64]);

  async function uploadPhotoAsync() {
    setIsLoading(true);
    const tmpPhotoToBeUploaded : PicBase64 = {
      id: -1,
      owner_id: LoginManager.getInstance().ID,
      base64_encoded_str: pickedImageBase64,   //there is no corresponding field in DB
      pic_description: photoDesc,
      local_filepath_on_server: '',
      pic_url: ''
    }
    var tmpNewPhotos: Array<PicBase64> = [];
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      tmpNewPhotos = await Studio_RestManager.getInstance().uploadPhoto(tmpPhotoToBeUploaded);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      tmpNewPhotos = await Tennis_RestManager.getInstance().uploadPhoto(tmpPhotoToBeUploaded);
    setMyPhotosArray(tmpNewPhotos as Array<PicBase64>);
    setPhotoDesc('');
    setIsLoading(false);
    setSnackBarText(i18n.t('photoUploaded'));
    setSnackBarVisible(true);
  }

  // This function is triggered when the "Select an image" button pressed
  const showImagePicker = async () => {
    if (Platform.OS === 'web') {  //WEB
      const options = {
        includeBase64: true,
        mediaType: 'photo'
      };
  
      const result = await launchImageLibrary(options);

      // Explore the result
      console.log('WEB - launchImageLibraryAsync (from library) result: ' + JSON.stringify(result));

      if(!result.didCancel && result.assets && result.assets[0] && result.assets[0].base64) {
        setPickedImagePath(result.assets[0].uri);
        setPickedImageBase64(result.assets[0].base64);
        //console.log(result.assets[0].uri);
      }
      else {
        console.log("Image selection cancelled or no image selected.");
      }
    } //MOBILE
    else {
      // Ask the user for the permission to access the media library 
      const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

      if (permissionResult.granted === false) {
        if(MyAlertReactNative != null)
          alert("You've refused to allow HandyBooker to access your photos!");
        return;
      }
      else 
        console.log("You've ACCEPTED to allow HandyBooker to access your photos!");

      const result = await ImagePicker.launchImageLibraryAsync({
        //presentationStyle: UIImagePickerPresentationStyle.FULL_SCREEN,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        base64: true
      });

      // Explore the result
      console.log('launchImageLibraryAsync (from library) result: ' + JSON.stringify(result));

      if(!result.canceled && result.assets && result.assets[0] && result.assets[0].base64) {
        setPickedImagePath(result.assets[0].uri);
        setPickedImageBase64(result.assets[0].base64);
        console.log(result.assets[0].uri);
      }
      else {
        console.log("Image selection cancelled or no image selected.");
      }
    }
  }

  // This function is triggered when the "Open camera" button pressed
  const openCamera = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      if(MyAlertReactNative != null)
        alert("You've refused to allow HandyBooker to access your camera!");
      return;
    }
    else 
      console.log("You've ACCEPTED to allow HandyBooker to access your camera!");

    const result = await ImagePicker.launchCameraAsync({
      presentationStyle: UIImagePickerPresentationStyle.FULL_SCREEN,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      //allowsEditing: true,
      base64: true
    });

    // Explore the result
    console.log('launchCameraAsync (from camera) result: ' + result);

    if(!result.canceled && result.assets && result.assets[0] && result.assets[0].base64) {
      setPickedImagePath(result.assets[0].uri);
      setPickedImageBase64(result.assets[0].base64);
      console.log(result.assets[0].uri);
    }
  }
    
  const [currentImageId, setCurrentImageId] = useState(-1);
  const [images, setImages] = useState/*<Array<PhotoItem>>*/([]);
  const [imagesForLightbox, setImagesForLightbox] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const [uploadPhotoDescDialogVisible, setUploadPhotoDescDialogVisible] = useState(false);
  useEffect(() => {
    if(pickedImageBase64!=undefined && pickedImageBase64!='' && uploadPhotoDescDialogVisible==false) {
      console.log("Anevazw pic! pickedImageBase64: " + pickedImageBase64);
      uploadPhotoAsync();
    }
    else {
      console.log("DEN Anevazw pic!");
      console.log("pickedImageBase64 undefined or empty (nothing to be uploaded) : " + pickedImageBase64);
    }
  }, [uploadPhotoDescDialogVisible]);
  const [editPhotoDescDialogVisible, setEditPhotoDescDialogVisible] = useState(false);
  
  const onRequestCloseAfterPhotoDeletion = () => { 
    setIsVisible(false); 
    setAlreadyRunOnceOnViewLoad(!alreadyRunOnceOnViewLoad);
    setSnackBarText(i18n.t('photoDeleted'));
    setSnackBarVisible(true);
  };

  function setPhotoDescriptionFunc() {
    setUploadPhotoDescDialogVisible(false);
  }

  const [index, setIndex] = useState(0);

  const handleIndexChange = (index) => {
    setIndex(index);
  };

  const [swiperIndex, setSwiperIndex] = useState(0);
  useEffect(() => {
    console.log("useEffect - swiperIndex:", swiperIndex);
  }, [swiperIndex]);

  const handleSwiperIndexChange = (index) => {
    setSwiperIndex(index);
  };

  //image gallery - react native
  const ITEM_WIDTH = screenDimensions.width * 0.7;
  const ITEM_HEIGHT = screenDimensions.height * 0.44;
  const [fullScreenPicModalVisible, setFullScreenPicModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => { 
        console.log("I've pressed -> item.url: " + item.url);
        setFullScreenPicModalVisible(true); 
        setSelectedImage(item);
      }}>
        <Image style={{width: ITEM_WIDTH, height: ITEM_HEIGHT, borderRadius: 8, marginTop: 20}} 
          source={{ uri: item.url }} />
      </TouchableOpacity>
    );
  };

  /*const renderIndicator = (currentIndex, allSize) => {
    const currentImage = images[currentIndex-1];
    return (
      <View style={{ position: 'absolute', bottom: 0, width: screenDimensions.width, alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.6)', paddingBottom: 20 }}>
        <Text style={{ color: '#fff', paddingBottom: 5 }}>{currentIndex}/{allSize}</Text>
        <Text style={{ color: '#fff', paddingBottom: 5 }}>{currentImage!=undefined && currentImage.description!=undefined ? currentImage.description : ""}</Text>
      </View>
    );
  };*/
  
  const handleImageClickOnWeb = (item) => {
    console.log("I've pressed -> item.url: " + item.original);
    setFullScreenPicModalVisible(true);
    setSelectedImage(item);
  };
    
  

  const [photoDesc, setPhotoDesc] = useState('');
  useEffect(() => {
    setNewPhotoDesc(photoDesc);
  }, [photoDesc]);
    
  const [newPhotoDesc, setNewPhotoDesc] = useState('');
  useEffect(() => {
    if(newPhotoDesc != photoDesc) {
      setSaveBtnDisabled(false);
    }
    else {
      setSaveBtnDisabled(true);
    }
  }, [newPhotoDesc]);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  
  const [photoEditedSnackBarVisible, setPhotoEditedSnackBarVisible] = useState(false);
  const onDismissPhotoEditedSnackBar = () => setPhotoEditedSnackBarVisible(false);

  const [deletePhotoDialogVisible, setDeletePhotoDialogVisible] = useState(false);
  const hideDeletePhotoDialog = () => setDeletePhotoDialogVisible(false);

  async function deletePhotoAsync(tmpID) {     
    setDeletePhotoDialogVisible(false);
    setFullScreenPicModalVisible(false);
    setIsLoading(true);
    var tmpNewPhotos: Array<PicBase64> = [];
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      tmpNewPhotos = await Studio_RestManager.getInstance().deleteOwnerPic(tmpID);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      tmpNewPhotos = await Tennis_RestManager.getInstance().deleteOwnerPic(tmpID);

    setMyPhotosArray(tmpNewPhotos as Array<PicBase64>);  

    setIsLoading(false);
    onRequestCloseAfterPhotoDeletion();
  }

  async function editPhotoDescAsync(tmpID) {     
    setIsLoading(true);  
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      await Studio_RestManager.getInstance().editOwnerPicDescription(tmpID, newPhotoDesc);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      await Tennis_RestManager.getInstance().editOwnerPicDescription(tmpID, newPhotoDesc);
    setIsLoading(false);
    setSaveBtnDisabled(true);
    setEditPhotoDescDialogVisible(false);
    setPhotoEditedSnackBarVisible(true);
    setPhotoDesc(newPhotoDesc);
  }
  //-image gallery - react native

  const captionsRef = useRef(null);
  const thumbnailsRef = useRef(null);
  // Create a ref for the TextInput components
  const uploadPhotoDescInputRef = useRef(null);
  const editPhotoDescInputRef = useRef(null);
 
    return (
      <View style={[styles.columnMainApp, { alignSelf: 'center' }]}>
          <Spinner
            visible={isLoading}
            textContent={i18n.t('loading')}
            textStyle={styles.spinnerTextStyle}
          />
          <Portal>          
            <Modal style={{ position: "absolute", top: -30 }} visible={fullScreenPicModalVisible && selectedImage!=null && selectedImage!=undefined} 
                    onDismiss={() => setFullScreenPicModalVisible(false)}  
                    contentContainerStyle={{ 
                      top: Platform.OS==='web' ? 50 : 0,
                      left: Platform.OS==='web' ? (windowDimensions.width-Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH)/2 : 0,
                      width: Platform.OS==='web' ? Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH : screenDimensions.width, 
                      height: Platform.OS==='web' ? windowDimensions.height-100 : screenDimensions.height, 
                    }} 
            >
              {MyImageGalleryForReactNative!=null && <ImageViewer
                  style={{ margin: 0 }}
                  imageUrls={images.map(pic => ({ url: pic.url }))}
                  index={images.findIndex(pic => pic.url === selectedImage?.url)}
                  enableSwipeDown={true}
                  onSwipeDown={() => setFullScreenPicModalVisible(false)}
                  renderIndicator={(currentIndex, allSize) => <OwnerManagePhotosFullScreenModal 
                                                                  currentIndex={currentIndex}
                                                                  allSize={allSize}
                                                                  images={images}
                                                                  currentImageId={currentImageId}
                                                                  setCurrentImageId={setCurrentImageId}
                                                                  setPhotoDesc={setPhotoDesc}
                                                                  newPhotoDesc={newPhotoDesc} 
                                                                  setNewPhotoDesc={setNewPhotoDesc}
                                                                  saveBtnDisabled={saveBtnDisabled}
                                                                  editPhotoDescAsync={editPhotoDescAsync}
                                                                  deletePhotoDialogVisible={deletePhotoDialogVisible}
                                                                  setDeletePhotoDialogVisible={setDeletePhotoDialogVisible}
                                                                  hideDeletePhotoDialog={hideDeletePhotoDialog}
                                                                  deletePhotoAsync={deletePhotoAsync}
                                                                  photoEditedSnackBarVisible={photoEditedSnackBarVisible}
                                                                  onDismissPhotoEditedSnackBar={onDismissPhotoEditedSnackBar}
                                                              />}
              />}
            </Modal>
          </Portal>
        
          {MyImageGalleryForReactNative!=null && 
            <View>
              <View style={[styles.buttonContainer, {alignContent: 'flex-start'}]}>
                <Button style={styles.btn} icon={({ color, size }) => (<MaterialCommunityIcons name="camera-image" color={color} size={size} />)} mode="outlined" onPress={showImagePicker}>{i18n.t('fromLibrary')}</Button>
                <Button style={styles.btn} icon={({ color, size }) => (<MaterialCommunityIcons name="camera-plus" color={color} size={size} />)} mode="outlined" onPress={openCamera}>{i18n.t('fromCamera')}</Button>
              </View>
            </View>
          }
          <View style={{display: (images==undefined || images==null || images.length==0 || myPhotosArray==undefined || myPhotosArray==null || myPhotosArray.length==0) && isLoading==false ? 'flex' : 'none', alignContent: 'center', alignItems: 'center', justifyContent: 'center', alignSelf: 'center', width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*0.7, height: Generic_ConstManager.getInstance().WEB_MAX_HEIGHT*0.7 }}>
            <Text style={{alignSelf: 'center', textAlign: 'center', fontWeight: 'bold', color: 'red'}}>{i18n.t('noUploadedPhotosYet')}</Text>
          </View>
          <FAB
            style={{position: 'absolute', left: 15, top: 30, zIndex: 1, elevation: 1, backgroundColor: '#6200EE' }}
            icon={({ color, size }) => (<MaterialCommunityIcons name="camera-plus" color={color} size={size} />)}
            onPress={showImagePicker}
          />
          <SafeAreaView style={styles.rootPhotos}>
            {MyImageGalleryForReactNative!=null && 
              <View style={{flex: 1,
                backgroundColor: '#fff',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
                marginBottom: 10,
                display: ((images!=undefined && images!=null && images.length>0) || (myPhotosArray!=undefined && myPhotosArray!=null && myPhotosArray.length>0)) ? 'flex' : 'none'
              }}>
                <MyImageGalleryForReactNative
                  data={images}
                  renderItem={renderItem}
                  sliderWidth={screenDimensions.width}
                  itemWidth={ITEM_WIDTH}
                  inactiveSlideScale={0.9}
                  inactiveSlideOpacity={0.7}
                />             
              </View>
            }
            {0>1 && MyImageGalleryForWeb!=null && ownerPics!=undefined && ownerPics.length>0 && 
              <View style={{width: '100%', height: 700}}>
                <Swiper key={JSON.stringify(allPhotosDimensions) + JSON.stringify(ownerPics)} index={swiperIndex} onIndexChanged={(tmpIndex) => {handleSwiperIndexChange(tmpIndex)}}
                  from={0}
                    minDistanceForAction={0.1}
                    controlsProps={{
                      dotsTouchable: true,
                      prevPos: 'left',
                      nextPos: 'right',
                      nextTitle: '>',
                      nextTitleStyle: { color: 'white', backgroundColor: 'black', fontSize: 35, fontWeight: '500', paddingBottom: 8, paddingLeft: 4, paddingRight: 4 },
                      PrevComponent: ({ onPress }) => (
                        <TouchableOpacity onPress={onPress}>
                          <Text style={{ color: 'white', backgroundColor: 'black', fontSize: 35, fontWeight: '500', paddingTop: 4, paddingBottom: 4, paddingLeft: 5, paddingRight: 8 }}>
                            {'<'}
                          </Text>
                        </TouchableOpacity>
                      ),
                    }}
                >
                  {ownerPics.map((image, index) => (
                    <View key={JSON.stringify(allPhotosDimensions[index]) + JSON.stringify(ownerPics[index])} style={{ flex: 1 }}>
                      <TouchableOpacity onPress={() => handleImageClickOnWeb(image)}>
                        <TransformWrapper
                          initialScale={image.initialScale}
                          minScale={image.initialScale}
                          maxScale={image.maxScale}
                          initialPositionX={Generic_ConstManager.getInstance().WEB_MAX_WIDTH/2 - (image.initialScale*image.width)/2}
                          initialPositionY={700/2 - (image.initialScale*image.height)/2}
                          disablePadding
                        >
                          <TransformComponent wrapperStyle={{ width: "100%", height: 700 }}>
                            <img src={image.original} alt={image.description} />
                          </TransformComponent>
                        </TransformWrapper>
                      </TouchableOpacity>
                      {image.description!=undefined && image.description!='' && <Text style={[styles.picDesc, {width: image.initialScale*image.width}]}>{image.description}</Text>}
                    </View>
                  ))}
                </Swiper>
              </View>
            }
            {MyImageGalleryForWeb!=null && myPhotosArray!=undefined && myPhotosArray.length>0 && 
              <View style={{ backgroundColor: 'yellow', width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH, height: windowDimensions.height-265 }}>
                <Lightbox
                  open={fullScreenPicModalVisible}
                  close={() => setFullScreenPicModalVisible(false)}
                  slides={imagesForLightbox}
                  index={swiperIndex}
                  plugins={[Counter, Captions, Thumbnails, Inline]}
                  counter={{ container: { style: { width: Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH, top: 0, alignItems: 'center', display: 'flex', justifyContent: 'center' } } }}
                  captions={{ ref: captionsRef, descriptionTextAlign: 'center', descriptionMaxLines: 3 }}
                  thumbnails={{ ref: thumbnailsRef }}
                  on={{
                    click: () => {
                      (thumbnailsRef.current?.visible
                        ? thumbnailsRef.current?.hide
                        : thumbnailsRef.current?.show)?.();
                    },
                    view: ({ index: currentIndex }) => setSwiperIndex(currentIndex)
                  }}
                />
                <FAB
                  style={{position: 'absolute', right: 85, top: 0, zIndex: 1, elevation: 1, backgroundColor: '#6200EE' }}
                  icon={({ color, size }) => (<MaterialCommunityIcons name="file-document-edit" color={color} size={size} />)}
                  onPress={() => { 
                    setPhotoDesc(imagesForLightbox[swiperIndex].description); 
                    setEditPhotoDescDialogVisible(true); 
                  }}
                />
                <FAB
                  style={{position: 'absolute', marginRight: 15, right: 0, top: 0, zIndex: 1, elevation: 1, backgroundColor: 'red' }}
                  icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                  onPress={() => { setDeletePhotoDialogVisible(true); }}
                />
              </View>
            }
          </SafeAreaView>
        
        <Modal style={styles.modalPhotoDesc} visible={uploadPhotoDescDialogVisible}>
          <TouchableOpacity onPress={() => uploadPhotoDescInputRef.current.focus()}>
            <TextInput
              ref={uploadPhotoDescInputRef}
              label={i18n.t('photoDescOptional')}
              value={photoDesc}
              onChangeText={tmpPhotoDesc => setPhotoDesc(tmpPhotoDesc)} 
            />
          </TouchableOpacity>
          <Button style={{ backgroundColor: 'white' }} onPress={setPhotoDescriptionFunc} icon={({ color, size }) => (<MaterialCommunityIcons name="check" color={color} size={size} />)}>Ok</Button>
        </Modal>

        <Modal style={styles.modalPhotoDesc} visible={editPhotoDescDialogVisible} onDismiss={() => setEditPhotoDescDialogVisible(false)}>
          <TouchableOpacity onPress={() => editPhotoDescInputRef.current.focus()}>
            <TextInput
              ref={editPhotoDescInputRef}
              label={i18n.t('photoDescOptional')}
              value={photoDesc}
              onChangeText={tmpPhotoDesc => setPhotoDesc(tmpPhotoDesc)} 
            />
          </TouchableOpacity>
          <Button style={{ backgroundColor: 'white' }} onPress={() => {
                                  editPhotoDescAsync(myPhotosArray[swiperIndex].id); 
                                  setImagesForLightbox(imagesForLightbox.map((item, index) => (index === swiperIndex ? { ...item, description: newPhotoDesc } : item))); 
                                  setSnackBarText(i18n.t('photoDescUpdated'));
                                  setSnackBarVisible(true);
                                }} 
                                icon={({ color, size }) => (<MaterialCommunityIcons name="check" color={color} size={size} />)}>Ok</Button>
        </Modal>

        <Portal>
            <Modal animationType="fade"
                    transparent={true}
                    visible={deletePhotoDialogVisible}
                    onRequestClose={() => { setDeletePhotoDialogVisible(false); }}>
              <Dialog style={styles.dialog} visible={deletePhotoDialogVisible} onDismiss={hideDeletePhotoDialog}>
                  <Dialog.Title>{i18n.t('delete')}</Dialog.Title>
                  <Dialog.Content>
                  <Paragraph>{i18n.t('areYouSure')}</Paragraph>
                  </Dialog.Content>
                  <Dialog.Actions>
                  <Button onPress={() => setDeletePhotoDialogVisible(false)}>{i18n.t('cancel')}</Button>
                  <Button onPress={() => deletePhotoAsync(myPhotosArray[swiperIndex].id)}>Ok</Button>
                  </Dialog.Actions>
              </Dialog>
            </Modal>
        </Portal>

        <Snackbar
          style={styles.snackbar}
          visible={snackBarVisible}
          onDismiss={onDismissSnackBar}>
          {snackBarText}
        </Snackbar>
      </View>
    );
  };

export default OwnerManagePhotosView;
