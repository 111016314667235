import * as React from 'react';
import { View } from 'react-native';
import PlayerControlPanelFooter from './PlayerControlPanelFooter'
import AdBanner from '../generic/AdBanner';
import styles from '../../css/myCss';
import { useState } from 'react';
import RightColumnWeb from '../generic/RightColumnWeb';
import LeftColumnWeb from '../generic/LeftColumnWeb';


const PlayerControlPanelView = ({route}) => {
  const [selectedDomainValue, setSelectedDomainValue] = useState(route.params.selectedDomainValue);

  return (
      <View style={styles.container}>
        <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
          <LeftColumnWeb/>
          <PlayerControlPanelFooter 
                selectedDomainValue={selectedDomainValue}
                setSelectedDomainValue={setSelectedDomainValue}
          ></PlayerControlPanelFooter>
          <RightColumnWeb/>
        </View>
        <AdBanner />
      </View>
  );
};

export default PlayerControlPanelView;