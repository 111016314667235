import React from "react";
import { BannerAd, BannerAdSize, TestIds } from "react-native-google-mobile-ads";
import { Provider, Subheading, TextInput, Button, ActivityIndicator, Divider, Text, List } from 'react-native-paper';
import styles from "../../css/myCss";
import { Platform, TouchableOpacity, View, Image } from "react-native";
import * as Linking from 'expo-linking';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import i18n from "../../utils/i18n";

const appStoreLogo =  require("../../assets/appStore.png");
const googlePlayLogo =  require("../../assets/googlePlay.png");

let MyLeftColumn;
if (Platform.OS === 'web') {  //WEB
        MyLeftColumn = () =>    <View style={styles.columnLeft}>
                                        <TouchableOpacity
                                                onPress={() => { 
                                                        console.log("App Store clicked!");
                                                }
                                        }>
                                                <Image
                                                        source={appStoreLogo}
                                                        accessibilityLabel="Download our iPhone/iPad app from the Apple Store"
                                                        style={{ width: 120, height: 40, marginBottom: 20 }}
                                                />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                                onPress={() => { 
                                                        console.log("Google Play clicked!");
                                                }
                                        }>
                                                <Image
                                                        source={googlePlayLogo}
                                                        accessibilityLabel="Download our Android app from the Google Play"
                                                        style={{ width: 120, height: 36 }}
                                                />
                                        </TouchableOpacity>
                                </View>;
} else {                      //REACT NATIVE
        MyLeftColumn = () => <View style={{ display: 'none' }}></View>
}

const LeftColumnWeb = () => {
     return (
        <MyLeftColumn/>
     );
    };
   
export default LeftColumnWeb; 