import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TextInput } from 'react-native-paper';

const OwnerDropdownMondayFrom = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeMondayStartHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeMondayStartHour_Tennis,
                                
                                setMondayStartHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Studio.showMondayStartHour}
        showDropDown={() => setMyData_Studio({...myData_Studio, showMondayStartHour: true})}
        onDismiss={() => setMyData_Studio({...myData_Studio, showMondayStartHour: false})}
        value={myData_Studio.mondayOpen=="true" && selectedRoom_Studio.monday_working_hours_start=="closed" ? proposeMondayStartHour_Studio : selectedRoom_Studio.monday_working_hours_start}
        setValue={setMondayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showMondayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Tennis.showMondayStartHour}
        showDropDown={() => setMyData_Tennis({...myData_Tennis, showMondayStartHour: true})}
        onDismiss={() => setMyData_Tennis({...myData_Tennis, showMondayStartHour: false})}
        value={myData_Tennis.mondayOpen=="true" && selectedCourt_Tennis.monday_working_hours_start=="closed" ? proposeMondayStartHour_Tennis : selectedCourt_Tennis.monday_working_hours_start}
        setValue={setMondayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showMondayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownMondayFrom/*, areEqual*/);