import moment from "moment";
import Generic_ConstManager from "./Generic_ConstManager";
import GUIManager from "./GUIManager";
import LoginManager from "./LoginManager";
import UtilsManager from "./UtilsManager";

class Studio_RestManager {

    private static instance: Studio_RestManager;

    private constructor() {
        
    }

    public static getInstance(): Studio_RestManager {
        if (!Studio_RestManager.instance) {
          Studio_RestManager.instance = new Studio_RestManager();
        }

        return Studio_RestManager.instance;
    }

    public initialize() {

    }

    public async login(tmpEncryptedToken: string) : Promise<RoleIDAndDomain>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_login', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              encryptedToken: tmpEncryptedToken
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            //alert("Role: " + responseJson.role + ", ID: " + responseJson.ID + ", verified: " + responseJson.verified);
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async addPlayer(tmpPlayer : Studio_Player) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addPlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpPlayer.id,
            name: tmpPlayer.name,
            surname: tmpPlayer.surname,
            telephone: tmpPlayer.telephone,
            email: tmpPlayer.email.toLowerCase(),
            password: tmpPlayer.password,
            last_login: tmpPlayer.last_login,
            language: tmpPlayer.language,
            email_notifications: tmpPlayer.email_notifications,
            registration_date: tmpPlayer.registration_date,
            verified: tmpPlayer.verified,    //verified is when he succesfully adds the verification code sent by HandyBooker
            band_name: tmpPlayer.band_name
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("addPlayer response:", responseStr);
          return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async getPlayerByEmail(tmpEmail: string) : Promise<Studio_Player>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getPlayer', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: LoginManager.getInstance().username.toLowerCase(),
                password: LoginManager.getInstance().md5EncodedPassword,
                language: LoginManager.getInstance().language,
                serviceInput: {
                    email: tmpEmail.toLowerCase()
                }
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });      
    }

    public async getPlayerByID(tmpMusicianID: number) : Promise<Studio_Player> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getPlayerById', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
                id: tmpMusicianID
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async getAllPlayersOfAnOwner(tmpOwnerID: number) : Promise<PlayerWithBlockingStatus> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllPlayersOfAnOwner', {
          method: 'POST',
          headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
              id: tmpOwnerID
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
          return responseJson;
      })
      .catch(error => {
          console.error(error);
      });
    }

    public async editPlayer(tmpPlayer: Studio_Player) : Promise<Studio_Player> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_editPlayer', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                id: tmpPlayer.id,
                name: tmpPlayer.name,
                surname: tmpPlayer.surname,
                telephone: tmpPlayer.telephone,
                email: tmpPlayer.email.toLowerCase(),
                password: tmpPlayer.password,
                last_login: tmpPlayer.last_login,
                language: tmpPlayer.language,
                email_notifications: tmpPlayer.email_notifications,
                registration_date: tmpPlayer.registration_date,
                verified: tmpPlayer.verified,
                band_name: tmpPlayer.band_name
              }
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async deletePlayer(tmpEmail : string) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_deletePlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            email: tmpEmail.toLowerCase()
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("deletePlayer response:", responseStr);
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async addOwner(tmpOwner : Owner) : Promise<RoleIDAndDomain> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addOwner', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpOwner.id,
            name: tmpOwner.name,
            surname: tmpOwner.surname,
            telephone: tmpOwner.telephone,
            email: tmpOwner.email.toLowerCase(),
            password: tmpOwner.password,
            company_name: tmpOwner.company_name,
            postal_address: tmpOwner.postal_address,
            city: tmpOwner.city,
            country: tmpOwner.country,
            longitude: tmpOwner.longitude,
            latitude: tmpOwner.latitude,
            website: tmpOwner.website,
            last_login: tmpOwner.last_login,
            language: tmpOwner.language,
            email_notifications: tmpOwner.email_notifications,
            registration_date: tmpOwner.registration_date,
            verified: tmpOwner.verified,   //verified is when he succesfully adds the verification code sent by HandyBooker
            active: tmpOwner.active,       //when active==false, this means that I manually created the user
            info_source: tmpOwner.info_source
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }
    
      public async getOwner(tmpEmail: string) : Promise<Owner>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              email: tmpEmail.toLowerCase()
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
      }

      public async getOwnerById(tmpOwnerId: number) : Promise<Owner>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getOwnerById', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpOwnerId
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
          console.log("studio_getOwnerById response:", responseJson);
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
      }

      public async editOwner(tmpOwner : Owner) : Promise<Owner> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_editOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpOwner.id,
              name: tmpOwner.name,
              surname: tmpOwner.surname,
              telephone: tmpOwner.telephone,
              email: tmpOwner.email.toLowerCase(),
              password: tmpOwner.password,
              company_name: tmpOwner.company_name,
              postal_address: tmpOwner.postal_address,
              city: tmpOwner.city,
              country: tmpOwner.country,
              longitude: tmpOwner.longitude,
              latitude: tmpOwner.latitude,
              website: tmpOwner.website,
              last_login: tmpOwner.last_login,
              language: tmpOwner.language,
              email_notifications: tmpOwner.email_notifications,
              registration_date: tmpOwner.registration_date,
              verified: tmpOwner.verified,    //verified is when he succesfully adds the verification code sent by HandyBooker
              active: tmpOwner.active,        //when active==false, this means that I manually created the user
              info_source: tmpOwner.info_source
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
          console.error(error);
        });
      }

      public async deleteOwner(tmpEmail : string) : Promise<string | void> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_deleteOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              email: tmpEmail.toLowerCase()
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("deleteOwner response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
      }
    
      public async getAllOwners() : Promise<Array<Owner>> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllOwners', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            console.log("studio_getAllOwners -> LoginManager.getInstance().username.toLowerCase(): ", LoginManager.getInstance().username.toLowerCase(), ", LoginManager.getInstance().md5EncodedPassword: ", LoginManager.getInstance().md5EncodedPassword, ", LoginManager.getInstance().language: ", LoginManager.getInstance().language, " ->  returned: ", responseJson);
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
      }

    public async getRoom(tmpRoomID: number) : Promise<Studio_Room> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getRoom', {
            method: 'POST',
            headers: {
                Accept: 'application/json; charset=UTF-8',
                        'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: LoginManager.getInstance().username.toLowerCase(),
                password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
                id: tmpRoomID
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async addRoom(tmpRoom : Studio_Room) : Promise<Studio_Room> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addRoom', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpRoom.id,
            owned_by: tmpRoom.owned_by,
            name: tmpRoom.name,
            currency: tmpRoom.currency,
            monday_cost_per_hour_reh: tmpRoom.monday_cost_per_hour_reh,
            monday_cost_per_hour_rec: tmpRoom.monday_cost_per_hour_rec,
            tuesday_cost_per_hour_reh: tmpRoom.tuesday_cost_per_hour_reh,
            tuesday_cost_per_hour_rec: tmpRoom.tuesday_cost_per_hour_rec,
            wednesday_cost_per_hour_reh: tmpRoom.wednesday_cost_per_hour_reh,
            wednesday_cost_per_hour_rec: tmpRoom.wednesday_cost_per_hour_rec,
            thursday_cost_per_hour_reh: tmpRoom.thursday_cost_per_hour_reh,
            thursday_cost_per_hour_rec: tmpRoom.thursday_cost_per_hour_rec,
            friday_cost_per_hour_reh: tmpRoom.friday_cost_per_hour_reh,
            friday_cost_per_hour_rec: tmpRoom.friday_cost_per_hour_rec,
            saturday_cost_per_hour_reh: tmpRoom.saturday_cost_per_hour_reh,
            saturday_cost_per_hour_rec: tmpRoom.saturday_cost_per_hour_rec,
            sunday_cost_per_hour_reh: tmpRoom.sunday_cost_per_hour_reh,
            sunday_cost_per_hour_rec: tmpRoom.sunday_cost_per_hour_rec,
            monday_working_hours_start: tmpRoom.monday_working_hours_start,
            tuesday_working_hours_start: tmpRoom.tuesday_working_hours_start,
            wednesday_working_hours_start: tmpRoom.wednesday_working_hours_start,
            thursday_working_hours_start: tmpRoom.thursday_working_hours_start,
            friday_working_hours_start: tmpRoom.friday_working_hours_start,
            saturday_working_hours_start: tmpRoom.saturday_working_hours_start,
            sunday_working_hours_start: tmpRoom.sunday_working_hours_start,
            monday_working_hours_stop: tmpRoom.monday_working_hours_stop,
            tuesday_working_hours_stop: tmpRoom.tuesday_working_hours_stop,
            wednesday_working_hours_stop: tmpRoom.wednesday_working_hours_stop,
            thursday_working_hours_stop: tmpRoom.thursday_working_hours_stop,
            friday_working_hours_stop: tmpRoom.friday_working_hours_stop,
            saturday_working_hours_stop: tmpRoom.saturday_working_hours_stop,
            sunday_working_hours_stop: tmpRoom.sunday_working_hours_stop,
            space: tmpRoom.space,
            speakers: tmpRoom.speakers,
            console: tmpRoom.console,
            microphones: tmpRoom.microphones,
            drums: tmpRoom.drums,
            guitars: tmpRoom.guitars,
            basses: tmpRoom.basses,
            pianos_keyboards: tmpRoom.pianos_keyboards,
            guitar_amplifiers: tmpRoom.guitar_amplifiers,
            bass_amplifiers: tmpRoom.bass_amplifiers,
            other_equipment: tmpRoom.other_equipment
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async editRoom(tmpRoom : Studio_Room) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_editRoom', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpRoom.id,
            owned_by: tmpRoom.owned_by,
            name: tmpRoom.name,
            currency: tmpRoom.currency,
            monday_cost_per_hour_reh: tmpRoom.monday_cost_per_hour_reh,
            monday_cost_per_hour_rec: tmpRoom.monday_cost_per_hour_rec,
            tuesday_cost_per_hour_reh: tmpRoom.tuesday_cost_per_hour_reh,
            tuesday_cost_per_hour_rec: tmpRoom.tuesday_cost_per_hour_rec,
            wednesday_cost_per_hour_reh: tmpRoom.wednesday_cost_per_hour_reh,
            wednesday_cost_per_hour_rec: tmpRoom.wednesday_cost_per_hour_rec,
            thursday_cost_per_hour_reh: tmpRoom.thursday_cost_per_hour_reh,
            thursday_cost_per_hour_rec: tmpRoom.thursday_cost_per_hour_rec,
            friday_cost_per_hour_reh: tmpRoom.friday_cost_per_hour_reh,
            friday_cost_per_hour_rec: tmpRoom.friday_cost_per_hour_rec,
            saturday_cost_per_hour_reh: tmpRoom.saturday_cost_per_hour_reh,
            saturday_cost_per_hour_rec: tmpRoom.saturday_cost_per_hour_rec,
            sunday_cost_per_hour_reh: tmpRoom.sunday_cost_per_hour_reh,
            sunday_cost_per_hour_rec: tmpRoom.sunday_cost_per_hour_rec,
            monday_working_hours_start: tmpRoom.monday_working_hours_start,
            tuesday_working_hours_start: tmpRoom.tuesday_working_hours_start,
            wednesday_working_hours_start: tmpRoom.wednesday_working_hours_start,
            thursday_working_hours_start: tmpRoom.thursday_working_hours_start,
            friday_working_hours_start: tmpRoom.friday_working_hours_start,
            saturday_working_hours_start: tmpRoom.saturday_working_hours_start,
            sunday_working_hours_start: tmpRoom.sunday_working_hours_start,
            monday_working_hours_stop: tmpRoom.monday_working_hours_stop,
            tuesday_working_hours_stop: tmpRoom.tuesday_working_hours_stop,
            wednesday_working_hours_stop: tmpRoom.wednesday_working_hours_stop,
            thursday_working_hours_stop: tmpRoom.thursday_working_hours_stop,
            friday_working_hours_stop: tmpRoom.friday_working_hours_stop,
            saturday_working_hours_stop: tmpRoom.saturday_working_hours_stop,
            sunday_working_hours_stop: tmpRoom.sunday_working_hours_stop,
            space: tmpRoom.space,
            speakers: tmpRoom.speakers,
            console: tmpRoom.console,
            microphones: tmpRoom.microphones,
            drums: tmpRoom.drums,
            guitars: tmpRoom.guitars,
            basses: tmpRoom.basses,
            pianos_keyboards: tmpRoom.pianos_keyboards,
            guitar_amplifiers: tmpRoom.guitar_amplifiers,
            bass_amplifiers: tmpRoom.bass_amplifiers,
            other_equipment: tmpRoom.other_equipment
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async editCurrencyOfAllRoomsOfCurOwner(tmpRoom : Studio_Room) : Promise<Array<Studio_Room>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_editCurrencyOfAllRoomsOfCurOwner', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpRoom.id,
            owned_by: tmpRoom.owned_by,
            name: tmpRoom.name,
            currency: tmpRoom.currency,
            monday_cost_per_hour_reh: tmpRoom.monday_cost_per_hour_reh,
            monday_cost_per_hour_rec: tmpRoom.monday_cost_per_hour_rec,
            tuesday_cost_per_hour_reh: tmpRoom.tuesday_cost_per_hour_reh,
            tuesday_cost_per_hour_rec: tmpRoom.tuesday_cost_per_hour_rec,
            wednesday_cost_per_hour_reh: tmpRoom.wednesday_cost_per_hour_reh,
            wednesday_cost_per_hour_rec: tmpRoom.wednesday_cost_per_hour_rec,
            thursday_cost_per_hour_reh: tmpRoom.thursday_cost_per_hour_reh,
            thursday_cost_per_hour_rec: tmpRoom.thursday_cost_per_hour_rec,
            friday_cost_per_hour_reh: tmpRoom.friday_cost_per_hour_reh,
            friday_cost_per_hour_rec: tmpRoom.friday_cost_per_hour_rec,
            saturday_cost_per_hour_reh: tmpRoom.saturday_cost_per_hour_reh,
            saturday_cost_per_hour_rec: tmpRoom.saturday_cost_per_hour_rec,
            sunday_cost_per_hour_reh: tmpRoom.sunday_cost_per_hour_reh,
            sunday_cost_per_hour_rec: tmpRoom.sunday_cost_per_hour_rec,
            monday_working_hours_start: tmpRoom.monday_working_hours_start,
            tuesday_working_hours_start: tmpRoom.tuesday_working_hours_start,
            wednesday_working_hours_start: tmpRoom.wednesday_working_hours_start,
            thursday_working_hours_start: tmpRoom.thursday_working_hours_start,
            friday_working_hours_start: tmpRoom.friday_working_hours_start,
            saturday_working_hours_start: tmpRoom.saturday_working_hours_start,
            sunday_working_hours_start: tmpRoom.sunday_working_hours_start,
            monday_working_hours_stop: tmpRoom.monday_working_hours_stop,
            tuesday_working_hours_stop: tmpRoom.tuesday_working_hours_stop,
            wednesday_working_hours_stop: tmpRoom.wednesday_working_hours_stop,
            thursday_working_hours_stop: tmpRoom.thursday_working_hours_stop,
            friday_working_hours_stop: tmpRoom.friday_working_hours_stop,
            saturday_working_hours_stop: tmpRoom.saturday_working_hours_stop,
            sunday_working_hours_stop: tmpRoom.sunday_working_hours_stop,
            space: tmpRoom.space,
            speakers: tmpRoom.speakers,
            console: tmpRoom.console,
            microphones: tmpRoom.microphones,
            drums: tmpRoom.drums,
            guitars: tmpRoom.guitars,
            basses: tmpRoom.basses,
            pianos_keyboards: tmpRoom.pianos_keyboards,
            guitar_amplifiers: tmpRoom.guitar_amplifiers,
            bass_amplifiers: tmpRoom.bass_amplifiers,
            other_equipment: tmpRoom.other_equipment
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async deleteRoom(tmpRoomId : number) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_deleteRoom', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpRoomId
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }
    
    public async getAllRoomsOfOwner(tmpOwnerId : number) : Promise<Array<Studio_Room>> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getOwnerRooms', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                id: tmpOwnerId
              }
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
      }

    public async addReservation(tmpReservation: Studio_Reservation) {
      /*var byWhomId = -1;      
      if(LoginManager.getInstance().role == Generic_ConstManager.getInstance().PLAYER_ROLE) {
        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
          byWhomId = LoginManager.getInstance().loggedInMusician.id;
        }
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
          byWhomId = LoginManager.getInstance().loggedInTennisPlayer.id;
        }
      }
      else if(LoginManager.getInstance().role == Generic_ConstManager.getInstance().OWNER_ROLE) {
        byWhomId = LoginManager.getInstance().loggedInOwner.id;
        //web-service side: RESERVATION_MADE_BY_THE_OWNER_OF_THE_COURT = -1000 
      }*/

        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addReservation', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                for_room: tmpReservation.for_room,
                rehearsal: tmpReservation.rehearsal,
                by_user_email: LoginManager.getInstance().username.toLowerCase(),
                reservation_date: tmpReservation.reservation_date,
                reservation_hour_start: tmpReservation.reservation_hour_start,
                reservation_hour_stop: tmpReservation.reservation_hour_stop,
                comments: tmpReservation.comments
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("addReservation response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    /*public async getReservation(tmpReservationID : number) : Promise<Reservation> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getReservation', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpReservationID
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }*/

    public async getAllReservationsByUserEmail(tmpPlayerEmail : string) : Promise<Array<Studio_Reservation>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByUserEmail', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                email: tmpPlayerEmail.toLowerCase()
              }
            }),
          })
          .then(async response => await response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });      
    }

    public async getAllReservationsByUserEmail_forReactNativeCalendarsAgenda(tmpPlayerEmail : string) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByUserEmail_forReactNativeCalendarsAgenda', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              email: tmpPlayerEmail.toLowerCase()
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("*** getAllReservationsByPlayerID_forReactNativeCalendarsAgenda START ***");
          //console.log(JSON.stringify(responseJson, null, 2));
          //console.log("*** getAllReservationsByPlayerID_forReactNativeCalendarsAgenda END ***");
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByOwnerID_forReactNativeCalendarsAgenda(tmpOwnerID : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByOwnerID_forReactNativeCalendarsAgenda', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpOwnerID
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda START ***");
          //console.log(JSON.stringify(responseJson, null, 2));
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda END ***");
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate(tmpOwnerID : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              owner_id: tmpOwnerID,
              from_date: UtilsManager.getInstance().getFirstDayOfPreviousMonth(),
              to_date: UtilsManager.getInstance().getLastDayOfNextMonth()
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda START ***");
          //console.log(JSON.stringify(responseJson, null, 2));
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda END ***");
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByOwnerID(tmpOwnerID : number) : Promise<Array<Studio_Reservation>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByOwnerID', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpOwnerID
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByRoomID(tmpRoomId : number) : Promise<Array<Studio_Reservation>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByRoomID', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                id: tmpRoomId
              }
            }),
          })
          .then(async response => await response.json())
          .then(responseJson => {
            //console.log("studio_getAllReservationsByRoomID RESULT: ", responseJson);
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });      
    }

    public async getAllReservationsByRoomID_fromDateToDate(tmpRoomId : number) : Promise<Array<Studio_Reservation>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getAllReservationsByRoomID_fromDateToDate', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              room_id: tmpRoomId,
              from_date: moment().format('DD/MM/YYYY').toString(), //today
              to_date: UtilsManager.getInstance().getLastDayOfNextMonth()
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("studio_getAllReservationsByRoomID RESULT: ", responseJson);
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
  }

    public async cancelReservation(tmpReservationID : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_cancelReservation', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpReservationID
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          //console.log("cancelReservation response:", responseStr);    
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async getOwnerPics(tmpOwnerId : number) : Promise<Array<PicBase64>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getOwnerPics', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                id: tmpOwnerId
              }
            }),
          })
          .then(async response => await response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async uploadPhoto(tmpPhoto : PicBase64) : Promise<Array<PicBase64>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_uploadPhoto', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          language: LoginManager.getInstance().language,
          serviceInput: {
            id: tmpPhoto.id,
            owner_id: tmpPhoto.owner_id,
            base64_encoded_str: tmpPhoto.base64_encoded_str,   //there is no corresponding field in DB
            pic_description: tmpPhoto.pic_description,
            local_filepath_on_server: tmpPhoto.local_filepath_on_server,
            pic_url: tmpPhoto.pic_url
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        console.log("studio_uploadPhoto response: " + JSON.stringify(responseJson));
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async deleteOwnerPic(tmpPhotoId: number) : Promise<Array<PicBase64>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_deleteOwnerPic', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpPhotoId
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        console.log("studio_deleteOwnerPic response:", responseJson);
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });      
    }

    public async editOwnerPicDescription(tmpPhotoId: number, tmpNewPhotoDesc: string) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_editOwnerPicDescription', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpPhotoId,
            owner_id: LoginManager.getInstance().ID,
            pic_description: tmpNewPhotoDesc
          }
        }),
      })
      .then(async response => await response.text())
      .then(responseStr => {
        //console.log("studio_editOwnerPicDescription response:", responseStr);

        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });      
    }

    public async verifyAccount(tmpKey : string) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_verifyAccount', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: -1,
            key_string: tmpKey,
            submitted_by_email: LoginManager.getInstance().username.toLowerCase(),
            submit_date: ""
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("verifyAccount response:", responseStr);
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async resendVerificationKey() : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_resendVerificationKey', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          language: LoginManager.getInstance().language,
          serviceInput: {          
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("resendVerificationKey response:", responseStr);
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async requestKeyForPasswordReset(tmpSubmittedByEmail : string, tmpLangNum : number) {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_requestKeyForPasswordReset', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                submitted_by_email: tmpSubmittedByEmail.toLowerCase(),
                emailLanguage: tmpLangNum
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("requestKeyForPasswordReset response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          }
        );
    }
    
    public async updatePasswordWithKey(tmpEmail : string, tmpNewPasswordMD5Encoded : string, tmpKey : string) {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_updatePasswordWithKey', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: tmpEmail,
              password: "itIsIgnored",
              serviceInput: {
                newwPassword: tmpNewPasswordMD5Encoded,
                key: tmpKey
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("updatePasswordWithKey response:", responseStr);    
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async addComment(tmpComments : string) : Promise<string | void> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addComment', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                submitted_by_email: LoginManager.getInstance().username.toLowerCase(),
                my_comment: tmpComments
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("addComment response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async addReportForOwner(tmpOwnerEmail : string, tmpReportReason : string) {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addReportForOwner', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                player_email: LoginManager.getInstance().username.toLowerCase(),
                owner_email: tmpOwnerEmail.toLowerCase(),
                report: tmpReportReason
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("addReportForOwner response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async blockPlayer(tmpBlockedPlayer : BlockedPlayer) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_blockPlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            owner_id: tmpBlockedPlayer.owner_id,
            blocked_user_email: tmpBlockedPlayer.blocked_user_email.toLowerCase(),
            reason: ""
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async unblockPlayer(tmpBlockedPlayer : BlockedPlayer) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_unblockPlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            owner_id: tmpBlockedPlayer.owner_id,
            blocked_user_email: tmpBlockedPlayer.blocked_user_email.toLowerCase(),
            reason: ""
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async promoteHandyBookerToOwner(tmpOwnerId : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_promoteHandyBookerToOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              from_user_email: LoginManager.getInstance().username.toLowerCase(),
              to_owner_id: tmpOwnerId
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          //console.log("promoteHandyBookerToOwner response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async addOwnerExtraInfo(tmpOwnerExtraInfo : Studio_OwnerExtraInfo) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_addOwnerExtraInfo', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID,
              allows_rehearsal: tmpOwnerExtraInfo.allows_rehearsal,
              allows_recording: tmpOwnerExtraInfo.allows_recording 
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("addOwnerExtraInfo response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async editOwnerExtraInfo(tmpOwnerExtraInfo : Studio_OwnerExtraInfo) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_editOwnerExtraInfo', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID,
              allows_rehearsal: tmpOwnerExtraInfo.allows_rehearsal,
              allows_recording: tmpOwnerExtraInfo.allows_recording 
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("editOwnerExtraInfo response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async getOwnerExtraInfo(tmpOwnerID: number) : Promise<Studio_OwnerExtraInfo> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getOwnerExtraInfo', {
          method: 'POST',
          headers: {
              Accept: 'application/json; charset=UTF-8',
                      'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
              id: tmpOwnerID
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        console.log("getOwnerExtraInfo response:", responseJson);
        return responseJson;
      })
      .catch(error => {
          console.error(error);
      });
    }

    public async sendEmailToInactiveOwners() : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_sendEmailToInactiveOwners', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("studio_sendEmailToInactiveOwners response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async sendEmailToInactiveOwnersTest() : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_sendEmailToInactiveOwnersTest', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("studio_sendEmailToInactiveOwners response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }
}

export default Studio_RestManager;