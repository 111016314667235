import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, Dimensions, PanResponder, Image, Keyboard, TouchableWithoutFeedback, Alert, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import { Provider, Subheading, TextInput, Button, ActivityIndicator, Divider, Text, List } from 'react-native-paper';
import PlayerControlPanelView from './components/player/PlayerControlPanelView'
import PlayerMyProfileView from './components/player/PlayerMyProfileView'
import PlayerSettingsView from './components/player/PlayerSettingsView';
import PlayerContactUsView from './components/player/PlayerContactUsView';
import OwnerControlPanelView from './components/owner/OwnerControlPanelView';
import OwnerMyProfileView from './components/owner/OwnerMyProfileView';
import OwnerSettingsView from './components/owner/OwnerSettingsView';
import OwnerPinOnMapView from './components/owner/OwnerPinOnMapView'
import OwnerAddFirstRoomView from './components/owner/OwnerAddFirstRoomView';
import SelectedOwnerSummaryView from './components/generic/SelectedOwnerSummaryView';
import GuestBrowseStudiosView from './components/guest/GuestBrowseStudiosView';
import ForgotPasswordView from './components/generic/ForgotPasswordView';
import SignUpView from './components/generic/SignUpView';
import SignUpKeyView from './components/generic/SignUpKeyView';
import AdminView from './components/admin/AdminView';
//import { NavigationContainer } from '@react-navigation/native';
//import { createStackNavigator } from '@react-navigation/stack';
import { MaterialCommunityIcons } from '@expo/vector-icons';
//import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import * as Crypto from 'expo-crypto';
import * as SecureStore from 'expo-secure-store';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import NetInfo from '@react-native-community/netinfo';
import LoginManager from './managers/LoginManager';
import Spinner from 'react-native-loading-spinner-overlay';
import Studio_RestManager from './managers/Studio_RestManager';
import GUIManager from './managers/GUIManager';
import i18n from './utils/i18n';
import styles from './css/myCss';
import AdBanner from './components/generic/AdBanner';

import MyCustomTheme from './config/MyCustomTheme';
import UtilsManager from './managers/UtilsManager';
import DropDown from 'react-native-paper-dropdown';
import Tennis_RestManager from './managers/Tennis_RestManager';
import Generic_ConstManager from './managers/Generic_ConstManager';
import OwnerManageRoomsView from './components/owner/OwnerManageRoomsView';
import OwnerAddTennisClubMembershipsView from './components/owner/tennis/OwnerAddTennisClubMembershipsView';
import OwnerManageTennisClubMembershipsView from './components/owner/tennis/OwnerManageTennisClubMembershipsView';
import NotificationManager from './managers/NotificationManager';
import Constants from 'expo-constants';
//import AesGcmCrypto, { EncryptedData } from 'react-native-aes-gcm-crypto';
//import * as Permissions from 'expo-permissions';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';
import LeftColumnWeb from './components/generic/LeftColumnWeb';
import RightColumnWeb from './components/generic/RightColumnWeb';
import BottomColumnWeb from './components/generic/BottomColumnWeb';
import PrivacyPolicy from './components/generic/PrivacyPolicy';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const studioLogo =  require("./assets/studio/HandyBooker_studio_logo.png");
const tennisLogo =  require("./assets/tennis/HandyBooker_tennis_logo.png");
const enFlag =  require("./assets/EN_flag.png");
const enFlagSelected =  require("./assets/EN_flag_selected.png");
const grFlag =  require("./assets/GR_flag.png");
const grFlagSelected =  require("./assets/GR_flag_selected.png");

const screenDimensions = Dimensions.get('window');

function HomeScreen({ navigation }) {
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');
  
  //notifications
  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    if(Platform.OS != 'web') {
      NotificationManager.getInstance().registerForPushNotificationsAsync().then(token => {
        LoginManager.getInstance().encrypted_token = UtilsManager.getInstance().encryptWithAES256(token);
        setEncryptedToken(UtilsManager.getInstance().encryptWithAES256(token));
        setExpoPushToken(token);
      });

      notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
        setNotification(notification);
      });

      responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        console.log("notification response: " + response);
      });

      if (Device.isDevice) {
        const existingStatus = async () => { Notifications.getPermissionsAsync(); }
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const status = async () => { await Notifications.requestPermissionsAsync(); }
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          console.log('Permission not granted to get push token for push notification!');
          return;
        }
        const projectId =
          Constants?.expoConfig?.extra?.eas?.projectId ??
          Constants?.easConfig?.projectId;
        if (!projectId) {
          console.log('Project ID not found');
        }
        try {
          const pushTokenString = async () => (
            await Notifications.getExpoPushTokenAsync({
              projectId,
            })
          ).data;
          console.log("line 132 of App.tsx -> Expo token: " + pushTokenString);
          return pushTokenString;
        } catch (e: unknown) {
          console.log(`${e}`);
        }
      } else {
        console.log('Must use physical device for push notifications');
      }

      // Ask for notification permissions if not granted already
      /* //PALIOS KWDIKAS - GIA PALIO EXPO VERSION
      
        const registerForPushNotificationsAsync = async () => {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
        if (status !== 'granted') {
          alert('You need to grant permission to receive notifications');
          return;
        }

        const token = await Notifications.getExpoPushTokenAsync();
        console.log("line 115 of App.tsx -> Expo token: " + token);
        return token;
      };*/

      // Add the notification handler with onPress logic
      /*Notifications.setNotificationHandler({
        handleNotification: async (notification) => {
          console.log(notification);

          // Add the notification to the notifications array
          setAllNotifications(prevNotifications => [...prevNotifications, notification]);


          // Handle onPress logic
          // For example, navigate to a specific screen based on the notification data
          // The data can be accessed through notification.data
          
          // Access the data property of the notification
          const data = notification.request.content.data;

          // Do something with the data
          console.log("notification data: ", data);

          return {
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true,
          };
        },
      });*/

      /*
          shouldPlaySound: A boolean value that indicates whether a sound should be played when the notification is received.
          shouldSetBadge: A boolean value that indicates whether the app icon badge should be updated when the notification is received.
          shouldShowPreview: A boolean value that indicates whether a notification preview should be shown on the lock screen when the notification is received.
          shouldShowForegroundAlert: A boolean value that indicates whether an alert should be shown when the notification is received while the app is in the foreground.
          shouldShowInAppNotificationSettings: A boolean value that indicates whether an in-app notification settings screen should be shown when the user taps on the notification settings button in the notification tray.
      */

      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, []);
  //-notifications

  const [isRunningOnEmulator, setIsRunningOnEmulator] = useState(false);
  useEffect(() => {
    console.log("useEffect - isRunningOnEmulator: " + isRunningOnEmulator);
  }, [isRunningOnEmulator]);

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    // code to run on component mount
    //it's equivalent to componentDidMount()
    console.log("HomeScreen: I'm ready!");

    //check device   
    console.log("Device: ", Device.modelName);
    if(Device.modelName?.indexOf("sdk_") != -1)
      setIsRunningOnEmulator(true);
    //-check device

    //check OS
    console.log("Platform.OS: ", Platform.OS);
    //-check OS

    //test
    //(async () => {
      //const myInitialText : string = "Eisai master!";
      //const myEncyptedText = UtilsManager.getInstance().encryptWithAES256(myInitialText);
      //const myDecyptedText = UtilsManager.getInstance().decryptWithAES256(myEncyptedText);
      //console.log("myInitialText: ", myInitialText);
      //console.log("myEncyptedText: ", myEncyptedText);
      //console.log("myDecyptedText: ", myDecyptedText);
    //})();
     
    //-test

    //load user preferences that are locally stored on the device
    if(Platform.OS != 'web') {
      SecureStore.getItemAsync('storedEmail')
        .then((myStoredEmail) => {
            if(myStoredEmail) {
              let myReturnedEmail = JSON.parse(myStoredEmail);
              if(myReturnedEmail) {
                setCurEmail(myReturnedEmail);
                LoginManager.getInstance().username = myReturnedEmail;
              }
            }
        });
        SecureStore.getItemAsync('storedNonEncryptedPassword')
        .then((myStoredNonEncryptedPassword) => {
            if(myStoredNonEncryptedPassword) {
              let myReturnedNonEncryptedPassword = JSON.parse(myStoredNonEncryptedPassword);
              if(myReturnedNonEncryptedPassword) {
                setCurPassword(myReturnedNonEncryptedPassword);
                LoginManager.getInstance().password = myReturnedNonEncryptedPassword;
              }
            }
        });
        SecureStore.getItemAsync('storedLanguage')
        .then((myStoredLanguage) => {
            if(myStoredLanguage) {
              let myReturnedLanguage = JSON.parse(myStoredLanguage);
              if(myReturnedLanguage) {
                setCurLanguage(myReturnedLanguage);              
              }
            }
            else
              setCurLanguage('en');
        });
        SecureStore.getItemAsync('storedDomain')
        .then((myStoredDomain) => {
            if(myStoredDomain) {
              let myReturnedDomain = JSON.parse(myStoredDomain);
              if(myReturnedDomain) {
                setSelectedDomainValue(myReturnedDomain);
              }
            }
            else
              setSelectedDomainValue(LoginManager.getInstance().STUDIO);
        });

      //check internet connection
      NetInfo.fetch().then(state => {
        //console.log('Connection type', state.type);
        //console.log('Is connected?', state.isConnected);

        setIsConnectedToInternet(state.isConnected);

        if(state.isConnected == false)
        {
          if(Platform.OS != "web") alert("Please connect to the internet!");
        }
        else
        {
          //alert("You are connected to the internet using " + state.type);
        }
      });
    }
    else {  //web
      const tmpStoredEmail = sessionStorage.getItem('storedEmail');
      const tmpStoredNonEncryptedPassword = sessionStorage.getItem('storedNonEncryptedPassword');
      const tmpStoredEncryptedPassword = sessionStorage.getItem('storedEncryptedPassword');
      const tmpStoredLanguage = sessionStorage.getItem('storedLanguage');
      const tmpStoredDomain = sessionStorage.getItem('storedDomain');

      console.log("sessionStorage -> tmpStoredEmail: ", tmpStoredEmail);
      console.log("sessionStorage -> tmpStoredNonEncryptedPassword: ", tmpStoredNonEncryptedPassword);
      console.log("sessionStorage -> tmpStoredEncryptedPassword: ", tmpStoredEncryptedPassword);
      console.log("sessionStorage -> tmpStoredLanguage: ", tmpStoredLanguage);
      console.log("sessionStorage -> tmpStoredDomain: ", tmpStoredDomain);

      if(tmpStoredEmail) {
        setCurEmail(tmpStoredEmail);
        LoginManager.getInstance().username = tmpStoredEmail;
      }
      if(tmpStoredNonEncryptedPassword) {
        setCurPassword(tmpStoredNonEncryptedPassword);
        LoginManager.getInstance().password = tmpStoredNonEncryptedPassword;
      }
      if(tmpStoredEncryptedPassword) {
        setCurMD5EncodedPassword(tmpStoredEncryptedPassword);
        LoginManager.getInstance().md5EncodedPassword = tmpStoredEncryptedPassword;
      }
      if(tmpStoredLanguage)
        setCurLanguage(tmpStoredLanguage);
      else
        setCurLanguage('en');
      if(tmpStoredDomain)
        setSelectedDomainValue(tmpStoredDomain);
      else
        setSelectedDomainValue(LoginManager.getInstance().STUDIO);
    }

    //Ask for permission to send notifications
    /*(async () => {
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      if(status === 'granted')
        GUIManager.getInstance().notificationsAllowed = true;
      console.log("notifications -> status: " + status);
    })();*/
  }, [alreadyRunOnceOnViewLoad]);

  const [isConnectedToInternet, setIsConnectedToInternet] = useState<boolean | null>(false);
  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(MyAlertReactNative != null) {
      if(isLoading===false && messageToShow.length > 0)
      {
        // Works on both Android and iOS
        Alert.alert(
          messageToShow,
          '',
          [
            {
              text: 'OK',
              onPress: () => {
                setMessageToShow('');
              }
            }
          ],
          { cancelable: false }
        );
      }
    }
    else if(MyAlertForWeb != null){
      if(isLoading===false && messageToShow.length > 0) {
        setAlertForWebText(messageToShow);
        setAlertForWebVisible(true);
      }
    }
  }, [isLoading]);

  
  // Declare a new state variable, which we'll call "curLanguage"
  const [curLanguage, setCurLanguage] = useState('');
  useEffect(() => {    
    // Set the locale once at the beginning of your app.
    i18n.locale = curLanguage;
    
    if(curLanguage === 'en')
    {
      setCurEnFlag(enFlagSelected);
      setCurGrFlag(grFlag);
      setCurDropdownList(UtilsManager.getInstance().domainsList_EN);
      LoginManager.getInstance().language = LoginManager.getInstance().EN;
    }
    else if(curLanguage === 'gr')
    {
      setCurEnFlag(enFlag);
      setCurGrFlag(grFlagSelected);
      setCurDropdownList(UtilsManager.getInstance().domainsList_GR);
      LoginManager.getInstance().language = LoginManager.getInstance().GR;
    }
    if(Platform.OS != 'web') 
      SecureStore.setItemAsync('storedLanguage', JSON.stringify(curLanguage)).catch((error) => console.log('Error during storedEmail storage!', error));
    else //web
      sessionStorage.setItem('storedLanguage', curLanguage);
  }, [curLanguage]);

  const [curEmail, setCurEmail] = useState(""); //'kaklanis@gmail.com'
  const [curPassword, setCurPassword] = useState(""); //'nk'
  useEffect(() => {
    (async () => {
      if(Platform.OS == "web") {
        const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(curPassword);
        console.log('Digest (web): ', digest);
        setCurMD5EncodedPassword(digest);
      }
      else { //mobile
        const digest = await Crypto.digestStringAsync(
          Crypto.CryptoDigestAlgorithm.MD5,
          curPassword
        );
        console.log('Digest: ', digest);
        setCurMD5EncodedPassword(digest);
      }
    })();
  }, [curPassword]);

  const [curMD5EncodedPassword, setCurMD5EncodedPassword] = useState('');
  const [curEnFlag, setCurEnFlag] = useState(enFlagSelected);
  const [curGrFlag, setCurGrFlag] = useState(grFlag);
  
  
  const [encryptedToken, setEncryptedToken] = useState('');

  async function loginFunc() {
    //first check if email is empty
    var tmpEmailIsEmpty : boolean = false;
    if(curEmail == "")
      tmpEmailIsEmpty = true;
    //then check if email is valid
    const tmpEmailIsValid = UtilsManager.getInstance().emailIsValid(curEmail);
    //then check if password is empty
    var tmpPasswordIsEmpty : boolean = false;
    if(curPassword == "")
      tmpPasswordIsEmpty = true;
    
    if(tmpEmailIsEmpty == true) {
      if(MyAlertReactNative != null) 
        alert(i18n.t('pleaseCompleteEmail'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteEmail'));
        setAlertForWebVisible(true);
      }
    }
    else if(tmpEmailIsValid == false) {
      if(MyAlertReactNative != null) 
        alert(i18n.t('pleaseCompleteValidEmail'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteValidEmail'));
        setAlertForWebVisible(true);
      }
    }
    else if(tmpPasswordIsEmpty == true) {
      if(MyAlertReactNative != null) 
        alert(i18n.t('pleaseCompletePassword'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompletePassword'));
        setAlertForWebVisible(true);
      }
    }
    else {
      setIsLoading(true);
      
      //store user email/password locally on the device
      if(Platform.OS != 'web') {
        SecureStore.setItemAsync('storedEmail', JSON.stringify(curEmail)).catch((error) => console.log('Error during storedEmail storage!', error));
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(curPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
        console.log("Email and password stored locally!");
      }
      else { //web
        sessionStorage.setItem('storedEmail', curEmail);
        sessionStorage.setItem('storedNonEncryptedPassword', curPassword);
        sessionStorage.setItem('storedEncryptedPassword', curMD5EncodedPassword);
      }

      LoginManager.getInstance().username = curEmail;
      LoginManager.getInstance().password = curPassword;
      LoginManager.getInstance().md5EncodedPassword = curMD5EncodedPassword;

      var tmpRoleIDAndDomain : RoleIDAndDomain = {
        role: -1,
        ID: -1,
        verified: false,
        domain: ""
      };
      if(selectedDomainValue == LoginManager.getInstance().STUDIO)
        tmpRoleIDAndDomain = await Studio_RestManager.getInstance().login(encryptedToken) as RoleIDAndDomain;
      else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
        tmpRoleIDAndDomain = await Tennis_RestManager.getInstance().login(encryptedToken) as RoleIDAndDomain;

      console.log("login response -> tmpRoleIDAndDomain: ", JSON.stringify(tmpRoleIDAndDomain,null, 2))
      LoginManager.getInstance().role = tmpRoleIDAndDomain.role;
      LoginManager.getInstance().ID = tmpRoleIDAndDomain.ID;
      LoginManager.getInstance().verified = tmpRoleIDAndDomain.verified;
      LoginManager.getInstance().domain = tmpRoleIDAndDomain.domain;

      //PLAYER_ROLE
      if(tmpRoleIDAndDomain.ID!==-1 && tmpRoleIDAndDomain.role===Generic_ConstManager.getInstance().PLAYER_ROLE)
      {
        console.log("*** PLAYER MODE (role: 1) ***");
        GUIManager.getInstance().mode = GUIManager.getInstance().PLAYER_LOGGED_IN_MODE;
        if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().STUDIO) {
          const curMusician : Studio_Player = await Studio_RestManager.getInstance().getPlayerByEmail(curEmail) as Studio_Player;
          LoginManager.getInstance().loggedInMusician = curMusician;
        }
        else if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().TENNIS) {
          const curTennisPlayer : Tennis_Player = await Tennis_RestManager.getInstance().getPlayerByEmail(curEmail) as Tennis_Player;
          LoginManager.getInstance().loggedInTennisPlayer = curTennisPlayer;
        }

        if(tmpRoleIDAndDomain.verified === true) {          
          navigation.reset({
            index: 1,
            routes: [{ name: 'PlayerControlPanel', params: { selectedDomainValue } }]
          });
        }
        else {
          navigation.reset({
            index: 1,
            routes: [{ name: 'SignUpKey', params: { title: i18n.t('accountVerification'), backLabel: i18n.t('back'), selectedDomainValue } }]
          });
        }
      }
      //OWNER_ROLE
      else if(tmpRoleIDAndDomain.ID!==-1 && tmpRoleIDAndDomain.role===Generic_ConstManager.getInstance().OWNER_ROLE)
      {
        console.log("*** OWNER MODE (role: 2) ***");
        GUIManager.getInstance().mode = GUIManager.getInstance().OWNER_LOGGED_IN_MODE;
        
        var curOwner : Owner = {
          id: -1, name: "", surname: "", telephone: "", email: "", password: "", company_name: "", postal_address: "", city: "", country: "",
          longitude: -1, latitude: -1, website: "", last_login: "", language: "", email_notifications: false, registration_date: "",
          verified: false, active: false, info_source: ""
        };
        if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().STUDIO) {
          curOwner = await Studio_RestManager.getInstance().getOwner(curEmail) as Owner;
          //get studio owner extra info
          var tmpStudio_OwnerExtraInfo : Studio_OwnerExtraInfo = await Studio_RestManager.getInstance().getOwnerExtraInfo(curOwner.id);
          if(tmpStudio_OwnerExtraInfo!=null && tmpStudio_OwnerExtraInfo!=undefined) {
            LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = tmpStudio_OwnerExtraInfo.allows_rehearsal;
            LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = tmpStudio_OwnerExtraInfo.allows_recording;
          }
          //-get studio owner extra info
        }
        else if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().TENNIS) {
          curOwner = await Tennis_RestManager.getInstance().getOwner(curEmail) as Owner;
          //get tennis owner extra info
          var tmpTennis_OwnerExtraInfo : Tennis_OwnerExtraInfo = await Tennis_RestManager.getInstance().getOwnerExtraInfo(curOwner.id);
          if(tmpTennis_OwnerExtraInfo!=null && tmpTennis_OwnerExtraInfo!=undefined) {
            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = tmpTennis_OwnerExtraInfo.allows_reservations_to_members;
            LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = tmpTennis_OwnerExtraInfo.allows_reservations_to_non_members;
          }
          //-get tennis owner extra info
        }
        LoginManager.getInstance().loggedInOwner = curOwner;
        if(tmpRoleIDAndDomain.verified === true) {
          //check if owner has room(s)
          //if not, this means that he has just registered
          setIsLoading(true);
          if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().STUDIO) {
            GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio = await Studio_RestManager.getInstance().getAllRoomsOfOwner(LoginManager.getInstance().ID) as Array<Studio_Room>;
            if(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.length === 0) {
              console.log("[getAllRoomsOfOwner()] -> O owner DEN exei rooms");
              navigation.reset({
                index: 1,
                routes: [{ name: 'OwnerPinOnMap', params: { title: i18n.t('verifyAddress'), backLabel: i18n.t('back') } }]
              });
            }
            else {
              console.log("[getAllRoomsOfOwner()] -> O owner exei rooms");
              navigation.reset({
                index: 1,
                routes: [{ name: 'OwnerControlPanel', params: { selectedDomainValue } }]
              });
            }
          } 
          else if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().TENNIS) {
            //get membership packages
            const tmpMemberships : Array<Tennis_OwnerMembership> = await Tennis_RestManager.getInstance().getAllClubMembershipsOfOwner(LoginManager.getInstance().ID);
            if(tmpMemberships!=null && tmpMemberships!=undefined)
              LoginManager.getInstance().loggedInTennisOwner_memberships = tmpMemberships;
            //-get membership packages
            GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis = await Tennis_RestManager.getInstance().getAllCourtsOfOwner(LoginManager.getInstance().ID) as Array<Tennis_Court>;
            if(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.length === 0) {
              console.log("[getAllCourtsOfOwner()] -> O owner DEN exei courts");
              navigation.reset({
                index: 1,
                routes: [{ name: 'OwnerPinOnMap', params: { title: i18n.t('verifyAddress'), backLabel: i18n.t('back') } }]
              });
            }
            else {
              console.log("[getAllCourtsOfOwner()] -> O owner exei courts");
              navigation.reset({
                index: 1,
                routes: [{ name: 'OwnerControlPanel', params: { selectedDomainValue } }]
              });
            }
          }   
        }
        else {
          navigation.reset({
            index: 1,
            routes: [{ name: 'SignUpKey', params: { title: i18n.t('accountVerification'), backLabel: i18n.t('back'), selectedDomainValue } }]
          });
        }
        
      }
      //ADMIN_ROLE
      else if(tmpRoleIDAndDomain.verified===true && tmpRoleIDAndDomain.ID!==-1 
        && tmpRoleIDAndDomain.role===Generic_ConstManager.getInstance().ADMIN_ROLE)
      {
        console.log("*** ADMIN MODE (role: 3) ***");
        GUIManager.getInstance().mode = GUIManager.getInstance().ADMIN_LOGGED_IN_MODE;
        //admin        navigation.navigate('OwnerControlPanel')
        /*if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().STUDIO) {
        }
        else if(tmpRoleIDAndDomain.domain == LoginManager.getInstance().TENNIS) {
        }*/
        navigation.reset({
          index: 1,
          routes: [{ name: 'AdminPanel', params: { title: 'Admin Panel' } }]
        });
      }
      //GUEST_ROLE
      else
      {
        setIsLoading(false);
        GUIManager.getInstance().mode = GUIManager.getInstance().GUEST_MODE;
        if(MyAlertReactNative != null) 
          alert(i18n.t('wrongEmailPassword'));
        else if(MyAlertForWeb != null){
            setAlertForWebText(i18n.t('wrongEmailPassword'));
            setAlertForWebVisible(true);
        }
      }

      //setIsLoading(false); An den to exw sxoliasmeno xtypaei giati paei na kanei setState th stigmh poy einai unmounted afoy exw hdh kanei navigate se allo view
    }
  }

  const [curLogo, setCurLogo] = useState(studioLogo);

  const [curDropdownList, setCurDropdownList] = useState(UtilsManager.getInstance().domainsList_EN);
  const [showDomainDropdownList, setShowDomainDropdownList] = useState(false);
  const [selectedDomainValue, setSelectedDomainValue] = useState("STUDIO");
  useEffect(() => {
    console.log("App -> useEffect - selectedDomainValue -> selectedDomainValue: ", selectedDomainValue);
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      setCurLogo(studioLogo);
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      setCurLogo(tennisLogo);
    }
    //store domain locally on the device
    if(Platform.OS != 'web') 
      SecureStore.setItemAsync('storedDomain', JSON.stringify(selectedDomainValue)).catch((error) => console.log('Error during storedDomain storage!', error));
    else //web
        sessionStorage.setItem('storedDomain', selectedDomainValue);
  }, [selectedDomainValue]);

  // Create a ref for the TextInput components
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  return (
    <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View style={styles.container}>
          <View style={{flexDirection:"row"}}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
              <ScrollView style={styles.scrollView}>
                <StatusBar style="auto" />
                <Spinner
                  //visibility of Overlay Loading Spinner
                  visible={isLoading}
                  //Text with the Spinner
                  textContent={i18n.t('loading')}
                  //Text style of the Spinner Text
                  textStyle={styles.spinnerTextStyle}
                />
                <View style={styles.domainDropdown}>
                  <DropDown
                      label={i18n.t('lookingFor')}
                      mode={'outlined'}
                      visible={showDomainDropdownList}
                      showDropDown={() => setShowDomainDropdownList(true)}
                      onDismiss={() => setShowDomainDropdownList(false)}
                      value={selectedDomainValue}
                      setValue={setSelectedDomainValue}
                      list={curDropdownList}
                      inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showDomainDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                  />
                  <Divider style={{ height: 5, backgroundColor: "white" }}/>
                </View>
                <TouchableOpacity>
                  <Image
                    source={curLogo}
                    style={styles.logoHomePage} //height/width
                  />
                  <View style={{flexDirection:"row"}}>
                    <View style={{flex:1, alignItems:'flex-end'}}>
                      <TouchableOpacity
                        onPress={() => { 
                              setCurLanguage('en');
                              LoginManager.getInstance().language = LoginManager.getInstance().EN;
                              //alert('en');
                            }
                        }            
                      >
                        <Image source={curEnFlag} style={styles.flag} />
                      </TouchableOpacity>
                    </View>

                    <View style={{flex:1, alignItems:'flex-start'}}>
                      <TouchableOpacity style={{justifyContent:"center"}}
                        onPress={() => { 
                              setCurLanguage('gr');
                              LoginManager.getInstance().language = LoginManager.getInstance().GR;
                              //alert('gr');
                            }
                        }
                      >
                        <Image source={curGrFlag} style={styles.flag} />                    
                      </TouchableOpacity>
                    </View>
                  </View>        
                </TouchableOpacity>  

                {Platform.OS!='web' && <View
                  style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'space-around', borderColor: 'black', borderWidth: 2
                  }}>
                  <Text>Your expo push token: {expoPushToken}</Text>
                  <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Text>Title: {notification && notification.request.content.title} </Text>
                    <Text>Body: {notification && notification.request.content.body}</Text>
                    <Text>Data: {notification && JSON.stringify(notification.request.content.data)}</Text>
                  </View>
                  <Button onPress={async () => {
                      await NotificationManager.getInstance().schedulePushNotification();
                    }}>
                    Press to schedule a notification
                  </Button>
                </View>}

                <TouchableOpacity onPress={() => emailInputRef.current.focus()}>
                  <TextInput
                    ref={emailInputRef}
                    label="Email"
                    value={curEmail}
                    onChangeText={tmpEmail => setCurEmail(tmpEmail)}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => passwordInputRef.current.focus()}>
                  <TextInput
                    ref={passwordInputRef}
                    label={i18n.t('password')}
                    value={curPassword}
                    onChangeText={tmpPassword => setCurPassword(tmpPassword)}
                    secureTextEntry
                  />
                </TouchableOpacity>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="login" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => loginFunc()}>{i18n.t('login')}</Button>
                <Button mode="outlined" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => navigation.navigate('ForgotPassword', { title: i18n.t('forgotPassword'), backLabel: i18n.t('back') })}>{i18n.t('forgotPassword')}</Button>
                <View style={{flexDirection:"row", marginTop: 20}}>
                  <View style={{flex:1, alignItems:'flex-end', justifyContent: "center", marginRight: 5 }}>
                    <Subheading>{i18n.t('notYetRegistered')}</Subheading>
                  </View>
                  <View style={{flex:1, alignItems:'flex-start', marginLeft: 5}}>
                    <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-plus" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => navigation.navigate('SignUp', { title: i18n.t('signUp'), backLabel: i18n.t('back'), selectedDomainValue, curDropdownList, showDomainDropdownList })}>{i18n.t('signUp')}</Button>
                  </View>
                </View>
                <Button style={{ marginTop: 15 }} icon={({ color, size }) => (<MaterialCommunityIcons name="map-search" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}  onPress={() => navigation.navigate('GuestBrowseStudios', { title: i18n.t('browseStudios'), backLabel: i18n.t('back'), params: { selectedDomainValue } })}>{i18n.t('browseStudios')}</Button>
              </ScrollView>
              <View style={{ display: Platform.OS!='web' ? "flex" : "none" }}>
                <AdBanner />
              </View>
            </View>
            <RightColumnWeb/>
          </View>
          <BottomColumnWeb/>
          {MyAlertForWeb!=null && 
            <MyAlertForWeb
              show={alertForWebVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForWebText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForWebVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForWebVisible(false);
              }}  
            />
          }
          <PrivacyPolicy/>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}


const Stack = createStackNavigator();
const linking = {
  prefixes: Platform.OS === 'web' ? [window.location.origin] : ['mychat://'],
  config: {
    screens: {
      HandyBooker_Home: '',
      PlayerControlPanel: 'player-control-panel',
      PlayerMyProfile: 'player-my-profile',
      PlayerSettings: 'player-settings',
      PlayerContactUs: 'player-contact-us',
      OwnerControlPanel: 'owner-control-panel',
      OwnerMyProfile: 'owner-my-profile',
      OwnerSettings: 'owner-settings',
      OwnerPinOnMap: 'owner-pin-on-map',
      OwnerAddFirstRoom: 'owner-add-first-room',
      OwnerAddTennisClubMemberships: 'owner-add-tennis-club-memberships',
      OwnerManageTennisClubMemberships: 'owner-manage-tennis-club-memberships',
      OwnerManageRooms: 'owner-manage-rooms',
      GuestBrowseStudios: 'guest-browse-studios',
      SelectedOwnerSummary: 'selected-owner-summary',
      ForgotPassword: 'forgot-password',
      SignUp: 'sign-up',
      SignUpKey: 'sign-up-key',
      AdminPanel: 'admin-panel'
    },
  },
};

export default function App() {
  const linkingConfig = {
    config: linking.config,
    linking: linking.prefixes,
  };

  return (
    <Provider theme={MyCustomTheme} >
      <NavigationContainer /*linking={linkingConfig}*/>
        <Stack.Navigator initialRouteName="HandyBooker_Home" screenOptions={{ headerTitleStyle: styles.screenTitle }}  >
          <Stack.Screen name="HandyBooker_Home" component={HomeScreen} options={{ title: 'HandyBooker' }} />
          <Stack.Screen name="PlayerControlPanel" component={PlayerControlPanelView} options={{title: 'HandyBooker'}} />
          <Stack.Screen name="PlayerMyProfile" component={PlayerMyProfileView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="PlayerSettings" component={PlayerSettingsView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="PlayerContactUs" component={PlayerContactUsView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />

          <Stack.Screen name="OwnerControlPanel" component={OwnerControlPanelView} options={{ title: 'HandyBooker' }} />
          <Stack.Screen name="OwnerMyProfile" component={OwnerMyProfileView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="OwnerSettings" component={OwnerSettingsView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="OwnerPinOnMap" component={OwnerPinOnMapView} options={{ title: 'HandyBooker' }} />
          <Stack.Screen name="OwnerAddFirstRoom" component={OwnerAddFirstRoomView} options={{ title: 'HandyBooker' }} />
          <Stack.Screen name="OwnerAddTennisClubMemberships" component={OwnerAddTennisClubMembershipsView} options={{ title: 'HandyBooker' }} />
          <Stack.Screen name="OwnerManageTennisClubMemberships" component={OwnerManageTennisClubMembershipsView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="OwnerManageRooms" component={OwnerManageRoomsView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />

          <Stack.Screen name="SelectedOwnerSummary" component={SelectedOwnerSummaryView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="GuestBrowseStudios" component={GuestBrowseStudiosView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />

          <Stack.Screen name="ForgotPassword" component={ForgotPasswordView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="SignUp" component={SignUpView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />
          <Stack.Screen name="SignUpKey" component={SignUpKeyView} options={({ route }) => ({ title: route.params?.title, headerBackTitle: route.params?.backLabel })} />

          <Stack.Screen name="AdminPanel" component={AdminView} options={{ title: 'Admin Panel' }} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}