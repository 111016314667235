import React, { Fragment, useEffect, useState } from "react";
import { Dimensions, Keyboard, ScrollView, TouchableWithoutFeedback, View } from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { Button, Colors, Divider, RadioButton, Text, TextInput, Title } from "react-native-paper";
import styles from "../../../css/myCss";
import LoginManager from "../../../managers/LoginManager";
import i18n from '../../../utils/i18n';
import Tennis_ConstManager from "../../../managers/Tennis_ConstManager";
import Tennis_RestManager from "../../../managers/Tennis_RestManager";


const TennisPlayerFirstCheckModal = ({  
                                curOwner,
                                curReservationType_Tennis,
                                ownerMemberships_Tennis,
                                currency,
                                setSnackBarText
                            }) => {
    const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
    useEffect(() => {
      console.log("TennisPlayerFirstCheckModal: I'm ready!");
      
    }, [alreadyRunOnceOnViewLoad]);

    /*
    privateClub: "Ιδιωτικό Τένις Club",
    options: "Επιλογές",
    joinClub: "Εγγραφή στο club",
    iAmAlreadyAMember: "Είμαι ήδη μέλος",
    reserveAsGuest: "Κράτηση ως επισκέπτης",
    selectMembershipPlan: "Επιλογή πακέτου εγγραφής",
    sendRequest: "Αποστολή αιτήματος",
    requestSent: "Το αίτημα εστάλη! Θα ειδοποιηθείτε όταν το τένις club επεξεργαστεί το αίτημά σας.",

    public readonly JOIN_THE_CLUB: string = "Join the club";
    public readonly I_AM_ALREADY_MEMBER: string = "I am already member";
    public readonly MAKE_RESERVATION_AS_GUEST: string = "Make a reservation as guest";
    */

    const [isLoading, setIsLoading] = useState(false);  

    const [selectedReservationType, setSelectedReservationType] = useState(Tennis_ConstManager.getInstance().JOIN_THE_CLUB);
    const [selectedMembership, setSelectedMembership] = useState(ownerMemberships_Tennis[0]?.id.toString());

    const continueAction = async () => {
      if(selectedReservationType === Tennis_ConstManager.getInstance().JOIN_THE_CLUB) {
        setIsLoading(true);
        const tmpTennisClubRequest : Tennis_ClubRequest = {
          id: -1,
          from_user_email: LoginManager.getInstance().username,
          request_date: "",
          to_owner_id: curOwner.id,
          status: "",
          response_date: "",
          membership_id: parseInt(selectedMembership),
          says_he_is_already_member: false
        }
        var webServiceResponseStr = await Tennis_RestManager.getInstance().addClubRequest(tmpTennisClubRequest);
        if(webServiceResponseStr == Tennis_ConstManager.getInstance().CLUB_REQUEST_ADDED_SUCCESSFULLY)
          webServiceResponseStr = i18n.t('requestSent');
        else if(webServiceResponseStr == Tennis_ConstManager.getInstance().CLUB_REQUEST_ALREADY_SENT)
          webServiceResponseStr = i18n.t('youHaveAlreadySendARequest');
        setSnackBarText(webServiceResponseStr);
      }
      else if(selectedReservationType===Tennis_ConstManager.getInstance().I_AM_ALREADY_MEMBER) {
        setIsLoading(true);
        const tmpTennisClubRequest : Tennis_ClubRequest = {
          id: -1,
          from_user_email: LoginManager.getInstance().username,
          request_date: "",
          to_owner_id: curOwner.id,
          status: "",
          response_date: "",
          membership_id: -1,
          says_he_is_already_member: true
        }
        var webServiceResponseStr = await Tennis_RestManager.getInstance().addClubRequest(tmpTennisClubRequest);
        if(webServiceResponseStr == Tennis_ConstManager.getInstance().CLUB_REQUEST_ADDED_SUCCESSFULLY)
          webServiceResponseStr = i18n.t('requestSent');
        else if(webServiceResponseStr == Tennis_ConstManager.getInstance().CLUB_REQUEST_ALREADY_SENT)
          webServiceResponseStr = i18n.t('youHaveAlreadySendARequest');
        setSnackBarText(webServiceResponseStr);
      }
      else if(selectedReservationType===Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST) {
        setSnackBarText(Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST);
      }
    }

    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <ScrollView>
          <Spinner
            visible={isLoading}
            textContent={i18n.t('loading')}
            textStyle={styles.spinnerTextStyle}
          />
          <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('privateClub')}</Title>
          {curReservationType_Tennis==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY && <Text style={{ margin: 15, textAlign: "center"}}>{i18n.t('onlyClubMembersCanReserve')}</Text>}
          {curReservationType_Tennis==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS && <Text style={{ margin: 15, textAlign: "center"}}>{i18n.t('clubMembersAndNonClubMembersCanReserve')}</Text>}
          <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('options')}</Title>
          <RadioButton.Group onValueChange={newValue => setSelectedReservationType(newValue)} value={selectedReservationType}>
            <RadioButton.Item
              key={Tennis_ConstManager.getInstance().JOIN_THE_CLUB}
              label={i18n.t('joinClub')}
              value={Tennis_ConstManager.getInstance().JOIN_THE_CLUB}
              status={ selectedReservationType === Tennis_ConstManager.getInstance().JOIN_THE_CLUB ? 'checked' : 'unchecked' }
            />
            <RadioButton.Item
              key={Tennis_ConstManager.getInstance().I_AM_ALREADY_MEMBER}
              label={i18n.t('iAmAlreadyAMember')}
              value={Tennis_ConstManager.getInstance().I_AM_ALREADY_MEMBER}
              status={ selectedReservationType === Tennis_ConstManager.getInstance().I_AM_ALREADY_MEMBER ? 'checked' : 'unchecked' }
            />
            {curReservationType_Tennis==Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS && <RadioButton.Item
              key={Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST}
              label={i18n.t('reserveAsGuest')}
              value={Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST}
              status={ selectedReservationType === Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST ? 'checked' : 'unchecked' }
            />}
          </RadioButton.Group>
          <Divider />
          {selectedReservationType === Tennis_ConstManager.getInstance().JOIN_THE_CLUB && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{i18n.t('membershipOptions')}</Title>}
          {selectedReservationType === Tennis_ConstManager.getInstance().JOIN_THE_CLUB && <Fragment>
          <RadioButton.Group onValueChange={newValue => setSelectedMembership(newValue)} value={selectedMembership}>
          {ownerMemberships_Tennis.map(item => {
              return (
                  <View style={{ flexDirection: 'row', borderRadius: 5, borderColor: 'grey', borderWidth: 0.5, margin: 5, backgroundColor: selectedMembership===item.id.toString() ? "green" : Colors.blueGrey100  }} key={item.id}>
                    <View style={{ flex: 6 }}>
                      <View style={{ borderRadius: 5, borderColor: 'black', borderWidth: 0.5, margin: 5 }}>
                        <TextInput
                            label={i18n.t('membershipTitle')}
                            value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.title}
                            style={{ flex: 1, backgroundColor: 'lightgray' }}
                        />
                        <TextInput
                            label={i18n.t('membershipDescription')}
                            value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.description}
                            style={{ flex: 1 }}
                            multiline
                            numberOfLines={3}
                        />
                        <TextInput
                            label={i18n.t('membershipDuration')}
                            value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.duration}
                            style={{ flex: 1 }}
                        />
                        <TextInput
                            label={i18n.t('membershipCost')}
                            value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.cost}
                            style={{ flex: 1 }}
                            right={<TextInput.Affix text={currency}/>}
                        />
                      </View>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <RadioButton.Item
                        key={item.id.toString()}
                        label=''
                        value={item.id.toString()}
                        status={ selectedMembership === item.id.toString() ? 'checked' : 'unchecked' }
                      />
                    </View>
                  </View>
            )})}
            </RadioButton.Group>
          </Fragment>}
          <Button icon={
            (selectedReservationType===Tennis_ConstManager.getInstance().JOIN_THE_CLUB || selectedReservationType===Tennis_ConstManager.getInstance().I_AM_ALREADY_MEMBER) ? 'send-circle-outline'
            : selectedReservationType===Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST ? 'check-outline'
            : "ERROR!"
          } mode="contained" onPress={continueAction} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{
            (selectedReservationType===Tennis_ConstManager.getInstance().JOIN_THE_CLUB || selectedReservationType===Tennis_ConstManager.getInstance().I_AM_ALREADY_MEMBER) ? i18n.t('sendRequest')
            : selectedReservationType===Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST ? i18n.t('continue')
            : "ERROR!"
          }</Button>
        </ScrollView>
      </TouchableWithoutFeedback>
    );
  };

  export default React.memo(TennisPlayerFirstCheckModal);