import React, { useState, useEffect } from 'react';
import { Appbar, Button, Switch, Text, Snackbar, Portal, Dialog, Paragraph } from 'react-native-paper';
import { Alert, Dimensions, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View, Image } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import styles from '../../css/myCss';
import AdBanner from '../generic/AdBanner';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import Generic_ConstManager from '../../managers/Generic_ConstManager';
import * as Updates from 'expo-updates';
import { MaterialCommunityIcons } from '@expo/vector-icons';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';
import RightColumnWeb from '../generic/RightColumnWeb';
import LeftColumnWeb from '../generic/LeftColumnWeb';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const enFlag =  require("../../assets/EN_flag.png");
const enFlagSelected =  require("../../assets/EN_flag_selected.png");
const grFlag =  require("../../assets/GR_flag.png");
const grFlagSelected =  require("../../assets/GR_flag_selected.png");

const PlayerSettingsView = () => {
  const navigation = useNavigation();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("PlayerSettingsView: I'm ready!");
    setIsLoading(true);
    (async () => {
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
      if(handleWebPageReloadActuallyRun) {
        setCurMusician(LoginManager.getInstance().loggedInMusician);
        setCurTennisPlayer(LoginManager.getInstance().loggedInTennisPlayer);
      }
      else {
        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
          const tmpCurMusician : Studio_Player = await Studio_RestManager.getInstance().getPlayerByEmail(LoginManager.getInstance().username);
          setCurMusician(tmpCurMusician);
        }
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
          const tmpCurTennisPlayer : Tennis_Player = await Tennis_RestManager.getInstance().getPlayerByEmail(LoginManager.getInstance().username);
          setCurTennisPlayer(tmpCurTennisPlayer);
        }
      }
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const onDismissSnackBar = () => {
    setSnackBarVisible(false);
    setMessageToShow('');
  }

  const [deleteAccountDialogVisible, setDeleteAccountDialogVisible] = useState(false);
  const hideDeleteAccountDialog = () => setDeleteAccountDialogVisible(false);

  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow.length > 0)
    {
      var translatedMessageToShow: string | void = messageToShow;
      if(messageToShow === "Player deleted!") {
        translatedMessageToShow = i18n.t('accountDeleted');
        setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
          if(MyAlertReactNative != null) {
            // Works on both Android and iOS
            MyAlertReactNative.alert(
              translatedMessageToShow,
              '',
              [
                {
                  text: 'OK',
                  onPress: () => {
                    logout();
                  }
                }
              ],
              { cancelable: false }
            );
          }
          else if(MyAlertForWeb != null) {
            setAlertForWebText(i18n.t('accountDeleted')); 
            setAlertForWebVisible(true);
          }
        }, 500);
      }
      else {
        setSnackBarVisible(true);
      }      
    }
  }, [isLoading]);
  
  const [curMusician, setCurMusician] = useState<Studio_Player>({
    id: -1,
    name: '',
    surname: '',
    telephone: '',
    email: '',
    password: '',
    last_login: '',
    language: '',
    email_notifications: false,
    registration_date: '',
    verified: false,    //verified is when he succesfully adds the verification code sent by HandyBooker
    band_name: ''
  });

  const [curTennisPlayer, setCurTennisPlayer] = useState<Tennis_Player>({
    id: -1,
    name: '',
    surname: '',
    telephone: '',
    email: '',
    password: '',
    last_login: '',
    language: '',
    email_notifications: false,
    registration_date: '',
    verified: false    //verified is when he succesfully adds the verification code sent by HandyBooker
  });

  const [updateLanguageNow, setUpdateLanguageNow] = useState(false);
  useEffect(() => {
    if(updateLanguageNow === true) {
      setUpdateLanguageNow(false);
      editPlayerAsync();
    }
  }, [curMusician.language, curTennisPlayer.language]);

  const [updateEmailNotificationsNow, setUpdateEmailNotificationsNow] = useState(false);
  useEffect(() => {
    if(updateEmailNotificationsNow === true) {
      setUpdateEmailNotificationsNow(false);
      editPlayerAsync();
      if((LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && curMusician.email_notifications === true)
          || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && curTennisPlayer.email_notifications === true))
        setMessageToShow(i18n.t('yesEmailNotifications'));
      else
        setMessageToShow(i18n.t('noEmailNotifications'));
    }
  }, [curMusician.email_notifications, curTennisPlayer.email_notifications]);

  const screenDimensions = Dimensions.get('window');

  const [curEnFlag, setCurEnFlag] = useState(enFlagSelected);
  const [curGrFlag, setCurGrFlag] = useState(grFlag);
  useEffect(() => {    
    if( (LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && curMusician.language==='EN' )
        || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && curTennisPlayer.language==='EN' ) )
    {
      i18n.locale = 'en';
      setCurEnFlag(enFlagSelected);
      setCurGrFlag(grFlag);
      LoginManager.getInstance().language = LoginManager.getInstance().EN;
      if(Platform.OS != 'web')
        SecureStore.setItemAsync('storedLanguage', JSON.stringify('en')).catch((error) => console.log('Error during storedEmail storage!', error));
      else //web
        sessionStorage.setItem('storedLanguage', 'en');
    }
    else if( (LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && curMusician.language==='GR' )
        || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && curTennisPlayer.language==='GR' ) )
    {
      i18n.locale = 'gr';
      setCurEnFlag(enFlag);
      setCurGrFlag(grFlagSelected);
      LoginManager.getInstance().language = LoginManager.getInstance().GR;
      if(Platform.OS != 'web')
        SecureStore.setItemAsync('storedLanguage', JSON.stringify('gr')).catch((error) => console.log('Error during storedEmail storage!', error));
      else //web
        sessionStorage.setItem('storedLanguage', 'gr');
    }
  }, [curMusician.language, curTennisPlayer.language, curEnFlag, curGrFlag]);

  
  async function editPlayerAsync() {        
    setIsLoading(true);       
    let editUserResponse;
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      editUserResponse = await Studio_RestManager.getInstance().editPlayer(curMusician);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      editUserResponse = await Tennis_RestManager.getInstance().editPlayer(curTennisPlayer);
    setIsLoading(false);
  }

  async function deletePlayerAsync() {     
    setDeleteAccountDialogVisible(false);   
    setIsLoading(true);       
    let webServiceResponseStr : string | void;
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      webServiceResponseStr = await Studio_RestManager.getInstance().deletePlayer(curMusician.email);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      webServiceResponseStr = await Tennis_RestManager.getInstance().deletePlayer(curTennisPlayer.email);
    setMessageToShow(webServiceResponseStr);
    setIsLoading(false); 
  }

  function logout() {
    LoginManager.getInstance().logout();
    navigation.reset({
      index: 1,
      routes: [{ name: 'HandyBooker_Home' }]
    });
  }

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={[styles.columnMainApp, { justifyContent:'flex-start' }]}>
              <ScrollView style={styles.scrollView}>
                <Appbar.Header>
                  <Appbar.Content title={i18n.t('settings')} />

                <Portal>
                  <Dialog visible={deleteAccountDialogVisible} onDismiss={hideDeleteAccountDialog} style={styles.dialog}>
                    <Dialog.Title>{i18n.t('deleteAccount')}</Dialog.Title>
                    <Dialog.Content>
                      <Paragraph>{i18n.t('areYouSure')}</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                      <Button onPress={() => setDeleteAccountDialogVisible(false)}>{i18n.t('cancel')}</Button>
                      <Button onPress={() => deletePlayerAsync()}>Ok</Button>
                    </Dialog.Actions>
                  </Dialog>
                </Portal>
                </Appbar.Header>

                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />

                <View style={{flexDirection:"row", marginTop: 20}}>

                  <View style={{flex:1, alignItems:'flex-end'}}>
                    <TouchableOpacity
                      onPress={() => {
                          if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) { 
                            setCurMusician({...curMusician, 
                              language: LoginManager.getInstance().EN
                            });
                          }
                          else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) { 
                            setCurTennisPlayer({...curTennisPlayer, 
                              language: LoginManager.getInstance().EN
                            });
                          }
                          LoginManager.getInstance().language = LoginManager.getInstance().EN;
                          setUpdateLanguageNow(true);
                        }
                      }            
                    >
                      <Image source={curEnFlag} style={styles.flag} />
                    </TouchableOpacity>
                  </View>

                  <View style={{flex:1, alignItems:'flex-start'}}>
                    <TouchableOpacity style={{justifyContent:"center"}}
                      onPress={() => { 
                          if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) { 
                            setCurMusician({...curMusician, 
                              language: LoginManager.getInstance().GR
                            });
                          }
                          else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) { 
                            setCurTennisPlayer({...curTennisPlayer, 
                              language: LoginManager.getInstance().GR
                            });
                          }
                          LoginManager.getInstance().language = LoginManager.getInstance().GR;
                          setUpdateLanguageNow(true);
                        }
                      }
                    >
                      <Image source={curGrFlag} style={styles.flag} />
                    </TouchableOpacity>
                  </View>
                </View>
    
                <View style={{flexDirection:"row", marginTop: 20}}>
                  <Text style={styles.notificationSettingsText}>{i18n.t('receiveEmailNotifications')}</Text> 
                  {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && 
                    <Switch style={styles.notificationSettingsSwitch} value={curMusician.email_notifications} onValueChange={() => {
                        setCurMusician({...curMusician, 
                          email_notifications: !curMusician.email_notifications
                        });
                        setUpdateEmailNotificationsNow(true);
                      }} 
                    />    
                  }
                  {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && 
                    <Switch style={styles.notificationSettingsSwitch} value={curTennisPlayer.email_notifications} onValueChange={() => {
                        setCurTennisPlayer({...curTennisPlayer, 
                          email_notifications: !curTennisPlayer.email_notifications
                        });
                        setUpdateEmailNotificationsNow(true);
                      }} 
                    />    
                  }
                </View> 
              </ScrollView>
              <View style={{flexDirection:"column"}}>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-remove" color={color} size={size} />)} mode="contained" style={{alignSelf: 'flex-end', width: Platform.OS!="web" ? screenDimensions.width : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH}} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContentRed} onPress={() => setDeleteAccountDialogVisible(true)}>{i18n.t('deleteAccount')}</Button>
              </View>
            </View>
            <RightColumnWeb/>
            
            <Snackbar
              style={{borderColor: 'white', borderWidth: 3, backgroundColor: '#6200EE', bottom: 100}}
              visible={snackBarVisible}
              onDismiss={onDismissSnackBar}>
              {messageToShow}
            </Snackbar>   
            {MyAlertForWeb!=null && 
              <MyAlertForWeb
                show={alertForWebVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForWebText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  logout();
                }}
                onConfirmPressed={() => {
                  logout();
                }}  
              />
            }
          </View>
        </TouchableWithoutFeedback>
        <AdBanner/>
      </KeyboardAvoidingView>
    );
  };

export default PlayerSettingsView;