import { Dimensions } from 'react-native';

class GenericConstManager {
    public readonly screenDimensions = Dimensions.get('window');

    public readonly UNKNOWN: string = "UNKNOWN";
    public readonly STUDIO: string = "STUDIO";
    public readonly TENNIS: string = "TENNIS";

    public readonly PLAYER: string = "Player";
    public readonly OWNER: string = "Owner";

    public readonly I_WANT_A_KEY: string = "I want a key";
    public readonly I_HAVE_A_KEY: string = "I have a key";

    public readonly GUEST_ROLE : number = 0;
    public readonly PLAYER_ROLE : number = 1;
    public readonly OWNER_ROLE : number = 2;
    public readonly ADMIN_ROLE : number = 3;

    public readonly RESERVATION_ADDED_MSG : string = "Reservation added successfully!";
    public readonly ANOTHER_USER_EXISTS_WITH_THIS_EMAIL : string = "Another user exists with this email!";

    public readonly INTRUDER_MSG : string = "You do not have permissions for this!";

    public readonly UPDATE_NOTIFICATIONS_EVERY_MILLISECONDS : number = 45000;

    public readonly WEB_MAX_WIDTH = 1200;
    public readonly WEB_MAX_HEIGHT = 1000;

    public readonly WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS = 500;

    public readonly WEB_LEFT_COLUMN_WIDTH = this.screenDimensions.width>this.WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS ? 150 : 0;
    public readonly WEB_MAINAPP_COLUMN_WIDTH = this.screenDimensions.width<900 ? this.screenDimensions.width : 900;
    public readonly WEB_RIGHT_COLUMN_WIDTH = this.screenDimensions.width>this.WEB_MIN_SCREEN_WIDTH_FOR_SHOWING_LEFTRIGHT_COLUMNS ? 150 : 0;

    public readonly WEB_BOTTOM_NAVIGATION_HEIGHT = 653;
    //public readonly MOBILE_BOTTOM_NAVIGATION_HEIGHT = 55;

    public readonly WEB_MAP_LAT_OFFSET = -0.000220; //-0.000530; (oso ayksanw apolyth timh -> katevainei o marker)
    public readonly WEB_MAP_LON_OFFSET = -0.000028;  //-0.000035; (oso ayksanw apolyth timh -> paei aristera o marker)

    public readonly ADMOB_ANDROID_APPID : string = "ca-app-pub-5813155632580992~8975202158"; //den to xrhsimopoiw - to xw gia pan endexomeno edw
    public readonly ADMOB_ANDROID_UNITID : string = "ca-app-pub-5813155632580992/8436146107";
    public readonly ADMOB_IOS_APPID : string = "ca-app-pub-5813155632580992~8937427722"; //den to xrhsimopoiw - to xw gia pan endexomeno edw
    public readonly ADMOB_IOS_UNITID : string = "ca-app-pub-5813155632580992/9947257063";

    private static instance: GenericConstManager;

    private constructor() { 
    }

    public static getInstance(): GenericConstManager {
        if (!GenericConstManager.instance) {
            GenericConstManager.instance = new GenericConstManager();
        }

        return GenericConstManager.instance;
    }
}

export default GenericConstManager;