import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, TouchableOpacity, TouchableWithoutFeedback, View, Text, ScrollView, RefreshControl } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import {LocaleConfig, Agenda} from 'react-native-calendars';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import UtilsManager from '../../managers/UtilsManager';
import styles from '../../css/myCss';
import moment from 'moment';
import { Divider, FAB, Modal, Portal, Snackbar } from 'react-native-paper';
import OwnerNewReservationModal from './OwnerNewReservationModal';
import OwnerSelectedReservationModal from './OwnerSelectedReservationModal';
import Tip from '../generic/Tip';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';

const calendarAgendaConfig = require('../calendarAgendaConfig');

//Calendar
  //react native
    //already imported above
  //web
  import { Calendar, momentLocalizer } from "react-big-calendar";
  //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
  import "react-big-calendar/lib/css/react-big-calendar.css";
  
  import "moment/locale/el"; // Import the Greek locale data
import { MaterialCommunityIcons } from '@expo/vector-icons';

  const localizer = momentLocalizer(moment);  

  let MyCalendarReactNative;
  let MyCalendarForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyCalendarReactNative = null;
    MyCalendarForWeb = Calendar;
  } else {                      //REACT NATIVE
    MyCalendarReactNative = Agenda;
    MyCalendarForWeb = null;
  }
//-Calendar

const OwnerMyReservationsView = () => {
  const navigation = useNavigation();
  const agendaRef = useRef(null);

  //test hard-coded agenda items for web
  /*const calendarEventsForWeb = [
    {
      start: moment("2023-10-28T18:00:00").toDate(),
      end: moment("2023-10-28T19:00:00").toDate(),
      title: "Πρόβα\nEmerald sun",
    }
  ];*/
  
  // Customized text for the navigation buttons
  const webCalendarMessages = LoginManager.getInstance().language == LoginManager.getInstance().EN ? {
    today: 'Today',
    previous: 'Previous',
    next: 'Next',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Time',
    event: 'Reservation'
  } : {
    today: 'Σήμερα',
    previous: 'Προηγούμενο',
    next: 'Επόμενο',
    month: 'Μήνας',
    week: 'Εβδομάδα',
    day: 'Ημέρα',
    agenda: 'Ατζέντα',
    date: 'Ημερομηνία',
    time: 'Ώρα',
    event: 'Κράτηση'
  };
  const [calendarEventsForWeb, setCalendarEventsForWeb] = useState([]); 
  const [calendarEventsForWebAllInfo, setCalendarEventsForWebAllInfo] = useState<Array<ReservationDetailsRetrievedFromAgendaItem>>([]); 

  const [selectedDateWebCalendar, setSelectedDateWebCalendar] = useState(null);

  const handleNavigateWebCalendar = (newDate, view, action) => {
    setSelectedDateWebCalendar(null); // Reset selectedDate when navigating to a new month
  };

  const handleSelectDateWeb = slotInfo => {
    setSelectedDateWebCalendar(slotInfo.start);
    const selectedMoment = moment(slotInfo.start);
    console.log('Date Clicked:', selectedMoment.format('DD/MM/YYYY'));
    setSelectedDate(UtilsManager.getInstance().getSelectedDayTitle(selectedMoment.day(), selectedMoment.month() + 1, selectedMoment.year(), selectedMoment.valueOf()));
    setNewReservation_date(selectedMoment.format('DD/MM/YYYY'));
  };

  const getCalendarDayStyleWeb = (date) => {
    const isCurrentDay = moment().isSame(date, 'day');
    const isSelectedDate = selectedDateWebCalendar && moment(selectedDateWebCalendar).isSame(date, 'day');

    let tmpBackgroundColor = 'whitesmoke';

    if(isCurrentDay==true)
      tmpBackgroundColor = 'lightblue';
    if(isCurrentDay==true && isSelectedDate==true)
      tmpBackgroundColor = 'lightsteelblue';
    else if(isCurrentDay==false && isSelectedDate==true)
      tmpBackgroundColor = 'gainsboro'; // Highlight selected date with light gray background

    return {
      style: {
        backgroundColor: tmpBackgroundColor
      }
    };
  };

  const handleEventClickWeb = (event) => {
    // Display an alert when an agenda item is clicked
    //alert(`Clicked on Agenda Item: ${event.title}`);
    //alert(`Clicked on Agenda Item: ${JSON.stringify(event, null, 2)}`);

    var tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem;
    //find the corresponding object from the list
    for (const tempItem of calendarEventsForWebAllInfo) {
      const tempJsonObject = JSON.parse(tempItem.webAgendaItem_JSONstringified);

      if(tempJsonObject.start==event.start.toISOString() && tempJsonObject.end==event.end.toISOString() && tempJsonObject.title==event.title)
        tmpReservationDetailsRetrievedFromAgendaItem = tempItem;
    }
    if(tmpReservationDetailsRetrievedFromAgendaItem == undefined) {
      alert(`Clicked on Agenda Item: ${JSON.stringify(event, null, 2)}`);
    }
    else {
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN
          || tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN)
        {
          agendaItemOnClicked(tmpReservationDetailsRetrievedFromAgendaItem.ownerID, tmpReservationDetailsRetrievedFromAgendaItem.itemDomain);
        }
        else if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_OWNER_IN_STUDIO_DOMAIN) //owner acts as owner for his studio
        {  
          agendaItemOnClicked_Studio(tmpReservationDetailsRetrievedFromAgendaItem.reservationID,
                  tmpReservationDetailsRetrievedFromAgendaItem.roomName,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationDate,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime,
                  tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? "yes" : "no",
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationCost,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationComments,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerName,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerTel,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerEmail,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerBand);
        }    
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        //handle reservations where owner acts as player
        if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN
        || tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN)
        {
          agendaItemOnClicked(tmpReservationDetailsRetrievedFromAgendaItem.ownerID, tmpReservationDetailsRetrievedFromAgendaItem.itemDomain);
        }
        else if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_OWNER_IN_TENNIS_DOMAIN) {  //owner acts as owner for his tennis courts
          agendaItemOnClicked_Tennis(tmpReservationDetailsRetrievedFromAgendaItem.reservationID,
                  tmpReservationDetailsRetrievedFromAgendaItem.courtName,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationDate,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationCost,
                  tmpReservationDetailsRetrievedFromAgendaItem.reservationComments,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerName,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerTel,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerEmail,
                  tmpReservationDetailsRetrievedFromAgendaItem.playerTag);
        }    
      }
    }
  };

  //set calendar locale
  LocaleConfig.locales[LoginManager.getInstance().language.toLowerCase()] = {
    monthNames: [i18n.t('january'),i18n.t('february'),i18n.t('march'),i18n.t('april'),i18n.t('may'),i18n.t('june'),i18n.t('july'),i18n.t('august'),i18n.t('september'),i18n.t('october'),i18n.t('november'),i18n.t('december')],
    monthNamesShort: [i18n.t('januaryShort'),i18n.t('februaryShort'),i18n.t('marchShort'),i18n.t('aprilShort'),i18n.t('mayShort'),i18n.t('juneShort'),i18n.t('julyShort'),i18n.t('augustShort'),i18n.t('septemberShort'),i18n.t('octoberShort'),i18n.t('novemberShort'),i18n.t('decemberShort')],
    dayNames: [i18n.t('sunday'),i18n.t('monday'),i18n.t('tuesday'),i18n.t('wednesday'),i18n.t('thursday'),i18n.t('friday'),i18n.t('saturday')],
    dayNamesShort: [i18n.t('sundayShort'),i18n.t('mondayShort'),i18n.t('tuesdayShort'),i18n.t('wednesdayShort'),i18n.t('thursdayShort'),i18n.t('fridayShort'),i18n.t('saturdayShort')],
    today: i18n.t('today')
  };
  LocaleConfig.defaultLocale = LoginManager.getInstance().language.toLowerCase(); //it could be gr/fr/whatever, as the values are already translated to the current language
  //-set calendar locale

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("useEffect - START - alreadyRunOnceOnViewLoad");
    console.log("OwnerMyReservationsView: I'm ready!");
    if(LoginManager.getInstance().language == LoginManager.getInstance().GR)
      moment.locale("el");
    else
      moment.locale("en");
    //console.log("curDate: " + curDate);
    //console.log("moment curDate: " + moment().format('YYYY-MM-DD').toString());
    (async () => {
      setIsLoading(true);
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
     
      if(LoginManager.getInstance().language == LoginManager.getInstance().EN) {
        moment.locale('en'); // Set the moment.js locale
      }
      else if(LoginManager.getInstance().language == LoginManager.getInstance().GR) {
        moment.locale('el'); // Set the moment.js locale
      }

      //get all reservations (agenda items)
      var tmpAllAgendaItemsToSet;
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        tmpAllAgendaItemsToSet = await Studio_RestManager.getInstance().getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate(LoginManager.getInstance().ID);
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        tmpAllAgendaItemsToSet = await Tennis_RestManager.getInstance().getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate(LoginManager.getInstance().ID);
      console.log("--------------------");
      console.log(tmpAllAgendaItemsToSet);
      console.log("--------------------");
      setAgendaItems(tmpAllAgendaItemsToSet);
      
      setIsLoading(false);
      //setShowTip(true);
      console.log("useEffect - STOP - alreadyRunOnceOnViewLoad");
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const todayDate = new Date();
  //console.log("todayDate: " + JSON.stringify(todayDate, null, 2));
  
  

  const [selectedDate, setSelectedDate] = useState(UtilsManager.getInstance().getSelectedDayTitle(todayDate.getDate(), todayDate.getMonth()+1, todayDate.getFullYear(), todayDate.getMilliseconds()));

  const [retrieveNewAgendaItemsNow, setRetrieveNewAgendaItemsNow] = useState(false);
  useEffect(() => {
    console.log("useEffect - START - retrieveNewAgendaItemsNow");
    if(retrieveNewAgendaItemsNow==true && Object.entries(agendaItems).length==0) { //this is true when the user pulls down to refresh and "handleRefresh" function is called
      (async () => {
        var tmpAllAgendaItemsToSet;
        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
          tmpAllAgendaItemsToSet = await Studio_RestManager.getInstance().getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate(LoginManager.getInstance().ID);
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
          tmpAllAgendaItemsToSet = await Tennis_RestManager.getInstance().getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate(LoginManager.getInstance().ID);
        console.log("tmpAllAgendaItemsToSet: ", tmpAllAgendaItemsToSet);
        setAgendaItems(tmpAllAgendaItemsToSet);
        setRetrieveNewAgendaItemsNow(false);
        if(isLoading == true)
          setIsLoading(false);
        if(agendaIsRefresing == true) 
          setAgendaIsRefresing(false);
      })();
    }
    console.log("useEffect - STOP - retrieveNewAgendaItemsNow");
  }, [retrieveNewAgendaItemsNow]);
  
  const [agendaItems, setAgendaItems] = useState({
    //'2022-06-12': [{name: 'item 1 - any js object', height: 100}]//,
    //'2021-04-18': [{name: 'item 2 - any js object', height: 100}],
    //'2021-04-19': [{name: 'item 3 - any js object', height: 100}, {name: 'any js object', height: 100}]
  });
  useEffect(() => {
    if (Platform.OS === 'web') {  //WEB
      var tmpNewCalendarEventsForWeb = [];

      /*const calendarEventsForWeb = [
        {
          start: moment("2023-10-28T18:00:00").toDate(),
          end: moment("2023-10-28T19:00:00").toDate(),
          title: "Πρόβα\nEmerald sun",
        }
      ];*/
      
      // Iterate over the properties of the input object
      for (const date in agendaItems) {
        if (agendaItems.hasOwnProperty(date)) {
          const dateItems = agendaItems[date];
          for (const item of dateItems) {
            const tmpReservationData = item.name;
            console.log("tmpReservationData -> ", tmpReservationData);
            
            const tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem = UtilsManager.getInstance().parseReservationDetailsFromAgendaItem(tmpReservationData);
            
            var tempCalItem = {
              start: moment(UtilsManager.getInstance().tranformDateToISOFormat(tmpReservationDetailsRetrievedFromAgendaItem.reservationDate) + "T" + tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime + ":00").toDate(),
              end: moment(UtilsManager.getInstance().tranformDateToISOFormat(tmpReservationDetailsRetrievedFromAgendaItem.reservationDate) + "T" + tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime + ":00").toDate(),
              title: tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime + "-" + tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime + "\n" 
                     + (tmpReservationDetailsRetrievedFromAgendaItem.itemDomain==LoginManager.getInstance().STUDIO ? tmpReservationDetailsRetrievedFromAgendaItem.roomName : tmpReservationDetailsRetrievedFromAgendaItem.itemDomain==LoginManager.getInstance().TENNIS ? tmpReservationDetailsRetrievedFromAgendaItem.courtName : "unknown")  + "\n" 
                     + tmpReservationDetailsRetrievedFromAgendaItem.playerName + "\n"
                     + (tmpReservationDetailsRetrievedFromAgendaItem.itemDomain==LoginManager.getInstance().STUDIO && tmpReservationDetailsRetrievedFromAgendaItem.playerBand!="" ? tmpReservationDetailsRetrievedFromAgendaItem.playerBand : "")
            };
            tmpNewCalendarEventsForWeb.push(tempCalItem); //this is to show the events in the agenda

            tmpReservationDetailsRetrievedFromAgendaItem.webAgendaItem_JSONstringified = JSON.stringify(tempCalItem); //this is to have all the info for each reservation stored to show it when the agenda event is clicked
            setCalendarEventsForWebAllInfo(calendarEventsForWebAllInfo => [...calendarEventsForWebAllInfo, tmpReservationDetailsRetrievedFromAgendaItem]); //add this to the list
          }
        }
      }
      setCalendarEventsForWeb(tmpNewCalendarEventsForWeb);
    }
  }, [agendaItems]);

  const [extraData, setExtraData] = useState(0);
    
  const [messageToShowForReservationCancellation, setMessageToShowForReservationCancellation] = useState('');  
  useEffect(() => {
    console.log("useEffect - START - messageToShowForReservationCancellation");
    if(messageToShowForReservationCancellation != "") {
      hideSelectedReservationDetailsModal();          
      
      const timeoutId = setTimeout(() => {
        setSnackBarText(messageToShowForReservationCancellation);
        setMessageToShowForReservationCancellation(""); 
      }, 1000); // 1 second

      return () => {
        clearTimeout(timeoutId); // Cleanup function to clear the timeout
      };
    }
    console.log("useEffect - STOP - messageToShowForReservationCancellation");
  }, [messageToShowForReservationCancellation])
  const [isLoading, setIsLoading] = useState(false);
    

  //const handleRefresh = useCallback(() => {
  const handleRefresh = (() => {
    //console.log("handleRefresh - start");
    //hideNewReservationModal(); //if visible
    setAgendaIsRefresing(true);
    setIsLoading(true);
    setAgendaItems({}); //I need this 2-step process (first make agenda empty and then re-fill it, because otherwise update has problems)
    setRetrieveNewAgendaItemsNow(true);
    //console.log("handleRefresh - end");
  //}, [agendaItems]);
  });

  const [curReservationDetails_Studio, setCurReservationDetails_Studio] = useState({
    reservation_id: -1,
    room_name: '',
    reservation_date: '',
    reservation_start_time: '',
    reservation_stop_time: '',
    rehearsal_recording_str: '',
    reservation_cost: '',
    reservation_comments: '',
    musician_name: '',
    musician_tel: '',
    musician_email: '',
    musician_band: ''
  });

  const [curReservationDetails_Tennis, setCurReservationDetails_Tennis] = useState({
    reservation_id: -1,
    court_name: '',
    reservation_date: '',
    reservation_start_time: '',
    reservation_stop_time: '',
    reservation_cost: '',
    reservation_comments: '',
    player_name: '',
    player_tel: '',
    player_email: '',
    player_tag: ''
  });

  const [visibleSelectedReservationDetailsModal, setVisibleSelectedReservationDetailsModal] = useState(false);
  useEffect(() => {
    console.log("useEffect - START - visibleSelectedReservationDetailsModal");
    if(visibleSelectedReservationDetailsModal == true) {
      //setMessageToShowForReservationCancellation(""); //init
    }
    console.log("useEffect - STOP - visibleSelectedReservationDetailsModal");
  }, [visibleSelectedReservationDetailsModal]);
  const hideSelectedReservationDetailsModal = () => setVisibleSelectedReservationDetailsModal(false);
  
  const agendaItemOnClicked_Studio = useCallback((tmpReservationID: number,
                                     tmpRoomName: string,
                                     tmpReservationDate: string,
                                     tmpReservationStartTime: string,
                                     tmpReservationStopTime: string,
                                     tmpRehearsalRecordingStr: string,
                                     tmpReservationCost: string,
                                     tmpReservationComments: string,
                                     tmpMusicianName: string,
                                     tmpMusicianTel: string,
                                     tmpMusicianEmail: string,
                                     tmpMusicianBand: string) => 
  {
    setCurReservationDetails_Studio({...curReservationDetails_Studio, 
      reservation_id: tmpReservationID,
      room_name: tmpRoomName,
      reservation_date: tmpReservationDate,
      reservation_start_time: tmpReservationStartTime,
      reservation_stop_time: tmpReservationStopTime,
      rehearsal_recording_str: tmpRehearsalRecordingStr,
      reservation_cost: tmpReservationCost,
      reservation_comments: tmpReservationComments,
      musician_name: tmpMusicianName,
      musician_tel: tmpMusicianTel,
      musician_email: tmpMusicianEmail,
      musician_band: tmpMusicianBand
    })
    setVisibleSelectedReservationDetailsModal(true);
    //Alert.alert("item clicked - reservation ID: " + tmpReservationID);
  }, [curReservationDetails_Studio]);

  const agendaItemOnClicked_Tennis = useCallback((tmpReservationID: number,
      tmpCourtName: string,
      tmpReservationDate: string,
      tmpReservationStartTime: string,
      tmpReservationStopTime: string,
      tmpReservationCost: string,
      tmpReservationComments: string,
      tmpPlayerName: string,
      tmpPlayerTel: string,
      tmpPlayerEmail: string,
      tmpPlayerTag: string) => 
  {
    setCurReservationDetails_Tennis({...curReservationDetails_Tennis, 
      reservation_id: tmpReservationID,
      court_name: tmpCourtName,
      reservation_date: tmpReservationDate,
      reservation_start_time: tmpReservationStartTime,
      reservation_stop_time: tmpReservationStopTime,
      reservation_cost: tmpReservationCost,
      reservation_comments: tmpReservationComments,
      player_name: tmpPlayerName,
      player_tel: tmpPlayerTel,
      player_email: tmpPlayerEmail,
      player_tag: tmpPlayerTag
    })
    setVisibleSelectedReservationDetailsModal(true);
    //Alert.alert("item clicked - reservation ID: " + tmpReservationID);
  }, [curReservationDetails_Tennis]);

  async function agendaItemOnClicked(tmpSelectedOwnerID: number, selectedDomainValue: string){
    //find owner 
    var currentSelectedOwner : Owner;
    if(selectedDomainValue == LoginManager.getInstance().STUDIO)
      currentSelectedOwner = await Studio_RestManager.getInstance().getOwnerById(tmpSelectedOwnerID);
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
      currentSelectedOwner = await Tennis_RestManager.getInstance().getOwnerById(tmpSelectedOwnerID);
    //-find owner
  
    navigation.navigate('SelectedOwnerSummary', { 
      title: selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('studioInfo') 
              : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourtInfo') 
              : "ERROR", 
      backLabel: i18n.t('back'),
      params: {selectedDomainValue, currentSelectedOwner}
    });
  }

  const renderItem_Studio = useCallback((item) => {
    const tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem = UtilsManager.getInstance().parseReservationDetailsFromAgendaItem(item.name);

    //handle reservations where owner acts as player
    if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN
        || tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN)
    {
      return (
        <ScrollView style={styles.scrollView}>
          {((tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO //kopse ta diplotypa - an o owner ekane krathsh sto studio toy, mhm ton pianeis san player!
            && tmpReservationDetailsRetrievedFromAgendaItem.ownerID != LoginManager.getInstance().loggedInOwner.id)
            || tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS)
            && <TouchableOpacity
            testID={calendarAgendaConfig.agenda.ITEM}
            style={[styles.item, {height: item.height}]}
            onPress={() => agendaItemOnClicked(tmpReservationDetailsRetrievedFromAgendaItem.ownerID, tmpReservationDetailsRetrievedFromAgendaItem.itemDomain)}
          >
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('category')}: <Text style={{fontWeight: "normal", color: "brown"}}>{i18n.t('studioCategory')}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('category')}: <Text style={{fontWeight: "normal", color: "green"}}>{i18n.t('tennisCategory')}</Text></Text>}
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('date')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationDate}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('hours')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime} - {tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime}</Text></Text>
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('studio')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.companyName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('company')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.companyName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('room')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.roomName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('tennisCourt')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.courtName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('type')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? i18n.t('rehearsal') : i18n.t('recording')}</Text></Text>}
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('totalCost')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationCost}</Text></Text>        
            <Text style={{flexDirection:"row", fontWeight: "bold", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{i18n.t('comments')}: <Text style={{fontWeight: "normal", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationComments}</Text></Text>
          </TouchableOpacity>}
        </ScrollView>
      );
    }
    else if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_OWNER_IN_STUDIO_DOMAIN) {  //owner acts as owner for his studio
      return (
        <ScrollView style={styles.scrollView}>
          <TouchableOpacity
            testID={calendarAgendaConfig.agenda.ITEM}
            style={[styles.item, {height: item.height}]}
            onPress={() => agendaItemOnClicked_Studio(tmpReservationDetailsRetrievedFromAgendaItem.reservationID,
              tmpReservationDetailsRetrievedFromAgendaItem.roomName,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationDate,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime,
              tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? "yes" : "no",
              tmpReservationDetailsRetrievedFromAgendaItem.reservationCost,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationComments,
              tmpReservationDetailsRetrievedFromAgendaItem.playerName,
              tmpReservationDetailsRetrievedFromAgendaItem.playerTel,
              tmpReservationDetailsRetrievedFromAgendaItem.playerEmail,
              tmpReservationDetailsRetrievedFromAgendaItem.playerBand
            )}
          >
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('room')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.roomName}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('date')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationDate}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('hours')}: <Text style={{fontWeight: "normal", color: "blue"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime} - {tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('type')}: <Text style={{fontWeight: "normal", color: tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? "green" : "red" }}>{tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? i18n.t('rehearsal') : i18n.t('recording')}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('totalCost')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationCost}</Text></Text>        
            <Text style={{flexDirection:"row", fontWeight: "bold", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{i18n.t('comments')}: <Text style={{fontWeight: "normal", color: "blue", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationComments}</Text></Text>
            <Divider style={{ marginTop: 5, marginBottom: 5 }}/>
            {tmpReservationDetailsRetrievedFromAgendaItem.playerEmail!="" && 
              <View style={{ backgroundColor: "black", borderWidth: 3, borderRadius: 5, padding: 2 }}>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>{i18n.t('musician')}: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerName}</Text></Text>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>{i18n.t('telephone')}: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerTel}</Text></Text>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>Email: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerEmail}</Text></Text>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>{i18n.t('bandCapital')}: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerBand}</Text></Text>
              </View>
            }
            {tmpReservationDetailsRetrievedFromAgendaItem.playerEmail=="" && <Text style={{fontStyle: "italic", color: "grey"}}>{i18n.t('reservationMadeByYou')}</Text>}
          </TouchableOpacity>
        </ScrollView>
      );
    }    
  }, []);

  const renderItem_Tennis = useCallback((item) => {
    const tmpReservationDetailsRetrievedFromAgendaItem : ReservationDetailsRetrievedFromAgendaItem = UtilsManager.getInstance().parseReservationDetailsFromAgendaItem(item.name);
    
    //handle reservations where owner acts as player
    if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN
      || tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN)
    {
      return (
        <ScrollView style={styles.scrollView}>
          {((tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS //kopse ta diplotypa - an o owner ekane krathsh sta ghpeda toy, mhm ton pianeis san player!
            && tmpReservationDetailsRetrievedFromAgendaItem.ownerID != LoginManager.getInstance().loggedInOwner.id)
            || tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO)
            && <TouchableOpacity
            testID={calendarAgendaConfig.agenda.ITEM}
            style={[styles.item, {height: item.height}]}
            onPress={() => agendaItemOnClicked(tmpReservationDetailsRetrievedFromAgendaItem.ownerID, tmpReservationDetailsRetrievedFromAgendaItem.itemDomain)}
          >
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('category')}: <Text style={{fontWeight: "normal", color: "brown"}}>{i18n.t('studioCategory')}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('category')}: <Text style={{fontWeight: "normal", color: "green"}}>{i18n.t('tennisCategory')}</Text></Text>}
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('date')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationDate}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('hours')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime} - {tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime}</Text></Text>
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('studio')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.companyName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('company')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.companyName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('room')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.roomName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().TENNIS && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('tennisCourt')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.courtName}</Text></Text>}
            {tmpReservationDetailsRetrievedFromAgendaItem.itemDomain == LoginManager.getInstance().STUDIO && <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('type')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal==true ? i18n.t('rehearsal') : i18n.t('recording')}</Text></Text>}
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('totalCost')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationCost}</Text></Text>        
            <Text style={{flexDirection:"row", fontWeight: "bold", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{i18n.t('comments')}: <Text style={{fontWeight: "normal", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationComments}</Text></Text>
          </TouchableOpacity>}
        </ScrollView>
      );
    }
    else if(tmpReservationDetailsRetrievedFromAgendaItem.reservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_OWNER_IN_TENNIS_DOMAIN) {  //owner acts as owner for his tennis courts
      return (
        <ScrollView style={styles.scrollView}>
          <TouchableOpacity
            testID={calendarAgendaConfig.agenda.ITEM}
            style={[styles.item, {height: item.height}]}
            onPress={() => agendaItemOnClicked_Tennis(tmpReservationDetailsRetrievedFromAgendaItem.reservationID,
              tmpReservationDetailsRetrievedFromAgendaItem.courtName,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationDate,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationCost,
              tmpReservationDetailsRetrievedFromAgendaItem.reservationComments,
              tmpReservationDetailsRetrievedFromAgendaItem.playerName,
              tmpReservationDetailsRetrievedFromAgendaItem.playerTel,
              tmpReservationDetailsRetrievedFromAgendaItem.playerEmail,
              tmpReservationDetailsRetrievedFromAgendaItem.playerTag
            )}
          >
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('tennisCourt')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.courtName}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('date')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationDate}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('hours')}: <Text style={{fontWeight: "normal", color: "blue"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime} - {tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime}</Text></Text>
            <Text style={{flexDirection:"row", fontWeight: "bold"}}>{i18n.t('totalCost')}: <Text style={{fontWeight: "normal"}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationCost}</Text></Text>        
            <Text style={{flexDirection:"row", fontWeight: "bold", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{i18n.t('comments')}: <Text style={{fontWeight: "normal", color: "blue", display: tmpReservationDetailsRetrievedFromAgendaItem.reservationComments!='' ? 'flex' : 'none'}}>{tmpReservationDetailsRetrievedFromAgendaItem.reservationComments}</Text></Text>        
            <Divider style={{ marginTop: 5, marginBottom: 5 }}/>
            {tmpReservationDetailsRetrievedFromAgendaItem.playerEmail!="" && 
              <View style={{ backgroundColor: "black", borderWidth: 3, borderRadius: 5, padding: 2 }}>
                <Text style={{ color: "yellow", fontWeight: 'bold', textDecorationLine: 'underline' }}>{tmpReservationDetailsRetrievedFromAgendaItem.playerTag==Tennis_ConstManager.getInstance().MEMBER ? i18n.t('clubMember') : tmpReservationDetailsRetrievedFromAgendaItem.playerTag==Tennis_ConstManager.getInstance().GUEST ? i18n.t('guest') : i18n.t('player')}</Text>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>{i18n.t('player')}: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerName}</Text></Text>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>{i18n.t('telephone')}: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerTel}</Text></Text>
                <Text style={{flexDirection:"row", fontWeight: "bold", color: "white"}}>Email: <Text style={{fontWeight: "normal", color: "white"}}>{tmpReservationDetailsRetrievedFromAgendaItem.playerEmail}</Text></Text>
              </View>
            }
            {tmpReservationDetailsRetrievedFromAgendaItem.playerEmail=="" && <Text style={{fontStyle: "italic", color: "grey"}}>{i18n.t('reservationMadeByYou')}</Text>}
          </TouchableOpacity>
        </ScrollView>
      );
    }    
  }, []);

  const renderEmptyDate = useCallback(() => {
    return (
      <View style={styles.emptyDate}>
        <Text>This is empty date!</Text>
      </View>
    );
  }, []);

  const rowHasChanged = useCallback((r1, r2) => {
    return r1.name !== r2.name;
  }, []);

  const timeToString = useCallback((time) => {
    const date = new Date(time);
    return date.toISOString().split('T')[0];
  }, []);

  const [agendaIsRefresing, setAgendaIsRefresing] = useState(false);
  /*useEffect(() => {
    
  }, [agendaIsRefresing]);*/

///////////////////////////////////////////////////
// from now on, I need these for new reservation //
///////////////////////////////////////////////////
  const [visibleNewReservationModal, setVisibleNewReservationModal] = useState(false);
  const hideNewReservationModal = () =>  { 
    setVisibleNewReservationModal(false);
  }
  useEffect(() => {
    console.log("useEffect - START - visibleNewReservationModal");
    console.log("step2: useEffect [visibleNewReservationModal]: ", visibleNewReservationModal);
    if(visibleNewReservationModal==false && reservationCompletionMsg!="") {
      console.log("    MPHKE!!!! step2: useEffect [visibleNewReservationModal]: ", visibleNewReservationModal);
      //step 2/5: setare snackbar text
      var translatedMessageToShow: string = "";
      if(reservationCompletionMsg.length > 0)
      {
        translatedMessageToShow = reservationCompletionMsg;
        if(reservationCompletionMsg == Studio_ConstManager.getInstance().RESERVATION_ADDED_MSG
            || reservationCompletionMsg == Tennis_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
          translatedMessageToShow = i18n.t('reservationAddedSuccessfully');
        }
        else if(reservationCompletionMsg == "Invalid date!") {
          translatedMessageToShow = i18n.t('invalidHours');
        }
      }
      setSnackBarText(translatedMessageToShow);
    }
    else if(visibleNewReservationModal == true) {
      setReservationCompletionMsg("");  //init
      setSnackBarText("");              //init
    }
    console.log("useEffect - STOP - visibleNewReservationModal");
  }, [visibleNewReservationModal]);

  const [newReservation_date, setNewReservation_date] = useState(moment().format('DD/MM/YYYY').toString());
  
  const [showTip, setShowTip] = useState(true);
  useEffect(() => {
    console.log("useEffect - START - showTip");
    console.log("step5: useEffect [showTip]: ", showTip);
    let timeoutId: number;
    if (showTip == true) {
      timeoutId = setTimeout(() => {
        setShowTip(false);
      }, 3000); // hide tip after 3 seconds
    } 
  
    // This cleanup function will be called when the useEffect hook is called again
    // (e.g. when showTip changes)
    console.log("useEffect - STOP - showTip");
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showTip]);

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  useEffect(() => {
    console.log("useEffect - START - snackBarVisible");
    console.log("step4: useEffect [snackBarVisible]: ", snackBarVisible);
    let timeoutId: number;
    if(snackBarVisible==false && reservationCompletionMsg!="") {
      console.log("    MPHKE!!!! step 4: useEffect [snackBarVisible]: ", snackBarVisible);
      //step 4/4: update agenda items
      //setShowTip(true);
      timeoutId = setTimeout(() => {
        //setShowTip(true);
        handleRefresh();
      }, 1000); // refresh after 1 second
    }
    console.log("useEffect - STOP - snackBarVisible");
    // This cleanup function will be called when the useEffect hook is called again
    // (e.g. when showTip changes)
    return () => {
      clearTimeout(timeoutId);
    };
  }, [snackBarVisible]); 
  const onDismissSnackBar = () => setSnackBarVisible(false);
  const [snackBarText, setSnackBarText] = useState("");
  useEffect(() => {
    console.log("useEffect - START - snackBarText");
    console.log("step3: useEffect [snackBarText]: ", snackBarText);
    if(snackBarText!="" && (reservationCompletionMsg!="" || messageToShowForReservationCancellation!="")) {
      console.log("    MPHKE!!!! step3: useEffect [snackBarText]: ", snackBarText);
      //step 3/5: emfaninhse snackbar
      setSnackBarVisible(true);
    }
    console.log("useEffect - STOP - snackBarText");
  }, [snackBarText]);  

  const [reservationCompletionMsg, setReservationCompletionMsg] = useState("");
  useEffect(() => {
    console.log("useEffect - START - reservationCompletionMsg");
    console.log("step1: useEffect [reservationCompletionMsg]: ", reservationCompletionMsg);
    if(reservationCompletionMsg != "") {
      console.log("    MPHKE!!! step 1: useEffect [reservationCompletionMsg]: ", reservationCompletionMsg);
      //step 1/5: kleise newReservationModal 
      setVisibleNewReservationModal(false);
    }
    console.log("useEffect - STOP - reservationCompletionMsg");
  }, [reservationCompletionMsg]);

  

  const [selectedDate_YYYY_pavla_MM_pavla_DD, setSelectedDate_YYYY_pavla_MM_pavla_DD] = useState(moment().format('YYYY-MM-DD').toString());
  /*useEffect(() => {
    console.log("useEffect - selectedDate_YYYY_pavla_MM_pavla_DD - START -> selectedDate_YYYY_pavla_MM_pavla_DD: ", selectedDate_YYYY_pavla_MM_pavla_DD);
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      setNewReservation_Studio({...newReservation_Studio, reservation_date: UtilsManager.getInstance().transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes(selectedDate_YYYY_pavla_MM_pavla_DD)});
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      setNewReservation_Tennis({...newReservation_Tennis, reservation_date: UtilsManager.getInstance().transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes(selectedDate_YYYY_pavla_MM_pavla_DD)});
    }
    console.log("useEffect - selectedDate_YYYY_pavla_MM_pavla_DD - END");
  }, [selectedDate_YYYY_pavla_MM_pavla_DD]);*/

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.columnMainApp}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.inner}>
            <Spinner
              visible={isLoading}
              textContent={i18n.t('loading')}
              textStyle={styles.spinnerTextStyle}
            />

            {MyCalendarReactNative!=null && <Agenda 
              ref={agendaRef}
              testID={calendarAgendaConfig.agenda.CONTAINER}
              items={agendaItems}
              extraData={extraData}
              // Callback that gets called when items for a certain month should be loaded (month became visible)
              loadItemsForMonth={month => {
                //console.log('trigger items loading - month: ' + JSON.stringify(month, null, 2));
              }}
              // Callback that fires when the calendar is opened or closed
              onCalendarToggled={calendarOpened => {
                //console.log("calendarOpened: " + calendarOpened);
              }}
              // Callback that gets called on day press
              onDayPress={day => {
                //console.log('day pressed: ' + JSON.stringify(day, null, 2));
                setSelectedDate(UtilsManager.getInstance().getSelectedDayTitle(day.day, day.month, day.year, day.timestamp));
                setNewReservation_date(UtilsManager.getInstance().transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes(day.dateString));
              }}
              // Callback that gets called when day changes while scrolling agenda list
              onDayChange={day => {
                console.log('day changed: ' + JSON.stringify(day, null, 2));
              }}
              //loadItemsForMonth={loadItems.bind(this)}
              selected={moment().format('YYYY-MM-DD').toString()}
              // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
              minDate={UtilsManager.getInstance().tranformDateToISOFormat(UtilsManager.getInstance().getFirstDayOfPreviousMonth())}
              // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
              maxDate={UtilsManager.getInstance().tranformDateToISOFormat(UtilsManager.getInstance().getLastDayOfNextMonth())}
              // Max amount of months allowed to scroll to the past. Default = 50
              pastScrollRange={1}
              // Max amount of months allowed to scroll to the future. Default = 50
              futureScrollRange={1}
              hideKnob={false}
              // When `true` and `hideKnob` prop is `false`, the knob will always be visible and the user will be able to drag the knob up and close the calendar. Default = false
              showClosingKnob={true}
              renderItem={LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? renderItem_Studio.bind(this) 
                          : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? renderItem_Tennis.bind(this)
                          : renderItem_Tennis.bind(this) //default
                         }
              renderEmptyDate={renderEmptyDate.bind(this)}
              rowHasChanged={rowHasChanged.bind(this)}
              renderEmptyData = {() => {return (
                  <View>
                    <Text style={styles.calendarSelectedDayTitle}>{selectedDate}</Text>
                    {isLoading==false && <Text style={{ textAlign: 'center' }}>{i18n.t('youHaveNoReservationForThisDate')}</Text>}
                  </View>
                );
              }}
              // If provided, a standard RefreshControl will be added for "Pull to Refresh" functionality. Make sure to also set the refreshing prop correctly
              //onRefresh={handleRefresh}
              // Set this true while waiting for new data from a refresh
              //refreshing={isLoading}
              // Add a custom RefreshControl component, used to provide pull-to-refresh functionality for the ScrollView
              refreshControl={
                <RefreshControl
                  refreshing={agendaIsRefresing}
                  onRefresh={handleRefresh}
                  progressViewOffset={40}
                  title={i18n.t('pullDownToUpdate')}
                  titleColor="blue"
                  tintColor="green"
                />
              }
              // markingType={'period'}
              // markedDates={{
              //    '2017-05-08': {textColor: '#43515c'},
              //    '2017-05-09': {textColor: '#43515c'},
              //    '2017-05-14': {startingDay: true, endingDay: true, color: 'blue'},
              //    '2017-05-21': {startingDay: true, color: 'blue'},
              //    '2017-05-22': {endingDay: true, color: 'gray'},
              //    '2017-05-24': {startingDay: true, color: 'gray'},
              //    '2017-05-25': {color: 'gray'},
              //    '2017-05-26': {endingDay: true, color: 'gray'}}}
              // monthFormat={'yyyy'}
                theme={{
                //...calendarTheme,
                //agendaDayTextColor: 'yellow',
                //agendaDayNumColor: 'green',
                //agendaTodayColor: 'black',
                agendaKnobColor: 'black'
              }}
              //renderDay={(day, item) => (<Text>{day ? day.day: 'item'}</Text>)}
              // hideExtraDays={false}
            />}
            {MyCalendarForWeb!=null && 
                <View style={styles.webCalendar}>
                  <MyCalendarForWeb
                    localizer={localizer}
                    messages={webCalendarMessages}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={calendarEventsForWeb}
                    onSelectSlot={handleSelectDateWeb}
                    //dayPropGetter={getCalendarDayStyleWeb}
                    dayPropGetter={(date) => getCalendarDayStyleWeb(date)}
                    onNavigate={handleNavigateWebCalendar}
                    onSelectEvent={handleEventClickWeb}
                    components={{
                      event: CustomEvent, // You can style the event component
                    }}
                    selectable
                  />
                </View>
            }
            <Portal>
              <Modal style={{ display: isLoading==false ? "flex" : "none" }} visible={visibleSelectedReservationDetailsModal} onDismiss={hideSelectedReservationDetailsModal} contentContainerStyle={styles.modalContainer}>
                <OwnerSelectedReservationModal 
                            setMessageToShowForReservationCancellation = {setMessageToShowForReservationCancellation}
                            curReservationDetails_Studio={curReservationDetails_Studio}
                            curReservationDetails_Tennis={curReservationDetails_Tennis}
                />
              </Modal>
            </Portal>
            <Portal>
              <Modal style={{ display: isLoading==false ? "flex" : "none" }} visible={visibleNewReservationModal} onDismiss={hideNewReservationModal} contentContainerStyle={styles.modalContainer}>
                <OwnerNewReservationModal 
                            setReservationCompletionMsg={setReservationCompletionMsg}
                            newReservation_date={newReservation_date}
                />  
              </Modal>
            </Portal>
            <FAB
              style={[styles.fab, { display: UtilsManager.getInstance().compareDates(moment(todayDate).format('DD/MM/YYYY'), newReservation_date)==-1 ? 'none' : 'flex'}]}
              icon={({ color, size }) => (<MaterialCommunityIcons name="calendar-plus" color={color} size={size} />)}
              onPress={() => { setVisibleNewReservationModal(true); }}
            />
            
            {Platform.OS!='web' && showTip==true && <Tip />}
                
            <Snackbar
              style={styles.snackbarUpper}
              duration={1000}
              visible={snackBarVisible}
              onDismiss={onDismissSnackBar}>
              {snackBarText}
            </Snackbar>  
            
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  };

  // Define a custom event component to style the event title
  const CustomEvent = ({ event }) => (
    <View style={styles.webCalendarItemContainer}>
      {event.title.split('\n').map((line, index) => (
        <View key={index}><Text style={styles.webCalendarItemText}>{line}</Text></View>
      ))}
    </View>
  );

export default OwnerMyReservationsView;
