class StudioConstManager {
    public readonly RESERVATION_ADDED : number = 100;
    public readonly COURT_ALREADY_RESERVED : number = -100;
    public readonly INVALID_DATE : number = -108;
    public readonly PLAYER_HAS_ANOTHER_RESERVATION_AT_THIS_TIME : number = -200;
    public readonly NOT_ENOUGH_PERMISSIONS : number = -300;
    public readonly RESERVATION_MADE_BY_THE_OWNER_OF_THE_COURT : number = -1000;
    public readonly CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED : number = -1005;

    public readonly INTRUDER_MSG : string = "You do not have permissions for this!";
    public readonly RESERVATION_ADDED_MSG : string = "Reservation added successfully!";
    public readonly COURT_ALREADY_RESERVED_MSG : string = "The room is already reserved at this time slot!";
    public readonly PLAYER_HAS_ANOTHER_RESERVATION_AT_THIS_TIME_MSG : string = "This musician has already another reservation at this time slot!";
    public readonly SIGN_IN_NEEDED_MSG : string = "Please sign in to get access to this information!";
    public readonly ALREADY_SENT : string = "Already sent!";
    public readonly INVALID_USERNAME_OR_KEY : string = "The email or key you provided is invalid!";
    public readonly INVALID_KEY : string = "The key you provided is invalid!";
    public readonly ACCOUNT_VERIFIED : string = "The account has been verified successfully!";
    public readonly VERIFICATION_KEY_HAS_BEEN_RESENT : string = "The verification key has been resent!";
    public readonly EMAILS_SENT_TO_INACTIVE_OWNERS : string = "Emails have been sent to all inactive owners!";
    public readonly PLAYER_BLOCKED : string = "Musician blocked successfully!";
    public readonly PLAYER_UNBLOCKED : string = "Musician unblocked successfully!";
    public readonly CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG : string = "The reservation cannot be completed because the musician is blocked by this owner!";
    public readonly DB_BACKUP_WAS_SAVED_SUCCESSFULLY_INTO_JSON : string = "BD was saved successfully into JSON!";
    public readonly AN_ERROR_OCCURED_DURING_DB_BACKUP : string = "An error occured during DB backup!";
    public readonly DB_WAS_RESTORED_SUCCESSFULLY_FROM_JSON : string = "DB was restored successfully from JSON!";
    public readonly AN_ERROR_OCCURED_DURING_DB_RESTORATION : string = "An error occured during DB restoration!";
    public readonly ANOTHER_USER_EXISTS_WITH_THIS_EMAIL : string = "Another user exists with this email!";
    public readonly PLAYER_ADDED_SUCCESSFULLY : string = "Musician added successfully!";
    public readonly YES : string = "YES";
    public readonly NO : string = "NO";
    public readonly INVALID_DATE_MSG : string = "Invalid date!";
    public readonly YOU_HAVE_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_SUCCESSFULLY_MSG : string = "You have promoted HandyBooker to this owner successfully!";
    public readonly YOU_HAVE_ALREADY_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_MSG : string = "You have already promoted HandyBooker to this owner!";
    public readonly CURRENCY_OF_ALL_ROOMS_UPDATED_MSG : string = "The currency of all rooms has been updated!";      

    public readonly RESERVATIONS_FOR_REHEARSALS_ONLY: string = "Reservations only for rehearsals";
    public readonly RESERVATIONS_FOR_RECORDINGS_ONLY: string = "Reservations only for recordings";
    public readonly RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS: string = "Reservations for both rehearsals and recordings";

    private static instance: StudioConstManager;

    private constructor() { 
    }

    public static getInstance(): StudioConstManager {
        if (!StudioConstManager.instance) {
            StudioConstManager.instance = new StudioConstManager();
        }

        return StudioConstManager.instance;
    }
}

export default StudioConstManager;