import React, { useState, useEffect, useRef } from 'react';
import { TextInput, Button, Colors, Appbar, Modal, Portal } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import styles from '../../../css/myCss';
import LoginManager from '../../../managers/LoginManager';
import UtilsManager from '../../../managers/UtilsManager';
import Tennis_RestManager from '../../../managers/Tennis_RestManager';
import Generic_RestManager from '../../../managers/Generic_RestManager';
import moment from 'moment';
import Tennis_ConstManager from '../../../managers/Tennis_ConstManager';
import Generic_ConstManager from '../../../managers/Generic_ConstManager';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//DatePicker
  //react native
  import DateTimePickerModal from "react-native-modal-datetime-picker";
  //web  
  import { SafeAreaProvider } from "react-native-safe-area-context";
  import { DatePickerModal } from 'react-native-paper-dates';  
import { MaterialCommunityIcons } from '@expo/vector-icons';

  let MyDateTimePickerModalReactNative;
  let MyDateTimePickerModalForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyDateTimePickerModalReactNative = null;
    MyDateTimePickerModalForWeb = DatePickerModal;
  } else {                      //REACT NATIVE
    MyDateTimePickerModalReactNative = DateTimePickerModal;
    MyDateTimePickerModalForWeb = null;
  }
//-DatePicker

const OwnerAddTennisClubMemberModal = ({
                                        membershipsDropdownList,
                                        setVisibleNewClubMemberModal,
                                        handleRefresh
}) => {
  //const navigation = useNavigation();
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebTitle, setAlertForWebTitle] = useState('');
  const [alertForWebMsg, setAlertForWebMsg] = useState('');

  const [handleAlertAfterSuccessfulAdding_forWebAlert, setHandleAlertAfterSuccessfulAdding_forWebAlert] = useState(false);
  useEffect(() => {
    if(handleAlertAfterSuccessfulAdding_forWebAlert==true && Platform.OS==='web') {
      setAlertForWebTitle(i18n.t('congratulations'));
      setAlertForWebMsg(i18n.t('clubMemberAddedSuccessfully'));
      setAlertForWebVisible(true);
    }
  }, [handleAlertAfterSuccessfulAdding_forWebAlert]);

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerAddTennisClubMemberModal: I'm ready!");    
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);

  const [newClubMember, setNewClubMember] = useState<Tennis_ClubMember>({
    id: -1,
    owner_id: LoginManager.getInstance().ID,
    user_email: "",
    member_since_date: moment(new Date()).format("DD/MM/YYYY"),
    membership_id: -1,
    membership_valid_from_date: moment(new Date()).format("DD/MM/YYYY"),
    membership_valid_to_date: moment(new Date()).format("DD/MM/YYYY"),
    user_name_provided_by_owner: "",
    user_surname_provided_by_owner: "",
    user_telephone_provided_by_owner: ""
  });
  useEffect(() => {
    console.log("useEffect - newClubMember: ", JSON.stringify(newClubMember, null, 2))
  }, [JSON.stringify(newClubMember)]);

  const [genericInfoReturnedByWebService, setGenericInfoReturnedByWebService] = useState<GenericPlayerInfo>({
    name: "",
    surname: "",
    email: "",
    telephone: "",
    language: "",
    email_notifications: false
  });

  useEffect(() => {
    let timeoutId;
    if (newClubMember.user_email.length > 0) {
      timeoutId = setTimeout(() => {
        const tmpEmailIsValid = UtilsManager.getInstance().emailIsValid(newClubMember.user_email);
        if (tmpEmailIsValid === false) {
          if(MyAlertReactNative != null)
            alert(i18n.t('pleaseCompleteValidEmail'));
          else if(MyAlertForWeb != null) {
            setAlertForWebTitle(i18n.t('caution'));
            setAlertForWebMsg(i18n.t('pleaseCompleteValidEmail'));
            setAlertForWebVisible(true);
          }
        } else {
          (async () => {
            setIsLoading(true);
            const tmpWebServiceResponse = await Generic_RestManager.getInstance().getGenericPlayerInfoByEmailOnly(newClubMember.user_email);
            setIsLoading(false);
            setGenericInfoReturnedByWebService(tmpWebServiceResponse);
          })();
        }
      }, 3000); // 3-second delay
    }
    return () => {
      clearTimeout(timeoutId);
    };    
  }, [newClubMember.user_email]);

  const [visibleMembershipsDropdownList, setVisibleMembershipsDropdownList] = useState<boolean>(false);

  const [memberSinceDatePickerVisible, setMemberSinceDatePickerVisible] = useState<boolean>(false);

  const [membershipValidUntilDatePickerVisible, setMembershipValidUntilDatePickerVisible] = useState<boolean>(false);

  async function addClubMember() {
    setIsLoading(true);
    const webServiceResponseStr = await Tennis_RestManager.getInstance().addClubMember(newClubMember);
    setIsLoading(false);

    if(webServiceResponseStr == Tennis_ConstManager.getInstance().CLUB_MEMBER_ADDED_SUCCESSFULLY) {
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(
          i18n.t('congratulations'),
          i18n.t('clubMemberAddedSuccessfully'),
          [
            { text: 'OK', onPress: handleAlertAfterSuccessfulAdding }
          ],
          { cancelable: false }
        );
      }
      else if(MyAlertForWeb != null) {
        setHandleAlertAfterSuccessfulAdding_forWebAlert(true);
      }
    }
    else if(webServiceResponseStr == Tennis_ConstManager.getInstance().THERE_IS_ANOTHER_CLUB_MEMBER_WITH_THIS_EMAIL) {
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(
          i18n.t('caution'),
          i18n.t('thereIsAnotherClubMemberWithThisEmail'),
          [{ text: 'OK' }],
          { cancelable: false }
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('caution'));
        setAlertForWebMsg(i18n.t('thereIsAnotherClubMemberWithThisEmail'));
        setAlertForWebVisible(true);
      }
    }
    else if(webServiceResponseStr == Generic_ConstManager.getInstance().INTRUDER_MSG) {
      if(MyAlertReactNative != null) {
        MyAlertReactNative.alert(
          i18n.t('caution'),
          i18n.t('intruderMsg'),
          [{ text: 'OK' }],
          { cancelable: false }
        );
      }
      else if(MyAlertForWeb != null) {
        setAlertForWebTitle(i18n.t('caution'));
        setAlertForWebMsg(i18n.t('intruderMsg'));
        setAlertForWebVisible(true);
      }
    }
  }

  const handleAlertAfterSuccessfulAdding = () => {
    setIsLoading(true);
    handleRefresh();
    setIsLoading(false);
    setVisibleNewClubMemberModal(false);
  };

  // Create a ref for the TextInput components
  const emailInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const surnameInputRef = useRef(null);
  const telephoneInputRef = useRef(null);

  return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View style={styles.inner}>
          <Appbar.Header>
            <Appbar.Content title={i18n.t('addNewClubMember')} />
          </Appbar.Header>
          <ScrollView keyboardShouldPersistTaps="always">
            <Spinner
              visible={isLoading}
              textContent={i18n.t('loading')}
              textStyle={styles.spinnerTextStyle}
            />
            <DropDown
                label={i18n.t('membershipPlan')}
                mode={'outlined'}
                visible={visibleMembershipsDropdownList}
                showDropDown={() => setVisibleMembershipsDropdownList(true)}
                onDismiss={() => setVisibleMembershipsDropdownList(false)}
                value={newClubMember.membership_id.toString()}
                setValue={tmpSelectedMembershipIdStr => setNewClubMember(prevState => ({...prevState, membership_id: parseInt(tmpSelectedMembershipIdStr)}))}
                list={membershipsDropdownList}
                inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={visibleMembershipsDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
            />

            <View style={{ flexDirection: "row" }}>
                      
              <TouchableOpacity onPress={() => setMemberSinceDatePickerVisible(true)} style={{ flex: 1}}>
                <TextInput
                  label={i18n.t('memberSince')}
                  value={newClubMember.member_since_date}
                  editable={false} // disable the keyboard
                  style={{ backgroundColor: Colors.blueGrey100 }}
                />
              </TouchableOpacity>
              {MyDateTimePickerModalReactNative!=null && 
                <MyDateTimePickerModalReactNative
                  isVisible={memberSinceDatePickerVisible}
                  mode="date"
                  onConfirm={newDate => setNewClubMember({...newClubMember, member_since_date: moment(newDate).format("DD/MM/YYYY")})}
                  onCancel={() => setMemberSinceDatePickerVisible(false)}
                />
              }
              {MyDateTimePickerModalForWeb!=null &&
                <SafeAreaProvider>
                  <MyDateTimePickerModalForWeb
                    locale={LoginManager.getInstance().language==LoginManager.getInstance().GR ? "el" : "en"}
                    mode="single"
                    label={i18n.t('selectDate')}
                    visible={memberSinceDatePickerVisible}
                    onDismiss={() => { setMemberSinceDatePickerVisible(false) }}
                    date={moment(newClubMember.member_since_date, "DD/MM/YYYY").toDate()}
                    onConfirm={(params) => {
                      setNewClubMember({...newClubMember, member_since_date: moment(params.date).format("DD/MM/YYYY")});
                      setMemberSinceDatePickerVisible(false);
                    }}
                    onChange={(params) => {
                      setNewClubMember({...newClubMember, member_since_date: moment(params.date).format("DD/MM/YYYY")});
                      setMemberSinceDatePickerVisible(false);
                    }}
                  />
                </SafeAreaProvider>                
              }

              <TouchableOpacity onPress={() => setMembershipValidUntilDatePickerVisible(true)} style={{ flex: 1 }}>
                <TextInput
                  label={i18n.t('membershipValidUntil')}
                  value={newClubMember.membership_valid_to_date}
                  editable={false} // disable the keyboard
                  style={{ backgroundColor: Colors.blueGrey100 }}
                />
              </TouchableOpacity>
              {MyDateTimePickerModalReactNative!=null && 
                <MyDateTimePickerModalReactNative
                  isVisible={membershipValidUntilDatePickerVisible}
                  mode="date"
                  onConfirm={newDate => setNewClubMember({...newClubMember, membership_valid_to_date: moment(newDate).format("DD/MM/YYYY")})}
                  onCancel={() => setMembershipValidUntilDatePickerVisible(false)}
                />
              }
              {MyDateTimePickerModalForWeb!=null &&
                <SafeAreaProvider>
                  <MyDateTimePickerModalForWeb
                    locale={LoginManager.getInstance().language==LoginManager.getInstance().GR ? "el" : "en"}
                    mode="single"
                    label={i18n.t('selectDate')}
                    visible={membershipValidUntilDatePickerVisible}
                    onDismiss={() => { setMembershipValidUntilDatePickerVisible(false) }}
                    date={moment(newClubMember.membership_valid_to_date, "DD/MM/YYYY").toDate()}
                    onConfirm={(params) => {
                      setNewClubMember({...newClubMember, membership_valid_to_date: moment(params.date).format("DD/MM/YYYY")});
                      setMembershipValidUntilDatePickerVisible(false);
                    }}
                    onChange={(params) => {
                      setNewClubMember({...newClubMember, membership_valid_to_date: moment(params.date).format("DD/MM/YYYY")});
                      setMembershipValidUntilDatePickerVisible(false);
                    }}
                  />
                </SafeAreaProvider>                
              }
              
              
            </View>

            <TouchableOpacity onPress={() => emailInputRef.current.focus()}>
              <TextInput
                ref={emailInputRef}
                label="Email"
                value={newClubMember.user_email}
                onChangeText={tmpEmail => setNewClubMember({...newClubMember, user_email: tmpEmail})}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => nameInputRef.current.focus()}>
              <TextInput
                ref={nameInputRef}
                label={i18n.t('name')}
                disabled={genericInfoReturnedByWebService.name=="" ? false : true}
                value={genericInfoReturnedByWebService.name!="" ? genericInfoReturnedByWebService.name : newClubMember.user_name_provided_by_owner}
                onChangeText={tmpName => setNewClubMember({...newClubMember, user_name_provided_by_owner: tmpName})}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => surnameInputRef.current.focus()}>
              <TextInput
                ref={surnameInputRef}
                label={i18n.t('surname')}
                disabled={genericInfoReturnedByWebService.surname=="" ? false : true}
                value={genericInfoReturnedByWebService.surname!="" ? genericInfoReturnedByWebService.surname : newClubMember.user_surname_provided_by_owner}
                onChangeText={tmpSurname => setNewClubMember({...newClubMember, user_surname_provided_by_owner: tmpSurname})}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => telephoneInputRef.current.focus()}>
              <TextInput
                ref={telephoneInputRef}
                label={i18n.t('telephone')}
                disabled={genericInfoReturnedByWebService.telephone=="" ? false : true}
                value={genericInfoReturnedByWebService.telephone!="" ? genericInfoReturnedByWebService.telephone : newClubMember.user_telephone_provided_by_owner}
                onChangeText={tmpTelephone => setNewClubMember({...newClubMember, user_telephone_provided_by_owner: tmpTelephone})}
              />
            </TouchableOpacity>
            
            <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-plus" color={color} size={size} />)} mode="contained" style={{ 
              display: newClubMember.user_email!=""
                        && (newClubMember.user_name_provided_by_owner!="" || genericInfoReturnedByWebService.name!="")
                        && (newClubMember.user_surname_provided_by_owner!="" || genericInfoReturnedByWebService.surname!="")
                        && (newClubMember.user_telephone_provided_by_owner!="" || genericInfoReturnedByWebService.telephone!="")
                      ? 'flex' : 'none', 
              flex:1, alignItems:'center', justifyContent: "center" }} 
              labelStyle={styles.iconButton} 
              contentStyle={styles.iconButtonContent} 
              onPress={() => addClubMember()}>{i18n.t('add')}
            </Button>
          </ScrollView>
          {MyAlertForWeb!=null && 
            <MyAlertForWeb
              show={alertForWebVisible}
              showProgress={false}
              title={alertForWebTitle}
              message={alertForWebMsg}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForWebVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForWebVisible(false);

                if(handleAlertAfterSuccessfulAdding_forWebAlert == true) {
                  setHandleAlertAfterSuccessfulAdding_forWebAlert(false);

                  handleAlertAfterSuccessfulAdding();
                }
              }}  
            />
          }
        </View>
      </TouchableWithoutFeedback>
  );
};

export default OwnerAddTennisClubMemberModal;