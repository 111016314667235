class GUIManager {
    //public readonly ngrokURL: string = "https://stag-nice-readily.ngrok-free.app";    //localhost - development
    public readonly ngrokURL: string = "https://services.handybooker.com";              //live - production

    public readonly GUEST_MODE: number;
    //public readonly PLAYER_TO_SIGN_UP_MODE: number;
    //public readonly OWNER_TO_SIGN_UP_MODE: number;
    public readonly PLAYER_LOGGED_IN_MODE: number;
    public readonly OWNER_LOGGED_IN_MODE: number;
    public readonly ADMIN_LOGGED_IN_MODE: number;

    public readonly ADMOBBANNER_ADUNITID: string;

    public mode: number;

    public myLatitude: number;
    public myLongitude: number;

    public myStudioPinLatitude: number;
    public myStudioPinLongitude: number;

    public allOwners: Owner[];
    public allPicsOfCurOwner: PicBase64[];
    public curSelectedOwnerIndex: number;

    public allRoomsOfLoggedInOwner_Studio: Studio_Room[];
    public allCourtsOfLoggedInOwner_Tennis: Tennis_Court[];

    public notificationsAllowed: boolean;

    private static instance: GUIManager;

    private constructor() { 
        this.GUEST_MODE = 0;
        //this.PLAYER_TO_SIGN_UP_MODE = 1;
        //this.OWNER_TO_SIGN_UP_MODE = 2;
        this.PLAYER_LOGGED_IN_MODE = 3;
        this.OWNER_LOGGED_IN_MODE = 4;
        this.ADMIN_LOGGED_IN_MODE = 5;

        this.ADMOBBANNER_ADUNITID = 'ca-app-pub-3940256099942544/6300978111';

        this.mode = this.GUEST_MODE;

        this.myLatitude = -1.0;
        this.myLongitude = -1.0;

        this.myStudioPinLatitude = -1.0;
        this.myStudioPinLongitude = -1.0;
        
        this.allOwners = [];
        this.allPicsOfCurOwner = [];
        this.curSelectedOwnerIndex = -1;

        this.notificationsAllowed = false;

        this.allRoomsOfLoggedInOwner_Studio = [{
            id: -1,
            owned_by: -1,
            name: '',
            currency: '',
            monday_cost_per_hour_reh: -1,
            monday_cost_per_hour_rec: -1,
            tuesday_cost_per_hour_reh: -1,
            tuesday_cost_per_hour_rec: -1,
            wednesday_cost_per_hour_reh: -1,
            wednesday_cost_per_hour_rec: -1,
            thursday_cost_per_hour_reh: -1,
            thursday_cost_per_hour_rec: -1,
            friday_cost_per_hour_reh: -1,
            friday_cost_per_hour_rec: -1,
            saturday_cost_per_hour_reh: -1,
            saturday_cost_per_hour_rec: -1,
            sunday_cost_per_hour_reh: -1,
            sunday_cost_per_hour_rec: -1,
            monday_working_hours_start: '',
            tuesday_working_hours_start: '',
            wednesday_working_hours_start: '',
            thursday_working_hours_start: '',
            friday_working_hours_start: '',
            saturday_working_hours_start: '',
            sunday_working_hours_start: '',
            monday_working_hours_stop: '',
            tuesday_working_hours_stop: '',
            wednesday_working_hours_stop: '',
            thursday_working_hours_stop: '',
            friday_working_hours_stop: '',
            saturday_working_hours_stop: '',
            sunday_working_hours_stop: '',            
            space: '',
            speakers: '',
            console: '',
            microphones: '',
            drums: '',
            guitars: '',
            basses: '',
            pianos_keyboards: '',
            guitar_amplifiers: '',
            bass_amplifiers: '',
            other_equipment: ''
        }];
        this.allCourtsOfLoggedInOwner_Tennis = [{
            id: -1,
            owned_by: -1,
            name: '',
            type: -1,
            currency: '',
            monday_cost_per_hour: -1,
            tuesday_cost_per_hour: -1,
            wednesday_cost_per_hour: -1,
            thursday_cost_per_hour: -1,
            friday_cost_per_hour: -1,
            saturday_cost_per_hour: -1,
            sunday_cost_per_hour: -1,
            monday_working_hours_start: '',
            tuesday_working_hours_start: '',
            wednesday_working_hours_start: '',
            thursday_working_hours_start: '',
            friday_working_hours_start: '',
            saturday_working_hours_start: '',
            sunday_working_hours_start: '',
            monday_working_hours_stop: '',
            tuesday_working_hours_stop: '',
            wednesday_working_hours_stop: '',
            thursday_working_hours_stop: '',
            friday_working_hours_stop: '',
            saturday_working_hours_stop: '',
            sunday_working_hours_stop: ''
        }];
    }

    public static getInstance(): GUIManager {
        if (!GUIManager.instance) {
            GUIManager.instance = new GUIManager();
        }

        return GUIManager.instance;
    }

    public someBusinessLogic() {
        console.log("someBusinessLogic called");
    }

    public initialize()
    {
        this.mode = this.GUEST_MODE;

        this.myLatitude = -1.0;
        this.myLongitude = -1.0;

        this.myStudioPinLatitude = -1.0;
        this.myStudioPinLongitude = -1.0;

        this.allOwners = [];
        this.allPicsOfCurOwner = [];
        this.curSelectedOwnerIndex = -1;

        this.notificationsAllowed = false;

        this.allRoomsOfLoggedInOwner_Studio = [{
            id: -1,
            owned_by: -1,
            name: '',
            currency: '',
            monday_cost_per_hour_reh: -1,
            monday_cost_per_hour_rec: -1,
            tuesday_cost_per_hour_reh: -1,
            tuesday_cost_per_hour_rec: -1,
            wednesday_cost_per_hour_reh: -1,
            wednesday_cost_per_hour_rec: -1,
            thursday_cost_per_hour_reh: -1,
            thursday_cost_per_hour_rec: -1,
            friday_cost_per_hour_reh: -1,
            friday_cost_per_hour_rec: -1,
            saturday_cost_per_hour_reh: -1,
            saturday_cost_per_hour_rec: -1,
            sunday_cost_per_hour_reh: -1,
            sunday_cost_per_hour_rec: -1,
            monday_working_hours_start: '',
            tuesday_working_hours_start: '',
            wednesday_working_hours_start: '',
            thursday_working_hours_start: '',
            friday_working_hours_start: '',
            saturday_working_hours_start: '',
            sunday_working_hours_start: '',
            monday_working_hours_stop: '',
            tuesday_working_hours_stop: '',
            wednesday_working_hours_stop: '',
            thursday_working_hours_stop: '',
            friday_working_hours_stop: '',
            saturday_working_hours_stop: '',
            sunday_working_hours_stop: '',            
            space: '',
            speakers: '',
            console: '',
            microphones: '',
            drums: '',
            guitars: '',
            basses: '',
            pianos_keyboards: '',
            guitar_amplifiers: '',
            bass_amplifiers: '',
            other_equipment: ''
        }];
        this.allCourtsOfLoggedInOwner_Tennis = [{
            id: -1,
            owned_by: -1,
            name: '',
            type: -1,
            currency: '',
            monday_cost_per_hour: -1,
            tuesday_cost_per_hour: -1,
            wednesday_cost_per_hour: -1,
            thursday_cost_per_hour: -1,
            friday_cost_per_hour: -1,
            saturday_cost_per_hour: -1,
            sunday_cost_per_hour: -1,
            monday_working_hours_start: '',
            tuesday_working_hours_start: '',
            wednesday_working_hours_start: '',
            thursday_working_hours_start: '',
            friday_working_hours_start: '',
            saturday_working_hours_start: '',
            sunday_working_hours_start: '',
            monday_working_hours_stop: '',
            tuesday_working_hours_stop: '',
            wednesday_working_hours_stop: '',
            thursday_working_hours_stop: '',
            friday_working_hours_stop: '',
            saturday_working_hours_stop: '',
            sunday_working_hours_stop: ''
        }];
    }
}

export default GUIManager;