import React, { useState } from 'react';
import { BottomNavigation } from 'react-native-paper';
import { i18n } from "../../utils/i18n";
import styles from '../../css/myCss';
import GUIManager from '../../managers/GUIManager';
import PlayerBrowseStudiosSubview from './PlayerBrowseStudiosSubview';
import PlayerMyReservationsView from './PlayerMyReservationsView';
import LoginManager from '../../managers/LoginManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';
//import MusicianReportOwnerView from './Studio_MusicianReportOwnerView'


const PlayerControlPanelFooter = ({selectedDomainValue, setSelectedDomainValue}) => {
  const [index, setIndex] = useState(0/*GUIManager.getInstance().goToMyProfileSubpageIndex*/);
  const [routes] = useState([
    { key: 'PlayerBrowseStudios', title: i18n.t('browse'), icon: ({ color, size }) => (<MaterialCommunityIcons name='earth' color={color} size={size} />) },
    { key: 'PlayerMyReservations', title: i18n.t('myReservations'), icon: ({ color, size }) => (<MaterialCommunityIcons name='calendar' color={color} size={size} />) },
//    { key: 'reportOwner', title: i18n.t('reportOwnerMenuTitle'), icon: 'alert' },
  ]);

  const renderScene = BottomNavigation.SceneMap({
    PlayerBrowseStudios: () => <PlayerBrowseStudiosSubview selectedDomainValue={selectedDomainValue} setSelectedDomainValue={setSelectedDomainValue} />,
    PlayerMyReservations: PlayerMyReservationsView,
//    reportOwner: MusicianReportOwnerView,
  });

  return (
    <BottomNavigation 
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={renderScene}
      style={styles.bottomNavigation}
    />
  );
};

export default PlayerControlPanelFooter;