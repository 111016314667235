import React from "react";
import { BannerAd, BannerAdSize, TestIds } from "react-native-google-mobile-ads";
import { Provider, Subheading, TextInput, Button, ActivityIndicator, Divider, Text, List } from 'react-native-paper';
import styles from "../../css/myCss";
import { Platform, TouchableOpacity, View, Image } from "react-native";
import GoogleAdSenseWebAd from "./GoogleAdSenseWebAd";

const facebookLogo =  require("../../assets/facebook.png");
const instagramLogo =  require("../../assets/instagram.png");

let MyRightColumn;
if (Platform.OS === 'web') {  //WEB
        MyRightColumn = () =>    <View style={styles.columnRight}>
                                        <TouchableOpacity
                                                onPress={() => { 
                                                        console.log("Facebook clicked!");
                                                }
                                        }>
                                                <Image
                                                        source={facebookLogo}
                                                        accessibilityLabel="Follow us on Facebook"
                                                        style={{ width: 40, height: 40, marginLeft: 20, marginBottom: 20 }}
                                                />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                                onPress={() => { 
                                                        console.log("Instagram clicked!");
                                                }
                                        }>
                                                <Image
                                                        source={instagramLogo}
                                                        accessibilityLabel="Follow us on Instagram"
                                                        style={{ width: 40, height: 40, marginLeft: 20 }}
                                                />
                                        </TouchableOpacity>
                                </View>;
} else {                      //REACT NATIVE
        MyRightColumn = () => <View style={{ display: 'none' }}></View>
}

const RightColumnWeb = () => {
     return (
        <MyRightColumn/>
     );
    };
   
export default RightColumnWeb; 