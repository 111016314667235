import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { TextInput } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OwnerRoomsDropdown = ({  
                                myRoomsDropdownList_Studio,
                                showRoomsDropdownList_Studio,
                                setShowRoomsDropdownList_Studio,
                                selectedRoomDropdownIndex_Studio,
                                setSelectedRoomDropdownIndex_Studio,

                                myCourtsDropdownList_Tennis,
                                showCourtsDropdownList_Tennis,
                                setShowCourtsDropdownList_Tennis,
                                selectedCourtDropdownIndex_Tennis,
                                setSelectedCourtDropdownIndex_Tennis
                            }) => {
  // component implementation
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
          label={i18n.t('selectRoom')}
          mode={'outlined'}
          visible={showRoomsDropdownList_Studio}
          showDropDown={() => setShowRoomsDropdownList_Studio(true)}
          onDismiss={() => setShowRoomsDropdownList_Studio(false)}
          value={myRoomsDropdownList_Studio[selectedRoomDropdownIndex_Studio]!=undefined ? myRoomsDropdownList_Studio[selectedRoomDropdownIndex_Studio].value : 'none' }
          setValue={tmpSelectedRoomIndex => setSelectedRoomDropdownIndex_Studio(parseInt(tmpSelectedRoomIndex))}
          list={myRoomsDropdownList_Studio}
          inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showRoomsDropdownList_Studio==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
          label={i18n.t('selectCourt')}
          mode={'outlined'}
          visible={showCourtsDropdownList_Tennis}
          showDropDown={() => setShowCourtsDropdownList_Tennis(true)}
          onDismiss={() => setShowCourtsDropdownList_Tennis(false)}
          value={myCourtsDropdownList_Tennis[selectedCourtDropdownIndex_Tennis]!=undefined ? myCourtsDropdownList_Tennis[selectedCourtDropdownIndex_Tennis].value : 'none' }
          setValue={tmpSelectedRoomIndex => setSelectedCourtDropdownIndex_Tennis(parseInt(tmpSelectedRoomIndex))}
          list={myCourtsDropdownList_Tennis}
          inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showCourtsDropdownList_Tennis==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerRoomsDropdown/*, areEqual*/);