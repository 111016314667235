import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { TextInput } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OwnerDropdownSaturdayTo = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeSaturdayStopHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeSaturdayStopHour_Tennis,
                                
                                setSaturdayStopHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
        label={i18n.t('to')}
        mode={'outlined'}
        visible={myData_Studio.showSaturdayStopHour}
        showDropDown={() => setMyData_Studio({...myData_Studio, showSaturdayStopHour: true})}
        onDismiss={() => setMyData_Studio({...myData_Studio, showSaturdayStopHour: false})}
        value={myData_Studio.saturdayOpen=="true" && selectedRoom_Studio.saturday_working_hours_stop=="closed" ? proposeSaturdayStopHour_Studio : selectedRoom_Studio.saturday_working_hours_stop}
        setValue={setSaturdayStopHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showSaturdayStopHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
        label={i18n.t('to')}
        mode={'outlined'}
        visible={myData_Tennis.showSaturdayStopHour}
        showDropDown={() => setMyData_Tennis({...myData_Tennis, showSaturdayStopHour: true})}
        onDismiss={() => setMyData_Tennis({...myData_Tennis, showSaturdayStopHour: false})}
        value={myData_Tennis.saturdayOpen=="true" && selectedCourt_Tennis.saturday_working_hours_stop=="closed" ? proposeSaturdayStopHour_Tennis : selectedCourt_Tennis.saturday_working_hours_stop}
        setValue={setSaturdayStopHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showSaturdayStopHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownSaturdayTo/*, areEqual*/);