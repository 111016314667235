import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TextInput } from 'react-native-paper';

const OwnerDropdownFridayTo = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeFridayStopHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeFridayStopHour_Tennis,
                                
                                setFridayStopHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
        label={i18n.t('to')}
        mode={'outlined'}
        visible={myData_Studio.showFridayStopHour}
        showDropDown={() => setMyData_Studio({...myData_Studio, showFridayStopHour: true})}
        onDismiss={() => setMyData_Studio({...myData_Studio, showFridayStopHour: false})}
        value={myData_Studio.fridayOpen=="true" && selectedRoom_Studio.friday_working_hours_stop=="closed" ? proposeFridayStopHour_Studio : selectedRoom_Studio.friday_working_hours_stop}
        setValue={setFridayStopHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showFridayStopHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
        label={i18n.t('to')}
        mode={'outlined'}
        visible={myData_Tennis.showFridayStopHour}
        showDropDown={() => setMyData_Tennis({...myData_Tennis, showFridayStopHour: true})}
        onDismiss={() => setMyData_Tennis({...myData_Tennis, showFridayStopHour: false})}
        value={myData_Tennis.fridayOpen=="true" && selectedCourt_Tennis.friday_working_hours_stop=="closed" ? proposeFridayStopHour_Tennis : selectedCourt_Tennis.friday_working_hours_stop}
        setValue={setFridayStopHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showFridayStopHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownFridayTo/*, areEqual*/);