import React, { useState, useEffect, useRef } from 'react';
import { Appbar, TextInput, Button, RadioButton, Subheading, Divider } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import GUIManager from '../../managers/GUIManager';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import styles from '../../css/myCss';
import AdBanner from '../generic/AdBanner';
import UtilsManager from '../../managers/UtilsManager';
import DropDown from 'react-native-paper-dropdown';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';
import Generic_ConstManager from '../../managers/Generic_ConstManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';

//Alert
  //react native
    //already imported above
  //web
    import AwesomeAlert from 'react-native-awesome-alerts';
import LeftColumnWeb from './LeftColumnWeb';
import RightColumnWeb from './RightColumnWeb';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
  //-Alert

const SignUpView = ({ navigation, route }) => {
  const [selectedDomainValue, setSelectedDomainValue] = useState(route.params?.selectedDomainValue);
  useEffect(() => {
    console.log("SignUpView: selectedDomainValue - useEffect -> selectedDomainValue: ", selectedDomainValue);
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {

    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      
    }
  }, [selectedDomainValue]);

  const [curDropdownList, setCurDropdownList] = useState(route.params?.curDropdownList);
  const [showDomainDropdownList, setShowDomainDropdownList] = useState(route.params?.showDomainDropdownList);

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  //const navigation = useNavigation();

  const [accountAddedGoToVerification, setAccountAddedGoToVerification] = useState(false);
  useEffect(() => {
    if(accountAddedGoToVerification == true) {
      /*if((selectedDomainValue==LoginManager.getInstance().STUDIO && messageToShow===Studio_ConstManager.getInstance().PLAYER_ADDED_SUCCESSFULLY)
        || (selectedDomainValue==LoginManager.getInstance().STUDIO && messageToShow===Tennis_ConstManager.getInstance().PLAYER_ADDED_SUCCESSFULLY
        || messageToShow===i18n.t('accountAddedSuccessfully'))) {*/
        LoginManager.getInstance().username = newAccountEmail;
        LoginManager.getInstance().password = newAccountNonEncryptedPassword;
        LoginManager.getInstance().md5EncodedPassword = newAccountMD5EncodedPassword;
        if(selectedDomainValue == LoginManager.getInstance().STUDIO)
          LoginManager.getInstance().domain = LoginManager.getInstance().STUDIO;
        else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
          LoginManager.getInstance().domain = LoginManager.getInstance().TENNIS;
        if(Platform.OS != 'web') {
          SecureStore.setItemAsync('storedEmail', JSON.stringify(newAccountEmail)).catch((error) => console.log('Error during storedEmail storage!', error));
          SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(newAccountNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
        }
        else { //web
          sessionStorage.setItem('storedEmail', newAccountEmail);
          sessionStorage.setItem('storedNonEncryptedPassword', newAccountNonEncryptedPassword);
        }
        
        navigation.reset({
          index: 1,
          routes: [{ name: 'SignUpKey', params: { title: i18n.t('accountVerification'), backLabel: i18n.t('back'), selectedDomainValue } }]
          //key: null
        });
      //}
    }
  }, [accountAddedGoToVerification]);  

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("SignUpView: I'm ready!");
    GUIManager.getInstance().mode = GUIManager.getInstance().PLAYER_LOGGED_IN_MODE; //init
  }, [alreadyRunOnceOnViewLoad]);

  const [messageToShow, setMessageToShow] = useState<string | void>('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow!='') {
      var translatedMessageToShow: string | void = messageToShow;
      if((selectedDomainValue==LoginManager.getInstance().STUDIO && messageToShow === Studio_ConstManager.getInstance().PLAYER_ADDED_SUCCESSFULLY)
         || (selectedDomainValue==LoginManager.getInstance().TENNIS && messageToShow === Tennis_ConstManager.getInstance().PLAYER_ADDED_SUCCESSFULLY))
        translatedMessageToShow = i18n.t('accountAddedSuccessfully');
      else if(messageToShow === Studio_ConstManager.getInstance().ANOTHER_USER_EXISTS_WITH_THIS_EMAIL
         || messageToShow === Tennis_ConstManager.getInstance().ANOTHER_USER_EXISTS_WITH_THIS_EMAIL)
        translatedMessageToShow = i18n.t('thereIsAnotherAccountWithThisEmail');      
      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        // Works on both Android and iOS
        if (typeof translatedMessageToShow === 'string') {
          if(MyAlertReactNative != null) {
            MyAlertReactNative.alert(
              translatedMessageToShow,
              '',
              [
                {
                  text: 'OK',
                  onPress: () => {
                    if(translatedMessageToShow == i18n.t('accountAddedSuccessfully'))
                      setAccountAddedGoToVerification(true);
                    else
                      setMessageToShow('');
                  }
                }
              ],
              { cancelable: false }
            );
          }
          else if(MyAlertForWeb != null) {
            setAlertForWebText(translatedMessageToShow); 
            setAlertForWebVisible(true);
          }
        }
      }, 500);
    }
  }, [isLoading]);

  const [registrationMode, setRegistrationMode] = useState(Generic_ConstManager.getInstance().PLAYER);
  useEffect(() => {
    if(registrationMode === Generic_ConstManager.getInstance().PLAYER) {
      GUIManager.getInstance().mode = GUIManager.getInstance().PLAYER_LOGGED_IN_MODE;
    }
    else if(registrationMode === Generic_ConstManager.getInstance().OWNER) {
      GUIManager.getInstance().mode = GUIManager.getInstance().OWNER_LOGGED_IN_MODE;
    }
  }, [registrationMode]);

  const [studioRehearsalsRecordings, setStudioRehearsalsRecordings] = useState(Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY);
  useEffect(() => {
    if(studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY) {
      console.log("Rehearsals only will be allowed through Handybooker");
    }
    else if(studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY) {
      console.log("Recordings only will be allowed through Handybooker");
    }
    else if(studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS) {
      console.log("Both rehearsals and recordings will be allowed through Handybooker");
    }
  }, [studioRehearsalsRecordings]);

  const [tennisPrivateClubMode, setTennisPrivateClubMode] = useState(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
  useEffect(() => {
    if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY) {
      console.log(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
    }
    else if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS) {
      console.log(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS);
    }
    else if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE) {
      console.log(Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE);
    }
  }, [tennisPrivateClubMode]);

  const [newAccountName, setNewAccountName] = useState('');
  const [newAccountSurname, setNewAccountSurname] = useState('');
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [newAccountTelephone, setNewAccountTelephone] = useState('');
  const [newAccountNonEncryptedPassword, setNewAccountNonEncryptedPassword] = useState('');
  useEffect(() => {
    (async () => {
      if(Platform.OS == "web") {
        const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(newAccountNonEncryptedPassword);
        console.log('Digest (web): ', digest);
        setNewAccountMD5EncodedPassword(digest);
      }
      else { //mobile
        const digest = await UtilsManager.getInstance().encodeWithMD5(newAccountNonEncryptedPassword);
        //console.log('Digest: ', digest);
        setNewAccountMD5EncodedPassword(digest);
      }
    })();
  }, [newAccountNonEncryptedPassword]);
  const [newAccountMD5EncodedPassword, setNewAccountMD5EncodedPassword] = useState('');
  const [newAccountNonEncryptedPasswordAgain, setNewAccountNonEncryptedPasswordAgain] = useState('');
  
  const [newMusicianBandName_Studio, setNewMusicianBandName_Studio] = useState('');

  const [newOwnerCompanyName, setNewOwnerCompanyName] = useState('');
  const [newOwnerPostalAddress, setNewOwnerPostalAddress] = useState('');
  const [newOwnerCity, setNewOwnerCity] = useState('');
  const [newOwnerCountry, setNewOwnerCountry] = useState('');
  const [newOwnerWebsite, setNewOwnerWebsite] = useState('');

  const [newOwnerRoleAndID, setNewOwnerRoleAndID] = useState({
    role: -1, //GUEST_ROLE = 0, PLAYER_ROLE = 1, OWNER_ROLE = 2, ADMIN_ROLE = 3;
    ID: -1,
    verified: false,  //verified is when he succesfully adds the verification code sent by HandyBooker
    domain: selectedDomainValue==LoginManager.getInstance().STUDIO ? LoginManager.getInstance().domain = LoginManager.getInstance().STUDIO
            : selectedDomainValue==LoginManager.getInstance().TENNIS ? LoginManager.getInstance().domain = LoginManager.getInstance().TENNIS
            : LoginManager.getInstance().UNKNOWN
  });
  useEffect(() => {
    if(newOwnerRoleAndID.role === -1) {
      //do nothing - initialization
    }
    else if(newOwnerRoleAndID.ID!=-1 && newOwnerRoleAndID.role===Generic_ConstManager.getInstance().OWNER_ROLE) {
      setMessageToShow(i18n.t('accountAddedSuccessfully'));
      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        // Works on both Android and iOS
        if(MyAlertReactNative != null) {
          MyAlertReactNative.alert(
            i18n.t('accountAddedSuccessfully'),
            '',
            [
              {
                text: 'OK',
                onPress: () => {
                  setAccountAddedGoToVerification(true);
                }
              }
            ],
            { cancelable: false }
          );
        }
        else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('accountAddedSuccessfully')); 
          setAlertForWebVisible(true);
        }
      }, 500);
    }
    else {
      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        if(MyAlertReactNative != null) {
          // Works on both Android and iOS
          MyAlertReactNative.alert(
            i18n.t('internalError'),
            '',
            [
              {
                text: 'OK',
                onPress: () => {
                  LoginManager.getInstance().logout();
                  navigation.reset({
                    index: 1,
                    routes: [{ name: 'HandyBooker_Home' }]
                    //key: null
                  });
                }
              }
            ],
            { cancelable: false }
          );
        }
        else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('internalError')); 
          setAlertForWebVisible(true);
        }
      }, 500);
    }
  }, [newOwnerRoleAndID]);

  function signUp() 
  {
    if(newAccountName === '') { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompleteName')); else { setAlertForWebText(i18n.t('pleaseCompleteName')); setAlertForWebVisible(true); } }
    else if(newAccountSurname === '') { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompleteSurname')); else { setAlertForWebText(i18n.t('pleaseCompleteSurname')); setAlertForWebVisible(true); } }
    else if(newAccountEmail === '') { if(MyAlertReactNative != null) alert(i18n.t('newAccountEmail')); else { setAlertForWebText(i18n.t('newAccountEmail')); setAlertForWebVisible(true); } }
    else if(UtilsManager.getInstance().emailIsValid(newAccountEmail) == false) { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompleteValidEmail')); else { setAlertForWebText(i18n.t('pleaseCompleteValidEmail')); setAlertForWebVisible(true); } }
    else if(newAccountTelephone === '') { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompletePhone')); else { setAlertForWebText(i18n.t('pleaseCompletePhone')); setAlertForWebVisible(true); } }
    else if(newAccountNonEncryptedPassword === '') { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompletePassword')); else { setAlertForWebText(i18n.t('pleaseCompletePassword')); setAlertForWebVisible(true); } }
    else if(newAccountNonEncryptedPasswordAgain === '') { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompletePasswordAgain')); else { setAlertForWebText(i18n.t('pleaseCompletePasswordAgain')); setAlertForWebVisible(true); } }
    else if(newAccountNonEncryptedPassword != newAccountNonEncryptedPasswordAgain) { if(MyAlertReactNative != null) alert(i18n.t('passwordsDoNotMatch')); else { setAlertForWebText(i18n.t('passwordsDoNotMatch')); setAlertForWebVisible(true); } }
    else if(registrationMode===Generic_ConstManager.getInstance().OWNER && newOwnerCompanyName==="") { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompleteCompanyName')); else { setAlertForWebText(i18n.t('pleaseCompleteCompanyName')); setAlertForWebVisible(true); } }
    else if(registrationMode===Generic_ConstManager.getInstance().OWNER && newOwnerPostalAddress==="") { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompletePostalAddress')); else { setAlertForWebText(i18n.t('pleaseCompletePostalAddress')); setAlertForWebVisible(true); } }
    else if(registrationMode===Generic_ConstManager.getInstance().OWNER && newOwnerCity==="") { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompleteCity')); else { setAlertForWebText(i18n.t('pleaseCompleteCity')); setAlertForWebVisible(true); } }
    else if(registrationMode===Generic_ConstManager.getInstance().OWNER && newOwnerCountry==="") { if(MyAlertReactNative != null) alert(i18n.t('pleaseCompleteCountry')); else { setAlertForWebText(i18n.t('pleaseCompleteCountry')); setAlertForWebVisible(true); } }
    else {       
      if(registrationMode === Generic_ConstManager.getInstance().PLAYER) {
        if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
          signUpMusician_Studio();
        }
        else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
          signUpPlayer_Tennis();
        }
      }
      else if(registrationMode === Generic_ConstManager.getInstance().OWNER) {
        signUpOwner();
      }
    }  
  }

  async function signUpMusician_Studio() {
    setIsLoading(true);
    const tmpMusicianToBeAdded : Studio_Player = {
      id: -1,
      name: newAccountName,
      surname: newAccountSurname,
      telephone: newAccountTelephone,
      email: newAccountEmail,
      password: newAccountMD5EncodedPassword,
      last_login: "",
      language: LoginManager.getInstance().language,
      email_notifications: true,
      registration_date: "",
      verified: false,    //verified is when he succesfully adds the verification code sent by HandyBooker
      band_name: newMusicianBandName_Studio
    }
    const webServiceResponseStr : string | void = await Studio_RestManager.getInstance().addPlayer(tmpMusicianToBeAdded);

    if(webServiceResponseStr != Generic_ConstManager.getInstance().ANOTHER_USER_EXISTS_WITH_THIS_EMAIL) {
      //init    
      LoginManager.getInstance().username = newAccountEmail;
      LoginManager.getInstance().password = newAccountNonEncryptedPassword;
      LoginManager.getInstance().md5EncodedPassword = newAccountMD5EncodedPassword;

      //store user email/password locally on the device
      if(Platform.OS != 'web') {
        SecureStore.setItemAsync('storedEmail', JSON.stringify(newAccountEmail)).catch((error) => console.log('Error during storedEmail storage!', error));
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(newAccountNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
      }
      else { //web
        sessionStorage.setItem('storedEmail', newAccountEmail);
        sessionStorage.setItem('storedNonEncryptedPassword', newAccountNonEncryptedPassword);
      }
      console.log("Email and password stored locally!");
      
      var curMusician : Studio_Player = await Studio_RestManager.getInstance().getPlayerByEmail(newAccountEmail) as Studio_Player;
      LoginManager.getInstance().loggedInMusician = curMusician;

      LoginManager.getInstance().role = Generic_ConstManager.getInstance().PLAYER_ROLE;
      LoginManager.getInstance().ID = curMusician.id;
      LoginManager.getInstance().verified = curMusician.verified;
      LoginManager.getInstance().domain = LoginManager.getInstance().STUDIO;

      GUIManager.getInstance().mode = GUIManager.getInstance().PLAYER_LOGGED_IN_MODE;
      //-init
    }

    setMessageToShow(webServiceResponseStr);
    setIsLoading(false);
  }

  async function signUpPlayer_Tennis() {
    setIsLoading(true);
    const tmpTennisPlayerToBeAdded : Tennis_Player = {
      id: -1,
      name: newAccountName,
      surname: newAccountSurname,
      telephone: newAccountTelephone,
      email: newAccountEmail,
      password: newAccountMD5EncodedPassword,
      last_login: "",
      language: LoginManager.getInstance().language,
      email_notifications: true,
      registration_date: "",
      verified: false    //verified is when he succesfully adds the verification code sent by HandyBooker
    }
    const webServiceResponseStr : string | void = await Tennis_RestManager.getInstance().addPlayer(tmpTennisPlayerToBeAdded);

    if(webServiceResponseStr != Generic_ConstManager.getInstance().ANOTHER_USER_EXISTS_WITH_THIS_EMAIL) {
      //init    
      LoginManager.getInstance().username = newAccountEmail;
      LoginManager.getInstance().password = newAccountNonEncryptedPassword;
      LoginManager.getInstance().md5EncodedPassword = newAccountMD5EncodedPassword;

      //store user email/password locally on the device
      if(Platform.OS != 'web') {
        SecureStore.setItemAsync('storedEmail', JSON.stringify(newAccountEmail)).catch((error) => console.log('Error during storedEmail storage!', error));
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(newAccountNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
      }
      else { //web
        sessionStorage.setItem('storedEmail', newAccountEmail);
        sessionStorage.setItem('storedNonEncryptedPassword', newAccountNonEncryptedPassword);
      }
      console.log("Email and password stored locally!");

      var curTennisPlayer : Tennis_Player = await Tennis_RestManager.getInstance().getPlayerByEmail(newAccountEmail) as Tennis_Player;
      LoginManager.getInstance().loggedInTennisPlayer = curTennisPlayer;

      LoginManager.getInstance().role = Generic_ConstManager.getInstance().PLAYER_ROLE;
      LoginManager.getInstance().ID = curTennisPlayer.id;
      LoginManager.getInstance().verified = curTennisPlayer.verified;
      LoginManager.getInstance().domain = LoginManager.getInstance().TENNIS;

      GUIManager.getInstance().mode = GUIManager.getInstance().PLAYER_LOGGED_IN_MODE;
      //-init
    }

    setMessageToShow(webServiceResponseStr);
    setIsLoading(false);
  }

  async function signUpOwner() {
    setIsLoading(true);
    const tmpOwnerToBeAdded : Owner = {
      id: -1,
      name: newAccountName,
      surname: newAccountSurname,
      telephone: newAccountTelephone,
      email: newAccountEmail,
      password: newAccountMD5EncodedPassword,
      company_name: newOwnerCompanyName,
      postal_address: newOwnerPostalAddress,
      city: newOwnerCity,
      country: newOwnerCountry,
      longitude: -1.0,
      latitude: -1.0,
      website: newOwnerWebsite,
      last_login: "",
      language: LoginManager.getInstance().language,
      email_notifications: true,
      registration_date: "",
      verified: false,    //verified is when he succesfully adds the verification code sent by HandyBooker
      active: true,       //when active==false, this means that I manually created the user
      info_source: ""  
    }
    var newOwnerRoleAndID : RoleIDAndDomain = {role: -1, ID: -1, verified: false, domain: ""};
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      newOwnerRoleAndID = await Studio_RestManager.getInstance().addOwner(tmpOwnerToBeAdded);
      
      //init
      LoginManager.getInstance().username = newAccountEmail;
      LoginManager.getInstance().password = newAccountNonEncryptedPassword;
      LoginManager.getInstance().md5EncodedPassword = newAccountMD5EncodedPassword;

      //store user email/password locally on the device
      if(Platform.OS != 'web') {
        SecureStore.setItemAsync('storedEmail', JSON.stringify(newAccountEmail)).catch((error) => console.log('Error during storedEmail storage!', error));
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(newAccountNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
      }
      else {  //web
        sessionStorage.setItem('storedEmail', newAccountEmail);
        sessionStorage.setItem('storedNonEncryptedPassword', newAccountNonEncryptedPassword);
      }
      console.log("Email and password stored locally!");
      
      LoginManager.getInstance().role = Generic_ConstManager.getInstance().OWNER_ROLE;
      LoginManager.getInstance().ID = newOwnerRoleAndID.ID;
      LoginManager.getInstance().verified = true;
      LoginManager.getInstance().domain = newOwnerRoleAndID.domain;

      GUIManager.getInstance().mode = GUIManager.getInstance().OWNER_LOGGED_IN_MODE;

      var curOwner : Owner = await Studio_RestManager.getInstance().getOwner(newAccountEmail) as Owner;
      LoginManager.getInstance().loggedInOwner = curOwner;
      //-init
      
      //add extra info
      var tmpOwnerExtraInfo : Studio_OwnerExtraInfo = {
        id: -1,
        owner_id: -1,
        allows_rehearsal: false,
        allows_recording: false
      };
      if(studioRehearsalsRecordings == Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY) {
        tmpOwnerExtraInfo.allows_rehearsal = true;
        tmpOwnerExtraInfo.allows_recording = false;

        LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = true;
        LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = false;
      }
      else if(studioRehearsalsRecordings == Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY) {
        tmpOwnerExtraInfo.allows_rehearsal = false;
        tmpOwnerExtraInfo.allows_recording = true;

        LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = false;
        LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = true;
      }
      else if(studioRehearsalsRecordings == Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS) {
        tmpOwnerExtraInfo.allows_rehearsal = true;
        tmpOwnerExtraInfo.allows_recording = true;

        LoginManager.getInstance().loggedInStudioOwner_allowsRehearsals = true;
        LoginManager.getInstance().loggedInStudioOwner_allowsRecordings = true;
      }
      await Studio_RestManager.getInstance().addOwnerExtraInfo(tmpOwnerExtraInfo);
      //-add extra info
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      newOwnerRoleAndID = await Tennis_RestManager.getInstance().addOwner(tmpOwnerToBeAdded);

      //init
      LoginManager.getInstance().username = newAccountEmail;
      LoginManager.getInstance().password = newAccountNonEncryptedPassword;
      LoginManager.getInstance().md5EncodedPassword = newAccountMD5EncodedPassword;

      //store user email/password locally on the device
      if(Platform.OS != 'web') {
        SecureStore.setItemAsync('storedEmail', JSON.stringify(newAccountEmail)).catch((error) => console.log('Error during storedEmail storage!', error));
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(newAccountNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
      }
      else { //web
        sessionStorage.setItem('storedEmail', newAccountEmail);
        sessionStorage.setItem('storedNonEncryptedPassword', newAccountNonEncryptedPassword);
      }
      console.log("Email and password stored locally!");
      
      LoginManager.getInstance().role = Generic_ConstManager.getInstance().OWNER_ROLE;
      LoginManager.getInstance().ID = newOwnerRoleAndID.ID;
      LoginManager.getInstance().verified = true;
      LoginManager.getInstance().domain = newOwnerRoleAndID.domain;

      GUIManager.getInstance().mode = GUIManager.getInstance().OWNER_LOGGED_IN_MODE;

      var curOwner : Owner = await Tennis_RestManager.getInstance().getOwner(newAccountEmail) as Owner;
      LoginManager.getInstance().loggedInOwner = curOwner;
      //-init

      //add extra info
      var tmpOwnerExtraInfo_Tennis : Tennis_OwnerExtraInfo = {
        id: -1,
        owner_id: LoginManager.getInstance().ID,
        allows_reservations_to_members: false,
        allows_reservations_to_non_members: false
      };
      if(tennisPrivateClubMode == Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY) {
        tmpOwnerExtraInfo_Tennis.allows_reservations_to_members = true;
        tmpOwnerExtraInfo_Tennis.allows_reservations_to_non_members= false;

        LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = true;
        LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = false;
      }
      else if(tennisPrivateClubMode == Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS) {
        tmpOwnerExtraInfo_Tennis.allows_reservations_to_members = true;
        tmpOwnerExtraInfo_Tennis.allows_reservations_to_non_members= true;

        LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = true;
        LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = true;
      }
      else if(tennisPrivateClubMode == Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE) {
        tmpOwnerExtraInfo_Tennis.allows_reservations_to_members = false;
        tmpOwnerExtraInfo_Tennis.allows_reservations_to_non_members= true;

        LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members = false;
        LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_non_members = true;
      }
      await Tennis_RestManager.getInstance().addOwnerExtraInfo(tmpOwnerExtraInfo_Tennis);
      //-add extra info
    }
    setNewOwnerRoleAndID(newOwnerRoleAndID);
    setIsLoading(false);
  }

  // Create a ref for the TextInput components
  const nameInputRef = useRef(null);
  const surnameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const telephoneInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const passwordAgainInputRef = useRef(null);
  const bandNameInputRef = useRef(null);
  const companyNameInputRef = useRef(null);
  const postalAddressInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const countryInputRef = useRef(null);
  const websiteInputRef = useRef(null);

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
              <Appbar.Header>
                  <Appbar.Content title={i18n.t('createAccount')} />
              </Appbar.Header>
              <ScrollView style={styles.scrollView}>
                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />
                <View style={styles.domainDropdown}>
                  <DropDown
                      label={i18n.t('category')}
                      mode={'outlined'}
                      visible={showDomainDropdownList}
                      showDropDown={() => setShowDomainDropdownList(true)}
                      onDismiss={() => setShowDomainDropdownList(false)}
                      value={selectedDomainValue}
                      setValue={setSelectedDomainValue}
                      list={curDropdownList}
                      inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showDomainDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                  />
                  <Divider style={{ height: 5, backgroundColor: "white" }}/>
                </View>
                <RadioButton.Group onValueChange={newValue => setRegistrationMode(newValue)} value={registrationMode}>
                  <RadioButton.Item
                    key={Generic_ConstManager.getInstance().PLAYER}
                    label={selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('iAmMusician')
                          : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('iAmTennisPlayer')
                          : "ERROR - PLAYER!"}
                    value={Generic_ConstManager.getInstance().PLAYER}
                    status={ registrationMode === Generic_ConstManager.getInstance().PLAYER ? 'checked' : 'unchecked' }
                  />
                  <RadioButton.Item
                    key={Generic_ConstManager.getInstance().OWNER}
                    label={selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('iAmStudioOwner')
                          : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('iAmTennisCourtOwner')
                          : "ERROR - OWNER!"}
                    value={Generic_ConstManager.getInstance().OWNER}
                    status={ registrationMode === Generic_ConstManager.getInstance().OWNER ? 'checked' : 'unchecked' }
                  />
                </RadioButton.Group>
                <Divider/>
                <Subheading style={[styles.subheading, {display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none'}]}>
                  {selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('studioOwnerInfo')
                  : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourtOwnerInfo')
                  : "ERROR - OWNER!"}
                </Subheading>
                <TouchableOpacity onPress={() => nameInputRef.current.focus()}>
                  <TextInput
                    ref={nameInputRef}
                    label={i18n.t('name')}
                    value={newAccountName}
                    onChangeText={tmpName => setNewAccountName(tmpName)}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => surnameInputRef.current.focus()}>
                  <TextInput
                    ref={surnameInputRef}
                    label={i18n.t('surname')}
                    value={newAccountSurname}
                    onChangeText={tmpSurname => setNewAccountSurname(tmpSurname)}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => emailInputRef.current.focus()}>
                  <TextInput
                    ref={emailInputRef}
                    label="Email"
                    value={newAccountEmail}
                    onChangeText={tmpEmail => setNewAccountEmail(tmpEmail)}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => telephoneInputRef.current.focus()}>
                  <TextInput
                    ref={telephoneInputRef}
                    label={i18n.t('telephone')}
                    value={newAccountTelephone}
                    onChangeText={tmpTelephone => setNewAccountTelephone(tmpTelephone)}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => passwordInputRef.current.focus()}>
                  <TextInput
                    ref={passwordInputRef}
                    label={i18n.t('password')}
                    value={newAccountNonEncryptedPassword}
                    onChangeText={tmpPassword => setNewAccountNonEncryptedPassword(tmpPassword)}
                    secureTextEntry
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => passwordAgainInputRef.current.focus()}>
                  <TextInput
                    ref={passwordAgainInputRef}
                    label={i18n.t('passwordAgain')}
                    value={newAccountNonEncryptedPasswordAgain}
                    onChangeText={tmpPasswordAgain => setNewAccountNonEncryptedPasswordAgain(tmpPasswordAgain)}
                    secureTextEntry
                  />
                </TouchableOpacity>
                {selectedDomainValue==LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => bandNameInputRef.current.focus()}>
                    <TextInput
                      ref={bandNameInputRef}
                      label={i18n.t('bandName')}
                      value={newMusicianBandName_Studio}
                      onChangeText={tmpBandName => setNewMusicianBandName_Studio(tmpBandName)}
                      style={{ display: registrationMode===Generic_ConstManager.getInstance().PLAYER ? 'flex' : 'none' }}
                    />
                  </TouchableOpacity>
                }
                <Subheading style={[styles.subheading, {display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none'}]}>{i18n.t('companyInfo')}</Subheading>
                
                {selectedDomainValue==LoginManager.getInstance().STUDIO && registrationMode==Generic_ConstManager.getInstance().OWNER && <RadioButton.Group onValueChange={newValue => setStudioRehearsalsRecordings(newValue)} value={studioRehearsalsRecordings}>
                  <RadioButton.Item
                    key={Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY}
                    label={i18n.t('reservationsOnlyForRehearsal')}
                    value={Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY}
                    status={ studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_REHEARSALS_ONLY ? 'checked' : 'unchecked' }
                  />
                  <RadioButton.Item
                    key={Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY}
                    label={i18n.t('reservationsOnlyForRecording')}
                    value={Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY}
                    status={ studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_RECORDINGS_ONLY ? 'checked' : 'unchecked' }
                  />
                  <RadioButton.Item
                    key={Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS}
                    label={i18n.t('reservationsForRehearsalAndRecording')}
                    value={Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS}
                    status={ studioRehearsalsRecordings === Studio_ConstManager.getInstance().RESERVATIONS_FOR_BOTH_REHEARSALS_AND_RECORDINGS ? 'checked' : 'unchecked' }
                  />
                </RadioButton.Group>}
                {selectedDomainValue==LoginManager.getInstance().STUDIO && registrationMode==Generic_ConstManager.getInstance().OWNER && <Divider/>}

                {selectedDomainValue==LoginManager.getInstance().TENNIS && registrationMode==Generic_ConstManager.getInstance().OWNER && <RadioButton.Group onValueChange={newValue => setTennisPrivateClubMode(newValue)} value={tennisPrivateClubMode}>
                  <RadioButton.Item
                    key={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY}
                    label={i18n.t('onlyClubMembersCanReserve')}
                    value={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY}
                    status={ tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY ? 'checked' : 'unchecked' }
                  />
                  <RadioButton.Item
                    key={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS}
                    label={i18n.t('clubMembersAndNonClubMembersCanReserve')}
                    value={Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS}
                    status={ tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS ? 'checked' : 'unchecked' }
                  />
                  <RadioButton.Item
                    key={Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE}
                    label={i18n.t('onlyNonClubMembersCanReserve')}
                    value={Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE}
                    status={ tennisPrivateClubMode === Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE ? 'checked' : 'unchecked' }
                  />
                </RadioButton.Group>}
                {selectedDomainValue==LoginManager.getInstance().TENNIS && registrationMode==Generic_ConstManager.getInstance().OWNER && <Divider/>}
                
                <TouchableOpacity onPress={() => companyNameInputRef.current.focus()}>
                  <TextInput
                    ref={companyNameInputRef}
                    label={i18n.t('companyName')}
                    value={newOwnerCompanyName}
                    onChangeText={tmpCompanyName => setNewOwnerCompanyName(tmpCompanyName)}
                    style={{ display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none' }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => postalAddressInputRef.current.focus()}>
                  <TextInput
                    ref={postalAddressInputRef}
                    label={i18n.t('postalAddress')}
                    value={newOwnerPostalAddress}
                    onChangeText={tmpPostalAddress => setNewOwnerPostalAddress(tmpPostalAddress)}
                    style={{ display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none' }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => cityInputRef.current.focus()}>
                  <TextInput
                    ref={cityInputRef}
                    label={i18n.t('city')}
                    value={newOwnerCity}
                    onChangeText={tmpCity => setNewOwnerCity(tmpCity)}
                    style={{ display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none' }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => countryInputRef.current.focus()}>
                  <TextInput
                    ref={countryInputRef}
                    label={i18n.t('country')}
                    value={newOwnerCountry}
                    onChangeText={tmpCountry => setNewOwnerCountry(tmpCountry)}
                    style={{ display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none' }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => websiteInputRef.current.focus()}>
                  <TextInput
                    ref={websiteInputRef}
                    label={i18n.t('website')}
                    value={newOwnerWebsite}
                    onChangeText={tmpWebsite => setNewOwnerWebsite(tmpWebsite)}
                    style={{ display: registrationMode===Generic_ConstManager.getInstance().OWNER ? 'flex' : 'none' }}
                  />
                </TouchableOpacity>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-plus" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}  onPress={() => signUp()}>{i18n.t('signUp')}</Button>
              </ScrollView>
              {MyAlertForWeb!=null && 
                <MyAlertForWeb
                  show={alertForWebVisible}
                  showProgress={false}
                  title={i18n.t('caution')}
                  message={alertForWebText}
                  closeOnTouchOutside={false}
                  closeOnHardwareBackPress={false}
                  showConfirmButton={true}
                  confirmText="OK"
                  confirmButtonColor="#DD6B55"
                  onCancelPressed={() => {
                    setAlertForWebVisible(false);
                  }}
                  onConfirmPressed={() => {
                    if(alertForWebText == i18n.t('accountAddedSuccessfully')) {
                      setAccountAddedGoToVerification(true);
                    }
                    else if(alertForWebText == i18n.t('internalError')) {
                      LoginManager.getInstance().logout();
                      navigation.reset({
                        index: 1,
                        routes: [{ name: 'HandyBooker_Home' }]
                        //key: null
                      });
                    }
                    else
                      setAlertForWebVisible(false);
                  }}  
                />
              }
              <AdBanner/>
            </View>
            <RightColumnWeb/>
          </View>
        </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    );
  };

export default SignUpView;