import React, { useState, useEffect, useRef } from 'react';
import { Appbar, TextInput, Button, Snackbar } from 'react-native-paper';
import { Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableWithoutFeedback, View } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import UtilsManager from '../../managers/UtilsManager';
import AdBanner from '../generic/AdBanner';
import styles from '../../css/myCss';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const AdminView = () => {

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("AdminView: I'm ready!");
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);
   
  async function sendEmailTennis() {
    setIsLoading(true);   
    let webServiceResponseStr : string | void;
    //webServiceResponseStr = await Tennis_RestManager.getInstance().sendEmailToInactiveOwners();
    setIsLoading(false);
  }

  async function sendEmailTennisTest() {
    setIsLoading(true);   
    let webServiceResponseStr : string | void;
    webServiceResponseStr = await Tennis_RestManager.getInstance().sendEmailToInactiveOwnersTest();
    setIsLoading(false);
  }

  async function sendEmailStudios() {
    setIsLoading(true);   
    let webServiceResponseStr : string | void;
    //webServiceResponseStr = await Studio_RestManager.getInstance().sendEmailToInactiveOwners();
    setIsLoading(false);
  }

  async function sendEmailStudiosTest() {
    setIsLoading(true);   
    let webServiceResponseStr : string | void;
    webServiceResponseStr = await Studio_RestManager.getInstance().sendEmailToInactiveOwnersTest();
    setIsLoading(false);
  }

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <View style={styles.columnMainApp}>
              <ScrollView style={styles.scrollView}>
                <Appbar.Header>
                  <Appbar.Content title='ADMINISTRATOR' />
                </Appbar.Header>
                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />               
                
                <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                  <Button icon={({ color, size }) => (<MaterialCommunityIcons name="email-send" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} style={{ margin: 20 }} onPress={() => sendEmailTennisTest()} >Tennis - Test</Button>
                  <Button icon={({ color, size }) => (<MaterialCommunityIcons name="email-send" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} style={{ margin: 20 }} onPress={() => sendEmailTennis()} >Tennis - Send email to all owners</Button>
                </View>
                <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                  <Button icon={({ color, size }) => (<MaterialCommunityIcons name="email-send" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} style={{ margin: 20 }} onPress={() => sendEmailStudiosTest()} >Studios - Test</Button>
                  <Button icon={({ color, size }) => (<MaterialCommunityIcons name="email-send" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} style={{ margin: 20 }} onPress={() => sendEmailStudios()} >Studios - Send email to all owners</Button>
                </View>
              </ScrollView>
            </View>
          </View>
        </TouchableWithoutFeedback>
        <AdBanner/>
      </KeyboardAvoidingView>
    );
  };

export default AdminView;