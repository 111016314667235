import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { FAB, Text } from 'react-native-paper';
import styles from '../../css/myCss';
import i18n from '../../utils/i18n';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const Tip = () => {
    const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
    useEffect(() => {
      console.log("Tip: I'm ready!");
      animateIn();
    }, [alreadyRunOnceOnViewLoad]);

  const [animation] = useState(new Animated.Value(0));

  const animateIn = () => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 3000,
      useNativeDriver: true,
    }).start();
  };

  const animateOut = () => {
    Animated.timing(animation, {
      toValue: 0,
      duration: 3000,
      useNativeDriver: true,
    }).start();
  };

  const opacity = animation.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [0, 0, 1],
  });

  const scale = animation.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [0.01, 0.5, 1],
  });

  return (
    <View style={styles.tipContainer}>
        <Text style={styles.tipText}>{i18n.t('pullDownToUpdate')}</Text>
        <Animated.View style={[styles.tipFab, { opacity, transform: [{ scale }] }]}>
        {
            <FAB icon={({ color, size }) => (<MaterialCommunityIcons name="arrow-down-bold" color={color} size={size} />)} />
        }
      </Animated.View>
    </View>
  );
};



export default React.memo(Tip);