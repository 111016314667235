import GUIManager from "./GUIManager";
import * as SecureStore from 'expo-secure-store';
import GenericConstManager from "./Generic_ConstManager";
import Generic_RestManager from "./Generic_RestManager";
import { Platform } from "react-native";
import UtilsManager from "./UtilsManager";
import Studio_RestManager from "./Studio_RestManager";
import Tennis_RestManager from "./Tennis_RestManager";
import Generic_ConstManager from "./Generic_ConstManager";
import i18n from '../utils/i18n';

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
class LoginManager {
    public readonly EN: string = "EN";
    public readonly GR: string = "GR";
    
    public readonly UNKNOWN: string = "UNKNOWN";
    public readonly STUDIO: string = "STUDIO";
    public readonly TENNIS: string = "TENNIS";

    public username: string;
    public password: string;
    public md5EncodedPassword: string;
    public language: string;    
    public encrypted_token: string;

    public readonly GUEST_ROLE: number = 0;
    public readonly PLAYER_ROLE: number = 1;
    public readonly OWNER_ROLE: number = 2;
    public readonly ADMIN_ROLE: number = 3;

    public role: number; 
    public ID: number;
    public verified: boolean;
    public domain: string; //"UNKNOWN" / "TENNIS" / "STUDIO"   PROSOXH: AYTO EINAI TO DOMAIN OPOY O XRHSTHS EKANE REGISTRATION! DEN TO ALLAZW!
    //generic player
    public genericPlayer_name: string;
    public genericPlayer_surname: string;
    public genericPlayer_telephone: string;
    public genericPlayer_email: string;
    public genericPlayer_language: string;

    public loggedInMusician: Studio_Player;
    public loggedInTennisPlayer: Tennis_Player;
    public loggedInOwner: Owner;

    //notifications
    //public allNotifications;
    
    //extra info
    public loggedInStudioOwner_allowsRehearsals: boolean;
    public loggedInStudioOwner_allowsRecordings: boolean;

    public loggedInTennisOwner_allows_reservations_to_members: boolean;
    public loggedInTennisOwner_allows_reservations_to_non_members: boolean;
    public loggedInTennisOwner_memberships: Array<Tennis_OwnerMembership>;

    private static instance: LoginManager;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() { 
        this.role = -1;
        this.ID = -1;
        this.verified = false;
        this.domain = this.STUDIO;
        this.genericPlayer_name = '';
        this.genericPlayer_surname = '';
        this.genericPlayer_telephone = '';
        this.genericPlayer_email = '';
        this.genericPlayer_language = '';

        this.username = 'test';
        this.password = 'test';
        this.md5EncodedPassword = 'test';
        this.language = '';
        this.encrypted_token = '';

        this.loggedInMusician = <Studio_Player>{
            id: -1,
            name: '',
            surname: '',
            telephone: '',
            email: '',
            password: '',
            last_login: '',
            language: '',
            email_notifications: false,
            registration_date: '',
            verified: false,
            band_name: ''
        };
        this.loggedInTennisPlayer = <Tennis_Player>{
            id: -1,
            name: '',
            surname: '',
            telephone: '',
            email: '',
            password: '',
            last_login: '',
            language: '',
            email_notifications: false,
            registration_date: '',
            verified: false
        };
        this.loggedInOwner = <Owner>{
            id: -1,
            name: '',
            surname: '',
            telephone: '',
            email: '',
            password: '',
            company_name: '',
            postal_address: '',
            city: '',
            country: '',
            longitude: -1,
            latitude: -1,
            website: '',
            last_login: '',
            language: '',
            email_notifications: false,
            registration_date: '',
            verified: false,
            active: false,
            info_source: ''
        };

        /*this.allNotifications = [
            {
                "content": {
                    "title": "New reservation",
                    "body": "20/03/2023, 11:00-12:00",
                    "data": {
                        "conversationId": "1234",
                        "senderId": "5678"
                    }
                },
                "sound": "default",
                "badge": 1,
                "categoryIdentifier": "MESSAGE",
                "threadIdentifier": "1234",
                "attachments": [],
                "mutableContent": false,
                "targetContentIdentifier": null,
                "userInfo": {}
            },
            {
                "content": {
                    "title": "New reservation",
                    "body": "25/03/2023, 14:00-15:00",
                    "data": {
                        "conversationId": "1234",
                        "senderId": "5678"
                    }
                },
                "sound": "default",
                "badge": 1,
                "categoryIdentifier": "MESSAGE",
                "threadIdentifier": "1234",
                "attachments": [],
                "mutableContent": false,
                "targetContentIdentifier": null,
                "userInfo": {}
            }
        ];*/

        this.loggedInStudioOwner_allowsRehearsals = false;
        this.loggedInStudioOwner_allowsRecordings = false;

        this.loggedInTennisOwner_allows_reservations_to_members = false;
        this.loggedInTennisOwner_allows_reservations_to_non_members = false;

        this.loggedInTennisOwner_memberships = [];
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): LoginManager {
        if (!LoginManager.instance) {
            LoginManager.instance = new LoginManager();
        }

        return LoginManager.instance;
    }

    /**
     * Finally, any singleton should define some business logic, which can be
     * executed on its instance.
     */
    public logout() {
        //clear stored username/password
        if(Platform.OS != 'web') {
            SecureStore.setItemAsync('storedEmail', JSON.stringify("")).catch((error) => console.log('Error during storedEmail storage!', error));
            SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify("")).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
        }
        else {
            sessionStorage.setItem('storedEmail', "");
            sessionStorage.setItem('storedNonEncryptedPassword', "");
        }
        
        GUIManager.getInstance().initialize();
        
        this.initialize();
    }

    public initialize()
    {
        this.role = -1;
        this.ID = -1;
        this.verified = false;
        this.domain = this.STUDIO;
        this.genericPlayer_name = '';
        this.genericPlayer_surname = '';
        this.genericPlayer_telephone = '';
        this.genericPlayer_email = '';
        this.genericPlayer_language = '';

        this.username = 'test';
        this.password = 'test';
        this.md5EncodedPassword = 'test';
        this.language = '';
        this.encrypted_token = '';

        this.loggedInMusician = <Studio_Player>{
            id: -1,
            name: '',
            surname: '',
            telephone: '',
            email: '',
            password: '',
            last_login: '',
            language: '',
            email_notifications: false,
            registration_date: '',
            verified: false,
            band_name: ''
        };
        this.loggedInTennisPlayer = <Tennis_Player>{
            id: -1,
            name: '',
            surname: '',
            telephone: '',
            email: '',
            password: '',
            last_login: '',
            language: '',
            email_notifications: false,
            registration_date: '',
            verified: false
        };
        this.loggedInOwner = <Owner>{
            id: -1,
            name: '',
            surname: '',
            telephone: '',
            email: '',
            password: '',
            company_name: '',
            postal_address: '',
            city: '',
            country: '',
            longitude: -1,
            latitude: -1,
            website: '',
            last_login: '',
            language: '',
            email_notifications: false,
            registration_date: '',
            verified: false,
            active: false,
            info_source: ''
        };

        /*this.allNotifications = [
            {
                "content": {
                    "title": "New reservation",
                    "body": "20/03/2023, 11:00-12:00",
                    "data": {
                        "conversationId": "1234",
                        "senderId": "5678"
                    }
                },
                "sound": "default",
                "badge": 1,
                "categoryIdentifier": "MESSAGE",
                "threadIdentifier": "1234",
                "attachments": [],
                "mutableContent": false,
                "targetContentIdentifier": null,
                "userInfo": {}
            },
            {
                "content": {
                    "title": "New reservation",
                    "body": "25/03/2023, 14:00-15:00",
                    "data": {
                        "conversationId": "1234",
                        "senderId": "5678"
                    }
                },
                "sound": "default",
                "badge": 1,
                "categoryIdentifier": "MESSAGE",
                "threadIdentifier": "1234",
                "attachments": [],
                "mutableContent": false,
                "targetContentIdentifier": null,
                "userInfo": {}
            }
        ];*/

        this.loggedInStudioOwner_allowsRehearsals = false;
        this.loggedInStudioOwner_allowsRecordings = false;

        this.loggedInTennisOwner_allows_reservations_to_members = false;
        this.loggedInTennisOwner_allows_reservations_to_non_members = false;
        this.loggedInTennisOwner_memberships = [];
    }

    public async handleWebPageReload(): Promise<boolean> {
        if(Platform.OS === 'web') {
            if(this.username==undefined || this.username=="" || this.username=="test"
                || this.password==undefined || this.password=="" || this.password=="test"
                || this.md5EncodedPassword==undefined || this.md5EncodedPassword=="" || this.md5EncodedPassword=="test") { //do whatever is performed during login
                console.log("handleWebPageReload...prospathw na swsw undefined apo web page reload");

                this.username = sessionStorage.getItem('storedEmail')!;
                this.password = sessionStorage.getItem('storedNonEncryptedPassword')!;
                const tmpStoredLanguage = sessionStorage.getItem('storedLanguage')!;
                i18n.locale = tmpStoredLanguage;
                if(tmpStoredLanguage == 'en')
                    this.language = this.EN;
                else if(tmpStoredLanguage == 'gr')
                    this.language = this.GR;
                
                this.md5EncodedPassword = sessionStorage.getItem('storedEncryptedPassword')!; //await UtilsManager.getInstance().generateMD5Hash_forWeb(this.password);

                var tmpRoleIDAndDomain : RoleIDAndDomain = {
                    role: -1,
                    ID: -1,
                    verified: false,
                    domain: ""
                };
                const tmpStoredDomain = sessionStorage.getItem('storedDomain');
                if(tmpStoredDomain == this.STUDIO)
                    tmpRoleIDAndDomain = await Studio_RestManager.getInstance().login("") as RoleIDAndDomain;
                else if(tmpStoredDomain == this.TENNIS)
                    tmpRoleIDAndDomain = await Tennis_RestManager.getInstance().login("") as RoleIDAndDomain;

                console.log("login response -> tmpRoleIDAndDomain: ", JSON.stringify(tmpRoleIDAndDomain,null, 2))
                this.role = tmpRoleIDAndDomain.role;
                this.ID = tmpRoleIDAndDomain.ID;
                this.verified = tmpRoleIDAndDomain.verified;
                this.domain = tmpRoleIDAndDomain.domain;

                //PLAYER_ROLE
                if(tmpRoleIDAndDomain.ID!==-1 && tmpRoleIDAndDomain.role===Generic_ConstManager.getInstance().PLAYER_ROLE)
                {
                    console.log("*** PLAYER MODE (role: 1) ***");
                    GUIManager.getInstance().mode = GUIManager.getInstance().PLAYER_LOGGED_IN_MODE;
                    if(tmpRoleIDAndDomain.domain == this.STUDIO) {
                        const curMusician : Studio_Player = await Studio_RestManager.getInstance().getPlayerByEmail(this.username) as Studio_Player;
                        this.loggedInMusician = curMusician;
                    }
                    else if(tmpRoleIDAndDomain.domain == this.TENNIS) {
                        const curTennisPlayer : Tennis_Player = await Tennis_RestManager.getInstance().getPlayerByEmail(this.username) as Tennis_Player;
                        this.loggedInTennisPlayer = curTennisPlayer;
                    }
                }
                //OWNER_ROLE
                else if(tmpRoleIDAndDomain.ID!==-1 && tmpRoleIDAndDomain.role===Generic_ConstManager.getInstance().OWNER_ROLE)
                {
                    console.log("*** OWNER MODE (role: 2) ***");
                    GUIManager.getInstance().mode = GUIManager.getInstance().OWNER_LOGGED_IN_MODE;
                    
                    var curOwner : Owner = {
                        id: -1, name: "", surname: "", telephone: "", email: "", password: "", company_name: "", postal_address: "", city: "", country: "",
                        longitude: -1, latitude: -1, website: "", last_login: "", language: "", email_notifications: false, registration_date: "",
                        verified: false, active: false, info_source: ""
                    };
                    if(tmpRoleIDAndDomain.domain == this.STUDIO) {
                        curOwner = await Studio_RestManager.getInstance().getOwner(this.username) as Owner;
                        //get studio owner extra info
                        var tmpStudio_OwnerExtraInfo : Studio_OwnerExtraInfo = await Studio_RestManager.getInstance().getOwnerExtraInfo(curOwner.id);
                        if(tmpStudio_OwnerExtraInfo!=null && tmpStudio_OwnerExtraInfo!=undefined) {
                            this.loggedInStudioOwner_allowsRehearsals = tmpStudio_OwnerExtraInfo.allows_rehearsal;
                            this.loggedInStudioOwner_allowsRecordings = tmpStudio_OwnerExtraInfo.allows_recording;
                        }
                        //-get studio owner extra info
                    }
                    else if(tmpRoleIDAndDomain.domain == this.TENNIS) {
                        curOwner = await Tennis_RestManager.getInstance().getOwner(this.username) as Owner;
                        //get tennis owner extra info
                        var tmpTennis_OwnerExtraInfo : Tennis_OwnerExtraInfo = await Tennis_RestManager.getInstance().getOwnerExtraInfo(curOwner.id);
                        if(tmpTennis_OwnerExtraInfo!=null && tmpTennis_OwnerExtraInfo!=undefined) {
                            this.loggedInTennisOwner_allows_reservations_to_members = tmpTennis_OwnerExtraInfo.allows_reservations_to_members;
                            this.loggedInTennisOwner_allows_reservations_to_non_members = tmpTennis_OwnerExtraInfo.allows_reservations_to_non_members;
                        }
                        //-get tennis owner extra info
                    }
                    this.loggedInOwner = curOwner;
                    if(tmpRoleIDAndDomain.verified === true) {
                        //check if owner has room(s)
                        //if not, this means that he has just registered
                        if(tmpRoleIDAndDomain.domain == this.STUDIO) {
                            GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio = await Studio_RestManager.getInstance().getAllRoomsOfOwner(this.ID) as Array<Studio_Room>;
                        } 
                        else if(tmpRoleIDAndDomain.domain == this.TENNIS) {
                            //get membership packages
                            const tmpMemberships : Array<Tennis_OwnerMembership> = await Tennis_RestManager.getInstance().getAllClubMembershipsOfOwner(this.ID);
                            if(tmpMemberships!=null && tmpMemberships!=undefined)
                            this.loggedInTennisOwner_memberships = tmpMemberships;
                            //-get membership packages
                            GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis = await Tennis_RestManager.getInstance().getAllCourtsOfOwner(this.ID) as Array<Tennis_Court>;
                        }                       
                    }

                }
                console.log("handleWebPageReload...ESWSE THN KATASTASH!");
                return true;
            }
        }
        console.log("handleWebPageReload...de xreiasthke na kanei kati!");
        return false;
    }
}

export default LoginManager;