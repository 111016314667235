import * as React from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, View } from 'react-native';
import OwnerControlPanelFooter from './OwnerControlPanelFooter'
import AdBanner from '../generic/AdBanner';
import styles from '../../css/myCss';
import { useState } from 'react';
import LoginManager from '../../managers/LoginManager';
import LeftColumnWeb from '../generic/LeftColumnWeb';
import RightColumnWeb from '../generic/RightColumnWeb';


const OwnerControlPanelView = ({route}) => {
    const [selectedDomainValue, setSelectedDomainValue] = useState(route.params!=null ? route.params.selectedDomainValue : LoginManager.getInstance().domain);

    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.container}>
            <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
              <LeftColumnWeb/>
              <OwnerControlPanelFooter
                  selectedDomainValue={selectedDomainValue}
                  setSelectedDomainValue={setSelectedDomainValue}
              />
              <RightColumnWeb/>
            </View>
            <AdBanner/>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
      );
};

export default OwnerControlPanelView;