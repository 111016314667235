import React, { memo } from 'react';
import { View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { hoursList } from '../../../data/constants';
import LoginManager from '../../../managers/LoginManager';
import i18n from '../../../utils/i18n';
import { TextInput } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OwnerDropdownWednesdayFrom = ({  
                                myData_Studio,
                                setMyData_Studio,
                                selectedRoom_Studio,
                                proposeWednesdayStartHour_Studio,
                                
                                myData_Tennis,
                                setMyData_Tennis,
                                selectedCourt_Tennis,
                                proposeWednesdayStartHour_Tennis,
                                
                                setWednesdayStartHour
                            }) => {
  return (
    <View>
      {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Studio.showWednesdayStartHour}
        showDropDown={() => setMyData_Studio({...myData_Studio, showWednesdayStartHour: true})}
        onDismiss={() => setMyData_Studio({...myData_Studio, showWednesdayStartHour: false})}
        value={myData_Studio.wednesdayOpen=="true" && selectedRoom_Studio.wednesday_working_hours_start=="closed" ? proposeWednesdayStartHour_Studio : selectedRoom_Studio.wednesday_working_hours_start}
        setValue={setWednesdayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Studio.showWednesdayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
      {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DropDown
        label={i18n.t('from')}
        mode={'outlined'}
        visible={myData_Tennis.showWednesdayStartHour}
        showDropDown={() => setMyData_Tennis({...myData_Tennis, showWednesdayStartHour: true})}
        onDismiss={() => setMyData_Tennis({...myData_Tennis, showWednesdayStartHour: false})}
        value={myData_Tennis.wednesdayOpen=="true" && selectedCourt_Tennis.wednesday_working_hours_start=="closed" ? proposeWednesdayStartHour_Tennis : selectedCourt_Tennis.wednesday_working_hours_start}
        setValue={setWednesdayStartHour}
        list={hoursList}
        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={myData_Tennis.showWednesdayStartHour==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
      />}
    </View>
  );
};

/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerDropdownWednesdayFrom/*, areEqual*/);