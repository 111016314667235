import React, { useState, useEffect, Fragment, useRef } from 'react';
import { TextInput, Button, IconButton, Colors, Appbar, Subheading } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { useNavigation } from '@react-navigation/native';
import styles from '../../../css/myCss';
import LoginManager from '../../../managers/LoginManager';
import UtilsManager from '../../../managers/UtilsManager';
import Tennis_RestManager from '../../../managers/Tennis_RestManager';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';
import { MaterialCommunityIcons } from '@expo/vector-icons';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const OwnerAddTennisClubMembershipsView = () => {
  const navigation = useNavigation();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerAddTennisClubMembershipsView: I'm ready!");    
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);

  const [currencyVisible, setCurrencyVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("EUR - Euro");
  
  const [lastPackageID, setLastPackageID] = useState(0);
  useEffect(() => {
    //add a new item
    if(lastPackageID != 0) {
      setMemberships([...memberships, {
        id: lastPackageID,
        owner_id: LoginManager.getInstance().ID,
        title: "",
        description: "",
        duration: "",
        cost: ""
      }]);
    }
  }, [lastPackageID]);

  const [memberships, setMemberships] = useState<Array<Tennis_OwnerMembership>>([{
    id: lastPackageID,
    owner_id: LoginManager.getInstance().ID,
    title: "",
    description: "",
    duration: "",
    cost: ""
  }]);
  useEffect(() => {
    console.log("useEffect - memberships - START");
    console.log("memberships: " + JSON.stringify(memberships,null, 2));
    console.log("useEffect - memberships - END");
  }, [JSON.stringify(memberships)]);

  async function saveMemberships() {
    var allFieldsCompleted = true;
    for (let i = 0; i < memberships.length; i++) {
      const tmpMembership : Tennis_OwnerMembership = memberships[i];
      if(tmpMembership.title == ""
          || tmpMembership.description == ""
          || tmpMembership.duration == ""
          || tmpMembership.cost == "")
          allFieldsCompleted = false;
    }
    if(allFieldsCompleted == false) {
      if(MyAlertReactNative != null)
        alert(i18n.t('pleaseCompleteAllFieldsForAllMemberships'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('pleaseCompleteAllFieldsForAllMemberships'));
        setAlertForWebVisible(true);
      }
    }
    else if(memberships.length == 0) {
      if(MyAlertReactNative != null)
        alert(i18n.t('atLeastOneMembershipNeeded'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('atLeastOneMembershipNeeded'));
        setAlertForWebVisible(true);
      }
    }
    else {
      for (let i = 0; i < memberships.length; i++) {
        await Tennis_RestManager.getInstance().addOwnerClubMembership(memberships[i]);
      }
      navigation.reset({
        index: 1,
        routes: [{ name: 'OwnerAddFirstRoom' }]
      });
    }
  }

  // Create a ref for the TextInput components
  const membershipTitleInputRef = useRef(null);
  const membershipDescriptionInputRef = useRef(null);
  const membershipDurationInputRef = useRef(null);
  const membershipCostInputRef = useRef(null);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={100}
      style={styles.container}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View style={styles.inner}>
          <Appbar.Header>
            <Appbar.Content title={i18n.t('addMembershipPlans')} />
          </Appbar.Header>
          <ScrollView>
            <Spinner
              visible={isLoading}
              textContent={i18n.t('loading')}
              textStyle={styles.spinnerTextStyle}
            />

            <View style={{flex:1}}>
              <DropDown
                label={i18n.t('currency')}
                mode={'flat'}
                visible={currencyVisible}
                showDropDown={() => setCurrencyVisible(true)}
                onDismiss={() => setCurrencyVisible(false)}
                value={selectedCurrency}
                setValue={tmpCurrency => setSelectedCurrency(tmpCurrency)}
                list={UtilsManager.getInstance().currencyList}
                inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={currencyVisible==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            
            <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.blueGrey100}}>
              <View style={{ flex: 5 }}>
                <Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('membershipOptions')}</Subheading>
              </View>
              <View style={{flex: 1, alignItems: 'flex-end', paddingTop: 5 }}>
                <IconButton icon={({ color, size }) => (<MaterialCommunityIcons name="plus-circle-outline" color={color} size={size} />)} size={30} color={Colors.green500} onPress={() => { 
                  setLastPackageID(lastPackageID - 1000);
                }} />
              </View>
            </View>

            <Fragment>
              {memberships.map(item => {
                  return (
                    <View style={{ flexDirection: 'row', borderRadius: 5, borderColor: 'black', borderWidth: 0.5, margin: 5 }} key={item.id}>
                      <View style={{ flex: 6 }}>
                        <View style={{ borderRadius: 5, borderColor: 'black', borderWidth: 0.5, margin: 5 }}>
                          <TouchableOpacity onPress={() => membershipTitleInputRef.current.focus()}>
                            <TextInput
                              ref={membershipTitleInputRef}
                              label={i18n.t('membershipTitle')}
                              value={memberships.find(membership => membership.id === item.id)?.title}
                              placeholder="e.g. Standard Annual Membership"
                              style={{ flex: 1, backgroundColor: 'lightgray' }}
                              onChangeText={tmpTitle => { 
                                setMemberships(memberships.map(tmpMembership => {
                                    if (tmpMembership.id === item.id) {
                                      return {
                                        ...tmpMembership,
                                        title: tmpTitle
                                      };
                                    }
                                    return tmpMembership;
                                  }));
                                } 
                              }
                            />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => membershipDescriptionInputRef.current.focus()}>
                            <TextInput
                              ref={membershipDescriptionInputRef}
                              label={i18n.t('membershipDescription')}
                              value={memberships.find(membership => membership.id === item.id)?.description}
                              placeholder="e.g. Unlimited reservations for 12 months"
                              style={{ flex: 1 }}
                              multiline
                              numberOfLines={3}
                              onChangeText={tmpDescription => { 
                                setMemberships(memberships.map(tmpMembership => {
                                    if (tmpMembership.id === item.id) {
                                      return {
                                        ...tmpMembership,
                                        description: tmpDescription
                                      };
                                    }
                                    return tmpMembership;
                                  }));
                                } 
                              }
                            />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => membershipDurationInputRef.current.focus()}>
                            <TextInput
                              ref={membershipDurationInputRef}
                              label={i18n.t('membershipDuration')}
                              value={memberships.find(membership => membership.id === item.id)?.duration}
                              placeholder="e.g. 12 months"
                              style={{ flex: 1 }}
                              onChangeText={tmpDuration => { 
                                setMemberships(memberships.map(tmpMembership => {
                                    if (tmpMembership.id === item.id) {
                                      return {
                                        ...tmpMembership,
                                        duration: tmpDuration
                                      };
                                    }
                                    return tmpMembership;
                                  }));
                                } 
                              }
                            />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => membershipCostInputRef.current.focus()}>
                            <TextInput
                              ref={membershipCostInputRef}
                              label={i18n.t('membershipCost')}
                              value={memberships.find(membership => membership.id === item.id)?.cost}
                              placeholder="e.g. 150"
                              style={{ flex: 1 }}
                              onChangeText={tmpCost => { 
                                setMemberships(memberships.map(tmpMembership => {
                                    if (tmpMembership.id === item.id) {
                                      return {
                                        ...tmpMembership,
                                        cost: tmpCost
                                      };
                                    }
                                    return tmpMembership;
                                  }));
                                } 
                              }
                              right={<TextInput.Affix text={selectedCurrency}/>}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <TouchableWithoutFeedback>
                        <View style={{flex: 1, alignItems: 'flex-end', paddingTop: 5 }}>
                          <IconButton icon={({ color, size }) => (<MaterialCommunityIcons name="minus-circle-outline" color={color} size={size} />)} size={30} color={Colors.red500} onPress={() => { 
                            setMemberships(memberships.filter(membership => membership.id !== item.id));
                          }} />
                        </View>
                      </TouchableWithoutFeedback>
                    </View>
              )})}
            </Fragment>
            <Button icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" style={{ flex:1, alignItems:'center', justifyContent: "center" }} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => saveMemberships()}>{i18n.t('save')}</Button>
          </ScrollView>
          {MyAlertForWeb!=null && 
            <MyAlertForWeb
              show={alertForWebVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForWebText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForWebVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForWebVisible(false);
              }}  
            />
          }
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

export default OwnerAddTennisClubMembershipsView;