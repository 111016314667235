import React, { useState, useEffect, useCallback } from 'react';
import { TextInput, Snackbar, Modal, DataTable, IconButton, Colors, Text, Title, FAB, Portal } from 'react-native-paper';
import { Dimensions, View, ScrollView, Alert, RefreshControl, TouchableOpacity, Platform } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import styles from '../../css/myCss';
import UtilsManager from '../../managers/UtilsManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';
import moment from 'moment';
import DropDown from 'react-native-paper-dropdown';
import OwnerAddTennisClubMemberModal from './tennis/OwnerAddTennisClubMemberModal';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//DatePicker
  //react native
  import DateTimePickerModal from "react-native-modal-datetime-picker";
  //web  
  import { SafeAreaProvider } from "react-native-safe-area-context";
  import { DatePickerModal } from 'react-native-paper-dates';  
import { MaterialCommunityIcons } from '@expo/vector-icons';

  let MyDateTimePickerModalReactNative;
  let MyDateTimePickerModalForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyDateTimePickerModalReactNative = null;
    MyDateTimePickerModalForWeb = DatePickerModal;
  } else {                      //REACT NATIVE
    MyDateTimePickerModalReactNative = DateTimePickerModal;
    MyDateTimePickerModalForWeb = null;
  }
//-DatePicker

const OwnerManagePlayersView = () => {
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerManagePlayersView: I'm ready!");
    handleRefresh();
  }, [alreadyRunOnceOnViewLoad]);

  const [ownerExtraInfo_Tennis, setOwnerExtraInfo_Tennis] = useState<Tennis_OwnerExtraInfo>({
    id: -1,
    owner_id: -1,
    allows_reservations_to_members: false,
    allows_reservations_to_non_members: false
  });
  useEffect(() => {
    console.log("useEffect - ownerExtraInfo_Tennis - START - ownerExtraInfo_Tennis", ownerExtraInfo_Tennis);
    if(ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false)
      setTennisPrivateClubMode(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
    else if(ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true)
      setTennisPrivateClubMode(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS);
    else if(ownerExtraInfo_Tennis.allows_reservations_to_members==false && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true)
      setTennisPrivateClubMode(Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE);    
    console.log("useEffect - ownerExtraInfo_Tennis - STOP");
  }, [JSON.stringify(ownerExtraInfo_Tennis)]);

  const [tennisPrivateClubMode, setTennisPrivateClubMode] = useState("");
  useEffect(() => {
    if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY) {
      console.log(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
    }
    else if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS) {
      console.log(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS);
    }
    else if(tennisPrivateClubMode === Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE) {
      console.log(Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE);
    }
  }, [tennisPrivateClubMode]);

  const [clubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis, setClubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis] = useState<Array<Tennis_ClubRequestWithGenericPlayerInfoAndMembership>>([]);
  const [clubMembersWithGenericPlayerInfoAndMembership_Tennis, setClubMembersWithGenericPlayerInfoAndMembership_Tennis] = useState<Array<Tennis_ClubMemberWithGenericPlayerInfoAndMembership>>([]);
  useEffect(() => {
    var tmpAllMembersSinceSelectedDate: Array<string> = [];
    var tmpAllMembersValidUntilSelectedDate: Array<string> = [];
    for(var i=0; i<clubMembersWithGenericPlayerInfoAndMembership_Tennis.length; i++) {
      //split the string at the space character and get the first part, or use the whole string if there is no space character
      tmpAllMembersSinceSelectedDate.push(clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember.member_since_date.split(" ")[0] || clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember.member_since_date);
      tmpAllMembersValidUntilSelectedDate.push(clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember.membership_valid_to_date.split(" ")[0] || clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember.membership_valid_to_date);
    }
    setMemberSinceSelectedDate(tmpAllMembersSinceSelectedDate);
    setMembershipValidUntilSelectedDate(tmpAllMembersValidUntilSelectedDate);
  }, [clubMembersWithGenericPlayerInfoAndMembership_Tennis]);

  const [myPlayersWithBlockingStatus, setMyPlayersWithBlockingStatus] = useState<Array<PlayerWithBlockingStatus>>([]);
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    setIsLoading(true);
    var tmpAllPlayersWithBlockingStatus : Array<PlayerWithBlockingStatus> = [];
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
      tmpAllPlayersWithBlockingStatus = await Studio_RestManager.getInstance().getAllPlayersOfAnOwner(LoginManager.getInstance().ID);
      console.log("studio_getAllPlayersOfAnOwner response: " + JSON.stringify(tmpAllPlayersWithBlockingStatus, null, 2));
    }
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
      tmpAllPlayersWithBlockingStatus = await Tennis_RestManager.getInstance().getAllPlayersOfAnOwner(LoginManager.getInstance().ID);
      console.log("tennis_getAllPlayersOfAnOwner response: " + JSON.stringify(tmpAllPlayersWithBlockingStatus, null, 2));

      const tmpOwnerExtraInfo_Tennis : Tennis_OwnerExtraInfo = await Tennis_RestManager.getInstance().getOwnerExtraInfo(LoginManager.getInstance().ID);
      if(tmpOwnerExtraInfo_Tennis!=null && tmpOwnerExtraInfo_Tennis!=undefined) 
        setOwnerExtraInfo_Tennis(tmpOwnerExtraInfo_Tennis);
      
      if(tmpOwnerExtraInfo_Tennis.allows_reservations_to_members == true) {
        //get pending club member requests - Tennis
        const tmpClubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis : Array<Tennis_ClubRequestWithGenericPlayerInfoAndMembership> = await Tennis_RestManager.getInstance().getAllClubRequestsOfOwner(LoginManager.getInstance().ID);
        console.log("tmpClubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis: ", tmpClubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis);
        setClubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis(tmpClubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis);
        //-get pending club member requests - Tennis

        //get membership packages
        const tmpMemberships : Array<Tennis_OwnerMembership> = await Tennis_RestManager.getInstance().getAllClubMembershipsOfOwner(LoginManager.getInstance().ID);
        if(tmpMemberships!=null && tmpMemberships!=undefined) {
          var tennisOwnerMembershipsLabelValueArray = tmpMemberships.map((membership) => {
            return { label: membership.title, value: membership.id.toString() };
          });
          const firstItem = { label: i18n.t('membershipPlanNotSelected'), value: '-1' };
          tennisOwnerMembershipsLabelValueArray.unshift(firstItem); // Add the first item to the beginning of the array
          setMembershipsDropdownList(tennisOwnerMembershipsLabelValueArray);
        }
        //-get membership packages

        //get club members - Tennis
        const tmpClubMembersWithGenericPlayerInfoAndMembership_Tennis : Array<Tennis_ClubMemberWithGenericPlayerInfoAndMembership> = await Tennis_RestManager.getInstance().getAllClubMembersOfOwner(LoginManager.getInstance().ID);
        console.log("tmpClubMembersWithGenericPlayerInfoAndMembership_Tennis: ", tmpClubMembersWithGenericPlayerInfoAndMembership_Tennis);
        setClubMembersWithGenericPlayerInfoAndMembership_Tennis(tmpClubMembersWithGenericPlayerInfoAndMembership_Tennis);

        var tmpSelectedMemberships : Array<string> = [];
        for(var i=0; i<tmpClubMembersWithGenericPlayerInfoAndMembership_Tennis.length; i++) {
          tmpSelectedMemberships.push(tmpClubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember.membership_id.toString());
        }
        setSelectedMemberships(tmpSelectedMemberships);
        //-get club members - Tennis
      }
    }
    setMyPlayersWithBlockingStatus(tmpAllPlayersWithBlockingStatus);
    setIsLoading(false);
    setRefreshing(false);
  }, [myPlayersWithBlockingStatus]);

  const [isLoading, setIsLoading] = useState(false);

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const onDismissSnackBar = () => setSnackBarVisible(false);
  const [snackBarText, setSnackBarText] = useState('');  

  //for web alert
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebTitle, setAlertForWebTitle] = useState('');
  const [alertForWebMsg, setAlertForWebMsg] = useState('');

  const [showAlertForPlayerBlocking_forWebAlert, setShowAlertForPlayerBlocking_forWebAlert] = useState(""); //player email
  useEffect(() => {
    if(showAlertForPlayerBlocking_forWebAlert!="" && Platform.OS==='web') {
      setAlertForWebTitle(LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('doYouWantToBlockMusician')
                        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('doYouWantToBlockPlayer')
                        : "ERROR!");
      setAlertForWebMsg(LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('blockMusicianMsg')
                        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('blockPlayerMsg')
                        : "ERROR!");
      setAlertForWebVisible(true);
    }
  }, [showAlertForPlayerBlocking_forWebAlert]);

  const [showAlertForPlayerUnblocking_forWebAlert, setShowAlertForPlayerUnblocking_forWebAlert] = useState(""); //player email
  useEffect(() => {
    if(showAlertForPlayerUnblocking_forWebAlert!="" && Platform.OS==='web') {
      setAlertForWebTitle(LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('doYouWantToUnblockMusician')
                        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('doYouWantToUnblockPlayer')
                        : "ERROR!");
      setAlertForWebMsg(LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('unblockMusicianMsg')
                        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('unblockPlayerMsg')
                        : "ERROR!");
      setAlertForWebVisible(true);
    }
  }, [showAlertForPlayerUnblocking_forWebAlert]);

  const [showAlertForClubRequestAcceptance_forWebAlert, setShowAlertForClubRequestAcceptance_forWebAlert] = useState<Tennis_ClubRequest>({
                                                                                                                                            id: -1,
                                                                                                                                            from_user_email: "",
                                                                                                                                            request_date: "",
                                                                                                                                            to_owner_id: -1,
                                                                                                                                            status: "",
                                                                                                                                            response_date: "",
                                                                                                                                            membership_id: -1,
                                                                                                                                            says_he_is_already_member: false
                                                                                                                                        });
  useEffect(() => {
    if(showAlertForClubRequestAcceptance_forWebAlert.from_user_email!="" && Platform.OS==='web') {
      setAlertForWebTitle(i18n.t('confirmation'));
      setAlertForWebMsg(i18n.t('confirmationMsgForClubRequestAcceptance'));
      setAlertForWebVisible(true);
    }
  }, [showAlertForClubRequestAcceptance_forWebAlert]);

  const [showAlertForClubRequestRejection_forWebAlert, setShowAlertForClubRequestRejection_forWebAlert] = useState<Tennis_ClubRequest>({
                                                                                                                                          id: -1,
                                                                                                                                          from_user_email: "",
                                                                                                                                          request_date: "",
                                                                                                                                          to_owner_id: -1,
                                                                                                                                          status: "",
                                                                                                                                          response_date: "",
                                                                                                                                          membership_id: -1,
                                                                                                                                          says_he_is_already_member: false
                                                                                                                                      });
  useEffect(() => {
    if(showAlertForClubRequestRejection_forWebAlert.from_user_email!="" && Platform.OS==='web') {
      setAlertForWebTitle(i18n.t('confirmation'));
      setAlertForWebMsg(i18n.t('confirmationMsgForClubRequestRejection'));
      setAlertForWebVisible(true);
    }
  }, [showAlertForClubRequestRejection_forWebAlert]);

  const [showAlertForClubMemberDeletion_forWebAlert, setShowAlertForClubMemberDeletion_forWebAlert] = useState<Tennis_ClubMember>({
                                                                                                                                    id: -1,
                                                                                                                                    owner_id: -1,
                                                                                                                                    user_email: "",
                                                                                                                                    member_since_date: "",
                                                                                                                                    membership_id: -1,
                                                                                                                                    membership_valid_from_date: "",
                                                                                                                                    membership_valid_to_date: "",
                                                                                                                                    user_name_provided_by_owner: "",
                                                                                                                                    user_surname_provided_by_owner: "",
                                                                                                                                    user_telephone_provided_by_owner: ""
                                                                                                                                  });
  useEffect(() => {
    if(showAlertForClubMemberDeletion_forWebAlert.user_email!="" && Platform.OS==='web') {
      setAlertForWebTitle(i18n.t('areYouSure'));
      setAlertForWebMsg(i18n.t('youAreGoindToDeleteClubMember'));
      setAlertForWebVisible(true);
    }
  }, [showAlertForClubMemberDeletion_forWebAlert]);
  //-for web alert
  
  const showAlertForPlayerBlocking = (tmpPlayerEmail: string) => {
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(
        LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('doYouWantToBlockMusician')
        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('doYouWantToBlockPlayer')
        : "ERROR!",
        LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('blockMusicianMsg')
        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('blockPlayerMsg')
        : "ERROR!",
        [
          {
            text: i18n.t('cancel'),
            style: 'cancel'
          },
          {
            text: 'OK',
            onPress: () => {
              var tmpBlockedPlayer: BlockedPlayer = {
                id: -1,
                owner_id: LoginManager.getInstance().ID,
                blocked_user_email: tmpPlayerEmail,
                reason: "",
                blocking_date: ""
              }
              blockPlayer(tmpBlockedPlayer);
            }
          }
        ],
        { cancelable: false }
      );
    }
    else if(MyAlertForWeb != null) {
      setShowAlertForPlayerBlocking_forWebAlert(tmpPlayerEmail);
    }
  };

  const showAlertForClubRequestAcceptance = (tmpRequest: Tennis_ClubRequest) => {
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(i18n.t('confirmation'),
        i18n.t('confirmationMsgForClubRequestAcceptance'),
        [
          {
            text: i18n.t('cancel'),
            style: 'cancel'
          },
          {
            text: 'OK',
            onPress: () => {
              (async () => {
                const webServiceResponseStr = await Tennis_RestManager.getInstance().acceptClubRequest(tmpRequest);
                if(webServiceResponseStr!=null && webServiceResponseStr!=undefined) {
                  handleRefresh();
                  alert(i18n.t('clubRequestAccepted'));
                }
              })();  
            }
          }
        ],
        { cancelable: false }
      );
    }
    else if(MyAlertForWeb != null) {
      setShowAlertForClubRequestAcceptance_forWebAlert(tmpRequest);
    }
  };

  const showAlertForClubRequestRejection = (tmpRequest: Tennis_ClubRequest) => {
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(i18n.t('confirmation'),
        i18n.t('confirmationMsgForClubRequestRejection'),
        [
          {
            text: i18n.t('cancel'),
            style: 'cancel'
          },
          {
            text: 'OK',
            onPress: () => {
              (async () => {
                const webServiceResponseStr = await Tennis_RestManager.getInstance().rejectClubRequest(tmpRequest);
                if(webServiceResponseStr!=null && webServiceResponseStr!=undefined) {
                  handleRefresh();
                  alert(i18n.t('clubRequestRejected'));
                }
              })();
            }
          }
        ],
        { cancelable: false }
      );
    }
    else if(MyAlertForWeb != null) {
      setShowAlertForClubRequestRejection_forWebAlert(tmpRequest);
    }
  };

  const showAlertForPlayerUnblocking = (tmpPlayerEmail: string) => {
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(
        LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('doYouWantToUnblockMusician')
        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('doYouWantToUnblockPlayer')
        : "ERROR!",
        LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('unblockMusicianMsg')
        : LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('unblockPlayerMsg')
        : "ERROR!",
        [
          {
            text: i18n.t('cancel'),
            style: 'cancel'
          },
          {
            text: 'OK',
            onPress: () => {
              var tmpBlockedPlayer: BlockedPlayer = {
                id: -1,
                owner_id: LoginManager.getInstance().ID,
                blocked_user_email: tmpPlayerEmail,
                reason: "",
                blocking_date: ""
              }
              unblockPlayer(tmpBlockedPlayer);
            }
          }
        ],
        { cancelable: false }
      );
    }
    else if(MyAlertForWeb != null) {
      setShowAlertForPlayerUnblocking_forWebAlert(tmpPlayerEmail);
    }
  };

  const blockPlayer = async (tmpBlockedPlayer: BlockedPlayer) => {
    setIsLoading(true);
    var tmpWebServiceResponseStr : string | void = "";
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      tmpWebServiceResponseStr = await Studio_RestManager.getInstance().blockPlayer(tmpBlockedPlayer);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      tmpWebServiceResponseStr = await Tennis_RestManager.getInstance().blockPlayer(tmpBlockedPlayer);
    console.log("tmpWebServiceResponseStr: " + tmpWebServiceResponseStr);
    if(tmpWebServiceResponseStr == Studio_ConstManager.getInstance().PLAYER_BLOCKED)
      setSnackBarText(i18n.t('musicianBlockedSuccessfully'));
    else if(tmpWebServiceResponseStr == Tennis_ConstManager.getInstance().PLAYER_BLOCKED)
      setSnackBarText(i18n.t('playerBlockedSuccessfully'));
    else
      setSnackBarText(tmpWebServiceResponseStr);
    setSnackBarVisible(true);
    handleRefresh();
  }

  const unblockPlayer = async (tmpBlockedPlayer: BlockedPlayer) => {
    setIsLoading(true);
    var tmpWebServiceResponseStr : string | void = "";
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      tmpWebServiceResponseStr = await Studio_RestManager.getInstance().unblockPlayer(tmpBlockedPlayer);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      tmpWebServiceResponseStr = await Tennis_RestManager.getInstance().unblockPlayer(tmpBlockedPlayer);
    console.log("tmpWebServiceResponseStr: " + tmpWebServiceResponseStr);
    if(tmpWebServiceResponseStr == Studio_ConstManager.getInstance().PLAYER_UNBLOCKED)
      setSnackBarText(i18n.t('musicianUnblockedSuccessfully'));
    else if(tmpWebServiceResponseStr == Tennis_ConstManager.getInstance().PLAYER_UNBLOCKED)
      setSnackBarText(i18n.t('playerUnblockedSuccessfully'));
    else
      setSnackBarText(tmpWebServiceResponseStr);
    setSnackBarVisible(true);
    handleRefresh();
  }

//member since - start
  const [memberSinceDatePickerVisible, setMemberSinceDatePickerVisible] = useState(false);
  const [memberSinceSelectedDate, setMemberSinceSelectedDate] = useState<Array<string>>([]);
  
  // Function to handle date selection
  const memberSinceHandleConfirm = useCallback(async (date, i) => {
    // Format the selected date as DD/MM/YYYY
    const formattedDate = moment(date).format("DD/MM/YYYY");
    
    setIsLoading(true);
    var tmpClubMember = clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember;
    console.log("memberSinceHandleConfirm - i:", i, ", tmpClubMember: ", tmpClubMember);
    tmpClubMember.member_since_date = formattedDate;
    await Tennis_RestManager.getInstance().editClubMember(tmpClubMember);
    setIsLoading(false);

    // Set the selected date in the state variable
    setMemberSinceSelectedDate(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(i, 1, formattedDate); // replace 'new value' with the value you want to set
      return newArray;
    });
    setClubMembersWithGenericPlayerInfoAndMembership_Tennis(prevState => [
      ...prevState.slice(0, i), // elements before the updated one
      {
        ...prevState[i], // the object being updated
        clubMember: {
          ...prevState[i].clubMember,
          member_since_date: formattedDate
        }
      },
      ...prevState.slice(i + 1) // elements after the updated one
    ]);
    // Hide the date picker
    setMemberSinceDatePickerVisible(false);
  }, [clubMembersWithGenericPlayerInfoAndMembership_Tennis]);
  
  // Function to show the date picker
  const memberSinceShowDatePicker = () => {
    setMemberSinceDatePickerVisible(true);
  };
  
  // Function to hide the date picker
  const memberSinceHideDatePicker = () => {
    setMemberSinceDatePickerVisible(false);
  };
//member since - end

//memberhip valid until - start
const [membershipValidUntilDatePickerVisible, setMembershipValidUntilDatePickerVisible] = useState(false);
const [membershipValidUntilSelectedDate, setMembershipValidUntilSelectedDate] = useState<Array<string>>([]);

// Function to handle date selection
const membershipValidUntilHandleConfirm = async (date, i) => {
  // Format the selected date as DD/MM/YYYY
  const formattedDate = moment(date).format("DD/MM/YYYY");
  
  setIsLoading(true);
  var tmpClubMember = clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember;
  tmpClubMember.membership_valid_to_date = formattedDate;
  await Tennis_RestManager.getInstance().editClubMember(tmpClubMember);
  setIsLoading(false);

  // Set the selected date in the state variable
  setMembershipValidUntilSelectedDate(prevArray => {
    const newArray = [...prevArray];
    newArray.splice(i, 1, formattedDate); // replace 'new value' with the value you want to set
    return newArray;
  });
  setClubMembersWithGenericPlayerInfoAndMembership_Tennis(prevState => [
    ...prevState.slice(0, i), // elements before the updated one
    {
      ...prevState[i], // the object being updated
      clubMember: {
        ...prevState[i].clubMember,
        membership_valid_to_date: formattedDate
      }
    },
    ...prevState.slice(i + 1) // elements after the updated one
  ]);
  
  // Hide the date picker
  setMembershipValidUntilDatePickerVisible(false);
};

// Function to show the date picker
const membershipValidUntilShowDatePicker = () => {
  setMembershipValidUntilDatePickerVisible(true);
};

// Function to hide the date picker
const membershipValidUntilHideDatePicker = () => {
  setMembershipValidUntilDatePickerVisible(false);
};
//membership valid until - end

const [membershipsDropdownList, setMembershipsDropdownList] = useState([]);
const [showMembershipsDropdownLists, setShowMembershipsDropdownLists] = useState<Array<boolean>>([]);
const [selectedMemberships, setSelectedMemberships] = useState<Array<string>>([]);

const setSelectedMembershipForClubMember = async (tmpMembershipIdStr, i) => {
  setSelectedMemberships(prevState => {
    const newState = [...prevState];
    newState[i] = tmpMembershipIdStr;
    return newState;
  });
  setClubMembersWithGenericPlayerInfoAndMembership_Tennis(prevState => [
    ...prevState.slice(0, i), // elements before the updated one
    {
      ...prevState[i], // the object being updated
      clubMember: {
        ...prevState[i].clubMember,
        membership_id: parseInt(tmpMembershipIdStr)
      }
    },
    ...prevState.slice(i + 1) // elements after the updated one
  ]);

  setIsLoading(true);
  var tmpClubMember = clubMembersWithGenericPlayerInfoAndMembership_Tennis[i].clubMember;
  tmpClubMember.membership_id = parseInt(tmpMembershipIdStr);
  await Tennis_RestManager.getInstance().editClubMember(tmpClubMember);
  setIsLoading(false);
};

const [visibleItemsDropdownList, setVisibleItemsDropdownList] = useState([
  { label: i18n.t('onlyClubRequests'), value: i18n.t('onlyClubRequests') },
  { label: i18n.t('onlyClubMembers'), value: i18n.t('onlyClubMembers') },
  { label: i18n.t('onlyValidClubMembers'), value: i18n.t('onlyValidClubMembers') },
  { label: i18n.t('onlyExpiredMembers'), value: i18n.t('onlyExpiredMembers') },
  { label: i18n.t('onlyGuests'), value: i18n.t('onlyGuests') },
  { label: i18n.t('allTheAbove'), value: i18n.t('allTheAbove') }
]);
const [showVisibleItemsDropdownLists, setShowVisibleItemsDropdownLists] = useState<boolean>(false);
const [selectedVisibleItems, setSelectedVisibleItems] = useState<string>(i18n.t('allTheAbove'));

const [visibleNewClubMemberModal, setVisibleNewClubMemberModal] = useState(false);
const hideNewClubMemberModal = () => { setVisibleNewClubMemberModal(false); }

const [selectedClubMemberIndex, setSelectedClubMemberIndex] = useState(-1);

const deleteClubMember = (tmpTennisClubMember : Tennis_ClubMember) => {
  if(MyAlertReactNative != null) {
    MyAlertReactNative.alert(
      i18n.t('areYouSure'),
      i18n.t('youAreGoindToDeleteClubMember'),
      [
        { 
          text: 'OK', onPress: async () => { 
            setIsLoading(true);
            const webServiceResponseStr = await Tennis_RestManager.getInstance().deleteClubMember(tmpTennisClubMember);
            setIsLoading(false);
            if(webServiceResponseStr == "Club member deleted!") {
              MyAlertReactNative.alert(
                i18n.t('congratulations'),
                i18n.t('clubMemberDeleted'),
                [{ text: 'OK', onPress: () => { handleRefresh(); } }],
                { cancelable: false }
              );
            }
          } 
        },
        { text: i18n.t('cancel') }
      ],
      { cancelable: true }
    );
  }
  else if(MyAlertForWeb != null) {
    setShowAlertForClubMemberDeletion_forWebAlert(tmpTennisClubMember);
  }
}
 
    return (
      <View style={styles.inner}>
        <Spinner
          visible={isLoading}
          textContent={i18n.t('loading')}
          textStyle={styles.spinnerTextStyle}
        />
        {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS 
          && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE
          && <DropDown
          label={i18n.t('show')}
          mode={'outlined'}
          visible={showVisibleItemsDropdownLists}
          showDropDown={() => setShowVisibleItemsDropdownLists(true)}
          onDismiss={() => setShowVisibleItemsDropdownLists(false)}
          value={selectedVisibleItems}
          setValue={tmpValue => setSelectedVisibleItems(tmpValue)}
          list={visibleItemsDropdownList}
          inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showVisibleItemsDropdownLists==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
        />}
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
                  refreshing={refreshing}
                  onRefresh={handleRefresh}
                  progressViewOffset={40}
                  title={i18n.t('pullDownToUpdate')}
                  titleColor="blue"
                  tintColor="green"
                />
          }
        >

          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE && (selectedVisibleItems==i18n.t('onlyClubRequests') || selectedVisibleItems==i18n.t('allTheAbove')) && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('clubMembersRequests')}</Title>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE && (selectedVisibleItems==i18n.t('onlyClubRequests') || selectedVisibleItems==i18n.t('allTheAbove')) && clubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis.map((tmpTennisClubRequestWithGenericPlayerInfoAndMembership: Tennis_ClubRequestWithGenericPlayerInfoAndMembership, i) => {            
            return (
              <View key={tmpTennisClubRequestWithGenericPlayerInfoAndMembership.clubRequest.id}>
                <TouchableOpacity>
                  <DataTable style={{ borderWidth: 2, borderRadius: 5, borderColor: "orange"}}>
                    
                    <DataTable.Header style={{ backgroundColor: "orange"}}>
                      <Text style={{ color: "white", fontWeight: "bold", alignSelf:"center", textAlign: "center", flex: 1 }}>{i+1}/{clubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis.length}</Text>
                    </DataTable.Header>
                  
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('name')}</DataTable.Title>
                      <Text style={{ flex: 2, fontWeight:"bold", alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.genericPlayerInfo.name} {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.genericPlayerInfo.surname}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('telephone')}</DataTable.Title>
                      <Text style={{ flex: 2, alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.genericPlayerInfo.telephone}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>Email</DataTable.Title>
                      <Text style={{ flex: 2, alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.genericPlayerInfo.email}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('pendingSince')}</DataTable.Title>
                      <Text style={{ flex: 2, fontWeight:"bold", alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.clubRequest.request_date}
                      </Text>
                    </DataTable.Row>

                    {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.membership.id!=-1 && <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('preferredMembership')}</DataTable.Title>
                      <Text style={{ flex: 2, fontWeight:"bold", alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.membership.title}
                      </Text>
                    </DataTable.Row>}

                    {tmpTennisClubRequestWithGenericPlayerInfoAndMembership.clubRequest.says_he_is_already_member==true && <DataTable.Row style={styles.tableRow}>
                      <Text style={{ flex: 2, fontStyle: "italic", fontWeight:"bold", alignSelf:"center", textAlign: "center" }}>
                          {i18n.t('saysHeIsAlreadyMember')}
                      </Text>
                    </DataTable.Row>}
                    
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="phone" color={color} size={size} />)}
                              color={Colors.blue900}
                              size={35}
                              onPress={() => UtilsManager.getInstance().makePhoneCall(tmpTennisClubRequestWithGenericPlayerInfoAndMembership.genericPlayerInfo.telephone)} />
                      </DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)}
                              color={Colors.blue900}
                              size={35}
                              onPress={() => UtilsManager.getInstance().sendEmail(tmpTennisClubRequestWithGenericPlayerInfoAndMembership.genericPlayerInfo.email)} />
                      </DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="check-circle" color={color} size={size} />)}
                              color={Colors.greenA700}
                              size={35}
                              onPress={() => { 
                                showAlertForClubRequestAcceptance(tmpTennisClubRequestWithGenericPlayerInfoAndMembership.clubRequest);
                              }} />
                      </DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="delete-circle" color={color} size={size} />)}
                              color={Colors.redA200}
                              size={35}
                              onPress={() => { 
                                showAlertForClubRequestRejection(tmpTennisClubRequestWithGenericPlayerInfoAndMembership.clubRequest);
                              }} />
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>    
                </TouchableOpacity>
              </View>             
            )
          })}
          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE && (selectedVisibleItems==i18n.t('onlyClubRequests') || selectedVisibleItems==i18n.t('allTheAbove')) && clubMemberPendingRequestsWithGenericPlayerInfoAndMembership_Tennis.length==0 &&
          <Text style={{ flex: 1, alignSelf: 'center', fontWeight: 'bold', margin: 10 }}>{i18n.t('noPendingClubRequests')}</Text>}

          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE && (selectedVisibleItems==i18n.t('onlyClubMembers') || selectedVisibleItems==i18n.t('onlyValidClubMembers') || selectedVisibleItems==i18n.t('onlyExpiredMembers') || selectedVisibleItems==i18n.t('allTheAbove')) && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('clubMembers')}</Title>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE && (selectedVisibleItems==i18n.t('onlyClubMembers') || selectedVisibleItems==i18n.t('onlyValidClubMembers') || selectedVisibleItems==i18n.t('onlyExpiredMembers') || selectedVisibleItems==i18n.t('allTheAbove')) && clubMembersWithGenericPlayerInfoAndMembership_Tennis.map((tmpTennisClubMemberWithGenericPlayerInfoAndMembership: Tennis_ClubMemberWithGenericPlayerInfoAndMembership, i) => {            
            return ( 
              <View key={tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember.id}>
                {(selectedVisibleItems==i18n.t('onlyClubMembers') 
                || (selectedVisibleItems==i18n.t('onlyValidClubMembers') && UtilsManager.getInstance().dateHasPassed(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember.membership_valid_to_date)==false)
                || (selectedVisibleItems==i18n.t('onlyExpiredMembers') && UtilsManager.getInstance().dateHasPassed(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember.membership_valid_to_date)==true)
                || selectedVisibleItems==i18n.t('allTheAbove')) 
              &&<TouchableOpacity>
                  <DataTable style={{ borderWidth: 2, borderRadius: 5, borderColor: UtilsManager.getInstance().dateHasPassed(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember.membership_valid_to_date)==false ? "green" : "red"}}>
                    
                    <DataTable.Header style={{ backgroundColor: UtilsManager.getInstance().dateHasPassed(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember.membership_valid_to_date)==false ? "green" : "red"}}>
                      <Text style={{ color: "white", fontWeight: "bold", alignSelf:"center", textAlign: "center", flex: 1 }}>{i+1}/{clubMembersWithGenericPlayerInfoAndMembership_Tennis.length}</Text>
                      <IconButton icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} size={24} color='white' onPress={() => deleteClubMember(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember)}/>
                    </DataTable.Header>
                  
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('name')}</DataTable.Title>
                      <Text style={{ flex: 2, fontWeight:"bold", alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubMemberWithGenericPlayerInfoAndMembership.genericPlayerInfo.name} {tmpTennisClubMemberWithGenericPlayerInfoAndMembership.genericPlayerInfo.surname}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('telephone')}</DataTable.Title>
                      <Text style={{ flex: 2, alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubMemberWithGenericPlayerInfoAndMembership.genericPlayerInfo.telephone}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>Email</DataTable.Title>
                      <Text style={{ flex: 2, alignSelf:"center", textAlign: "center" }}>
                          {tmpTennisClubMemberWithGenericPlayerInfoAndMembership.genericPlayerInfo.email}
                      </Text>
                    </DataTable.Row>

                    <DropDown
                        label={i18n.t('membershipPlan')}
                        mode={'outlined'}
                        visible={showMembershipsDropdownLists[i]}
                        showDropDown={() => setShowMembershipsDropdownLists(prevState => {
                                              const newState = [...prevState];
                                              newState[i] = true;
                                              return newState;
                                            })}
                        onDismiss={() => setShowMembershipsDropdownLists(prevState => {
                                              const newState = [...prevState];
                                              newState[i] = false;
                                              return newState;
                                            })}
                        value={selectedMemberships[i]}
                        setValue={tmpSelectedMembershipIdStr => setSelectedMembershipForClubMember(tmpSelectedMembershipIdStr, i)}
                        list={membershipsDropdownList}
                        inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showMembershipsDropdownLists[i]==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                    />

                    {memberSinceSelectedDate.length > 0
                      && memberSinceSelectedDate[i] != null
                      && memberSinceSelectedDate[i] != undefined && <DataTable.Row style={styles.tableRow}>
                      
                      <TouchableOpacity onPress={() => {setSelectedClubMemberIndex(i); memberSinceShowDatePicker();}} style={{ flex: 1}}>
                        <TextInput
                          label={i18n.t('memberSince')}
                          value={memberSinceSelectedDate[i]}
                          editable={false} // disable the keyboard
                          style={{ backgroundColor: Colors.blueGrey100 }}
                        />
                      </TouchableOpacity>

                      <TouchableOpacity onPress={() => {setSelectedClubMemberIndex(i); membershipValidUntilShowDatePicker();}} style={{ flex: 1 }}>
                        <TextInput
                          label={i18n.t('membershipValidUntil')}
                          value={membershipValidUntilSelectedDate[i]=="UNKNOWN" ? "" : membershipValidUntilSelectedDate[i]}
                          editable={false} // disable the keyboard
                          style={{ backgroundColor: (membershipValidUntilSelectedDate[i]==undefined || membershipValidUntilSelectedDate[i]=="" || membershipValidUntilSelectedDate[i]=="UNKNOWN") ? 'red' : Colors.blueGrey100 }}
                        />
                      </TouchableOpacity>
                     
                    </DataTable.Row>}

                    {UtilsManager.getInstance().dateHasPassed(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.clubMember.membership_valid_to_date)==true &&<DataTable.Row style={[styles.tableRow, {backgroundColor: Colors.blueGrey100}]}>
                      <Text style={{ flex: 1, fontWeight:"bold", alignSelf:"center", textAlign: "center", color: "red", fontStyle: "italic" }}>
                          {i18n.t('membershipExpired')}
                      </Text>
                    </DataTable.Row>}                    

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="phone" color={color} size={size} />)}
                              color={Colors.blue900}
                              size={35}
                              onPress={() => UtilsManager.getInstance().makePhoneCall(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.genericPlayerInfo.telephone)} />
                      </DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)}
                              color={Colors.blue900}
                              size={35}
                              onPress={() => UtilsManager.getInstance().sendEmail(tmpTennisClubMemberWithGenericPlayerInfoAndMembership.genericPlayerInfo.email)} />
                      </DataTable.Cell>
                      
                    </DataTable.Row>
                  </DataTable>    
                </TouchableOpacity>}
              </View>
            )
          })}
          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE && (selectedVisibleItems==i18n.t('onlyClubMembers') || selectedVisibleItems==i18n.t('onlyValidClubMembers') || selectedVisibleItems==i18n.t('onlyExpiredMembers') || selectedVisibleItems==i18n.t('allTheAbove')) && clubMembersWithGenericPlayerInfoAndMembership_Tennis.length==0 &&
          <Text style={{ flex: 1, alignSelf: 'center', fontWeight: 'bold', margin: 10 }}>{i18n.t('noClubMembers')}</Text>}

          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && (selectedVisibleItems==i18n.t('onlyGuests') || selectedVisibleItems==i18n.t('allTheAbove')) && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('nonMembersGuests')}</Title>}
          {((LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && (selectedVisibleItems==i18n.t('onlyGuests') || selectedVisibleItems==i18n.t('allTheAbove'))) || LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO) && myPlayersWithBlockingStatus.map((tmpPlayerWithBlockingStatus: PlayerWithBlockingStatus, i) => {            
            var tmpPlayerIsBlocked = false;
            if(tmpPlayerWithBlockingStatus.blockedPlayer.owner_id == LoginManager.getInstance().ID
              && tmpPlayerWithBlockingStatus.blockedPlayer.blocked_user_email == tmpPlayerWithBlockingStatus.genericPlayerInfo.email)
              tmpPlayerIsBlocked = true;

            return (
              <View key={tmpPlayerWithBlockingStatus.blockedPlayer.id}>
                <TouchableOpacity>
                  <DataTable style={{ borderWidth: 2, borderRadius: 5, borderColor: tmpPlayerIsBlocked==true ? "red": "green"}}>
                    
                    <DataTable.Header style={{ backgroundColor: tmpPlayerIsBlocked==true ? "red": "green"}}>
                      <Text style={{ color: "white", fontWeight: "bold", alignSelf:"center", textAlign: "center", flex: 1 }}>{i+1}/{myPlayersWithBlockingStatus.length} {tmpPlayerIsBlocked==true ? "(" + i18n.t('blocked') + ")" : ""}</Text>
                    </DataTable.Header>
                    
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('name')}</DataTable.Title>
                      <Text style={{ flex: 2, fontWeight:"bold", alignSelf:"center", textAlign: "center" }}>
                          {tmpPlayerWithBlockingStatus.genericPlayerInfo.name} {tmpPlayerWithBlockingStatus.genericPlayerInfo.surname}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>{i18n.t('telephone')}</DataTable.Title>
                      <Text style={{ flex: 2, alignSelf:"center", textAlign: "center" }}>
                          {tmpPlayerWithBlockingStatus.genericPlayerInfo.telephone}
                      </Text>
                    </DataTable.Row>

                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={[styles.tableTitle, {flex: 1}]}>Email</DataTable.Title>
                      <Text style={{ flex: 2, alignSelf:"center", textAlign: "center" }}>
                          {tmpPlayerWithBlockingStatus.genericPlayerInfo.email}
                      </Text>
                    </DataTable.Row>
                    
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="phone" color={color} size={size} />)}
                              color={Colors.blue900}
                              size={35}
                              onPress={() => UtilsManager.getInstance().makePhoneCall(tmpPlayerWithBlockingStatus.genericPlayerInfo.telephone)} />
                      </DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)}
                              color={Colors.blue900}
                              size={35}
                              onPress={() => UtilsManager.getInstance().sendEmail(tmpPlayerWithBlockingStatus.genericPlayerInfo.email)} />
                      </DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                          <IconButton
                              icon={tmpPlayerIsBlocked==false ? "account-cancel" : "account-sync"}
                              color={tmpPlayerIsBlocked==false ? Colors.redA200 : Colors.greenA700}
                              size={35}
                              onPress={() => {
                                if(tmpPlayerIsBlocked==false)
                                  showAlertForPlayerBlocking(tmpPlayerWithBlockingStatus.genericPlayerInfo.email)
                                else
                                  showAlertForPlayerUnblocking(tmpPlayerWithBlockingStatus.genericPlayerInfo.email)
                              }} />
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable> 
                </TouchableOpacity>   
              </View>
            )
          })}
          {(((selectedVisibleItems==i18n.t('onlyGuests') || selectedVisibleItems==i18n.t('allTheAbove')) && LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS) || LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO) && myPlayersWithBlockingStatus.length==0 &&
          <Text style={{ flex: 1, alignSelf: 'center', fontWeight: 'bold', margin: 10 }}>{
            LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS ? i18n.t('noGuests')
            : LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO ? i18n.t('noMusicians')
            : i18n.t('noCustomers')
          }</Text>}
        </ScrollView>

        {MyDateTimePickerModalReactNative!=null && LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && 
          <DateTimePickerModal
            isVisible={memberSinceDatePickerVisible || membershipValidUntilDatePickerVisible}
            mode="date"
            onConfirm={newDate => {
              if(memberSinceDatePickerVisible)
                memberSinceHandleConfirm(newDate, selectedClubMemberIndex);
              else if(membershipValidUntilDatePickerVisible)
                membershipValidUntilHandleConfirm(newDate, selectedClubMemberIndex);
            }}
            onCancel={memberSinceHideDatePicker}
          />
        }
        {MyDateTimePickerModalForWeb!=null && LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS &&
          <SafeAreaProvider>
            <MyDateTimePickerModalForWeb
              locale={LoginManager.getInstance().language==LoginManager.getInstance().GR ? "el" : "en"}
              mode="single"
              label={i18n.t('selectDate')}
              visible={memberSinceDatePickerVisible || membershipValidUntilDatePickerVisible}
              onDismiss={memberSinceHideDatePicker}
              date={memberSinceDatePickerVisible==true ? moment(clubMembersWithGenericPlayerInfoAndMembership_Tennis[selectedClubMemberIndex].clubMember.member_since_date, "DD/MM/YYYY").toDate()
                    : membershipValidUntilDatePickerVisible && membershipValidUntilSelectedDate[selectedClubMemberIndex]!="UNKNOWN" ? moment(clubMembersWithGenericPlayerInfoAndMembership_Tennis[selectedClubMemberIndex].clubMember.membership_valid_to_date, "DD/MM/YYYY").toDate()
                    : moment().toDate()}
              onConfirm={(params) => {
                if(memberSinceDatePickerVisible)
                  memberSinceHandleConfirm(params.date, selectedClubMemberIndex);
                else if(membershipValidUntilDatePickerVisible)
                  membershipValidUntilHandleConfirm(params.date, selectedClubMemberIndex);
                memberSinceHideDatePicker();
              }}
              onChange={(params) => {
                if(memberSinceDatePickerVisible)
                  memberSinceHandleConfirm(params.date, selectedClubMemberIndex);
                else if(membershipValidUntilDatePickerVisible)
                  membershipValidUntilHandleConfirm(params.date, selectedClubMemberIndex);
                memberSinceHideDatePicker();
              }}
            />
          </SafeAreaProvider>                
        }

        <Portal>
          <Modal visible={visibleNewClubMemberModal} onDismiss={hideNewClubMemberModal} contentContainerStyle={styles.modalContainer}>
            <OwnerAddTennisClubMemberModal 
                        membershipsDropdownList={membershipsDropdownList}
                        setVisibleNewClubMemberModal={setVisibleNewClubMemberModal}
                        handleRefresh={handleRefresh}
            />  
          </Modal>
        </Portal>

        {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS 
          && tennisPrivateClubMode!=Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE 
          && <FAB style={[styles.fab, { display: 'flex'}]}
          icon={({ color, size }) => (<MaterialCommunityIcons name="account-plus" color={color} size={size} />)}
            onPress={() => { setVisibleNewClubMemberModal(true); }}
        />}

        {MyAlertForWeb!=null && 
          <MyAlertForWeb
            show={alertForWebVisible}
            showProgress={false}
            title={alertForWebTitle}
            message={alertForWebMsg}
            closeOnTouchOutside={false}
            closeOnHardwareBackPress={false}
            showConfirmButton={true}
            confirmText="OK"
            showCancelButton={true}
            cancelText={i18n.t('cancel')}
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              setAlertForWebVisible(false);

              if(showAlertForPlayerBlocking_forWebAlert != "")
                setShowAlertForPlayerBlocking_forWebAlert("");
              if(showAlertForPlayerUnblocking_forWebAlert != "")
                setShowAlertForPlayerUnblocking_forWebAlert("");
              if(showAlertForClubRequestAcceptance_forWebAlert.from_user_email != "")
                setShowAlertForClubRequestAcceptance_forWebAlert({
                                                                  id: -1,
                                                                  from_user_email: "",
                                                                  request_date: "",
                                                                  to_owner_id: -1,
                                                                  status: "",
                                                                  response_date: "",
                                                                  membership_id: -1,
                                                                  says_he_is_already_member: false
                                                                });
              if(showAlertForClubRequestRejection_forWebAlert.from_user_email != "")
                setShowAlertForClubRequestRejection_forWebAlert({
                                                                  id: -1,
                                                                  from_user_email: "",
                                                                  request_date: "",
                                                                  to_owner_id: -1,
                                                                  status: "",
                                                                  response_date: "",
                                                                  membership_id: -1,
                                                                  says_he_is_already_member: false
                                                                });
              if(showAlertForClubMemberDeletion_forWebAlert.user_email != "")
                setShowAlertForClubMemberDeletion_forWebAlert({
                                                                id: -1,
                                                                owner_id: -1,
                                                                user_email: "",
                                                                member_since_date: "",
                                                                membership_id: -1,
                                                                membership_valid_from_date: "",
                                                                membership_valid_to_date: "",
                                                                user_name_provided_by_owner: "",
                                                                user_surname_provided_by_owner: "",
                                                                user_telephone_provided_by_owner: ""
                                                              });
            }}
            onConfirmPressed={() => {
              if(showAlertForPlayerBlocking_forWebAlert != "") {
                var tmpBlockedPlayer: BlockedPlayer = {
                  id: -1,
                  owner_id: LoginManager.getInstance().ID,
                  blocked_user_email: showAlertForPlayerBlocking_forWebAlert,
                  reason: "",
                  blocking_date: ""
                }
                blockPlayer(tmpBlockedPlayer);
              }
              else if(showAlertForPlayerUnblocking_forWebAlert != "") {
                var tmpBlockedPlayer: BlockedPlayer = {
                  id: -1,
                  owner_id: LoginManager.getInstance().ID,
                  blocked_user_email: showAlertForPlayerUnblocking_forWebAlert,
                  reason: "",
                  blocking_date: ""
                }
                unblockPlayer(tmpBlockedPlayer);
              }              
              else if(showAlertForClubRequestAcceptance_forWebAlert.from_user_email != "") {
                (async () => {
                  const webServiceResponseStr = await Tennis_RestManager.getInstance().acceptClubRequest(showAlertForClubRequestAcceptance_forWebAlert);
                  if(webServiceResponseStr!=null && webServiceResponseStr!=undefined) {
                    handleRefresh();
                    //show snackbar
                    setSnackBarText(i18n.t('clubRequestAccepted'));
                    setSnackBarVisible(true);
                  }
                })();  
              }
              else if(showAlertForClubRequestRejection_forWebAlert.from_user_email != "") {
                (async () => {
                  const webServiceResponseStr = await Tennis_RestManager.getInstance().rejectClubRequest(showAlertForClubRequestRejection_forWebAlert);
                  if(webServiceResponseStr!=null && webServiceResponseStr!=undefined) {
                    handleRefresh();
                    //show snackbar
                    setSnackBarText(i18n.t('clubRequestRejected'));
                    setSnackBarVisible(true);
                  }
                })();
              }              
              else if(showAlertForClubMemberDeletion_forWebAlert.user_email != "") {
                (async () => {
                  setIsLoading(true);
                  const webServiceResponseStr = await Tennis_RestManager.getInstance().deleteClubMember(showAlertForClubMemberDeletion_forWebAlert);
                  setIsLoading(false);
                  if(webServiceResponseStr == "Club member deleted!") {
                    handleRefresh();
                    //show snackbar
                    setSnackBarText(i18n.t('clubMemberDeleted'));
                    setSnackBarVisible(true);
                  }
                })();
              }

              setAlertForWebVisible(false);

              if(showAlertForPlayerBlocking_forWebAlert != "")
                setShowAlertForPlayerBlocking_forWebAlert("");
              if(showAlertForPlayerUnblocking_forWebAlert != "")
                setShowAlertForPlayerUnblocking_forWebAlert("");
              if(showAlertForClubRequestAcceptance_forWebAlert.from_user_email != "")
                setShowAlertForClubRequestAcceptance_forWebAlert({
                                                                  id: -1,
                                                                  from_user_email: "",
                                                                  request_date: "",
                                                                  to_owner_id: -1,
                                                                  status: "",
                                                                  response_date: "",
                                                                  membership_id: -1,
                                                                  says_he_is_already_member: false
                                                                });
              if(showAlertForClubRequestRejection_forWebAlert.from_user_email != "")
                setShowAlertForClubRequestRejection_forWebAlert({
                                                                  id: -1,
                                                                  from_user_email: "",
                                                                  request_date: "",
                                                                  to_owner_id: -1,
                                                                  status: "",
                                                                  response_date: "",
                                                                  membership_id: -1,
                                                                  says_he_is_already_member: false
                                                                });
              if(showAlertForClubMemberDeletion_forWebAlert.user_email != "")
                setShowAlertForClubMemberDeletion_forWebAlert({
                                                                id: -1,
                                                                owner_id: -1,
                                                                user_email: "",
                                                                member_since_date: "",
                                                                membership_id: -1,
                                                                membership_valid_from_date: "",
                                                                membership_valid_to_date: "",
                                                                user_name_provided_by_owner: "",
                                                                user_surname_provided_by_owner: "",
                                                                user_telephone_provided_by_owner: ""
                                                              });
            }}  
          />
        }

        <Snackbar
          style={styles.snackbar}
          visible={snackBarVisible}
          onDismiss={onDismissSnackBar}>
          {snackBarText}
        </Snackbar> 
      </View>
    );
  };

export default OwnerManagePlayersView;