import GUIManager from "./GUIManager";
import LoginManager from "./LoginManager";

class Generic_RestManager {

    private static instance: Generic_RestManager;

    private constructor() {
        
    }

    public static getInstance(): Generic_RestManager {
        if (!Generic_RestManager.instance) {
            Generic_RestManager.instance = new Generic_RestManager();
        }

        return Generic_RestManager.instance;
    }

    public async getAllReservationsOfAllDomainsByUserEmail_forReactNativeCalendarsAgenda(tmpPlayerEmail : string) {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsOfAllDomainsByUserEmail_forReactNativeCalendarsAgenda', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username,
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
                email: tmpPlayerEmail
            }
            }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
            //console.log("*** getAllReservationsByPlayerID_forReactNativeCalendarsAgenda START ***");
            //console.log(JSON.stringify(responseJson, null, 2));
            //console.log("*** getAllReservationsByPlayerID_forReactNativeCalendarsAgenda END ***");
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });      
    }

    public async getGenericPlayerInfoByEmailOnly(tmpUserEmail : string) : Promise<GenericPlayerInfo>  {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getGenericPlayerInfoByEmailOnly', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username,
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
                email: tmpUserEmail
            }
            }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
            console.log("getGenericPlayerInfoByEmailOnly(" + tmpUserEmail + ") response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });      
    }

    ////////////
    // TOKENS //
    ////////////

    public async deleteToken() : Promise<boolean>  {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteToken', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username,
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
                user_email: LoginManager.getInstance().username,
                encrypted_token: LoginManager.getInstance().encrypted_token
            }
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("deleteToken response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        }); 
    }

    ///////////////////
    // NOTIFICATIONS //
    ///////////////////

    public async deleteNotification(tmpNotificationID : number) : Promise<boolean>  {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteNotification', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username,
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
                id: tmpNotificationID,
                user_email: LoginManager.getInstance().username
            }
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("deleteNotification response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        }); 
    }

    public async getAllNotificationsByUserEmail() : Promise<Array<Notification>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllNotificationsByUserEmail', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username,
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
                user_email: LoginManager.getInstance().username
            }
            }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
            //console.log("*** getAllNotificationsByUserEmail START ***");
            //console.log(JSON.stringify(responseJson, null, 2));
            //console.log("*** getAllNotificationsByUserEmail END ***");
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });      
    }

    ////////////////////////////////////////////
    //GETTING CURRENT LOCATION BASED ON THE IP//
    ////////////////////////////////////////////
    public async getUserGeolocationBasedOnIP(tmpMyIP: string): Promise<{ latitude: number; longitude: number }> {
        try {
          const response = await fetch('http://ipinfo.io/' + tmpMyIP + '?token=3992f3f6d1926a');
          if (!response.ok) {
            console.log("API response: ", response);
            throw new Error('Failed to fetch geolocation data.');
          }
      
          const data = await response.json();
          const [latitude, longitude] = data.loc.split(',').map(parseFloat);
          console.log('User geolocation success:', latitude, longitude);
          return { latitude, longitude };
        } catch (error) {
          console.log('Error getting user geolocation:', error);
          // You can handle the error here or throw it to be caught by the calling function.
          throw error;
        }
      

      /*try {
        const response = await fetch('http://ip-api.com/json');
        if (!response.ok) {
          throw new Error('Failed to fetch geolocation data.');
        }
    
        const data = await response.json();
    
        if (!data || data.status !== 'success' || !data.lat || !data.lon) {
          throw new Error('Invalid geolocation data received from the server.');
        }
    
        const latitude = parseFloat(data.lat);
        const longitude = parseFloat(data.lon);
        console.log('User geolocation success:', latitude, longitude);
        return { latitude, longitude };
      } catch (error) {
        console.error('Error getting user geolocation:', error);
        // You can handle the error here or throw it to be caught by the calling function.
        throw error;
      }*/
    }


}

export default Generic_RestManager;