import moment from "moment";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Dimensions, Keyboard, Platform, ScrollView, TouchableWithoutFeedback, View, TouchableOpacity } from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { Appbar, Button, Checkbox, Colors, DataTable, Divider, Modal, Portal, RadioButton, Text, TextInput, Title } from "react-native-paper";
import {LocaleConfig, Calendar as RNCalendar} from 'react-native-calendars';
import styles from "../../css/myCss";
import LoginManager from "../../managers/LoginManager";
import Studio_RestManager from "../../managers/Studio_RestManager";
import UtilsManager from "../../managers/UtilsManager";
import i18n from '../../utils/i18n';
import Generic_ConstManager from "../../managers/Generic_ConstManager";
import Studio_ConstManager from "../../managers/Studio_ConstManager";
import Tennis_ConstManager from "../../managers/Tennis_ConstManager";
import Tennis_RestManager from "../../managers/Tennis_RestManager";
import { MaterialCommunityIcons } from "@expo/vector-icons";

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const screenDimensions = Dimensions.get('window');

const PlayerNewReservationModal = ({  
                                selectedDomainValue,
                                curOwner,
                                allRoomsOfCurOwner_Studio,
                                ownerExtraInfo_Studio,
                                allCourtsOfCurOwner_Tennis,
                                setReservationCompletionMsg,
                                iAmMemberOfTheClub_Tennis
                            }) => {
  
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');
  
///////////////////////////
// Studio domain - START //
///////////////////////////
  //////////////////////////////////////////////////////////////////////////
  // 1) Arxika pairnoume to 1o dwmatio selectedRoomIndexInRoomsArray = 0  //
  //////////////////////////////////////////////////////////////////////////
  const [selectedRoomIndexInRoomsArray, setSelectedRoomIndexInRoomsArray] = useState(0);
  //each time current room is changing, get all reservations for this room
  useEffect(() => {
    console.log("useEffect - selectedRoomIndexInRoomsArray - START -> selectedRoomIndexInRoomsArray: ", selectedRoomIndexInRoomsArray);
    const tmpCurRoom = allRoomsOfCurOwner_Studio[selectedRoomIndexInRoomsArray];
    if(tmpCurRoom!=undefined && tmpCurRoom.id!=-1 && tmpCurRoom.name!="") {
      /////////////////////////////
      // 2) Setaroume to dwmatio //
      /////////////////////////////
      setSelectedRoom(tmpCurRoom);
    }
    else
      console.log("tmpCurRoom is NULL!!!")
    console.log("useEffect - selectedRoomIndexInRoomsArray - STOP");
  }, [selectedRoomIndexInRoomsArray]);

  //BOHTHITIKES METAVLHTES GIA NA SYNEXISTEI TO WORKFLOW STIS EKSHS PERIPTWSEIS:
  // 1) ROOM DEN EXEI RESERVATIONS GENIKA
  // 2) TO ROOM DEN EXEI RESERVATIONS GIA TH SYGKEKRIMENH HMEROMHNIA
  const [selectedRoomHasNoReservations, setSelectedRoomHasNoReservations] = useState(false);
  useEffect(() => {
    console.log("useEffect - selectedRoomHasNoReservations - START -> selectedRoomHasNoReservations: ", selectedRoomHasNoReservations);
    if(selectedRoomHasNoReservations == true) {
      //afoy den exei reservations genika, sigoura den exei kai gia th sygkekrimenh hmeromhnia
      setSelectedRoomHasNoReservationsForThisDate(true);
      //init
      setSelectedRoomHasNoReservations(false);
    }
    console.log("useEffect - selectedRoomHasNoReservations - STOP");
  }, [selectedRoomHasNoReservations]);
  const [selectedRoomHasNoReservationsForThisDate, setSelectedRoomHasNoReservationsForThisDate] = useState(false);
  useEffect(() => {
    console.log("useEffect - selectedRoomHasNoReservationsForThisDate - START -> selectedRoomHasNoReservationsForThisDate: ", selectedRoomHasNoReservationsForThisDate);
    if(selectedRoomHasNoReservationsForThisDate == true) {
      ////////////////////////////
      // 5) Setarw ta timeslots //
      ////////////////////////////
      if(selectedRoom!=undefined && selectedRoom.id!=-1) {
        setCurTimeslots_Studio(moment(newReservation_Studio.reservation_date, 'DD/MM/YYYY').toDate().getDay()); //pairnw day number
      }
      //init
      setSelectedRoomHasNoReservationsForThisDate(false);
    }
    console.log("useEffect - selectedRoomHasNoReservationsForThisDate - STOP");
  }, [selectedRoomHasNoReservationsForThisDate]);

  const [selectedRoom, setSelectedRoom] = useState<Studio_Room>({
    id: -1, owned_by: LoginManager.getInstance().loggedInOwner.id, name: "", currency: "",
    monday_cost_per_hour_reh: -1.0, monday_cost_per_hour_rec: -1.0,
    tuesday_cost_per_hour_reh: -1.0, tuesday_cost_per_hour_rec: -1.0,
    wednesday_cost_per_hour_reh: -1.0, wednesday_cost_per_hour_rec: -1.0,
    thursday_cost_per_hour_reh: -1.0, thursday_cost_per_hour_rec: -1.0,
    friday_cost_per_hour_reh: -1.0, friday_cost_per_hour_rec: -1.0,
    saturday_cost_per_hour_reh: -1.0, saturday_cost_per_hour_rec: -1.0,
    sunday_cost_per_hour_reh: -1.0, sunday_cost_per_hour_rec: -1.0,
    monday_working_hours_start: "closed", tuesday_working_hours_start: "closed",
    wednesday_working_hours_start: "closed", thursday_working_hours_start: "closed",
    friday_working_hours_start: "closed", saturday_working_hours_start: "closed",
    sunday_working_hours_start: "closed", monday_working_hours_stop: "closed",
    tuesday_working_hours_stop: "closed", wednesday_working_hours_stop: "closed",
    thursday_working_hours_stop: "closed", friday_working_hours_stop: "closed",
    saturday_working_hours_stop: "closed", sunday_working_hours_stop: "closed",
    space: "", speakers: "", console: "", microphones: "", drums: "", guitars: "", basses: "", 
    pianos_keyboards: "", guitar_amplifiers: "",     bass_amplifiers: "", other_equipment: ""
  });
  useEffect(() => {
    (async () => {
      console.log("useEffect - selectedRoom - START -> selectedRoom: ", JSON.stringify(selectedRoom, null, 2));
      setIsLoading(true);
      try {
        //////////////////////////////////////////////////////////////////////////////////////
        // 3) Pairnoume ola ta reservations apo shmera mexri kai to telos toy epomenou mhna //
        //////////////////////////////////////////////////////////////////////////////////////
        if(selectedRoom!=undefined && selectedRoom.id!=-1) {
          const tmpAllReservationsOfTheRoom = await Studio_RestManager.getInstance().getAllReservationsByRoomID_fromDateToDate(selectedRoom.id);
          //console.log("room: ", selectedRoom.name);
          //console.log(JSON.stringify(selectedRoom, null, 2));
          setAllReservationsOfThisRoom(tmpAllReservationsOfTheRoom);
          //PROSOXH: An den yparxoun reservations, de tha mpei sto parapanw useEffect...opote kanw patenta me to parakatw gia na synexistei to workflow...
          if(tmpAllReservationsOfTheRoom==undefined || tmpAllReservationsOfTheRoom.length==0)
            setSelectedRoomHasNoReservations(true);
    
          setNewReservation_Studio({...newReservation_Studio,
            for_room: selectedRoom.id
          });
        } else {
          console.log("selectedRoom is NULL!!!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        console.log("useEffect - selectedRoom - STOP");
      }
    })();
  }, [JSON.stringify(selectedRoom)]);

  const [newReservation_Studio, setNewReservation_Studio] = useState<Studio_Reservation>({
    id: -1,                         //I don't need to complete this field - automatically completed internally by the web service
    for_room: -1,
    rehearsal: ownerExtraInfo_Studio.allows_rehearsal==true && ownerExtraInfo_Studio.allows_recording==false ? true
                : ownerExtraInfo_Studio.allows_rehearsal==false && ownerExtraInfo_Studio.allows_recording==true ? false
                : true,
    by_user_email: LoginManager.getInstance().username,
    reservation_date: moment().format('DD/MM/YYYY').toString(),
    reservation_hour_start: '',
    reservation_hour_stop: '',
    date_created: '',               //I don't need to complete this field - automatically completed internally by the web service
    date_last_updated: '',          //I don't need to complete this field - automatically completed internally by the web service
    completed_successfully: false,  //I don't need to complete this field - automatically completed internally by the web service
    comments: ''
  });
  useEffect(() => {
    console.log("useEffect - newReservation_Studio - START -> newReservation_Studio: ", JSON.stringify(newReservation_Studio, null, 2));
    
    console.log("useEffect - newReservation_Studio - END");
  }, [JSON.stringify(newReservation_Studio)]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // 4) search in all reservations for this room and get all the reservations for the specific day //
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const [allReservationsOfThisRoom, setAllReservationsOfThisRoom] = useState<Array<Studio_Reservation>>([]);
  useEffect(() => {
    //console.log("4 - Start");
    var newReservationsForThisDate : Array<Studio_Reservation> = [];
    if(allReservationsOfThisRoom != undefined) {
      for (let i = 0; i < allReservationsOfThisRoom.length; i++) { 
          const tmpReservation: Studio_Reservation = allReservationsOfThisRoom[i];

          if(newReservation_Studio.reservation_date == tmpReservation.reservation_date) {             
            newReservationsForThisDate.push(tmpReservation);  
          }
      }     
      setAllReservationsOfThisRoomForThisDate(newReservationsForThisDate);
      //PROSOXH: An den yparxoun reservations gia ayth thn hmeromhnia, de tha mpei sto parapanw useEffect...opote kanw patenta me to parakatw gia na synexistei to workflow...
      if(newReservationsForThisDate==undefined || newReservationsForThisDate.length==0)
        setSelectedRoomHasNoReservationsForThisDate(true);
    }    
  }, [JSON.stringify(allReservationsOfThisRoom), newReservation_Studio.reservation_date, selectedRoomIndexInRoomsArray]);

  const [allReservationsOfThisRoomForThisDate, setAllReservationsOfThisRoomForThisDate] = useState<Array<Studio_Reservation>>([]);  
  useEffect(() => {
    console.log("useEffect - allReservationsOfThisRoomForThisDate - START -> allReservationsOfThisRoomForThisDate: ", JSON.stringify(allReservationsOfThisRoomForThisDate, null, 2));
    ////////////////////////////
    // 5) Setarw ta timeslots //
    ////////////////////////////
    if(selectedRoom!=undefined && selectedRoom.id!=-1) {
      setCurTimeslots_Studio(moment(newReservation_Studio.reservation_date, 'DD/MM/YYYY').toDate().getDay()); //pairnw day number
    }
    console.log("useEffect - allReservationsOfThisRoomForThisDate - END");
  }, [JSON.stringify(allReservationsOfThisRoomForThisDate)]);

  const setCurTimeslots_Studio = useCallback((tmpDayNum: number) => {
      console.log("setCurTimeslots_Studio - START - tmpDayNum: ", tmpDayNum, " (0 is Sunday)");
      //console.log(JSON.stringify(selectedRoom, null, 2));
      setSelectedDayNum(tmpDayNum);
  
      var tmpAllHoursOfSelectedDay: Array<string> = new Array(0);
      var tmpAllHoursOfSelectedDaySelected: Array<boolean> = new Array(0);
      var tmpCurSelectedRoomOpenAtSelectedDay = true;
    
      //Sunday is 0, Monday is 1, and so on.
      var tmpStartHourFound : boolean = false;
      var tmpStopHourFound: boolean = false;
      var tmpCurRoomStartHour: string = "";
      var tmpCurRoomStopHour: string = "";
    
      //Sunday
      if(tmpDayNum == 0) {
        tmpCurRoomStartHour = selectedRoom.sunday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.sunday_working_hours_stop;
        //console.log("Sunday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.sunday_working_hours_start=="closed" || selectedRoom.sunday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
      //Monday
      else if(tmpDayNum == 1) {
        tmpCurRoomStartHour = selectedRoom.monday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.monday_working_hours_stop;
        //console.log("Monday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.monday_working_hours_start=="closed" || selectedRoom.monday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
      //Tuesday
      else if(tmpDayNum == 2) {
        tmpCurRoomStartHour = selectedRoom.tuesday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.tuesday_working_hours_stop;
        //console.log("Tuesday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.tuesday_working_hours_start=="closed" || selectedRoom.tuesday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
      //Wednesday
      else if(tmpDayNum == 3) {
        tmpCurRoomStartHour = selectedRoom.wednesday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.wednesday_working_hours_stop;
        //console.log("Wednesday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.wednesday_working_hours_start=="closed" || selectedRoom.wednesday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
      //Thursday
      else if(tmpDayNum == 4) {
        tmpCurRoomStartHour = selectedRoom.thursday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.thursday_working_hours_stop;
        //console.log("Thursday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.thursday_working_hours_start=="closed" || selectedRoom.thursday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
      //Friday
      else if(tmpDayNum == 5) {
        tmpCurRoomStartHour = selectedRoom.friday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.friday_working_hours_stop;
        //console.log("Friday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.friday_working_hours_start=="closed" || selectedRoom.friday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
      //Saturday
      else if(tmpDayNum == 6) {
        tmpCurRoomStartHour = selectedRoom.saturday_working_hours_start;
        tmpCurRoomStopHour = selectedRoom.saturday_working_hours_stop;
        //console.log("Saturday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        if(selectedRoom.saturday_working_hours_start=="closed" || selectedRoom.saturday_working_hours_stop=="closed")
          tmpCurSelectedRoomOpenAtSelectedDay = false;
      }
  
      if(tmpCurSelectedRoomOpenAtSelectedDay == true) {
        //console.log(" open, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
        for (let i=0; i<UtilsManager.getInstance().allHours.length; i++) {
          //console.log("    i: ", i, " -> ", UtilsManager.getInstance().allHours[i]);
          if( (tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStartHour) 
              || (tmpStartHourFound==true && tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStopHour)
              || (tmpStartHourFound==true && tmpStopHourFound==false) //Start(found) - tmp - Stop(not found)
                ) {
            //console.log(" to prosthetw");
            tmpAllHoursOfSelectedDay.push(UtilsManager.getInstance().allHours[i]);
            tmpAllHoursOfSelectedDaySelected.push(false);
  
            if(tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStartHour) {
              //console.log("      tmpStartHourFound");
              tmpStartHourFound = true;
            }
            else if(tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStopHour) {
              //console.log("      tmpStoptHourFound");
              tmpStopHourFound = true;
            }
          }
          //check if it needs to be restarted
          if((tmpStartHourFound==false || tmpStopHourFound==false) && i==(UtilsManager.getInstance().allHours.length-1)) {
            //console.log(" restart");
            i = -1;
          }
        }
        //delete last item e.x. if it closes at 00:00, we don't want to add 00:00-00:30
        tmpAllHoursOfSelectedDay.pop();
        tmpAllHoursOfSelectedDaySelected.pop();
      }
      setAllHoursOfSelectedDay(tmpAllHoursOfSelectedDay);
      setSelectedTimeslots(tmpAllHoursOfSelectedDaySelected);
      setCurSelectedRoomOpenAtSelectedDay(tmpCurSelectedRoomOpenAtSelectedDay);
      console.log("setCurTimeslots_Studio - END");
    }, [newReservation_Studio.reservation_date, JSON.stringify(selectedRoom), selectedRoomIndexInRoomsArray]);

  function prepareReservationCostDetails_Studio() {
    var misawra: number = 0;
    for (let i = 0; i < selectedTimeslots.length; i++) {
      if(selectedTimeslots[i] === true) {
        misawra++;
      }
    }
    
    if(selectedRoom != undefined)
    {
      var tmpCurrency: string = selectedRoom.currency;
      const pavlaIndex : number = tmpCurrency.indexOf(' - ');
      if(pavlaIndex != 0)
        tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

      if(newReservation_Studio.rehearsal == true) {
        //Sunday
        if(selectedDayNum === 0) { setNewReservationCostLabel((misawra*(selectedRoom.sunday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
        //Monday
        else if(selectedDayNum === 1) { setNewReservationCostLabel((misawra*(selectedRoom.monday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
        //Tuesday
        else if(selectedDayNum === 2) { setNewReservationCostLabel((misawra*(selectedRoom.tuesday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
        //Wednesday
        else if(selectedDayNum === 3) { setNewReservationCostLabel((misawra*(selectedRoom.wednesday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
        //Thursday
        else if(selectedDayNum === 4) { setNewReservationCostLabel((misawra*(selectedRoom.thursday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
        //Friday
        else if(selectedDayNum === 5) { setNewReservationCostLabel((misawra*(selectedRoom.friday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
        //Saturday
        else if(selectedDayNum === 6) { setNewReservationCostLabel((misawra*(selectedRoom.saturday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
      }
      else if(newReservation_Studio.rehearsal == false) {
        //Sunday
        if(selectedDayNum === 0) { setNewReservationCostLabel((misawra*(selectedRoom.sunday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
        //Monday
        else if(selectedDayNum === 1) { setNewReservationCostLabel((misawra*(selectedRoom.monday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
        //Tuesday
        else if(selectedDayNum === 2) { setNewReservationCostLabel((misawra*(selectedRoom.tuesday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
        //Wednesday
        else if(selectedDayNum === 3) { setNewReservationCostLabel((misawra*(selectedRoom.wednesday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
        //Thursday
        else if(selectedDayNum === 4) { setNewReservationCostLabel((misawra*(selectedRoom.thursday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
        //Friday
        else if(selectedDayNum === 5) { setNewReservationCostLabel((misawra*(selectedRoom.friday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
        //Saturday
        else if(selectedDayNum === 6) { setNewReservationCostLabel((misawra*(selectedRoom.saturday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
      }
    }
  }

  //is the room open at this date?
  const [curSelectedRoomOpenAtSelectedDay, setCurSelectedRoomOpenAtSelectedDay] = useState(false);

  const [mySelectedExistingReservation_Studio, setMySelectedExistingReservation_Studio] = useState({
      index: -1,
      rehearsalRecording: "",
      hours: "",
      cost: "",
      comments : ""
  });
  useEffect(() => {
    //...
    if(mySelectedExistingReservation_Studio.index != -1) {
      const tmpSelReservation = allReservationsOfThisRoomForThisDate[mySelectedExistingReservation_Studio.index];
      //set all the other props (except id that is already set)
      setMySelectedExistingReservation_Studio({...mySelectedExistingReservation_Studio, 
        rehearsalRecording: tmpSelReservation.rehearsal ? i18n.t("rehearsal") : i18n.t("recording"),
        hours: tmpSelReservation.reservation_hour_start + " - " + tmpSelReservation.reservation_hour_stop,
        cost: UtilsManager.getInstance().getReservationCostWithCurrency_Studio(tmpSelReservation.reservation_hour_start, tmpSelReservation.reservation_hour_stop, allReservationsOfThisRoomForThisDate[mySelectedExistingReservation_Studio.index].rehearsal, selectedRoom, selectedDayNum),
        comments : tmpSelReservation.comments
      });
    }
  }, [mySelectedExistingReservation_Studio.index]);
  useEffect(() => {
    if(mySelectedExistingReservation_Studio.index != -1) {
      console.log("useEffect - mySelectedExistingReservation_Studio - START -> mySelectedExistingReservation_Studio: ", JSON.stringify(mySelectedExistingReservation_Studio, null, 2));
      setVisibleNewReservationDetailsModal(true);
      console.log("useEffect - mySelectedExistingReservation_Studio - END");
    }
  }, [JSON.stringify(mySelectedExistingReservation_Studio)]);
/////////////////////////
// Studio domain - END //
/////////////////////////

///////////////////////////
// Tennis domain - START //
///////////////////////////
  //////////////////////////////////////////////////////////////////////////
  // 1) Arxika pairnoume to 1o court selectedCourtIndexInCourtsArray = 0  //
  //////////////////////////////////////////////////////////////////////////
  const [selectedCourtIndexInCourtsArray, setSelectedCourtIndexInCourtsArray] = useState(0);
  //each time current court is changing, get all reservations for this court
  useEffect(() => {
    console.log("useEffect - selectedCourtIndexInCourtsArray - START -> selectedCourtIndexInCourtsArray: ", selectedCourtIndexInCourtsArray);
    const tmpCurCourt = allCourtsOfCurOwner_Tennis[selectedCourtIndexInCourtsArray];
    if(tmpCurCourt!=undefined && tmpCurCourt.id!=-1 && tmpCurCourt.name!="") {
      ////////////////////////////
      // 2) Setaroume to ghpedo //
      ////////////////////////////
      setSelectedCourt(tmpCurCourt);
    }
    else
      console.log("tmpCurCourt is NULL!!!")
    console.log("useEffect - selectedCourtIndexInCourtsArray - STOP");
  }, [selectedCourtIndexInCourtsArray]);

  //BOHTHITIKES METAVLHTES GIA NA SYNEXISTEI TO WORKFLOW STIS EKSHS PERIPTWSEIS:
  // 1) COURT DEN EXEI RESERVATIONS GENIKA
  // 2) TO COURT DEN EXEI RESERVATIONS GIA TH SYGKEKRIMENH HMEROMHNIA
  const [selectedCourtHasNoReservations, setSelectedCourtHasNoReservations] = useState(false);
  useEffect(() => {
    console.log("useEffect - selectedCourtHasNoReservations - START -> selectedCourtHasNoReservations: ", selectedCourtHasNoReservations);
    if(selectedCourtHasNoReservations == true) {
      //afoy den exei reservations genika, sigoura den exei kai gia th sygkekrimenh hmeromhnia
      setSelectedCourtHasNoReservationsForThisDate(true);
      //init
      setSelectedCourtHasNoReservations(false);
    }
    console.log("useEffect - selectedCourtHasNoReservations - STOP");
  }, [selectedCourtHasNoReservations]);
  const [selectedCourtHasNoReservationsForThisDate, setSelectedCourtHasNoReservationsForThisDate] = useState(false);
  useEffect(() => {
    console.log("useEffect - selectedCourtHasNoReservationsForThisDate - START -> selectedCourtHasNoReservationsForThisDate: ", selectedCourtHasNoReservationsForThisDate);
    if(selectedCourtHasNoReservationsForThisDate == true) {
      ////////////////////////////
      // 5) Setarw ta timeslots //
      ////////////////////////////
      if(selectedCourt!=undefined && selectedCourt.id!=-1) {
        setCurTimeslots_Tennis(moment(newReservation_Tennis.reservation_date, 'DD/MM/YYYY').toDate().getDay()); //pairnw day number
      }
      //init
      setSelectedCourtHasNoReservationsForThisDate(false);
    }
    console.log("useEffect - selectedCourtHasNoReservationsForThisDate - STOP");
  }, [selectedCourtHasNoReservationsForThisDate]);

  const [selectedCourt, setSelectedCourt] = useState<Tennis_Court>({
    id: -1, owned_by: LoginManager.getInstance().loggedInOwner.id, name: "", type: -1, currency: "",
    monday_cost_per_hour: -1.0, tuesday_cost_per_hour: -1.0, wednesday_cost_per_hour: -1.0,
    thursday_cost_per_hour: -1.0, friday_cost_per_hour: -1.0, saturday_cost_per_hour: -1.0, sunday_cost_per_hour: -1.0,
    monday_working_hours_start: "closed", 
    tuesday_working_hours_start: "closed",
    wednesday_working_hours_start: "closed",
    thursday_working_hours_start: "closed",
    friday_working_hours_start: "closed",
    saturday_working_hours_start: "closed",
    sunday_working_hours_start: "closed",
    monday_working_hours_stop: "closed",
    tuesday_working_hours_stop: "closed",
    wednesday_working_hours_stop: "closed",
    thursday_working_hours_stop: "closed",
    friday_working_hours_stop: "closed",
    saturday_working_hours_stop: "closed",
    sunday_working_hours_stop: "closed"
  });
  useEffect(() => {
    (async () => {
      console.log("useEffect - selectedCourt - START -> selectedCourt: ", JSON.stringify(selectedCourt, null, 2));
      setIsLoading(true);
      try {
        //////////////////////////////////////////////////////////////////////////////////////
        // 3) Pairnoume ola ta reservations apo shmera mexri kai to telos toy epomenou mhna //
        //////////////////////////////////////////////////////////////////////////////////////
        if(selectedCourt!=undefined && selectedCourt.id!=-1) {
          const tmpAllReservationsOfTheCourt = await Tennis_RestManager.getInstance().getAllReservationsByCourtID_fromDateToDate(selectedCourt.id);
          //console.log("room: ", selectedCourt.name);
          //console.log(JSON.stringify(selectedCourt, null, 2));
          setAllReservationsOfThisCourt(tmpAllReservationsOfTheCourt);
          //PROSOXH: An den yparxoun reservations, de tha mpei sto parapanw useEffect...opote kanw patenta me to parakatw gia na synexistei to workflow...
          if(tmpAllReservationsOfTheCourt==undefined || tmpAllReservationsOfTheCourt.length==0)
            setSelectedCourtHasNoReservations(true);
    
          setNewReservation_Tennis({...newReservation_Tennis,
            for_court: selectedCourt.id
          });
        } else {
          console.log("selectedCourt is NULL!!!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        console.log("useEffect - selectedCourt - STOP");
      }
    })();
  }, [JSON.stringify(selectedCourt)]);

  const [newReservation_Tennis, setNewReservation_Tennis] = useState<Tennis_Reservation>({
    id: -1,                         //I don't need to complete this field - automatically completed internally by the web service
    for_court: -1,
    by_user_email: LoginManager.getInstance().username,
    reservation_date: moment().format('DD/MM/YYYY').toString(),
    reservation_hour_start: '',
    reservation_hour_stop: '',
    date_created: '',               //I don't need to complete this field - automatically completed internally by the web service
    date_last_updated: '',          //I don't need to complete this field - automatically completed internally by the web service
    completed_successfully: false,  //I don't need to complete this field - automatically completed internally by the web service
    comments: ''
  });
  useEffect(() => {
    console.log("useEffect - newReservation_Tennis - START -> newReservation_Tennis: ", JSON.stringify(newReservation_Tennis, null, 2));
    
    console.log("useEffect - newReservation_Tennis - END");
  }, [JSON.stringify(newReservation_Tennis)]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // 4) search in all reservations for this court and get all the reservations for the specific day //
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [allReservationsOfThisCourt, setAllReservationsOfThisCourt] = useState<Array<Tennis_Reservation>>([]);
  useEffect(() => {
    //console.log("4 - Start");
    var newReservationsForThisDate : Array<Tennis_Reservation> = [];
    if(allReservationsOfThisCourt != undefined) {
      for (let i = 0; i < allReservationsOfThisCourt.length; i++) { 
          const tmpReservation: Tennis_Reservation = allReservationsOfThisCourt[i];

          if(newReservation_Tennis.reservation_date == tmpReservation.reservation_date) {             
            newReservationsForThisDate.push(tmpReservation);  
          }
      }     
      setAllReservationsOfThisCourtForThisDate(newReservationsForThisDate);
      //PROSOXH: An den yparxoun reservations gia ayth thn hmeromhnia, de tha mpei sto parapanw useEffect...opote kanw patenta me to parakatw gia na synexistei to workflow...
      if(newReservationsForThisDate==undefined || newReservationsForThisDate.length==0)
        setSelectedCourtHasNoReservationsForThisDate(true);
    }    
  }, [JSON.stringify(allReservationsOfThisCourt), newReservation_Tennis.reservation_date, selectedCourtIndexInCourtsArray]);

  const [allReservationsOfThisCourtForThisDate, setAllReservationsOfThisCourtForThisDate] = useState<Array<Tennis_Reservation>>([]);  
  useEffect(() => {
    console.log("useEffect - allReservationsOfThisCourtForThisDate - START -> allReservationsOfThisCourtForThisDate: ", JSON.stringify(allReservationsOfThisCourtForThisDate, null, 2));
    ////////////////////////////
    // 5) Setarw ta timeslots //
    ////////////////////////////
    if(selectedCourt!=undefined && selectedCourt.id!=-1) {
      setCurTimeslots_Tennis(moment(newReservation_Tennis.reservation_date, 'DD/MM/YYYY').toDate().getDay()); //pairnw day number
    }
    console.log("useEffect - allReservationsOfThisCourtForThisDate - END");
  }, [JSON.stringify(allReservationsOfThisCourtForThisDate)]);

  const setCurTimeslots_Tennis = useCallback((tmpDayNum: number) => {
      console.log("setCurTimeslots_Tennis - START - tmpDayNum: ", tmpDayNum, " (0 is Sunday)");
      //console.log(JSON.stringify(selectedCourt, null, 2));
      setSelectedDayNum(tmpDayNum);
  
      var tmpAllHoursOfSelectedDay: Array<string> = new Array(0);
      var tmpAllHoursOfSelectedDaySelected: Array<boolean> = new Array(0);
      var tmpCurSelectedCourtOpenAtSelectedDay = true;
    
      //Sunday is 0, Monday is 1, and so on.
      var tmpStartHourFound : boolean = false;
      var tmpStopHourFound: boolean = false;
      var tmpCurCourtStartHour: string = "";
      var tmpCurCourtStopHour: string = "";
    
      //Sunday
      if(tmpDayNum == 0) {
        tmpCurCourtStartHour = selectedCourt.sunday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.sunday_working_hours_stop;
        //console.log("Sunday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.sunday_working_hours_start=="closed" || selectedCourt.sunday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
      //Monday
      else if(tmpDayNum == 1) {
        tmpCurCourtStartHour = selectedCourt.monday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.monday_working_hours_stop;
        //console.log("Monday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.monday_working_hours_start=="closed" || selectedCourt.monday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
      //Tuesday
      else if(tmpDayNum == 2) {
        tmpCurCourtStartHour = selectedCourt.tuesday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.tuesday_working_hours_stop;
        //console.log("Tuesday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.tuesday_working_hours_start=="closed" || selectedCourt.tuesday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
      //Wednesday
      else if(tmpDayNum == 3) {
        tmpCurCourtStartHour = selectedCourt.wednesday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.wednesday_working_hours_stop;
        //console.log("Wednesday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.wednesday_working_hours_start=="closed" || selectedCourt.wednesday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
      //Thursday
      else if(tmpDayNum == 4) {
        tmpCurCourtStartHour = selectedCourt.thursday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.thursday_working_hours_stop;
        //console.log("Thursday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.thursday_working_hours_start=="closed" || selectedCourt.thursday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
      //Friday
      else if(tmpDayNum == 5) {
        tmpCurCourtStartHour = selectedCourt.friday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.friday_working_hours_stop;
        //console.log("Friday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.friday_working_hours_start=="closed" || selectedCourt.friday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
      //Saturday
      else if(tmpDayNum == 6) {
        tmpCurCourtStartHour = selectedCourt.saturday_working_hours_start;
        tmpCurCourtStopHour = selectedCourt.saturday_working_hours_stop;
        //console.log("Saturday, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        if(selectedCourt.saturday_working_hours_start=="closed" || selectedCourt.saturday_working_hours_stop=="closed")
          tmpCurSelectedCourtOpenAtSelectedDay = false;
      }
  
      if(tmpCurSelectedCourtOpenAtSelectedDay == true) {
        //console.log(" open, tmpCurCourtStartHour: ", tmpCurCourtStartHour, ", tmpCurCourtStopHour: ", tmpCurCourtStopHour);
        for (let i=0; i<UtilsManager.getInstance().allHours.length; i++) {
          //console.log("    i: ", i, " -> ", UtilsManager.getInstance().allHours[i]);
          if( (tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStartHour) 
              || (tmpStartHourFound==true && tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStopHour)
              || (tmpStartHourFound==true && tmpStopHourFound==false) //Start(found) - tmp - Stop(not found)
                ) {
            //console.log(" to prosthetw");
            tmpAllHoursOfSelectedDay.push(UtilsManager.getInstance().allHours[i]);
            tmpAllHoursOfSelectedDaySelected.push(false);
  
            if(tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStartHour) {
              //console.log("      tmpStartHourFound");
              tmpStartHourFound = true;
            }
            else if(tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStopHour) {
              //console.log("      tmpStoptHourFound");
              tmpStopHourFound = true;
            }
          }
          //check if it needs to be restarted
          if((tmpStartHourFound==false || tmpStopHourFound==false) && i==(UtilsManager.getInstance().allHours.length-1)) {
            //console.log(" restart");
            i = -1;
          }
        }
        //delete last item e.x. if it closes at 00:00, we don't want to add 00:00-00:30
        tmpAllHoursOfSelectedDay.pop();
        tmpAllHoursOfSelectedDaySelected.pop();
      }
      setAllHoursOfSelectedDay(tmpAllHoursOfSelectedDay);
      setSelectedTimeslots(tmpAllHoursOfSelectedDaySelected);
      setCurSelectedCourtOpenAtSelectedDay(tmpCurSelectedCourtOpenAtSelectedDay);
      console.log("setCurTimeslots_Tennis - END");
    }, [newReservation_Tennis.reservation_date, JSON.stringify(selectedCourt), selectedCourtIndexInCourtsArray]);

  function prepareReservationCostDetails_Tennis() {
    var misawra: number = 0;
    for (let i = 0; i < selectedTimeslots.length; i++) {
      if(selectedTimeslots[i] === true) {
        misawra++;
      }
    }
    
    if(selectedCourt != undefined)
    {
      var tmpCurrency: string = selectedCourt.currency;
      const pavlaIndex : number = tmpCurrency.indexOf(' - ');
      if(pavlaIndex != 0)
        tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

      //Sunday
      if(selectedDayNum === 0) { setNewReservationCostLabel((misawra*(selectedCourt.sunday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
      //Monday
      else if(selectedDayNum === 1) { setNewReservationCostLabel((misawra*(selectedCourt.monday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
      //Tuesday
      else if(selectedDayNum === 2) { setNewReservationCostLabel((misawra*(selectedCourt.tuesday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
      //Wednesday
      else if(selectedDayNum === 3) { setNewReservationCostLabel((misawra*(selectedCourt.wednesday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
      //Thursday
      else if(selectedDayNum === 4) { setNewReservationCostLabel((misawra*(selectedCourt.thursday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
      //Friday
      else if(selectedDayNum === 5) { setNewReservationCostLabel((misawra*(selectedCourt.friday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
      //Saturday
      else if(selectedDayNum === 6) { setNewReservationCostLabel((misawra*(selectedCourt.saturday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
    }
  }

  //is the room open at this date?
  const [curSelectedCourtOpenAtSelectedDay, setCurSelectedCourtOpenAtSelectedDay] = useState(false);

  const [mySelectedExistingReservation_Tennis, setMySelectedExistingReservation_Tennis] = useState({
      index: -1,
      hours: "",
      cost: "",
      comments : ""
  });
  useEffect(() => {
    //...
    if(mySelectedExistingReservation_Tennis.index != -1) {
      const tmpSelReservation = allReservationsOfThisCourtForThisDate[mySelectedExistingReservation_Tennis.index];
      //set all the other props (except id that is already set)
      setMySelectedExistingReservation_Tennis({...mySelectedExistingReservation_Tennis, 
        hours: tmpSelReservation.reservation_hour_start + " - " + tmpSelReservation.reservation_hour_stop,
        cost: UtilsManager.getInstance().getReservationCostWithCurrency_Tennis(tmpSelReservation.reservation_hour_start, tmpSelReservation.reservation_hour_stop, selectedCourt, selectedDayNum),
        comments : tmpSelReservation.comments
      });
    }
  }, [mySelectedExistingReservation_Tennis.index]);
  useEffect(() => {      
    if(mySelectedExistingReservation_Tennis.index != -1) {
      console.log("useEffect - mySelectedExistingReservation_Tennis - START -> mySelectedExistingReservation_Tennis: ", JSON.stringify(mySelectedExistingReservation_Tennis, null, 2));
      setVisibleNewReservationDetailsModal(true);
      console.log("useEffect - mySelectedExistingReservation_Tennis - END");
    }
  }, [JSON.stringify(mySelectedExistingReservation_Tennis)]);

/////////////////////////
// Tennis domain - END //
/////////////////////////

//////////////////////////////////
// Generic, All domains - START //
//////////////////////////////////
  //set calendar locale
  LocaleConfig.locales[LoginManager.getInstance().language.toLowerCase()] = {
    monthNames: [i18n.t('january'),i18n.t('february'),i18n.t('march'),i18n.t('april'),i18n.t('may'),i18n.t('june'),i18n.t('july'),i18n.t('august'),i18n.t('september'),i18n.t('october'),i18n.t('november'),i18n.t('december')],
    monthNamesShort: [i18n.t('januaryShort'),i18n.t('februaryShort'),i18n.t('marchShort'),i18n.t('aprilShort'),i18n.t('mayShort'),i18n.t('juneShort'),i18n.t('julyShort'),i18n.t('augustShort'),i18n.t('septemberShort'),i18n.t('octoberShort'),i18n.t('novemberShort'),i18n.t('decemberShort')],
    dayNames: [i18n.t('sunday'),i18n.t('monday'),i18n.t('tuesday'),i18n.t('wednesday'),i18n.t('thursday'),i18n.t('friday'),i18n.t('saturday')],
    dayNamesShort: [i18n.t('sundayShort'),i18n.t('mondayShort'),i18n.t('tuesdayShort'),i18n.t('wednesdayShort'),i18n.t('thursdayShort'),i18n.t('fridayShort'),i18n.t('saturdayShort')],
    today: i18n.t('today')
  };
  LocaleConfig.defaultLocale = LoginManager.getInstance().language.toLowerCase(); //it could be gr/fr/whatever, as the values are already translated to the current language
  //-set calendar locale

  const [isLoading, setIsLoading] = useState(false);

  const [finishBtnDisabled, setFinishBtnDisabled] = useState<boolean>(false);                                
  const [messageToShow, setMessageToShow] = useState<string | void>('');  
  useEffect(() => {
    //console.log("1 - Start");
    if(messageToShow.length > 0)
    {
      var translatedMessageToShow: string = messageToShow;
      if(messageToShow === Generic_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
        translatedMessageToShow = i18n.t('reservationAddedSuccessfully');
      }
      else if(messageToShow === Studio_ConstManager.getInstance().CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG
              || messageToShow === Tennis_ConstManager.getInstance().CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG) {
        translatedMessageToShow = i18n.t('messageToBlockedPlayer');
      }
      else if(messageToShow === "Invalid date!") {
        translatedMessageToShow = i18n.t('invalidHours');
      }

      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        if(MyAlertReactNative != null) {
          // Works on both Android and iOS
          MyAlertReactNative.alert(
            translatedMessageToShow,
            '',
            [
              {
                text: 'OK',
                onPress: () => {
                  if(messageToShow === Generic_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
                    //handleRefresh();
                    //hideNewReservationModal();
                  }
                  setMessageToShow('');
                }
              }
            ],
            { 
              cancelable: false,
            }
          );
        }
        else if(MyAlertForWeb != null) {
          setAlertForWebText(translatedMessageToShow);
          setAlertForWebVisible(true);
        }
      }, 500);
    }
    //console.log("1 - End");
  }, [messageToShow]);

  const [visibleNewReservationDetailsModal, setVisibleNewReservationDetailsModal] = useState(false);
  const showNewReservationDetailsModal = () => { 
    //console.log("2 - Start");
    const tmpReservationValidityResult : ReservationValidityResult = UtilsManager.getInstance().checkIfReservationIsValid(allHoursOfSelectedDay, selectedTimeslots);
    if(tmpReservationValidityResult.resultOfCheck != "ok") {
      if(MyAlertReactNative != null)
        alert(tmpReservationValidityResult.resultOfCheck);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(tmpReservationValidityResult.resultOfCheck);
        setAlertForWebVisible(true);
      }
    }
    else {
      if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
        prepareReservationCostDetails_Studio();
        setNewReservation_Studio((prevState) => ({
          ...prevState,
          reservation_hour_start: tmpReservationValidityResult.startTime,
          reservation_hour_stop: tmpReservationValidityResult.stopTime
        }));
      }
      else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
        prepareReservationCostDetails_Tennis();
        setNewReservation_Tennis((prevState) => ({
          ...prevState,
          reservation_hour_start: tmpReservationValidityResult.startTime,
          reservation_hour_stop: tmpReservationValidityResult.stopTime
        }));
      }
      setVisibleNewReservationDetailsModal(true);
    }
    //console.log("2 - End");
  };
  const hideNewReservationDetailsModal = () => {
    //init
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      setMySelectedExistingReservation_Studio({...mySelectedExistingReservation_Studio, 
        index: -1,
        rehearsalRecording: "",
        hours: "",
        cost: "",
        comments : ""
      });
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      setMySelectedExistingReservation_Tennis({...mySelectedExistingReservation_Tennis, 
        index: -1,
        hours: "",
        cost: "",
        comments : ""
      });
    }
    setVisibleNewReservationDetailsModal(false);
  }

  const [selectedDayNum, setSelectedDayNum] = useState(-1);
  const [newReservationCostLabel, setNewReservationCostLabel] = useState('');

  //complete the array with all timeslots
  const [allHoursOfSelectedDay, setAllHoursOfSelectedDay] = useState<Array<string>>([]);
  const [allHoursOfSelectedDayItemsWithUniqueId, setAllHoursOfSelectedItemsWithUniqueId] = useState<Array<TimeslotWithID>>([]);
  let id = 0; //I need a unique identifier, which remains unique even if the list is reorder/modified
  useEffect(() => {
    //console.log("6 - Start");
    if(allHoursOfSelectedDay.length > 0) {
      const tmpItemsWithUniqueId = allHoursOfSelectedDay.map(tmpHour => ({
        id: id++,
        hour: tmpHour,
      }));
      setAllHoursOfSelectedItemsWithUniqueId(tmpItemsWithUniqueId);
    }
    else
        setAllHoursOfSelectedItemsWithUniqueId([]);
    //console.log("6 - End");
  }, [allHoursOfSelectedDay]);

  //here we have all the selected timeslots for the new reservation
  const [selectedTimeslots, setSelectedTimeslots] = useState<Array<boolean>>([]); //it will have exactly the same size with allHoursOfSelectedDay

  const checkBoxOnClick = useCallback((itemID : number) => {
    //console.log("7 - Start");
    setSelectedTimeslots((prevSelectedTimeslots) => {
      const newSelectedTimeslots = [...prevSelectedTimeslots];
      newSelectedTimeslots[itemID] = !newSelectedTimeslots[itemID];
      return newSelectedTimeslots;
    });
    //console.log("7 - End");
  }, [newReservation_Studio.reservation_date, newReservation_Tennis.reservation_date]);

  const completeReservation = async () => {
  //const completeReservation = useCallback(async () => {
    //console.log("8 - Start - new reservation to be added:");
    //console.log(JSON.stringify(newReservation, null, 2));
    setFinishBtnDisabled(true);
    //setIsLoading(true);
    let webServiceResponseStr : string | void = "";
    ////////////
    // STUDIO //
    ////////////
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      webServiceResponseStr = await Studio_RestManager.getInstance().addReservation(newReservation_Studio);
      if(webServiceResponseStr == Studio_ConstManager.getInstance().PLAYER_HAS_ANOTHER_RESERVATION_AT_THIS_TIME_MSG) {
        setFinishBtnDisabled(false);
        if(MyAlertReactNative != null)
          alert(i18n.t("youHaveAnotherReservationAtThisTime"));
        else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t("youHaveAnotherReservationAtThisTime"));
          setAlertForWebVisible(true);
        }
      } 
      else if(webServiceResponseStr == Studio_ConstManager.getInstance().COURT_ALREADY_RESERVED_MSG
              || webServiceResponseStr == Studio_ConstManager.getInstance().CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG
              || webServiceResponseStr == Studio_ConstManager.getInstance().INVALID_DATE_MSG
              || webServiceResponseStr == Studio_ConstManager.getInstance().INTRUDER_MSG) {
        setFinishBtnDisabled(false);
        if(MyAlertReactNative != null)
          alert(i18n.t("internalError"));
        else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t("internalError"));
          setAlertForWebVisible(true);
        }
      }
      else if(webServiceResponseStr == Studio_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
        setReservationCompletionMsg(i18n.t("reservationAddedSuccessfully"));
      }
    }
    ////////////
    // TENNIS //
    ////////////
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      webServiceResponseStr = await Tennis_RestManager.getInstance().addReservation(newReservation_Tennis);
      console.log("1");
      if(webServiceResponseStr == Tennis_ConstManager.getInstance().PLAYER_HAS_ANOTHER_RESERVATION_AT_THIS_TIME_MSG) {
        console.log("2");
        setFinishBtnDisabled(false);
        if(MyAlertReactNative != null)
          alert(i18n.t("youHaveAnotherReservationAtThisTime"));
        else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t("youHaveAnotherReservationAtThisTime"));
          setAlertForWebVisible(true);
        }
      } 
      else if(webServiceResponseStr == Tennis_ConstManager.getInstance().COURT_ALREADY_RESERVED_MSG
              || webServiceResponseStr == Tennis_ConstManager.getInstance().CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG
              || webServiceResponseStr == Tennis_ConstManager.getInstance().INVALID_DATE_MSG
              || webServiceResponseStr == Tennis_ConstManager.getInstance().INTRUDER_MSG) {
                console.log("3");
        setFinishBtnDisabled(false);
        if(MyAlertReactNative != null)
          alert(i18n.t("internalError"));
        else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t("internalError"));
          setAlertForWebVisible(true);
        }
      }
      else if(webServiceResponseStr == Tennis_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
        console.log("4");
        setReservationCompletionMsg(i18n.t("reservationAddedSuccessfully"));
      }
      console.log("5");
    }

    //setIsLoading(false);
    //setReservationCompletionMsg(webServiceResponseStr);
    //setMessageToShow(webServiceResponseStr);
    //console.log("8 - End");
  }  

  const [selectedDate_YYYY_pavla_MM_pavla_DD, setSelectedDate_YYYY_pavla_MM_pavla_DD] = useState(moment().format('YYYY-MM-DD').toString());
  useEffect(() => {
    console.log("useEffect - selectedDate_YYYY_pavla_MM_pavla_DD - START -> selectedDate_YYYY_pavla_MM_pavla_DD: ", selectedDate_YYYY_pavla_MM_pavla_DD);
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      setNewReservation_Studio({...newReservation_Studio, reservation_date: UtilsManager.getInstance().transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes(selectedDate_YYYY_pavla_MM_pavla_DD)});
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      setNewReservation_Tennis({...newReservation_Tennis, reservation_date: UtilsManager.getInstance().transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes(selectedDate_YYYY_pavla_MM_pavla_DD)});
    }
    console.log("useEffect - selectedDate_YYYY_pavla_MM_pavla_DD - END");
  }, [selectedDate_YYYY_pavla_MM_pavla_DD]);
////////////////////////////////
// Generic, All domains - END //
////////////////////////////////

// Create a ref for the TextInput components
const commentsInputRef_Studio = useRef(null);
const commentsInputRef_Tennis = useRef(null);

    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <ScrollView>
          <Spinner
            visible={isLoading}
            textContent={i18n.t('loading')}
            textStyle={styles.spinnerTextStyle}
          />
          <Appbar.Header>
            <Appbar.Content title={i18n.t('newReservation')} />
          </Appbar.Header>
          <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{i18n.t('selectDateTime')}</Title>
          <Divider/>
          <RNCalendar 
            markedDates={{
              [selectedDate_YYYY_pavla_MM_pavla_DD]: { //reservation_date is DD/MM/YYY and I want in the calendar to have YYYY-MM-DD
                selected: true,
                //disableTouchEvent: true,
                selectedColor: 'black',
                selectedTextColor: 'white'
              }
            }}            
            
            // Collection of dates that have to be colored in a special way. Default = {}
            //markedDates={{
            //  selectedDateStr: {selected: true, marked: true, selectedColor: 'blue'}
            //}}
            // Date marking style [simple/period/multi-dot/custom]. Default = 'simple'
            //markingType={'period'}

            // Handler which gets executed on day press. Default = undefined
            //onDayPress=(day) => console.log('selected day', day)
            // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
            monthFormat='MMMM yyyy'
            
            // Handler which gets executed on day press. Default = undefined
            onDayPress={(day) => { //setSelectedDateObj(day)
              //console.log("day: ", day);
              setSelectedDate_YYYY_pavla_MM_pavla_DD(day.dateString);
            }}
            // Handler which gets executed on day long press. Default = undefined
            //onDayLongPress={(day) => {console.log('selected day: ', day)}}
            // Handler which gets executed when visible month changes in calendar. Default = undefined
            onMonthChange={(month) => {/*console.log('month changed:  ', month)*/}}
            // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday.
            firstDay={1}
            // Handler which gets executed when press arrow icon left. It receive a callback can go back month
            onPressArrowLeft={subtractMonth => subtractMonth()}
            // Handler which gets executed when press arrow icon right. It receive a callback can go next month
            onPressArrowRight={addMonth => addMonth()}
            // Enable the option to swipe between months. Default = false
            enableSwipeMonths={true}
            //disable previous (than today) dates
            minDate={moment().format('YYYY-MM-DD').toString()}
            //max: 12 months from today
            maxDate={UtilsManager.getInstance().tranformDateToISOFormat(UtilsManager.getInstance().getLastDayOfNextNextMonth())}
            // Max amount of months allowed to scroll to the past. Default = 50
            pastScrollRange={0}
            // Max amount of months allowed to scroll to the future. Default = 50
            futureScrollRange={2}
            disableArrowsOnDisableDays={true}
            disableSwipeOnDisableDays={true}
          />
          <Divider/>
          <DataTable>
              <DataTable.Row style={styles.tableRow}>
              <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
              {selectedDomainValue == LoginManager.getInstance().STUDIO && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.reservation_date}</DataTable.Cell>}
              {selectedDomainValue == LoginManager.getInstance().TENNIS && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Tennis.reservation_date}</DataTable.Cell>}
              </DataTable.Row>
          </DataTable>
          <Divider/>
          {selectedDomainValue == LoginManager.getInstance().STUDIO && <RadioButton.Group 
              onValueChange={newValue => { 
                  if(newValue === 'rehearsal')
                    setNewReservation_Studio({...newReservation_Studio,
                      rehearsal: true
                    });
                  else if(newValue === 'recording') 
                    setNewReservation_Studio({...newReservation_Studio,
                      rehearsal: false
                    });
              }} 
              value={newReservation_Studio.rehearsal==true ? 'rehearsal' : 'recording'}>
              {ownerExtraInfo_Studio.allows_rehearsal==true && <RadioButton.Item
                key={0}
                label={i18n.t('rehearsal')}
                value={'rehearsal'}
                status={ newReservation_Studio.rehearsal === true ? 'checked' : 'unchecked' }
              />}
              {ownerExtraInfo_Studio.allows_recording==true && <RadioButton.Item
                key={1}
                label={i18n.t('recording')}
                value={'recording'}
                status={ newReservation_Studio.rehearsal === false ? 'checked' : 'unchecked' }
              />}
          </RadioButton.Group>}
          {selectedDomainValue == LoginManager.getInstance().STUDIO && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('selectRoom')}</Title>}
          {selectedDomainValue == LoginManager.getInstance().TENNIS && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('selectCourt')}</Title>}
          {selectedDomainValue == LoginManager.getInstance().STUDIO && <RadioButton.Group onValueChange={tmpSelectedRoomIndex => setSelectedRoomIndexInRoomsArray(parseInt(tmpSelectedRoomIndex))} value={selectedRoomIndexInRoomsArray.toString()}>
              {allRoomsOfCurOwner_Studio.map((room: Studio_Room, i) => (
                  <RadioButton.Item
                      key={room.id}
                      label={room.name}
                      value={i.toString()}
                      status={selectedRoomIndexInRoomsArray == i ? 'checked' : 'unchecked'} />
              ))}      
          </RadioButton.Group>}
          {selectedDomainValue == LoginManager.getInstance().TENNIS && <RadioButton.Group onValueChange={tmpSelectedCourtIndex => setSelectedCourtIndexInCourtsArray(parseInt(tmpSelectedCourtIndex))} value={selectedCourtIndexInCourtsArray.toString()}>
              {allCourtsOfCurOwner_Tennis.map((court: Tennis_Court, i) => (
                  <RadioButton.Item
                      key={court.id}
                      label={court.name}
                      value={i.toString()}
                      status={selectedCourtIndexInCourtsArray == i ? 'checked' : 'unchecked'} />
              ))}      
          </RadioButton.Group>}
          <Fragment>
              {allHoursOfSelectedDayItemsWithUniqueId.map(item => {
                  //getTimeslotStatus takes as input: 
                  //      1) the starting hout of a misawro, 
                  //      2) AllReservationsOfThisRoomForThisDate: Array<Reservation>  
                  // kai epistrefei ena apo ta parakatw strings (opou i einai to index sto array allReservationsOfThisRoomForThisDate): 
                  //          "*STATUS*free*INDEX*-1"
                  //          "*STATUS*reservedByThisPlayer*INDEX*" + i.toString()
                  //          "*STATUS*reservedByTheOwnerOfTheRoom*INDEX*" + i.toString();
                  //          "*STATUS*reservedByOtherPlayer*INDEX*" + i.toString();
                  let statusFullStr : string = "";
                  if(selectedDomainValue == LoginManager.getInstance().STUDIO)
                    statusFullStr = UtilsManager.getInstance().getTimeslotStatus_Studio(item.hour, allReservationsOfThisRoomForThisDate);
                  else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
                    statusFullStr = UtilsManager.getInstance().getTimeslotStatus_Tennis(item.hour, allReservationsOfThisCourtForThisDate);
                  const finalStatusStr : string = statusFullStr.substring(statusFullStr.indexOf("*STATUS*")+8, statusFullStr.indexOf("*INDEX*"));
                  console.log("finalStatusStr: " + finalStatusStr);
                  const reservationIndexInArrayStr : string = statusFullStr.substring(statusFullStr.indexOf("*INDEX*")+7, statusFullStr.length);
                  
                  return (
                      <View style={{flexDirection:"row"}} key={item.id}>
                          <Checkbox.Item
                              label={item.hour + " - " + UtilsManager.getInstance().getNextTimeslotStr(item.hour)}  
                              disabled={finalStatusStr=="free" ? false : true}                  
                              status={selectedTimeslots[item.id] ? 'checked' : 'unchecked'}
                              onPress={() => checkBoxOnClick(item.id)}
                              color="white"
                              style={{width: Platform.OS!='web' ? screenDimensions.width/2 : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*0.4, backgroundColor: selectedTimeslots[item.id] ? 'green' : 'lightgrey' }}
                          />
                          <View style={{backgroundColor: finalStatusStr=="free" ? 'green' : finalStatusStr=="reservedByThisPlayer" ? 'lightgrey' : 'red'}}>
                              {finalStatusStr=="free" && <Text style={styles.statusLabel}>{i18n.t('free')}</Text>}
                              {finalStatusStr=="reservedByOtherPlayer" && <Text style={styles.statusLabel}>{i18n.t('reserved')}</Text>}
                              {selectedDomainValue==LoginManager.getInstance().STUDIO && (finalStatusStr=="reservedByThisPlayer" || (finalStatusStr=="reservedByTheOwnerOfTheRoom" && LoginManager.getInstance().username.toLowerCase()==curOwner.email.toLowerCase())) && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="information-outline" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButtonSmall} contentStyle={styles.iconButtonContentSmall} style={{width: Platform.OS!='web' ? screenDimensions.width/2 : Generic_ConstManager.getInstance().WEB_MAX_WIDTH*0.3}} onPress={() => setMySelectedExistingReservation_Studio({...mySelectedExistingReservation_Studio, index : parseInt(reservationIndexInArrayStr) })}>{i18n.t('reservedByYou')}</Button>}
                              {selectedDomainValue==LoginManager.getInstance().TENNIS && (finalStatusStr=="reservedByThisPlayer" || (finalStatusStr=="reservedByTheOwnerOfTheRoom" && LoginManager.getInstance().username.toLowerCase()==curOwner.email.toLowerCase())) && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="information-outline" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButtonSmall} contentStyle={styles.iconButtonContentSmall} style={{width: Platform.OS!='web' ? screenDimensions.width/2 : Generic_ConstManager.getInstance().WEB_MAX_WIDTH*0.3}} onPress={() => setMySelectedExistingReservation_Tennis({...mySelectedExistingReservation_Tennis, index : parseInt(reservationIndexInArrayStr) })}>{i18n.t('reservedByYou')}</Button>}
                              {finalStatusStr=="reservedByTheOwnerOfTheRoom" && LoginManager.getInstance().username.toLowerCase()!=curOwner.email.toLowerCase() && <Text style={styles.statusLabel}>{i18n.t('reserved')}</Text>}
                          </View>
                      </View>
              )})}
          </Fragment>
          {selectedDomainValue == LoginManager.getInstance().STUDIO && <View style={{ display: curSelectedRoomOpenAtSelectedDay ? 'flex' : 'none' }}>
            <TouchableOpacity onPress={() => commentsInputRef_Studio.current.focus()}>
              <TextInput
                ref={commentsInputRef_Studio}
                label={i18n.t('commentsOptional')}
                value={newReservation_Studio.comments}
                onChangeText={tmpComments => setNewReservation_Studio({...newReservation_Studio,
                  comments: tmpComments
              })} />   
            </TouchableOpacity>        
            <Button icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" onPress={showNewReservationDetailsModal} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{i18n.t('continue')}</Button>
          </View>}
          {selectedDomainValue == LoginManager.getInstance().STUDIO && <Text style={[styles.closedLabel, { display: curSelectedRoomOpenAtSelectedDay==false && isLoading==false ? 'flex' : 'none' }]}>{i18n.t('closed')}</Text>}

          {selectedDomainValue == LoginManager.getInstance().TENNIS && <View style={{ display: curSelectedCourtOpenAtSelectedDay ? 'flex' : 'none' }}>
            <TouchableOpacity onPress={() => commentsInputRef_Tennis.current.focus()}>
              <TextInput
                ref={commentsInputRef_Tennis}
                label={i18n.t('commentsOptional')}
                value={newReservation_Tennis.comments}
                onChangeText={tmpComments => setNewReservation_Tennis({...newReservation_Tennis,
                  comments: tmpComments
              })} />      
            </TouchableOpacity>  
            <Button icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" onPress={showNewReservationDetailsModal} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{i18n.t('continue')}</Button>
          </View>}
          {selectedDomainValue == LoginManager.getInstance().TENNIS && <Text style={[styles.closedLabel, { display: curSelectedCourtOpenAtSelectedDay==false && isLoading==false ? 'flex' : 'none' }]}>{i18n.t('closed')}</Text>}

          <Portal>
            <Modal visible={visibleNewReservationDetailsModal} onDismiss={hideNewReservationDetailsModal} contentContainerStyle={styles.modalContainer}>
              <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
                <ScrollView>
                  <Appbar.Header>
                    <Appbar.Content title={i18n.t('reservationDetails')} />
                  </Appbar.Header>
                  <Divider/>
                  <DataTable>
                    {selectedDomainValue == LoginManager.getInstance().STUDIO && <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('rehearsalRecording')}</DataTable.Title>
                      {mySelectedExistingReservation_Studio.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.rehearsal ? i18n.t('rehearsal') : i18n.t('recording')}</DataTable.Cell>}
                      {mySelectedExistingReservation_Studio.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Studio.rehearsalRecording}</DataTable.Cell>}
                    </DataTable.Row>}
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('companyName')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{curOwner.company_name}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      {selectedDomainValue == LoginManager.getInstance().STUDIO && <DataTable.Title style={styles.tableTitle}>{i18n.t('room')}</DataTable.Title>}
                      {selectedDomainValue == LoginManager.getInstance().STUDIO && <DataTable.Cell style={styles.tableValueColumn}>{selectedRoom.name}</DataTable.Cell>}

                      {selectedDomainValue == LoginManager.getInstance().TENNIS && <DataTable.Title style={styles.tableTitle}>{i18n.t('tennisCourt')}</DataTable.Title>}
                      {selectedDomainValue == LoginManager.getInstance().TENNIS && <DataTable.Cell style={styles.tableValueColumn}>{selectedCourt.name}</DataTable.Cell>}
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
                      {selectedDomainValue == LoginManager.getInstance().STUDIO && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.reservation_date}</DataTable.Cell>}
                      {selectedDomainValue == LoginManager.getInstance().TENNIS && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Tennis.reservation_date}</DataTable.Cell>}
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('hours')}</DataTable.Title>
                      {selectedDomainValue==LoginManager.getInstance().STUDIO && mySelectedExistingReservation_Studio.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.reservation_hour_start} - {newReservation_Studio.reservation_hour_stop}</DataTable.Cell>}
                      {selectedDomainValue==LoginManager.getInstance().STUDIO && mySelectedExistingReservation_Studio.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Studio.hours}</DataTable.Cell>}

                      {selectedDomainValue==LoginManager.getInstance().TENNIS && mySelectedExistingReservation_Tennis.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Tennis.reservation_hour_start} - {newReservation_Tennis.reservation_hour_stop}</DataTable.Cell>}
                      {selectedDomainValue==LoginManager.getInstance().TENNIS && mySelectedExistingReservation_Tennis.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Tennis.hours}</DataTable.Cell>}
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{selectedDomainValue==LoginManager.getInstance().TENNIS && iAmMemberOfTheClub_Tennis==true ? i18n.t('costForGuests') : i18n.t('totalCost')}</DataTable.Title>
                      {selectedDomainValue==LoginManager.getInstance().STUDIO && mySelectedExistingReservation_Studio.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservationCostLabel}</DataTable.Cell>}
                      {selectedDomainValue==LoginManager.getInstance().STUDIO && mySelectedExistingReservation_Studio.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Studio.cost}</DataTable.Cell>}
                      
                      {selectedDomainValue==LoginManager.getInstance().TENNIS && mySelectedExistingReservation_Tennis.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservationCostLabel}</DataTable.Cell>}
                      {selectedDomainValue==LoginManager.getInstance().TENNIS && mySelectedExistingReservation_Tennis.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Tennis.cost}</DataTable.Cell>}
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('comments')}</DataTable.Title>
                      {selectedDomainValue==LoginManager.getInstance().STUDIO && mySelectedExistingReservation_Studio.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.comments}</DataTable.Cell>}
                      {selectedDomainValue==LoginManager.getInstance().STUDIO && mySelectedExistingReservation_Studio.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Studio.comments}</DataTable.Cell>}

                      {selectedDomainValue==LoginManager.getInstance().TENNIS && mySelectedExistingReservation_Tennis.index==-1 && <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Tennis.comments}</DataTable.Cell>}
                      {selectedDomainValue==LoginManager.getInstance().TENNIS && mySelectedExistingReservation_Tennis.index!=-1 && <DataTable.Cell style={styles.tableValueColumn}>{mySelectedExistingReservation_Tennis.comments}</DataTable.Cell>}
                    </DataTable.Row>
                  </DataTable>
                  {(mySelectedExistingReservation_Studio.index==-1 && mySelectedExistingReservation_Tennis.index==-1) && <Button disabled={finishBtnDisabled} icon={({ color, size }) => (<MaterialCommunityIcons name="calendar" color={color} size={size} />)} mode="contained" onPress={completeReservation} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{i18n.t('completeReservation')}</Button>}
                </ScrollView>
              </TouchableWithoutFeedback>
            </Modal>
          </Portal>
          {MyAlertForWeb!=null && 
            <View style={{ alignContent: "flex-end" }}>
              <MyAlertForWeb
                contentContainerStyle={{ marginBottom: 400 }}
                show={alertForWebVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForWebText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForWebVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForWebVisible(false);
                }}  
              />
            </View>
          }
        </ScrollView>
      </TouchableWithoutFeedback>
    );
  };

  export default React.memo(PlayerNewReservationModal);