import React, { useState, useEffect, useRef } from 'react';
import { Appbar, Button } from 'react-native-paper';
import { Alert, Dimensions, KeyboardAvoidingView, Platform, View, Image } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import * as Location from 'expo-location';
import GUIManager from '../../managers/GUIManager';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import AdBanner from '../generic/AdBanner';
import styles from '../../css/myCss';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import _ from 'lodash';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//maps
  //react native
  import MapView from 'react-native-maps';
  //web
  import { MapContainer as WebMap, TileLayer as WebTileLayer, Marker as WebMarker, Popup as WebPopup, Tooltip as WebTooltip, useMapEvents } from 'react-leaflet';
  import L from 'leaflet';
  import axios from 'axios';
  //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
  import 'leaflet/dist/leaflet.css'; //important - if it's not loaded, the map tiles are not shown correctly
import Generic_RestManager from '../../managers/Generic_RestManager';
import GenericConstManager from '../../managers/Generic_ConstManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import LeftColumnWeb from '../generic/LeftColumnWeb';
import RightColumnWeb from '../generic/RightColumnWeb';

let MyMapViewForReactNative;
let MyMapViewForWeb;

  const zoomLevel = 12;


if (Platform.OS === 'web') {  //WEB
  MyMapViewForWeb = true;
  MyMapViewForReactNative = null;
} else {                      //REACT NATIVE
  MyMapViewForWeb = false;
  MyMapViewForReactNative = MapView;
}
//-maps

const myMarkerImg =  require("../../assets/marker.png");

const longitudeOffset = 0;//0.0344;  //oso to ayksanw, o xarths kineitai pros ta aristera (pineza pros ta deksia)
const latitudeOffset = 0;//-0.0476;  //oso to meiwnw (ayksanw thn arnhtikh timh), o xarths kineitai pros ta panw (pineza pros ta katw)
const deltaOffset = 0.004;       //oso to ayksanw, meiwnei zoom

const screenDimensions = Dimensions.get('window');

const OwnerPinOnMapView = () => {
  const navigation = useNavigation();

  const webMapRef = useRef(null);
  
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const markerLatitudeDelta = 0.009;
  const markerLongitudeDelta = 0.009;
  const markerLatitudeOffset = 0.0;
  const markerLongitudeOffset = 0.0;
  const myLocationLatitudeOffset = -0.0022;
  const myLocationLongitudeOffset = 0.0;

  const [initialPositionBasedOnIP, setInitialPositionBasedOnIP] = useState<{ latitude: number; longitude: number }>({ latitude: 40.60289, longitude: 22.96243 }); // Default: Thessaloniki
  useEffect(() => {
    console.log("initialPositionBasedOnIP - useEffect: ", initialPositionBasedOnIP);
  }, [initialPositionBasedOnIP]);

  const [signUpMode, setSignUpMode] = useState(false);

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerPinOnMapView: I'm ready!");
    (async () => {
      setIsLoading(true);
      
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
      
      //registered owner -> My profile -> Edit location
      if(LoginManager.getInstance().loggedInOwner!=null && LoginManager.getInstance().loggedInOwner.latitude!=-1 && LoginManager.getInstance().loggedInOwner.longitude!=-1) {
        GUIManager.getInstance().myLatitude = LoginManager.getInstance().loggedInOwner.latitude;
        GUIManager.getInstance().myLongitude = LoginManager.getInstance().loggedInOwner.longitude;
        
        setMyLocation({...myLocation,
          latitude: LoginManager.getInstance().loggedInOwner.latitude,
          longitude: LoginManager.getInstance().loggedInOwner.longitude
        }); 
      }
      //during sign up
      else {  
        if(Platform.OS === 'web') { 
          console.log("...trying to get coordinates from IP...")
          let tmpCoords = await Generic_RestManager.getInstance().getUserGeolocationBasedOnIP("188.117.197.175"); /* TODO: FIND USER IP!!! */
          setInitialPositionBasedOnIP(tmpCoords);
          GUIManager.getInstance().myLatitude = tmpCoords.latitude;
          GUIManager.getInstance().myLongitude = tmpCoords.longitude;
          LoginManager.getInstance().loggedInOwner.latitude = parseFloat(tmpCoords.latitude.toFixed(6));
          LoginManager.getInstance().loggedInOwner.longitude = parseFloat(tmpCoords.longitude.toFixed(6));
          console.log("...trying to get coordinates from IP - DONE!");

          sessionStorage.setItem('storedLatitude', tmpCoords.latitude.toFixed(6));
          sessionStorage.setItem('storedLongitude', tmpCoords.longitude.toFixed(6));

          setMyLocation({...myLocation,
            latitude: parseFloat(tmpCoords.latitude.toFixed(6)),
            longitude: parseFloat(tmpCoords.longitude.toFixed(6))
          });  
        }
        else {
          //check GPS permissions
          let { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== 'granted') {
            //alert('Permission to access location was denied');
            return;
          }

          let location = await Location.getCurrentPositionAsync({});
          setMyLocation({...myLocation,
            latitude: location.coords.latitude,
            longitude: location.coords.longitude
          });
        }
      }

      //find if we are in sign up mode or in edit profile mode
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio = await Studio_RestManager.getInstance().getAllRoomsOfOwner(LoginManager.getInstance().ID) as Array<Studio_Room>;
        if(GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.length === 0) {
          console.log("[getAllRoomsOfOwner()] -> O owner DEN exei rooms");
          setSignUpMode(true);
        }
        else {
          console.log("[getAllRoomsOfOwner()] -> O owner exei rooms");
        }
      } 
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis = await Tennis_RestManager.getInstance().getAllCourtsOfOwner(LoginManager.getInstance().ID) as Array<Tennis_Court>;
        if(GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.length === 0) {
          console.log("[getAllCourtsOfOwner()] -> O owner DEN exei courts");
          setSignUpMode(true);
        }
        else {
          console.log("[getAllCourtsOfOwner()] -> O owner exei courts");
        }
      }

      //alert('my location (found now): ' + JSON.stringify(location));

      /*var tmpOwner : Owner = { id: -1, name:'', surname: '', telephone: '', email: '', password: '', company_name: '', postal_address: '', city: '', country: '',
                                longitude: -1.0, latitude: -1.0, website: '', last_login: '', language: '', email_notifications: true, registration_date: '', 
                                verified: false, active: false, info_source: ''};
      if(selectedDomainValue == LoginManager.getInstance().STUDIO)
        tmpOwner = await Studio_RestManager.getInstance().getOwner(LoginManager.getInstance().username);
      else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
        tmpOwner = await Tennis_RestManager.getInstance().getOwner(LoginManager.getInstance().username);

      LoginManager.getInstance().loggedInOwner.id = tmpOwner.id;
      setCurOwner(tmpOwner);*/
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);

  const [myLocation, setMyLocation] = useState({
    latitude: 0.0,
    longitude: 0.0
  });
  useEffect(() => {
    if(myLocation.latitude!=0 && myLocation.longitude!=0) {
      setRegion({ ...region, latitude: myLocation.latitude, longitude: myLocation.longitude, latitudeDelta: 0.009, longitudeDelta: 0.009});
    }
  }, [JSON.stringify(myLocation)]);

  const [region, setRegion] = useState({
    latitude: 0.0,
    longitude: 0.0,
    latitudeDelta: 0.009,
    longitudeDelta: 0.009
  });
  useEffect(() => {
    if(region.latitude!=0 && region.longitude!=0) {
      console.log("New coordinates -> lat: " + region.latitude + ", lon: " + region.longitude);

      GUIManager.getInstance().myLatitude = region.latitude;
      GUIManager.getInstance().myLongitude = region.longitude;

      setCurOwner({...curOwner, latitude: region.latitude, longitude: region.longitude});

      //save latitude & longitude locally
      if(Platform.OS != 'web') {
        SecureStore.setItemAsync('storedLatitude', JSON.stringify(region.latitude)).catch((error) => console.log('Error during storedLatitude storage!', error));
        SecureStore.setItemAsync('storedLongitude', JSON.stringify(region.longitude)).catch((error) => console.log('Error during storedLongitude storage!', error));
      }
      else {  //web
        sessionStorage.setItem('storedLatitude', region.latitude.toFixed(6));
        sessionStorage.setItem('storedLongitude', region.longitude.toFixed(6));
      }
    }
  }, [JSON.stringify(region)]);

  const [curOwner, setCurOwner] = useState<Owner>(LoginManager.getInstance().loggedInOwner);
  useEffect(() => {
    console.log("curOwner: ", JSON.stringify(curOwner, null, 2));
    setIsLoading(false);
  }, [JSON.stringify(curOwner)]);

  const [verifyBtnDisabled, setVerifyBtnDisabled] = useState<boolean>(false);
  async function saveCoordinates() {
    setIsLoading(true);      
    setVerifyBtnDisabled(true); //disable button to avoid pressed again while loading 
    var tmpEditedOwner: Owner = { id: -1, name:'', surname: '', telephone: '', email: '', password: '', company_name: '', postal_address: '', city: '', country: '',
                                  longitude: -1.0, latitude: -1.0, website: '', last_login: '', language: '', email_notifications: true, registration_date: '', 
                                  verified: false, active: false, info_source: ''};
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      tmpEditedOwner = await Studio_RestManager.getInstance().editOwner(curOwner);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      tmpEditedOwner = await Tennis_RestManager.getInstance().editOwner(curOwner);
    console.log("tmpEditedOwner: ", JSON.stringify(tmpEditedOwner, null, 2));
    LoginManager.getInstance().loggedInOwner = tmpEditedOwner;
    setIsLoading(false);
    if(MyAlertReactNative != null) {
      MyAlertReactNative.alert(i18n.t('congratulations'), i18n.t('addressVerified'), [{ text: "OK", 
        onPress: () => {
          if(signUpMode == true) { //during sign up
            if(LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS 
                && LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members==true
                && LoginManager.getInstance().loggedInTennisOwner_memberships.length == 0){
              navigation.reset({
                index: 1,
                routes: [{ name: 'OwnerAddTennisClubMemberships' }]
                //key: null
              });
            }
            else {
              navigation.reset({
                index: 1,
                routes: [{ name: 'OwnerAddFirstRoom' }]
              });
            }
          }
          else {  //registered owner -> My profile -> Edit location
            //do nothing
          }
        }
      }] );
    }
    else if(MyAlertForWeb != null){
      setAlertForWebText(i18n.t('addressVerified'));
      setAlertForWebVisible(true);
    }
  }

  const debouncedOnRegionChangeComplete = _.debounce((region) => setRegion(region), 3000); //update it once in 3 sec
  const onWebMapMove = (event) => {
    // Log the center coordinates when the map is moved
    const newCenter = event.target.getCenter();
    const newLat = parseFloat(newCenter.lat.toFixed(6));
    const newLon = parseFloat(newCenter.lng.toFixed(6));
    console.log('Web Map - Center Coordinates:', newLat, newLon);
    setRegion({...region, 
      latitude: newLat,
      longitude: newLon
    });
  };

  const WebMapEvents = () => {
    const map = useMapEvents({
      move: onWebMapMove,
    });

    return null;
  };

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
          <LeftColumnWeb/>
          <View style={[styles.columnMainApp, { alignContent: 'center' }]}>
            <Appbar.Header>
              <Appbar.Content title={i18n.t('verifyAddress')} />
            </Appbar.Header>
            <Spinner
              visible={isLoading}
              textContent={i18n.t('loading')}
              textStyle={styles.spinnerTextStyle}
            />        
            {MyMapViewForReactNative!=null && 
              <MyMapViewForReactNative style={styles.mapStyleOwnerPinOnMap} 
                region={region}
                showsUserLocation={true}
                userLocationAnnotationTitle={i18n.t('myLocation')}
                onRegionChangeComplete={debouncedOnRegionChangeComplete}
              >                
              </MyMapViewForReactNative>
            }
            {MyMapViewForWeb==true && 
              <View>
                <WebMap ref={webMapRef} key={JSON.stringify(initialPositionBasedOnIP)} 
                        center={[initialPositionBasedOnIP.latitude, initialPositionBasedOnIP.longitude]} 
                        zoom={zoomLevel} 
                        style={styles.mapStyleOwnerPinOnMapSignUp}>
                  <WebTileLayer
                    attribution='&copy; <a href="https://tile.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <WebMapEvents />
                </WebMap>
              </View>
            }
            <View style={styles.markerContainer}>
              <View style={styles.markerContainerChildOwnerPinOnMap}>
                <Image
                  source={myMarkerImg}
                  style={{width: Platform.OS!='web' ? screenDimensions.width/5 : GenericConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/5, 
                          height: Platform.OS!='web' ? (screenDimensions.width/5)*(640/630) : (GenericConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH/5)*(640/630) //height/width
                        }} 
                />
              </View>
            </View>
            <Button disabled={verifyBtnDisabled} icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => saveCoordinates()}>{i18n.t('verifyAddress')}</Button>
            <AdBanner/>
            {MyAlertForWeb!=null && 
              <MyAlertForWeb
                show={alertForWebVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForWebText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForWebVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForWebVisible(false);

                  if(signUpMode == true) { //during sign up
                    if(LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS 
                        && LoginManager.getInstance().loggedInTennisOwner_allows_reservations_to_members==true
                        && LoginManager.getInstance().loggedInTennisOwner_memberships.length == 0){
                      navigation.reset({
                        index: 1,
                        routes: [{ name: 'OwnerAddTennisClubMemberships' }]
                        //key: null
                      });
                    }
                    else {
                      navigation.reset({
                        index: 1,
                        routes: [{ name: 'OwnerAddFirstRoom' }]
                      });
                    }
                  }
                  else {  //registered owner -> My profile -> Edit location
                    //do nothing
                  }
                }}  
              />
            }
          </View>
          <RightColumnWeb/>
        </View>
      </KeyboardAvoidingView>
    );
  };

export default OwnerPinOnMapView;
