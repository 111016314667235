import React, { useState, useEffect, useRef } from 'react';
import { Appbar, TextInput, Button, Divider, Title, Subheading, RadioButton } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import AdBanner from '../generic/AdBanner';
import styles from '../../css/myCss';
import UtilsManager from '../../managers/UtilsManager';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';
import Generic_ConstManager from '../../managers/Generic_ConstManager';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RightColumnWeb from './RightColumnWeb';
import LeftColumnWeb from './LeftColumnWeb';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const ForgotPasswordView = () => {
  const navigation = useNavigation();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ForgotPasswordView: I'm ready!");
  }, [alreadyRunOnceOnViewLoad]);

  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow!='') {
      var translatedMessageToShow: string = messageToShow;
      if(messageToShow === "key sent")
        translatedMessageToShow = i18n.t('keySent');
      else if(messageToShow === "Already sent!")
        translatedMessageToShow = i18n.t('keyAlreadySent');      
      else if(messageToShow === Studio_ConstManager.getInstance().INVALID_USERNAME_OR_KEY
              || messageToShow === Tennis_ConstManager.getInstance().INVALID_USERNAME_OR_KEY)
        translatedMessageToShow = i18n.t('invalidEmailOrKey');
      else if(messageToShow === "password has changed")
        translatedMessageToShow = i18n.t('passwordHasChanged');
      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        // Works on both Android and iOS
        if(MyAlertReactNative != null) {
          MyAlertReactNative.alert(
            translatedMessageToShow,
            '',
            [
              {
                text: 'OK',
                onPress: () => {
                  if(messageToShow === "password has changed") {
                    //store user email/password locally on the device
                    SecureStore.setItemAsync('storedEmail', JSON.stringify(email)).catch((error) => console.log('Error during storedEmail storage!', error));
                    SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(newPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));

                    navigation.reset({
                      index: 1,
                      routes: [{ name: 'HandyBooker_Home' }]
                      //key: null
                    });
                  }
                  
                  //setMessageToShow('');
                }
              }
            ],
            { cancelable: false }
          );
        }
        else if(MyAlertForWeb != null){
            setAlertForWebText(translatedMessageToShow);
            setAlertForWebVisible(true);
        }
      }, 500);
    }
  }, [isLoading]);

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  useEffect(() => {
    (async () => {
      if(Platform.OS == "web") {
        const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(newPassword);
        console.log('Digest: ', digest);
        setNewPasswordMD5Encoded(digest);
      }
      else { //mobile
        const digest = await UtilsManager.getInstance().encodeWithMD5(newPassword);
        setNewPasswordMD5Encoded(digest);
      }
    })();
  }, [newPassword]);
  const [newPasswordMD5Encoded, setNewPasswordMD5Encoded] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [key, setKey] = useState('');

  const [forgotPasswordMode, setForgotPasswordMode] = useState(Generic_ConstManager.getInstance().I_WANT_A_KEY);

  async function requestKeyAsync() 
  {
    if(email === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('pleaseCompleteEmail'));
      else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('pleaseCompleteEmail'));
          setAlertForWebVisible(true);
      }
    }
    else {
      setIsLoading(true);       

      //public static final int ENGLISH = 0;
      //public static final int GREEK = 1;
      var tmpLangNum: number = 0;
      if(LoginManager.getInstance().language === "GR")
        tmpLangNum = 1;

      //to domain toy xrhsth DEN TO KSERW, opote kalw sthn tyxh th Studio version poy internally an de vrei xrhsth kalei thn antistoixh toy tennis (to antistoixo symvainei kai sto tennis version)
      const webServiceResponseStr = await Studio_RestManager.getInstance().requestKeyForPasswordReset(email, tmpLangNum);
      if(webServiceResponseStr === "true") {
        setMessageToShow("key sent");
      }
      else if(webServiceResponseStr === "Already sent!") {
        setMessageToShow("Already sent!");
      }
      setIsLoading(false);
    }
  }

  async function resetPasswordAsync() 
  {
    if(email === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('pleaseCompleteEmail'));
      else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('pleaseCompleteEmail'));
          setAlertForWebVisible(true);
      }
    }
    else if(newPassword === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('pleaseCompleteNewPassword'));
      else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('pleaseCompleteNewPassword'));
          setAlertForWebVisible(true);
      }
    }
    else if(newPasswordAgain === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('pleaseCompleteNewPasswordAgain'));
      else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('pleaseCompleteNewPasswordAgain'));
          setAlertForWebVisible(true);
      }
    }
    else if(key === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('pleaseCompleteKey'));
      else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('pleaseCompleteKey'));
          setAlertForWebVisible(true);
      }
    }
    else if(newPassword != newPasswordAgain) {
      if(MyAlertReactNative != null)
        alert(i18n.t('passwordsDoNotMatch'));
      else if(MyAlertForWeb != null) {
          setAlertForWebText(i18n.t('passwordsDoNotMatch'));
          setAlertForWebVisible(true);
      }
    }
    else {
      setIsLoading(true);       
      //to domain toy xrhsth DEN TO KSERW, opote kalw sthn tyxh th Studio version poy internally an de vrei xrhsth kalei thn antistoixh toy tennis (to antistoixo symvainei kai sto tennis version)
      const webServiceResponseStr = await Studio_RestManager.getInstance().updatePasswordWithKey(email, newPasswordMD5Encoded, key);
      if(webServiceResponseStr === "true") {
        setMessageToShow("password has changed");
      }
      else if(webServiceResponseStr === Studio_ConstManager.getInstance().INVALID_USERNAME_OR_KEY) {
        setMessageToShow("The email or key you provided is invalid!");
      }
      setIsLoading(false);      
    }
  }

  // Create a ref for the TextInput components
  const emailInputRef = useRef(null);
  const newPasswordInputRef = useRef(null);
  const newPasswordAgainInputRef = useRef(null);
  const keyInputRef = useRef(null);

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.inner}>

          

            <View style={styles.container}>
              <Appbar.Header>
                <Appbar.Content title={i18n.t('resetPassword')} />
              </Appbar.Header>
              <View style={{flexDirection:"row", paddingTop: 50}}>
                <LeftColumnWeb/>
                <ScrollView style={styles.scrollView}>

                  <Spinner
                    visible={isLoading}
                    textContent={i18n.t('loading')}
                    textStyle={styles.spinnerTextStyle}
                  />

                  <RadioButton.Group onValueChange={newValue => setForgotPasswordMode(newValue)} value={forgotPasswordMode}>
                    <RadioButton.Item
                      key={Generic_ConstManager.getInstance().I_WANT_A_KEY}
                      label={i18n.t('iWantAKey')}
                      value={Generic_ConstManager.getInstance().I_WANT_A_KEY}
                      status={ forgotPasswordMode === Generic_ConstManager.getInstance().I_WANT_A_KEY ? 'checked' : 'unchecked' }
                    />
                    <RadioButton.Item
                      key={Generic_ConstManager.getInstance().I_HAVE_A_KEY}
                      label={i18n.t('iHaveAKey')}
                      value={Generic_ConstManager.getInstance().I_HAVE_A_KEY}
                      status={ forgotPasswordMode === Generic_ConstManager.getInstance().I_HAVE_A_KEY ? 'checked' : 'unchecked' }
                    />
                  </RadioButton.Group>

                  <TouchableOpacity onPress={() => emailInputRef.current.focus()}>
                    <TextInput
                      ref={emailInputRef}
                      textAlign="left"
                      label="Email"
                      value={email}
                      onChangeText={tmpEmail => setEmail(tmpEmail)}
                    />
                  </TouchableOpacity>
                  
                  <View style={{display: forgotPasswordMode===Generic_ConstManager.getInstance().I_WANT_A_KEY ? 'flex' : 'none'}}>
                    
                    <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-key" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => requestKeyAsync()}>{i18n.t('sendKey')}</Button>
                  </View>
                  
                  <View style={{display: forgotPasswordMode===Generic_ConstManager.getInstance().I_HAVE_A_KEY ? 'flex' : 'none'}}>
                    <TouchableOpacity onPress={() => newPasswordInputRef.current.focus()}>
                      <TextInput
                        ref={newPasswordInputRef}
                        textAlign="left"
                        label={i18n.t('newPassword')}
                        value={newPassword}
                        onChangeText={tmpNewPassword => setNewPassword(tmpNewPassword)}
                        secureTextEntry
                      />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => newPasswordAgainInputRef.current.focus()}>
                      <TextInput
                        ref={newPasswordAgainInputRef}
                        textAlign="left"
                        label={i18n.t('newPasswordAgain')}
                        value={newPasswordAgain}
                        onChangeText={tmpNewPasswordAgain => setNewPasswordAgain(tmpNewPasswordAgain)}
                        secureTextEntry
                      />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => keyInputRef.current.focus()}>
                      <TextInput
                        ref={keyInputRef}
                        textAlign="left"
                        label={i18n.t('key')}
                        value={key}
                        onChangeText={tmpKey => setKey(tmpKey)}
                      />
                    </TouchableOpacity>
                    
                    <Button icon={({ color, size }) => (<MaterialCommunityIcons name="key-change" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => resetPasswordAsync()}>{i18n.t('setNewPassword')}</Button>
                  </View>
                  
                </ScrollView>
                <RightColumnWeb/>
              </View>
            </View>
            {MyAlertForWeb!=null && 
              <MyAlertForWeb
                show={alertForWebVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForWebText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForWebVisible(false);
                }}
                onConfirmPressed={() => {
                  if(alertForWebText == i18n.t('passwordHasChanged')) {
                    //store user email/password in session
                    sessionStorage.setItem('storedEmail', email);
                    sessionStorage.setItem('storedNonEncryptedPassword', newPassword);

                    navigation.reset({
                      index: 1,
                      routes: [{ name: 'HandyBooker_Home' }]
                      //key: null
                    });
                  }

                  setAlertForWebVisible(false);
                }}  
              />
            }
            <AdBanner />
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  };

export default ForgotPasswordView;