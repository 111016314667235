import React, { useState, useEffect, useRef } from 'react';
import { TextInput, Button, Subheading } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import GUIManager from '../../managers/GUIManager';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import styles from '../../css/myCss';
import AdBanner from '../generic/AdBanner';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';

//Alert
  //react native
    //already imported above
  //web
    import AwesomeAlert from 'react-native-awesome-alerts';
import LeftColumnWeb from './LeftColumnWeb';
import RightColumnWeb from './RightColumnWeb';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
  //-Alert

const SignUpKeyView = ({ navigation, route }) => {
  const [selectedDomainValue, setSelectedDomainValue] = useState(route.params?.selectedDomainValue);
  useEffect(() => {
    console.log("SignUpKeyView: selectedDomainValue - useEffect -> selectedDomainValue: ", selectedDomainValue);
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {

    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      
    }
  }, [selectedDomainValue]);

  //const navigation = useNavigation();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("Studio_SignUpKeyView: I'm ready!");
  }, [alreadyRunOnceOnViewLoad]);

  const [messageToShow, setMessageToShow] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if(isLoading===false && messageToShow!='') {
      var translatedMessageToShow: string = messageToShow;
      if((LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && messageToShow === Studio_ConstManager.getInstance().ACCOUNT_VERIFIED)
          || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && messageToShow === Tennis_ConstManager.getInstance().ACCOUNT_VERIFIED))
        translatedMessageToShow = i18n.t('accountActivated');
      else if((LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && messageToShow === Studio_ConstManager.getInstance().INVALID_KEY)
          || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && messageToShow === Tennis_ConstManager.getInstance().INVALID_KEY))
        translatedMessageToShow = i18n.t('invalidKey');      
      else if((LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && messageToShow === Studio_ConstManager.getInstance().VERIFICATION_KEY_HAS_BEEN_RESENT)
          || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && messageToShow === Tennis_ConstManager.getInstance().VERIFICATION_KEY_HAS_BEEN_RESENT))
        translatedMessageToShow = i18n.t('aKeyWasSent');  
      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        // Works on both Android and iOS
        if(MyAlertReactNative != null) {
          MyAlertReactNative.alert(
            translatedMessageToShow,
            '',
            [
              {
                text: 'OK',
                onPress: () => {
                  if((LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && messageToShow === Studio_ConstManager.getInstance().ACCOUNT_VERIFIED)
                    || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && messageToShow === Tennis_ConstManager.getInstance().ACCOUNT_VERIFIED)) {
                    if(GUIManager.getInstance().mode == GUIManager.getInstance().PLAYER_LOGGED_IN_MODE) {
                      navigation.reset({
                        index: 1,
                        routes: [{ name: 'PlayerControlPanel', params: { selectedDomainValue } }]
                      });
                    }
                    else if(GUIManager.getInstance().mode == GUIManager.getInstance().OWNER_LOGGED_IN_MODE) {
                      navigation.reset({
                        index: 1,
                        routes: [{ name: 'OwnerPinOnMap' }]
                        //key: null
                      });
                    }
                  }                  
                }                
                //setMessageToShow('');
              }
            ],
            { cancelable: false }
          );
        }
        else if(MyAlertForWeb != null) {
          setAlertForWebText(translatedMessageToShow); 
          setAlertForWebVisible(true);
        }
      }, 500);
    }
  }, [isLoading]);

  const [activationKey, setActivationKey] = useState('');

  async function sendANewKeyAsync() 
  {
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      webServiceResponseStr = await Studio_RestManager.getInstance().resendVerificationKey();
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      webServiceResponseStr = await Tennis_RestManager.getInstance().resendVerificationKey();
    if(typeof webServiceResponseStr === 'string')
      setMessageToShow(webServiceResponseStr);
    setIsLoading(false);
  }

  function completeRegistration() 
  {
    if(activationKey === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('insertKeySentToYourEmail'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('insertKeySentToYourEmail')); 
        setAlertForWebVisible(true);
      }
    }
    else {      
      verifyAccountAsync();
    }
  }

  async function verifyAccountAsync() {
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      webServiceResponseStr = await Studio_RestManager.getInstance().verifyAccount(activationKey);
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
    webServiceResponseStr = await Tennis_RestManager.getInstance().verifyAccount(activationKey);
    if(typeof webServiceResponseStr === 'string')
      setMessageToShow(webServiceResponseStr);
    setIsLoading(false);
  }

  // Create a ref for the TextInput components
  const keyInputRef = useRef(null);

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
              <ScrollView style={styles.scrollView}>
                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />
                <Subheading style={{ textAlign: 'center' }}>{i18n.t('insertKeySentToYourEmail')}</Subheading>
                <Subheading style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>{i18n.t('checkAlsoSpam')}</Subheading>
                <TouchableOpacity onPress={() => keyInputRef.current.focus()}>
                  <TextInput
                    ref={keyInputRef}
                    label={i18n.t('key')}
                    value={activationKey}
                    onChangeText={tmpActivationKey => setActivationKey(tmpActivationKey)}
                  />
                </TouchableOpacity>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-check" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}  onPress={() => completeRegistration()}>{i18n.t('activateAccount')}</Button>
                <Subheading style={{ textAlign: 'center', marginTop: 70 }}>{i18n.t('ifYouHaveNotReceivedKey')}</Subheading>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="account-key" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => sendANewKeyAsync()}>{i18n.t('resendKey')}</Button>
              </ScrollView>
              {MyAlertForWeb!=null && 
                <MyAlertForWeb
                  show={alertForWebVisible}
                  showProgress={false}
                  title={i18n.t('caution')}
                  message={alertForWebText}
                  closeOnTouchOutside={false}
                  closeOnHardwareBackPress={false}
                  showConfirmButton={true}
                  confirmText="OK"
                  confirmButtonColor="#DD6B55"
                  onCancelPressed={() => {
                    setAlertForWebVisible(false);
                  }}
                  onConfirmPressed={() => {
                    if(alertForWebText == i18n.t('accountActivated')) {
                      if(GUIManager.getInstance().mode == GUIManager.getInstance().PLAYER_LOGGED_IN_MODE) {
                        navigation.reset({
                          index: 1,
                          routes: [{ name: 'PlayerControlPanel', params: { selectedDomainValue } }]
                        });
                      }
                      else if(GUIManager.getInstance().mode == GUIManager.getInstance().OWNER_LOGGED_IN_MODE) {
                        navigation.reset({
                          index: 1,
                          routes: [{ name: 'OwnerPinOnMap' }]
                          //key: null
                        });
                      }
                    }
                    else
                      setAlertForWebVisible(false);
                  }}  
                />
              }
              <AdBanner/>
            </View>
            <RightColumnWeb/>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  };

export default SignUpKeyView;