// -*- coding: utf-8 -*-
import { resolve } from "node:path";
import GUIManager from "./GUIManager";
import LoginManager from "./LoginManager";
import Studio_RestManager from "./Studio_RestManager";
import * as Crypto from 'expo-crypto';
import i18n from '../utils/i18n';
import { Linking } from "react-native";
import * as MailComposer from 'expo-mail-composer';
import Generic_ConstManager from "./Generic_ConstManager";
import CryptoES from 'crypto-es';
import { MD5 } from 'crypto-js';



interface ImageDimensions {
    width: number;
    height: number;
}

class UtilsManager {

    public domainsList = [
        { label: i18n.t('studiosDomainTitle'), value: LoginManager.getInstance().STUDIO }, 
        { label: i18n.t('tennisDomainTitle'), value: LoginManager.getInstance().TENNIS }
    ];

    public domainsList_EN = [
        { label: 'Rehearsal/recording studios', value: LoginManager.getInstance().STUDIO }, 
        { label: 'Tennis courts', value: LoginManager.getInstance().TENNIS }
    ];

    public domainsList_GR = [
        { label: 'Studios προβών/ηχογραφήσεων', value: LoginManager.getInstance().STUDIO }, 
        { label: 'Γήπεδα τένις', value: LoginManager.getInstance().TENNIS }
    ];

    public tennisCourtTypeList_EN = [
        { label: 'Acrylic', value: "0"},
        { label: 'Artificial clay', value: "1"},
        { label: 'Artificial grass', value: "2"},
        { label: 'Asphalt', value: "3"},
        { label: 'Carpet', value: "4"},
        { label: 'Clay', value: "5"},
        { label: 'Concrete', value: "6"},
        { label: 'Grass', value: "7"},
        { label: 'Other', value: "8"}
    ];

    public tennisCourtTypeList_GR = [
        { label: 'Ακρυλικό', value: "0"},
        { label: 'Τεχνητό χώμα', value: "1"},
        { label: 'Τεχνητό γρασίδι', value: "2"},
        { label: 'Άσφαλτος', value: "3"},
        { label: 'Χαλί', value: "4"},
        { label: 'Χώμα', value: "5"},
        { label: 'Τσιμέντο', value: "6"},
        { label: 'Γρασίδι', value: "7"},
        { label: 'Άλλο', value: "8"}
    ];
    
    public allHours = [
        "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
        "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
    ];
    
    public currencyList = [
        { label: 'EUR - Euro', value: 'EUR - Euro' },
        { label: 'USD - US Dollar', value: 'USD - US Dollar' },
        { label: 'GBP - British Pound', value: 'GBP - British Pound' },
        { label: 'AED - Emirati Dirham', value: 'AED - Emirati Dirham' },
        { label: 'AFN - Afghan Afghani', value: 'AFN - Afghan Afghani' },
        { label: 'AMD - Armenian Dram', value: 'AMD - Armenian Dram' },
        { label: 'ANG - Dutch Guilder', value: 'ANG - Dutch Guilder' },
        { label: 'AOA - Angolan Kwanza', value: 'AOA - Angolan Kwanza' },
        { label: 'ARS - Argentina Peso', value: 'ARS - Argentina Peso' },
        { label: 'AUD - Australian Dollar', value: 'AUD - Australian Dollar' },
        { label: 'AWG - Aruban or Dutch Guilder', value: 'AWG - Aruban or Dutch Guilder' },
        { label: 'AZN - Azerbaijani New Manat', value: 'AZN - Azerbaijani New Manat' },
        { label: 'BAM - Bosnian Convertible Marka', value: 'BAM - Bosnian Convertible Marka' },
        { label: 'BBD - Barbadian or Bajan Dollar', value: 'BBD - Barbadian or Bajan Dollar' },
        { label: 'BDT - Bangladeshi Taka', value: 'BDT - Bangladeshi Taka' },
        { label: 'BGN - Bulgarian Lev', value: 'BGN - Bulgarian Lev' },
        { label: 'BHD - Bahraini Dinar', value: 'BHD - Bahraini Dinar' },
        { label: 'BIF - Burundian Franc', value: 'BIF - Burundian Franc' },
        { label: 'BMD - Bermudian Dollar', value: 'BMD - Bermudian Dollar' },
        { label: 'BND - Bruneian Dollar', value: 'BND - Bruneian Dollar' },
        { label: 'BOB - Bolivian Boliviano', value: 'BOB - Bolivian Boliviano' },
        { label: 'BRL - Brazilian Real', value: 'BRL - Brazilian Real' },
        { label: 'BSD - Bahamian Dollar', value: 'BSD - Bahamian Dollar' },
        { label: 'BTN - Bhutanese Ngultrum', value: 'BTN - Bhutanese Ngultrum' },
        { label: 'BWP - Botswana Pula', value: 'BWP - Botswana Pula' },
        { label: 'BYR - Belarusian Ruble', value: 'BYR - Belarusian Ruble' },
        { label: 'BZD - Belizean Dollar', value: 'BZD - Belizean Dollar' },
        { label: 'CAD - Canadian Dollar', value: 'CAD - Canadian Dollar' },
        { label: 'CDF - Congolese Franc', value: 'CDF - Congolese Franc' },
        { label: 'CHF - Swiss Franc', value: 'CHF - Swiss Franc' },
        { label: 'CLP - Chilean Peso', value: 'CLP - Chilean Peso' },
        { label: 'CNY - Chinese Yuan Renminbi', value: 'CNY - Chinese Yuan Renminbi' },
        { label: 'COP - Colombian Peso', value: 'COP - Colombian Peso' },
        { label: 'CRC - Costa Rican Colon', value: 'CRC - Costa Rican Colon' },
        { label: 'CUC - Cuban Convertible Peso', value: 'CUC - Cuban Convertible Peso' },
        { label: 'CUP - Cuban Peso', value: 'CUP - Cuban Peso' },
        { label: 'CVE - Cape Verdean Escudo', value: 'CVE - Cape Verdean Escudo' },
        { label: 'CZK - Czech Koruna', value: 'CZK - Czech Koruna' },
        { label: 'DJF - Djiboutian Franc', value: 'DJF - Djiboutian Franc' },
        { label: 'DKK - Danish Krone', value: 'DKK - Danish Krone' },
        { label: 'DOP - Dominican Peso', value: 'DOP - Dominican Peso' },
        { label: 'DZD - Algerian Dinar', value: 'DZD - Algerian Dinar' },
        { label: 'EGP - Egyptian Pound', value: 'EGP - Egyptian Pound' },
        { label: 'ERN - Eritrean Nakfa', value: 'ERN - Eritrean Nakfa' },
        { label: 'ETB - Ethiopian Birr', value: 'ETB - Ethiopian Birr' },
        { label: 'FJD - Fijian Dollar', value: 'FJD - Fijian Dollar' },
        { label: 'FKP - Falkland Island Pound', value: 'FKP - Falkland Island Pound' },
        { label: 'GEL - Georgian Lari', value: 'GEL - Georgian Lari' },
        { label: 'GGP - Guernsey Pound', value: 'GGP - Guernsey Pound' },
        { label: 'GHS - Ghanaian Cedi', value: 'GHS - Ghanaian Cedi' },
        { label: 'GIP - Gibraltar Pound', value: 'GIP - Gibraltar Pound' },
        { label: 'GMD - Gambian Dalasi', value: 'GMD - Gambian Dalasi' },
        { label: 'GNF - Guinean Franc', value: 'GNF - Guinean Franc' },
        { label: 'GTQ - Guatemalan Quetzal', value: 'GTQ - Guatemalan Quetzal' },
        { label: 'GYD - Guyanese Dollar', value: 'GYD - Guyanese Dollar' },
        { label: 'HKD - Hong Kong Dollar', value: 'HKD - Hong Kong Dollar' },
        { label: 'HNL - Honduran Lempira', value: 'HNL - Honduran Lempira' },
        { label: 'HRK - Croatian Kuna', value: 'HRK - Croatian Kuna' },
        { label: 'HTG - Haitian Gourde', value: 'HTG - Haitian Gourde' },
        { label: 'HUF - Hungarian Forint', value: 'HUF - Hungarian Forint' },
        { label: 'IDR - Indonesian Rupiah', value: 'IDR - Indonesian Rupiah' },
        { label: 'ILS - Israeli Shekel', value: 'ILS - Israeli Shekel' },
        { label: 'IMP - Isle of Man Pound', value: 'IMP - Isle of Man Pound' },
        { label: 'INR - Indian Rupee', value: 'INR - Indian Rupee' },
        { label: 'IQD - Iraqi Dinar', value: 'IQD - Iraqi Dinar' },
        { label: 'IRR - Iranian Rial', value: 'IRR - Iranian Rial' },
        { label: 'ISK - Icelandic Krona', value: 'ISK - Icelandic Krona' },
        { label: 'JEP - Jersey Pound', value: 'JEP - Jersey Pound' },
        { label: 'JMD - Jamaican Dollar', value: 'JMD - Jamaican Dollar' },
        { label: 'JOD - Jordanian Dinar', value: 'JOD - Jordanian Dinar' },
        { label: 'JPY - Japanese Yen', value: 'JPY - Japanese Yen' },
        { label: 'KES - Kenyan Shilling', value: 'KES - Kenyan Shilling' },
        { label: 'KGS - Kyrgyzstani Som', value: 'KGS - Kyrgyzstani Som' },
        { label: 'KHR - Cambodian Riel', value: 'KHR - Cambodian Riel' },
        { label: 'KMF - Comoran Franc', value: 'KMF - Comoran Franc' },
        { label: 'KPW - North Korean Won', value: 'KPW - North Korean Won' },
        { label: 'KRW - South Korean Won', value: 'KRW - South Korean Won' },
        { label: 'KWD - Kuwaiti Dinar', value: 'KWD - Kuwaiti Dinar' },
        { label: 'KYD - Caymanian Dollar', value: 'KYD - Caymanian Dollar' },
        { label: 'KZT - Kazakhstani Tenge', value: 'KZT - Kazakhstani Tenge' },
        { label: 'LAK - Lao or Laotian Klp', value: 'LAK - Lao or Laotian Klp' },
        { label: 'LBP - Lebanese Pound', value: 'LBP - Lebanese Pound' },
        { label: 'LKR - Sri Lankan Rupee', value: 'LKR - Sri Lankan Rupee' },
        { label: 'LRD - Liberian Dollar', value: 'LRD - Liberian Dollar' },
        { label: 'LSL - Basotho Loti', value: 'LSL - Basotho Loti' },
        { label: 'LTL - Lithuanian Litas', value: 'LTL - Lithuanian Litas' },
        { label: 'LVL - Latvian Lat', value: 'LVL - Latvian Lat' },
        { label: 'LYD - Libyan Dinar', value: 'LYD - Libyan Dinar' },
        { label: 'MAD - Moroccan Dirham', value: 'MAD - Moroccan Dirham' },
        { label: 'MDL - Moldovan Leu', value: 'MDL - Moldovan Leu' },
        { label: 'MGA - Malagasy Ariary', value: 'MGA - Malagasy Ariary' },
        { label: 'MMK - Burmese Kyat', value: 'MMK - Burmese Kyat' },
        { label: 'MNT - Mongolian Tughrik', value: 'MNT - Mongolian Tughrik' },
        { label: 'MOP - Macau Pataca', value: 'MOP - Macau Pataca' },
        { label: 'MRO - Mauritanian Ouguiya', value: 'MRO - Mauritanian Ouguiya' },
        { label: 'MUR - Maurutian Rupee', value: 'MUR - Maurutian Rupee' },
        { label: 'MVR - Maldivian Rufiyaa', value: 'MVR - Maldivian Rufiyaa' },
        { label: 'MWK - Malawian Kwacha', value: 'MWK - Malawian Kwacha' },
        { label: 'MXN - Mexican Peso', value: 'MXN - Mexican Peso' },
        { label: 'MYR - Malaysian Ringgit', value: 'MYR - Malaysian Ringgit' },
        { label: 'MZN - Mozambican Metical', value: 'MZN - Mozambican Metical' },
        { label: 'NAD - Namibian Dollar', value: 'NAD - Namibian Dollar' },
        { label: 'NGN - Nigerian Naira', value: 'NGN - Nigerian Naira' },
        { label: 'NIO - Nicaraguan Cordoba', value: 'NIO - Nicaraguan Cordoba' },
        { label: 'NOK - Norwegian Krone', value: 'NOK - Norwegian Krone' },
        { label: 'NPR - Nepalese Rupee', value: 'NPR - Nepalese Rupee' },
        { label: 'NZD - New Zealand Dollar', value: 'NZD - New Zealand Dollar' },
        { label: 'OMR - Omani Rial', value: 'OMR - Omani Rial' },
        { label: 'PAB - Panamanian Balboa', value: 'PAB - Panamanian Balboa' },
        { label: 'PEN - Peruvian Nuevo Sol', value: 'PEN - Peruvian Nuevo Sol' },
        { label: 'PGK - Papua New Guinean Kina', value: 'PGK - Papua New Guinean Kina' },
        { label: 'PHP - Philippine Peso', value: 'PHP - Philippine Peso' },
        { label: 'PKR - Pakistani Rupee', value: 'PKR - Pakistani Rupee' },
        { label: 'PLN - Polish Zloty', value: 'PLN - Polish Zloty' },
        { label: 'PYG - Paraguayan Guarani', value: 'PYG - Paraguayan Guarani' },
        { label: 'QAR - Qatari Riyal', value: 'QAR - Qatari Riyal' },
        { label: 'RON - Romanian New Leu', value: 'RON - Romanian New Leu' },
        { label: 'RSD - Serbian Dinar', value: 'RSD - Serbian Dinar' },
        { label: 'RUB - Russian Ruble', value: 'RUB - Russian Ruble' },
        { label: 'RWF - Rwandan Franc', value: 'RWF - Rwandan Franc' },
        { label: 'SAR - Saudi Arabian Riyal', value: 'SAR - Saudi Arabian Riyal' },
        { label: 'SBD - Solomon Islander Dollar', value: 'SBD - Solomon Islander Dollar' },
        { label: 'SCR - Seychelois Rupee', value: 'SCR - Seychelois Rupee' },
        { label: 'SDG - Sudanese Pound', value: 'SDG - Sudanese Pound' },
        { label: 'SEK - Swedish Krona', value: 'SEK - Swedish Krona' },
        { label: 'SGD - Singapore Dollar', value: 'SGD - Singapore Dollar' },
        { label: 'SHP - Saint Helenian Pound', value: 'SHP - Saint Helenian Pound' },
        { label: 'SLL - Sierra Leonean Leone', value: 'SLL - Sierra Leonean Leone' },
        { label: 'SOS - Somali Shilling', value: 'SOS - Somali Shilling' },
        { label: 'SPL - Seborgan Luigino', value: 'SPL - Seborgan Luigino' },
        { label: 'SRD - Surinamese Dollar', value: 'SRD - Surinamese Dollar' },
        { label: 'STD - Sai Tomean Dobra', value: 'STD - Sai Tomean Dobra' },
        { label: 'SVC - Salvadoran Colon', value: 'SVC - Salvadoran Colon' },
        { label: 'SYP - Syrian Pound', value: 'SYP - Syrian Pound' },
        { label: 'SZL - Swazi Lilangeni', value: 'SZL - Swazi Lilangeni' },
        { label: 'THB - Thai Baht', value: 'THB - Thai Baht' },
        { label: 'TJS - Tajikistani Somoni', value: 'TJS - Tajikistani Somoni' },
        { label: 'TMT - Turkmenistani Manat', value: 'TMT - Turkmenistani Manat' },
        { label: 'TND - Tunisian Dinar', value: 'TND - Tunisian Dinar' },
        { label: 'TOP - Tongan Pa anga', value: 'TOP - Tongan Pa anga' },
        { label: 'TRY - Turkish Lira', value: 'TRY - Turkish Lira' },
        { label: 'TTD - Trinidadian Dollar', value: 'TTD - Trinidadian Dollar' },
        { label: 'TVD - Tuvaluan Dollar', value: 'TVD - Tuvaluan Dollar' },
        { label: 'TWD - Taiwan New Dollar', value: 'TWD - Taiwan New Dollar' },
        { label: 'TZS - Tanzanian Shilling', value: 'TZS - Tanzanian Shilling' },
        { label: 'UAH - Ukrainian Hryvna', value: 'UAH - Ukrainian Hryvna' },
        { label: 'UGX - Ugandan Shilling', value: 'UGX - Ugandan Shilling' },
        { label: 'UYU - Uruguayan Peso', value: 'UYU - Uruguayan Peso' },
        { label: 'UZS - Uzbekistani Som', value: 'UZS - Uzbekistani Som' },
        { label: 'VEF - Venezuelan Bolivar', value: 'VEF - Venezuelan Bolivar' },
        { label: 'VND - Vietnamese Dong', value: 'VND - Vietnamese Dong' },
        { label: 'VUV - Ni-Vanuatu Vatu', value: 'VUV - Ni-Vanuatu Vatu' },
        { label: 'WST - Samoan Tala', value: 'WST - Samoan Tala' },
        { label: 'XAF - Central African CFA Franc BEAC', value: 'XAF - Central African CFA Franc BEAC' },
        { label: 'XAG - Silver Ounce', value: 'XAG - Silver Ounce' },
        { label: 'XAU - Gold Ounce', value: 'XAU - Gold Ounce' },
        { label: 'XBT - Bitcoin', value: 'XBT - Bitcoin' },
        { label: 'XCD - East Caribbean Dollar', value: 'XCD - East Caribbean Dollar' },
        { label: 'XDR - IMF Special Drawing Rights', value: 'XDR - IMF Special Drawing Rights' },
        { label: 'XOF - CFA Franc', value: 'XOF - CFA Franc' },
        { label: 'XPD - Palladium Ounce', value: 'XPD - Palladium Ounce' },
        { label: 'XPF - CFP Franc', value: 'XPF - CFP Franc' },
        { label: 'XPT - Platinum Ounce', value: 'XPT - Platinum Ounce' },
        { label: 'YER - Yemeni Rial', value: 'YER - Yemeni Rial' },
        { label: 'ZAR - South African Rand', value: 'ZAR - South African Rand' },
        { label: 'ZMW - Zambian Kwacha', value: 'ZMW - Zambian Kwacha' },
        { label: 'ZWD - Zimbabwean Dollar', value: 'ZWD - Zimbabwean Dollar' }
    ];

    public readonly RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN : number = 0;
    public readonly RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN : number = 1;
    public readonly RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_OWNER_IN_STUDIO_DOMAIN : number = 2;
    
    public readonly RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN : number = 3;
    public readonly RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN : number = 4;
    public readonly RESERVATION_TYPE_TENNISOWNER_ACTS_AS_OWNER_IN_TENNIS_DOMAIN : number = 5;

    public readonly RESERVATION_TYPE_STUDIOPLAYER_RESERVED_IN_STUDIO_DOMAIN : number = 6;
    public readonly RESERVATION_TYPE_STUDIOPLAYER_RESERVED_IN_TENNIS_DOMAIN : number = 7;

    public readonly RESERVATION_TYPE_TENNISPLAYER_RESERVED_IN_STUDIO_DOMAIN : number = 8;
    public readonly RESERVATION_TYPE_TENNISPLAYER_RESERVED_IN_TENNIS_DOMAIN : number = 9;

    private static instance: UtilsManager;

    private constructor() { 

    }

    public static getInstance(): UtilsManager {
        if (!UtilsManager.instance) {
            UtilsManager.instance = new UtilsManager();
        }

        return UtilsManager.instance;
    }

    //async function encodeWithMD5(tmpPassword: string) {
    //  return await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, buffer, { encoding: Crypto.CryptoEncoding.BASE64 });
    //} 

    public async encodeWithMD5(tmpPassword: string) {
        const digest = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.MD5,
            tmpPassword
        );
        console.log('Digest: ', digest);
        /* Some crypto operation... */
        return digest;
    }

    /*public generateMD5Hash_forWeb(input) {
        return MD5(input).toString();
      }*/

      public async generateMD5Hash_forWeb(input) {
        try {
          const hash = await MD5(input).toString();
          return hash;
        } catch (error) {
          throw error;
        }
      }
      

    // Define a secret key for encryption
    //public readonly mySecretKey : string = 'hgj@@@!3dfgsvbnmVHJ*&$$jhxgs';

    

      /*public async encryptWithAES256(text) {
        const encryptedData = await AesCrypto.encrypt(text, myKey, myIv, AesCrypto.AES_256_CBC);
        return encryptedData;
      }
    
      public decryptWithAES256 = async (encryptedData) => {
        const decryptedText = await AesCrypto.decrypt(this.mySecretKey, encryptedData);
        return decryptedText;
      };*/

      /*const key = await window.crypto.subtle.generateKey(
        {
          name: 'AES-GCM',
          length: 256
        },
        true,
        ['encrypt', 'decrypt']
      );

      function async encryptString(input: string, key: CryptoKey): Promise<string> {
        const result = await AesGcmCrypto.encrypt(input, false, key);
        return result.content;
      }*/

    /*  public key = 'Yzg1MDhmNDYzZjRlMWExOGJkNTk5MmVmNzFkOGQyNzk=';



public async encryptWithAES256(data : string): Promise<string> {
    return AesGcmCrypto.encrypt(data, false, this.key)
      .then((result) => {
        return result.content;
      });
  }*/
/*
public async decryptWithAES256(encryptedData: string): Promise<string> {
  try {
    const decryptedData = await AesGcmCrypto.decrypt(
      'LzpSalRKfL47H5rUhqvA',
      this.key,
      '131348c0987c7eece60fc0bc',
      '5baa85ff3e7eda3204744ec74b71d523',
      false
    );
    return decryptedData;
  } catch (error) {
    console.error('Decryption error:', error);
    throw error;
  }
}*/

// Define the key and IV (initialization vector)
/*public key = 'my-secret-key-12345';
public iv = this.generateRandomIV(16);

// Generate a random IV of the given length
public generateRandomIV(length: number): number[] {
  let iv: number[] = [];

  try {
    // Try to use the crypto module to generate a random IV
    const ivWordArray = CryptoJS.lib.WordArray.random(length);
    iv = ivWordArray.words;
  } catch (e) {
    // If the crypto module is not available, generate a non-cryptographically secure random IV using Math.random()
    for (let i = 0; i < length / 4; i++) {
      iv.push(Math.floor(Math.random() * 0x100000000));
    }
  }

  return iv;
}

// Encrypt a plaintext string using AES-256-CBC
public encryptWithAES256(plaintext: string): string {
  // Convert the plaintext to a WordArray
  const plaintextWordArray = CryptoJS.enc.Utf8.parse(plaintext);

  // Encrypt the plaintext using AES-256-CBC
  const ciphertextWordArray = CryptoJS.AES.encrypt(
    plaintextWordArray,
    this.key,
    { iv: CryptoJS.lib.WordArray.create(new Uint32Array(this.iv)) }
  );

  // Convert the ciphertext to a string
  const ciphertext = ciphertextWordArray.toString();

  // Return the encrypted string
  return ciphertext;
}

// Decrypt an encrypted string using AES-256-CBC
public decryptWithAES256(ciphertext: string): string {
  // Convert the ciphertext to a WordArray
  const ciphertextWordArray = CryptoJS.enc.Base64.parse(ciphertext);

  // Decrypt the ciphertext using AES-256-CBC
  const plaintextWordArray = CryptoJS.AES.decrypt(
    { ciphertext: ciphertextWordArray },
    this.key,
    { iv: CryptoJS.lib.WordArray.create(new Uint32Array(this.iv)) }
  );

  // Convert the plaintext to a string
  const plaintext = plaintextWordArray.toString(CryptoJS.enc.Utf8);

  // Return the decrypted string
  return plaintext;
}*/

    public readonly MY_SECRET_KEY : string = 'vldfghjFGHJhyWYBMlbugIVj'; //the same with Java!
    public readonly MY_IV : string = "jcHgYESkMnOpUvRT"; //the same with Java

    public encryptWithAES256(textToBeEncoded: string): string {
        const encryptedBytes = CryptoES.AES.encrypt(textToBeEncoded, 
            CryptoES.enc.Utf8.parse(this.MY_SECRET_KEY), {
            iv: CryptoES.enc.Utf8.parse(this.MY_IV),
            mode: CryptoES.mode.CBC,
            padding: CryptoES.pad.Pkcs7
        });
        const encryptedText = encryptedBytes.toString();
        //console.log("encryptWithAES256 -> encryptedText: " + encryptedText);
        return encryptedText;
    }

    /*public decryptWithAES256(encodedText: string): string {
        const decryptedBytes = CryptoES.AES.decrypt(encodedText, 
            CryptoES.enc.Utf8.parse(this.MY_SECRET_KEY), { 
            iv: CryptoES.enc.Utf8.parse(this.MY_IV) });
        const decryptedText = decryptedBytes.toString(CryptoES.enc.Utf8);
        console.log("decryptWithAES256 -> decryptedText: " + decryptedText);
        return decryptedText;
    }*/    

    public makePhoneCall(tmpTelephone: string) {
        Linking.openURL("tel://" + tmpTelephone);
    }
    
    public async sendEmail(tmpEmail: string) {
        let result = await MailComposer.composeAsync({
            recipients: [tmpEmail],
            subject: '',
            body: '',
        });
    }

    public checkIfReservationIsValid(allHoursOfSelectedDay : string[],
                                    selectedTimeslots : boolean[]) : ReservationValidityResult
    {
        var tmpReservationValidityResult : ReservationValidityResult = {
            resultOfCheck: "",
            startTime: "",
            stopTime: ""
        }

        //check if selected timeslots are sequential
        var reservationIsValid: boolean = false;
        var reservationStartTime: string = '';
        var reservationStopTime: string = '';
        var timeslotsAreSequential: boolean = true;
        var nonSelectedTimeslotAfterStartTimeFound = false;
        for (let i = 0; i < allHoursOfSelectedDay.length; i++) { 
            //console.log(i, ")", allHoursOfSelectedDay[i], "-", UtilsManager.getInstance().getNextTimeslotStr(allHoursOfSelectedDay[i]), " -> ", selectedTimeslots[i]);
            if(reservationStartTime==='' && selectedTimeslots[i]===true) {
                reservationStartTime = allHoursOfSelectedDay[i];
                reservationStopTime = UtilsManager.getInstance().getNextTimeslotStr(allHoursOfSelectedDay[i]);
            }
            else if(reservationStartTime!='') {
                if(selectedTimeslots[i]===true && nonSelectedTimeslotAfterStartTimeFound===false) {
                reservationStopTime = UtilsManager.getInstance().getNextTimeslotStr(allHoursOfSelectedDay[i]);
                }
                else if(selectedTimeslots[i]===false && nonSelectedTimeslotAfterStartTimeFound===false) {
                nonSelectedTimeslotAfterStartTimeFound = true;
                }
                else if(selectedTimeslots[i]===true && nonSelectedTimeslotAfterStartTimeFound===true) {
                timeslotsAreSequential = false;
                }
            }
        }
        //-check if selected timeslots are sequential
        if(reservationStartTime=='' || reservationStopTime=='') {
            tmpReservationValidityResult = {
                resultOfCheck: i18n.t('selectTimeslots'),
                startTime: "",
                stopTime: ""
            };
        }
        else if(reservationStartTime!='' && reservationStopTime!='') {
            if(timeslotsAreSequential === false) {
                tmpReservationValidityResult = {
                    resultOfCheck: i18n.t('timeslotsAreNotSequential'),
                    startTime: "",
                    stopTime: ""
                };
            }
            else {
                tmpReservationValidityResult = {
                    resultOfCheck: "ok",
                    startTime: reservationStartTime,
                    stopTime: reservationStopTime
                };
            }
        }
        return tmpReservationValidityResult;
    }

    /*public async calculateReservationCost(tmpRoomID: number, tmpReservationIsRehearsal: boolean, tmpReservationDate: string, tmpReservationStartTime: string, tmpReservationStopTime: string)
    {
        //console.log("calculateReservationCost - Start");
        //find day
        const tmpResDayNum: number = this.findDayNumFromDate(tmpReservationDate);
        //find duration in hours
        const tmpResDurationInHours: number = this.findDurationInHours(tmpReservationStartTime, tmpReservationStopTime);

        //get room
        const tmpRoom = await Studio_RestManager.getInstance().getRoom(tmpRoomID);
        //console.log("tmpRoom: ", tmpRoom);
                
        var tmpCost: string = 'n/a';
        var tmpCurrency: string = tmpRoom.currency;
        const pavlaIndex : number = tmpCurrency.indexOf(' - ');
        if(pavlaIndex != 0)
            tmpCurrency = tmpCurrency.substr(0, pavlaIndex);
        //Sunday
        if(tmpResDayNum === 0) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.sunday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.sunday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //Monday
        else if(tmpResDayNum === 1) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.monday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.monday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //Tuesday
        else if(tmpResDayNum === 2) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.tuesday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.tuesday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //Wednesday
        else if(tmpResDayNum === 3) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.wednesday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.wednesday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //Thursday
        else if(tmpResDayNum === 4) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.thursday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.thursday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //Friday
        else if(tmpResDayNum === 5) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.friday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.friday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //Saturday
        else if(tmpResDayNum === 6) {
            if(tmpReservationIsRehearsal === true) {
                tmpCost = (tmpResDurationInHours*tmpRoom.saturday_cost_per_hour_reh).toString() + " " + tmpCurrency;
            }
            else {
                tmpCost = (tmpResDurationInHours*tmpRoom.saturday_cost_per_hour_rec).toString() + " " + tmpCurrency;
            }
        }
        //console.log("tmpCost: " + tmpCost);
        //console.log("calculateReservationCost - End");
        return tmpCost;        
    }*/

    public findDayNumFromDate(tmpDateStr: string): number {
        //tmpDateStr is in format DD/MM/YYYY
        //we need ISO format: YYYY-MM-DD so we transform it
        const tmpDateStrISOFormat = this.tranformDateToISOFormat(tmpDateStr);        
        //Sunday is 0, Monday is 1, and so on.
        const tmpResDayNum: number = new Date(tmpDateStrISOFormat).getDay();
        //debug
        /*var tmpResDayStr: string = "undefined";
        if(tmpResDayNum === 0)
            tmpResDayStr = "Sunday";
        else if(tmpResDayNum === 1)
            tmpResDayStr = "Monday";
        else if(tmpResDayNum === 2)
            tmpResDayStr = "Tuesday";
        else if(tmpResDayNum === 3)
            tmpResDayStr = "Wednesday";
        else if(tmpResDayNum === 4)
            tmpResDayStr = "Thursday";
        else if(tmpResDayNum === 5)
            tmpResDayStr = "Friday";
        else if(tmpResDayNum === 6)
            tmpResDayStr = "Saturday";
        console.log(tmpDateStr + " -> " + tmpDateStrISOFormat + ", day: " + tmpResDayStr + ", dayNum: " + tmpResDayNum);*/
        //-debug
        return tmpResDayNum;
    }

    public tranformDateToISOFormat(tmpDateStr: string) : string {
        //tmpDateStr is in format DD/MM/YYYY
        const tmpDay: string = tmpDateStr.substring(0, tmpDateStr.indexOf("/")).padStart(2, "0");
        const tmpMonth: string = tmpDateStr.substring(tmpDateStr.indexOf("/")+1, tmpDateStr.lastIndexOf("/")).padStart(2, "0");
        const tmpYear: string = tmpDateStr.substring(tmpDateStr.lastIndexOf("/")+1, tmpDateStr.length);
        const tmpDateStrISOFormat = tmpYear + "-" + tmpMonth + "-" + tmpDay;
        //we need ISO format: YYYY-MM-DD
        return tmpDateStrISOFormat;
    }

    public transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes(tmpDateStr: string) : string {
        //console.log("transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes - start");
        //console.log("\t from: " + tmpDateStr);
        // First, split the date string into an array of three substrings, using the "-" character as the separator
        const dateArray = tmpDateStr.split("-");
        // Next, reverse the order of the elements in the array
        dateArray.reverse();
        // Use the map function to transform each element of the array
        const transformedDateArray = dateArray.map(element => {
            // If the element is a single-digit month or day, pad it with a leading "0"
            if (element.length === 1) {
                return element.padStart(2, "0");
            }
            // If the element is not a single-digit month or day, return it as is
            return element;
        });
        // Finally, join the elements of the transformed array back into a single string, using the "/" character as the separator
        const transformedDateStr = transformedDateArray.join("/");
        //console.log("\t to: " + transformedDateStr);
        //console.log("transformDateFrom_YYYYMMDD_withPavles_to_DDMMYYYY_withSlashes - end");
        return transformedDateStr;
    }

    public compareDates(tmpDate1Str : string, tmpDate2Str: string) : number {
        //input strings are in this form:  DD/MM/YYYY
        let date1IsoStr = this.tranformDateToISOFormat(tmpDate1Str);
        let date2IsoStr = this.tranformDateToISOFormat(tmpDate2Str);

        let timestamp1 = Date.parse(date1IsoStr);
        let timestamp2 = Date.parse(date2IsoStr);

        let result : number = 0;
        if (timestamp1 > timestamp2) {
            result = -1;
            //console.log(tmpDate1Str + " (or " + date1IsoStr + ")" + " is after " + tmpDate2Str + " (or " + date2IsoStr + ")");
        } else if (timestamp1 < timestamp2) {
            result = 1;
            //console.log(tmpDate1Str + " (or " + date1IsoStr + ")" + " is before " + tmpDate2Str + " (or " + date2IsoStr + ")");
        } else {
            result = 0; //ek perissou
            //console.log(tmpDate1Str + " (or " + date1IsoStr + ")" + " is the same as " + tmpDate2Str + " (or " + date2IsoStr + ")");
        }
        return result;
    }

    /*public findDurationInHours(tmpStartTime: string, tmpStopTime: string): number {
        var tmpMisawra: number = 0;
        var tmpTime: string = "";
        var tmpStartTimeFound: boolean = false;
        var tmpStopTimeFound: boolean = false;
        for (let i = 0; i < this.allHours.length; i++) {
            if(tmpStartTimeFound===false && this.allHours[i]===tmpStartTime && tmpStopTimeFound===false) {
                tmpStartTimeFound = true;
            }
            else if(tmpStartTimeFound===true && this.allHours[i]===tmpStopTime && tmpStopTimeFound===false) {
                tmpStopTimeFound = true;
            }

            if(tmpStartTimeFound===true && tmpStopTimeFound===false) {
                tmpMisawra++;
            }

            if(tmpStopTimeFound === true) {
                i = this.allHours.length-1;
            }
            if(tmpStopTimeFound===false && i===(this.allHours.length-1)) {
                i = 0;  //restart
            }
        }

        return tmpMisawra/2;
    }*/

    public getNextTimeslotStr(tmpCurTime: string): string
    {
        for (let i = 0; i < this.allHours.length; i++) {
            if(this.allHours[i] === tmpCurTime)
            {
                if(i < (this.allHours.length-1))
                    return this.allHours[i+1];
                else
                    return this.allHours[0];
            }
        }
        return '';
    }

    public getTimeslotStatus_Studio(tmpStartHour: string, tmpAllReservationsOfThisRoomForThisDate: Array<Studio_Reservation>) : string {
        //possible statuses: "free" / "reservedByOtherPlayer" / "reservedByThisPlayer" / "reservedByTheOwnerOfTheRoom"
        var resultStr: string = "*STATUS*free*INDEX*-1";
        for (let i = 0; i < tmpAllReservationsOfThisRoomForThisDate.length; i++) { 
            const tmpReservation: Studio_Reservation = tmpAllReservationsOfThisRoomForThisDate[i];
            //check if "tmpStartHour - (tmpStartHour+30min)" timeslot is included in tmpReservation's hours
            const isIncludedInReservation = this.timeslotIsIncludedInReservationDuration(tmpStartHour, tmpReservation.reservation_hour_start, tmpReservation.reservation_hour_stop);
            //-check if "tmpStartHour - (tmpStartHour+30min)" timeslot is included in tmpReservation's hours
            if(isIncludedInReservation === true) {
                if(LoginManager.getInstance().role==Generic_ConstManager.getInstance().PLAYER_ROLE && tmpReservation.by_user_email===LoginManager.getInstance().username) {
                    resultStr = "*STATUS*reservedByThisPlayer*INDEX*" + i.toString();
                }
                else if(LoginManager.getInstance().role==Generic_ConstManager.getInstance().OWNER_ROLE && tmpReservation.by_user_email===LoginManager.getInstance().username) {    //web-service side: RESERVATION_MADE_BY_THE_OWNER_OF_THE_COURT = -1000
                    resultStr = "*STATUS*reservedByTheOwnerOfTheRoom*INDEX*" + i.toString();
                }    
                else {
                    resultStr = "*STATUS*reservedByOtherPlayer*INDEX*" + i.toString();
                }            
            }
        }

        return resultStr;
    }

    public getTimeslotStatus_Tennis(tmpStartHour: string, tmpAllReservationsOfThisCourtForThisDate: Array<Tennis_Reservation>) : string {
        //possible statuses: "free" / "reservedByOtherPlayer" / "reservedByThisPlayer" / "reservedByTheOwnerOfTheRoom"
        var resultStr: string = "*STATUS*free*INDEX*-1";
        for (let i = 0; i < tmpAllReservationsOfThisCourtForThisDate.length; i++) { 
            const tmpReservation: Tennis_Reservation = tmpAllReservationsOfThisCourtForThisDate[i];
            //check if "tmpStartHour - (tmpStartHour+30min)" timeslot is included in tmpReservation's hours
            const isIncludedInReservation = this.timeslotIsIncludedInReservationDuration(tmpStartHour, tmpReservation.reservation_hour_start, tmpReservation.reservation_hour_stop);
            //-check if "tmpStartHour - (tmpStartHour+30min)" timeslot is included in tmpReservation's hours
            if(isIncludedInReservation === true) {
                //console.log("getTimeslotStatus_Tennis -> tmpReservation: ", JSON.stringify(tmpReservation, null, 2))
                if((LoginManager.getInstance().role==Generic_ConstManager.getInstance().PLAYER_ROLE //he is a Player
                            || (LoginManager.getInstance().role==Generic_ConstManager.getInstance().OWNER_ROLE && LoginManager.getInstance().domain!=LoginManager.getInstance().TENNIS))  //he is an owner but in another domain = here acts as Player!
                        && tmpReservation.by_user_email.toLowerCase()===LoginManager.getInstance().username.toLowerCase()) {
                    resultStr = "*STATUS*reservedByThisPlayer*INDEX*" + i.toString();
                }
                else if(LoginManager.getInstance().role==Generic_ConstManager.getInstance().OWNER_ROLE && tmpReservation.by_user_email.toLowerCase()===LoginManager.getInstance().username.toLowerCase()) {    //web-service side: RESERVATION_MADE_BY_THE_OWNER_OF_THE_COURT = -1000
                    resultStr = "*STATUS*reservedByTheOwnerOfTheRoom*INDEX*" + i.toString();
                }    
                else {
                    resultStr = "*STATUS*reservedByOtherPlayer*INDEX*" + i.toString();
                }    
                //console.log("    resultStr: " + resultStr);        
            }
        }

        return resultStr;
    }

    public timeslotIsIncludedInReservationDuration(tmpStartHour, tmpReservationStartHour, tmpReservationStopHour) : boolean {
        //periptwseis na einai mesa
        // A) |-    |
        if(tmpStartHour === tmpReservationStartHour) {
            return true;
        }
        // B) |  -  |
        else {
            var tmpReservationStartHourFound = false;
            var tmpReservationStopHourFound = false;
            for (let i = 0; i < this.allHours.length; i++) {
                if(this.allHours[i]===tmpReservationStartHour && tmpReservationStartHourFound===false) {
                    tmpReservationStartHourFound = true;
                }
                else if(this.allHours[i]===tmpReservationStopHour && tmpReservationStopHourFound===false) {
                    tmpReservationStopHourFound = true;
                }
                else if(tmpReservationStartHourFound===true && tmpReservationStopHourFound===false && tmpStartHour===this.allHours[i]) {
                    return true;
                }
                else if(tmpReservationStartHourFound===true && tmpReservationStopHourFound===false && i===(this.allHours.length-1)) {
                    i = 0;
                }
            }
        }
        return false;
    }

    public getSelectedDayTitle(tmpDayNum: number, tmpMonthNum: number, tmpYearNum: number, tmpTimeStamp: number) : string {
        //console.log("tmpDayNum: ", tmpDayNum, ", tmpMonthNum: ", tmpMonthNum, ", tmpYearNum: ", tmpYearNum, ", tmpTimeStamp: ", tmpTimeStamp);
        //var tmpDayNameNum: number = new Date(tmpTimeStamp).getDay(); //0: Sun, 1: Mon, etc.
        var tmpDayNameNum: number = new Date(tmpYearNum, tmpMonthNum-1, tmpDayNum).getDay(); //0: Sun, 1: Mon, etc.
        
        var tmpDayStr: string = '';
        if(tmpDayNameNum == 0) tmpDayStr = i18n.t('sundayTitle');
        else if(tmpDayNameNum == 1) tmpDayStr = i18n.t('mondayTitle');
        else if(tmpDayNameNum == 2) tmpDayStr = i18n.t('tuesdayTitle');
        else if(tmpDayNameNum == 3) tmpDayStr = i18n.t('wednesdayTitle');
        else if(tmpDayNameNum == 4) tmpDayStr = i18n.t('thursdayTitle');
        else if(tmpDayNameNum == 5) tmpDayStr = i18n.t('fridayTitle');
        else if(tmpDayNameNum == 6) tmpDayStr = i18n.t('saturdayTitle');
        
        var tmpMonthStr: string = '';
        if(tmpMonthNum == 1) tmpMonthStr = i18n.t('januaryTitle');
        else if(tmpMonthNum == 2) tmpMonthStr = i18n.t('februaryTitle');
        else if(tmpMonthNum == 3) tmpMonthStr = i18n.t('marchTitle');
        else if(tmpMonthNum == 4) tmpMonthStr = i18n.t('aprilTitle');
        else if(tmpMonthNum == 5) tmpMonthStr = i18n.t('mayTitle');
        else if(tmpMonthNum == 6) tmpMonthStr = i18n.t('juneTitle');
        else if(tmpMonthNum == 7) tmpMonthStr = i18n.t('julyTitle');
        else if(tmpMonthNum == 8) tmpMonthStr = i18n.t('augustTitle');
        else if(tmpMonthNum == 9) tmpMonthStr = i18n.t('septemberTitle');
        else if(tmpMonthNum == 10) tmpMonthStr = i18n.t('octoberTitle');
        else if(tmpMonthNum == 11) tmpMonthStr = i18n.t('novemberTitle');
        else if(tmpMonthNum == 12) tmpMonthStr = i18n.t('decemberTitle');

        var finalTitleStr = '';
        if(LoginManager.getInstance().language == "EN") {
            var tmpDayNumSuffix = '';
            if(tmpDayNum == 1)
                tmpDayNumSuffix = "st";
            else if(tmpDayNum == 2)
                tmpDayNumSuffix = "nd";
            else if(tmpDayNum == 3)
                tmpDayNumSuffix = "rd";
            else
                tmpDayNumSuffix = "th";
            finalTitleStr = tmpDayStr + ", " + tmpMonthStr + " " + tmpDayNum + tmpDayNumSuffix + " " + tmpYearNum;
        }
        else if(LoginManager.getInstance().language == "GR") {
            finalTitleStr = tmpDayStr + ", " + tmpDayNum + " " + tmpMonthStr + " " + tmpYearNum;
        }

        return finalTitleStr;
    }

    public getFirstDayOfPreviousMonth() {
        const dateObject = new Date();
        dateObject.setDate(1);
        dateObject.setMonth(dateObject.getMonth() - 1);
        const year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1;
        let day = dateObject.getDate();
      
        if (month < 10) {
          month = `0${month}`;
        }
      
        if (day < 10) {
          day = `0${day}`;
        }
      
        return `${day}/${month}/${year}`;
    }

    public getLastDayOfNextMonth() {
        let dateObject = new Date();
        dateObject.setMonth(dateObject.getMonth() + 2);
        dateObject.setDate(0); //set to 0, it will set the date to last day of the previous month 
        const year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1;
        let day = dateObject.getDate();
    
        if (month < 10) {
            month = `0${month}`;
        }
    
        if (day < 10) {
            day = `0${day}`;
        }
    
        return `${day}/${month}/${year}`;
    }

    public getLastDayOfNextNextMonth() {
        let dateObject = new Date();
        dateObject.setMonth(dateObject.getMonth() + 3);
        dateObject.setDate(0); //set to 0, it will set the date to last day of the previous month 
        const year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1;
        let day = dateObject.getDate();
    
        if (month < 10) {
            month = `0${month}`;
        }
    
        if (day < 10) {
            day = `0${day}`;
        }
    
        return `${day}/${month}/${year}`;
    }

    public emailIsValid(email) : boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const res = re.test(String(email).toLowerCase());
        console.log("emailIsValid: ", res, " (", email, ")");
        return res;
    }

    public getReservationCostWithCurrency_Studio(tmpStartHour: string, tmpStopHour: string, tmpIsRehearsal: boolean, tmpRoom: Studio_Room, tmpDayNum: number) {
        var resultStr = "";
        
        //prwta vriskw ta misawra
        var misawra : number = 0;
        var tmpStartHourFound : boolean = false;
        var tmpStopHourFound : boolean = false;
        for(let i=0; i<this.allHours.length; i++) {
            if(tmpStartHour == this.allHours[i])
                tmpStartHourFound = true;
            if(tmpStartHourFound==true && tmpStopHour==this.allHours[i])
                tmpStopHourFound = true;
            if(tmpStartHourFound==true && tmpStopHourFound==false)
                misawra++;
            if(tmpStartHourFound==true && tmpStopHourFound==true)
                i = this.allHours.length - 1; //go out of the loop
            if(tmpStartHourFound==true && tmpStopHourFound==false && i==(this.allHours.length-1))
                i = -1; //restart
        }

        var tmpCurrency: string = tmpRoom.currency;
        const pavlaIndex : number = tmpCurrency.indexOf(' - ');
        if(pavlaIndex != 0)
            tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

        if(tmpIsRehearsal == true) {
            //Sunday
            if(tmpDayNum === 0) { resultStr = (misawra*(tmpRoom.sunday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
            //Monday
            else if(tmpDayNum === 1) { resultStr = (misawra*(tmpRoom.monday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
            //Tuesday
            else if(tmpDayNum === 2) { resultStr = (misawra*(tmpRoom.tuesday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
            //Wednesday
            else if(tmpDayNum === 3) { resultStr = (misawra*(tmpRoom.wednesday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
            //Thursday
            else if(tmpDayNum === 4) { resultStr = (misawra*(tmpRoom.thursday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
            //Friday
            else if(tmpDayNum === 5) { resultStr = (misawra*(tmpRoom.friday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
            //Saturday
            else if(tmpDayNum === 6) { resultStr = (misawra*(tmpRoom.saturday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency; }
        }
        else {
            //Sunday
            if(tmpDayNum === 0) { resultStr = (misawra*(tmpRoom.sunday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
            //Monday
            else if(tmpDayNum === 1) { resultStr = (misawra*(tmpRoom.monday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
            //Tuesday
            else if(tmpDayNum === 2) { resultStr = (misawra*(tmpRoom.tuesday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
            //Wednesday
            else if(tmpDayNum === 3) { resultStr = (misawra*(tmpRoom.wednesday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
            //Thursday
            else if(tmpDayNum === 4) { resultStr = (misawra*(tmpRoom.thursday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
            //Friday
            else if(tmpDayNum === 5) { resultStr = (misawra*(tmpRoom.friday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
            //Saturday
            else if(tmpDayNum === 6) { resultStr = (misawra*(tmpRoom.saturday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency; }
        }
        console.log("getReservationCostWithCurrency_Studio -> tmpStartHour:", tmpStartHour, ", tmpStopHour:", tmpStopHour, ", tmpIsRehearsal:", tmpIsRehearsal, ", tmpRoom:", tmpRoom, ", tmpDayNum:", tmpDayNum);
        console.log("   resultStr:", resultStr);
        return resultStr;
    }

    public getReservationCostWithCurrency_Tennis(tmpStartHour: string, tmpStopHour: string, tmpCourt: Tennis_Court, tmpDayNum: number) {
        var resultStr = "";
        
        //prwta vriskw ta misawra
        var misawra : number = 0;
        var tmpStartHourFound : boolean = false;
        var tmpStopHourFound : boolean = false;
        for(let i=0; i<this.allHours.length; i++) {
            if(tmpStartHour == this.allHours[i])
                tmpStartHourFound = true;
            if(tmpStartHourFound==true && tmpStopHour==this.allHours[i])
                tmpStopHourFound = true;
            if(tmpStartHourFound==true && tmpStopHourFound==false)
                misawra++;
            if(tmpStartHourFound==true && tmpStopHourFound==true)
                i = this.allHours.length - 1; //go out of the loop
            if(tmpStartHourFound==true && tmpStopHourFound==false && i==(this.allHours.length-1))
                i = -1; //restart
        }

        var tmpCurrency: string = tmpCourt.currency;
        const pavlaIndex : number = tmpCurrency.indexOf(' - ');
        if(pavlaIndex != 0)
            tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

        //Sunday
        if(tmpDayNum === 0) { resultStr = (misawra*(tmpCourt.sunday_cost_per_hour/2)).toString() + " " + tmpCurrency; }
        //Monday
        else if(tmpDayNum === 1) { resultStr = (misawra*(tmpCourt.monday_cost_per_hour/2)).toString() + " " + tmpCurrency; }
        //Tuesday
        else if(tmpDayNum === 2) { resultStr = (misawra*(tmpCourt.tuesday_cost_per_hour/2)).toString() + " " + tmpCurrency; }
        //Wednesday
        else if(tmpDayNum === 3) { resultStr = (misawra*(tmpCourt.wednesday_cost_per_hour/2)).toString() + " " + tmpCurrency; }
        //Thursday
        else if(tmpDayNum === 4) { resultStr = (misawra*(tmpCourt.thursday_cost_per_hour/2)).toString() + " " + tmpCurrency; }
        //Friday
        else if(tmpDayNum === 5) { resultStr = (misawra*(tmpCourt.friday_cost_per_hour/2)).toString() + " " + tmpCurrency; }
        //Saturday
        else if(tmpDayNum === 6) { resultStr = (misawra*(tmpCourt.saturday_cost_per_hour/2)).toString() + " " + tmpCurrency; }

        return resultStr;
    }

    public getCourtType_Tennis(tmpType : number) : string 
    {
        if(tmpType == 0)    //ACRYLIC
           return i18n.t('acrylic');
        else if(tmpType == 1)   //ARTIFICIAL_CLAY
            return i18n.t('artificialClay');
        else if(tmpType == 2)   //ARTIFICIAL_GRASS
            return i18n.t('artificialGrass');
        else if(tmpType == 3)   //ASPHALT
            return i18n.t('ashpalt');
        else if(tmpType == 4)   //CARPET
            return i18n.t('carpet');
        else if(tmpType == 5)   //CLAY
            return i18n.t('clay');
        else if(tmpType == 6)   //CONCRETE
            return i18n.t('concrete');
        else if(tmpType == 7)   //GRASS
            return i18n.t('grass');
        else if(tmpType == 8)   //OTHER
            return i18n.t('other');
        return "unknown";
    }

    /*public getTableCellTextColor(tmpCellStr : string) : string
    {
        var resColor = "black";
        //tmpCellSt will be: 
        //for working hours -> e.g. "12:00" or "closed"
        //for cost -> e.g. "10 EUR" or i18n.t('freeCost')
        var tmpCellStrIsForCost : boolean = false;

        if(tmpCellStr != "") {
            if(tmpCellStr.indexOf(":")==-1 && tmpCellStr.indexOf(i18n.t('closed'))==-1) 
                tmpCellStrIsForCost = true;
            if(tmpCellStrIsForCost == false) { //working hours
                if(tmpCellStr == "closed")
                    return "red";
            }
            else { //cost
                const costValue : number = parseInt(tmpCellStr.split(" ")[0], 10);
            }
        }
        console.log("getTableCellTextColor -> color of '" + tmpCellStr + "' is " + resColor);
        return resColor;
    }*/

    public dateHasPassed(dateString: string): boolean { //input in format DD/MM/YYYY
        const inputDate = new Date(dateString.split('/').reverse().join('/'));
        const today = new Date();
        return inputDate < today && inputDate.getDate() !== today.getDate();
    }

    public getCurrentYear(): string {
        return new Date().getFullYear().toString();
      }
      
    public getImageDimensions = async (imageUrl) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = imageUrl;
      
          img.onload = () => {
            resolve({
              width: img.width,
              height: img.height,
            });
          };
      
          img.onerror = () => {
            //reject(new Error('UtilsManager -> getImageDimensions: Failed to load image -> ', imageUrl));
            console.log('UtilsManager -> getImageDimensions: Failed to load image -> ', imageUrl + ' default dimensions (50, 50) returned!');
            resolve({
                width: 250,
                height: 250,
              });
          };
        });
    };

    public calculateImageScale = (
        containerWidth: number,
        containerHeight: number,
        imageActualWidth: number,
        imageActualHeight: number,
        maxZoomFactor: number
      ) => {
        if (containerWidth <= 0 || containerHeight <= 0 || imageActualWidth <= 0 || imageActualHeight <= 0) {
          return { initialScale: 1, maxScale: 1 };
        }
      
        var initialScale = 1;
        if(imageActualWidth>containerWidth || imageActualHeight>containerHeight)
            initialScale = Math.min(containerWidth / imageActualWidth, containerHeight / imageActualHeight);
        const maxScale = initialScale * maxZoomFactor;
      
        return { initialScale, maxScale };
    };

    public parseReservationDetailsFromAgendaItem(tmpReservationData: string): ReservationDetailsRetrievedFromAgendaItem {
        var tmpReservationDetailsRetrievedFromAgendaItem: ReservationDetailsRetrievedFromAgendaItem = {
            webAgendaItem_JSONstringified: "",
            reservationType: -1,
            itemDomain: "unknown",
            ownerID: -1,
            companyName: "unknown",            
            roomID: -1,
            courtID: -1,            
            roomName: "unknown",
            courtName: "unknown",       
            reservationID: -1,     
            reservationDate: "unknown",
            reservationStartTime: "unknown",
            reservationStopTime: "unknown",
            isRehearsal: false,
            reservationCost: "",
            reservationComments: "",
            playerName: "unknown",
            playerTel: "",
            playerEmail: "unknown",
            playerBand: "",
            playerTag: ""
        };

        var tmpCurReservationType : number = -1;
        var itemDomain = "";
        if(tmpReservationData.indexOf("*ROOM_NAME*") != -1)
            itemDomain = LoginManager.getInstance().STUDIO;
        if(tmpReservationData.indexOf("*COURT_NAME*") != -1)
            itemDomain = LoginManager.getInstance().TENNIS;
        tmpReservationDetailsRetrievedFromAgendaItem.itemDomain = itemDomain; //now set it!

        ////////////
        // OWNERS //
        ////////////
        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
            ////////////
            // STUDIO //
            ////////////    
            //Kwdikopoihsh gia Reservations Studio owner san Player sto STUDIO domain (prosoxh: mporei na exei kanei krathsh sto diko toy studio - prepei na fygoun ta diplotypa!):
            // name: "*OWNER_ID*45*OWNER_COMPANY_NAME*Test studios*ROOM_ID*23*ROOM_NAME*Room 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*REHEARSAL*yes*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments"
            //const RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN: number = 0;
            ////////////
            // TENNIS //
            ////////////    
            //Kwdikopoihsh gia Reservations Studio owner san Player sto TENNIS domain:
            // name: "*OWNER_ID*45*OWNER_COMPANY_NAME*Test courts*COURT_ID*23*COURT_NAME*Court 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments"
            //const RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN: number = 1;

            ///////////////////////////////////////////////////////////////////////////
            // STUDIO - Kwdikopoihsh gia Reservations Studio Owner gia to studio toy //
            ///////////////////////////////////////////////////////////////////////////
            // name: "*RESERVATION_ID*43*ROOM_NAME*Room 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*REHEARSAL*yes*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments*MUSICIAN_NAME*xyz*MUSICIAN_TEL*xyz*MUSICIAN_EMAIL*xyz*MUSICIAN_BAND*xyz"
            //const RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_OWNER_IN_STUDIO_DOMAIN: number = 2;

            //first find reservation type
            if(LoginManager.getInstance().role == LoginManager.getInstance().OWNER_ROLE) {
                if(tmpReservationData.indexOf("*RESERVATION_ID*") != -1)
                    tmpCurReservationType = this.RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_OWNER_IN_STUDIO_DOMAIN;
                else {
                    if(tmpReservationData.indexOf("*ROOM_ID*") != -1) {
                        tmpCurReservationType = this.RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN;
                    }
                    else if(tmpReservationData.indexOf("*COURT_ID*") != -1) {
                        tmpCurReservationType = this.RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN;
                    }
                }
            }
            else if(LoginManager.getInstance().role == LoginManager.getInstance().PLAYER_ROLE) {
                if(tmpReservationData.indexOf("*ROOM_ID*") != -1) {
                    tmpCurReservationType = this.RESERVATION_TYPE_STUDIOPLAYER_RESERVED_IN_STUDIO_DOMAIN;
                }
                else if(tmpReservationData.indexOf("*COURT_ID*") != -1) {
                    tmpCurReservationType = this.RESERVATION_TYPE_STUDIOPLAYER_RESERVED_IN_TENNIS_DOMAIN;
                }
            }
            tmpReservationDetailsRetrievedFromAgendaItem.reservationType = tmpCurReservationType; //now set it!

            //then handle reservations where owner acts as player
            if(tmpCurReservationType == this.RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN
                || tmpCurReservationType == this.RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN)
            {
              const tempOwnerID: number = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_ID*")+10, tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")));
              tmpReservationDetailsRetrievedFromAgendaItem.ownerID = tempOwnerID; //now set it!

              var tempCompanyName: string = "";
              if(itemDomain == LoginManager.getInstance().STUDIO)
                tempCompanyName = tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")+20, tmpReservationData.indexOf("*ROOM_ID*"));
              if(itemDomain == LoginManager.getInstance().TENNIS)
                tempCompanyName = tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")+20, tmpReservationData.indexOf("*COURT_ID*"));
              tmpReservationDetailsRetrievedFromAgendaItem.companyName = tempCompanyName; //now set it!

              var tempRoomID: number = -1;
              if(itemDomain == LoginManager.getInstance().STUDIO)
                tempRoomID = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_ID*")+9, tmpReservationData.indexOf("*ROOM_NAME*")));
              tmpReservationDetailsRetrievedFromAgendaItem.roomID = tempRoomID; //now set it!
              
              var tempCourtID: number = -1;
              if(itemDomain == LoginManager.getInstance().TENNIS)
                tempCourtID = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*COURT_ID*")+10, tmpReservationData.indexOf("*COURT_NAME*")));
              tmpReservationDetailsRetrievedFromAgendaItem.courtID = tempCourtID; //now set it!
              
              var tempRoomName: string = "";
              if(itemDomain == LoginManager.getInstance().STUDIO)
                tempRoomName = tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_NAME*")+11, tmpReservationData.indexOf("*RESERVATION_DATE*"));
              tmpReservationDetailsRetrievedFromAgendaItem.roomName = tempRoomName; //now set it!

              var tempCourtName: string = "";
              if(itemDomain == LoginManager.getInstance().TENNIS)
                tempCourtName = tmpReservationData.substring(tmpReservationData.indexOf("*COURT_NAME*")+12, tmpReservationData.indexOf("*RESERVATION_DATE*"));
              tmpReservationDetailsRetrievedFromAgendaItem.courtName = tempCourtName; //now set it!
              
              const tempReservationDate: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_DATE*")+18, tmpReservationData.indexOf("*RESERVATION_START_TIME*"));
              tmpReservationDetailsRetrievedFromAgendaItem.reservationDate = tempReservationDate; //now set it!

              const tempReservationStartTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_START_TIME*")+24, tmpReservationData.indexOf("*RESERVATION_STOP_TIME*"));
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime = tempReservationStartTime; //now set it!

              var tempReservationStopTime: string = "";
              if(itemDomain == LoginManager.getInstance().STUDIO)
                tempReservationStopTime = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*REHEARSAL*"));
              if(itemDomain == LoginManager.getInstance().TENNIS)
                tempReservationStopTime = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*RESERVATION_COST*"));
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime = tempReservationStopTime; //now set it!
              
              var tempIsRehearsalStr: string = "";
              if(itemDomain == LoginManager.getInstance().STUDIO)
                tempIsRehearsalStr = tmpReservationData.substring(tmpReservationData.indexOf("*REHEARSAL*")+11, tmpReservationData.indexOf("*RESERVATION_COST*"));
              var tempIsRehearsalBoolean: boolean = false;
              if(tempIsRehearsalStr === "yes") {
                tempIsRehearsalBoolean = true;
              }
              tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal = tempIsRehearsalBoolean; //now set it!
              
              const tempReservationCost: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COST*")+18, tmpReservationData.indexOf("*RESERVATION_COMMENTS*"));
              tmpReservationDetailsRetrievedFromAgendaItem.reservationCost = tempReservationCost; //now set it!
              
              const tempReservationComments: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COMMENTS*")+22, tmpReservationData.length);
              tmpReservationDetailsRetrievedFromAgendaItem.reservationComments = tempReservationComments; //now set it!    
              
              tmpReservationDetailsRetrievedFromAgendaItem.playerName = i18n.t('reservedByMe');
            }
            else if(tmpCurReservationType == this.RESERVATION_TYPE_STUDIOOWNER_ACTS_AS_OWNER_IN_STUDIO_DOMAIN) {  //owner acts as owner for his studio
              //Kwdikopoihsh gia Reservations Studio Owner gia to studio toy:
              // name: "*RESERVATION_ID*43*ROOM_NAME*Room 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*REHEARSAL*yes*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments*MUSICIAN_NAME*xyz*MUSICIAN_TEL*xyz*MUSICIAN_EMAIL*xyz*MUSICIAN_BAND*xyz"
              const tempReservationID: number = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_ID*")+16, tmpReservationData.indexOf("*ROOM_NAME*")));
              const tempRoomName: string = tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_NAME*")+11, tmpReservationData.indexOf("*RESERVATION_DATE*"));
              const tempReservationDate: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_DATE*")+18, tmpReservationData.indexOf("*RESERVATION_START_TIME*"));
              const tempReservationStartTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_START_TIME*")+24, tmpReservationData.indexOf("*RESERVATION_STOP_TIME*"));
              const tempReservationStopTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*REHEARSAL*"));
              const tempIsRehearsalStr: string = tmpReservationData.substring(tmpReservationData.indexOf("*REHEARSAL*")+11, tmpReservationData.indexOf("*RESERVATION_COST*"));
              var tempIsRehearsalBoolean: boolean = false;
              if(tempIsRehearsalStr === "yes") {
                tempIsRehearsalBoolean = true;
              }
              const tempReservationCost: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COST*")+18, tmpReservationData.indexOf("*RESERVATION_COMMENTS*"));
              const tempReservationComments: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COMMENTS*")+22, tmpReservationData.indexOf("*MUSICIAN_NAME*"));
              const tempMusicianName: string = tmpReservationData.substring(tmpReservationData.indexOf("*MUSICIAN_NAME*")+15, tmpReservationData.indexOf("*MUSICIAN_TEL*"));
              const tempMusicianTel: string = tmpReservationData.substring(tmpReservationData.indexOf("*MUSICIAN_TEL*")+14, tmpReservationData.indexOf("*MUSICIAN_EMAIL*"));
              const tempMusicianEmail: string = tmpReservationData.substring(tmpReservationData.indexOf("*MUSICIAN_EMAIL*")+16, tmpReservationData.indexOf("*MUSICIAN_BAND*"));
              const tempMusicianBand: string = tmpReservationData.substring(tmpReservationData.indexOf("*MUSICIAN_BAND*")+15, tmpReservationData.length);
              
              //now set them!
              tmpReservationDetailsRetrievedFromAgendaItem.reservationID = tempReservationID;
              tmpReservationDetailsRetrievedFromAgendaItem.roomName = tempRoomName;
              tmpReservationDetailsRetrievedFromAgendaItem.reservationDate = tempReservationDate;
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime = tempReservationStartTime;
              tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime = tempReservationStopTime;
              tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal = tempIsRehearsalBoolean;
              tmpReservationDetailsRetrievedFromAgendaItem.reservationCost = tempReservationCost;
              tmpReservationDetailsRetrievedFromAgendaItem.reservationComments = tempReservationComments;
              tmpReservationDetailsRetrievedFromAgendaItem.playerName = tempMusicianName;
              tmpReservationDetailsRetrievedFromAgendaItem.playerTel = tempMusicianTel;
              tmpReservationDetailsRetrievedFromAgendaItem.playerEmail = tempMusicianEmail;
              tmpReservationDetailsRetrievedFromAgendaItem.playerBand = tempMusicianBand;              

              //const dokDayNum: number = UtilsManager.getInstance().findDayNumFromDate(tempReservationDate);
              //const dokDuration: number = UtilsManager.getInstance().findDurationInHours(tempReservationStartTime, tempReservationStopTime);
              
              //console.log(tempReservationDate + " " + tempReservationStartTime + "-" + tempReservationStopTime + ", duration: " + dokDuration + " hours, IsRehearsal: " + tempIsRehearsalStr + ", cost: " + tempReservationCost);

              /*console.log("tempReservationID: ", tempReservationID);
              console.log("tempRoomName: ", tempRoomName);
              console.log("tempReservationDate: ", tempReservationDate);
              console.log("tempReservationStartTime: ", tempReservationStartTime);
              console.log("tempReservationStopTime: ", tempReservationStopTime);
              console.log("tempIsRehearsalStr: ", tempIsRehearsalStr);
              console.log("tempReservationCost: ", tempReservationCost);
              console.log("tempReservationComments: ", tempReservationComments);
              console.log("tempMusicianName: ", tempMusicianName);
              console.log("tempMusicianTel: ", tempMusicianTel);
              console.log("tempMusicianEmail: ", tempMusicianEmail);
              console.log("tempMusicianBand: ", tempMusicianBand);*/              
            }
        }
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
            ////////////
            // STUDIO //
            ////////////    
            //Kwdikopoihsh gia Reservations Tennis Court Owner san Player sto STUDIO domain:
            // name: "*OWNER_ID*45*OWNER_COMPANY_NAME*Test studios*ROOM_ID*23*ROOM_NAME*Room 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*REHEARSAL*yes*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments"
            //const RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN: number = 0;
            ////////////
            // TENNIS //
            ////////////    
            //Kwdikopoihsh gia Reservations Tennis Court Owner san Player sto TENNIS domain (prosoxh: mporei na exei kanei krathsh sta dika toy ghpeda - prepei na fygoun ta diplotypa!):
            // name: "*OWNER_ID*45*OWNER_COMPANY_NAME*Test courts*COURT_ID*23*COURT_NAME*Court 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments"
            //const RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN: number = 1;

            /////////////////////////////////////////////////////////////////////////////////
            // TENNIS - Kwdikopoihsh gia Reservations Tennis Court Owner gia ta ghpeda toy //
            /////////////////////////////////////////////////////////////////////////////////
            // name: "*RESERVATION_ID*43*COURT_NAME*Court 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments*PLAYER_NAME*xyz*PLAYER_TEL*xyz*PLAYER_EMAIL*xyz*PLAYER_TAG*player/member/guest"
            //const RESERVATION_TYPE_TENNISOWNER_ACTS_AS_OWNER_IN_TENNIS_DOMAIN: number = 2;

            //first find reservation type
            if(LoginManager.getInstance().role == LoginManager.getInstance().OWNER_ROLE) {
                if(tmpReservationData.indexOf("*RESERVATION_ID*") != -1)
                    tmpCurReservationType = UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_OWNER_IN_TENNIS_DOMAIN;
                else {
                    if(tmpReservationData.indexOf("*ROOM_ID*") != -1) {
                        tmpCurReservationType = UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN;
                    }
                    else if(tmpReservationData.indexOf("*COURT_ID*") != -1) {
                        tmpCurReservationType = UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN;
                    }
                }
            }
            else if(LoginManager.getInstance().role == LoginManager.getInstance().PLAYER_ROLE) {
                if(tmpReservationData.indexOf("*ROOM_ID*") != -1) {
                    tmpCurReservationType = UtilsManager.getInstance().RESERVATION_TYPE_TENNISPLAYER_RESERVED_IN_STUDIO_DOMAIN;
                }
                else if(tmpReservationData.indexOf("*COURT_ID*") != -1) {
                    tmpCurReservationType = UtilsManager.getInstance().RESERVATION_TYPE_TENNISPLAYER_RESERVED_IN_TENNIS_DOMAIN;
                }
            }
            tmpReservationDetailsRetrievedFromAgendaItem.reservationType = tmpCurReservationType; //now set it!

            //then handle reservations where owner acts as player
            if(tmpCurReservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_STUDIO_DOMAIN
                || tmpCurReservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_PLAYER_IN_TENNIS_DOMAIN)
            {
                const tempOwnerID: number = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_ID*")+10, tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")));
                tmpReservationDetailsRetrievedFromAgendaItem.ownerID = tempOwnerID; //now set it!

                var tempCompanyName: string = "";
                if(itemDomain == LoginManager.getInstance().STUDIO)
                    tempCompanyName = tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")+20, tmpReservationData.indexOf("*ROOM_ID*"));
                if(itemDomain == LoginManager.getInstance().TENNIS)
                    tempCompanyName = tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")+20, tmpReservationData.indexOf("*COURT_ID*"));
                tmpReservationDetailsRetrievedFromAgendaItem.companyName = tempCompanyName; //now set it!

                var tempRoomID: number = -1;
                if(itemDomain == LoginManager.getInstance().STUDIO)
                    tempRoomID = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_ID*")+9, tmpReservationData.indexOf("*ROOM_NAME*")));
                tmpReservationDetailsRetrievedFromAgendaItem.roomID = tempRoomID; //now set it!
                
                var tempCourtID: number = -1;
                if(itemDomain == LoginManager.getInstance().TENNIS)
                    tempCourtID = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*COURT_ID*")+10, tmpReservationData.indexOf("*COURT_NAME*")));
                tmpReservationDetailsRetrievedFromAgendaItem.courtID = tempCourtID; //now set it!
                
                var tempRoomName: string = "";
                if(itemDomain == LoginManager.getInstance().STUDIO)
                    tempRoomName = tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_NAME*")+11, tmpReservationData.indexOf("*RESERVATION_DATE*"));
                tmpReservationDetailsRetrievedFromAgendaItem.roomName = tempRoomName; //now set it!

                var tempCourtName: string = "";
                if(itemDomain == LoginManager.getInstance().TENNIS)
                    tempCourtName = tmpReservationData.substring(tmpReservationData.indexOf("*COURT_NAME*")+12, tmpReservationData.indexOf("*RESERVATION_DATE*"));
                tmpReservationDetailsRetrievedFromAgendaItem.courtName = tempCourtName; //now set it!
                
                const tempReservationDate: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_DATE*")+18, tmpReservationData.indexOf("*RESERVATION_START_TIME*"));
                tmpReservationDetailsRetrievedFromAgendaItem.reservationDate = tempReservationDate; //now set it!
                
                const tempReservationStartTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_START_TIME*")+24, tmpReservationData.indexOf("*RESERVATION_STOP_TIME*"));
                tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime = tempReservationStartTime; //now set it!
                
                var tempReservationStopTime: string = "";
                if(itemDomain == LoginManager.getInstance().STUDIO)
                    tempReservationStopTime = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*REHEARSAL*"));
                if(itemDomain == LoginManager.getInstance().TENNIS)
                    tempReservationStopTime = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*RESERVATION_COST*"));
                tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime = tempReservationStopTime; //now set it!
                
                var tempIsRehearsalStr: string = "";
                if(itemDomain == LoginManager.getInstance().STUDIO)
                    tempIsRehearsalStr = tmpReservationData.substring(tmpReservationData.indexOf("*REHEARSAL*")+11, tmpReservationData.indexOf("*RESERVATION_COST*"));
                var tempIsRehearsalBoolean: boolean = false;
                if(tempIsRehearsalStr === "yes") {
                    tempIsRehearsalBoolean = true;
                }
                tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal = tempIsRehearsalBoolean; //now set it!

                const tempReservationCost: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COST*")+18, tmpReservationData.indexOf("*RESERVATION_COMMENTS*"));
                tmpReservationDetailsRetrievedFromAgendaItem.reservationCost = tempReservationCost; //now set it!

                const tempReservationComments: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COMMENTS*")+22, tmpReservationData.length);
                tmpReservationDetailsRetrievedFromAgendaItem.reservationComments = tempReservationComments; //now set it!

                tmpReservationDetailsRetrievedFromAgendaItem.playerName = i18n.t('reservedByMe');
            }
            else if(tmpCurReservationType == UtilsManager.getInstance().RESERVATION_TYPE_TENNISOWNER_ACTS_AS_OWNER_IN_TENNIS_DOMAIN) {  //owner acts as owner for his tennis courts
                //Kwdikopoihsh gia Reservations Tennis Court Owner gia ta ghpeda toy
                // name: "*RESERVATION_ID*43*COURT_NAME*Court 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments*PLAYER_NAME*xyz*PLAYER_TEL*xyz*PLAYER_EMAIL*xyz*PLAYER_TAG*player/member/guest"

                const tempReservationID: number = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_ID*")+16, tmpReservationData.indexOf("*COURT_NAME*")));
                const tempCourtName: string = tmpReservationData.substring(tmpReservationData.indexOf("*COURT_NAME*")+12, tmpReservationData.indexOf("*RESERVATION_DATE*"));
                const tempReservationDate: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_DATE*")+18, tmpReservationData.indexOf("*RESERVATION_START_TIME*"));
                const tempReservationStartTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_START_TIME*")+24, tmpReservationData.indexOf("*RESERVATION_STOP_TIME*"));
                const tempReservationStopTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*RESERVATION_COST*"));
                const tempReservationCost: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COST*")+18, tmpReservationData.indexOf("*RESERVATION_COMMENTS*"));
                const tempReservationComments: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COMMENTS*")+22, tmpReservationData.indexOf("*PLAYER_NAME*"));
                const tempPlayerName: string = tmpReservationData.substring(tmpReservationData.indexOf("*PLAYER_NAME*")+13, tmpReservationData.indexOf("*PLAYER_TEL*"));
                const tempPlayerTel: string = tmpReservationData.substring(tmpReservationData.indexOf("*PLAYER_TEL*")+12, tmpReservationData.indexOf("*PLAYER_EMAIL*"));
                const tempPlayerEmail: string = tmpReservationData.substring(tmpReservationData.indexOf("*PLAYER_EMAIL*")+14, tmpReservationData.indexOf("*PLAYER_TAG*"));
                const tempPlayerTag: string = tmpReservationData.substring(tmpReservationData.indexOf("*PLAYER_TAG*")+12, tmpReservationData.length);

                //now set them!
                tmpReservationDetailsRetrievedFromAgendaItem.reservationID = tempReservationID;
                tmpReservationDetailsRetrievedFromAgendaItem.courtName = tempCourtName;
                tmpReservationDetailsRetrievedFromAgendaItem.reservationDate = tempReservationDate;
                tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime = tempReservationStartTime;
                tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime = tempReservationStopTime;
                tmpReservationDetailsRetrievedFromAgendaItem.reservationCost = tempReservationCost;
                tmpReservationDetailsRetrievedFromAgendaItem.reservationComments = tempReservationComments;
                tmpReservationDetailsRetrievedFromAgendaItem.playerName = tempPlayerName;
                tmpReservationDetailsRetrievedFromAgendaItem.playerTel = tempPlayerTel;
                tmpReservationDetailsRetrievedFromAgendaItem.playerEmail = tempPlayerEmail;
                tmpReservationDetailsRetrievedFromAgendaItem.playerTag = tempPlayerTag;
            }
        }
        /////////////
        // PLAYERS //
        /////////////
        if(tmpCurReservationType == this.RESERVATION_TYPE_STUDIOPLAYER_RESERVED_IN_STUDIO_DOMAIN
            || tmpCurReservationType == this.RESERVATION_TYPE_STUDIOPLAYER_RESERVED_IN_TENNIS_DOMAIN
            || tmpCurReservationType == this.RESERVATION_TYPE_TENNISPLAYER_RESERVED_IN_STUDIO_DOMAIN
            || tmpCurReservationType == this.RESERVATION_TYPE_TENNISPLAYER_RESERVED_IN_TENNIS_DOMAIN)
        {
            ////////////
            // STUDIO //
            ////////////    
            //Kwdikopoihsh:
            // name: "*OWNER_ID*45*OWNER_COMPANY_NAME*Test studios*ROOM_ID*23*ROOM_NAME*Room 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*REHEARSAL*yes*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments"
            ////////////
            // TENNIS //
            ////////////    
            //Kwdikopoihsh:
            // name: "*OWNER_ID*45*OWNER_COMPANY_NAME*Test courts*COURT_ID*23*COURT_NAME*Court 1*RESERVATION_DATE*22/04/2021*RESERVATION_START_TIME*21:30*RESERVATION_STOP_TIME*22:30*RESERVATION_COST*15 EUR*RESERVATION_COMMENTS*my comments"
            
            const tempOwnerID: number = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_ID*")+10, tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")));
            tmpReservationDetailsRetrievedFromAgendaItem.ownerID = tempOwnerID; //now set it!

            var tempCompanyName: string = "";
            if(itemDomain == LoginManager.getInstance().STUDIO)
                tempCompanyName = tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")+20, tmpReservationData.indexOf("*ROOM_ID*"));
            if(itemDomain == LoginManager.getInstance().TENNIS)
                tempCompanyName = tmpReservationData.substring(tmpReservationData.indexOf("*OWNER_COMPANY_NAME*")+20, tmpReservationData.indexOf("*COURT_ID*"));
            tmpReservationDetailsRetrievedFromAgendaItem.companyName = tempCompanyName; //now set it!

            var tempRoomID: number = -1;
            if(itemDomain == LoginManager.getInstance().STUDIO)
                tempRoomID = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_ID*")+9, tmpReservationData.indexOf("*ROOM_NAME*")));
            tmpReservationDetailsRetrievedFromAgendaItem.roomID = tempRoomID; //now set it!
            
            var tempCourtID: number = -1;
            if(itemDomain == LoginManager.getInstance().TENNIS)
                tempCourtID = parseInt(tmpReservationData.substring(tmpReservationData.indexOf("*COURT_ID*")+10, tmpReservationData.indexOf("*COURT_NAME*")));
            tmpReservationDetailsRetrievedFromAgendaItem.courtID = tempCourtID; //now set it!
            
            var tempRoomName: string = "";
            if(itemDomain == LoginManager.getInstance().STUDIO)
                tempRoomName = tmpReservationData.substring(tmpReservationData.indexOf("*ROOM_NAME*")+11, tmpReservationData.indexOf("*RESERVATION_DATE*"));
            tmpReservationDetailsRetrievedFromAgendaItem.roomName = tempRoomName; //now set it!

            var tempCourtName: string = "";
            if(itemDomain == LoginManager.getInstance().TENNIS)
                tempCourtName = tmpReservationData.substring(tmpReservationData.indexOf("*COURT_NAME*")+12, tmpReservationData.indexOf("*RESERVATION_DATE*"));
            tmpReservationDetailsRetrievedFromAgendaItem.courtName = tempCourtName; //now set it!

            const tempReservationDate: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_DATE*")+18, tmpReservationData.indexOf("*RESERVATION_START_TIME*"));
            tmpReservationDetailsRetrievedFromAgendaItem.reservationDate = tempReservationDate; //now set it!
            
            const tempReservationStartTime: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_START_TIME*")+24, tmpReservationData.indexOf("*RESERVATION_STOP_TIME*"));
            tmpReservationDetailsRetrievedFromAgendaItem.reservationStartTime = tempReservationStartTime; //now set it!
            
            var tempReservationStopTime: string = "";
            if(itemDomain == LoginManager.getInstance().STUDIO)
                tempReservationStopTime = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*REHEARSAL*"));
            if(itemDomain == LoginManager.getInstance().TENNIS)
                tempReservationStopTime = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_STOP_TIME*")+23, tmpReservationData.indexOf("*RESERVATION_COST*"));
            tmpReservationDetailsRetrievedFromAgendaItem.reservationStopTime = tempReservationStopTime;

            var tempIsRehearsalStr: string = "";
            if(itemDomain == LoginManager.getInstance().STUDIO)
                tempIsRehearsalStr = tmpReservationData.substring(tmpReservationData.indexOf("*REHEARSAL*")+11, tmpReservationData.indexOf("*RESERVATION_COST*"));
            var tempIsRehearsalBoolean: boolean = false;
            if(tempIsRehearsalStr === "yes") {
               tempIsRehearsalBoolean = true;
            }
            tmpReservationDetailsRetrievedFromAgendaItem.isRehearsal = tempIsRehearsalBoolean; //now set it!
                
            const tempReservationCost: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COST*")+18, tmpReservationData.indexOf("*RESERVATION_COMMENTS*"));
            tmpReservationDetailsRetrievedFromAgendaItem.reservationCost = tempReservationCost; //now set it!

            const tempReservationComments: string = tmpReservationData.substring(tmpReservationData.indexOf("*RESERVATION_COMMENTS*")+22, tmpReservationData.length);
            tmpReservationDetailsRetrievedFromAgendaItem.reservationComments = tempReservationComments; //now set it!
        }
        console.log("To tmpReservationData: ", tmpReservationData, "\n Edwse -> ", JSON.stringify(tmpReservationDetailsRetrievedFromAgendaItem, null, 2));
        return tmpReservationDetailsRetrievedFromAgendaItem;
    }

}

export default UtilsManager;