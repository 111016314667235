import React, { useState } from "react";
import { Text, Button, Portal, Modal, Card } from "react-native-paper";
import styles from "../../css/myCss";
import { Platform, View, Linking, ScrollView } from "react-native";
import UtilsManager from "../../managers/UtilsManager";
import i18n from '../../utils/i18n';


const PrivacyPolicy = () => {

    const handleHomepagePress = () => { Linking.openURL('https://handybooker.com'); };
    const handleContactPress = () => { Linking.openURL('mailto:studiosAdmin@handybooker.com'); };

    const [privacyPolicyModalVisible, setPrivacyPolicyModalVisible] = useState(false);
    const hidePrivacyPolicyModal = () => { 
        setPrivacyPolicyModalVisible(false);
    }

    let MyPrivacyPolicy;
    if (i18n.locale === 'en') {  
        MyPrivacyPolicy = () =>    <ScrollView style={styles.scrollView}>
                                        <Text style={{ fontWeight: 'bold', fontSize: 24, margin: 5, alignSelf: 'center' }}>Privacy policy</Text>
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Company Details" />
                                                <Card.Content>
                                                        <View style={{ flexDirection: 'row' }}>
                                                                <Text variant="bodyMedium">{'\u2022'} Website: </Text>
                                                                <Text variant="bodyMedium" style={{ color: 'blue' }} onPress={handleHomepagePress}>https://handybooker.com</Text>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                                <Text variant="bodyMedium">{'\u2022'} Contact: </Text>
                                                                <Text variant="bodyMedium" style={{ color: 'blue' }} onPress={handleContactPress}>studiosAdmin@handybooker.com</Text>
                                                        </View>
                                                </Card.Content>
                                        </Card>
                                        <Card style={styles.card}>
                                                <Card.Content>
                                                        <Text variant="bodyMedium">This app is aimed at people of any age.</Text>
                                                </Card.Content>
                                        </Card>
                                        
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Data collection" />
                                                <Card.Content>
                                                        <Text variant="bodyMedium">This app collects information from you so we can:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Give you the service you wanted</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Improve the app over time</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text variant="bodyMedium">HandyBooker shares your data with:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} None</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text variant="bodyMedium">No cookies are used</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text variant="bodyMedium">This app collects the following data:</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text style={{ fontWeight: 'bold' }} variant="bodyMedium">User Details</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Name</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Telephone number</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Email address</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Company name (for company owners)</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Postal address (for company owners)</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Website (for company owners)</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Extra info related with specific domains e.g. Band name (for musicians)</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text style={{ fontWeight: 'bold' }} variant="bodyMedium">Location</Text>
                                                        <Text variant="bodyMedium">HandyBooker accesses user's location so it can:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Help the user navigate</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Serve content relevant to user's location</Text>
                                                </Card.Content>
                                        </Card>
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Security" />
                                                <Card.Content>
                                                        <Text variant="bodyMedium">The security of your personal information is extremely important.</Text>
                                                        <Text variant="bodyMedium">We take the following precautions to make sure it cannot be accessed or altered from third parties:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Selected data (e.g. password) encrypted in transit</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Selected data (e.g. password) encrypted in storage</Text>
                                                </Card.Content>
                                        </Card>
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Control" />
                                                <Card.Content>
                                                <Text variant="bodyMedium">HandyBooker allows you to access, amend and/or delete your data.</Text>
                                                        <Text variant="bodyMedium">This can be achieved as follows:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Users can update/delete all the information of their profile.</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} If they wish to delete their account, the corresponding information is also deleted from HandyBooker's database.</Text>
                                                </Card.Content>
                                        </Card>
                                </ScrollView>
    } else if (i18n.locale === 'gr') {  
        MyPrivacyPolicy = () =>    <ScrollView style={styles.scrollView}>
                                        <Text style={{ fontWeight: 'bold', fontSize: 24, margin: 5, alignSelf: 'center' }}>Πολιτική απορρήτου</Text>
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Πληροφορίες εταιρίας" />
                                                <Card.Content>
                                                        <View style={{ flexDirection: 'row' }}>
                                                                <Text variant="bodyMedium">{'\u2022'} Ιστοσελίδα: </Text>
                                                                <Text variant="bodyMedium" style={{ color: 'blue' }} onPress={handleHomepagePress}>https://handybooker.com</Text>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                                <Text variant="bodyMedium">{'\u2022'} Επικοινωνία: </Text>
                                                                <Text variant="bodyMedium" style={{ color: 'blue' }} onPress={handleContactPress}>studiosAdmin@handybooker.com</Text>
                                                        </View>
                                                </Card.Content>
                                        </Card>
                                        <Card style={styles.card}>
                                                <Card.Content>
                                                        <Text variant="bodyMedium">Αυτή η εφαρμογή απευθύνεται σε άτομα οποιασδήποτε ηλικίας.</Text>
                                                </Card.Content>
                                        </Card>
                                        
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Συλλογή δεδομένων" />
                                                <Card.Content>
                                                        <Text variant="bodyMedium">Αυτή η εφαρμογή συλλέγει δεδομένα από εσάς, ώστε να μπορούμε:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Να σας παρέχουμε τις υπηρεσίες που επιθυμείτε</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Να βελτιώνουμε την εφαρμογή στο πέρασμα του χρόνου</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text variant="bodyMedium">Το HandyBooker μοιράζεται τα δεδομένα σας με:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Κανέναν</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text variant="bodyMedium">Δε χρησιμοποιούνται cookies</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text variant="bodyMedium">Αυτή η εφαρμογή συλλέγει τα παρακάτω δεδομένα:</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text style={{ fontWeight: 'bold' }} variant="bodyMedium">Πληροφορίες χρήστη</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Όνομα</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Αριθμός τηλεφώνου</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Email διεύθυνση</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Όνομα επιχείρησης (για ιδιοκτήτες επιχειρήσεων)</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Ταχυδρομική διεύθυνση (για ιδιοκτήτες επιχειρήσεων)</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Ιστοσελίδα (για ιδιοκτήτες επιχειρήσεων)</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Έξτρα πληροφορίες που σχετίζονται με συγκεκριμένες κατηγορίες π.χ. Όνομα μπάντας (για μουσικούς)</Text>

                                                        <View style={styles.lineSeparator} />

                                                        <Text style={{ fontWeight: 'bold' }} variant="bodyMedium">Τοποθεσία</Text>
                                                        <Text variant="bodyMedium">Το HandyBooker προσπελαύνει δεδομένα τοποθεσίας του χρήστη, ώστε να μπορεί:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Να βοηθά το χρήστη να πλοηγηθεί</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Να προσφέρει περιεχόμενο σχετικό με την τοποθεσία του χρήστη</Text>
                                                </Card.Content>
                                        </Card>
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Ασφάλεια" />
                                                <Card.Content>
                                                        <Text variant="bodyMedium">Η ασφάλεια των προσωπικών σας πληροφοριών είναι πάρα πολύ σημαντική.</Text>
                                                        <Text variant="bodyMedium">Λαμβάνουμε τα παρακάτω μέτρα για να διασφαλίσουμε ότι δεν θα προσπελαστούν ή τροποποιηθούν από τρίτους:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Επιλεγμένα δεδομένα (π.χ. κωδικοί πρόσβασης) μεταφέρονται κρυπτογραφημένα</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Επιλεγμένα δεδομένα (π.χ. κωδικοί πρόσβασης) αποθηκεύονται κρυπτογραφημένα</Text>
                                                </Card.Content>
                                        </Card>
                                        <Card style={styles.card}>
                                                <Card.Title titleStyle={{ fontWeight: 'bold' }} title="Έλεγχος" />
                                                <Card.Content>
                                                <Text variant="bodyMedium">Το HandyBooker σας επιτρέπει να προσπελαύνετε, να τροποποιείτε και/ή να διαφράφετε τα δεδομένα σας.</Text>
                                                        <Text variant="bodyMedium">Αυτό επιτυγχάνεται μέσω των παρακάτω:</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Οι χρήστες μπορούν να ανανεώσουν/διαγράψουν όλες τις πληροφορίες του προφίλ τους.</Text>
                                                        <Text variant="bodyMedium">{'\u2022'} Αν οι χρήστες επιθυμούν να διαγράψουν το προφίλ τους, οι αντίστοιχες πληροφορίες διαγράφονται και από τη βάση δεδομένων του HandyBooker.</Text>
                                                </Card.Content>
                                        </Card>
                                </ScrollView>
    }

     return (
        <View style={styles.container}>
                <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                        <Text style={styles.copyright}>Copyright © {UtilsManager.getInstance().getCurrentYear()} HandyBooker</Text>
                        <Button labelStyle={{ fontSize: 12 }} mode="text" onPress={() => setPrivacyPolicyModalVisible(true)}>{i18n.t('privacyPolicy')}</Button>
                </View>
                <Portal>
                        <Modal visible={privacyPolicyModalVisible} onDismiss={hidePrivacyPolicyModal} contentContainerStyle={styles.modalContainer}>
                                <MyPrivacyPolicy/>                               
                        </Modal>
                </Portal>
        </View>
     );
    };
   
export default PrivacyPolicy; 
