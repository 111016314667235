import React, { useState } from "react";
import { Platform } from "react-native";
import { Button, Dialog, Paragraph, Appbar, Portal, Menu, Divider } from "react-native-paper";
import { useNavigation } from '@react-navigation/native';
import i18n from '../../utils/i18n';
import LoginManager from "../../managers/LoginManager";
import styles from "../../css/myCss";
import Generic_RestManager from "../../managers/Generic_RestManager";
import { MaterialCommunityIcons } from "@expo/vector-icons";
   
type Props = {
  screenTitle: string;
};  
  
const PlayerMenuOptions = ({ screenTitle }: Props) => {
  const navigation = useNavigation();

  const [menuVisible, setMenuVisible] = useState(false);
  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);
  const hideLogoutDialog = () => setLogoutDialogVisible(false);

  const MORE_ICON = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';
  function goToMyProfile() {
    closeMenu();
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
      navigation.navigate('PlayerMyProfile', { title: i18n.t('musicianProfile'), backLabel: i18n.t('back') });
    else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
      navigation.navigate('PlayerMyProfile', { title: i18n.t('tennisPlayerProfile'), backLabel: i18n.t('back') });
  }

  function goToSettings() {
    closeMenu();
    navigation.navigate('PlayerSettings', { title: i18n.t('accountSettings'), backLabel: i18n.t('back') });
  }

  function goToContactUs() {
    closeMenu();
    navigation.navigate('PlayerContactUs', { title: i18n.t('contactUsMenuTitle'), backLabel: i18n.t('back') });
  }

  function logout() {
    setLogoutDialogVisible(false);
    /*const webServiceResponseBoolean : boolean = */Generic_RestManager.getInstance().deleteToken();
    LoginManager.getInstance().logout();
    navigation.reset({
      index: 1,
      routes: [{ name: 'HandyBooker_Home' }]
    });
  }
 
   return (
    <Appbar.Header>
        <Appbar.Content title={screenTitle} />
        <Portal>
          <Dialog visible={logoutDialogVisible} onDismiss={hideLogoutDialog} style={styles.dialog}>
            <Dialog.Title>{i18n.t('logout')}</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setLogoutDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => logout()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        
        <Menu
          visible={menuVisible}
          onDismiss={closeMenu}
          anchor={<Appbar.Action icon={({ color, size }) => <MaterialCommunityIcons name={MORE_ICON} size={size} color="white" />} onPress={openMenu} />}
          style={styles.menu}
          >
            <Menu.Item onPress={() => {goToMyProfile()}} title={i18n.t('myProfile')} icon={({ color, size }) => (<MaterialCommunityIcons name="account" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={() => {goToSettings()}} title={i18n.t('settings')} icon={({ color, size }) => (<MaterialCommunityIcons name="cog-outline" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={() => {goToContactUs()}} title={i18n.t('contactUsMenuTitle')} icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={() => {closeMenu(); setLogoutDialogVisible(true);}} title={i18n.t('logout')} icon={({ color, size }) => (<MaterialCommunityIcons name="logout" color={color} size={size} />)} />
        </Menu>            
    </Appbar.Header>
   );
  };
 
  export default PlayerMenuOptions;