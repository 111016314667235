import React, { useState, useEffect, useRef } from 'react';
import { Appbar, TextInput, Button, Snackbar } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import UtilsManager from '../../managers/UtilsManager';
import AdBanner from '../generic/AdBanner';
import styles from '../../css/myCss';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import LeftColumnWeb from '../generic/LeftColumnWeb';
import RightColumnWeb from '../generic/RightColumnWeb';


//Alert
  //react native
    //already imported above
  //web
    import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const PlayerMyProfileView = () => {

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("PlayerMyProfileView: I'm ready!");
    (async () => {
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
      if(handleWebPageReloadActuallyRun) {
        setCurNonEncryptedPassword(LoginManager.getInstance().password);
        setCurMD5EncodedPassword(LoginManager.getInstance().md5EncodedPassword);
        setCurNonEncryptedPasswordAgain(LoginManager.getInstance().password);  
        setCurMusician(LoginManager.getInstance().loggedInMusician);
        setCurTennisPlayer(LoginManager.getInstance().loggedInTennisPlayer);
      }
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  useEffect(() => {
    if(snackBarVisible == false) {
      setSnackBarText("");
    }
  }, [snackBarVisible]);
  const onDismissSnackBar = () => setSnackBarVisible(false);

  const [snackBarText, setSnackBarText] = useState<string>('');
  useEffect(() => {
    if(snackBarText != '') {
      setIsLoading(false);
      setSnackBarVisible(true);
    }
  }, [snackBarText]);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    
  }, [isLoading]);
  
  const [curNonEncryptedPassword, setCurNonEncryptedPassword] = useState(LoginManager.getInstance().password);
  useEffect(() => {
    (async () => {
      if(Platform.OS == "web") {
        const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(curNonEncryptedPassword);
        console.log('Digest: ', digest);
        setCurMD5EncodedPassword(digest);

        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
          if(curMusician.id != -1) {
            setCurMusician({...curMusician, 
              password: digest
            });
          }
        }
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
          if(curTennisPlayer.id != -1) {
            setCurTennisPlayer({...curTennisPlayer, 
              password: digest
            });
          }
        }
      }
      else { //mobile
        const digest = await UtilsManager.getInstance().encodeWithMD5(curNonEncryptedPassword);
        console.log('Digest: ', digest);
        setCurMD5EncodedPassword(digest);

        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
          setCurMusician({...curMusician, 
            password: digest
          });
        }
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
          setCurTennisPlayer({...curTennisPlayer, 
            password: digest
          });
        }
      }
    })();
  }, [curNonEncryptedPassword]);
  const [curMD5EncodedPassword, setCurMD5EncodedPassword] = useState(LoginManager.getInstance().md5EncodedPassword);
  const [curNonEncryptedPasswordAgain, setCurNonEncryptedPasswordAgain] = useState(LoginManager.getInstance().password);
  
  const [curMusician, setCurMusician] = useState<Studio_Player>(LoginManager.getInstance().loggedInMusician);
  useEffect(() => {
    console.log("useEffect - curMusician: ", curMusician);
  }, [curMusician]);

  const [curTennisPlayer, setCurTennisPlayer] = useState<Tennis_Player>(LoginManager.getInstance().loggedInTennisPlayer);
  
  async function saveProfile() {
    if( (LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && curMusician.name=="")
        || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && curTennisPlayer.name=="") ) {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompleteName'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompleteName'));
    }
    else if( (LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && curMusician.surname=="")
        || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && curTennisPlayer.surname=="") ) {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompleteSurname'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompleteSurname'));
    }
    else if( (LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && curMusician.telephone=="")
        || (LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && curTennisPlayer.telephone=="") ) {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompletePhone'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompletePhone'));
    }
    else if(curNonEncryptedPassword === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompletePassword'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompletePassword'));
    }
    else if(curNonEncryptedPasswordAgain === "") {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('pleaseCompletePasswordAgain'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('pleaseCompletePasswordAgain'));
    }
    else if(curNonEncryptedPassword != curNonEncryptedPasswordAgain) {
      if(Platform.OS === 'web') {
        setAlertForWebText(i18n.t('passwordsDoNotMatch'));
        setAlertForWebVisible(true);
      }
      else
        alert(i18n.t('passwordsDoNotMatch'));
    }
    else {
      //first update password
      if(curMD5EncodedPassword != LoginManager.getInstance().md5EncodedPassword) {
          console.log("New password stored locally! OLD LoginManager.getInstance().md5EncodedPassword: " + LoginManager.getInstance().md5EncodedPassword + ", NEW curMD5EncodedPassword: " + curMD5EncodedPassword);
      }        
      setIsLoading(true);   
      let updatedMusician : Studio_Player;
      let updatedTennisPlayer : Tennis_Player;
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        updatedMusician = await Studio_RestManager.getInstance().editPlayer(curMusician);
        //update stored object
        LoginManager.getInstance().loggedInMusician = updatedMusician;
        setCurMusician(updatedMusician);
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        updatedTennisPlayer = await Tennis_RestManager.getInstance().editPlayer(curTennisPlayer);
        //update stored object
        LoginManager.getInstance().loggedInTennisPlayer = updatedTennisPlayer;
        setCurTennisPlayer(updatedTennisPlayer);
      }

      //IMPORTANT: this has to be performed AFTER editUser - otherwise editUser will fail as the new password is not yet stored in DB!
      LoginManager.getInstance().password = curNonEncryptedPassword;
      LoginManager.getInstance().md5EncodedPassword = curMD5EncodedPassword;
      
      //store user email/password locally on the device
      if(Platform.OS != 'web')
        SecureStore.setItemAsync('storedNonEncryptedPassword', JSON.stringify(curNonEncryptedPassword)).catch((error) => console.log('Error during storedNonEncryptedPassword storage!', error));
      else //web
        sessionStorage.setItem('storedNonEncryptedPassword', curNonEncryptedPassword);

      setSnackBarText(i18n.t('profileUpdated'));
    }
  }

  // Create a ref for the TextInput components
  const nameInputRef = useRef(null);
  const surnameInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const passwordAgainInputRef = useRef(null);
  const bandInputRef = useRef(null);

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
              <ScrollView style={styles.scrollView}>
                <Appbar.Header>
                  <Appbar.Content title={i18n.t('myProfile')} />
                </Appbar.Header>
                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />

                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => nameInputRef.current.focus()}>
                    <TextInput
                      ref={nameInputRef}
                      label={i18n.t('name')}
                      value={curMusician.name}
                      onChangeText={tmpName => setCurMusician({...curMusician, 
                        name: tmpName
                      })}
                    />
                  </TouchableOpacity>
                } 
                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => surnameInputRef.current.focus()}>
                    <TextInput
                      ref={surnameInputRef}
                      label={i18n.t('surname')}
                      value={curMusician.surname}
                      onChangeText={tmpSurname => setCurMusician({...curMusician, 
                        surname: tmpSurname
                        })}
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TextInput
                    label="Email"
                    value={curMusician.email}
                    style={{color: 'red', backgroundColor: 'lightgray'}}
                    disabled={true}
                  />
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => phoneInputRef.current.focus()}>
                    <TextInput
                      ref={phoneInputRef}
                      label={i18n.t('telephone')}
                      value={curMusician.telephone}
                      onChangeText={tmpTelephone => setCurMusician({...curMusician, 
                        telephone: tmpTelephone
                        })}
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => passwordInputRef.current.focus()}>
                    <TextInput
                      ref={passwordInputRef}
                      label={i18n.t('password')}
                      value={curNonEncryptedPassword}
                      onChangeText={tmpPassword => setCurNonEncryptedPassword(tmpPassword)}
                      secureTextEntry
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => passwordAgainInputRef.current.focus()}>
                    <TextInput
                      ref={passwordAgainInputRef}
                      label={i18n.t('passwordAgain')}
                      value={curNonEncryptedPasswordAgain}
                      onChangeText={tmpPasswordAgain => setCurNonEncryptedPasswordAgain(tmpPasswordAgain)}
                      secureTextEntry
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO && 
                  <TouchableOpacity onPress={() => bandInputRef.current.focus()}>
                    <TextInput
                      ref={bandInputRef}
                      label={i18n.t('bandName')}
                      value={curMusician.band_name}
                      onChangeText={tmpBandName => setCurMusician({...curMusician, 
                        band_name: tmpBandName
                      })}
                    />
                  </TouchableOpacity>
                }
                

                {LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS && 
                  <TouchableOpacity onPress={() => nameInputRef.current.focus()}>
                    <TextInput
                      ref={nameInputRef}
                      label={i18n.t('name')}
                      value={curTennisPlayer.name}
                      onChangeText={tmpName => setCurTennisPlayer({...curTennisPlayer, 
                        name: tmpName
                      })}
                    />
                  </TouchableOpacity>
                } 
                {LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS && 
                  <TouchableOpacity onPress={() => surnameInputRef.current.focus()}>
                    <TextInput
                      ref={surnameInputRef}
                      label={i18n.t('surname')}
                      value={curTennisPlayer.surname}
                      onChangeText={tmpSurname => setCurTennisPlayer({...curTennisPlayer, 
                        surname: tmpSurname
                        })}
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS && 
                  <TextInput
                    label="Email"
                    value={curTennisPlayer.email}
                    style={{color: 'red', backgroundColor: 'lightgray'}}
                    disabled={true}
                  />
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS && 
                  <TouchableOpacity onPress={() => phoneInputRef.current.focus()}>
                    <TextInput
                      ref={phoneInputRef}
                      label={i18n.t('telephone')}
                      value={curTennisPlayer.telephone}
                      onChangeText={tmpTelephone => setCurTennisPlayer({...curTennisPlayer, 
                        telephone: tmpTelephone
                      })}
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS && 
                  <TouchableOpacity onPress={() => passwordInputRef.current.focus()}>
                    <TextInput
                      ref={passwordInputRef}
                      label={i18n.t('password')}
                      value={curNonEncryptedPassword}
                      onChangeText={tmpPassword => setCurNonEncryptedPassword(tmpPassword)}
                      secureTextEntry
                    />
                  </TouchableOpacity>
                }
                {LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS && 
                  <TouchableOpacity onPress={() => passwordAgainInputRef.current.focus()}>
                    <TextInput
                      ref={passwordAgainInputRef}
                      label={i18n.t('passwordAgain')}
                      value={curNonEncryptedPasswordAgain}
                      onChangeText={tmpPasswordAgain => setCurNonEncryptedPasswordAgain(tmpPasswordAgain)}
                      secureTextEntry
                    />
                  </TouchableOpacity>
                }
                
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="content-save" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => saveProfile()} disabled={snackBarVisible==true ? true : false}>{i18n.t('save')}</Button>
              </ScrollView>
            </View>
            <RightColumnWeb/>
            <Snackbar
              style={styles.snackbarUpper}
              visible={snackBarVisible}
              onDismiss={onDismissSnackBar}>
              {snackBarText}
            </Snackbar> 
            {MyAlertForWeb!=null && 
              <MyAlertForWeb
                show={alertForWebVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForWebText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForWebVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForWebVisible(false);
                }}  
              />
            }
          </View>
        </TouchableWithoutFeedback>
        <AdBanner/>
      </KeyboardAvoidingView>
    );
  };

export default PlayerMyProfileView;