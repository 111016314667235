import moment from "moment";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Dimensions, Keyboard, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { Appbar, Button, Checkbox, Colors, DataTable, Divider, Modal, Portal, RadioButton, Text, TextInput, Title } from "react-native-paper";
import styles from "../../css/myCss";
import GUIManager from "../../managers/GUIManager";
import LoginManager from "../../managers/LoginManager";
import Studio_ConstManager from "../../managers/Studio_ConstManager";
import Studio_RestManager from "../../managers/Studio_RestManager";
import Tennis_ConstManager from "../../managers/Tennis_ConstManager";
import Tennis_RestManager from "../../managers/Tennis_RestManager";
import UtilsManager from "../../managers/UtilsManager";
import i18n from '../../utils/i18n';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';
import GenericConstManager from "../../managers/Generic_ConstManager";
import { MaterialCommunityIcons } from "@expo/vector-icons";

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const screenDimensions = Dimensions.get('window');

const OwnerNewReservationModal = ({  
                                setReservationCompletionMsg,
                                newReservation_date        
                            }) => {

//////////////////////////////////
// Generic, All domains - START //
//////////////////////////////////
  const [isLoading, setIsLoading] = useState(false);

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [finishBtnDisabled, setFinishBtnDisabled] = useState<boolean>(false);                                
  const [messageToShow, setMessageToShow] = useState<string | void>('');  
  useEffect(() => {
    //console.log("1 - Start");
    if(messageToShow.length > 0)
    {
      var translatedMessageToShow: string = messageToShow;
      if(messageToShow == Studio_ConstManager.getInstance().RESERVATION_ADDED_MSG
        || messageToShow == Tennis_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
        translatedMessageToShow = i18n.t('reservationAddedSuccessfully');
      }
      else if(messageToShow === Studio_ConstManager.getInstance().CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG
              || messageToShow === Tennis_ConstManager.getInstance().CANNOT_RESERVE_BECAUSE_PLAYER_IS_BLOCKED_MSG) {
        translatedMessageToShow = i18n.t('messageToBlockedPlayer');
      }
      else if(messageToShow === "Invalid date!") {
        translatedMessageToShow = i18n.t('invalidHours');
      }

      setTimeout(() => {  //if I don't use a 500ms delay, Alert disappears automatically on iOS
        // Works on both Android and iOS
        if(MyAlertReactNative != null) {
          MyAlertReactNative.alert(
            translatedMessageToShow,
            '',
            [
              {
                text: 'OK',
                onPress: () => {
                  if(messageToShow == Studio_ConstManager.getInstance().RESERVATION_ADDED_MSG
                    || messageToShow == Tennis_ConstManager.getInstance().RESERVATION_ADDED_MSG) {
                    //handleRefresh();
                    //hideNewReservationModal();
                  }
                  else if(messageToShow === "The room is already reserved at this time slot!") {

                  }
                  else if(messageToShow === "This musician has already another reservation at this time slot!") {
          
                  }
                  else if(messageToShow === "Invalid date!") {
                    
                  }
                  else if(messageToShow === "You do not have permissions for this!") {
                    
                  }
                  setMessageToShow('');
                }
              }
            ],
            { 
              cancelable: false,
            }
          );
        }
      }, 500);
    }
    //console.log("1 - End");
  }, [messageToShow]);

  const [visibleNewReservationDetailsModal, setVisibleNewReservationDetailsModal] = useState(false);
  const showNewReservationDetailsModal = () => { 
    //console.log("2 - Start");
    const tmpReservationValidityResult : ReservationValidityResult = UtilsManager.getInstance().checkIfReservationIsValid(allHoursOfSelectedDay, selectedTimeslots);
    if(tmpReservationValidityResult.resultOfCheck != "ok") {
      if(MyAlertReactNative != null)
        alert(tmpReservationValidityResult.resultOfCheck);
      else if(MyAlertForWeb != null) {
        setAlertForWebText(tmpReservationValidityResult.resultOfCheck);
        setAlertForWebVisible(true);
      }
    }
    else {
      prepareReservationDetails();
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        setNewReservation_Studio((prevState) => ({
          ...prevState,
          reservation_hour_start: tmpReservationValidityResult.startTime,
          reservation_hour_stop: tmpReservationValidityResult.stopTime
        }));
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        setNewReservation_Tennis((prevState) => ({
          ...prevState,
          reservation_hour_start: tmpReservationValidityResult.startTime,
          reservation_hour_stop: tmpReservationValidityResult.stopTime
        }));
      }
      setVisibleNewReservationDetailsModal(true);
    }
    //console.log("2 - End");
  };
  const hideNewReservationDetailsModal = () => setVisibleNewReservationDetailsModal(false);

  const [selectedDayNum, setSelectedDayNum] = useState(-1);
  const [newReservationCostLabel, setNewReservationCostLabel] = useState('');

  function prepareReservationDetails() {
    /*if(newReservation.for_room != -1 
      && newReservation.reservation_date != ''
      && newReservation.reservation_hour_start != ''
      && newReservation.reservation_hour_stop != ''
    )
    {*/
      //console.log("newReservation: ", newReservation);
      
      var misawra: number = 0;
      for (let i = 0; i < selectedTimeslots.length; i++) {
        if(selectedTimeslots[i] === true) {
          misawra++;
        }
      }
      
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
        if(selectedRoom_Studio != undefined) {
          var tmpCurrency: string = selectedRoom_Studio.currency;
          const pavlaIndex : number = tmpCurrency.indexOf(' - ');
          if(pavlaIndex != 0)
            tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

          if(newReservation_Studio.rehearsal == true) {
            //Sunday
            if(selectedDayNum === 0) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.sunday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
            //Monday
            else if(selectedDayNum === 1) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.monday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
            //Tuesday
            else if(selectedDayNum === 2) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.tuesday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
            //Wednesday
            else if(selectedDayNum === 3) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.wednesday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
            //Thursday
            else if(selectedDayNum === 4) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.thursday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
            //Friday
            else if(selectedDayNum === 5) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.friday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
            //Saturday
            else if(selectedDayNum === 6) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.saturday_cost_per_hour_reh/2)).toString() + " " + tmpCurrency); }
          }
          else if(newReservation_Studio.rehearsal == false) {
            //Sunday
            if(selectedDayNum === 0) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.sunday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
            //Monday
            else if(selectedDayNum === 1) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.monday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
            //Tuesday
            else if(selectedDayNum === 2) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.tuesday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
            //Wednesday
            else if(selectedDayNum === 3) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.wednesday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
            //Thursday
            else if(selectedDayNum === 4) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.thursday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
            //Friday
            else if(selectedDayNum === 5) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.friday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
            //Saturday
            else if(selectedDayNum === 6) { setNewReservationCostLabel((misawra*(selectedRoom_Studio.saturday_cost_per_hour_rec/2)).toString() + " " + tmpCurrency); }
          }
        }
      }
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
        if(selectedCourt_Tennis != undefined) {
          var tmpCurrency: string = selectedCourt_Tennis.currency;
          const pavlaIndex : number = tmpCurrency.indexOf(' - ');
          if(pavlaIndex != 0)
            tmpCurrency = tmpCurrency.substr(0, pavlaIndex);
          
          //Sunday
          if(selectedDayNum === 0) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.sunday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
          //Monday
          else if(selectedDayNum === 1) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.monday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
          //Tuesday
          else if(selectedDayNum === 2) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.tuesday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
          //Wednesday
          else if(selectedDayNum === 3) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.wednesday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
          //Thursday
          else if(selectedDayNum === 4) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.thursday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
          //Friday
          else if(selectedDayNum === 5) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.friday_cost_per_hour/2)).toString() + " " + tmpCurrency); }
          //Saturday
          else if(selectedDayNum === 6) { setNewReservationCostLabel((misawra*(selectedCourt_Tennis.saturday_cost_per_hour/2)).toString() + " " + tmpCurrency); }          
        }
      }
  }

  //complete the array with all timeslots
  const [allHoursOfSelectedDay, setAllHoursOfSelectedDay] = useState<Array<string>>([]);
  const [allHoursOfSelectedDayItemsWithUniqueId, setAllHoursOfSelectedItemsWithUniqueId] = useState<Array<TimeslotWithID>>([]);
  let id = 0; //I need a unique identifier, which remains unique even if the list is reorder/modified
  useEffect(() => {
    //console.log("6 - Start");
    if(allHoursOfSelectedDay.length > 0) {
      const tmpItemsWithUniqueId = allHoursOfSelectedDay.map(tmpHour => ({
        id: id++,
        hour: tmpHour,
      }));
      setAllHoursOfSelectedItemsWithUniqueId(tmpItemsWithUniqueId);
    }
    else
        setAllHoursOfSelectedItemsWithUniqueId([]);
    //console.log("6 - End");
  }, [allHoursOfSelectedDay]);

  //here we have all the selected timeslots for the new reservation
  const [selectedTimeslots, setSelectedTimeslots] = useState<Array<boolean>>([]); //it will have exactly the same size with allHoursOfSelectedDay

  const checkBoxOnClick = useCallback((itemID : number) => {
    //console.log("7 - Start");
    setSelectedTimeslots((prevSelectedTimeslots) => {
      const newSelectedTimeslots = [...prevSelectedTimeslots];
      newSelectedTimeslots[itemID] = !newSelectedTimeslots[itemID];
      return newSelectedTimeslots;
    });
    //console.log("7 - End");
  }, [newReservation_date]);

  const completeReservation = async () => {
    //const completeReservation = useCallback(async () => {
      //console.log("8 - Start - new reservation to be added:");
      //console.log(JSON.stringify(newReservation, null, 2));
      setFinishBtnDisabled(true);
      //setIsLoading(true);
      var webServiceResponseStr : string | void;
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        webServiceResponseStr = await Studio_RestManager.getInstance().addReservation(newReservation_Studio);
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        webServiceResponseStr = await Tennis_RestManager.getInstance().addReservation(newReservation_Tennis);
      //setIsLoading(false);
      setReservationCompletionMsg(webServiceResponseStr);
      //setMessageToShow(webServiceResponseStr);
      //console.log("8 - End");
    }
    //}, [newReservation]);
////////////////////////////////
// Generic, All domains - END //
////////////////////////////////

///////////////////////////
// Studio domain - START //
///////////////////////////
  const [selectedRoom_Studio, setSelectedRoom_Studio] = useState<Studio_Room>({
    id: -1,
    owned_by: LoginManager.getInstance().loggedInOwner.id,
    name: "",
    currency: "",
    monday_cost_per_hour_reh: -1.0,
    monday_cost_per_hour_rec: -1.0,
    tuesday_cost_per_hour_reh: -1.0,
    tuesday_cost_per_hour_rec: -1.0,
    wednesday_cost_per_hour_reh: -1.0,
    wednesday_cost_per_hour_rec: -1.0,
    thursday_cost_per_hour_reh: -1.0,
    thursday_cost_per_hour_rec: -1.0,
    friday_cost_per_hour_reh: -1.0,
    friday_cost_per_hour_rec: -1.0,
    saturday_cost_per_hour_reh: -1.0,
    saturday_cost_per_hour_rec: -1.0,
    sunday_cost_per_hour_reh: -1.0,
    sunday_cost_per_hour_rec: -1.0,
    monday_working_hours_start: "",
    tuesday_working_hours_start: "",
    wednesday_working_hours_start: "",
    thursday_working_hours_start: "",
    friday_working_hours_start: "",
    saturday_working_hours_start: "",
    sunday_working_hours_start: "",
    monday_working_hours_stop: "",
    tuesday_working_hours_stop: "",
    wednesday_working_hours_stop: "",
    thursday_working_hours_stop: "",
    friday_working_hours_stop: "",
    saturday_working_hours_stop: "",
    sunday_working_hours_stop: "",
    space: "",
    speakers: "",
    console: "",
    microphones: "",
    drums: "",
    guitars: "",
    basses: "",
    pianos_keyboards: "",
    guitar_amplifiers: "",
    bass_amplifiers: "",
    other_equipment: ""
  });
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if(selectedRoom_Studio!=undefined && selectedRoom_Studio.id!=-1 && selectedRoom_Studio.name!="") {
          const tmpAllReservationsOfTheRoom = await Studio_RestManager.getInstance().getAllReservationsByRoomID(selectedRoom_Studio.id);
          //console.log("room: ", selectedRoom_Studio.name);
          //console.log(JSON.stringify(selectedRoom_Studio, null, 2));
          setAllReservationsOfThisRoom_Studio(tmpAllReservationsOfTheRoom);
    
          setNewReservation_Studio({...newReservation_Studio,
            for_room: selectedRoom_Studio.id
          });
        } else {
          //console.log("selectedRoom_Studio is NULL!!!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedRoom_Studio]);

  const [newReservation_Studio, setNewReservation_Studio] = useState<Studio_Reservation>({
    id: -1,                         //I don't need to complete this field - automatically completed internally by the web service
    for_room: -1,
    rehearsal: true,
    by_user_email: "",               
    reservation_date: newReservation_date,
    reservation_hour_start: '',
    reservation_hour_stop: '',
    date_created: '',               //I don't need to complete this field - automatically completed internally by the web service
    date_last_updated: '',          //I don't need to complete this field - automatically completed internally by the web service
    completed_successfully: false,  //I don't need to complete this field - automatically completed internally by the web service
    comments: ''
  });

  const [selectedRoomIndexInRoomsArray_Studio, setSelectedRoomIndexInRoomsArray_Studio] = useState(0);
  //each time current room is changing, get all reservations for this room
  useEffect(() => {
    if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
      //console.log("3 - Start - selectedRoomIndexInRoomsArray_Studio: ", selectedRoomIndexInRoomsArray_Studio);
      const tmpCurRoom = GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio[selectedRoomIndexInRoomsArray_Studio];
      if(tmpCurRoom!=undefined && tmpCurRoom.id!=-1 && tmpCurRoom.name!="") 
        setSelectedRoom_Studio(tmpCurRoom);
      else
        console.log("tmpCurRoom is NULL!!!")
      //console.log("3 - End");
    }
  }, [selectedRoomIndexInRoomsArray_Studio]);

  //search in all reservations for this room and get all the reservations for the specific day
  const [allReservationsOfThisRoom_Studio, setAllReservationsOfThisRoom_Studio] = useState<Array<Studio_Reservation>>([]);
  useEffect(() => {
    //console.log("4 - Start");
    var newReservationsForThisDate : Array<Studio_Reservation> = [];
    if(allReservationsOfThisRoom_Studio != undefined) {
      for (let i = 0; i < allReservationsOfThisRoom_Studio.length; i++) { 
        const tmpReservation: Studio_Reservation = allReservationsOfThisRoom_Studio[i];
        if(newReservation_date == tmpReservation.reservation_date)
          newReservationsForThisDate.push(tmpReservation);  
      }            
      setAllReservationsOfThisRoomForThisDate_Studio(newReservationsForThisDate);
    }    
    //set timeslots for this room and day
    if(selectedRoom_Studio!=undefined && selectedRoom_Studio.id!=-1 && selectedRoom_Studio.name!="") {
      const tempDayNumber = moment(newReservation_date, 'DD/MM/YYYY').toDate().getDay();
      setCurTimeslots_Studio(tempDayNumber);
    }
    //console.log("4 - End");
  }, [allReservationsOfThisRoom_Studio]);

  const setCurTimeslots_Studio = useCallback((tmpDayNum: number) => {
    //function setCurTimeslots_Studio(tmpDayNum: number) {    
    //console.log("5 - Start - tmpDayNum: ", tmpDayNum);
    //console.log(JSON.stringify(selectedRoom_Studio, null, 2));
    setSelectedDayNum(tmpDayNum);

    var tmpAllHoursOfSelectedDay: Array<string> = new Array(0);
    var tmpAllHoursOfSelectedDaySelected: Array<boolean> = new Array(0);
    var tmpCurSelectedRoomOpenAtSelectedDay = true;
  
    //Sunday is 0, Monday is 1, and so on.
    var tmpStartHourFound : boolean = false;
    var tmpStopHourFound: boolean = false;
    var tmpCurRoomStartHour: string = "";
    var tmpCurRoomStopHour: string = "";
  
    //Sunday
    if(tmpDayNum == 0) {
      tmpCurRoomStartHour = selectedRoom_Studio.sunday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.sunday_working_hours_stop;
      //console.log("Sunday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.sunday_working_hours_start=="closed" || selectedRoom_Studio.sunday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }
    //Monday
    else if(tmpDayNum == 1) {
      tmpCurRoomStartHour = selectedRoom_Studio.monday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.monday_working_hours_stop;
      //console.log("Monday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.monday_working_hours_start=="closed" || selectedRoom_Studio.monday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }
    //Tuesday
    else if(tmpDayNum == 2) {
      tmpCurRoomStartHour = selectedRoom_Studio.tuesday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.tuesday_working_hours_stop;
      //console.log("Tuesday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.tuesday_working_hours_start=="closed" || selectedRoom_Studio.tuesday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }
    //Wednesday
    else if(tmpDayNum == 3) {
      tmpCurRoomStartHour = selectedRoom_Studio.wednesday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.wednesday_working_hours_stop;
      //console.log("Wednesday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.wednesday_working_hours_start=="closed" || selectedRoom_Studio.wednesday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }
    //Thursday
    else if(tmpDayNum == 4) {
      tmpCurRoomStartHour = selectedRoom_Studio.thursday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.thursday_working_hours_stop;
      //console.log("Thursday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.thursday_working_hours_start=="closed" || selectedRoom_Studio.thursday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }
    //Friday
    else if(tmpDayNum == 5) {
      tmpCurRoomStartHour = selectedRoom_Studio.friday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.friday_working_hours_stop;
      //console.log("Friday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.friday_working_hours_start=="closed" || selectedRoom_Studio.friday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }
    //Saturday
    else if(tmpDayNum == 6) {
      tmpCurRoomStartHour = selectedRoom_Studio.saturday_working_hours_start;
      tmpCurRoomStopHour = selectedRoom_Studio.saturday_working_hours_stop;
      //console.log("Saturday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedRoom_Studio.saturday_working_hours_start=="closed" || selectedRoom_Studio.saturday_working_hours_stop=="closed")
        tmpCurSelectedRoomOpenAtSelectedDay = false;
    }

    if(tmpCurSelectedRoomOpenAtSelectedDay == true) {
      //console.log(" open, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      for (let i=0; i<UtilsManager.getInstance().allHours.length; i++) {
        //console.log("    i: ", i, " -> ", UtilsManager.getInstance().allHours[i]);
        if( (tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStartHour) 
            || (tmpStartHourFound==true && tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStopHour)
            || (tmpStartHourFound==true && tmpStopHourFound==false) //Start(found) - tmp - Stop(not found)
              ) {
          //console.log(" to prosthetw");
          tmpAllHoursOfSelectedDay.push(UtilsManager.getInstance().allHours[i]);
          tmpAllHoursOfSelectedDaySelected.push(false);

          if(tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStartHour) {
            //console.log("      tmpStartHourFound");
            tmpStartHourFound = true;
          }
          else if(tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurRoomStopHour) {
            //console.log("      tmpStoptHourFound");
            tmpStopHourFound = true;
          }
        }
        //check if it needs to be restarted
        if((tmpStartHourFound==false || tmpStopHourFound==false) && i==(UtilsManager.getInstance().allHours.length-1)) {
          //console.log(" restart");
          i = -1;
        }
      }
      //delete last item e.x. if it closes at 00:00, we don't want to add 00:00-00:30
      tmpAllHoursOfSelectedDay.pop();
      tmpAllHoursOfSelectedDaySelected.pop();
    }
    setAllHoursOfSelectedDay(tmpAllHoursOfSelectedDay);
    setSelectedTimeslots(tmpAllHoursOfSelectedDaySelected);
    setCurSelectedRoomOpenAtSelectedDay_Studio(tmpCurSelectedRoomOpenAtSelectedDay);
    //console.log("5 - End");
  }, [newReservation_date, selectedRoom_Studio]);

  const [allReservationsOfThisRoomForThisDate_Studio, setAllReservationsOfThisRoomForThisDate_Studio] = useState<Array<Studio_Reservation>>([]);  

  //is the room open at this date?
  const [curSelectedRoomOpenAtSelectedDay_Studio, setCurSelectedRoomOpenAtSelectedDay_Studio] = useState(false);
/////////////////////////
// Studio domain - END //
/////////////////////////

///////////////////////////
// Tennis domain - START //
///////////////////////////
  const [selectedCourt_Tennis, setSelectedCourt_Tennis] = useState<Tennis_Court>({
    id: -1,
    owned_by: LoginManager.getInstance().loggedInOwner.id,
    name: "",
    type: -1,
    currency: "",
    monday_cost_per_hour: -1.0,
    tuesday_cost_per_hour: -1.0,
    wednesday_cost_per_hour: -1.0,
    thursday_cost_per_hour: -1.0,
    friday_cost_per_hour: -1.0,
    saturday_cost_per_hour: -1.0,
    sunday_cost_per_hour: -1.0,
    monday_working_hours_start: "",
    tuesday_working_hours_start: "",
    wednesday_working_hours_start: "",
    thursday_working_hours_start: "",
    friday_working_hours_start: "",
    saturday_working_hours_start: "",
    sunday_working_hours_start: "",
    monday_working_hours_stop: "",
    tuesday_working_hours_stop: "",
    wednesday_working_hours_stop: "",
    thursday_working_hours_stop: "",
    friday_working_hours_stop: "",
    saturday_working_hours_stop: "",
    sunday_working_hours_stop: ""
  });
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if(selectedCourt_Tennis!=undefined && selectedCourt_Tennis.id!=-1 && selectedCourt_Tennis.name!="") {
          const tmpAllReservationsOfTheCourt = await Tennis_RestManager.getInstance().getAllReservationsByCourtID(selectedCourt_Tennis.id);
          //console.log("court: ", selectedCourt_Tennis.name);
          //console.log(JSON.stringify(selectedCourt_Tennis, null, 2));
          setAllReservationsOfThisCourt_Tennis(tmpAllReservationsOfTheCourt);
    
          setNewReservation_Tennis({...newReservation_Tennis,
            for_court: selectedCourt_Tennis.id
          });
        } else {
          //console.log("selectedCourt_Tennis is NULL!!!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedCourt_Tennis]);

  const [newReservation_Tennis, setNewReservation_Tennis] = useState<Tennis_Reservation>({
    id: -1,                         //I don't need to complete this field - automatically completed internally by the web service
    for_court: -1,
    by_user_email: "",               
    reservation_date: newReservation_date,
    reservation_hour_start: '',
    reservation_hour_stop: '',
    date_created: '',               //I don't need to complete this field - automatically completed internally by the web service
    date_last_updated: '',          //I don't need to complete this field - automatically completed internally by the web service
    completed_successfully: false,  //I don't need to complete this field - automatically completed internally by the web service
    comments: ''
  });

  const [selectedCourtIndexInCourtsArray_Tennis, setSelectedCourtIndexInCourtsArray_Tennis] = useState(0);
  //each time current court is changing, get all reservations for this court
  useEffect(() => {
    if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
      //console.log("3 - Start - selectedCourtIndexInCourtsArray_Tennis: ", selectedCourtIndexInCourtsArray_Tennis);
      const tmpCurCourt = GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis[selectedCourtIndexInCourtsArray_Tennis];
      if(tmpCurCourt!=undefined && tmpCurCourt.id!=-1 && tmpCurCourt.name!="") 
        setSelectedCourt_Tennis(tmpCurCourt);
      else
        console.log("tmpCurCourt is NULL!!!")
      //console.log("3 - End");
    }
  }, [selectedCourtIndexInCourtsArray_Tennis]);

  //search in all reservations for this court and get all the reservations for the specific day
  const [allReservationsOfThisCourt_Tennis, setAllReservationsOfThisCourt_Tennis] = useState<Array<Tennis_Reservation>>([]);
  useEffect(() => {
    //console.log("4 - Start");
    var newReservationsForThisDate : Array<Tennis_Reservation> = [];
    if(allReservationsOfThisCourt_Tennis != undefined) {
      for (let i = 0; i < allReservationsOfThisCourt_Tennis.length; i++) { 
        const tmpReservation: Tennis_Reservation = allReservationsOfThisCourt_Tennis[i];
        if(newReservation_date == tmpReservation.reservation_date)
          newReservationsForThisDate.push(tmpReservation);  
      }            
      setAllReservationsOfThisCourtForThisDate_Tennis(newReservationsForThisDate);
    }    
    //set timeslots for this court and day
    if(selectedCourt_Tennis!=undefined && selectedCourt_Tennis.id!=-1 && selectedCourt_Tennis.name!="") {
      const tempDayNumber = moment(newReservation_date, 'DD/MM/YYYY').toDate().getDay();
      setCurTimeslots_Tennis(tempDayNumber);
    }
    //console.log("4 - End");
  }, [allReservationsOfThisCourt_Tennis]);

  const setCurTimeslots_Tennis = useCallback((tmpDayNum: number) => {
    //function setCurTimeslots_Tennis(tmpDayNum: number) {    
    //console.log("5 - Start - tmpDayNum: ", tmpDayNum);
    //console.log(JSON.stringify(selectedCourt_Tennis, null, 2));
    setSelectedDayNum(tmpDayNum);

    var tmpAllHoursOfSelectedDay: Array<string> = new Array(0);
    var tmpAllHoursOfSelectedDaySelected: Array<boolean> = new Array(0);
    var tmpCurSelectedCourtOpenAtSelectedDay = true;
  
    //Sunday is 0, Monday is 1, and so on.
    var tmpStartHourFound : boolean = false;
    var tmpStopHourFound: boolean = false;
    var tmpCurCourtStartHour: string = "";
    var tmpCurCourtStopHour: string = "";
  
    //Sunday
    if(tmpDayNum == 0) {
      tmpCurCourtStartHour = selectedCourt_Tennis.sunday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.sunday_working_hours_stop;
      //console.log("Sunday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.sunday_working_hours_start=="closed" || selectedCourt_Tennis.sunday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }
    //Monday
    else if(tmpDayNum == 1) {
      tmpCurCourtStartHour = selectedCourt_Tennis.monday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.monday_working_hours_stop;
      //console.log("Monday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.monday_working_hours_start=="closed" || selectedCourt_Tennis.monday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }
    //Tuesday
    else if(tmpDayNum == 2) {
      tmpCurCourtStartHour = selectedCourt_Tennis.tuesday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.tuesday_working_hours_stop;
      //console.log("Tuesday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.tuesday_working_hours_start=="closed" || selectedCourt_Tennis.tuesday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }
    //Wednesday
    else if(tmpDayNum == 3) {
      tmpCurCourtStartHour = selectedCourt_Tennis.wednesday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.wednesday_working_hours_stop;
      //console.log("Wednesday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.wednesday_working_hours_start=="closed" || selectedCourt_Tennis.wednesday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }
    //Thursday
    else if(tmpDayNum == 4) {
      tmpCurCourtStartHour = selectedCourt_Tennis.thursday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.thursday_working_hours_stop;
      //console.log("Thursday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.thursday_working_hours_start=="closed" || selectedCourt_Tennis.thursday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }
    //Friday
    else if(tmpDayNum == 5) {
      tmpCurCourtStartHour = selectedCourt_Tennis.friday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.friday_working_hours_stop;
      //console.log("Friday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.friday_working_hours_start=="closed" || selectedCourt_Tennis.friday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }
    //Saturday
    else if(tmpDayNum == 6) {
      tmpCurCourtStartHour = selectedCourt_Tennis.saturday_working_hours_start;
      tmpCurCourtStopHour = selectedCourt_Tennis.saturday_working_hours_stop;
      //console.log("Saturday, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      if(selectedCourt_Tennis.saturday_working_hours_start=="closed" || selectedCourt_Tennis.saturday_working_hours_stop=="closed")
        tmpCurSelectedCourtOpenAtSelectedDay = false;
    }

    if(tmpCurSelectedCourtOpenAtSelectedDay == true) {
      //console.log(" open, tmpCurRoomStartHour: ", tmpCurRoomStartHour, ", tmpCurRoomStopHour: ", tmpCurRoomStopHour);
      for (let i=0; i<UtilsManager.getInstance().allHours.length; i++) {
        //console.log("    i: ", i, " -> ", UtilsManager.getInstance().allHours[i]);
        if( (tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStartHour) 
            || (tmpStartHourFound==true && tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStopHour)
            || (tmpStartHourFound==true && tmpStopHourFound==false) //Start(found) - tmp - Stop(not found)
              ) {
          //console.log(" to prosthetw");
          tmpAllHoursOfSelectedDay.push(UtilsManager.getInstance().allHours[i]);
          tmpAllHoursOfSelectedDaySelected.push(false);

          if(tmpStartHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStartHour) {
            //console.log("      tmpStartHourFound");
            tmpStartHourFound = true;
          }
          else if(tmpStopHourFound==false && UtilsManager.getInstance().allHours[i]==tmpCurCourtStopHour) {
            //console.log("      tmpStoptHourFound");
            tmpStopHourFound = true;
          }
        }
        //check if it needs to be restarted
        if((tmpStartHourFound==false || tmpStopHourFound==false) && i==(UtilsManager.getInstance().allHours.length-1)) {
          //console.log(" restart");
          i = -1;
        }
      }
      //delete last item e.x. if it closes at 00:00, we don't want to add 00:00-00:30
      tmpAllHoursOfSelectedDay.pop();
      tmpAllHoursOfSelectedDaySelected.pop();
    }
    setAllHoursOfSelectedDay(tmpAllHoursOfSelectedDay);
    setSelectedTimeslots(tmpAllHoursOfSelectedDaySelected);
    setCurSelectedCourtOpenAtSelectedDay_Tennis(tmpCurSelectedCourtOpenAtSelectedDay);
    //console.log("5 - End");
  }, [newReservation_date, selectedCourt_Tennis]);

  const [allReservationsOfThisCourtForThisDate_Tennis, setAllReservationsOfThisCourtForThisDate_Tennis] = useState<Array<Tennis_Reservation>>([]);  

  //is the court open at this date?
  const [curSelectedCourtOpenAtSelectedDay_Tennis, setCurSelectedCourtOpenAtSelectedDay_Tennis] = useState(false);
/////////////////////////
// Tennis domain - END //
/////////////////////////

    // Create a ref for the TextInput components
    const studio_commentsInputRef = useRef(null);
    const tennis_commentsInputRef = useRef(null);
  
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <ScrollView>
          <Spinner
            visible={isLoading}
            textContent={i18n.t('loading')}
            textStyle={styles.spinnerTextStyle}
          />
          <Appbar.Header>
            <Appbar.Content title={i18n.t('newReservation')} />
          </Appbar.Header>
          <DataTable>
              <DataTable.Row style={styles.tableRow}>
              <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
              <DataTable.Cell style={styles.tableValueColumn}>{newReservation_date}</DataTable.Cell>
              </DataTable.Row>
          </DataTable>
          <Divider/>
          {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <RadioButton.Group 
              onValueChange={newValue => { 
                  if(newValue === 'rehearsal')
                    setNewReservation_Studio({...newReservation_Studio,
                      rehearsal: true
                    });
                  else if(newValue === 'recording') 
                    setNewReservation_Studio({...newReservation_Studio,
                      rehearsal: false
                    });
              }} 
              value={newReservation_Studio.rehearsal==true ? 'rehearsal' : 'recording'}>
              <RadioButton.Item
                key={0}
                label={i18n.t('rehearsal')}
                value={'rehearsal'}
                status={ newReservation_Studio.rehearsal === true ? 'checked' : 'unchecked' }
              />
              <RadioButton.Item
                key={1}
                label={i18n.t('recording')}
                value={'recording'}
                status={ newReservation_Studio.rehearsal === false ? 'checked' : 'unchecked' }
              />
          </RadioButton.Group>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('selectRoom')}</Title>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <RadioButton.Group onValueChange={tmpSelectedRoomIndex => setSelectedRoomIndexInRoomsArray_Studio(parseInt(tmpSelectedRoomIndex))} value={selectedRoomIndexInRoomsArray_Studio.toString()}>
              {GUIManager.getInstance().allRoomsOfLoggedInOwner_Studio.map((room: Studio_Room, i) => (
                  <RadioButton.Item
                      key={room.id}
                      label={room.name}
                      value={i.toString()}
                      status={selectedRoomIndexInRoomsArray_Studio == i ? 'checked' : 'unchecked'} />
              ))}      
          </RadioButton.Group>}

          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('selectCourt')}</Title>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <RadioButton.Group onValueChange={tmpSelectedCourtIndex => setSelectedCourtIndexInCourtsArray_Tennis(parseInt(tmpSelectedCourtIndex))} value={selectedCourtIndexInCourtsArray_Tennis.toString()}>
              {GUIManager.getInstance().allCourtsOfLoggedInOwner_Tennis.map((court: Tennis_Court, i) => (
                  <RadioButton.Item
                      key={court.id}
                      label={court.name}
                      value={i.toString()}
                      status={selectedCourtIndexInCourtsArray_Tennis == i ? 'checked' : 'unchecked'} />
              ))}      
          </RadioButton.Group>}

          <Fragment>
              {allHoursOfSelectedDayItemsWithUniqueId.map(item => {
                  //getTimeslotStatus takes as input: 
                  //      1) the starting hout of a misawro, 
                  //      2) AllReservationsOfThisRoomForThisDate: Array<Reservation>  
                  // kai epistrefei ena apo ta parakatw strings (opou i einai to index sto array allReservationsOfThisRoomForThisDate): 
                  //          "*STATUS*free*INDEX*-1"
                  //          "*STATUS*reservedByThisPlayer*INDEX*" + i.toString()
                  //          "*STATUS*reservedByTheOwnerOfTheRoom*INDEX*" + i.toString();
                  //          "*STATUS*reservedByOtherPlayer*INDEX*" + i.toString();
                  var statusFullStr : string = "";
                  if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
                    statusFullStr = UtilsManager.getInstance().getTimeslotStatus_Studio(item.hour, allReservationsOfThisRoomForThisDate_Studio);
                  else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
                    statusFullStr = UtilsManager.getInstance().getTimeslotStatus_Tennis(item.hour, allReservationsOfThisCourtForThisDate_Tennis);
                  const finalStatusStr : string = statusFullStr.substring(statusFullStr.indexOf("*STATUS*")+8, statusFullStr.indexOf("*INDEX*"));
                  
                  return (
                      <View style={{flexDirection:"row"}} key={item.id}>
                          <Checkbox.Item
                              label={item.hour + " - " + UtilsManager.getInstance().getNextTimeslotStr(item.hour)}  
                              disabled={finalStatusStr=="free" ? false : true}                  
                              status={selectedTimeslots[item.id] ? 'checked' : 'unchecked'}
                              onPress={() => checkBoxOnClick(item.id)}
                              color="white"
                              style={{width: Platform.OS!='web' ? screenDimensions.width/2 : GenericConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*0.4, 
                                      backgroundColor: selectedTimeslots[item.id] ? 'green' : 'lightgrey' }}
                          />
                          <View style={{backgroundColor: finalStatusStr=="free" ? 'green' : 'red'}}>
                              {finalStatusStr=="free" && <Text style={styles.statusLabel}>{i18n.t('free')}</Text>}
                              {finalStatusStr=="reservedByOtherPlayer" && <Text style={styles.statusLabel}>{i18n.t('reserved')}</Text>}
                              {finalStatusStr=="reservedByThisPlayer" && <Button icon={({ color, size }) => (<MaterialCommunityIcons name="information-outline" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButtonSmall} contentStyle={styles.iconButtonContentSmall} style={{width: screenDimensions.width/2}} /*onPress={() => fillPreviousReservationForPreview(parseInt(UtilsManager.getInstance().getTimeslotStatus(item.hour, allReservationsOfThisRoomForThisDate).substring(UtilsManager.getInstance().getTimeslotStatus(item.hour, allReservationsOfThisRoomForThisDate).indexOf("*INDEX*")+7, UtilsManager.getInstance().getTimeslotStatus(item.hour, allReservationsOfThisRoomForThisDate).length)))}*/>{i18n.t('reservedByYou')}</Button>}
                              {finalStatusStr=="reservedByTheOwnerOfTheRoom" && <Text style={styles.statusLabel}>{i18n.t('reserved')}</Text>}
                          </View>
                      </View>
              )})}
          </Fragment>

          {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <View style={{ display: curSelectedRoomOpenAtSelectedDay_Studio ? 'flex' : 'none' }}>
            <TouchableOpacity onPress={() => studio_commentsInputRef.current.focus()}>
              <TextInput
                ref={studio_commentsInputRef}
                label={i18n.t('commentsOptional')}
                value={newReservation_Studio.comments}
                onChangeText={tmpComments => setNewReservation_Studio({...newReservation_Studio,
                  comments: tmpComments
                })} />
            </TouchableOpacity>
            <Button icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" onPress={showNewReservationDetailsModal} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{i18n.t('continue')}</Button>
          </View>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <Text style={[styles.closedLabel, { display: curSelectedRoomOpenAtSelectedDay_Studio==false && isLoading==false ? 'flex' : 'none' }]}>{i18n.t('closed')}</Text>}

          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <View style={{ display: curSelectedCourtOpenAtSelectedDay_Tennis ? 'flex' : 'none' }}>
            <TouchableOpacity onPress={() => tennis_commentsInputRef.current.focus()}>
              <TextInput
                ref={tennis_commentsInputRef}
                label={i18n.t('commentsOptional')}
                value={newReservation_Tennis.comments}
                onChangeText={tmpComments => setNewReservation_Tennis({...newReservation_Tennis,
                  comments: tmpComments
                })} />
            </TouchableOpacity>
            <Button icon={({ color, size }) => (<MaterialCommunityIcons name="check-outline" color={color} size={size} />)} mode="contained" onPress={showNewReservationDetailsModal} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{i18n.t('continue')}</Button>
          </View>}
          {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <Text style={[styles.closedLabel, { display: curSelectedCourtOpenAtSelectedDay_Tennis==false && isLoading==false ? 'flex' : 'none' }]}>{i18n.t('closed')}</Text>}
        
          {MyAlertForWeb!=null && 
            <MyAlertForWeb
              show={alertForWebVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForWebText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              contentContainerStyle={{bottom: 70, position: 'absolute'}}
              onCancelPressed={() => {
                setAlertForWebVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForWebVisible(false);
              }}  
            />
          }

          <Portal>
            <Modal visible={visibleNewReservationDetailsModal} onDismiss={hideNewReservationDetailsModal} contentContainerStyle={styles.modalContainer}>
              <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
                <ScrollView>
                  <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('reservationDetails')}</Title>
                  <Divider/>

                  {LoginManager.getInstance().domain==LoginManager.getInstance().STUDIO && <DataTable>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('rehearsalRecording')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.rehearsal ? i18n.t('rehearsal') : i18n.t('recording')}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('room')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{selectedRoom_Studio.name}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_date}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('hours')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.reservation_hour_start} - {newReservation_Studio.reservation_hour_stop}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('cost')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservationCostLabel}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('comments')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Studio.comments}</DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>}

                  {LoginManager.getInstance().domain==LoginManager.getInstance().TENNIS && <DataTable>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('tennisCourt')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{selectedCourt_Tennis.name}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_date}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('hours')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Tennis.reservation_hour_start} - {newReservation_Tennis.reservation_hour_stop}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('cost')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservationCostLabel}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('comments')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{newReservation_Tennis.comments}</DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>}

                  <Button disabled={finishBtnDisabled} icon={({ color, size }) => (<MaterialCommunityIcons name="calendar" color={color} size={size} />)} mode="contained" onPress={completeReservation} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent}>{i18n.t('completeReservation')}</Button>
                </ScrollView>
              </TouchableWithoutFeedback>
            </Modal>
          </Portal>
        </ScrollView>
      </TouchableWithoutFeedback>
    );
  };

  export default React.memo(OwnerNewReservationModal);