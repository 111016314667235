import { I18n } from "i18n-js";
import en from "../locales/en.js";
import gr from "../locales/gr.js";

export const i18n = new I18n({
  en,
  gr
});

/* Apo palia version toy i18n-js

// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
  en,
  gr
};
*/

export default i18n;