import React, { useCallback, useState } from "react";
import { Keyboard, Linking, ScrollView, TouchableWithoutFeedback, View } from "react-native";
import { Button, Colors, DataTable, Dialog, Divider, IconButton, Paragraph, Portal, Title } from "react-native-paper";
import * as MailComposer from 'expo-mail-composer';
import styles from "../../css/myCss";
import i18n from "../../utils/i18n";
import Studio_RestManager from "../../managers/Studio_RestManager";
import UtilsManager from "../../managers/UtilsManager";
import moment from "moment";
import Spinner from "react-native-loading-spinner-overlay";
import LoginManager from "../../managers/LoginManager";
import Tennis_RestManager from "../../managers/Tennis_RestManager";
import Tennis_ConstManager from "../../managers/Tennis_ConstManager";
import { MaterialCommunityIcons } from "@expo/vector-icons";


const PlayerSelectedReservationModal = ({  
                                        navigation,                            
                                        curReservationDetails,
                                        hideSelectedReservationDetailsModal
                            }) => {
    const [isLoading, setIsLoading] = useState(false);                                

    const [cancelReservationDialogVisible, setCancelReservationDialogVisible] = useState(false);
    const showCancelReservationDialog = () => setCancelReservationDialogVisible(true);
    const hideCancelReservationDialog = () => setCancelReservationDialogVisible(false);

    async function goToCompanyProfile() {
        hideSelectedReservationDetailsModal();
        //find owner 
        var currentSelectedOwner : Owner;
        if(curReservationDetails.itemDomain == LoginManager.getInstance().STUDIO)
            currentSelectedOwner = await Studio_RestManager.getInstance().getOwnerById(curReservationDetails.ownerID);
        else if(curReservationDetails.itemDomain == LoginManager.getInstance().TENNIS)
            currentSelectedOwner = await Tennis_RestManager.getInstance().getOwnerById(curReservationDetails.ownerID);
        //-find owner
        const selectedDomainValue = curReservationDetails.itemDomain;
     
       navigation.navigate('SelectedOwnerSummary', { 
         title: curReservationDetails.itemDomain==LoginManager.getInstance().STUDIO ? i18n.t('studioInfo') 
                 : curReservationDetails.itemDomain==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourtInfo') 
                 : "ERROR", 
         backLabel: i18n.t('back'),
         params: {selectedDomainValue, currentSelectedOwner}
       });
     }
  
    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
            <ScrollView>
                <Spinner
                    visible={isLoading}
                    textContent={i18n.t('loading')}
                    textStyle={styles.spinnerTextStyle}
                />
                <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey200 }}>{i18n.t('reservationDetails')}</Title>
                <Divider/>
                <View>
                    <DataTable>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('category')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.itemDomain==LoginManager.getInstance().STUDIO ? i18n.t('studioCategory') : curReservationDetails.itemDomain==LoginManager.getInstance().TENNIS ? i18n.t('tennisCategory') : 'Unknown category'}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('date')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.reservationDate}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('hours')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.reservationStartTime} - {curReservationDetails.reservationStopTime}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{curReservationDetails.itemDomain==LoginManager.getInstance().STUDIO ? i18n.t('studio') : curReservationDetails.itemDomain==LoginManager.getInstance().TENNIS ? i18n.t('company') : i18n.t('company')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}><Button icon={({ color, size }) => (<MaterialCommunityIcons name="web" color={color} size={size} />)} mode="contained" onPress={goToCompanyProfile} >{curReservationDetails.companyName}</Button></DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{curReservationDetails.itemDomain==LoginManager.getInstance().STUDIO ? i18n.t('room') : curReservationDetails.itemDomain==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourt') : 'Unknown'}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.itemDomain==LoginManager.getInstance().STUDIO ? curReservationDetails.roomName : curReservationDetails.itemDomain==LoginManager.getInstance().TENNIS ? curReservationDetails.courtName : 'Unknown'}</DataTable.Cell>
                        </DataTable.Row>
                        {curReservationDetails.itemDomain==LoginManager.getInstance().STUDIO && <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('rehearsalRecording')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.isRehearsal==true ? i18n.t('rehearsal') : i18n.t('recording')}</DataTable.Cell>
                        </DataTable.Row>}
                        <DataTable.Row style={styles.tableRow}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('totalCost')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.reservationCost}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row style={[styles.tableRow, {display: curReservationDetails.reservationComments!='' ? 'flex' : 'none' }]}>
                            <DataTable.Title style={styles.tableTitle}>{i18n.t('comments')}</DataTable.Title>
                            <DataTable.Cell style={styles.tableValueColumn}>{curReservationDetails.reservationComments}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </View>
            </ScrollView>
        </TouchableWithoutFeedback>
    );
  };

  export default React.memo(PlayerSelectedReservationModal);