import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Alert, Platform, View, Keyboard, TouchableWithoutFeedback, FlatList, TouchableOpacity } from 'react-native';
import { Text, Searchbar, TextInput } from 'react-native-paper';
import * as Location from 'expo-location';
import GUIManager from '../../managers/GUIManager'
import * as SecureStore from 'expo-secure-store';
import Studio_RestManager from '../../managers/Studio_RestManager';
import Spinner from 'react-native-loading-spinner-overlay';
import i18n from '../../utils/i18n';
import { useNavigation } from '@react-navigation/native';
import styles from '../../css/myCss';
import DropDown from 'react-native-paper-dropdown';
import UtilsManager from '../../managers/UtilsManager';
import LoginManager from '../../managers/LoginManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';

//Alert
  //react native
    //already imported above
  //web
  //...toDo...

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = null;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//maps
  //react native
  import MapView, { Callout, Marker } from 'react-native-maps';
  //web
  import { MapContainer as WebMap, TileLayer as WebTileLayer, Marker as WebMarker, Popup as WebPopup, Tooltip as WebTooltip } from 'react-leaflet';
  import L from 'leaflet';
  import axios from 'axios';
  //PROSOXH: PREPEI NA TO EXW COMMENTED OUT TO PARAKATW IMPORT TOY CSS GIA NA BGALW MOBILE VERSION!
  import 'leaflet/dist/leaflet.css'; //important - if it's not loaded, the map tiles are not shown correctly
  import myMarkerIcon from '../../assets/web_marker.png';
  const customWebMarkerIcon = new L.Icon({
    iconUrl: myMarkerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]  });

  
import Generic_RestManager from '../../managers/Generic_RestManager';
import { platform } from 'os';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

let MyMapViewForReactNative;
let MyMapViewForWeb;

  const zoomLevel = 12;


if (Platform.OS === 'web') {  //WEB
  MyMapViewForWeb = true;
  MyMapViewForReactNative = null;
} else {                      //REACT NATIVE
  MyMapViewForWeb = false;
  MyMapViewForReactNative = MapView;
}
//-maps

const OwnerBrowseStudiosSubview = ({selectedDomainValue, setSelectedDomainValue}) => {
  const navigation = useNavigation();

  const webMapRef = useRef(null);

  const markerLatitudeDelta = 0.009;
  const markerLongitudeDelta = 0.009;
  const markerLatitudeOffset = 0.0;
  const markerLongitudeOffset = 0.0;
  const myLocationLatitudeOffset = -0.0022;
  const myLocationLongitudeOffset = 0.0;

  const [initialPositionBasedOnIP, setInitialPositionBasedOnIP] = useState<{ latitude: number; longitude: number }>({ latitude: 40.60289, longitude: 22.96243 }); // Default: Thessaloniki
  useEffect(() => {
    console.log("initialPositionBasedOnIP - useEffect: ", initialPositionBasedOnIP);
  }, [initialPositionBasedOnIP]);

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("OwnerBrowseStudiosSubview: I'm ready!");
    (async () => {
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
      if(handleWebPageReloadActuallyRun) {
        const tmpStoredDomain = sessionStorage.getItem('storedDomain');
        setSelectedDomainValue(tmpStoredDomain);
      }

      if(LoginManager.getInstance().language == "EN")
        setCurDropdownList(UtilsManager.getInstance().domainsList_EN);
      else if(LoginManager.getInstance().language == "GR")
        setCurDropdownList(UtilsManager.getInstance().domainsList_GR);

      if(Platform.OS === 'web') { 
        //first check if there are any stored coordinates
        const tmpStoredLatitude = sessionStorage.getItem('storedLatitude');
        const tmpStoredLongitude = sessionStorage.getItem('storedLongitude');
        if(tmpStoredLatitude && tmpStoredLongitude) {
          setMyLocationLatitude(parseFloat(tmpStoredLatitude));
          GUIManager.getInstance().myLatitude = parseFloat(tmpStoredLatitude);
          setMyLocationLongitude(parseFloat(tmpStoredLongitude));
          GUIManager.getInstance().myLongitude = parseFloat(tmpStoredLongitude);

          setInitialPositionBasedOnIP({latitude: parseFloat(tmpStoredLatitude), longitude: parseFloat(tmpStoredLongitude)});
        }
        else {  //if there are no stored coordinates, find user location based on the IP
          console.log("...trying to get coordinates from IP...")
          let tmpCoords = await Generic_RestManager.getInstance().getUserGeolocationBasedOnIP("188.117.197.175"); /* TODO: FIND USER IP!!! */
          setInitialPositionBasedOnIP(tmpCoords);
          setMyLocationLatitude(tmpCoords.latitude);
          GUIManager.getInstance().myLatitude = tmpCoords.latitude;
          setMyLocationLongitude(tmpCoords.longitude);
          GUIManager.getInstance().myLongitude = tmpCoords.longitude;
          console.log("...trying to get coordinates from IP - DONE!");

          sessionStorage.setItem('storedLatitude', tmpCoords.latitude.toFixed(6));
          sessionStorage.setItem('storedLongitude', tmpCoords.longitude.toFixed(6));
        }
      }
      else {  //mobile
        //check GPS permissions
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          if(MyAlertReactNative != null)
            alert(i18n.t('noGPSAllowed'));
          return;
        }

        //load latitude stored in the device (if any)
        let myStoredLatitude = await SecureStore.getItemAsync('storedLatitude');
        if(myStoredLatitude) {
          let myReturnedLatitude = JSON.parse(myStoredLatitude);
          if(myReturnedLatitude) {
            setMyLocationLatitude(myReturnedLatitude);
            GUIManager.getInstance().myLatitude = myReturnedLatitude;
          }
        }
        //load longitude stored in the device (if any)
        let myStoredLongitude = await SecureStore.getItemAsync('storedLongitude');
        if(myStoredLongitude) {
          let myReturnedLongitude = JSON.parse(myStoredLongitude);
          if(myReturnedLongitude) {
            setMyLocationLongitude(myReturnedLongitude);
            GUIManager.getInstance().myLongitude = myReturnedLongitude;
          }
        }
        //load allOwners stored in the device (if any)
  //      let myStoredAllOwners = await SecureStore.getItemAsync('storedAllOwners');
  //      if(myStoredAllOwners) {
  //        let myReturnedAllOwners = JSON.parse(myStoredAllOwners);
  //        if(myReturnedAllOwners) {
  //          GUIManager.getInstance().allOwners = myReturnedAllOwners;
  //        }
  //      }
  //      else
  //      {
          //getAllOwners();
  //      }   


        if(myStoredLatitude && myStoredLongitude) {
          //alert('my location (previously stored in the device: (' + GUIManager.getInstance().myLatitude + ", " + GUIManager.getInstance().myLongitude + ")");
        }
        else
        {
          let location = await Location.getCurrentPositionAsync({});
          setMyLocationLatitude(location.coords.latitude);
          setMyLocationLongitude(location.coords.longitude);
          GUIManager.getInstance().myLatitude = location.coords.latitude;
          GUIManager.getInstance().myLongitude = location.coords.longitude;

          //save latitude & longitude locally
          SecureStore.setItemAsync('storedLatitude', JSON.stringify(location.coords.latitude)).catch((error) => console.log('Error during storedLatitude storage!', error));
          SecureStore.setItemAsync('storedLongitude', JSON.stringify(location.coords.longitude)).catch((error) => console.log('Error during storedLongitude storage!', error));

          //alert('my location (found now): ' + JSON.stringify(location));
        }
        goToMyLocation();
      }
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const [showDomainDropdownList, setShowDomainDropdownList] = useState(false);
  const [curDropdownList, setCurDropdownList] = useState(UtilsManager.getInstance().domainsList_EN);
  
  const [isLoading, setIsLoading] = useState(false);
  const [myLocationLatitude, setMyLocationLatitude] = useState(0);
  const [myLocationLongitude, setMyLocationLongitude] = useState(0);

  const [region, setRegion] = useState({
    latitude: GUIManager.getInstance().myLatitude,
    longitude: GUIManager.getInstance().myLongitude,
    latitudeDelta: markerLatitudeDelta,
    longitudeDelta: markerLongitudeDelta
  });

  function goToMyLocation() {
    setRegion({ ...region, latitude: GUIManager.getInstance().myLatitude + myLocationLatitudeOffset, longitude: GUIManager.getInstance().myLongitude + myLocationLongitudeOffset, latitudeDelta: markerLatitudeDelta, longitudeDelta: markerLongitudeDelta});
  }

  async function markerTitleClick(event){
    //console.log("Marker with ID:" + event._targetInst.return.key + " was clicked");
    GUIManager.getInstance().curSelectedOwnerIndex = event._targetInst.return.key;
    //find owner 
    var currentSelectedOwner : Owner;
    if(selectedDomainValue == LoginManager.getInstance().STUDIO)
      currentSelectedOwner = await Studio_RestManager.getInstance().getOwnerById(masterDataSource[GUIManager.getInstance().curSelectedOwnerIndex].id);
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
      currentSelectedOwner = await Tennis_RestManager.getInstance().getOwnerById(masterDataSource[GUIManager.getInstance().curSelectedOwnerIndex].id);
    //-find owner
    navigation.navigate('SelectedOwnerSummary', { 
      title: selectedDomainValue==LoginManager.getInstance().STUDIO ? i18n.t('studioInfo') 
              : selectedDomainValue==LoginManager.getInstance().TENNIS ? i18n.t('tennisCourtInfo') 
              : "ERROR", 
      backLabel: i18n.t('back'),
      params: {selectedDomainValue, currentSelectedOwner}
    });
  }

  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [masterDataSource, setMasterDataSource] = useState<Array<Owner>>([]);

  const getAllOwners = useCallback(async () => {
    setIsLoading(true);
    let tmpAllOwners : Array<Owner> = [];
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      console.log("Pairnw allOwners apo studio...");
      tmpAllOwners = await Studio_RestManager.getInstance().getAllOwners();
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      console.log("Pairnw allOwners apo tennis...");
      tmpAllOwners = await Tennis_RestManager.getInstance().getAllOwners();
    }

    GUIManager.getInstance().allOwners = tmpAllOwners;
    setMasterDataSource(tmpAllOwners);
    
    //store all owners locally on the device
    //EINAI MEGALYTERO APO 2048 kai vgazei warning! SecureStore.setItemAsync('storedAllOwners', JSON.stringify(responseJson)).catch((error) => console.log('Error during storedAllOwners storage!', error));

    // Create the array of markers when the component mounts
    const markers = tmpAllOwners.map((owner) => {
      const markerRef = React.createRef();
      const popupRef = React.createRef();
      const tooltipRef = React.createRef();
      return { ownerId: owner.id, markRef: markerRef, popRef: popupRef, tolRef: tooltipRef };
    });
    setWebMarkers(markers);

    setIsLoading(false);
  }, [selectedDomainValue]);

  const [searchQuery, setSearchQuery] = useState('');

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = masterDataSource.filter(
        function (item: Owner) {
          const itemData = item.company_name + ' ' + item.city + ' ' + item.country
            ? item.company_name.toUpperCase() + ' ' + item.city.toUpperCase() + ' ' + item.country.toUpperCase()
            : ''.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;
      });
      setFilteredDataSource(newData);
      setSearchQuery(text);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource([]);
      setSearchQuery(text);
    }
  };

  const ItemView = ({item}) => {
    return (
      // Flat List Item
      <Text
        style={styles.itemStyle}
        onPress={() => itemPressed(item)}>
          <Text style={{fontWeight: "bold"}}>
            {item.company_name.toUpperCase()}
          </Text>
          {', '}
          {item.city.toUpperCase()}
          {', '}
          {item.country.toUpperCase()}
      </Text>
    );
  };

  const ItemSeparatorView = () => {
    return (
      // Flat List Item Separator
      <View
        style={{
          height: 0.5,
          width: '100%',
          backgroundColor: '#C8C8C8',
        }}
      />
    );
  };

  const itemPressed = (item: Owner) => {
    // Function for click on an item
    //alert('Id : ' + item.id + ' Title : ' + item.company_name);
    console.log('itemPressed -> Id : ' + item.id + ' Title : ' + item.company_name);
    Keyboard.dismiss;
    setRegion({ ...region, latitude: item.latitude + markerLatitudeOffset, longitude: item.longitude + markerLongitudeOffset, latitudeDelta: markerLatitudeDelta, longitudeDelta: markerLongitudeDelta});
    //setInitialPositionBasedOnIP({latitude: item.latitude + markerLatitudeOffset, longitude: item.longitude + markerLongitudeOffset});
    //showWebMarkerPopup(item.id);
    if (Platform.OS === 'web')
      webMapRef.current.flyTo([item.latitude, item.longitude], 18);
    setFilteredDataSource([]);
    setSearchQuery(item.company_name + ', ' + item.city + ', ' + item.country);
  };
  
  const MORE_ICON = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';
  const _handleSearch = () => console.log('Searching');
  const _handleMore = () => console.log('Shown more');

  const [viewTitle, setViewTitle] = useState(i18n.t('findStudio'));

  useEffect(() => {
    console.log("OwnerBrowseStudiosSubview -> useEffect - selectedDomainValue - START -> selectedDomainValue: ", selectedDomainValue);
    getAllOwners();
    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      setViewTitle(i18n.t('findStudio'));
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
      setViewTitle(i18n.t('findTennisCourt'));
    }
    console.log("OwnerBrowseStudiosSubview -> useEffect - selectedDomainValue - STOP");
  }, [selectedDomainValue]);

  // Create a ref for the TextInput components
  const searchInputRef = useRef(null);

  // Custom Popup component that supports onClick event
  const CustomWebPopup = ({ tmpRef, onClick, tmpOwnerIndex, children }) => {
    const handleWebPopupClick = async () => {
      if (onClick) {
        onClick(tmpOwnerIndex); // Pass tmpOwnerIndex to the onClick function
      }
    };
    return (
      <WebPopup ref={tmpRef} closeButton={true} closeOnClick={false} autoClose={false}>
        {children}
        <button onClick={handleWebPopupClick}>{masterDataSource[tmpOwnerIndex].company_name}</button>
      </WebPopup>
    );
  };

  // Function to handle popup click event
  const handleWebPopupClick = async (tmpOwnerIndex) => {
    console.log("handleWebPopupClick -> tmpOwnerIndex: ", tmpOwnerIndex);
    // Your logic for handling the popup click event goes here
    // For example:
    GUIManager.getInstance().curSelectedOwnerIndex = tmpOwnerIndex;
    //find owner
    var currentSelectedOwner;
    if (selectedDomainValue == LoginManager.getInstance().STUDIO)
      currentSelectedOwner = await Studio_RestManager.getInstance().getOwnerById(
        masterDataSource[tmpOwnerIndex].id
      );
    else if (selectedDomainValue == LoginManager.getInstance().TENNIS)
      currentSelectedOwner = await Tennis_RestManager.getInstance().getOwnerById(
        masterDataSource[tmpOwnerIndex].id
      );
    // ...
    navigation.navigate('SelectedOwnerSummary', {
      title:
        selectedDomainValue == LoginManager.getInstance().STUDIO
          ? i18n.t('studioInfo')
          : selectedDomainValue == LoginManager.getInstance().TENNIS
          ? i18n.t('tennisCourtInfo')
          : 'ERROR',
      backLabel: i18n.t('back'),
      params: { selectedDomainValue, currentSelectedOwner },
    });
  };

  const [webMarkers, setWebMarkers] = useState([]);

  // Function to show the popup of a specific marker programmatically
  /*const showWebMarkerPopup = (markerId) => {
    console.log("showWebMarkerPopup -> trying to find markerId: ", markerId);
    const marker = webMarkers.find((m) => m.ownerId === markerId);

    //if (marker && marker.markRef && marker.markRef.current) {
      const popup = marker.popRef.current;
      //if (popup) {
        console.log("marker: ", marker);
        console.log("popup: ", popup);

        popup.openPopup();
      //}
    //}
    marker.tolRef.current.openTooltip();    
  };*/
    
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <View style={styles.columnMainApp}>
          <View style={styles.domainDropdown}>
            <DropDown
                label={i18n.t('lookingFor')}
                mode={'outlined'}
                visible={showDomainDropdownList}
                showDropDown={() => setShowDomainDropdownList(true)}
                onDismiss={() => setShowDomainDropdownList(false)}
                value={selectedDomainValue}
                setValue={setSelectedDomainValue}
                list={curDropdownList}
                inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showDomainDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
            />
          </View>
          <Spinner
            visible={isLoading}
            textContent={i18n.t('loading')}
            textStyle={styles.spinnerTextStyle}
          />
          <TouchableOpacity onPress={() => searchInputRef.current.focus()}>
            <Searchbar
              ref={searchInputRef}
              placeholder={i18n.t('search')}
              onChangeText={(text) => searchFilterFunction(text)}
              value={searchQuery}
              style={styles.searchBar}
              icon={({ color, size }) => <MaterialCommunityIcons name="search-web" size={size} color={color} />}
              clearIcon={({ color, size }) => <MaterialIcons name="clear" size={size} color={color} />}
            />
          </TouchableOpacity>
          <FlatList
            data={filteredDataSource}
            keyExtractor={(item, index) => index.toString()}
            ItemSeparatorComponent={ItemSeparatorView}
            renderItem={ItemView}
            style={styles.searchResults}
          />
          {MyMapViewForReactNative!=null && <MyMapViewForReactNative 
            style={styles.mapStyle} 
            region={region}
            showsUserLocation={true}
            userLocationAnnotationTitle={i18n.t('myLocation')}
            onPress={Keyboard.dismiss}
          >
            {GUIManager.getInstance().allOwners.map((owner, i) => (
              <Marker
                coordinate={{ latitude: owner.latitude, longitude: owner.longitude }}
                key={i}
                flat={false}
                onCalloutPress={(event) => markerTitleClick(event)}
              >
                <Callout>
                  <Text style={styles.markerTitle}>{owner.company_name}</Text>
                </Callout>
              </Marker>
            ))}        
          </MyMapViewForReactNative>}
          {MyMapViewForWeb==true && 
            <View style={{opacity: filteredDataSource.length==0 ? 1 : 0}}>
              <WebMap ref={webMapRef} whenCreated={(map) => { webMapRef.current = map }} key={JSON.stringify(initialPositionBasedOnIP)} center={[initialPositionBasedOnIP.latitude, initialPositionBasedOnIP.longitude]} zoom={zoomLevel} style={styles.mapStyle}>
                <WebTileLayer
                  attribution='&copy; <a href="https://tile.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {webMarkers.map((marker, i) => (
                  <WebMarker
                    position={[GUIManager.getInstance().allOwners[i].latitude, GUIManager.getInstance().allOwners[i].longitude]}
                    icon={customWebMarkerIcon}
                    key={marker.ownerId}
                    ref={marker.markRef}
                  >
                    <CustomWebPopup tmpRef={marker.popRef} onClick={(tmpIndex) => handleWebPopupClick(tmpIndex)} tmpOwnerIndex={i}>
                    </CustomWebPopup>
                    <WebTooltip ref={marker.tolRef}>{GUIManager.getInstance().allOwners[i].company_name}</WebTooltip>
                  </WebMarker>
                ))}
              </WebMap>
            </View>
          }
      </View>
    </TouchableWithoutFeedback>
  );
};

export default OwnerBrowseStudiosSubview;
