import React, { memo } from 'react';
import { Dimensions, View } from 'react-native';
import { Button, Colors, Dialog, IconButton, Modal, Paragraph, Portal, Snackbar, Text, TextInput} from 'react-native-paper';
import i18n from '../../utils/i18n';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const OwnerManagePhotosFullScreenModal = ({  
                                            currentIndex, 
                                            allSize, 
                                            images,
                                            currentImageId,
                                            setCurrentImageId,                                             
                                            setPhotoDesc,                                             
                                            newPhotoDesc, 
                                            setNewPhotoDesc,                                             
                                            saveBtnDisabled,                                             
                                            editPhotoDescAsync,                                             
                                            deletePhotoDialogVisible, 
                                            setDeletePhotoDialogVisible, 
                                            hideDeletePhotoDialog, 
                                            deletePhotoAsync,                                            
                                            photoEditedSnackBarVisible,
                                            onDismissPhotoEditedSnackBar
}) => {
    const screenDimensions = Dimensions.get('window');

    const currentImage = images[currentIndex-1];    

    if(currentImage != undefined) {
        if(currentImageId != currentImage.id) {
        setCurrentImageId(currentImage.id);
        setPhotoDesc(currentImage.description);
        setNewPhotoDesc(currentImage.description);
        }
    }
    
    return (
        <View>
        <Portal>
            <Modal animationType="fade"
                    transparent={true}
                    visible={deletePhotoDialogVisible}
                    onRequestClose={() => { setDeletePhotoDialogVisible(false); }}>
            <Dialog style={{position: 'absolute', bottom: 0, width: screenDimensions.width*0.6, left: screenDimensions.width*0.13}} visible={deletePhotoDialogVisible} onDismiss={hideDeletePhotoDialog}>
                <Dialog.Title>{i18n.t('delete')}</Dialog.Title>
                <Dialog.Content>
                <Paragraph>{i18n.t('areYouSure')}</Paragraph>
                </Dialog.Content>
                <Dialog.Actions>
                <Button onPress={() => setDeletePhotoDialogVisible(false)}>{i18n.t('cancel')}</Button>
                <Button onPress={() => deletePhotoAsync(currentImage.id)}>Ok</Button>
                </Dialog.Actions>
            </Dialog>
            </Modal>
        </Portal>

        <View style={{ flexDirection: "row", position: 'absolute', bottom: 0, width: screenDimensions.width, alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.6)', paddingBottom: 20 }}>
            <View style={{flex: 1, alignItems: newPhotoDesc!=undefined && newPhotoDesc!='' ? 'flex-start' : 'center', marginLeft: newPhotoDesc!=undefined && newPhotoDesc!='' ? 15 : 0}}>
                <Text style={{fontSize: 17, color: "#FFF"}}>{`${currentIndex}/${allSize}`}</Text>
            </View>
            <View style={{flex: 5, alignItems: 'center', justifyContent: 'center'}}>
                <TextInput
                        label={i18n.t('photoDescOptional')}
                        style={{ width: '100%', marginTop: 0, marginBottom: 0 }}
                        value={newPhotoDesc}
                        onChangeText={tmpPhotoDesc => setNewPhotoDesc(tmpPhotoDesc)}
                        editable={true}
                />
            </View>
            <View style={{flex: 1, alignItems: 'flex-end', marginTop: 0, display: 'flex'}}>
                <IconButton icon={({ color, size }) => (<MaterialCommunityIcons name="content-save" color={color} size={size} />)} disabled={saveBtnDisabled} size={30} color={Colors.white} onPress={() => editPhotoDescAsync(currentImage.id)} />
            </View>
            <View style={{flex: 1, alignItems: 'flex-end', marginTop: 0, display: 'flex'}}>
                <IconButton icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)} size={30} color={Colors.red500} onPress={() => setDeletePhotoDialogVisible(true)} />
            </View>
        </View>

        <Snackbar
            style={{borderColor: 'white', 
            borderWidth: 3, 
            backgroundColor: '#6200EE',
            marginBottom: 90}}
            visible={photoEditedSnackBarVisible}
            onDismiss={onDismissPhotoEditedSnackBar}>
            {i18n.t('photoDescUpdated')}
        </Snackbar>
        </View>
    );
};


/*const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps.myRoomsDropdownList) === JSON.stringify(nextProps.myRoomsDropdownList);
}*/

export default memo(OwnerManagePhotosFullScreenModal);