export default {
    lookingFor: "Ψάχνω για",
    category: "Κατηγορία",
    studioCategory: "Studios προβών/ηχογραφήσεων",
    tennisCategory: "Γήπεδα τένις",
    studiosDomainTitle: "Studio προβών/ηχογραφήσεων",
    tennisDomainTitle: "Γήπεδο τένις",
    privacyPolicy: "ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ",

    youHaveAnotherReservationAtThisTime: "Η κράτηση δε μπορεί να ολοκληρωθεί, γιατί οι ώρες της συμπίπτουν με κάποια άλλη κράτησή σας!",
    internalError: "Συνέβη κάποιο εσωτερικό σφάλμα! Παρακαλώ επανεκκινήστε το HandyBooker και ξαναπροσπαθήστε.",

    pleaseCompleteEmail: "Παρακαλώ συμπληρώστε το email σας!",
    pleaseCompletePassword: "Παρακαλώ συμπληρώστε τον κωδικό σας!",
    pleaseCompleteValidEmail: "Αυτό το email δεν είναι έγκυρο!",

    reminderTitle: "HandyBooker - Πρέπει να είστε στο studio σε 2 ώρες!",

    back: "Πίσω",
    login: "ΕΙΣΟΔΟΣ",
    browse: "Αναζήτηση",
    browseStudios: "ΑΝΑΖΗΤΗΣΗ STUDIO",
    findStudio: "Βρείτε Studio",
    findTennisCourt: "Βρείτε γήπεδο τένις",
    studios: "Studios",
    tennisCourts: "Γήπεδα τένις",
    loading: "Παρακαλώ περιμένετε...",
    forgotPassword: "ΞΕΧΑΣΑ ΤΟΝ ΚΩΔΙΚΟ ΜΟΥ",
    wrongEmailPassword: "Λάθος email/κωδικός!",
    studioSummary: "ΠΛΗΡΟΦΟΡΙΕΣ STUDIO",
    myLocation: "Η τοποθεσία μου",
    search: "Αναζήτηση",
    musicianControlPanel: "Πίνακας ελέγχου μουσικού",
    studioInfo: "Πληροφορίες studio",
    tennisCourtInfo: "Πληροφορίες γηπέδου τένις",
    makeReservation: "ΚΑΝΤΕ ΚΡΑΤΗΣΗ",
    ownerNotYetRegistered: "Ο ιδιοκτήτης αυτού του studio δεν έχει εγγραφεί ακόμη, επομένως δεν επιτρέπονται κρατήσεις μέσω του HandyBooker.",
    promoteHandyBookerToOwnerPartOne: "Πατώντας το παρακάτω κουμπί, μπορείτε να προτείνετε το HandyBooker στον ιδιοκτήτη του studio (θα σταλεί αυτόματα ένα email που θα λέει ότι ο/η ",
    promoteHandyBookerToOwnerPartTwo: " σας προτείνει να χρησιμοποιήσετε το HandyBooker).",
    promoteHandyBookerToOwnerBtn: "ΠΡΟΤΕΙΝΕΤΕ ΤΟ HandyBooker",
    promotionSuccessful: "Η πρότασή σας υποβλήθηκε επιτυχώς! Σας ευχαριστούμε για την υποστήριξη!",
    youHaveAlreadyPromotedThisOwner: "Έχετε ήδη προτείνει το HandyBooker σε αυτό το studio!",
    musician: "Μουσικός",
    player: "Παίκτης",
    bandCapital: "Μπάντα",
    cancelReservation: "ΑΚΥΡΩΣΗ ΚΡΑΤΗΣΗΣ",
    reservationCancelled: "Η κράτηση έχει ακυρωθεί και ο μουσικός έχει ενημερωθεί μέσω email.",
    newReservation: "Νέα κράτηση",
    reservedByMe: "(Δική μου κράτηση)",

    registrationIsNeeded: "Απαιτείται εγγραφή",

    noGPSAllowed: "Δε δόθηκε άδεια πρόσβασης στην τοποθεσία. Επομένως, η εφαρμογή δε μπορεί να λειτουργήσει κανονικά!",

    studioOwnerInfo: "Στοιχεία ιδιοκτήτη",
    tennisCourtOwnerInfo: "Στοιχεία ιδιοκτήτη",
    companyInfo: "Πληροφορίες εταιρείας",
    musicianDetails: "Πληροφορίες μουσικού",
    playerDetails: "Πληροφορίες παίκτη",

    mondayTitle: "Δευτέρα",
    tuesdayTitle: "Τρίτη",
    wednesdayTitle: "Τετάρτη",
    thursdayTitle: "Πέμπτη",
    fridayTitle: "Παρασκευή",
    saturdayTitle: "Σάββατο",
    sundayTitle: "Κυριακή",
    januaryTitle: "Ιανουαρίου",
    februaryTitle: "Φεβρουαρίου",
    marchTitle: "Μαρτίου",
    aprilTitle: "Απριλίου",
    mayTitle: "Μαΐου",
    juneTitle: "Ιουνίου",
    julyTitle: "Ιουλίου",
    augustTitle: "Αυγούστου",
    septemberTitle: "Σεπτεμβρίου",
    octoberTitle: "Οκτωβρίου",
    novemberTitle: "Νοεμβρίου",
    decemberTitle: "Δεκεμβρίου",
    
    musicianProfile: "Προφίλ μουσικού",
    tennisPlayerProfile: "Προφίλ παίκτη τένις",
    myProfile: "Το προφίλ μου",
    settings: "Ρυθμίσεις",
    logout: "Αποσύνδεση",
    name: "Όνομα",
    surname: "Επώνυμο",
    telephone: "Τηλέφωνο",
    password: "Κωδικός",
    passwordAgain: "Κωδικός (ξανά)",
    band: "μπάντα: ",
    bandName: "Όνομα μπάντας",
    save: "ΑΠΟΘΗΚΕΥΣΗ",
    profileUpdated: "Το προφίλ σας ανανεώθηκε!",
    coordinatesUpdated: "Η τοποθεσία ανανεώθηκε! Παρακαλώ επανεκκινήστε την εφαρμογή για να ανανεωθεί η πινέζα στο χάρτη.",
    coordinatesUpdatedWeb: "Η τοποθεσία ανανεώθηκε! Παρακαλώ πατήστε ανανέωση την εφαρμογής στο web browser σας πατώντας F5 για να ανανεωθεί η πινέζα στο χάρτη.",
    passwordsDoNotMatch: "Οι κωδικοί δεν ταυτίζονται!",
    studioOwnerProfile: "Προφίλ ιδιοκτήτη studio",
    tennisCourtOwnerProfile: "Προφίλ ιδιοκτήτη γηπέδων τένις",
    verifyAddress: "ΕΠΙΒΕΒΑΙΩΣΗ ΔΙΕΥΘΥΝΣΗΣ",
    addressVerified: "Η διεύθυνσή σας έχει επιβεβαιωθεί!",

    accountSettings: "Ρυθμίσεις λογαριασμού",
    receiveEmailNotifications: "Λήψη ειδοποιήσεων μέσω email",
    yesEmailNotifications: "Από τώρα και στο εξής θα ενημερώνεστε και μέσω email για νέες κρατήσεις",
    noEmailNotifications: "Από τώρα και στο εξής δε θα ενημερώνεστε μέσω email για νέες κρατήσεις",
    deleteAccount: "ΔΙΑΓΡΑΦΗ ΛΟΓΑΡΙΑΣΜΟΥ",
    accountDeleted: "Ο λογαριασμός σας έχει διαγραφεί!",

    addARoom: "Προσθήκη χώρου",
    roomName: "Όνομα αίθουσας",
    courtName: "Όνομα γηπέδου",
    address: "Διεύθυνση",
    website: "Ιστοσελίδα",
    currency: "Νόμισμα",
    day: "Ημέρα",
    from: "Από",
    to: "'Eως",
    workingHours: "Ώρες λειτουργίας",
    workingHoursFrom: "Ώρες λειτουργίας (από)",
    workingHoursTo: "Ώρες λειτουργία (έως)",
    totalCost: "Κόστος",
    cost: "Κόστος (ανά ώρα)",
    costForGuests: "Κόστος (για μη μέλη)",
    rehearsalCost: "Κόστος πρόβας (ανά ώρα)",
    recordingCost: "Κόστος ηχογράφησης (ανά ώρα)",
    monday: "Δευτέρα",
    tuesday: "Τρίτη",
    wednesday: "Τετάρτη",
    thursday: "Πέμπτη",
    friday: "Παρασκευή",
    saturday: "Σάββατο",
    sunday: "Κυριακή",
    open: "Ανοιχτά",
    closed: "Κλειστά",
    availableRooms: "Διαθέσιμες αίθουσες",
    details: "Πληροφορίες",
    equipment: "Λεπτομέρειες χώρου/Εξοπλισμός",
    space: "Διαστάσεις (m2)",
    drums: "Τύμπανα",
    guitarAmplifiers: "Ενισχυτές κιθάρας",
    bassAmplifiers: "Ενισχυτές μπάσου",
    microphones: "Μικρόφωνα",
    console: "Κονσόλα",
    speakers: "Ηχεία",
    guitars: "Κιθάρες",
    basses: "Μπάσα",
    pianosKeyboards: "Πιάνο/πλήκτρα",
    otherEquipment: "Άλλο",
    selectDateTime: "Επιλέξτε ημέρα/ώρα",
    free: "Διαθέσιμο",
    reserved: "Μη διαθέσιμο",
    reservedByYou: "ΚΡΑΤΗΜΕΝΟ ΑΠΟ ΕΣΑΣ",

    manageRooms: "Διαχείριση Αιθουσών",
    manageCourts: "Διαχείριση Γηπέδων",
    addFirstRoom: "Προσθέστε μια αίθουσα",
    addFirstCourt: "Προσθέστε ένα γήπεδο",
    editRoomInfo: "Επεξεργασία πληροφοριών αίθουσας",
    editCourtInfo: "Επεξεργασία πληροφοριών γηπέδου",
    selectRoom: "Επιλέξτε αίθουσα",
    selectCourt: "Επιλέξτε γήπεδο",
    freeCost: "Δωρεάν",
    type: "Τύπος",
    acrylic: "Ακρυλικό",
    artificialClay: "Τεχνητό χώμα",
    artificialGrass: "Τεχνητό γρασίδι",
    ashpalt: "Άσφαλτος",
    carpet: "Χαλί",
    clay: "Χώμα",
    concrete: "Τσιμέντο",
    grass: "Γρασίδι",
    other: "Άλλο",
    differentCurrencies: "Όλες οι αίθουσες σας πρέπει να δέχονται πληρωμές στο ίδιο νόμισμα ",
    pleaseCompleteMondayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος της Δευτέρας!",
    pleaseCompleteMondayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος της Δευτέρας!",
    pleaseCompleteTuesdayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος της Τρίτης!",
    pleaseCompleteTuesdayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος της Τρίτης!",
    pleaseCompleteWednesdayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος της Τετάρτης!",
    pleaseCompleteWednesdayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος της Τετάρτης!",
    pleaseCompleteThursdayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος της Πέμπτης!",
    pleaseCompleteThursdayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος της Πέμπτης!",
    pleaseCompleteFridayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος της Παρασκευής!",
    pleaseCompleteFridayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος της Παρασκευής!",
    pleaseCompleteSaturdayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος του Σαββάτου!",
    pleaseCompleteSaturdayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος του Σαββάτου!",
    pleaseCompleteSundayStartHour: "Παρακαλώ συμπληρώστε την ώρα ανοίγματος της Κυριακής!",
    pleaseCompleteSundayStopHour: "Παρακαλώ συμπληρώσετ την ώρα κλεισίματος της Κυριακής!",
    areYouSureThatRehearsalOnMondayIsFree: "Είστε σίγουρος/η ότι η πρόβα τη Δευτέρα είναι δωρεάν;",
    areYouSureThatRecordingOnMondayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση τη Δευτέρα είναι δωρεάν;",
    areYouSureThatRehearsalOnTuesdayIsFree: "Είστε σίγουρος/η ότι η πρόβα την Τρίτη είναι δωρεάν;",
    areYouSureThatRecordingOnTuesdayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση την Τρίτη είναι δωρεάν;",
    areYouSureThatRehearsalOnWednesdayIsFree: "Είστε σίγουρος/η ότι η πρόβα την Τετάρτη είναι δωρεάν;",
    areYouSureThatRecordingOnWednesdayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση την Τετάρτη είναι δωρεάν;",
    areYouSureThatRehearsalOnThursayIsFree: "Είστε σίγουρος/η ότι η πρόβα την Πέμπτη είναι δωρεάν;",
    areYouSureThatRecordingOnThursdayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση την Πέμπτη είναι δωρεάν;",
    areYouSureThatRehearsalOnFridayIsFree: "Είστε σίγουρος/η ότι η πρόβα την Παρασκευή είναι δωρεάν;",
    areYouSureThatRecordingOnFridayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση την Παρασκευή είναι δωρεάν;",
    areYouSureThatRehearsalOnSaturdayIsFree: "Είστε σίγουρος/η ότι η πρόβα το Σάββατο είναι δωρεάν;",
    areYouSureThatRecordingOnSaturdayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση το Σάββατο είναι δωρεάν;",
    areYouSureThatRehearsalOnSundayIsFree: "Είστε σίγουρος/η ότι η πρόβα την Κυριακή είναι δωρεάν;",
    areYouSureThatRecordingOnSundayIsFree: "Είστε σίγουρος/η ότι η ηχογράφηση την Κυριακή είναι δωρεάν;",
    pleaseEnterValidCostForMondayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας τη Δευτέρα!",
    pleaseEnterValidCostForMondayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης τη Δευτέρα!",
    pleaseEnterValidCostForTuesdayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας την Τρίτη!",
    pleaseEnterValidCostForTuesdayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης την Τρίτη!",
    pleaseEnterValidCostForWednesdayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας την Τετάρτη!",
    pleaseEnterValidCostForWednesdayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης την Τετάρτη!",
    pleaseEnterValidCostForThursdayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας την Πέμπτη",
    pleaseEnterValidCostForThursdayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης την Πέμπτη",
    pleaseEnterValidCostForFridayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας την Παρασκευή!",
    pleaseEnterValidCostForFridayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης την Παρασκευή!",
    pleaseEnterValidCostForSaturdayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας το Σάββατο!",
    pleaseEnterValidCostForSaturdayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης το Σάββατο!",
    pleaseEnterValidCostForSundayReh: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος πρόβας την Κυριακή!",
    pleaseEnterValidCostForSundayRec: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος ηχογράφησης την Κυριακή!",
    theRoomShouldBeAtLeastOneDayOpen: "Ο χώρος πρέπει να είναι ανοικτός για τουλάχιστον μια μέρα της εβδομάδας!",

    areYouSureThatCostOnMondayIsFree: "Είστε σίγουρος/η ότι η χρέωση τη Δευτέρα είναι δωρεάν;",
    areYouSureThatCostOnTuesdayIsFree: "Είστε σίγουρος/η ότι η χρέωση την Τρίτη είναι δωρεάν;",
    areYouSureThatCostOnWednesdayIsFree: "Είστε σίγουρος/η ότι η χρέωση την Τετάρτη είναι δωρεάν;",
    areYouSureThatCostOnThursdayIsFree: "Είστε σίγουρος/η ότι η χρέωση την Πέμπτη είναι δωρεάν;",
    areYouSureThatCostOnFridayIsFree: "Είστε σίγουρος/η ότι η χρέωση την Παρασκευή είναι δωρεάν;",
    areYouSureThatCostOnSaturdayIsFree: "Είστε σίγουρος/η ότι η χρέωση το Σάββατο είναι δωρεάν;",
    areYouSureThatCostOnSundayIsFree: "Είστε σίγουρος/η ότι η χρέωση την Κυριακή είναι δωρεάν;",
    pleaseEnterValidCostForMonday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος τη Δευτέρα!",
    pleaseEnterValidCostForTuesday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος την Τρίτη!",
    pleaseEnterValidCostForWednesday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος την Τετάρτη!",
    pleaseEnterValidCostForThursday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος την Πέμπτη!",
    pleaseEnterValidCostForFriday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος την Παρασκευή!",
    pleaseEnterValidCostForSaturday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος το Σάββατο!",
    pleaseEnterValidCostForSunday: "Παρακαλώ εισάγετε έναν έγκυρο αριθμό (>=0) για το κόστος την Κυριακή!",
    theCourtShouldBeAtLeastOneDayOpen: "Το γήπεδο πρέπει να είναι ανοικτό για τουλάχιστον μια μέρα της εβδομάδας!",

    roomUpdated: "Οι πληροφορίες της αίθουσας έχουν ανανεωθεί!",
    courtUpdated: "Οι πληροφορίες του γηπέδου έχουν ανανεωθεί!",
    congratulations: "Συγχαρητήρια!",
    roomAdded: "Ο νέος χώρος προστέθηκε!",
    courtAdded: "Το νέο γήπεδο προστέθηκε!",
    add: "ΠΡΟΣΘΗΚΗ",
    addRoom: "ΠΡΟΣΘΗΚΗ ΑΙΘΟΥΣΑΣ",
    addCourt: "ΠΡΟΣΘΗΚΗ ΓΗΠΕΔΟΥ",
    delete: "ΔΙΑΓΡΑΦΗ",
    deleteRoom: "Διαγραφή αίθουσας!",
    deleteCourt: "Διαγραφή γηπέδου!",
    deleteRoomAreYouSure: "Σίγουρα θέλετε να προχωρήσετε με τη διαγραφή αυτής της αίθουσας;",
    deleteCourtAreYouSure: "Σίγουρα θέλετε να προχωρήσετε με τη διαγραφή αυτού του γηπέδου;",
    roomHasBeenDeleted: "Η αίθουσα έχει διαγραφεί!",
    courtHasBeenDeleted: "Το γήπεδο έχει διαγραφεί!",
    caution: "Προσοχή!",

    photos: "Φωτογραφίες",
    infrastructure: "Υποδομές",
    rooms: "Αίθουσες",
    customers: "Πελάτες",
    players: "Μουσικοί",
    myPlayers: "Οι μουσικοί μου",
    tennisPlayers: "Παίκτες τένις",
    studios: "Studios",
    
    doYouWantToBlockMusician: "Θέλετε να μπλοκάρετε αυτόν τον μουσικό;",
    doYouWantToUnblockMusician: "Θέλετε να ξεμπλοκάρετε αυτόν τον μουσικό;",
    blockMusicianMsg: "Αν αντιμετωπίζετε προβλήματα με κάποιον μουσικό (π.χ. συνεχώς ακυρώνει κρατήσεις, καταστρέφει εξοπλισμό, κλπ.) μπορείτε να τον μπλοκάρετε. Ένας μπλοκαρισμένος μουσικός δε μπορεί κάνει κρατήσεις στο studio σας.",
    unblockMusicianMsg: "Αν τον/την ξεμπλοκάρετε, θα μπορεί να κάνει κρατήσεις στο studio σας.",

    doYouWantToBlockPlayer: "Θέλετε να μπλοκάρετε αυτόν τον παίκτη;",
    doYouWantToUnblockPlayer: "Θέλετε να ξεμπλοκάρετε αυτόν τον παίκτη;",
    blockPlayerMsg: "Αν αντιμετωπίζετε προβλήματα με κάποιον παίκτη (π.χ. συνεχώς ακυρώνει κρατήσεις, καταστρέφει εξοπλισμό, κλπ.) μπορείτε να τον μπλοκάρετε. Ένας μπλοκαρισμένος παίτης δε μπορεί κάνει κρατήσεις στα γήπεδά σας.",
    unblockPlayerMsg: "Αν τον/την ξεμπλοκάρετε, θα μπορεί να κάνει κρατήσεις στα γήπεδά σας.",
    
    musicianBlockedSuccessfully: "Ο μουσικός μπλοκαρίστηκε επιτυχώς!",
    musicianUnblockedSuccessfully: "Ο μουσικός ξεμπλοκαρίστηκε επιτυχώς!",
    playerBlockedSuccessfully: "Ο παίκτης μπλοκαρίστηκε επιτυχώς!",
    playerUnblockedSuccessfully: "Ο παίκτης ξεμπλοκαρίστηκε επιτυχώς!",
    blocked: "Μπλοκαρισμένος/η",
    messageToBlockedPlayer: "Παρακαλώ επανεκκινήστε την εφαρμογή και ξαναπροσπαθήστε!",

    clubMembersRequests: "Αιτήματα εγγραφής νέων μελών",
    pendingSince: "Εκκρεμεί από",
    preferredMembership: "Πακέτο συνδρομής",
    saysHeIsAlreadyMember: "Ο παίκτης δήλωσε ότι είναι ήδη μέλος του club",
    confirmation: "Επιβεβαίωση",
    confirmationMsgForClubRequestAcceptance: "Πρόκειται να αποδεχτείτε αυτό το αίτημα και να προσθέσετε αυτόν τον παίκτη/παίκτρια στα μέλη του club σας",
    confirmationMsgForClubRequestRejection: "Πρόκειται να απορρίψετε αυτό το αίτημα και να μην προσθέσετε αυτόν τον παίκτη/παίκτρια στα μέλη του club σας",
    clubRequestAccepted: "Αποδεχτήκατε το αίτημα επιτυχώς!",
    clubRequestRejected: "Το αίτημα απορρίφθηκε!",
    clubMembers: "Μέλη του club",
    clubMember: "Μέλος του club",
    guest: "Επισκέπτης",
    memberSince: "Μέλος από",
    membershipPlan: "Πακέτο συνδρομής",
    membershipValidUntil: "Συνδρομή σε ισχύ έως",
    membershipPlanNotSelected: "Δεν έχει επιλεγεί πακέτο συνδρομής",
    membershipExpired: "Η συνδρομή έχει λήξει",
    nonMembersGuests: "Επισκέπτες (μη μέλη)",
    show: "Εμφάνισε",
    onlyClubRequests: "Αιτήματα εγγραφής νέων μελών",
    onlyClubMembers: "Όλα τα μέλη του club",
    onlyValidClubMembers: "Συνδρομές σε ισχύ",
    onlyExpiredMembers: "Ληγμένες συνδρομές",
    onlyGuests: "Επισκέπτες (μη μέλη)",
    allTheAbove: "Όλα",
    noPendingClubRequests: "Κανένα αίτημα σε εκκρεμότητα",
    noClubMembers: "Κανένα μέλος",
    noGuests: "Κανένας επισκέπτης",
    noMusicians: "Κανένας μουσικός",
    noCustomers: "Κανένας πελάτης",
    youAreMemberOfThisTennisClub: "Είστε μέλος αυτού του τένις club",
    addNewClubMember: "Προσθήκη νέου μέλους του club",
    clubMemberAddedSuccessfully: "Το νέο μέλος του club προστέθηκε επιτυχώς!",
    thereIsAnotherClubMemberWithThisEmail: "Υπάρχει ήδη ένα μέλος του club σας με αυτό το email!",
    intruderMsg: "Δεν έχετε δικαιώματα για αυτή την ενέργεια!",
    youAreGoindToDeleteClubMember: "Θέλετε να προχωρήστε στη διαγραφή αυτού του μέλους του club;",
    clubMemberDeleted: "Το μέλος του club διαγράφηκε!",
    selectDate: "ΕΠΙΛΟΓΗ ΗΜΕΡΟΜΗΝΙΑΣ",

    managePhotos: "Διαχείριση Φωτογραφιών",
    uploadPhoto: "Ανέβασμα νέας φωτογραφίας",
    newPhoto: "ΝΕΑ ΦΩΤΟΓΡΑΦΙΑ",
    fromLibrary: "ΒΙΒΛΙΟΘΗΚΗ",
    fromCamera: "ΚΑΜΕΡΑ",
    myPhotos: "Οι φωτογραφίες μου",
    photoDeleted: "Η φωτογραφία διαγράφηκε!",
    photoDesc: "Περιγραφή",
    photoDescOptional: "Περιγραφή (προαιρετικά)",
    photoDescUpdated: "Η περιγραφή της φωτογραφίας ενημερώθηκε!",
    photoUploaded: "Η φωτογραφία προστέθηκε επιτυχώς!",
    noUploadedPhotosYet: "Δεν έχετε προσθέσει καμιά φωτογραφία της επιχείρησής σας ακόμη!",

    january: "Ιανουάριος",
    february: "Φεβρουάριος",
    march: "Μάρτιος",
    april: "Απρίλιος",
    may: "Μάιος",
    june: "Ιούνιος",
    july: "Ιούλιος",
    august: "Αύγουστος",
    september: "Σεπτέμβριος",
    october: "Οκτώβριος",
    november: "Νοέμβριος",
    december: "Δεκέμβριος",

    januaryShort: "Ιαν",
    februaryShort: "Φεβ",
    marchShort: "Μαρ",
    aprilShort: "Απρ",
    mayShort: "Μάι",
    juneShort: "Ιούν",
    julyShort: "Ιούλ",
    augustShort: "Αύγ",
    septemberShort: "Σεπ",
    octoberShort: "Οκτ",
    novemberShort: "Νοέ",
    decemberShort: "Δεκ",

    mondayShort: "Δευ",
    tuesdayShort: "Τρ",
    wednesdayShort: "Τετ",
    thursdayShort: "Πέμ",
    fridayShort: "Παρ",
    saturdayShort: "Σάβ",
    sundayShort: "Κυρ",

    today: "Σήμερα",

    selectType: "Επιλέξτε τύπο",
    studio: "Στούντιο",
    company: "Επιχείρηση",
    reservationDetails: "Πληροφορίες κράτησης",
    rehearsal: "Πρόβα",
    recording: "Ηχογράφηση",
    room: "Αίθουσα",
    tennisCourt: "Γήπεδο τένις",
    rehearsalRecording: "Πρόβα/Ηχογράφηση",
    date: "Ημερομηνία",
    hours: "Ώρες",
    commentsOptional: "Σχόλια (προαιρετικά)",
    comments: "Σχόλια",
    continue: "ΣΥΝΕΧΕΙΑ",
    completeReservation: "ΟΛΟΚΛΗΡΩΣΗ ΚΡΑΤΗΣΗΣ",
    selectTimeslots: "Παρακαλώ επιλέξτε τις επιθυμητές ώρες",
    timeslotsAreNotSequential: "Οι επιλεγμένες ώρες πρέπει να είναι συνεχόμενες",
    reservationAddedSuccessfully: "Η κράτησή σας έχει ολοκληρωθεί επιτυχώς!",
    pullDownToUpdate: "Τραβήξτε προς τα κάτω για ανανέωση",
    invalidHours: "Παρακαλώ επιλέξτε ώρες που δεν έχουν παρέλθει!",
    reservationMadeByYou: "Η κράτηση έγινε από εσάς",

    myReservations: "Οι κρατήσεις μου",
    reservations: "Κρατήσεις",
    type: "Τύπος",
    youHaveNoReservationForThisDate: "Δεν έχετε κάποια κράτηση για αυτή την ημερομηνία",

    reportOwnerMenuTitle: "Αναφορά",
    reportOwner: "Αναφέρετε ιδιοκτήτη",
    doYouHavePrlblemsWithOwner: "Αν αντιμετωπίζετε προβλήματα με κάποιον ιδιοκτήτη studio (π.χ. ακύρωσε την κράτησή σας χωρίς να σας ειδοποιήσει), παρακαλώ ενημερώστε μας συμπληρώνοντας αυτή τη φόρμα. Η επικοινωνία θα είναι εμπιστευτική. Ο ιδιοκτήτης δε θα ενημερωθεί για την αναφορά αυτή!",
    ownerEmail: "Email ιδιοκτήτη",
    reason: "Αιτιολογία",
    submitReport: "ΥΠΟΒΟΛΗ",
    noSuchOwnerExists: "Δεν υπάρχει ιδιοκτήτης με αυτό το email! Παρακαλούμε ελέγξτε αν γράψατε το email σωστά!",
    reportSubmittedSuccessfully: "Η αναφορά υπεβλήθη επιτυχώς!",
    completeOwnerEmail: "Παρακαλούμε συμπληρώστε το email του ιδιοκτήτη!",
    completeReportReason: "Παρακαλούμε συμπληρώστε την αιτία της αναφοράς!",

    contactUsMenuTitle: "Επικοινωνία",
    contactUs: "Επικοινωνήστε μαζί μας",
    yourComments: "Τα σχόλιά σας",
    completeComments: "Παρακαλώ συμπληρώστε τα σχόλιά σας",
    commentsSubmittedSuccessfully: "Σας ευχαριστούμε! Τα σχόλιά σας υποβλήθηκαν επιτυχώς!",

    cancel: "ΑΚΥΡΩΣΗ",
    areYouSure: "Είστε σίγουρος/η;",

    resetPassword: "Επαναφορά κωδικού",
    sendKey: "ΑΠΟΣΤΟΛΗ ΚΛΕΙΔΙΟΥ",
    or: "Ή",
    iVeAlreadyGottenKey: "Αν έχετε ήδη λάβει κλειδί",
    newPassword: "Νέος κωδικός",
    newPasswordAgain: "Νέος κωδικός (ξανά)",
    key: "Κλειδί",
    setNewPassword: "ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ",
    pleaseCompleteEmail: "Παρακαλώ συμπληρώστε το email σας!",
    pleaseCompleteNewPassword: "Παρακαλώ συμπληρώστε το νέο κωδικό σας!",
    pleaseCompleteNewPasswordAgain: "Παρακαλώ συμπληρώστε το νέο κωδικό σας ξανά!",
    pleaseCompleteKey: "Παρακαλώ συμπληρώστε το κλειδί που έχετε λάβει με email!",
    keySent: "Ένα κλειδί για ανάκτηση του κωδικού σας έχει σταλεί στο email σας!",
    keyAlreadySent: "Ένα κλειδί για ανάκτηση του κωδικού σας έχει ήδη σταλεί στο email σας!",
    invalidEmailOrKey: "Το email ή το κλειδί που εισήγατε δεν είναι έγκυρο!",
    passwordHasChanged: "Ο κωδικός σας έχει αλλάξει επιτυχώς!",

    notYetRegistered: "Δεν έχετε εγγραφεί;",
    signUp: "ΕΓΓΡΑΦΗ",
    createAccount: "Δημιουργία λογαριασμού",
    iAmMusician: "Είμαι μουσικός",
    iAmStudioOwner: "Είμαι ιδιοκτήτης studio",
    iAmTennisPlayer: "Είμαι παίκτης τένις",
    iAmTennisCourtOwner: "Είμαι ιδιοκτήτης γηπέδου/ων τένις",
    reservationsOnlyForRehearsal: "Κρατήσεις μόνο για πρόβες",
    reservationsOnlyForRecording: "Κρατήσεις μόνο για ηχογραφήσεις",
    reservationsForRehearsalAndRecording: "Κρατήσεις για πρόβες και ηχογραφήσεις",
    roomEditingNeeded: "Πρέπει να ανανεώσετε τις πληροφορίες των χώρων σας!",
    youNeedToSetCostForRehersal: "Πρέπει να ορίσετε το κόστος πρόβας για όλους τους χώρους σας.",
    youNeedToSetCostForRecording: "Πρέπει να ορίσετε το κόστος ηχογράφησης για όλους τους χώρους σας.",
    defineRehearsalCost: "Καθορίστε κόστος πρόβας",
    defineRecordingCost: "Καθορίστε κόστος ηχογράφησης",
    youNeedToDefineAtLeastOneMembershipPlan: "Πρέπει να ορίσετε τουλάχιστον ένα πακέτο εγγραφής μέλους",
    defineMembershipPlans: "Καθορίστε πακέτα εγγραφής μελών",
    youNeedToDefineTheCostPerHourForEachCourtForNonMembers: "Για μη μέλη του club πρέπει να ορίσετε το κόστος χρήσης (ανά ώρα) για όλα τα γήπεδά σας",
    defineTheCostPerHourForEachCourt: "Καθορίστε κόστος χρήσης (ανά ώρα)",
    membershipPlansNeeded: "Απαιτείται ορισμός πακέτων εγγραφής!",
    courtEditingNeeded: "Πρέπει να ανανεώσετε τις πληροφορίες των γηπέδων σας!",
    onlyClubMembersCanReserve: "Είναι ιδιωτικό club - Κρατήσεις επιτρέπονται μόνο σε μέλη του club",
    clubMembersAndNonClubMembersCanReserve: "Είναι ιδιωτικό club - Κρατήσεις επιτρέπονται σε μέλη του club αλλά και σε μη-μέλη",
    onlyNonClubMembersCanReserve: "Δεν είναι ιδιωτικό club - Κρατήσεις επιτρέπονται στον καθένα",

    privateClub: "Ιδιωτικό Τένις Club",
    options: "Επιλογές",
    joinClub: "Εγγραφή στο club",
    iAmAlreadyAMember: "Είμαι ήδη μέλος",
    reserveAsGuest: "Κράτηση ως επισκέπτης",
    selectMembershipPlan: "Επιλογή πακέτου εγγραφής",
    sendRequest: "ΑΠΟΣΤΟΛΗ ΑΙΤΗΜΑΤΟΣ",
    youHaveAlreadySendARequest: "Έχετε στείλει ήδη αίτημα στο συγκεκριμένο τένις club. Θα ειδοποιηθείτε όταν το τένις club επεξεργαστεί το αίτημά σας.",
    requestSent: "Το αίτημα εστάλη! Θα ειδοποιηθείτε όταν το τένις club επεξεργαστεί το αίτημά σας.",

    companyName: "Όνομα εταιρίας",
    postalAddress: "Ταχυδρομική διεύθυνση",
    city: "Πόλη",
    country: "Χώρα",
    longitude: "Γεωγραφικό μήκος",
    latitude: "Γεωγραφικό πλάτος",
    website: "Ιστοσελίδα",
    pleaseCompleteName: "Παρακαλώ συμπληρώστε το όνομά σας!",
    pleaseCompleteSurname: "Παρακαλώ συμπληρώστε το επώνυμό σας!",
    pleaseCompleteEmail: "Παρακαλώ συμπληρώστε το email σας!",
    pleaseCompletePhone: "Παρακαλώ συμπληρώστε το τηλέφωνό σας!",
    pleaseCompletePassword: "Παρακαλώ συμπληρώστε τον κωδικό σας!",
    pleaseCompletePasswordAgain: "Παρακαλώ συμπληρώστε τον κωδικό σας ξανά!",
    pleaseCompleteCompanyName: "Παρακαλώ συμπληρώστε το όνομα της εταιρίας σας!",
    pleaseCompletePostalAddress: "Παρακαλώ συμπληρώστε την ταχυδρομική σας διεύθυνση!",
    pleaseCompleteCity: "Παρακαλώ συμπληρώστε την πόλη σας!",
    pleaseCompleteCountry: "Παρακαλώ συμπληρώστε τη χώρα σας!",

    accountAddedSuccessfully: "Η εγγραφή σας ολοκληρώθηκε επιτυχώς!",
    thereIsAnotherAccountWithThisEmail: "Σφάλμα: Το email χρησιμοποιείται ήδη από άλλο λογαριασμό!",

    addMembershipPlans: "Πακέτα για εγγραφές μελών",
    membershipOptions: "Επιλογές συνδρομής",
    membershipTitle: "Όνομα πακέτου συνδρομής",
    membershipDescription: "Περιγραφή πακέτου",
    membershipDuration: "Διάρκεια πακέτου",
    membershipCost: "Κόστος πακέτου",
    whoCanReserve: "Ποιος μπορεί να κάνει κράτηση;",
    membershipPackages: "Πακέτα συνδρομών",
    pleaseCompleteAllFieldsForAllMemberships: "Παρακαλώ συμπληρώστε όλα τα πεδία για όλα τα πακέτα συνδρομών",
    membershipPackagesUpdated: "Τα πακέτα συνδρομών ανανεώθηκαν!",
    atLeastOneMembershipNeeded: "Δεδομένου ότι υποστηρίζετε εγγραφές μελών, πρέπει να ορίσετε τουλάχιστον ένα πακέτο συνδρομής",

    accountVerification: "Επιβεβαίωση λογαριασμού",
    insertKeySentToYourEmail: "Παρακαλώ εισάγετε το κλειδί που λάβατε στο email σας",
    checkAlsoSpam: "Προσοχή: Ελέγξτε και το φάκελο 'spam'",
    key: "Κλειδί",
    activateAccount: "ΕΝΕΡΓΟΠΟΙΗΣΗ ΛΟΓΑΡΙΑΣΜΟΥ",
    ifYouHaveNotReceivedKey: "Αν δε λάβατε κάποιο κλειδί, παρακαλώ πατήστε παρακάτω",
    resendKey: "ΑΠΟΣΤΟΛΗ ΝΕΟΥ ΚΛΕΙΔΙΟΥ",
    aKeyWasSent: "Ένα κλειδί εστάλη στην ηλεκτρονική σας διεύθυνση",
    accountActivated: "Συγχαρητήρια! Ο λογαριασμός σας έχει ενεργοποιηθεί!",
    invalidKey: "Λάθος κλειδί!",
    iWantAKey: "Θέλω κλειδί για αλλαγή κωδικού",
    iHaveAKey: "Έχω ήδη κλειδί",

    internalError: "Συνέβη κάποιο σφάλμα! Παρακαλούμε προσπαθήστε ξανά!",
    youNeedToBeARegisteredUser: "Απαιτείται εγγραφή για να προσπελάσετε αυτή την πληροφορία"
};