import React from "react";
import { BannerAd, BannerAdSize, TestIds } from "react-native-google-mobile-ads";
import { Provider, Subheading, TextInput, Button, ActivityIndicator, Divider, Text, List } from 'react-native-paper';
import styles from "../../css/myCss";
import { Platform, TouchableOpacity, View, Image } from "react-native";
import * as Linking from 'expo-linking';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import i18n from "../../utils/i18n";

const appStoreLogo =  require("../../assets/appStore.png");
const googlePlayLogo =  require("../../assets/googlePlay.png");
const facebookLogo =  require("../../assets/facebook.png");
const instagramLogo =  require("../../assets/instagram.png");

let MyBottomColumn;
if (Platform.OS === 'web') {  //WEB
        MyBottomColumn = () =>    <View style={styles.columnBottom}>
                                        <TouchableOpacity
                                                onPress={() => { 
                                                        console.log("App Store clicked!");
                                                }
                                        }>
                                                <Image
                                                        source={appStoreLogo}
                                                        accessibilityLabel="Download our iPhone/iPad app from the Apple Store"
                                                        style={{ width: 120, height: 40, marginBottom: 10 }}
                                                />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                                onPress={() => { 
                                                        console.log("Google Play clicked!");
                                                }
                                        }>
                                                <Image
                                                        source={googlePlayLogo}
                                                        accessibilityLabel="Download our Android app from the Google Play"
                                                        style={{ width: 120, height: 36, marginBottom: 10 }}
                                                />
                                        </TouchableOpacity>
                                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                                            <TouchableOpacity
                                                    onPress={() => { 
                                                            console.log("Facebook clicked!");
                                                    }
                                            }>
                                                    <Image
                                                            source={facebookLogo}
                                                            accessibilityLabel="Follow us on Facebook"
                                                            style={{ width: 40, height: 40 }}
                                                    />
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                    onPress={() => { 
                                                            console.log("Instagram clicked!");
                                                    }
                                            }>
                                                    <Image
                                                            source={instagramLogo}
                                                            accessibilityLabel="Follow us on Instagram"
                                                            style={{ width: 40, height: 40, marginLeft: 20 }}
                                                    />
                                            </TouchableOpacity>
                                        </View>
                                </View>;
} else {                      //REACT NATIVE
        MyBottomColumn = () => <View style={{ display: 'none' }}></View>
}

const BottomColumnWeb = () => {
     return (
        <MyBottomColumn/>
     );
    };
   
export default BottomColumnWeb; 